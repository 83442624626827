import { PipesModule } from './../../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateProtefectivaComponent } from './template-protefectiva.component';

// Componente


@NgModule({
  imports: [
    CommonModule,
    PipesModule
  ],
  declarations: [
      TemplateProtefectivaComponent
  ]
})
export class TemplateProtefectivaModule { }
