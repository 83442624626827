import { Component, OnInit } from '@angular/core';
// MODELS
import { Need } from '@models/necesity/needs.model';
// CONSTANT
import { PROSPECTUS_NECESITY_CONSTANTS } from '@constants/prospectus-needs/prospectus-needs.constants';
// SERVICE
import { NecessityService } from '@services/necessity/necessity.service';
import { Router } from '@angular/router';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
/**
 * Componente encargado de las necesidades
 */
@Component({
  selector: 'cvida-necessity',
  templateUrl: './necessity.component.html',
  styleUrls: ['./necessity.component.sass']
})
export class NecessityComponent implements OnInit {

  /**
   * Necesidad actual seleccionada
   * @property
   * @type [Need]
  **/
  currentNeed: Need;

  /**
   * Texto en forma de thumb
   * @property
   * @type [boolean]
   */
  thumbLabel = true;

  /**
   * Valor del deslizador
   * @property
   * @type [Number]
   */
  slideValue = 1;

  /**
   * Costructor
   * @method
   * @param necessity
   * @param opciones
   * @param router
   */
  constructor(
    private necessity: NecessityService,
    private opciones: OpcionesPlanService,
    private router: Router
  ) {
    this.changeYears();
  }

  /**
   * Lifecycle hook de OnInit
   * @method
   */
  ngOnInit(): void {
    this.currentNeed = <Need>PROSPECTUS_NECESITY_CONSTANTS.DEFAULT_NEED;
  }

  /**
   * Establece una necesidad
   * @method
   * @param need
   * @type [Need]
   */
  setActiveNeed(need: Need): void {
    if (need !== this.currentNeed) {
      this.currentNeed = need;
      this.necessity.updateSliderValue(this.currentNeed);
    } else {
      return;
    }
  }

  /**
   * Verifica si la necesidad está activa
   * @method
   * @param need
   * @type [Need]
   * @return [boolean]
   */
  isActiveNeed(need: Need): boolean {
    return this.currentNeed === need;
  }

  /**
   * Muestra el número seleccionado en el deslizador
   * @method
   * @return [number]
   */
  displayWith(): number {
    switch (this.slideValue) {
      case 1:
        return 1;
      case 2:
        return 5;
      case 3:
        return 10;
      case 4:
        return 15;
      case 5:
        return 20;
      case 6:
        return 25;
      case 7:
        return 60;
      case 8:
        return 65;
      case 9:
        return 70;
      default:
        return 0;
    }
  }

  /**
   * Redireccionamiento a url de propuestas
   * @method
   */
  goToPropuestas(): void {
    this.router.navigate(['/cotizador/propuestas']);
  }

  /**
   * Cambia los años en los terminos de pago
   * @method
   */
  changeYears(): void {
    this.opciones.changePaymentTerm(this.displayWith());
  }

}
