import { Injectable } from '@angular/core';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { CoberturaTarifa } from './../../models/universales/coberturaTarifa';
import { CargoAdquisicion } from './../../models/universales/cargoAdquisicion';
import { Fx } from './../../models/universales/fx';
import { Qx } from './../../models/universales/qx';
import { ServicesApi } from '../services-api/services-api';


@Injectable({
  providedIn: 'root'
})
export class BaseDatosUniversalesService {

  online$: Observable<boolean>;
   

  constructor(
    private api:ServicesApi) {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(true)),
    );


   

  }


  async getTarifa(cobTar: CoberturaTarifa) {
    
    const query = {
      selector: {
         Producto: cobTar.Producto,
         Cobertura: {'$in': cobTar.ArrCobertura},
         Moneda: cobTar.Moneda,
         Edad: {'$in': cobTar.ArrEdad},
         Riesgo: {'$in': [cobTar.Riesgo, 'A']},
         Banda: {'$in': cobTar.ArrBanda},
         Genero: {'$in': [cobTar.Genero, 'A']},
         Fumador: {'$in': [cobTar.Fumador, 'A']}
      },
      fields: ['Tarifa', 'Edad', 'Cobertura', 'Banda']
    };
    return await new Promise(async (resolve, reject) => {
      try {
       

        let request;
        request = await this.api.getFactoresUniversales(query).toPromise();
        resolve(request.docs);
      } catch (error) {
        reject('ERR->C01: LA CONSULTA NO OBTUBO NINGUN VALOR: ' + JSON.stringify(cobTar));
      }
    });
  }

  async getCargoAdquisicion(cargoAdq: CargoAdquisicion): Promise<CargoAdquisicion[]> {
    return await new Promise<CargoAdquisicion[]>((resolve, reject) => {
        const query = {
          selector: {
             Producto: cargoAdq.Producto,
             Moneda: cargoAdq.Moneda,
             Comision: cargoAdq.Comision,
             Incremento: {'$in': cargoAdq.ArrIncremento}
          },
          fields: ['Producto', 'Moneda', 'Comision', 'Incremento', 'AnioHasta', 'CargoAdq'],
          sort: ['AnioHasta']
        };
        this.api.getChargueAcqui(query).toPromise()
        .then((result: any) => {
          let docs = result.docs;
          if (docs.length > 0) {
            resolve(docs);
          } else {
            reject('ERR->C02: LA CONSULTA NO OBTUBO NINGUN VALOR: ' + JSON.stringify(cargoAdq));
          }
        });
    });
  }

  async getFx(fx: Fx): Promise<Fx[]> {
    return await new Promise<Fx[]>((resolve, reject) => {
        const query = {
          selector: {
             Producto: fx.Producto,
             Moneda: fx.Moneda,
             Riesgo: fx.Riesgo,
             Banda: fx.Banda,
             Edad: {'$in': fx.ArrEdad},
          },
          fields: ['Edad', 'Fx']
        };
        this.api.getFxUniversals(query).toPromise()
        .then((result:any) => {
          if (result.docs.length > 0) {
            resolve(result.docs);
          } else {
            reject('ERR->C03: LA CONSULTA NO OBTUBO NINGUN VALOR: ' + JSON.stringify(fx));
          }
        });
    });
  }

  async getQx(qx: Qx): Promise<Qx[]> {
    return await new Promise<Qx[]>((resolve, reject) => {
        const query = {
          selector: {
             Producto: qx.Producto,
             Moneda: qx.Moneda,
             Riesgo: {'$in': [qx.Riesgo, 'A']},
             Banda: {'$in': [qx.Banda, 'T']},
          },
          fields: ['Producto', 'Moneda', 'Cobertura', 'Riesgo', 'Banda', 'Edad', 'Qx']
        };
        this.api.getQxUniversals(query).toPromise()
        .then((result: any) => {
          if (result.docs.length > 0) {
            resolve(result.docs);
          } else {
            reject('ERR->C04: LA CONSULTA NO OBTUBO NINGUN VALOR: ' + JSON.stringify(qx));
          }
        });
    });
  }

  async getEquivalentAge(age1: string, age2: string): Promise<any> {
    const query = {
      selector: {
        EdadHorizontal: age1,
        EdadVertical: age2
      },
      fields: ['EdadEquivalente']
    };
    return await this.api.getServiceAgesUniversal(query).toPromise();
  }

}
