import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe que transforma el género 'H' o 'M' a texto.
 */
@Pipe({
  name: 'genderToTextPipe'
})
export class GenderToTextPipe implements PipeTransform {
/** Método básico de la pipe */
  transform(gender: string): string {
  return gender === 'H' ? 'Hombre' : 'Mujer';
    }
}