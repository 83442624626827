import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { RoutesService } from '@app/services/routes/routes.service';
/**
 * Generals component
 */
@Component({
  selector: 'cvida-generals',
  templateUrl: './generals.component.html',
  styleUrls: ['./generals.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class GeneralsComponent implements OnInit {
  /**
   *Estado del collapse
   * @property
   * @type {boolean}
   */
  isCollapsed: boolean;

  /**
   * Detecta si el tipo de dispositivo desde el que se accede es mobile.
   * True si el dispositivo actual es de dimensiones de un smartphone.
   * @property
   * @type {boolean}
   */
  isMobile: boolean;

   /**
   * Ancho de la pantalla del dispositivo
   * @property
   * @type {number}
   */
  screenWidth: number;
  isUniversal = false;
  /**
   *Creates an instance of GeneralsComponent.
   * Declaración de variables.
   * @memberof GeneralsComponent
   */
  constructor(
    private routes: RoutesService,
  ) {
    this.isUniversal = false;
    if (this.routes.chosenCotizador.value === 'Universales') {
      this.isUniversal = true;
    }
    this.isMobile = false;
    this.screenWidth = window.innerWidth;
  }
  /**
   *
   *Inicialización de variables.
   * @memberof GeneralsComponent
   */
  ngOnInit() {
    // Si las dimensiones actuales son menores de 1085, el dispositivo es móvil.
    this.isMobile = window.innerWidth <= 1085;
    // Establece valor inicial del collpase
    this.isCollapsed = this.isMobile;
  }

}
