/** Angular Core */
import { Injectable } from '@angular/core';
/** Services */
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
/** Models */
import { CommissionScheme } from '@models/commission-scheme/commission-scheme.interface';
import { CommissionAssignment } from '../../models/commission-scheme/commission-scheme.interface';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';
/** Constants */
import { DEFAULT_ANUAL_INFLATION } from './../../constants/plan-options/anual-inflations.constant';
import { DEFAULT_COMMISSION_SCHEME } from './../../constants/commission-scheme/commission-scheme.constant';

/**
 * Servicio dedicado al cálculo de los excedentes (Aportaciones Adicionales)
 * @export
 * @class ExcedentesService
 */
@Injectable({
  providedIn: 'root'
})
export class ExcedentesService {
  /**
   *  Aportación anual en UDI. Se crea una instancia reactiva (Observable).
   * @property
   * @type {number}
   * @default "0"
   */
  aportacion_anual_UDI = new BehaviorSubject<number>(0);
  /**
   * Número de aportaciones. Se crea una instancia reactiva (Observable).
   * @property
   * @type    {number}
   * @default "0"
   */

  /** para cambio de moneda */
  cambioMoneda = new BehaviorSubject<string[]>([]);

  /** Valores par apoder elegir */
  values: string[] = [];
  numero_aportaciones = new BehaviorSubject<number>(0);
  primerAportacion = new BehaviorSubject<number>(0);
  segundaAportacion = new BehaviorSubject<number>(0);
  /**
   * Rendimiento actual
   * @property
   * @default "1"
   */
  rendimiento_actual = 0.01;
  /**
   * Número de aporte (Observable).
   * @property
   * @type    {number}
   * @default "0"
   */
  no_aporte = new BehaviorSubject<number>(0);
  /**
   * Comisón cedida al agente. Instancia reactiva (observable).
   * @property
   * @type    {number}
   * @default "0"
   */
  cesion_comision = new BehaviorSubject<number>(0.025);
  /**
   * Nombre que se le asigna al número de cesion de comisión
   * @type    {BehaviorSubject<CommissionAssignment>}
   * @memberof ExcedentesService
   */
  string_cesion_comision = new BehaviorSubject<CommissionAssignment>('Si');
  /**
   * Esquema de comisión. Se crea una instancia reactiva (observable).
   * @property
   * @type    {CommissionScheme}
   * @default DEFAULT_COMMISSION_SCHEME
   */
  esquema_comision = new BehaviorSubject<CommissionScheme>(
    DEFAULT_COMMISSION_SCHEME
  );
  /**
   * Inflación anual de UDI por defecto. (observable)
   * @property
   * @type    {number}
   * @default DEFAULT_ANUAL_INFLATION
   */
  inflacion_udi = new BehaviorSubject<number>(DEFAULT_ANUAL_INFLATION);
  /**
   *  Valor de la currency UDI. (Observable)
   * @property
   * @type    {number}
   */
  valor_UDI = new BehaviorSubject<number>(this.recursosExternos.valor_UDI);
  /**
   * Valor iterativo de la UDI
   * @property
   * @type    {number}
   */
  valor_UDI_iterativo: number;
  /**
   * Excedente. Se crea una instancia reactiva (Observable).
   * @property
   * @type    {Number}
   * @default "0"
   */
  excedente = new BehaviorSubject<number>(0);
  /**
   * Valor del excedente menos la comisión. Se crea una instancia reactiva (observable).
   * @property
   * @type    {number}
   * @default "0"
   */
  excedente_menos_comision = new BehaviorSubject<number>(0);
  /**
   * Crecimiento anual del excedente (Observable).
   * @property
   */
  crecimiento_anual = new BehaviorSubject<number>(0);
  /**
   * Total del excedente (Observable).
   * @property
   */
  total_excedente = new BehaviorSubject<number>(0);
  /**
   * Valor del excedente en pesos (Observable).
   * @property
   */
  excedentes_pesos = new BehaviorSubject<number>(0);
  /**
   * Suma del excedente (Observable)
   * @property
   */
  suma_excedente = new BehaviorSubject<number>(0);

  suma_aportacion_first = new BehaviorSubject<number>(0);
  suma_aportacion_second = new BehaviorSubject<number>(0);

  primerAnioAportacion = new BehaviorSubject<number>(1);
  segundoAnioAportacion = new BehaviorSubject<number>(2);
  montoAdicionalInput = new BehaviorSubject<number>(0);
  tasaInteresInput = new BehaviorSubject<string>('1');
  iteracionCalculoAcumulado = new BehaviorSubject<number>(1);

  /**
   * Crea una instancia de RecursosExternosService
   * @param    recursosExternos
   */
  constructor(private recursosExternos: RecursosExternosService) {}

  /**
   * Se calculan todos los excedentes.
   * @method
   * @param    anioPoliza
   */
  calculosExcedentes(anioPoliza: number): number {
    if (anioPoliza === this.primerAportacion.value) {
      if (this.aportacion_anual_UDI.value !== 0) {
        this.excedente_menos_comision.next(
          this.aportacion_anual_UDI.value -
            (this.string_cesion_comision.value === 'Si' ? 0 :
            this.aportacion_anual_UDI.value * this.cesion_comision.value)
        );
        this.excedente.next(this.excedente_menos_comision.value);
        this.suma_excedente.next(this.excedente_menos_comision.value);
        this.total_excedente.next(
          this.excedente_menos_comision.value *
            (1 + (this.crecimiento_anual.value || 0))
        );
        this.valor_UDI.next(Number(this.valor_UDI.value));
        this.excedentes_pesos.next(
          Number(this.total_excedente.value) * Number(this.valor_UDI.value)
        );
        this.no_aporte.next(1);
      }
    } else {
      if (this.aportacion_anual_UDI.value !== 0) {
        this.excedente.next(
          this.no_aporte.value > this.numero_aportaciones.value
            ? 0
            : this.excedente.value
        );
        this.suma_excedente.next(
          (Number(this.total_excedente.value) + Number(this.excedente.value)) *
            (1 + this.crecimiento_anual.value)
        );
        this.total_excedente.next(this.suma_excedente.value);
        // this.crecimiento_anual.next(Number(this.excedente_menos_comision.value) * Number(this.rendimiento_actual));

        this.valor_UDI.next(
          Number(this.valor_UDI.value) +
            Number(this.valor_UDI.value) * Number(this.inflacion_udi.value)
        );
        this.excedentes_pesos.next(
          Number(this.total_excedente.value) * Number(this.valor_UDI.value)
        );
        this.no_aporte.next(this.no_aporte.value + 1);
      } else {
        anioPoliza === 1
          ? this.valor_UDI.next(Number(this.valor_UDI.value))
          : this.valor_UDI.next(
              Number(this.valor_UDI.value) +
                Number(this.valor_UDI.value) * Number(this.inflacion_udi.value)
            );
      }
    }

    if (
      this.primerAportacion.value <= anioPoliza &&
      this.segundaAportacion.value >= anioPoliza
    ) {
      this.suma_aportacion_first.next(
        this.aportacion_anual_UDI.value * this.iteracionCalculoAcumulado.value
      );
      this.suma_aportacion_second.next(
        this.aportacion_anual_UDI.value *
          this.iteracionCalculoAcumulado.value *
          this.valor_UDI.value
      );
      this.iteracionCalculoAcumulado.next(
        this.iteracionCalculoAcumulado.value + 1
      );
    } else {
      this.suma_aportacion_first.next(0);
      this.suma_aportacion_second.next(0);
    }

    return Number(this.valor_UDI.value);
  }

  setValue(currency: string) {
    switch (currency) {
      case 'UDI':
        this.values = ['1', '1.5', '2'];
        this.cambioMoneda.next(this.values);
        break;
      case 'MXN':
        this.values = ['1', '1.5', '2', '2.5', '3', '3.5', '4'];
        this.cambioMoneda.next(this.values);
        break;
      case 'USD':
        this.values = ['1', '1.5', '2'];
        this.cambioMoneda.next(this.values);
        break;
    }
    return this.cambioMoneda;
  }
}
