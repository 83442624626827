// ANGULAR CORE
import { Injectable } from '@angular/core';
// MODELS
import { Need } from '@models/necesity/needs.model';

/**
 * Servicio encargado de las necesidades del prospecto
 *
 * @export
 * @class NecessityService
 */
@Injectable({
    providedIn: 'root'
})

export class NecessityService {

  /**
   * Necesidad actual del deslizador
   *
   * @type {Need}
   * @memberof NecessityService
   */
  sliderValue: Need;

  /**
   * Necesidad seleccionada
   *
   * @type {Need}
   * @memberof NecessityService
   */
  necessity: Need;

  /**
   * Meta o sueño
   *
   * @type {string}
   * @memberof NecessityService
   */
  dream: string;

  /**
   * Actualiza el valor del slider con una necesidad nueva
   *
   * @param {Need} value
   * @memberof NecessityService
   */
  updateSliderValue(value: Need) {
    this.sliderValue = value;
  }

  /**
   * Actualiza el valor de la meta a obtener
   *
   * @param {string} value
   * @memberof NecessityService
   */
  updateGoals(value: string) {
    this.dream = value;
  }
}
