/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { GF, GFIUniversal } from '@constants/coverages';
/** Models */
import { Plazo } from '../../../models';
/** Services */
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { BAService } from '@services/coverages/BA/ba.service';
import { FactoresService } from '@services/factores/factores.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
/** Rxjs */
import { BehaviorSubject, Subscription } from 'rxjs';

/**
 * Servicio para cobertura GF
 */
@Injectable({
  providedIn: 'root'
})
export class GFService {
  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_UDI = new BehaviorSubject<number>(0);
  sumaAsegurada_MXN_U_I = new BehaviorSubject<number>(0);
  sumaAsegurada_USD_U_I = new BehaviorSubject<number>(0);
  sumaAsegurada_MXN_U_C = new BehaviorSubject<number>(0);
  sumaAsegurada_USD_U_C = new BehaviorSubject<number>(0);
  sumaAsegurada_MXN_U_H = new BehaviorSubject<number>(0);
  sumaAsegurada_USD_U_H = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida1 en la seginda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  sumaAsegurada_MXN_U_I$ = this.sumaAsegurada_MXN_U_I.asObservable();
  sumaAsegurada_UDI_U_I$ = this.sumaAsegurada_USD_U_I.asObservable();
  sumaAsegurada_MXN_U_C$ = this.sumaAsegurada_MXN_U_C.asObservable();
  sumaAsegurada_UDI_U_C$ = this.sumaAsegurada_USD_U_C.asObservable();
  sumaAsegurada_MXN_U_H$ = this.sumaAsegurada_MXN_U_H.asObservable();
  sumaAsegurada_UDI_U_H$ = this.sumaAsegurada_USD_U_H.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();
  /**
   * Crea una suscripción almacenando la suma asegurada de la cobertura Básica
   * @property {Subscription}
   */
  sumaAsegurada_BA: Subscription;

  prima_DiezMasGf = 0;

  /**
   * Constructor
   * @constructor
   * @param recursosExternos Crea una instancia de RecursosExternosService
   * @param factores Crea una instanci de FactoresService
   * @param BaService  Crea una instanciia de BAService
   * @param planOptions Crea una instancia de PlanOptionsService
   */

  cobertura: string;
  constructor(
    private recursosExternos: RecursosExternosService,
    private factores: FactoresService,
    private BaService: BAService,
    private planOptions: OpcionesPlanService
  ) {}
  /**
   * Establece la prima anual en la cobertura dado el valor
   * @param sumaAseguradaUDI
   */
  establecePrimaAnual(sumaAseguradaGF: number) {
    this.sumaAsegurada_BA = this.BaService.sumaAsegurada_UDI
      .distinctUntilChanged()
      .subscribe(sumaAseguradaBA => {
        if (sumaAseguradaGF !== -1) {
          this.obtenerPrima(
            this.recursosExternos.FirstToUDI(sumaAseguradaBA),
            this.recursosExternos.FirstToUDI(sumaAseguradaGF)
          );
        } else {
          this.resetValues();
        }
      });
  }
  /**
   * Obtiene la prima apartir del valor asignado
   * @param {number} sumaAseguradaBA
   * @param {number} sumaAseguradaGF
   */
  obtenerPrima(sumaAseguradaBA: number, sumaAseguradaGF: number): void {
    const valorEnPorcentaje = sumaAseguradaBA * 0.1;
    let valorMaximoEnConstante = 0;

    switch (this.planOptions.moneda) {
      case 'UDI':
        valorMaximoEnConstante = GF.MAX_SUM_UDI || 16000;
        break;

      case 'MXN':
        valorMaximoEnConstante = GF.MAX_SUM_MXN || 30000;
        break;

      case 'USD':
        valorMaximoEnConstante = GF.MAX_SUM_USD || 5000;
        break;
    }

    let sumaAsegurada =
      sumaAseguradaGF !== 0 && sumaAseguradaGF !== 1
        ? sumaAseguradaGF
        : valorMaximoEnConstante;

    switch (true) {
      case valorEnPorcentaje < sumaAsegurada &&
        valorEnPorcentaje < valorMaximoEnConstante:
        sumaAsegurada = valorEnPorcentaje;
        break;
      case valorMaximoEnConstante < valorEnPorcentaje &&
        valorMaximoEnConstante < sumaAsegurada:
        sumaAsegurada = valorMaximoEnConstante;
        break;
    }

    const sumaAsegurada_UDI = sumaAsegurada;
    const sumaAsegurada_MXN = this.recursosExternos.UDIToSecond(
      sumaAsegurada_UDI
    );

    const primaAnual_UDI = this.calculoPrimaGF(sumaAsegurada_UDI, 'Elegido');
    const primaAnual_MXN = this.recursosExternos.UDItoMXN(primaAnual_UDI);

    const primaAnual_UDI_Sugerida1 = this.calculoPrimaGF(
      sumaAsegurada_UDI,
      'Sugerido1'
    );
    const primaAnual_MXN_Sugerida1 = this.recursosExternos.UDItoMXN(
      primaAnual_UDI_Sugerida1
    );

    const primaAnual_UDI_Sugerida2 = this.calculoPrimaGF(
      sumaAsegurada_UDI,
      'Sugerido2'
    );
    const primaAnual_MXN_Sugerida2 = this.recursosExternos.UDItoMXN(
      primaAnual_UDI_Sugerida2
    );

    this.sumaAsegurada_UDI.next(Math.round(Number(sumaAsegurada_UDI)));
    this.sumaAsegurada_MXN.next(sumaAsegurada_MXN);

    this.primaAnual_UDI.next(primaAnual_UDI);
    this.primaAnual_MXN.next(primaAnual_MXN);

    this.primaAnual_UDI_Sugerida1.next(primaAnual_UDI_Sugerida1);
    this.primaAnual_MXN_Sugerida1.next(primaAnual_MXN_Sugerida1);

    this.primaAnual_UDI_Sugerida2.next(primaAnual_UDI_Sugerida2);
    this.primaAnual_MXN_Sugerida2.next(primaAnual_MXN_Sugerida2);
  }

  /**
   * Realiza el calculo de la tarifa
   * @param {number} calculoPrimaGF
   * @returns {number} Prima GF
   * @memberof GFService
   */
  calculoPrimaGF(SAGF, plazo: Plazo, isINCP: boolean = false, edadInpc?: number): number {
    const factorGF = this.factores.obtenerFactorCobertura('GF', plazo, isINCP, edadInpc);
    // Ejecuta la formula dada
    const primaGF = this.obtenerPrimaGF(SAGF, factorGF);
    return primaGF;
  }

    /**
* MÉTODO QUE CALCULA LA NUEVA PRIMA CADA 10 ANIOS
* @param {number} edad
* @memberof GFService
*/
  calcularPrimaDiezMas(edad: number) {
    let primaGF = 0;
    if (this.planOptions.coberturaEnVigencia(this.primaAnual_UDI.value, GF, edad) > 0) {
      const factorGF = this.factores.obtenerFactoresDiezMas('GF', edad);
      primaGF = this.obtenerPrimaGF(this.sumaAsegurada_UDI.value, factorGF);
      primaGF = primaGF - this.primaAnual_UDI.value;
    }
    this.prima_DiezMasGf = primaGF;
  }

  obtenerPrimaGF(SAGF, factorGF: number){
    const primaGF = factorGF !== 0 ? (SAGF * factorGF) / 1000 : 0;
    // Retorna el resultado de la operación
    return primaGF;
  }

  resetValues(cob?: string) {
    this.sumaAsegurada_UDI.next(0);
    this.sumaAsegurada_MXN.next(0);
    this.primaAnual_UDI.next(0);
    this.primaAnual_MXN.next(0);
    this.primaAnual_UDI_Sugerida1.next(0);
    this.primaAnual_MXN_Sugerida1.next(0);
    this.primaAnual_UDI_Sugerida2.next(0);
    this.primaAnual_MXN_Sugerida2.next(0);
    if (cob === 'GFI') {
      this.sumaAsegurada_MXN_U_I.next(0);
      this.sumaAsegurada_USD_U_I.next(0);
      this.sumaAsegurada_MXN_U_C.next(0);
      this.sumaAsegurada_USD_U_C.next(0);
      this.sumaAsegurada_MXN_U_H.next(0);
      this.sumaAsegurada_USD_U_H.next(0);
    }
    if (cob === 'GFC') {
      this.sumaAsegurada_MXN_U_C.next(0);
      this.sumaAsegurada_USD_U_C.next(0);
    }
    if (cob === 'GFH') {
      this.sumaAsegurada_MXN_U_H.next(0);
      this.sumaAsegurada_USD_U_H.next(0);
    }
  }

  // Métodos para universales

  establecePrimaAnualUI(sumaAseguradaGFI: number, cob?: string) {
    this.cobertura = cob;
    this.sumaAsegurada_BA = this.BaService.sumaAseguradaUniversal
      .distinctUntilChanged()
      .subscribe(sumaAseguradaBA => {
        if (sumaAseguradaGFI !== -1) {
          this.obtenerPrimaUI(
            this.recursosExternos.FirstToUDI(sumaAseguradaBA),
            this.recursosExternos.FirstToUDI(sumaAseguradaGFI),
            this.cobertura
          );
        } else {
          this.resetValues(this.cobertura);
        }
      });
  }
  obtenerPrimaUI(sumaAseguradaBA: number, sumaAseguradaGF: number, cob: string): void {
    const valorEnPorcentaje = sumaAseguradaBA * 0.1;
    let valorMaximoEnConstante = 0;

    switch (this.planOptions.moneda) {
      case 'MXN':
        valorMaximoEnConstante = GFIUniversal.MAX_SUM_MXN || 30000;
        break;

      case 'USD':
        valorMaximoEnConstante = GFIUniversal.MAX_SUM_USD || 4000;
        break;
    }

    let sumaAsegurada =
      sumaAseguradaGF !== 0 && sumaAseguradaGF !== 1
        ? sumaAseguradaGF
        : valorMaximoEnConstante;

    switch (true) {
      case valorEnPorcentaje < sumaAsegurada &&
        valorEnPorcentaje < valorMaximoEnConstante:
        sumaAsegurada = valorEnPorcentaje;
        break;
      case valorMaximoEnConstante < valorEnPorcentaje &&
        valorMaximoEnConstante < sumaAsegurada:
        sumaAsegurada = valorMaximoEnConstante;
        break;
    }

    const sumaAsegurada_MXN = sumaAsegurada;
    const sumaAsegurada_USD = this.recursosExternos.MXNtoUSDU(sumaAsegurada_MXN);

    if (cob === 'GFI' || this.sumaAsegurada_MXN_U_I.value > 0 || this.sumaAsegurada_USD_U_I.value > 0) {
      this.sumaAsegurada_MXN_U_I.next(sumaAsegurada_MXN);
      this.sumaAsegurada_USD_U_I.next(sumaAsegurada_USD);
    }
    if (cob === 'GFC' || this.sumaAsegurada_MXN_U_C.value > 0 || this.sumaAsegurada_USD_U_C.value > 0) {
      this.sumaAsegurada_MXN_U_C.next(sumaAsegurada_MXN);
      this.sumaAsegurada_USD_U_C.next(sumaAsegurada_USD);
    }
    if (cob === 'GFH' || this.sumaAsegurada_MXN_U_H.value > 0 || this.sumaAsegurada_USD_U_H.value > 0) {
      this.sumaAsegurada_MXN_U_H.next(sumaAsegurada_MXN);
      this.sumaAsegurada_USD_U_H.next(sumaAsegurada_USD);
    }

  }

}
