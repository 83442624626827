import { Prospectu, User } from '../../models';
import {
  ValorGarantizado,
  ValoresGarantizadosTabla
} from '@models/valor-garantizado/ValorGarantizado';
import { ValoresGarantizadosService } from '@services/valoresGarantizados/valores-garantizados.service';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  TemplateRef,
  HostListener
} from '@angular/core';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { BaseDatosService } from '@services/baseDatos/base-datos.service';
import { GeneradorTablaValoresService } from '@services/generadorTablaValores/generador-tabla-valores.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { CalculosService } from '@services/calculos/calculos.service';
import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';

import { Currency } from '../../constants';
import {
  BsModalService,
  BsModalRef
} from '../../../../node_modules/ngx-bootstrap/modal';
import { AlertModalComponent } from '@shared/modal/alert-modal/alert-modal.component';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { AlertIosComponent } from '@app/shared/modal/alert-ios/alert-ios.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkStateService } from '@app/services/networkState/network-state.service';
/**
 * Componente del template del pdf de valores garantizados
 */
@Component({
  selector: 'cvida-valores-template',
  templateUrl: './valores-template.component.html',
  styleUrls: ['./valores-template.component.sass']
})
export class ValoresTemplateComponent
  implements OnInit {
  /**
   * Hace referencia al elemento pdfQuotation del HTML
   * @property
   * @type {ElementRef}
   */
  @ViewChild('pdfQuotation') pdfTranslate: ElementRef;
  /**
   * Hace referencia a un objeto del dom
   * @property
   * @type {ViewChild}
   */
  @ViewChild('loader')
  /**
   * Hace referencia a un template
   * @property
   * @type {TemplateRef<any>}
   */
  loader: TemplateRef<any>;
  /**
   * Tamño en ancho de la pantalla
   * @property
   * @type {number}
   */
  screenWidth: number;

  /**
   * Parametros de la tabla
   * @property
   * @type {Array<ValoresGarantizadosTabla[]>}
   */
  vistaVg: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Primeros parametros de la tabla (en valores mayores a 60)
   * @property
   * @type {Array<ValoresGarantizadosTabla[]>}
   */
  vistaVg1: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Segundos parametros de la tabla (en valores mayores a 60)
   * @property
   * @type {Array<ValoresGarantizadosTabla[]>}
   */
  vistaVg2: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Valores garantizados en columnas
   * @property
   * @type {ValoresGarantizadosTablas[]}
   */
  vistaCols: ValoresGarantizadosTabla[] = [];
  /**
   * Hace referencia al modal
   */
  modalRef: BsModalRef;
  /**
   * Verifica si el botón está escondido
   * @property
   * @type {boolean}
   */
  hideButon: boolean;

  /**
   * Modelo de los resultados de los valores garantizados
   * @property
   * @type {ValorGarantizado[]}
   */
  resultados: ValorGarantizado[] = [
    {
      edad: 0,
      anioPoliza: 0,
      primaElegida: 0,
      aportacionAdicional_UDI: 0,
      prima_AcumuladaUDI: 0,
      primaDeducibleUDI: 0,
      // primaNoDeducibleUDI: 0,
      valorRescate: 0,
      porcentajeRecuperacionUdi: 0,
      sumaAsegurada: 0,
      valorUDI: 0,
      primaElegida_Pesos: 0,
      aportacionAdicional_MXN: 0,
      prima_AcumuladaMXN: 0,
      primaDeducibleMXN: 0,
      // primaNoDeducibleMXN: 0,
      valorRescate_Pesos: 0,
      porcentajeRecuperacionPesos: 0,
      sumaAsegurada_Pesos: 0,
      valorEnEfectivo_UDI: 0,
      valorEnEfectivo_Pesos: 0,
      aportacionAdicional: 0,
      aportacionAdicionalProyectada: 0,
      porcentajeRecuperacionUdiBaCoExcd: 0,
      porcentajeRecuperacionPesosBaCoExcd: 0,
      porcentajeRecuperacionUdiBaCo: 0,
      porcentajeRecuperacionPesosBaCo: 0
    }
  ];
  /**
   * Propecto actual
   * @property
   * @type {Prospectu}
   */
  prospecto: Prospectu;

  /**
   * Folio generado
   * @property
   * @type {string}
   */
  strFolio: string;
  /**
   * Mensaje del modal
   * @property
   * @type {string}
   */
  message: string;

  /**
   * Fecha actual
   * @property
   */
  date: any;
  /**
   * String para fecha
   * @property
   */
  de = ' de ';
  /**
   * Meses actuales
   * @property
   * @type {number}
   */
  meses;
  /**
   * Fecha actual
   * @property
   * @type {number}
   */
  f;
  /**
   * Tipo de plan seleccionado
   * @property
   * @type {any}
   */
  plan: any;
  /**
   * Tiempo de espera la impresión
   * @property
   */
  time;
  /**
   * Tipo de moneda seleccionado
   * @property
   * @type {Currency}
   */
  moneda: Currency;
  /**
   * Tipo de plzo de pagos seleccionado
   * @property
   * @type {number}
   */
  plazoPagos: number;
  /**
   * Primas totales de todas las coberturas en primera moneda
   * @property
   * @type {number}
   */
  primaTotalUDI: number;
  /**
   * Sumas aseguradas de todas las coberturas en primera moneda
   * @property
   * @type {number}
   */
  sumaAseguradaUDI: number;
  /**
   * Valor actual de UDI
   * @property
   * @type {number}
   */
  valorUDI: number;
  /**
   * Primas totales de todas las coberturas en segunda moneda
   * @property
   * @type {number}
   */
  primaTotalPeso: number;
  /**
   * Sumas aseguradas de todas las coberturas en segunda moneda
   * @property
   * @type {number}
   */
  primaAnualPeso: number;
  /**
   * Inflación anual seleccionada
   * @property
   * @type {string}
   */
  inflacion_anual: string;
  /**
   * Valores de la tabla
   * @property
   * @type {ValorGarantizado[}
   */
  tabladata: ValorGarantizado[] = [];
  /**
   * Versión del pdf
   * @property
   * @type {string}
   */
  version: string;
  /**
   * Tipo de navegador
   * @property
   * @type {string}
   */
  browser: any;
  /**
   * Bandera para antes de imprimir
   * @property
   * @type {boolean}
   */
  beforePrint: boolean;
  /**
   * Valida si cobertura BASICA es Seleccionada
   * @property
   * @type {boolean}
   */
  isBasicaActive: boolean;
  /**
   * Valida si cobertura BI es Seleccionada
   * @property
   * @type {boolean}
   */
  isBiActive: boolean;
  /**
   * Valida si coberturA PAI es Seleccionada
   * @property
   * @type {boolean}
   */
  isPaiActive: boolean;
  /**
   * Valida si cobertura DI es Seleccionada
   * @property
   * @type {boolean}
   */
  isDiActive: boolean;
  /**
   * Valida si cobertura DIPO es Seleccionada
   * @property
   * @type {boolean}
   */
  isDipoActive: boolean;
  /**
   * Valida si cobertura DIPOC es Seleccionada
   * @property
   * @type {boolean}
   */
  isDipocActive: boolean;
  /**
   * Valida si cobertura IAC es Seleccionada
   * @property
   * @type {boolean}
   */
  isIacActive: boolean;
  /**
   * Valida si cobertura PAE es Seleccionada
   * @property
   * @type {boolean}
   */
  isPaeActive: boolean;
  /**
   * Valida si cobertura CC es Seleccionada
   * @property
   * @type {boolean}
   */
  isCCActive: boolean;
  /**
   * Valida si cobertura PCD es Seleccionada
   * @property
   * @type {boolean}
   */
  isPcdActive: boolean;
  /**
   * Valida si cobertura GF es Seleccionada
   * @property
   * @type {boolean}
   */
  isGfActive: boolean;
  /**
   * Coverturas Activas
   * @property
   * @type {boolean}
   */
  activeCoverages: number;
  /**
   * Datos del usuario
   * @property
   * @type {User}
   */
  user: User;

  /**
   * Variable booleana para identificar cuando
   * el plan es Aliados+
   * @type {boolean}
   */
  isDotal: boolean;
  /**
   * Variable booleana para identificar que se ejecuta en iOS
   * @type {boolean}
   */
  isiOS: boolean;
  /**
   * Toma el html del template
   * @property
   * @type {ElementRef}
   */
  @ViewChild('template') template: ElementRef;
  /**
   * Toma el html del elemento folio_sec
   * @property
   * @type {ElementRef}
   */
  @ViewChild('folio_sec') folioItem: ElementRef;
  @ViewChild('firstTable') firstTable: ElementRef;
  /**
   * Verifica si el botón puede esconderse
   * @property
   * @type {boolean}
   */
  hideTable: boolean;

  /**
   * Valores de las primas anuales elegidas en la moneda principal
   *
   * @type {{
   *     BA: number,
   *     BI: number,
   *     PAI: number,
   *     DI: number,
   *     DIPO: number,
   *     DIPOC: number,
   *     IAC: number,
   *     PAE: number,
   *     CC: number,
   *     PCD: number,
   *     GF: number,
   *   }}
   * @memberof ValoresTemplateComponent
   */
  primasElegidas: {
    BA: number,
    BI: number,
    PAI: number,
    DI: number,
    DIPO: number,
    DIPOC: number,
    IAC: number,
    PAE: number,
    CC: number,
    PCD: number,
    GF: number,
  };

  /**
   * Primas elegidas en pesos
   *
   * @type {{
   *     BA: number,
   *     BI: number,
   *     PAI: number,
   *     DI: number,
   *     DIPO: number,
   *     DIPOC: number,
   *     IAC: number,
   *     PAE: number
   *     CC: number,
   *     PCD: number,
   *     GF: number,
   *   }}
   * @memberof ValoresTemplateComponent
   */
  primasElegidasMXN: {
    BA: number,
    BI: number,
    PAI: number
    DI: number,
    DIPO: number,
    DIPOC: number,
    IAC: number,
    PAE: number,
    CC: number,
    PCD: number,
    GF: number
  };

  networkStatus: boolean;

  /**
   * Constructor
   * @constructor
   * @param generadorTabla Crea una instancia de GeneradorTablaValoresService
   * @param ProtectionPlansGenerator Crea una instancia de ProtectictionPlanGeneratorService
   * @param valoresG Crea una instancia de ValoresGarantizadosServiceService
   * @param prospectu Crea una instancia de ProspectoService
   * @param bd Crea una instancia de BaseDatosService
   * @param deviceservice Crea una instancia de DeviceTypeService
   * @param opciones Crea una instancia de OpcionesPlanService
   * @param calculos Crea una instancia de CalculosService
   * @param excedentes Crea una instancia de ExcedentesService
   * @param modalService Crea una instancia de BsModalService
   * @param recursos Crea una instancia de RecursosExternosService
   * @param cdRef Crea una instancia de ChangeDetectorRef
   * @param authentication Crea una instancia de AuthenticationService
   */
  constructor(
    private generadorTabla: GeneradorTablaValoresService,
    private ProtectionPlansGenerator: ProtectionPlansGeneratorService,
    private valoresG: ValoresGarantizadosService,
    private prospectu: ProspectoService,
    private bd: BaseDatosService,
    private deviceservice: DeviceTypeService,
    public opciones: OpcionesPlanService,
    public calculos: CalculosService,
    public excedentes: ExcedentesService,
    private modalService: BsModalService,
    private recursos: RecursosExternosService,
    private authentication: AuthenticationService,
    private network: NetworkStateService
  ) {
    this.authentication.user.subscribe(user => (this.user = user));
    this.hideTable = false;
    this.screenWidth = window.innerWidth;
    this.meses = new Array(
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    );
    this.f = new Date();
    this.valoresG.tablavalores.subscribe(
      valores => (this.resultados = valores)
    );
    this.date =
      this.f.getDate() +
      this.de +
      this.meses[this.f.getMonth()] +
      this.de +
      this.f.getFullYear();
    // this.tabladata = this.generadorTabla.saveTable;
    this.vistaCols = this.generadorTabla.vistaCols;
    this.vistaVg = this.generadorTabla.vistaVg;
    this.hideButon = true;
    this.beforePrint = false;
    this.isBasicaActive = false;
    this.isBiActive = false;
    this.isPaiActive = false;
    this.isDiActive = false;
    this.isDipoActive = false;
    this.isDipocActive = false;
    this.isIacActive = false;
    this.isPaeActive = false;
    this.isCCActive = false;
    this.isPcdActive = false;
    this.isGfActive = false;
    this.activeCoverages = 0;

    this.networkStatus = this.network.lastNetworkState;

  }

  /**
   * Acciones antes de imprimir un pdf
   * @method
   * @return {string}
   */
  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint(event) {
    this.hideButon = false;
    this.beforePrint = true;
  }

  /**
   * Acciones despues de imprimir un pdf
   * @method
   * @return {string}
   */
  @HostListener('window:afterprint', ['$event'])
  onAfterPrint(event) {
    this.hideButon = true;
  }

  /**
   * Lifecycle Hook de OnInit
   * @method
   */
  ngOnInit() {
    this.strFolio = this.bd.folioTest.value;
    this.isiOS = false;

    if (this.deviceservice.getDeviceType() === 'iPhone' || this.deviceservice.getDeviceType() === 'iPad' ) {
      this.openModalDownload();
      this.isiOS = true;
    }

    this.prospectu.prospectoActual.subscribe(value => {
      this.prospecto = value;
    });
    this.valoresG.tablavalores.asObservable().subscribe(data => {
      this.resultados = data;
    });

    const propuesta = this.ProtectionPlansGenerator.plazosSugeridos.value
    .findIndex(item => item === Number(this.opciones.plazoPago.value));

    const esUltimo = this.ProtectionPlansGenerator.esUltimoPlazo.value;
    const esPenultimo = this.ProtectionPlansGenerator.esPenultimoPlazo.value;

    if (esUltimo) {
      switch (propuesta) {
        case 2:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI.value,
            BI: this.calculos.BI.primaAnual_UDI.value,
            PAI: this.calculos.PAI.primaAnual_UDI.value,
            DI: this.calculos.DI.primaAnual_UDI.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI.value,
            IAC: this.calculos.IAC.primaAnual_UDI.value,
            PAE: this.calculos.PAE.primaAnual_UDI.value,
            CC: this.calculos.CC.primaAnual_UDI.value,
            PCD: this.calculos.PCD.primaAnual_UDI.value,
            GF: this.calculos.GF.primaAnual_UDI.value,
          };
        break;
        case 0:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI_Sugerida1.value,
            BI: this.calculos.BI.primaAnual_UDI_Sugerida1.value,
            PAI: this.calculos.PAI.primaAnual_UDI_Sugerida1.value,
            DI: this.calculos.DI.primaAnual_UDI_Sugerida1.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI_Sugerida1.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI_Sugerida1.value,
            IAC: this.calculos.IAC.primaAnual_UDI_Sugerida1.value,
            PAE: this.calculos.PAE.primaAnual_UDI_Sugerida1.value,
            CC: this.calculos.CC.primaAnual_UDI_Sugerida1.value,
            PCD: this.calculos.PCD.primaAnual_UDI_Sugerida1.value,
            GF: this.calculos.GF.primaAnual_UDI_Sugerida1.value,
          };
        break;
        case 1:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI_Sugerida2.value,
            BI: this.calculos.BI.primaAnual_UDI_Sugerida2.value,
            PAI: this.calculos.PAI.primaAnual_UDI_Sugerida2.value,
            DI: this.calculos.DI.primaAnual_UDI_Sugerida2.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI_Sugerida2.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI_Sugerida2.value,
            IAC: this.calculos.IAC.primaAnual_UDI_Sugerida2.value,
            PAE: this.calculos.PAE.primaAnual_UDI_Sugerida2.value,
            CC: this.calculos.CC.primaAnual_UDI_Sugerida2.value,
            PCD: this.calculos.PCD.primaAnual_UDI_Sugerida2.value,
            GF: this.calculos.GF.primaAnual_UDI_Sugerida2.value,
          };
        break;
      }
      switch (propuesta) {
        case 2:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN.value,
            BI: this.calculos.BI.primaAnual_MXN.value,
            PAI: this.calculos.PAI.primaAnual_MXN.value,
            DI: this.calculos.DI.primaAnual_MXN.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN.value,
            IAC: this.calculos.IAC.primaAnual_MXN.value,
            PAE: this.calculos.PAE.primaAnual_MXN.value,
            CC: this.calculos.CC.primaAnual_MXN.value,
            PCD: this.calculos.PCD.primaAnual_MXN.value,
            GF: this.calculos.GF.primaAnual_MXN.value,
          };
        break;
        case 0:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN_Sugerida1.value,
            BI: this.calculos.BI.primaAnual_MXN_Sugerida1.value,
            PAI: this.calculos.PAI.primaAnual_MXN_Sugerida1.value,
            DI: this.calculos.DI.primaAnual_MXN_Sugerida1.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN_Sugerida1.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN_Sugerida1.value,
            IAC: this.calculos.IAC.primaAnual_MXN_Sugerida1.value,
            PAE: this.calculos.PAE.primaAnual_MXN_Sugerida1.value,
            CC: this.calculos.CC.primaAnual_MXN_Sugerida1.value,
            PCD: this.calculos.PCD.primaAnual_MXN_Sugerida1.value,
            GF: this.calculos.GF.primaAnual_MXN_Sugerida1.value,
          };
        break;
        case 1:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN_Sugerida2.value,
            BI: this.calculos.BI.primaAnual_MXN_Sugerida2.value,
            PAI: this.calculos.PAI.primaAnual_MXN_Sugerida2.value,
            DI: this.calculos.DI.primaAnual_MXN_Sugerida2.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN_Sugerida2.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN_Sugerida2.value,
            IAC: this.calculos.IAC.primaAnual_MXN_Sugerida2.value,
            PAE: this.calculos.PAE.primaAnual_MXN_Sugerida2.value,
            CC: this.calculos.CC.primaAnual_MXN_Sugerida2.value,
            PCD: this.calculos.PCD.primaAnual_MXN_Sugerida2.value,
            GF: this.calculos.GF.primaAnual_MXN_Sugerida2.value,
          };
        break;
      }
    } else if (esPenultimo) {
      switch (propuesta) {
        case 1:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI.value,
            BI: this.calculos.BI.primaAnual_UDI.value,
            PAI: this.calculos.PAI.primaAnual_UDI.value,
            DI: this.calculos.DI.primaAnual_UDI.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI.value,
            IAC: this.calculos.IAC.primaAnual_UDI.value,
            PAE: this.calculos.PAE.primaAnual_UDI.value,
            CC: this.calculos.CC.primaAnual_UDI.value,
            PCD: this.calculos.PCD.primaAnual_UDI.value,
            GF: this.calculos.GF.primaAnual_UDI.value,
          };
        break;
        case 0:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI_Sugerida1.value,
            BI: this.calculos.BI.primaAnual_UDI_Sugerida1.value,
            PAI: this.calculos.PAI.primaAnual_UDI_Sugerida1.value,
            DI: this.calculos.DI.primaAnual_UDI_Sugerida1.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI_Sugerida1.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI_Sugerida1.value,
            IAC: this.calculos.IAC.primaAnual_UDI_Sugerida1.value,
            PAE: this.calculos.PAE.primaAnual_UDI_Sugerida1.value,
            CC: this.calculos.CC.primaAnual_UDI_Sugerida1.value,
            PCD: this.calculos.PCD.primaAnual_UDI_Sugerida1.value,
            GF: this.calculos.GF.primaAnual_UDI_Sugerida1.value,
          };
        break;
        case 2:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI_Sugerida2.value,
            BI: this.calculos.BI.primaAnual_UDI_Sugerida2.value,
            PAI: this.calculos.PAI.primaAnual_UDI_Sugerida2.value,
            DI: this.calculos.DI.primaAnual_UDI_Sugerida2.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI_Sugerida2.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI_Sugerida2.value,
            IAC: this.calculos.IAC.primaAnual_UDI_Sugerida2.value,
            PAE: this.calculos.PAE.primaAnual_UDI_Sugerida2.value,
            CC: this.calculos.CC.primaAnual_UDI_Sugerida2.value,
            PCD: this.calculos.PCD.primaAnual_UDI_Sugerida2.value,
            GF: this.calculos.GF.primaAnual_UDI_Sugerida2.value,
          };
        break;
      }
      switch (propuesta) {
        case 1:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN.value,
            BI: this.calculos.BI.primaAnual_MXN.value,
            PAI: this.calculos.PAI.primaAnual_MXN.value,
            DI: this.calculos.DI.primaAnual_MXN.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN.value,
            IAC: this.calculos.IAC.primaAnual_MXN.value,
            PAE: this.calculos.PAE.primaAnual_MXN.value,
            CC: this.calculos.CC.primaAnual_MXN.value,
            PCD: this.calculos.PCD.primaAnual_MXN.value,
            GF: this.calculos.GF.primaAnual_MXN.value,
          };
        break;
        case 0:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN_Sugerida1.value,
            BI: this.calculos.BI.primaAnual_MXN_Sugerida1.value,
            PAI: this.calculos.PAI.primaAnual_MXN_Sugerida1.value,
            DI: this.calculos.DI.primaAnual_MXN_Sugerida1.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN_Sugerida1.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN_Sugerida1.value,
            IAC: this.calculos.IAC.primaAnual_MXN_Sugerida1.value,
            PAE: this.calculos.PAE.primaAnual_MXN_Sugerida1.value,
            CC: this.calculos.CC.primaAnual_MXN_Sugerida1.value,
            PCD: this.calculos.PCD.primaAnual_MXN_Sugerida1.value,
            GF: this.calculos.GF.primaAnual_MXN_Sugerida1.value,
          };
        break;
        case 2:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN_Sugerida2.value,
            BI: this.calculos.BI.primaAnual_MXN_Sugerida2.value,
            PAI: this.calculos.PAI.primaAnual_MXN_Sugerida2.value,
            DI: this.calculos.DI.primaAnual_MXN_Sugerida2.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN_Sugerida2.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN_Sugerida2.value,
            IAC: this.calculos.IAC.primaAnual_MXN_Sugerida2.value,
            PAE: this.calculos.PAE.primaAnual_MXN_Sugerida2.value,
            CC: this.calculos.CC.primaAnual_MXN_Sugerida2.value,
            PCD: this.calculos.PCD.primaAnual_MXN_Sugerida2.value,
            GF: this.calculos.GF.primaAnual_MXN_Sugerida2.value,
          };
        break;
      }

    } else {
      switch (propuesta) {
        case 0:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI.value,
            BI: this.calculos.BI.primaAnual_UDI.value,
            PAI: this.calculos.PAI.primaAnual_UDI.value,
            DI: this.calculos.DI.primaAnual_UDI.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI.value,
            IAC: this.calculos.IAC.primaAnual_UDI.value,
            PAE: this.calculos.PAE.primaAnual_UDI.value,
            CC: this.calculos.CC.primaAnual_UDI.value,
            PCD: this.calculos.PCD.primaAnual_UDI.value,
            GF: this.calculos.GF.primaAnual_UDI.value,
          };
        break;
        case 1:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI_Sugerida1.value,
            BI: this.calculos.BI.primaAnual_UDI_Sugerida1.value,
            PAI: this.calculos.PAI.primaAnual_UDI_Sugerida1.value,
            DI: this.calculos.DI.primaAnual_UDI_Sugerida1.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI_Sugerida1.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI_Sugerida1.value,
            IAC: this.calculos.IAC.primaAnual_UDI_Sugerida1.value,
            PAE: this.calculos.PAE.primaAnual_UDI_Sugerida1.value,
            CC: this.calculos.CC.primaAnual_UDI_Sugerida1.value,
            PCD: this.calculos.PCD.primaAnual_UDI_Sugerida1.value,
            GF: this.calculos.GF.primaAnual_UDI_Sugerida1.value,
          };
        break;
        case 2:
          this.primasElegidas = {
            BA: this.calculos.BA.primaAnual_UDI_Sugerida2.value,
            BI: this.calculos.BI.primaAnual_UDI_Sugerida2.value,
            PAI: this.calculos.PAI.primaAnual_UDI_Sugerida2.value,
            DI: this.calculos.DI.primaAnual_UDI_Sugerida2.value,
            DIPO: this.calculos.DIPO.primaAnual_UDI_Sugerida2.value,
            DIPOC: this.calculos.DIPOC.primaAnual_UDI_Sugerida2.value,
            IAC: this.calculos.IAC.primaAnual_UDI_Sugerida2.value,
            PAE: this.calculos.PAE.primaAnual_UDI_Sugerida2.value,
            CC: this.calculos.CC.primaAnual_UDI_Sugerida2.value,
            PCD: this.calculos.PCD.primaAnual_UDI_Sugerida2.value,
            GF: this.calculos.GF.primaAnual_UDI_Sugerida2.value,
          };
        break;
      }

      switch (propuesta) {
        case 0:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN.value,
            BI: this.calculos.BI.primaAnual_MXN.value,
            PAI: this.calculos.PAI.primaAnual_MXN.value,
            DI: this.calculos.DI.primaAnual_MXN.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN.value,
            IAC: this.calculos.IAC.primaAnual_MXN.value,
            PAE: this.calculos.PAE.primaAnual_MXN.value,
            CC: this.calculos.CC.primaAnual_MXN.value,
            PCD: this.calculos.PCD.primaAnual_MXN.value,
            GF: this.calculos.GF.primaAnual_MXN.value,
          };
        break;
        case 1:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN_Sugerida1.value,
            BI: this.calculos.BI.primaAnual_MXN_Sugerida1.value,
            PAI: this.calculos.PAI.primaAnual_MXN_Sugerida1.value,
            DI: this.calculos.DI.primaAnual_MXN_Sugerida1.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN_Sugerida1.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN_Sugerida1.value,
            IAC: this.calculos.IAC.primaAnual_MXN_Sugerida1.value,
            PAE: this.calculos.PAE.primaAnual_MXN_Sugerida1.value,
            CC: this.calculos.CC.primaAnual_MXN_Sugerida1.value,
            PCD: this.calculos.PCD.primaAnual_MXN_Sugerida1.value,
            GF: this.calculos.GF.primaAnual_MXN_Sugerida1.value,
          };
        break;
        case 2:
          this.primasElegidasMXN = {
            BA: this.calculos.BA.primaAnual_MXN_Sugerida2.value,
            BI: this.calculos.BI.primaAnual_MXN_Sugerida2.value,
            PAI: this.calculos.PAI.primaAnual_MXN_Sugerida2.value,
            DI: this.calculos.DI.primaAnual_MXN_Sugerida2.value,
            DIPO: this.calculos.DIPO.primaAnual_MXN_Sugerida2.value,
            DIPOC: this.calculos.DIPOC.primaAnual_MXN_Sugerida2.value,
            IAC: this.calculos.IAC.primaAnual_MXN_Sugerida2.value,
            PAE: this.calculos.PAE.primaAnual_MXN_Sugerida2.value,
            CC: this.calculos.CC.primaAnual_MXN_Sugerida2.value,
            PCD: this.calculos.PCD.primaAnual_MXN_Sugerida2.value,
            GF: this.calculos.GF.primaAnual_MXN_Sugerida2.value,
          };
        break;
      }

    }


    this.primaTotalUDI = this.calculos.primaAnual_ConRecargo_Actual.value;

    this.sumaAseguradaUDI = this.calculos.primaTotalFraccionadaDividida_Actual.value;

    this.plan = this.opciones.tipoPlan;
    this.moneda = this.opciones.moneda;
    this.plazoPagos = Number(this.opciones.plazoPago.value);
    this.valorUDI = this.recursos.valor_UDI;
    this.primaTotalPeso =
      this.opciones.moneda === 'UDI'
        ? this.recursos.UDItoMXN(this.primaTotalUDI)
        : this.primaTotalUDI;
    this.primaAnualPeso =
      this.opciones.moneda === 'UDI'
        ? this.recursos.UDItoMXN(this.sumaAseguradaUDI)
        : this.sumaAseguradaUDI;
    this.version = this.getVersion();
    this.browser = this.detectBrowser();

    this.ActiveCoverages();

    this.isDotal = this.opciones.tipoPlan === 'DOTAL';
  }

  openModalDownload() {
    this.modalRef = this.modalService.show(AlertIosComponent, {
      backdrop: false
    });
  }

  /**
   * Consigue el tipo de dispositivo en el que se encuentra es válido
   * @method
   * @return {boolean}
   */
  getDeviceType(): boolean {
    const device = this.deviceservice.getDeviceType();
    if (device === 'iPhone' || device === 'iPad' || device === 'Android') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checa si el género del prospecto es mujer
   * @method
   * @return {boolean}
   */
  isFemaleActive(): boolean {
    // BUG - Se repite letias veces la compobación - arreglar
    return this.prospecto.gender === 'M';
  }

  /**
   * Regresa a la última ruta
   * @method
   */
  volver() {
    window.history.back();
  }

  /**
   * Consigue la versión del pdf
   * @method
   * @return {string}
   */
  getVersion(): string {
    let v = '';
    const vanio = this.f.getFullYear();
    const vmes = this.f.getMonth() + 1;
    v = vanio + '.' + vmes;
    return v;
  }

  /**
   * cuenta el numero de coverturas seleccionadas
   * @method
   */
  ActiveCoverages() {
    if (this.calculos.BASICA_primaAnualUDI.value > 0) {
      this.isBasicaActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.BI_sumaAseguradaUDI.value !== 'EXCLUIDO') {
      this.isBiActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.PAI_sumaAseguradaUDI.value > 0) {
      this.isPaiActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.DI_sumaAseguradaUDI.value > 0) {
      this.isDiActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.DIPO_sumaAseguradaUDI.value > 0) {
      this.isDipoActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.DIPOC_sumaAseguradaUDI.value > 0) {
      this.isDipocActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.IAC_sumaAseguradaUDI.value > 0) {
      this.isIacActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.PAE_sumaAseguradaUDI.value > 0) {
      this.isPaeActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.CC_sumaAseguradaUDI.value > 0) {
      this.isCCActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.PCD_sumaAseguradaUDI.value !== 'EXCLUIDO') {
      this.isPcdActive = true;
      this.activeCoverages++;
    }
    if (this.calculos.GF_sumaAseguradaUDI.value > 0) {
      this.isGfActive = true;
      this.activeCoverages++;
    }
  }

  /**
   * Detecta el tipo de navegador que se esta usando
   * @method
   * @return {string}
   */
  detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return (this.browser = 'Opera');
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return (this.browser = 'Chrome');
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return (this.browser = 'Safari');
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return (this.browser = 'Firefox');
    } else if (navigator.userAgent.indexOf('Edge') !== -1) {
      return (this.browser = 'Edge');
    } else {
      return alert('unknown');
    }
  }

  /**
   * Realiza ls impresion dependiendo del dispositivo
   * @method
   */
  imprimir() {
    window.print();
    /*
    switch (this.deviceservice.getDeviceType()) {

      case 'Android':
        this.printAndroid();
        break;

      case 'Desktop':
        this.printDesktop();
        break;

      case 'iPad':
        this.printDesktop();
        break;

      case 'iPhone':
        this.printDesktop();
        break;
    }*/
  }

  /**
   * Llama al modal de inicio de descarga del pdf
   * @method
   * @return {string}
   */
  openModal(replica: boolean) {
    this.message = `En breve comenzará la descarga de su archivo Folio: ${
      this.strFolio
    }`;
    this.ProtectionPlansGenerator.title = this.message;
    this.ProtectionPlansGenerator.dateVisible = replica;
    this.modalRef = this.modalService.show(AlertModalComponent, {
      backdrop: false
    });
  }

  /**
   * Realiza la impresion del pdf en dispositivos android
   * @method
   */
  printAndroid() {
    window.print();
  }

  /**
   * Crea un pdf con la tabla de valores en pantalla
   * @method
   */
  printDesktop() {
    let coberturasLength;

    Observable.combineLatest([
      this.calculos.BA.primaAnual_UDI,
      this.calculos.BI.primaAnual_UDI,
      this.calculos.PAI.primaAnual_UDI,
      this.calculos.DI.primaAnual_UDI,
      this.calculos.DIPO.primaAnual_UDI,
      this.calculos.DIPOC.primaAnual_UDI,
      this.calculos.IAC.primaAnual_UDI,
      this.calculos.PAE.primaAnual_UDI,
      this.calculos.CC.primaAnual_UDI,
      this.calculos.PCD.primaAnual_UDI,
      this.calculos.GF.primaAnual_UDI
    ]).pipe(map(coberturas => coberturas.filter(c => c !== 0))).subscribe(coberturas => coberturasLength = coberturas.length);

    this.hideButon = false;

    if (this.vistaVg.length > 37) {
      this.hideTable = true;
      this.vistaVg1 = this.vistaVg.slice(0, 37);
      this.vistaVg2 = this.vistaVg.slice(37, this.vistaVg.length);
    }

    this.openModal(false);

    setTimeout(async () => {
      if (this.vistaVg.length <= 37) {
        try {
          let height = 2030;

          if (coberturasLength > 1 && coberturasLength <= 4) {
            height = 2090;
          } else if (coberturasLength > 4 && coberturasLength <= 7) {
            height = 2160;
          } else if (coberturasLength > 7) {
            height = 2210;
          }

          const canvas = await html2canvas(this.template.nativeElement,
            { logging: false, height});

          const pdf = new jsPDF('p', 'pt', 'letter', 1);
          const contentDataURL = canvas.toDataURL('image/png');

          pdf.deletePage(1);
          pdf.addPage();

          pdf.addImage(contentDataURL, 'PNG', 0, 0, 612, 792, 'Cotización', 'MEDIUM');

          pdf.save(`CotizadorVida-${this.strFolio}.pdf`);
        } catch (error) {
          Error('No se pudo generar el PDF!');
        }
      } else {
        try {
          const canvas1 = await html2canvas(this.template.nativeElement,
            { height: this.folioItem.nativeElement.getBoundingClientRect().top + 5, logging: false });
          const canvas2 = await html2canvas(this.template.nativeElement,
            { height: this.folioItem.nativeElement.getBoundingClientRect().top - 5,
              y: this.folioItem.nativeElement.getBoundingClientRect().top, logging: false });

          const pdf = new jsPDF('p', 'pt', 'letter', 1);
          const contentDataURL1 = canvas1.toDataURL('image/png');
          const contentDataURL2 = canvas2.toDataURL('image/png');

          pdf.deletePage(1);

          // Primera imagen
          pdf.addPage();
          pdf.addImage(contentDataURL1, 'PNG', 0, 0, 612, 792, 'Cotización Pt1', 'MEDIUM');

          // Segunda imagen
          pdf.addPage();
          pdf.addImage(contentDataURL2, 'PNG', 0, 0, 612, 792, 'Cotización Pt2', 'MEDIUM');

          pdf.save(`CotizadorVida-${this.opciones.tipoPlan}-${this.strFolio}.pdf`);
        } catch (error) {
          Error('No se pudo generar el PDF!');
        }
      }
      this.hideButon = true;
      this.hideTable = false;
    }, 5000);
  }
}
