import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  bolBodalServ = false;
  descripcion = '';
  fechaVisible = false;
  folio = '';
constructor(
) { }



}
