import { Injectable } from '@angular/core';
import { AportacionAdicional } from './../../models/universales/aportacionAdicional';

@Injectable({
  providedIn: 'root'
})
export class FormulasService {

  constructor() { }

  edadCalculada(edad: number, descuestoGenero: number, descuentoHabito: number) {
    return edad - descuestoGenero - descuentoHabito;
  }

  obtenerPrima(sumAsegurada: number, tarifa: number, recFijo: number) {
    return sumAsegurada * (tarifa / 1000) + recFijo;
  }

  // obtenerPrima(sumAsegurada: number, tarifa: number, descuento: number, recFijo: number) {
  //   return sumAsegurada * (tarifa / 1000) * (1 - descuento) + recFijo;
  // }

  asignarProyeccionAportacionAdicional(tasaRendimiento: number, comision: number, arrAportAdc: AportacionAdicional[]) {
    if (arrAportAdc.length > 0) {
      for (let i = 0; i < arrAportAdc.length; i++) {
        if (arrAportAdc[i] !== null) {
          const proy = ((i - 1) >= 0 ? arrAportAdc[i - 1].proyeccion : 0);
          const aport = (arrAportAdc[i].aportacion * (1 - (comision / 100)));
          const rendi = (1 + (tasaRendimiento / 100));
          const retiro = arrAportAdc[i].retiro;
          const vProyec = ((proy + aport) * rendi) - retiro;
          arrAportAdc[i].proyeccion = vProyec;
        }
      }
    }
    return arrAportAdc;
  }

  obtenerMetaBuscadaAportacionAdicional(montoDeseado: number, proyeccion1: number, proyeccion2: number,
    montoAportacion1: number, montoAportacion2: number) {
    let meta = 0;
    meta = (((montoDeseado - proyeccion2) * (montoAportacion2 - montoAportacion1)) / (proyeccion2 - proyeccion1)) + montoAportacion2;
    return meta;

  }

  obtenerPrimaBasicaFondoBasico(primaBasica: number, recargoFijo: number, cargoAdquisicion: number) {
    return primaBasica * (1 - cargoAdquisicion) + (recargoFijo * cargoAdquisicion);
  }

  obtenerPrimaBasicaFondoBasicoINPC(primaBasicaAnt: number, cargoAdq: number, primaSugResto: number, cargoAdquIncre: number, recFijo: number) {
    return (primaBasicaAnt)
    * (1 - cargoAdq)
    + (primaSugResto)
    * (1 - cargoAdquIncre)
    + (recFijo * cargoAdq);
  }

  obtenerPrimaBasicaFondoBasicoINPCPPR(primaBasica: number, cargoAdq: number, recFijo: number) {
    return (primaBasica) * (1 - cargoAdq) + (recFijo * cargoAdq);
  }

  obtenerFxBasica(sumAsegurada: number, fx: number, anio: number) {
    let total = 0;
    if (anio !== 1) {
      total = sumAsegurada * (fx / 24000);
    } else {
      total = sumAsegurada * (fx / 12000);
    }
    return total;
  }

  obtenerFxCoberturas(sumAsegurada: number, fx: number, anio: number) {
    let total = 0;
    const valorFijo = 0.1;
    if (anio !== 1) {
      total = sumAsegurada * ((fx * valorFijo) / 24000);
    } else {
      total = sumAsegurada * ((fx * valorFijo) / 12000);
    }
    return total;
  }

  obtenerCargoTotalAdministracion(fxBasica: number, fxCoberturas: number, recargoFijo: number) {
    return fxBasica + fxCoberturas + (recargoFijo / 12);
  }

  obtenerCostoMortalidad(sumaAsegurada: number, costoSeguroCobertura: number) {
    return sumaAsegurada * (costoSeguroCobertura / 12000);
  }

  obtenerCostoMortalidadCDC(sumaAsegurada: number, costoSeguroCobertura: number) {
    return sumaAsegurada * (costoSeguroCobertura / 12);
  }

  obtenerRiesgoCoberturaBasicaCreciente(sumaAsegurada: number, fondoBasico: number) {
    return this.obtenerMaximoRiesgoCobertura(sumaAsegurada, fondoBasico) - fondoBasico;
  }

  obtenerMaximoRiesgoCobertura(sumaAsegurada: number, valorEfectivo: number) {
    return Math.max(sumaAsegurada, (1.05 * valorEfectivo));
  }

  obtenerFondoBasico(fondoBasico: number, primaBasicaFondoBasico: number, cargoAdministracion: number, costoMortalidad: number, tasaRendimientoMes: number) {
    return (fondoBasico + primaBasicaFondoBasico - cargoAdministracion - costoMortalidad) * tasaRendimientoMes;
  }
  obtenerFondoBasicoSinTasa(fondoBasico: number, primaBasicaFondoBasico: number, cargoAdministracion: number, costoMortalidad: number) {
    return (fondoBasico + primaBasicaFondoBasico - cargoAdministracion - costoMortalidad);
  }
  obtenerFondoExcenteMenosRecargos(fondoExcedente: number,cargoAdministracion: number) {
    return (fondoExcedente + (cargoAdministracion));
  }

  obtenerTasaRendimientoMes(tasaRendimiento: number) {
    const tasa = (tasaRendimiento / 100);
    return Math.pow((1 + tasa), 0.0833333333333333);
  }

  obtenerFondoExcedente(valorFondoExcedente: number, primaExcedente: number, retiroFondoExcendente: number, tasaRendimientoMes: number) {
    return (valorFondoExcedente + primaExcedente) * tasaRendimientoMes - retiroFondoExcendente;
  }

  obtenerValorEfectivo(fondoBasico: number, fondoExcedente: number) {
    return fondoBasico + fondoExcedente;
  }

  obtenerValorRescate(primaBasica: number, fondoBasico: number, fondoExcedente: number, factorRescate: number) {
    let valorRescate = 0.0;
    valorRescate = (Math.max(fondoBasico - (primaBasica * factorRescate), 0)) + fondoExcedente;
    return valorRescate;
  }

  obtenerPorcentajeRecuperacion(valorRescate: number, primaAcumulada: number) {
    return ((valorRescate) / primaAcumulada) * 100;
  }

  obtenerBeneficioFallecimientoNivelada(sumaAsegurada: number, valorEfectivo: number) {
    return Math.max(sumaAsegurada, (1.05 * valorEfectivo));
  }

  obtenerBeneficioFallecimientoCreciente(sumaAsegurada: number, fondoExcedente: number) {
    return Math.max(0, (sumaAsegurada + fondoExcedente));
  }

  obtenerIncrementoSumaAseguradaInpc(sumaAsegurada: number, porcentajeInpc: number) {
    return sumaAsegurada * porcentajeInpc;
  }

  obtenerPrimaCoberturaBasicaIncremento(incrementoSumaAbsoluta: number, tarifa: number) {
    return (incrementoSumaAbsoluta / 1000) * tarifa;
}

}
