import { Component, OnInit } from '@angular/core';
import { RoutesService } from '@app/services/routes/routes.service';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
import { ScrollPercentageDetectionService } from '@app/services/scrollPercentageDetection/scroll-percentage-detection.service';
import { Router } from '@angular/router';
import { QUIZ } from '@app/constants/profiling/anwers-vi-constant';
import { QuizAnswer } from '@app/models';
// TextMask
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { ResizeDetectionService } from '@app/services/resizeDetection/resize-detection.service';
import { CalculosExcedentesUniversalesService } from '@app/services/universales/calculos-excedentes-universales.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AlertModalComponent } from '@shared/modal/alert-modal/alert-modal.component';
import { ModalService } from './../../services/utils/modal.service';

@Component({
  selector: 'cvida-profiling',
  templateUrl: './profiling.component.html',
  styleUrls: ['./profiling.component.sass']
})
export class ProfilingComponent implements OnInit {
  /**Declaracion de variables */

  isCollapsedT1 = false;
  isCollapsedT2 = false;
  isCollapsedT3 = false;
  isCollapsedT4 = false;

  list2: any;

  totalRespuestas = 0;

  porcentajeMask = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: '',
    suffix: ' %',
    allowDecimal: true
  });

  fbValores = {
    distribucion: {
      conservador: 0,
      balanceado: 0,
      creciente: 0,
      dinamicoMX: 0,
      dolares: 0,
      dinamicoEUA: 0
    },
    tasa: {
      conservador: 3,
      balanceado: 5,
      creciente: 6,
      dinamicoMX: 7,
      dolares: 2,
      dinamicoEUA: 4
    }
  };

  feValores = {
    distribucion: {
      conservador: 0,
      balanceado: 0,
      creciente: 0,
      dinamicoMX: 0,
      dolares: 0,
      dinamicoEUA: 0
    },
    tasa: {
      conservador: 3,
      balanceado: 5,
      creciente: 6,
      dinamicoMX: 7,
      dolares: 2,
      dinamicoEUA: 4
    }
  };

  keys = Object.keys(this.fbValores);
  entries = Object.keys(this.fbValores.distribucion);

  actualStateFb: boolean[] = [false, false, false, false, false, false];
  actualStateFe: boolean[] = [false, false, false, false, false, false];

  edad = 0;

  fbTotalPercentaje = 0;
  feTotalPercentaje = 0;

  modalRef: BsModalRef;
  feDistribucionExcd = [];

  messageErrorValidation: String = '';
  messagePosition: String = '';
  messageErrorDist: String = '';

  constructor(
    public routes: RoutesService,
    public opciones: OpcionesPlanService,
    private router: Router,
    public scrollPercent: ScrollPercentageDetectionService,
    public resizeDetection: ResizeDetectionService,
    private calculosExcedentes: CalculosExcedentesUniversalesService,
    private modalService: BsModalService,
    private modal: ModalService
  ) {
    this.list2 = QUIZ;
    this.edad = Number(this.opciones.prospecto.age);
    // this.feValores.distribucion.conservador = 100;
    // this.actualStateFe[0] = true;
    // this.feTotalPercentaje = 100;
    // this.feDistribucionExcd.push(100);
    // this.calculosExcedentes.porcentajeFondoExcedente.next(this.feValores.tasa.balanceado);
    // this.edad = 40;
    // this.opciones.tipoPlan = 'MI PROYECTO R';
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.handleScore();
  }

  goToValores() {
    if (this.validarPorcentajes()) {
      this.recalcularFondos();
      this.router.navigate([
        `/cotizador/${this.routes.chosenCotizador.value}/valores-proyectados`
      ]);
    }
  }

  goToSurplus() {
    if (this.validarPorcentajes()) {
      this.recalcularFondos();
      this.router.navigate([
        `/cotizador/${this.routes.chosenCotizador.value}/primas-excedentes`
      ]);
    }
  }

  private recalcularFondos() {
    this.calcularFondoBasico();
    this.calcularFondoExcedente();
    this.calculosExcedentes.fondoBasico = this.fbValores;
    this.calculosExcedentes.fondoExcedente = this.feValores;
  }

  private validarPorcentajes() {
    if (this.feTotalPercentaje !== 100) {
      this.mostrarModal('El porcentaje de la suma de las distrubuciones en el Fondo Excedente debe ser 100%');
      return false;
    } if (this.fbTotalPercentaje !== 100) {
      this.mostrarModal('El porcentaje de la suma de las distrubuciones en el Fondo Básico debe ser 100%');
      return false;
    }
    return true;
  }

  valueChange(question: number, answer: number) {
    let active = 0;
    if (this.list2[question].answers[answer].checked === true) {
      this.list2[question].answers[answer].checked = false;
      active = 0;
    } else {
      this.list2[question].answers[answer].checked = true;
      active = 1;
    }
    this.totalRespuestas = 0;
    const pre: QuizAnswer = this.list2[question];
    pre.answers.forEach(data => {
      data.checked = false;
    });
    if (active === 1) {
      this.list2[question].answers[answer].checked = true;
    } else {
      this.list2[question].answers[answer].checked = false;
    }
    let valRespuesta = 0;
    valRespuesta = this.list2[question].answers[answer].value;
    if (active === 1) {
      this.list2[question].value = valRespuesta;
    } else {
      this.list2[question].value = null;
    }
    this.list2.forEach(valor => {
      if (valor.value !== undefined && valor.value !== null) {
        this.totalRespuestas = this.totalRespuestas + valor.value;
        this.opciones.totalQuestion.next(this.totalRespuestas);
      }
    });
    this.handleScore();
  }

  inversionChkbx(valueChanged: number, isDolares: boolean = false): void {
    const lengthChosen = this.actualStateFb.filter(v => v).length;
    if (this.actualStateFb[valueChanged]) {
      this.actualStateFb = this.actualStateFb.map((el, i) => {
        if (i !== valueChanged && !isDolares) {
          el = false;
        }

        if (el && !isDolares) {
          this.cleanEntries();
          this.fbValores.distribucion[this.entries[i]] = (100 / lengthChosen);
        }

        if (isDolares && el) {
          this.fbValores.distribucion[this.entries[i]] = (100 / lengthChosen);
        }

        return el;
      });
    } else {
      if (isDolares) {
        this.fbValores.distribucion[this.entries[valueChanged]] = 0;
        this.actualStateFb = this.actualStateFb.map((el, i) => {
          if (el) {
            this.fbValores.distribucion[this.entries[i]] = (100 / lengthChosen);
          }
          return el;
        });
      }
    }
    this.calcularFondoBasico();
  }

  inversionChkbxFe(valueChanged: number) {
    const lengthChosen = this.actualStateFe.filter(v => v).length;
    this.actualStateFe.map((el, i) => {
      if (el) {
        this.feValores.distribucion[this.entries[i]] = (100 / lengthChosen);
      } else {
        this.feValores.distribucion[this.entries[i]] = 0;
      }
    });
    this.calcularFondoExcedente();
  }

  handleScore(): void {
    this.cleanEntries();

    if (this.opciones.tipoPlan === 'VIDA INTELIGENTE') {
      if (this.opciones.moneda === 'MXN') {
        this.actualStateFb[1] = true;
        this.inversionChkbx(1);
        this.fbValores[this.keys[0]][this.entries[1]] = 100;
        if (this.totalRespuestas >= 0 && this.totalRespuestas <= 9) {
          this.actualStateFe[0] = true;
          this.inversionChkbxFe(0);
          this.actualStateFe[1] = false;
          this.inversionChkbxFe(1);
          this.actualStateFe[2] = false;
          this.inversionChkbxFe(2);
          this.actualStateFe[3] = false;
          this.inversionChkbxFe(3);
          this.actualStateFe[4] = false;
          this.inversionChkbxFe(4);
        } else if (this.totalRespuestas >= 10 && this.totalRespuestas <= 24) {
          this.actualStateFe[0] = false;
          this.inversionChkbxFe(0);
          this.actualStateFe[1] = true;
          this.inversionChkbxFe(1);
          this.actualStateFe[2] = false;
          this.inversionChkbxFe(2);
          this.actualStateFe[3] = false;
          this.inversionChkbxFe(3);
          this.actualStateFe[4] = false;
          this.inversionChkbxFe(4);
        } else if (this.totalRespuestas >= 25) {
          this.actualStateFe[0] = false;
          this.inversionChkbxFe(0);
          this.actualStateFe[1] = false;
          this.inversionChkbxFe(1);
          this.actualStateFe[2] = true;
          this.inversionChkbxFe(2);
          this.actualStateFe[3] = false;
          this.inversionChkbxFe(3);
          this.actualStateFe[4] = false;
          this.inversionChkbxFe(4);
        } else {
        }

      } else {
        if (this.totalRespuestas >= 0 && this.totalRespuestas <= 9) {
          this.actualStateFb[4] = true;
          this.inversionChkbx(4);
          this.actualStateFe[0] = false;
          this.actualStateFe[1] = false;
          this.actualStateFe[2] = false;
          this.actualStateFe[3] = false;
          this.actualStateFe[4] = true;
          this.inversionChkbxFe(4);
          this.actualStateFe[5] = false;
          this.inversionChkbxFe(5);
          this.feValores[this.keys[0]][this.entries[4]] = 100;
          this.feValores[this.keys[1]][this.entries[4]] = 2;
          this.fbValores[this.keys[0]][this.entries[4]] = 100;
          this.fbValores[this.keys[1]][this.entries[4]] = 2;
        } else if (this.totalRespuestas >= 10 && this.totalRespuestas <= 24) {
          this.actualStateFe[4] = true;
          this.inversionChkbxFe(4);
          this.actualStateFb[4] = true;
          this.inversionChkbx(4);
          this.actualStateFb[4] = true;
          this.inversionChkbx(4);
          this.actualStateFe[5] = true;
          this.inversionChkbxFe(5);
          this.fbValores[this.keys[0]][this.entries[4]] = 80;
          this.fbValores[this.keys[1]][this.entries[4]] = 2;
          this.fbValores[this.keys[0]][this.entries[5]] = 20;
          this.fbValores[this.keys[1]][this.entries[5]] = 2;

          this.feValores[this.keys[0]][this.entries[4]] = 80;
          this.feValores[this.keys[1]][this.entries[4]] = 2;
          this.feValores[this.keys[0]][this.entries[5]] = 20;
          this.feValores[this.keys[1]][this.entries[5]] = 2;

        } else if (this.totalRespuestas >= 25) {
          this.actualStateFe[4] = true;
          this.inversionChkbxFe(4);
          this.actualStateFb[4] = true;
          this.inversionChkbx(4);
          this.fbValores[this.keys[0]][this.entries[5]] = 100;
          this.fbValores[this.keys[1]][this.entries[4]] = 2;
          this.fbValores[this.keys[1]][this.entries[5]] = 4;

          this.actualStateFe[4] = false;
          this.inversionChkbxFe(4);
          this.actualStateFe[5] = true;
          this.inversionChkbxFe(5);
          this.feValores[this.keys[0]][this.entries[5]] = 100;
          this.feValores[this.keys[1]][this.entries[5]] = 4;
        }
      }
    } else if (this.opciones.tipoPlan === 'MI PROYECTO R') {
      if (this.opciones.moneda === 'MXN') {
        if (this.edad >= 60) {
          this.actualStateFb[1] = true;
          this.actualStateFe[0] = true;
          this.inversionChkbx(0);
          this.fbValores[this.keys[0]][this.entries[1]] = 100;
          this.feValores[this.keys[0]][this.entries[0]] = 100;
        } else if (this.edad >= 50 && this.edad < 60) {
          this.actualStateFb[1] = true;
          this.actualStateFe[1] = true;
          this.inversionChkbx(1);
          this.fbValores[this.keys[0]][this.entries[1]] = 100;
          this.feValores[this.keys[0]][this.entries[1]] = 100;
        } else if (this.edad >= 40 && this.edad < 50) {
          this.actualStateFb[2] = true;
          this.actualStateFe[2] = true;
          this.inversionChkbx(2);
          this.fbValores[this.keys[0]][this.entries[2]] = 100;
          this.feValores[this.keys[0]][this.entries[2]] = 100;
        } else if (this.edad >= 18 && this.edad < 40) {
          this.actualStateFb[3] = true;
          this.actualStateFe[3] = true;
          this.inversionChkbx(3);
          this.fbValores[this.keys[0]][this.entries[3]] = 100;
          this.feValores[this.keys[0]][this.entries[3]] = 100;
        }
      } else {
        if (this.edad >= 60) {
          this.actualStateFb[4] = true;
          this.actualStateFe[4] = true;
          this.inversionChkbx(4);
          this.fbValores[this.keys[0]][this.entries[4]] = 100;
          this.feValores[this.keys[0]][this.entries[4]] = 100;
        } else if (this.edad >= 50 && this.edad < 60) {
          this.actualStateFb[4] = true;
          this.actualStateFe[4] = true;
          // this.inversionChkbx(4, true);
          this.fbValores[this.keys[0]][this.entries[4]] = 80;
          this.feValores[this.keys[0]][this.entries[4]] = 80;
          this.fbValores[this.keys[1]][this.entries[4]] = 2;
          this.feValores[this.keys[1]][this.entries[4]] = 2;

          this.actualStateFb[5] = true;
          this.actualStateFe[5] = true;
          // this.inversionChkbx(5, true);
          this.fbValores[this.keys[0]][this.entries[5]] = 20;
          this.feValores[this.keys[0]][this.entries[5]] = 20;
          this.fbValores[this.keys[1]][this.entries[5]] = 2;
          this.feValores[this.keys[1]][this.entries[5]] = 2;
        } else if (this.edad >= 40 && this.edad < 50) {
          this.actualStateFb[4] = true;
          this.actualStateFe[4] = true;
          // this.inversionChkbx(4, true);
          this.fbValores[this.keys[0]][this.entries[4]] = 70;
          this.feValores[this.keys[0]][this.entries[4]] = 70;
          this.fbValores[this.keys[1]][this.entries[4]] = 3;
          this.feValores[this.keys[1]][this.entries[4]] = 3;

          this.actualStateFb[5] = true;
          this.actualStateFe[5] = true;
          // this.inversionChkbx(5, true);
          this.fbValores[this.keys[0]][this.entries[5]] = 30;
          this.feValores[this.keys[0]][this.entries[5]] = 30;
          this.fbValores[this.keys[1]][this.entries[5]] = 3;
          this.feValores[this.keys[1]][this.entries[5]] = 3;
        } else if (this.edad >= 18 && this.edad < 40) {
          this.actualStateFb[5] = true;
          this.actualStateFe[5] = true;
          this.inversionChkbx(5);
          this.fbValores[this.keys[0]][this.entries[5]] = 100;
          this.feValores[this.keys[0]][this.entries[5]] = 100;
        }
      }
    }
    const fbDistribucion = Object.values(Object.entries(this.fbValores)[0][1])
      .map(v => typeof v === 'string' ? this.cleanInput(v) : v);
    const feDistribucion = Object.values(Object.entries(this.feValores)[0][1])
      .map(v => typeof v === 'string' ? this.cleanInput(v) : v);
    this.feTotalPercentaje = feDistribucion.reduce((acc, el) => acc += el, 0);
    this.fbTotalPercentaje = fbDistribucion.reduce((acc, el) => acc += el, 0);

    // this.calcularFondoBasico();
  }

  cleanEntries() {
    for (const key in this.fbValores.distribucion) {
      if (this.fbValores.distribucion.hasOwnProperty(key)) {
        this.fbValores.distribucion[key] = 0;
      }
    }
  }

  calcularFondoBasico(key?: number): void {
    // if (key !== undefined) {
    //   this.validarInput('basico', key, this.cleanInput(this.fbValores.distribucion[this.entries[key]]));
    // }

    const fbDistribucion = Object.values(Object.entries(this.fbValores)[0][1])
      .map(v => typeof v === 'string' ? this.cleanInput(v) : v);
    const fbTasa = Object.values(Object.entries(this.fbValores)[1][1])
      .map(v => typeof v === 'string' ? this.cleanInput(v) : v);
    const fbArr = [fbDistribucion, fbTasa];

    const calculo = fbArr[0].reduce((acc, el, i) => acc += (el * 0.1) * (fbArr[1][i] * 0.1), 0);
    const calculoDistribucion = fbArr[0].reduce((acc, el) => acc += el, 0);
    this.fbTotalPercentaje = calculoDistribucion;
    this.calculosExcedentes.porcentajeFondoBasico.next(calculo);
  }

  // private validarInput(type: 'basico' | 'excedente', key: number | string, value: number): void {
  //   const selectedType = type === 'basico' ? this.fbValores : this.feValores;
  //   const distribucion = Object.values(Object.entries(selectedType)[0][1])
  //     .map(v => typeof v === 'string' ? this.cleanInput(v) : v);

  //   const calculoDistribucion = distribucion
  //     .reduce((acc, el, i) => i === key ? acc += 0 : acc += el, 0);
  //   key = this.entries[key];

  //   if (calculoDistribucion < 100 && value < 100 - calculoDistribucion) {
  //     type === 'basico' ? this.fbValores.distribucion[key] = value
  //       : this.feValores.distribucion[key] = value;
  //   } else {
  //     if (calculoDistribucion < 100) {
  //       type === 'basico' ? this.fbValores.distribucion[key] = 100 - calculoDistribucion
  //         : this.feValores.distribucion[key] = 100 - calculoDistribucion;
  //     } else {
  //       type === 'basico' ? this.fbValores.distribucion[key] = 0
  //         : this.feValores.distribucion[key] = 0;
  //     }
  //   }
  // }

  calcularFondoExcedente(key?: number): void {
    // if (key !== undefined) {
    //   this.validarInput('excedente', key, this.cleanInput(this.feValores.distribucion[this.entries[key]]));
    // }
    const feDistribucion = Object.values(Object.entries(this.feValores)[0][1])
      .map(v => typeof v === 'string' ? this.cleanInput(v) : v);
    this.feDistribucionExcd = feDistribucion;
    const feTasa = Object.values(Object.entries(this.feValores)[1][1])
      .map(v => typeof v === 'string' ? this.cleanInput(v) : v);
    const feArr = [feDistribucion, feTasa];

    const calculo = feArr[0].reduce((acc, el, i) => acc += (el * 0.1) * (feArr[1][i] * 0.1), 0);
    const calculoDistribucion = feArr[0].reduce((acc, el) => acc += el, 0);

    this.feTotalPercentaje = calculoDistribucion;
    this.calculosExcedentes.porcentajeFondoExcedente.next(calculo);
  }

  private cleanInput(input: string): number {
    return Number(String(input).replace(' %', '')) || 0;
  }

  private mostrarModal(descripcion: string) {
    this.modal.bolBodalServ = true;
    this.modal.descripcion = descripcion;
    this.modalRef = this.modalService.show(AlertModalComponent, {
      backdrop: false,
      class: 'alert-modal'
    });
    this.modal.bolBodalServ = false;
  }

  messageSetPosition(position: string = '') {
    return this.messagePosition = position;
  }

  /**
   * Estructura ranges[Nombre, Mínimo, Máximo, Default]
   * @param key Posición del elemento a validar
   * @param inValue Valor del elemento (Porcentaje %)
   */
  prepareNumberValid(key?: number, inValue?: string) {
    const ranges = [['Conservador', 0, 5, 3], ['Balanceado', 0, 7, 5], ['Creciente', 0, 9, 6],
    ['Dinámico MX', 0, 11, 7], ['Dólares', 0, 4, 2], ['Dinámico EUA', 0, 8, 4]
    ];
    if (key >= 0) {
      const min = ranges[key][1];
      const max = ranges[key][2];
      inValue = inValue.substring(0, inValue.length - 2);
      if ((Number(inValue) < Number(min)) || (Number(inValue) > Number(max))) {
        this.messageErrorValidation = 'La tasa para el fondo ' + ranges[key][0] + ' debe de estar entre ' + min + '% y ' + max + '%';
        this.messageSetPosition('right');
        switch (key) {
          case 0:
            this.feValores.tasa.conservador = Number(ranges[key][3]);
            break;
          case 1:
            this.feValores.tasa.balanceado = Number(ranges[key][3]);
            break;
          case 2:
            this.feValores.tasa.creciente = Number(ranges[key][3]);
            break;
          case 3:
            this.feValores.tasa.dinamicoMX = Number(ranges[key][3]);
            break;
          case 4:
            this.feValores.tasa.dolares = Number(ranges[key][3]);
            break;
          case 4:
            this.feValores.tasa.dinamicoEUA = Number(ranges[key][3]);
            break;
          default:
            break;
        }
      } else {
        this.messageErrorValidation = '';
      }
    }
  }

  /**
   * Estructura ranges[Nombre, Mínimo, Máximo, Default]
   * @param key Posición del elemento a validar
   * @param inValue Valor del elemento (Porcentaje %)
   */
  prepareNumberValidFb(key?: number, inValue?: string) {
    const ranges = [['Conservador', 0, 5, 3], ['Balanceado', 0, 7, 5], ['Creciente', 0, 9, 6],
    ['Dinámico MX', 0, 11, 7], ['Dólares', 0, 4, 2], ['Dinámico EUA', 0, 8, 4]
    ];
    if (key >= 0) {
      const min = ranges[key][1];
      const max = ranges[key][2];
      inValue = inValue.substring(0, inValue.length - 2);
      if ((Number(inValue) < Number(min)) || (Number(inValue) > Number(max))) {
        this.messageErrorValidation = 'La tasa para el fondo ' + ranges[key][0] + ' debe de estar entre ' + min + '% y ' + max + '%';
        this.messageSetPosition('left');
        switch (key) {
          case 0:
            this.fbValores.tasa.conservador = Number(ranges[key][3]);
            break;
          case 1:
            this.fbValores.tasa.balanceado = Number(ranges[key][3]);
            break;
          case 2:
            this.fbValores.tasa.creciente = Number(ranges[key][3]);
            break;
          case 3:
            this.fbValores.tasa.dinamicoMX = Number(ranges[key][3]);
            break;
          case 4:
            this.fbValores.tasa.dolares = Number(ranges[key][3]);
            break;
          case 4:
            this.fbValores.tasa.dinamicoEUA = Number(ranges[key][3]);
            break;
          default:
            break;
        }
      } else {
        this.messageErrorValidation = '';
      }
    }
  }

  initValidationMessage(key?: number) {
    this.messageErrorValidation = '';
  }

  validDistribucion() {
    if (this.feTotalPercentaje !== 100) {
      this.messageErrorDist = 'El porcentaje de la suma de las distrubuciones en el Fondo Excedente debe ser 100%';
      this.messageSetPosition('right');
      return true;
    } if (this.fbTotalPercentaje !== 100) {
      this.messageErrorDist = 'El porcentaje de la suma de las distrubuciones en el Fondo Básico debe ser 100%';
      this.messageSetPosition('left');
      return true;
    }
    this.messageErrorDist = '';
    return false;
  }

}
