import { Injectable } from '@angular/core';
import { CalculosExcedentesUniversalesService } from '@app/services/universales/calculos-excedentes-universales.service';
import { CargoAdquisicion } from './../../models/universales/cargoAdquisicion';
import { Qx } from './../../models/universales/qx';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { BaseDatosUniversalesService } from './../baseDatos/base-datos-universales.service';
import { FormulasService } from './formulas.service';
import { FrecuenciaPagoService } from '@app/services/universales/frecuencia-pago.service';
import { Fx } from './../../models/universales/fx';
import { CalculosUniversalesService } from './calculos-universales.service';
import { ECMIUniversal, DIPOCUniversal, GFIUniversal } from '@constants/coverages';
import { PAIUniversal, DIUniversal, GFHUniversal, CDC } from '@constants/coverages/coverages.constants';
import { GFCUniversal } from './../../constants/coverages/coverages.constants';
import { VALOR_RESCATE } from '@app/constants';
import { Cobertura } from './../../models/universales/cobertura';
import { PrimasFondos } from '@app/models/universales/primasFondo';

@Injectable({
  providedIn: 'root'
})
export class CalculosProyeccionUniversalesComunService {

  arrCargoAdq: CargoAdquisicion[] = [];
  arrQx: Qx[] = [];
  fx = -1;

  constructor(
    private exc: CalculosExcedentesUniversalesService,
    private opc: OpcionesPlanService,
    private bd: BaseDatosUniversalesService,
    private formulas: FormulasService,
    private frec: FrecuenciaPagoService,
    private primas: CalculosUniversalesService) {
  }

  async obtenerDatos(edadCalculada: number, anio: number) {
    await this.cargoAdquisicion(anio);
    await this.obtenerFx(edadCalculada, anio);
    await this.factorTasaMortalidad(anio);
  }


  primaExcedente(anio: number) {
    let pExcd = 0;
    if (this.exc.arrAportAdi_BS.value.length > 0) {
      if (anio <= this.exc.arrAportAdi_BS.value.length) {
        const arrExced = this.exc.arrAportAdi_BS.value.filter(s => s.anio === anio.toString());
        if (arrExced.length > 0) {
          pExcd = arrExced[0].aportacion;
        }
      }
    }
    return pExcd;
  }

  primaTotal(primaBasica: number, primaExcedente: number) {
    return primaBasica + primaExcedente;
  }

  primaAcumulada(primaTotal: number, primaTotalAnterior: number) {
    return primaTotal + primaTotalAnterior;
  }

  async fondoBasico(primaBasica: number, sumaAsegurada: number, fondoBasicoAnt: number, anio: number, edadCalculada: number, primaFondoExcedente:number,
    edadReal?: number, difPrima?: number, sumaPrimaAnt?: number, arrCoberturas?: Cobertura[]) {
    await this.obtenerDatos(edadCalculada, anio);
    let mesActual = 1;
    let primasFondos: PrimasFondos = {
      fondoBasico: 0,
      fondoExcedente: 0
    };
    let fondoBasico = fondoBasicoAnt;
    const frecuencia = this.frec.frecuenciaPagoUniv.value.filter(s => s.seleccionado)[0].frecuencia;
    const mesdePago = 12 / frecuencia;
    const recFijo = this.primas.recargoFijoBasica.value;
    const cargoAdquisicion = Number(this.arrCargoAdq.filter(s => anio <= s.AnioHasta && s.Incremento === '0')[0].CargoAdq);
    let primaBasicaFondoBasico = this.obtenerPrimaBasicaFondoBasico(primaBasica, recFijo, cargoAdquisicion, difPrima, sumaPrimaAnt, anio);
    primaBasicaFondoBasico = Number((primaBasicaFondoBasico / frecuencia).toFixed(2));
    const fx = this.fx;
    let tasaRendimientoBasicoMes = this.exc.porcentajeFondoBasico.value;    
    tasaRendimientoBasicoMes = this.formulas.obtenerTasaRendimientoMes(tasaRendimientoBasicoMes);   
    const arrCostoSeguroCobertura = this.arrQx.filter(s => s.Edad === edadCalculada.toString());
    // tasaRendimientoBasicoMes: ${tasaRendimientoBasicoMes}`);
    for (let mes = 1; mes <= 12; mes++) {
      let riesgoCoberturaBasica = 0;
      const fxBasica = this.formulas.obtenerFxBasica(sumaAsegurada, fx, anio);
      const fxCoberturas = this.fxCoberturas(fx, edadReal, anio, arrCoberturas);
      const cargoTotalAdministracion = this.formulas.obtenerCargoTotalAdministracion(fxBasica, fxCoberturas, recFijo);
      riesgoCoberturaBasica = this.obtenerRiesgoCoberturaBasica(anio, mes, sumaAsegurada, fondoBasico);
      let primaBasicaFb = 0;
      if (mes === 1 || mesActual === mes) {
        mesActual += mesdePago;
        primaBasicaFb = primaBasicaFondoBasico;
      }
      const costoMortalidad = this.costoMortalidad(riesgoCoberturaBasica, arrCostoSeguroCobertura, edadReal, anio, arrCoberturas);
      let fondoBasicoSinTasa = this.formulas.obtenerFondoBasicoSinTasa(fondoBasico, primaBasicaFb, cargoTotalAdministracion,
      costoMortalidad);
      fondoBasico = fondoBasicoSinTasa * tasaRendimientoBasicoMes;
      if(fondoBasico < 0 ) {  
      let primaExcedenteTotal = this.formulas.obtenerFondoExcenteMenosRecargos(primaFondoExcedente, fondoBasicoSinTasa);   
      primasFondos = {
        fondoBasico: 0,
        fondoExcedente: primaExcedenteTotal};
       }
       else {
         primasFondos = {
          fondoBasico: fondoBasico,
          fondoExcedente: primaFondoExcedente};
      }

    }
    
    return primasFondos;
  }

  public fondoExcedente(fondoExcedenteAnt: number, anio: number) {
    let mesActual = 1;
    const frecuencia = this.frec.frecuenciaPagoUniv.value.filter(s => s.seleccionado)[0].frecuencia;
    const mesdePago = 12 / frecuencia;
    let valorFondoExc = fondoExcedenteAnt;
    let tasaRendimiento = this.exc.porcentajeFondoExcedente.value;
    tasaRendimiento = this.formulas.obtenerTasaRendimientoMes(tasaRendimiento);
    let primExcd = 0;
    const arrExcd = this.exc.arrAportAdi_BS.value.filter(s => s.anio === anio.toString());
    let retiro = 0;
    if (arrExcd.length > 0) {
      retiro = arrExcd[0].retiro;
      primExcd = arrExcd[0].aportacion / frecuencia;
    }
    for (let mes = 1; mes <= 12; mes++) {
      let prima = 0;
      if (mes === 1 || mesActual === mes) {
        mesActual += mesdePago;
        prima = primExcd;
      }
      valorFondoExc = this.formulas.obtenerFondoExcedente(valorFondoExc, prima, mes === 12 ? retiro : 0, tasaRendimiento);
    }
    return valorFondoExc;
  }

  public valorEfectivo(fondoBasico: number, fondoExcedente: number) {
    return this.formulas.obtenerValorEfectivo(fondoBasico, fondoExcedente);
  }

  public valorRescate(primaBasica: number, fondoBasico: number, fondoExcedente: number, anio: number) {
    let factorRescate = 0;
    if (this.opc.tipoPlan === 'VIDA INTELIGENTE') {
      if (anio <= 10) {
        factorRescate = VALOR_RESCATE[anio - 1];
      }
    }
    return this.formulas.obtenerValorRescate(primaBasica, fondoBasico, fondoExcedente, factorRescate);
  }

  public porcentajeRecuperacion(valorRescate: number, primaAcumulada: number) {
    return Number(this.formulas.obtenerPorcentajeRecuperacion(valorRescate, primaAcumulada).toFixed(2));
  }

  public beneficioFallecimiento(sumaAsegurada: number, valorEfectivo: number, fondoExcedente: number) {
    if (this.opc.tipoPlan === 'VIDA INTELIGENTE') {
      const esquema = 'Creciente';
      if (esquema === 'Creciente') {
        return this.formulas.obtenerBeneficioFallecimientoCreciente(sumaAsegurada, fondoExcedente);
      } else {
        return this.formulas.obtenerBeneficioFallecimientoNivelada(sumaAsegurada, valorEfectivo);
      }
    } else {
      return this.formulas.obtenerBeneficioFallecimientoCreciente(sumaAsegurada, valorEfectivo);
    }

  }

  private obtenerPrimaBasicaFondoBasico(primaBasica: number, recFijo: number, cargoAdquisicion: number, difPrima?: number, sumaPrimaAnt?: number,
    anio?: number) {
    if (!this.opc.inpcUniversal.value) {
      return this.formulas.obtenerPrimaBasicaFondoBasico(primaBasica, recFijo, cargoAdquisicion);
    } else {
      let primaSugeridaResto = difPrima < 0 ? 0 : difPrima;
      if (primaSugeridaResto === 0) {
        primaSugeridaResto = primaBasica - sumaPrimaAnt < 0 ? 0 : primaBasica - sumaPrimaAnt;
      }
      if (this.opc.tipoPlan === 'VIDA INTELIGENTE') {
        const cargoAdquisicionInc = Number(this.arrCargoAdq.filter(s => anio <= s.AnioHasta && s.Incremento === '1')[0].CargoAdq);
        return this.formulas.obtenerPrimaBasicaFondoBasicoINPC(sumaPrimaAnt, cargoAdquisicion, primaSugeridaResto, cargoAdquisicionInc, recFijo);
      } else {
        return this.formulas.obtenerPrimaBasicaFondoBasicoINPCPPR(primaBasica, cargoAdquisicion, recFijo);
      }
    }
  }

  private obtenerRiesgoCoberturaBasica(anio: number, mes: number, sumaAsegurada: number, fondoBasico: number) {
    let riesgoCoberturaBasica = sumaAsegurada;
    if (this.opc.tipoPlan === 'VIDA INTELIGENTE') {
      if (mes === 1 && anio === 1) {
        riesgoCoberturaBasica = sumaAsegurada;
      } else {
        riesgoCoberturaBasica = this.formulas.obtenerRiesgoCoberturaBasicaCreciente(sumaAsegurada, fondoBasico);
      }
    }
    return riesgoCoberturaBasica;
  }


  private fxCoberturas(fx: number, edadReal: number, anio: number, arrCoberturas?: Cobertura[]) {
    let fxCob = 0;
    let ecmi = 0, pai = 0, di = 0, dipoc = 0, gfi = 0, gfh = 0, gfc = 0, cdc = 0;
    if (!this.opc.inpcUniversal.value || anio === 1) {
      ecmi = this.primas.ecmi.value.sumaAsegurada;
      pai = this.primas.pai.value.sumaAsegurada;
      di = this.primas.di.value.sumaAsegurada;
      dipoc = this.primas.dipoc.value.sumaAsegurada;
      gfi = this.primas.gfi.value.sumaAsegurada;
      gfh = this.primas.gfh.value.sumaAsegurada;
      gfc = this.primas.gfc.value.sumaAsegurada;
      cdc = this.primas.cdc.value.sumaAsegurada;
    } else {
      ecmi = this.buscarSumAsegCobertura('ECMI', arrCoberturas);
      pai = this.buscarSumAsegCobertura('PAI', arrCoberturas);
      di = this.buscarSumAsegCobertura('DI', arrCoberturas);
      dipoc = this.buscarSumAsegCobertura('DIPOC', arrCoberturas);
      gfi = this.buscarSumAsegCobertura('GFI', arrCoberturas);
      gfh = this.buscarSumAsegCobertura('GFH', arrCoberturas);
      gfc = this.buscarSumAsegCobertura('GFC', arrCoberturas);
      cdc = this.buscarSumAsegCobertura('CDC', arrCoberturas);
    }
    fxCob += (edadReal >= ECMIUniversal.CANCELLATION_DATE + 1 ? 0 : this.fxCobertura(ecmi, fx, anio));
    fxCob += (edadReal >= PAIUniversal.CANCELLATION_DATE + 1 ? 0 : this.fxCobertura(pai, fx, anio));
    fxCob += (edadReal >= DIUniversal.CANCELLATION_DATE + 1 ? 0 : this.fxCobertura(di, fx, anio));
    fxCob += (edadReal >= DIPOCUniversal.CANCELLATION_DATE + 1 ? 0 : this.fxCobertura(dipoc, fx, anio));
    fxCob += (edadReal >= GFIUniversal.CANCELLATION_DATE + 1 ? 0 : this.fxCobertura(gfi, fx, anio));
    fxCob += (edadReal >= GFHUniversal.CANCELLATION_DATE + 1 ? 0 : this.fxCobertura(gfh, fx, anio));
    fxCob += (edadReal >= GFCUniversal.CANCELLATION_DATE + 1 ? 0 : this.fxCobertura(gfc, fx, anio));
    fxCob += (edadReal >= CDC.CANCELLATION_DATE + 1 ? 0 : this.fxCobertura(cdc, fx, anio));
    return fxCob;
  }

  private fxCobertura(sumaAsegurada: number, fx: number, anio: number) {
    let fxCob = 0;
    if (sumaAsegurada > 0) {
      fxCob = this.formulas.obtenerFxCoberturas(sumaAsegurada, fx, anio);
    }
    return fxCob;
  }

  private costoMortalidad(riesgoCoberturaBasica: number, arrCostoSeguroCobertura: Qx[], edadReal: number, anio?: number, arrCoberturas?: Cobertura[]) {
    let totalCostoMortalidad = 0;
    let ecmi = 0, pai = 0, di = 0, dipoc = 0, gfi = 0, gfh = 0, gfc = 0, cdc = 0;
    if (!this.opc.inpcUniversal.value || anio === 1) {
      ecmi = this.primas.ecmi.value.sumaAsegurada;
      pai = this.primas.pai.value.sumaAsegurada;
      di = this.primas.di.value.sumaAsegurada;
      dipoc = this.primas.dipoc.value.sumaAsegurada;
      gfi = this.primas.gfi.value.sumaAsegurada;
      gfh = this.primas.gfh.value.sumaAsegurada;
      gfc = this.primas.gfc.value.sumaAsegurada;
      cdc = this.primas.cdc.value.sumaAsegurada;
    } else {
      ecmi = this.buscarSumAsegCobertura('ECMI', arrCoberturas);
      pai = this.buscarSumAsegCobertura('PAI', arrCoberturas);
      di = this.buscarSumAsegCobertura('DI', arrCoberturas);
      dipoc = this.buscarSumAsegCobertura('DIPOC', arrCoberturas);
      gfi = this.buscarSumAsegCobertura('GFI', arrCoberturas);
      gfh = this.buscarSumAsegCobertura('GFH', arrCoberturas);
      gfc = this.buscarSumAsegCobertura('GFC', arrCoberturas);
      cdc = this.buscarSumAsegCobertura('CDC', arrCoberturas);
    }
    totalCostoMortalidad = this.obtenerCostoMortalidad(riesgoCoberturaBasica, arrCostoSeguroCobertura, 'BASICA');
    totalCostoMortalidad += edadReal >= ECMIUniversal.CANCELLATION_DATE + 1 ? 0 :
      this.obtenerCostoMortalidad(ecmi, arrCostoSeguroCobertura, 'ECMI');
    totalCostoMortalidad += edadReal >= PAIUniversal.CANCELLATION_DATE + 1 ? 0 :
      this.obtenerCostoMortalidad(pai, arrCostoSeguroCobertura, 'PAI');
    totalCostoMortalidad += edadReal >= DIUniversal.CANCELLATION_DATE + 1 ? 0 :
      this.obtenerCostoMortalidad(di, arrCostoSeguroCobertura, 'DI');
    totalCostoMortalidad += edadReal >= DIPOCUniversal.CANCELLATION_DATE + 1 ? 0 :
      this.obtenerCostoMortalidad(dipoc, arrCostoSeguroCobertura, 'DIPOC');
    totalCostoMortalidad += edadReal >= GFIUniversal.CANCELLATION_DATE + 1 ? 0 :
      this.obtenerCostoMortalidad(gfi, arrCostoSeguroCobertura, 'GFI');
    totalCostoMortalidad += edadReal >= GFHUniversal.CANCELLATION_DATE + 1 ? 0 :
      this.obtenerCostoMortalidad(gfh, arrCostoSeguroCobertura, 'GFH');
    totalCostoMortalidad += edadReal >= GFCUniversal.CANCELLATION_DATE + 1 ? 0 :
      this.obtenerCostoMortalidad(gfc, arrCostoSeguroCobertura, 'GFC');
    totalCostoMortalidad += edadReal >= CDC.CANCELLATION_DATE + 1 ? 0 :
      this.obtenerCostoMortalidad(cdc, arrCostoSeguroCobertura, 'CDC');
    return totalCostoMortalidad;
  }

  private buscarSumAsegCobertura(cobertura: string, arrCoberturas: Cobertura[]) {
    let sumAsegurada = 0;
    const arrCob = arrCoberturas.filter(s => s.cobertura === cobertura);
    if (arrCob.length > 0) {
      sumAsegurada = arrCob[0].sumaAsegurada;
    }
    return sumAsegurada;
  }

  private obtenerCostoMortalidad(sumaAsegurada: number, arrCostoSeguroCobertura: Qx[], cobertura: string) {
    let total = 0;
    if (sumaAsegurada > 0) {
      const costoSeguroCobertura = this.obtenerFactorMortalidad(arrCostoSeguroCobertura, cobertura);
      total = this.formulas.obtenerCostoMortalidad(sumaAsegurada, costoSeguroCobertura);
    }
    return total;
  }

  private obtenerFactorMortalidad(arrCostoSeguroCobertura: Qx[], cobertura: string) {
    let costo = 0;
    const arrCostSeg = arrCostoSeguroCobertura.filter(s => s.Cobertura === cobertura);
    if (arrCostSeg.length > 0) {
      costo = arrCostSeg[0].Qx;
    } else {
      throw new Error('No se encontro el Factor de Mortalidad para la cobertura ' + cobertura);
    }
    return Number(costo);
  }

  private async cargoAdquisicion(anio) {
    if (anio === 1) {
      const producto = this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR';
      const comision = this.opc.esquemaComisionUniv === 'Decrecientes' ? '2' : '1';
      const cargoAdq: CargoAdquisicion = {
        Producto: producto,
        Moneda: this.opc.moneda === 'MXN' ? '1' : '2',
        Comision: comision,
        ArrIncremento: !this.opc.inpcUniversal.value ? ['0'] : ['0', '1'] // Hay que colocar si tiene incrementos Inpc = 1
      };
      this.arrCargoAdq = await this.bd.getCargoAdquisicion(cargoAdq);
      this.arrCargoAdq = this.arrCargoAdq.sort((a, b) => a.AnioHasta - b.AnioHasta);
    }
  }

  private async obtenerFx(edadCalculada: number, anio: number) {
    if (anio === 1) {
      const objFx: Fx = {
        Producto: this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR',
        Moneda: this.opc.moneda === 'MXN' ? '1' : '2',
        Riesgo: this.opc.tipoRiesgo.value === 'Normal' ? 'N' : 'P',
        Banda: this.primas.bandaBasica.value,
        ArrEdad: [edadCalculada.toString()]
      };
      const strFx = await this.bd.getFx(objFx);
      this.fx = Number(strFx[0].Fx);
    }
    // return Number(this.fx);
  }

  private async factorTasaMortalidad(anio: number) {
    if (anio === 1) {
      const qx: Qx = {
        Producto: this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR',
        Moneda: this.opc.moneda === 'MXN' ? '1' : '2',
        Riesgo: this.opc.tipoRiesgo.value === 'Normal' ? 'N' : 'P',
        Banda: this.primas.bandaBasica.value
      };
      this.arrQx = await this.bd.getQx(qx);
    }
  }

}
