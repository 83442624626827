import { CommissionAssignment } from './../../models/commission-scheme/commission-scheme.interface';
import { CommissionScheme } from '@models/commission-scheme/commission-scheme.interface';

/**
 * Esquema de comisión
 * @const
 * @type {CommissionScheme[]}
 */

export const COMMISSION_SCHEMES: CommissionScheme[] = ['Decrecientes', 'Niveladas'];
/**
 * Esquema de comisión por defecto.
 * @const
 * @type {CommissionScheme}
 * @default "Decrecientes"
 */

export const DEFAULT_COMMISSION_SCHEME: CommissionScheme = 'Decrecientes';

/**
 * Asignación de comisión.
 * @const
 * @type {CommissionAssignment[]}
 */

export const COMMISSION_ASSIGNMENTS: CommissionAssignment[] = ['Si', 'No'];
/**
 * Asignación de comisión por defecto.
 * @const
 * @type {CommissionAssignment}
 * @default "No"
 */

export const DEFAULT_COMMISSION_ASSIGNMENT: CommissionAssignment = 'Si';
