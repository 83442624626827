import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TemplateComponent, ValoresTemplateComponent, TemplateProtefectivaComponent } from '@components/index';
import { RouteGuard } from '@guards/route/route.guard';
import { ROUTE_NAMING } from '@constants/index';

const reporte: string = ROUTE_NAMING.TEMPLATE;
const pdf: string = ROUTE_NAMING.VALORESTEMPLATE;
const landing: string = ROUTE_NAMING.LANDING;
const pdfprotefecan: string = ROUTE_NAMING.PROTEFECAN;

const APPROUTES: Routes = [
  { path: '', redirectTo: landing, pathMatch: 'full' },
  { path: reporte, component: TemplateComponent, canActivate: [RouteGuard] },
  { path: pdf, component: ValoresTemplateComponent, canActivate: [RouteGuard]},
  { path: pdfprotefecan, component: TemplateProtefectivaComponent}
];

/**
 * Módulo de ruteo global para la aplicación
 * @export
 * @class AppRoutingModule
 */
@NgModule({
  imports: [
    RouterModule.forRoot(APPROUTES, {
      urlUpdateStrategy: 'deferred',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
