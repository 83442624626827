/** Angular Core */
import { Injectable } from '@angular/core';

/**
 * Dispositivos Apple.
 * @const
 */
const devices = {
    ipad: [{ iPad_Pro_12_9: '12.9 iPad Pro 12.9', id: 1 },
           { iPad_Pro_10_5: 'iPad Pro 10.5', id: 2 },
           { iPad_iPad_mini4_7_9_y_9_7Pro_12_9: 'iPad 9.7  y ipad min 4', id: 3 }
        ],
    iphone: [{ iPhone_XS_Max: 'iPhone XS Max', id: 4},
             { iPhone_XR: 'iPhone XR', id: 5},
             { iPhone_X: 'iPhone X- XS', id: 6},
             { iPhone_Plus: 'iPhone 8 - 7 - 6 Plus', id: 7},
             { iPhone_8_7_6s: 'iPhone 8 - 7-6s', id: 8},
             { iPhone_SE: 'iPhone_SE', id: 9}]
};


/**
 * Servicio orientado a obtener el tipo de dispositivo desde el que se accede al cotizador, así como su resolución y orientación.
 */
@Injectable({
    providedIn: 'root'
})

export class DeviceTypeService {

    /**
     * Obtiene el tipo de dispositivo desde el que se accede.
     * @method
     */
    getDeviceType (): 'iPad' | 'iPhone' | 'Android' | 'Desktop' {
        if (/iPad/.test(navigator.userAgent)) {
            return 'iPad';
        } else if ((/iPhone/.test(navigator.userAgent))) {
            return 'iPhone';
        } else if ((/Android/.test(navigator.userAgent))) {
            return 'Android';
        } else {
            return 'Desktop';
        }
    }
    /**
     * Obtiene la orientación del dispositivo (Portrait o Landscape).
     * @method
     * @param height
     * @param width
     */
    getOrientation(height: number, width: number): string {
        let orientation: string;

        if ('orientation' in screen) {
            orientation =
                'type' in screen['orientation']
                    ? screen['orientation']['type'] === 'portrait-primary' || screen['orientation']['type'] === 'portrait-secondary'
                        ? 'portrait'
                        : 'landscape'
                    : typeof screen['orientation'] === 'string'
                        ? screen['orientation']
                        : height > width
                            ? 'portrait'
                            : 'landscape';
        } else {
            orientation = height > width ? 'portrait' : 'landscape';
        }

        return orientation;
    }

    /**
     *Obtiene el dispositivo desde el que se accede, lo clasifica y retorna el dispositivo específico.
     * @method
     */
    getDevice() {
        const ua = navigator.userAgent.toLowerCase();
        if ( /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)) {
            return window.innerWidth > 1023 && window.innerHeight > 1023 ? 'desktop' : 'tablet';
        } else if (/(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds)/.test(ua) ||
                   /(archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(ua)) {
            return 'mobile';
        } else {
            return 'desktop';
        }
    }
    /**
     *Retorna el dispositivo de iOS con base en la resolución.
     * @returns {number}
     * @memberof DeviceTypeService
     */
    getIosDeviceResolution(): number {
        let val = 0;

        const height = window.screen.height;
        const width = window.screen.width;

        if (/iPhone/.test(navigator.userAgent)) {
            val = this.iphoneDevice(height, width);
        } else {
            val = this.iPadDevice(height, width);
        }

        return val;
    }
    /**
     * Si el dispositivo es un iPhone, retorna el dispositivo específico.
     * @param height
     * @param width
     */
    iphoneDevice(height: number, width: number): number {
        let resolution = 2;
        let type = 0;

        while (resolution <= 3) {

            const w = width * resolution;
            const h = height * resolution;

            if (w === 1242 && h === 2688) {   // iPhone_XS_Max
                type = devices.iphone[0].id;
                break;

            } else if (w === 1125 && h === 2436) { // x - xs
                type = devices.iphone[2].id;
                break;

            } else if (w === 828 && h === 1792) { // xr
                type = devices.iphone[1].id;
                break;

            } else if (w === 1242 && h === 2208) { // 8 -7 -6 plus
                type = devices.iphone[3].id;
                break;

            } else if (w === 750 && h === 1334) { // 8 - 7 - 6s
                type = devices.iphone[4].id;
                break;

            } else if (w === 640 && h === 1136) {
                type = devices.iphone[5].id;
                break;
            }

            resolution += 1;
        }
        return type;
    }
    /**
     *Si el dispositivo es un iPad, retorna el dispositivo específico.
     * @param {number} height
     * @param {number} width
     * @returns {number}
     * @memberof DeviceTypeService
     */
    iPadDevice(height: number, width: number): number {
        let resolution = 2;
        let type = 0;

        while (resolution <= 3) {

            const w = width * resolution;
            const h = height * resolution;

            if (w === 2048 && h === 2732) { // ipad pro 12.9
                type = devices.ipad[0].id;
                break;

            } else if (w === 1668 && h === 2224) { // ipad pro 10.5
                type = devices.ipad[1].id;
                break;

            } else if (w === 1536 && h === 2048) { // ipad 9.7
                type = devices.ipad[2].id;
                break;

            }
            resolution += 1;
        }
        return type;
    }

    /**
     * Prueba si el navegador es Safari
     * @method
     * @returns {boolean}
     * @memberof DeviceTypeService
     */
    isSafari(): boolean {
        return navigator.vendor === 'Apple Computer, Inc' || navigator.vendor === 'Apple Computer, Inc.';
    }
}

