/** Angular Core */
import { Injectable } from '@angular/core';
/** Angular Common */
import { HttpClient, HttpHeaders } from '@angular/common/http';
/** Constants */
import {
  Currency, BA, BAS, BI, IAC, DIPOC, PAI, PAE, GF, DIPO, DI, CC, MU, PAIUniversal, BAUniversal,
  DIUniversal, DIPOCUniversal, CDC, GFIUniversal, GFCUniversal, GFHUniversal,
  //  PAIUniversal
} from '../../constants';
/** Rxjs */
import { Observable } from 'rxjs';
import { CDC_MU } from '@constants/coverages';
import { PLAN_TEMP, PLAN_TEMP_IAC, PLAN_TEMP_CC, PLAN_TEMP_DIPO, PLAN_TEMP_PAI } from './../../constants/coverages/coverages.constants';
import { CoverageConstants } from '@models/coverage-constants/coverage-constants.interface';
import { PLAN_TEMP_PAE } from './../../constants/coverages/coverages.constants';
import { PLAN_TEMP_DI } from './../../constants/coverages/coverages.constants';
import { PLAN_TEMP_DIPOC } from './../../constants/coverages/coverages.constants';
import { environment } from '@env/environment';


/**
 * Servicio orientado a manejar recursos externos
 *
 * @export
 * @class RecursosExternosService
 */
@Injectable({
  providedIn: 'root'
})
export class RecursosExternosService {
  /**
   * URL de la api
   * @property
   * @type [string]
   */
  url: string;
  urlU: string;

  /**
   * Tipo de peticion
   * @property
   * @type [string]
   */
  requestType: string;

  /**
   * Valor inicial de las UDIS
   * @property
   * @default [0]
   */
  valor_UDI = 0;
  valor_USD_U = 0;

  /**
   * Tipo de moneda
   * @property
   * @type [Currency]
   */
  moneda: Currency;
  monedaU: Currency;

  /**
   * Moneda secundaria
   * @property
   * @type [Currency]
   */
  segundaMoneda: Currency;

  /**
   * Valor de la UDI en US
   * @property
   * @default [1]
   */
  valor_UDI_USD = 1;

  /**
   * Valor del dolar
   * @property
   * @default [19.19]
   */
  valor_USD = 19.19;

  /**
   * Metodo constructor que inicializa las variables
   * @param http
   */
  constructor(
    private http: HttpClient,
    //  private opciones: OpcionesPlanService
  ) {
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    this.moneda = 'UDI';
    this.segundaMoneda = 'MXN';
    this.url = `${environment.services}/banxico/udi`; {headers};
      this.setUDI().then();

    this.monedaU = 'USD';
    this.segundaMoneda = 'MXN';
    this.urlU = `${environment.services}/banxico/usd`; {headers};

      this.setUSD().then();
    //}
  }

  /**
   * Metodo que optine el valor actual de la UDI
   * @method
   * @type [Observable<number>]
   */
  getUdiValue(): Observable<number> {
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    return this.http.post(this.url, { headers }).map((response :any)=> {
      response.response.bmx.series[0].datos.sort(function(obj1,obj2){
        return obj1.fecha ? -1 : obj2.fecha ? 1 : 0;
      });
      let currentUDI = null;
      let currentDate = new Date();
      for(let i=0;i<response.response.bmx.series[0].datos.length;i++)
      {
        var dateString = response.response.bmx.series[0].datos[i].fecha; // Oct 23

        var dateParts = dateString.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        if(dateObject > currentDate)
        {
          continue;
        }
        else
        {
          currentUDI =response.response.bmx.series[0].datos[i].dato
          break;
        }
      }
      return currentUDI;
    });
  }
  getUSDValue(): Observable<number> {
    const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    return this.http.post(this.urlU, { headers }).map((response : any) => {
      response.response.bmx.series[0].datos.sort(function(obj1,obj2){
        return obj1.fecha ? -1 : obj2.fecha ? 1 : 0;
      });
      let currentUSD = null;
      let currentDate = new Date();
      for(let i=0;i<response.response.bmx.series[0].datos.length;i++)
      {
        var dateString = response.response.bmx.series[0].datos[i].fecha; // Oct 23

        var dateParts = dateString.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        if(dateObject > currentDate)
        {
          continue;
        }
        else
        {
          currentUSD =response.response.bmx.series[0].datos[i].dato
          break;
        }
      }
      return currentUSD;
    });
  }

  /**
   * Metodo asincrono que establece los valors de las UDIS
   * @method
   * @type [Promise<any>]
   */
  async setUDI(): Promise<any> {
    return await new Promise<any>(resolve => {
        this.getUdiValue().subscribe(data => {
          this.valor_UDI = data;
          resolve(Number(data));
        });
    });
  }

  async setUSD(): Promise<any> {
    return await new Promise<any>(resolve => {
        this.getUSDValue().subscribe(data => {
          this.valor_USD_U = data;
          resolve(Number(data));
        });
    });
  }

  /**
   * Metodo que convierte las UDIS a MXN
   * @param udis
   * @method
   */
  UDItoMXN(udis: number): number {
    return this.valor_UDI * udis;
  }

  /**
   * Metodo que convierte los MXN a UDIS
   * @param mxn
   * @method
   */
  MXNtoUDI(mxn: number) {
    return mxn / this.valor_UDI;
  }

  /**
   * Metodo que convierte los MXN a US
   * @param mxn
   * @method
   */
  MXNtoUSD(mxn: number) {
    return mxn * 18.56;
  }

  MXNtoUSDU(mxn: number) {
    return mxn * this.valor_USD_U;
  }

  /**
   * Metodo que convierte los US a MXN
   * @param usd
   * @method
   */
  USDtoMXN(usd) {
    return usd / 18.56;
  }

  /**
   * Metodo que convierte los valores a UDIS
   * @param firstValue
   * @method
   */
  FirstToUDI(firstValue: number): number {
    switch (this.moneda) {
      case 'UDI':
        return firstValue;
      case 'MXN':
        if (String(this.moneda) === 'UDI') {
          return firstValue / this.valor_UDI;
        } else {
          return firstValue;
        }
      case 'USD':
        return firstValue / this.valor_UDI_USD;
    }
    return 0;
  }

  /**
  * Metodo que convierte los valores secundarios a UDIS
  * @param secondValue
  * @method
  */
  SecondToUDI(secondValue: number): number {
    switch (this.segundaMoneda) {
      case 'UDI':
        return secondValue;
      case 'MXN':
        return secondValue / this.valor_UDI;
      case 'USD':
        return secondValue / this.valor_UDI_USD;
    }
    return 0;
  }

  /**
  * Metodo que convierte las UDIS a valores primarios
  * @param udiValue
  * @method
  */
  UDIToFirst(udiValue: number): number {
    switch (this.moneda) {
      case 'UDI':
        return udiValue;
      case 'MXN':
        if (String(this.moneda) === 'UDI') {
          return udiValue / this.valor_UDI;
        } else {
          return udiValue;
        }
      case 'USD':
        return udiValue * this.valor_UDI_USD;
    }
    return 0;
  }

  /**
   * Metodo que convierte las UDIS a valores secundarios
   * @param udiValue
   * @method
   */
  UDIToSecond(udiValue: number): number {
    switch (this.segundaMoneda) {
      case 'UDI':
        return udiValue;
      case 'MXN':
        return udiValue * this.valor_UDI;
      case 'USD':
        return udiValue * this.valor_UDI_USD;
    }
    return 0;
  }

  /**
   * Metodo que transforma los valores primarios a secundarios
   * @param firstValue
   * @method
   */
  FirstToSecond(firstValue: number): number {
    switch (this.moneda) {
      case 'MXN':
        if (this.segundaMoneda === 'UDI') {
          return firstValue / this.valor_UDI;
        } else if (this.segundaMoneda === 'USD') {
          return firstValue / this.valor_USD;
        }
        return 0;
      case 'USD':
        if (this.segundaMoneda === 'UDI') {
          return firstValue * this.valor_UDI_USD;
        } else if (this.segundaMoneda === 'MXN') {
          return firstValue * this.valor_USD;
        }
        return 0;
      case 'UDI':
        if (this.segundaMoneda === 'MXN') {
          return firstValue * this.valor_UDI;
        } else if (this.segundaMoneda === 'USD') {
          return firstValue * this.valor_UDI_USD;
        }
    }
    return 0;
  }

  /**
   * Metodo que transforma los valores secundaios a primarios
   * @param secondValue
   * @method
   */
  SecondToFirst(secondValue: number): number {
    switch (this.segundaMoneda) {
      case 'UDI':
        if (this.moneda === 'MXN') {
          return secondValue * this.valor_UDI;
        } else if (this.moneda === 'USD') {
          return secondValue * this.valor_UDI_USD;
        }
        return 0;
      case 'MXN':
        if (this.moneda === 'UDI') {
          return secondValue / this.valor_UDI;
        } else if (this.moneda === 'USD') {
          return secondValue / this.valor_USD;
        }
        return 0;
      case 'USD':
        if (this.moneda === 'UDI') {
          return secondValue * this.valor_UDI_USD;
        } else if (this.moneda === 'MXN') {
          return secondValue * this.valor_USD;
        }
    }
    return 0;
  }
  SecondToFirstU(secondValue: number): number {
    if (this.segundaMoneda === 'MXN') {
      // return secondValue / this.valor_USD;
      return secondValue / this.valor_USD_U;
    }
    return 0;
  }

  /**
   * Metodo que optiene los valores maximos y minimos de cobertura
   * @param cobertura
   * @param need
   * @method
   */
  GetMinMax(cobertura: string, need: string): number {
    switch (cobertura) {
      case 'Ba':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? BAS.MAX_SUM_UDI || 0 : BA.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? BAS.MAX_SUM_MXN || 0 : BA.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? BAS.MAX_SUM_USD || 0 : BA.MIN_SUM_USD || 0;
        }    
      case 'BI':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? BI.MAX_SUM_UDI || 0 : BI.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? BI.MAX_SUM_MXN || 0 : BI.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? BI.MAX_SUM_USD || 0 : BI.MIN_SUM_USD || 0;
        }
      case 'CC':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? CC.MAX_SUM_UDI || 0 : CC.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? CC.MAX_SUM_MXN || 0 : CC.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? CC.MAX_SUM_USD || 0 : CC.MIN_SUM_USD || 0;
        }
      case 'PAI':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? PAI.MAX_SUM_UDI || 0 : PAI.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? PAI.MAX_SUM_MXN || 0 : PAI.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? PAI.MAX_SUM_USD || 0 : PAI.MIN_SUM_USD || 0;
        }
      case 'DI':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? DI.MAX_SUM_UDI || 0 : DI.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? DI.MAX_SUM_MXN || 0 : DI.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? DI.MAX_SUM_USD || 0 : DI.MIN_SUM_USD || 0;
        }
      case 'DIPO':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? DIPO.MAX_SUM_UDI || 0 : DIPO.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? DIPO.MAX_SUM_MXN || 0 : DIPO.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? DIPO.MAX_SUM_USD || 0 : DIPO.MIN_SUM_USD || 0;
        }
      case 'DIPOC':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? DIPOC.MAX_SUM_UDI || 0 : DIPOC.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? DIPOC.MAX_SUM_MXN || 0 : DIPOC.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? DIPOC.MAX_SUM_USD || 0 : DIPOC.MIN_SUM_USD || 0;
        }
      case 'IAC':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? IAC.MAX_SUM_UDI || 0 : IAC.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? IAC.MAX_SUM_MXN || 0 : IAC.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? IAC.MAX_SUM_USD || 0 : IAC.MIN_SUM_USD || 0;
        }
      case 'PAE':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? PAE.MAX_SUM_UDI || 0 : PAE.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? PAE.MAX_SUM_MXN || 0 : PAE.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? PAE.MAX_SUM_USD || 0 : PAE.MIN_SUM_USD || 0;
        }
      case 'GF':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? GF.MAX_SUM_UDI || 0 : GF.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? GF.MAX_SUM_MXN || 0 : GF.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? GF.MAX_SUM_USD || 0 : GF.MIN_SUM_USD || 0;
        }
      case 'MU':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? MU.MAX_SUM_UDI || 0 : MU.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? MU.MAX_SUM_MXN || 0 : MU.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? MU.MAX_SUM_USD || 0 : MU.MIN_SUM_USD || 0;
        }
      case 'CDC':
        if (this.moneda === 'UDI') {
          return need === 'MAX' ? CDC_MU.MAX_SUM_UDI || 0 : CDC_MU.MIN_SUM_UDI || 0;
        } else if (this.moneda === 'MXN') {
          return need === 'MAX' ? CDC_MU.MAX_SUM_MXN || 0 : CDC_MU.MIN_SUM_MXN || 0;
        } else {
          return need === 'MAX' ? CDC_MU.MAX_SUM_USD || 0 : CDC_MU.MIN_SUM_USD || 0;
        }
        case 'PlanTmp':
          if (this.moneda === 'MXN') {
            return need === 'MAX' ? PLAN_TEMP.MAX_SUM_MXN || 0 : PLAN_TEMP.MIN_SUM_MXN || 0;
          }
    }
    return 0;
    //TODO:ALIADOSKIDS Poner de regreso los max y mins
  }

  GetMinMaxU(cobertura: string, need: string, mon: string, opciones?: string): number {
    switch (cobertura) {
      case 'BA':
        if (mon === 'MXN') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? BAUniversal.MAX_SUM_MXN || 0 : BAUniversal.MIN_SUM_MXN_VI || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? BAUniversal.MAX_SUM_MXN || 0 : BAUniversal.MIN_SUM_MXN_MR || 0;
          }
        }
        if (mon === 'USD') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? BAUniversal.MAX_SUM_USD || 0 : BAUniversal.MIN_SUM_USD_VI || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? BAUniversal.MAX_SUM_USD || 0 : BAUniversal.MIN_SUM_USD_MR || 0;
          }
        }
        break;
      case 'PAI':
        if (mon === 'MXN') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? PAIUniversal.MAX_SUM_MXN || 0 : PAIUniversal.MIN_SUM_VI_MXN || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? PAIUniversal.MAX_SUM_MXN || 0 : PAIUniversal.MIN_SUM_MR_MXN || 0;
          }
        }
        if (mon === 'USD') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? PAIUniversal.MAX_SUM_USD || 0 : PAIUniversal.MIN_SUM_VI_USD || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? PAIUniversal.MAX_SUM_USD || 0 : PAIUniversal.MIN_SUM_MR_USD || 0;
          }
        }
        break;
      case 'DI':
        if (mon === 'MXN') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? DIUniversal.MAX_SUM_MXN || 0 : DIUniversal.MIN_SUM_VI_MXN || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? DIUniversal.MAX_SUM_MXN || 0 : DIUniversal.MIN_SUM_MR_MXN || 0;
          }
        }
        if (mon === 'USD') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? DIUniversal.MAX_SUM_USD || 0 : DIUniversal.MIN_SUM_VI_USD || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? DIUniversal.MAX_SUM_USD || 0 : DIUniversal.MIN_SUM_MR_USD || 0;
          }
        }
        break;
      case 'DIPOC':
        if (mon === 'MXN') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? DIPOCUniversal.MAX_SUM_MXN || 0 : DIPOCUniversal.MIN_SUM_VI_MXN || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? DIPOCUniversal.MAX_SUM_MXN || 0 : DIPOCUniversal.MIN_SUM_MR_MXN || 0;
          }
        }
        if (mon === 'USD') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? DIPOCUniversal.MAX_SUM_USD || 0 : DIPOCUniversal.MIN_SUM_VI_USD || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? DIPOCUniversal.MAX_SUM_USD || 0 : DIPOCUniversal.MIN_SUM_MR_USD || 0;
          }
        }
        break;
      case 'CDC':
        if (mon === 'MXN') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? CDC.MAX_SUM_MXN || 0 : CDC.MIN_SUM_VI_MXN || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? CDC.MAX_SUM_MXN || 0 : CDC.MIN_SUM_MR_MXN || 0;
          }
        }
        if (mon === 'USD') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? CDC.MAX_SUM_USD || 0 : CDC.MIN_SUM_VI_USD || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? CDC.MAX_SUM_USD || 0 : CDC.MIN_SUM_MR_USD || 0;
          }
        }
        break;
      case 'GFI':
        if (mon === 'MXN') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? GFIUniversal.MAX_SUM_MXN || 0 : GFIUniversal.MIN_SUM_VI_MXN || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? GFIUniversal.MAX_SUM_MXN || 0 : GFIUniversal.MIN_SUM_MR_MXN || 0;
          }
        }
        if (mon === 'USD') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? GFIUniversal.MAX_SUM_USD || 0 : GFIUniversal.MIN_SUM_VI_USD || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? GFIUniversal.MAX_SUM_USD || 0 : GFIUniversal.MIN_SUM_MR_USD || 0;
          }
        }
        break;
      case 'GFI':
        if (mon === 'MXN') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? GFCUniversal.MAX_SUM_MXN || 0 : GFCUniversal.MIN_SUM_VI_MXN || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? GFCUniversal.MAX_SUM_MXN || 0 : GFCUniversal.MIN_SUM_MR_MXN || 0;
          }
        }
        if (mon === 'USD') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? GFCUniversal.MAX_SUM_USD || 0 : GFCUniversal.MIN_SUM_VI_USD || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? GFCUniversal.MAX_SUM_USD || 0 : GFCUniversal.MIN_SUM_MR_USD || 0;
          }
        }
        break;
      case 'GFI':
        if (mon === 'MXN') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? GFHUniversal.MAX_SUM_MXN || 0 : GFHUniversal.MIN_SUM_VI_MXN || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? GFCUniversal.MAX_SUM_MXN || 0 : GFHUniversal.MIN_SUM_MR_MXN || 0;
          }
        }
        if (mon === 'USD') {
          if (opciones === 'VIDA INTELIGENTE') {
            return need === 'MAX' ? GFHUniversal.MAX_SUM_USD || 0 : GFHUniversal.MIN_SUM_VI_USD || 0;
          }
          if (opciones === 'MI PROYECTO R') {
            return need === 'MAX' ? GFHUniversal.MAX_SUM_USD || 0 : GFHUniversal.MIN_SUM_MR_USD || 0;
          }
        }
        break;
    }
    return 0;
  }

  obtenerSumaAsegurada(cobertura: string, sumaAsegurada: number) {
    let sumAseg = 0;
    switch (cobertura) {
      case 'Ba':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, BAS);
        break;
      case 'BI':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, BI);
        break;
      case 'CC':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, CC);
        break;
      case 'PAI':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PAI);
        break;
      case 'DI':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, DI);
        break;
      case 'DIPO':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, DIPO);
        break;
      case 'DIPOC':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, DIPOC);
        break;
      case 'IAC':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, IAC);
        break;
      case 'PAE':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PAE);
        break;
      case 'GF':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, GF);
        break;
      case 'MU':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, MU);
        break;
      case 'CDC':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, CDC_MU);
        break;
      case 'PlanTmp':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PLAN_TEMP);
        break;
      case 'PlanTmpIAC':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PLAN_TEMP_IAC);
        break;
      case 'PlanTmpPAE':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PLAN_TEMP_PAE);
        break;
      case 'PlanTmpCC':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PLAN_TEMP_CC);
        break;
      case 'PlanTmpDI':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PLAN_TEMP_DI);
        break;
      case 'PlanTmpDIPO':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PLAN_TEMP_DIPO);
        break;
      case 'PlanTmpDIPOC':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PLAN_TEMP_DIPOC);
        break;
      case 'PlanTmpPAI':
        sumAseg = this.validarSumasAseguradas(sumaAsegurada, PLAN_TEMP_PAI);
        break;
    }
    return sumAseg;
  }


  private validarSumasAseguradas(sumaAsegurada: number, cober: CoverageConstants) {
    let sumAseg = 0;
    let sumaMin = 0, sumaMax = 0;
    if (this.moneda === 'MXN') {
      sumaMin = cober.MIN_SUM_MXN;
      sumaMax = cober.MAX_SUM_MXN;
    } else if (this.moneda === 'USD') {
      sumaMin = cober.MIN_SUM_USD;
      sumaMax = cober.MAX_SUM_USD;
    } else {
      sumaMin = cober.MIN_SUM_UDI;
      sumaMax = cober.MAX_SUM_UDI;
    }
    if (sumaAsegurada >= sumaMin && sumaAsegurada <= sumaMax) {
      sumAseg = sumaAsegurada;
    } else {
      if (sumaAsegurada < sumaMin) {
        sumAseg = sumaMin;
      } else if (sumaAsegurada > sumaMax) {
        sumAseg = sumaMax;
      }
    }
    return sumAseg;
  }
}
