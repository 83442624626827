import { Component, OnInit } from '@angular/core';

/**
 * Componente enccargado de manejar la necesidad de educación
 */
@Component({
  selector: 'cvida-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.sass']
})
export class EducationComponent implements OnInit {
  /**
   * Pregunta uno activa
   * @property
   * @type [boolean]
   */
  isAskOneActive: boolean;

  /**
   * Lifecycle hook de onInit
   * @method
   */
  ngOnInit(): void {
    this.isAskOneActive = true;
  }

  /**
   * Cambia la pregunta activa
   * @method
   * @param isEdad [boolean]
   */
  changeQuestion(isEdad: boolean): void {
    if (isEdad !== this.isAskOneActive) {
      this.isAskOneActive = isEdad;
    }
  }

}
