import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MatTabsModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TextMaskModule } from 'angular2-text-mask';

// Componente
import { ProfilingComponent } from '@components/profiling/profiling.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    TextMaskModule,
    MatTabsModule,
    CollapseModule,
    ReactiveFormsModule,
    CarouselModule.forRoot(),
    SharedModule,
    MatCheckboxModule,
    FormsModule
  ],
  declarations: [
    ProfilingComponent
  ],
  exports: [
    ProfilingComponent
  ]
})
export class ProfilingModule { }
