import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ROUTE_NAMING } from '@constants/index';
import { RolesGuard } from '@app/guards/roles/roles.guard';
import { OfflineGuard } from '@app/guards/offline/offline.guard';
import { ShellComponent } from '@shell/shell.component';
import { CotizadoresComponent } from '@shell/cotizadores/cotizadores.component';
import { BrowserGuard } from '@guards/browser/browser.guard';
import { RouteGuard } from '@guards/route/route.guard';
import {
  ProspectusDataComponent,
  NecessityComponent,
  BuildPlanComponent,
  ProposalsComponent,
  GuaranteedValuesComponent,
  LandingComponent,
  RequirementsCoverageComponent,
  DownloadPlansComponent,
  ProfilingComponent,
  SurplusComponent
} from '@components/index';


/**
 * Ruta Principal
 * @const
 */
const principal = ROUTE_NAMING.PRINCIPAL;
/**
 * Ruta PASO1 (Datos de prospecto)
 * @const
 */
const PASO1 = ROUTE_NAMING.PASO1;
/**
 * Ruta PASO2A (Arma tu plan)
 * @const
 */
const PASO2A = ROUTE_NAMING.PASO2A;
/**
 * Ruta PASO2B (Identifica tus necesidades)
 * @const
 */
const PASO2B = ROUTE_NAMING.PASO2B;
/**
 * Ruta PASO3 (Propuestas)
 * @const
 */
const PASO3 = ROUTE_NAMING.PASO3;
/**
 * Ruta PASO4 (Valores Garantizados)
 * @const
 */
const PASO4 = ROUTE_NAMING.PASO4;
/**
 * Ruta Descarga de planes
 * @const
 */
const PLAZOPAGO_CONFIG = ROUTE_NAMING.PLAZOPAGO_CONFIG;
const REQUISITOS_ASE = ROUTE_NAMING.REQUISITOS;
const TARIFA = ROUTE_NAMING.TARIFAS;
const landing = ROUTE_NAMING.LANDING;
const ProTGT = ROUTE_NAMING.PROTGT;
const ALIADOS = ROUTE_NAMING.ALIADOS;
const AXAPARATODOS = ROUTE_NAMING.AXAPARATODOS;
const UNIVERSALES = ROUTE_NAMING.UNIVERSALES;
const PERFILAMIENTO = ROUTE_NAMING.PERFILAMIENTO;
const PRIMASEX = ROUTE_NAMING.PASO4UNI;
const VALORESPROY = ROUTE_NAMING.PASO5UNI;


const ShellRoutes: Routes = [
  {
    path: principal,
    component: ShellComponent,
    children: [
      { path: '', redirectTo: landing, pathMatch: 'full' },
      {
        path: landing,
        component: LandingComponent,
        canActivate: [BrowserGuard]
      },
      { path: PLAZOPAGO_CONFIG, component: DownloadPlansComponent },
      {
        path: ProTGT,
        component: CotizadoresComponent,
        children: [
          {
            path: PASO1,
            component: ProspectusDataComponent,
            canActivate: [BrowserGuard, OfflineGuard]
          },
          {
            path: PASO2A,
            component: BuildPlanComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: PASO2B,
            component: NecessityComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: PASO3,
            component: ProposalsComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: PASO4,
            component: GuaranteedValuesComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: REQUISITOS_ASE,
            component: RequirementsCoverageComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: '**',
            redirectTo: PASO1
          }
        ]
      },
      {
        path: ALIADOS,
        component: CotizadoresComponent,
        children: [
          {
            path: PASO1,
            component: ProspectusDataComponent,
            canActivate: [RolesGuard, BrowserGuard, OfflineGuard]
          },
          {
            path: PASO2A,
            component: BuildPlanComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: PASO2B,
            component: NecessityComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: PASO3,
            component: ProposalsComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: PASO4,
            component: GuaranteedValuesComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: REQUISITOS_ASE,
            component: RequirementsCoverageComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: '**',
            redirectTo: PASO1
          }
        ]
      },
      {
        path: AXAPARATODOS,
        component: CotizadoresComponent,
        children: [
          {
            path: PASO1,
            component: ProspectusDataComponent,
            canActivate: [RolesGuard, BrowserGuard, OfflineGuard]
          },
          {
            path: PASO2A,
            component: BuildPlanComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: TARIFA,
            component: GuaranteedValuesComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: REQUISITOS_ASE,
            component: RequirementsCoverageComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: '**',
            redirectTo: PASO1
          }
        ]
      },
      {
        path: UNIVERSALES,
        component: CotizadoresComponent,
        children: [
          {
            path: PASO1,
            component: ProspectusDataComponent,
            canActivate: [BrowserGuard, OfflineGuard]
          },
          {
            path: PASO2A,
            component: BuildPlanComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: PERFILAMIENTO,
            component: ProfilingComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: PRIMASEX,
            component: SurplusComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: VALORESPROY,
            component: GuaranteedValuesComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: REQUISITOS_ASE,
            component: RequirementsCoverageComponent,
            canActivate: [RouteGuard, OfflineGuard]
          },
          {
            path: '**',
            redirectTo: PASO1
          }

        ]
      }
    ]
  },
  {
    path: '**',
    redirectTo: principal
  }
];

/**
 * @description Rutas del shell de la cotización.
 */
@NgModule({
  imports: [RouterModule.forChild(ShellRoutes)],
  exports: [RouterModule]
})
export class ShellRoutingModule {}
