/** Angular Core */
import { Injectable } from '@angular/core';
/** Models  */
import { Proposal } from '@models/proposal/proposal.interface';
/** Services */
import { FactoresService } from '@services/factores/factores.service';
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';
// import { ValoresGarantizadosService } from '@services/valoresGarantizados/valores-garantizados.service';
import {
  BAService,
  BIService,
  PAIService,
  CCService,
  IACService,
  PAEService,
  DIService,
  DIPOService,
  DIPOCService,
  PCDService,
  GFService,
  TUTORService,
  EFIService,
} from '@services/coverages';
/** Rxjs  */
import 'rxjs/Observable';


/**
 * Servicio encargado de generar las propuestas, tanto como la elegida como las sugeridas.
 *  Así como detectar y retornar la que es finalmente elegida por el prospecto.
 * @export
 * @class PropuestasService
 */
@Injectable({
  providedIn: 'root'
})
export class PropuestasService {

  /**
   *Crea una instancia de PropuestasService e inicializa las variables de coberturas.
  * @param {ProtectionPlansGeneratorService} protectionPlansG
  * @param {BAService} BA
  * @param {BIService} BI
  * @param {PAIService} PAI
  * @param {CCService} CC
  * @param {IACService} IAC
  * @param {PAEService} PAE
  * @param {DIService} DI
  * @param {DIPOService} DIPO
  * @param {DIPOCService} DIPOC
  * @param {PCDService} PCD
  * @param {GFService} GF
  * @param {FactoresService} factores
  * @memberof PropuestasService
  */
  constructor(
 //   private valoresGarantizadosService: ValoresGarantizadosService,
    private protectionPlansG: ProtectionPlansGeneratorService,
    private BA: BAService,
    private BI: BIService,
    private PAI: PAIService,
    private CC: CCService,
    private IAC: IACService,
    private PAE: PAEService,
    private DI: DIService,
    private DIPO: DIPOService,
    private DIPOC: DIPOCService,
    private PCD: PCDService,
    private GF: GFService,
    private TUTOR: TUTORService,
    private EFI: EFIService,
    private factores: FactoresService    
  ) {
  }

  /**
   *Inicializa los plazos posibles tomando como referencia los plazos sugeridos,
   * @returns plazosPosibles
   */
  initPlazos() {

    const plazosPosibles = this.protectionPlansG.plazosSugeridos.value;

    return plazosPosibles;
  }


  /**
   * Crea la propuesta elegida (propuesta por defecto).
   * @method
   * @returns {Proposal}
   */
  createProposal_Elegida(): Proposal {

    const propuesta: Proposal = {
      BA: {
        MONEDA1: this.BA.primaAnual_UDI.asObservable(),
        MONEDA2: this.BA.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.BA.sumaAsegurada_UDI.asObservable()
      },
      BI: {
        MONEDA1: this.BI.primaAnual_UDI.asObservable(),
        MONEDA2: this.BI.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.BI.sumaAsegurada_UDI.asObservable()
      },
      PAI: {
        MONEDA1: this.PAI.primaAnual_UDI.asObservable(),
        MONEDA2: this.PAI.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.PAI.sumaAsegurada_UDI.asObservable()
      },
      CC: {
        MONEDA1: this.CC.primaAnual_UDI.asObservable(),
        MONEDA2: this.CC.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.CC.sumaAsegurada_UDI.asObservable()
      },
      IAC: {
        MONEDA1: this.IAC.primaAnual_UDI.asObservable(),
        MONEDA2: this.IAC.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.IAC.sumaAsegurada_UDI.asObservable()
      },
      PAE: {
        MONEDA1: this.PAE.primaAnual_UDI.asObservable(),
        MONEDA2: this.PAE.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.PAE.sumaAsegurada_UDI.asObservable()
      },
      DI: {
        MONEDA1: this.DI.primaAnual_UDI.asObservable(),
        MONEDA2: this.DI.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DI.sumaAsegurada_UDI.asObservable()
      },
      DIPO: {
        MONEDA1: this.DIPO.primaAnual_UDI.asObservable(),
        MONEDA2: this.DIPO.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DIPO.sumaAsegurada_UDI.asObservable()
      },
      DIPOC: {
        MONEDA1: this.DIPOC.primaAnual_UDI.asObservable(),
        MONEDA2: this.DIPOC.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DIPOC.sumaAsegurada_UDI.asObservable()
      },
      PCD: {
        MONEDA1: this.PCD.primaAnual_UDI.asObservable(),
        MONEDA2: this.PCD.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.PCD.sumaAsegurada_UDI.asObservable()
      },
      TUTOR: {
        MONEDA1: this.TUTOR.primaAnual_UDI.asObservable(),
        MONEDA2: this.TUTOR.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.TUTOR.sumaAsegurada_UDI.asObservable()
      },
      EFI: {
        MONEDA1: this.EFI.primaAnual_UDI.asObservable(),
        MONEDA2: this.EFI.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.EFI.sumaAsegurada_UDI.asObservable()
      },
      GF: {
        MONEDA1: this.GF.primaAnual_UDI.asObservable(),
        MONEDA2: this.GF.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.GF.sumaAsegurada_UDI.asObservable()
      }
    };

    return propuesta;
  }

  /**
   * Crea la propuesta sugerida n. 1
   * @method
   * @returns {Proposal}
   */
  createProposal_Sugerida1(): Proposal {

    const propuesta: Proposal = {
      BA: {
        MONEDA1: this.BA.primaAnual_UDI_Sugerida1.asObservable(),
        MONEDA2: this.BA.primaAnual_MXN_Sugerida1.asObservable(),
        SUMA_ASEGURADA: this.BA.sumaAsegurada_UDI.asObservable()
      },
      BI: {
        MONEDA1: this.BI.primaAnual_UDI_Sugerida1.asObservable(),
        MONEDA2: this.BI.primaAnual_MXN_Sugerida1.asObservable(),
        SUMA_ASEGURADA: this.BI.sumaAsegurada_UDI.asObservable()
      },
      PAI: {
        MONEDA1: this.PAI.primaAnual_UDI_Sugerida1.asObservable(),
        MONEDA2: this.PAI.primaAnual_MXN_Sugerida1.asObservable(),
        SUMA_ASEGURADA: this.PAI.sumaAsegurada_UDI.asObservable()
      },
      CC: {
        MONEDA1: this.CC.primaAnual_UDI_Sugerida1.asObservable(),
        MONEDA2: this.CC.primaAnual_MXN_Sugerida1.asObservable(),
        SUMA_ASEGURADA: this.CC.sumaAsegurada_UDI.asObservable()
      },
      IAC: {
        MONEDA1: this.IAC.primaAnual_UDI_Sugerida1.asObservable(),
        MONEDA2: this.IAC.primaAnual_MXN_Sugerida1.asObservable(),
        SUMA_ASEGURADA: this.IAC.sumaAsegurada_UDI.asObservable()
      },
      PAE: {
        MONEDA1: this.PAE.primaAnual_UDI_Sugerida1.asObservable(),
        MONEDA2: this.PAE.primaAnual_MXN_Sugerida1.asObservable(),
        SUMA_ASEGURADA: this.PAE.sumaAsegurada_UDI.asObservable()
      },
      DI: {
        MONEDA1: this.DI.primaAnual_UDI.asObservable(),
        MONEDA2: this.DI.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DI.sumaAsegurada_UDI.asObservable()
      },
      DIPO: {
        MONEDA1: this.DIPO.primaAnual_UDI.asObservable(),
        MONEDA2: this.DIPO.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DIPO.sumaAsegurada_UDI.asObservable()
      },
      DIPOC: {
        MONEDA1: this.DIPOC.primaAnual_UDI.asObservable(),
        MONEDA2: this.DIPOC.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DIPOC.sumaAsegurada_UDI.asObservable()
      },
      PCD: {
        MONEDA1: this.PCD.primaAnual_UDI_Sugerida1.asObservable(),
        MONEDA2: this.PCD.primaAnual_MXN_Sugerida1.asObservable(),
        SUMA_ASEGURADA: this.PCD.sumaAsegurada_UDI.asObservable()
      },
      TUTOR: {
        // MONEDA1: this.TUTOR.primaAnual_UDI_Sugerida1.asObservable(),
        // MONEDA2: this.TUTOR.primaAnual_MXN_Sugerida1.asObservable(),
        MONEDA1:this.TUTOR.sumaAsegurada_UDI.asObservable(),
        MONEDA2:this.TUTOR.sumaAsegurada_UDI.asObservable(),
        SUMA_ASEGURADA: this.TUTOR.sumaAsegurada_UDI.asObservable()
      },
      EFI: {
        MONEDA1: this.EFI.primaAnual_UDI.asObservable(),
        MONEDA2: this.EFI.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.EFI.sumaAsegurada_UDI.asObservable()
      },
      GF: {
        MONEDA1: this.GF.primaAnual_UDI_Sugerida1.asObservable(),
        MONEDA2: this.GF.primaAnual_MXN_Sugerida1.asObservable(),
        SUMA_ASEGURADA: this.GF.sumaAsegurada_UDI.asObservable()
      }
    };

    return propuesta;
  }

  /**
   * Crea la propuesta sugerida n. 2
   * @method
   * @returns {Proposal}
   */
  createProposal_Sugerida2(): Proposal {

    const propuesta: Proposal = {
      BA: {
        MONEDA1: this.BA.primaAnual_UDI_Sugerida2.asObservable(),
        MONEDA2: this.BA.primaAnual_MXN_Sugerida2.asObservable(),
        SUMA_ASEGURADA: this.BA.sumaAsegurada_UDI.asObservable()
      },
      BI: {
        MONEDA1: this.BI.primaAnual_UDI_Sugerida2.asObservable(),
        MONEDA2: this.BI.primaAnual_MXN_Sugerida2.asObservable(),
        SUMA_ASEGURADA: this.BI.sumaAsegurada_UDI.asObservable()
      },
      PAI: {
        MONEDA1: this.PAI.primaAnual_UDI_Sugerida2.asObservable(),
        MONEDA2: this.PAI.primaAnual_MXN_Sugerida2.asObservable(),
        SUMA_ASEGURADA: this.PAI.sumaAsegurada_UDI.asObservable()
      },
      CC: {
        MONEDA1: this.CC.primaAnual_UDI_Sugerida2.asObservable(),
        MONEDA2: this.CC.primaAnual_MXN_Sugerida2.asObservable(),
        SUMA_ASEGURADA: this.CC.sumaAsegurada_UDI.asObservable()
      },
      IAC: {
        MONEDA1: this.IAC.primaAnual_UDI_Sugerida2.asObservable(),
        MONEDA2: this.IAC.primaAnual_MXN_Sugerida2.asObservable(),
        SUMA_ASEGURADA: this.IAC.sumaAsegurada_UDI.asObservable()
      },
      PAE: {
        MONEDA1: this.PAE.primaAnual_UDI_Sugerida2.asObservable(),
        MONEDA2: this.PAE.primaAnual_MXN_Sugerida2.asObservable(),
        SUMA_ASEGURADA: this.PAE.sumaAsegurada_UDI.asObservable()
      },
      DI: {
        MONEDA1: this.DI.primaAnual_UDI.asObservable(),
        MONEDA2: this.DI.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DI.sumaAsegurada_UDI.asObservable()
      },
      DIPO: {
        MONEDA1: this.DIPO.primaAnual_UDI.asObservable(),
        MONEDA2: this.DIPO.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DIPO.sumaAsegurada_UDI.asObservable()
      },
      DIPOC: {
        MONEDA1: this.DIPOC.primaAnual_UDI.asObservable(),
        MONEDA2: this.DIPOC.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.DIPOC.sumaAsegurada_UDI.asObservable()
      },
      PCD: {
        MONEDA1: this.PCD.primaAnual_UDI_Sugerida2.asObservable(),
        MONEDA2: this.PCD.primaAnual_MXN_Sugerida2.asObservable(),
        SUMA_ASEGURADA: this.PCD.sumaAsegurada_UDI.asObservable()
      },
      TUTOR: {
        // MONEDA1: this.TUTOR.primaAnual_UDI_Sugerida1.asObservable(),
        // MONEDA2: this.TUTOR.primaAnual_MXN_Sugerida1.asObservable(),
        MONEDA1:this.TUTOR.sumaAsegurada_UDI.asObservable(),
        MONEDA2:this.TUTOR.sumaAsegurada_UDI.asObservable(),
        SUMA_ASEGURADA: this.TUTOR.sumaAsegurada_UDI.asObservable()
      },
      EFI: {
        MONEDA1: this.EFI.primaAnual_UDI.asObservable(),
        MONEDA2: this.EFI.primaAnual_MXN.asObservable(),
        SUMA_ASEGURADA: this.EFI.sumaAsegurada_UDI.asObservable()
      },
      GF: {
        MONEDA1: this.GF.primaAnual_UDI_Sugerida2.asObservable(),
        MONEDA2: this.GF.primaAnual_MXN_Sugerida2.asObservable(),
        SUMA_ASEGURADA: this.GF.sumaAsegurada_UDI.asObservable()
      }
    };

    return propuesta;
  }

  /**
   *Método que detecta la propuesta elegida y la retorna como valor.
   * @method
   * @param {number} numeroPropuesta
   */
  elegirPropuesta(numeroPropuesta: number) {
    switch (numeroPropuesta) {
      case 1:
        this.factores.factoresActual = this.factores.factoresCoberturaElegido;
        this.factores.factoresValoresActual = this.factores.factoresValoresElegido;
      break;
      case 2:
        this.factores.factoresActual = this.factores.factoresCoberturaSugerido1;
        this.factores.factoresValoresActual = this.factores.factoresValoresSugerido1;
      break;
      case 3:
        this.factores.factoresActual = this.factores.factoresCoberturaSugerido2;
        this.factores.factoresValoresActual = this.factores.factoresValoresSugerido2;
      break;
    }
  }

}
