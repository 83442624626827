import { Component, OnInit, OnDestroy } from '@angular/core';

import { CalculosService } from '@services/calculos/calculos.service';
import { Subject } from 'rxjs';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { BA } from '@constants/coverages/coverages.constants';

import { DEFAULT_TERMS, PROTECTION_TERMS } from '@constants/plan-options/protection-plans.constant';
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

/**
 * Componente encargado de la necesidad de familia
 */
@Component({
  selector: 'cvida-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.sass']
})

export class FamilyComponent implements OnInit, OnDestroy {
  /**
   * Unsuscribable para finalizar suscribers
   * @property
   * @type [Subject]
   */
  private ngUnsuscribe = new Subject();

  /**
   *
   * Pregunta uno activa
   * @property
   * @type [boolean]
   */
  isAskOneActive: boolean;

  /**
   *
   * Suma asegurada del input
   * @property
   * @type [number]
   */
  sumaAsegurada: number;

  /**
   * Prima anual del input
   * @property
   * @type [number]
   */
  primaAnual: number;

  /**
   * Plazos asegurados
   *
   * @type {number}
   * @memberof FamilyComponent
   */
  plazosAsegurados: number;

  /**
   * Mascara de cantidades númericas con simbolo $
   *
   * @memberof FamilyComponent
   */
  cantidadMask = createNumberMask({
    prefix: '$ ',
    thousandsSeparatorSymbol: ',',
    suffix: '',
    allowDecimal: false
  });

  /**
   * Constructor
   * @param calculos
   * @param opciones
   */
  constructor(
    private protectionPlansGenerator: ProtectionPlansGeneratorService,
    private calculos: CalculosService,
    private opciones: OpcionesPlanService
  ) {
    this.initConstructor();
  }

  /**
   * Inicializa los factores
   * @method
   */
  initConstructor(): void {
    this.opciones.tipoPlan = 'TEMPORAL';
    this.opciones.plazoPago.next(DEFAULT_TERMS.filter(res => res.plan === this.opciones.tipoPlan)[0].default || 0);
    this.plazosAsegurados = PROTECTION_TERMS.filter(
      plan => plan.plan === this.opciones.tipoPlan
    )[0].safeTerm;
    this.protectionPlansGenerator.productsGenerator(this.opciones.tipoPlan);
    this.protectionPlansGenerator.generatePlansFront(this.opciones.tipoPlan, this.opciones.plazoPago.value, this.opciones.moneda);
    this.protectionPlansGenerator.currenciesGenerator(this.opciones.moneda, this.opciones.tipoPlan);
    this.opciones.plazosAsegurados = this.plazosAsegurados;

    Promise.all([this.opciones.generarFactores(true), this.opciones.generarFactoresValoresG()])
      .then(() => {
        this.calculos.resetCalculus();
        this.calculos.COBERTURA_BASICA(BA.MIN_SUM_UDI);
        this.calculos.calcularPagoDividido();
    });
  }

  /**
   * Lifecycle hook de OnInit
   * @method
   */
  ngOnInit() {
    this.isAskOneActive = true;
    this.initCalculus();
  }

  /**
   * Lifecycle hood de OnDestroy
   * @method
   */
  ngOnDestroy(): void {
    this.ngUnsuscribe.unsubscribe();
  }

  /**
   * Cambia la pregunta seleccionada
   * @method
   * @param isEdad
   */
  changeQuestion(isEdad: boolean) {
    if (isEdad !== this.isAskOneActive) {
      this.isAskOneActive = isEdad;
    }
  }

  /**
   * Inicia los cálculos
   * @method
   */
  initCalculus(): void {
    this.calculos.BASICA_sumaAseguradaPesos.takeUntil(
      this.ngUnsuscribe
    ).subscribe(value => (this.sumaAsegurada = value));
    this.calculos.BASICA_primaAnualPesos.takeUntil(
      this.ngUnsuscribe
    ).subscribe(value => (this.primaAnual = value));
  }

  /**
   * Cambia la suma asegurada
   * @method
   */
  changeSumaAsegurada(): void {
    this.calculos.COBERTURA_BASICA_SEGMON(
      Number(this.deleteCurrencySigns(String(this.sumaAsegurada)))
    );
  }

  /**
   * Cambia la prima anual
   * @method
   */
  changePrimaAnual(): void {
    this.calculos.COBERTURA_BASICA_INVERSA(
      Number(this.deleteCurrencySigns(String(this.primaAnual)))
    );
  }

  /**
   * Retorna un texto sin carácteres de dinero
   * @param text
   * @return string
   */
  deleteCurrencySigns(text: string): string {
    return String(text).replace(/\,/g, '').replace('$ ', '');
  }

}
