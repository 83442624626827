import { Banda } from './../../models/universales/banda';
import { DescuentoEdad } from './../../models/universales/descuentoEdad';
import { FrecuenciaPago } from './../../models/universales/frecuencia-pago';

export const BANDAS: Banda[] = [
        { producto: 'VI', moneda: 'MXN', riesgo: 'N', banda: 'B1', rango_min: 350000, rango_max: 749999, recargo_fijo: 750 },
        { producto: 'VI', moneda: 'MXN', riesgo: 'N', banda: 'B2', rango_min: 750000, rango_max: 1499999, recargo_fijo: 750 },
        { producto: 'VI', moneda: 'MXN', riesgo: 'N', banda: 'B3', rango_min: 1500000, rango_max: 999999999, recargo_fijo: 900 },
        { producto: 'VI', moneda: 'MXN', riesgo: 'P', banda: 'B4', rango_min: 2000000, rango_max: 999999999, recargo_fijo: 900 },
        { producto: 'VI', moneda: 'USD', riesgo: 'N', banda: 'B1', rango_min: 35000, rango_max: 74999, recargo_fijo: 63 },
        { producto: 'VI', moneda: 'USD', riesgo: 'N', banda: 'B2', rango_min: 75000, rango_max: 149999, recargo_fijo: 63 },
        { producto: 'VI', moneda: 'USD', riesgo: 'N', banda: 'B3', rango_min: 150000, rango_max: 999999999, recargo_fijo: 92 },
        { producto: 'VI', moneda: 'USD', riesgo: 'P', banda: 'B4', rango_min: 200000, rango_max: 999999999, recargo_fijo: 92 },
        { producto: 'PPR', moneda: 'MXN', riesgo: 'N', banda: 'B1', rango_min: 300000, rango_max: 749999, recargo_fijo: 1100 },
        { producto: 'PPR', moneda: 'MXN', riesgo: 'N', banda: 'B2', rango_min: 750000, rango_max: 1499999, recargo_fijo: 1100 },
        { producto: 'PPR', moneda: 'MXN', riesgo: 'N', banda: 'B3', rango_min: 1500000, rango_max: 999999999, recargo_fijo: 1200 },
        { producto: 'PPR', moneda: 'MXN', riesgo: 'P', banda: 'B4', rango_min: 2000000, rango_max: 999999999, recargo_fijo: 1200 },
        { producto: 'PPR', moneda: 'USD', riesgo: 'N', banda: 'B1', rango_min: 30000, rango_max: 74999, recargo_fijo: 110 },
        { producto: 'PPR', moneda: 'USD', riesgo: 'N', banda: 'B2', rango_min: 75000, rango_max: 149999, recargo_fijo: 110 },
        { producto: 'PPR', moneda: 'USD', riesgo: 'N', banda: 'B3', rango_min: 150000, rango_max: 999999999, recargo_fijo: 120 },
        { producto: 'PPR', moneda: 'USD', riesgo: 'P', banda: 'B4', rango_min: 200000, rango_max: 999999999, recargo_fijo: 120 }
    ];

export const DESCUENTO_EDAD: DescuentoEdad[] = [
    {producto: 'VI', moneda: 'MXN', riesgo: 'N', descuento_mujer: 3, descuento_no_fumador: 2},
    {producto: 'VI', moneda: 'MXN', riesgo: 'P', descuento_mujer: 3, descuento_no_fumador: 4},
    {producto: 'VI', moneda: 'USD', riesgo: 'N', descuento_mujer: 3, descuento_no_fumador: 2},
    {producto: 'VI', moneda: 'USD', riesgo: 'P', descuento_mujer: 3, descuento_no_fumador: 4},
    {producto: 'PPR', moneda: 'MXN', riesgo: 'N', descuento_mujer: 2, descuento_no_fumador: 2},
    {producto: 'PPR', moneda: 'MXN', riesgo: 'P', descuento_mujer: 2, descuento_no_fumador: 2},
    {producto: 'PPR', moneda: 'USD', riesgo: 'N', descuento_mujer: 2, descuento_no_fumador: 2},
    {producto: 'PPR', moneda: 'USD', riesgo: 'P', descuento_mujer: 2, descuento_no_fumador: 2}
];

export const FRECUENCIA_PAGO: FrecuenciaPago[] = [
    {nombre: 'ANUAL', primaAnual: 0, recargoFijo: 0, frecuencia: 1, primaFormaPago: 0, seleccionado: true},
    {nombre: 'SEMESTRAL', primaAnual: 0, recargoFijo: 0, frecuencia: 2, primaFormaPago: 0, seleccionado: false},
    {nombre: 'TRIMESTRAL', primaAnual: 0, recargoFijo: 0, frecuencia: 4, primaFormaPago: 0, seleccionado: false},
    {nombre: 'MENSUAL', primaAnual: 0, recargoFijo: 0, frecuencia: 12, primaFormaPago: 0, seleccionado: false}
];

export const VALOR_RESCATE: number[] = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1];
