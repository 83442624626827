import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { formatCurrency } from '@angular/common';

import { Gender, Fumador } from '@app/models';
import { PlanType } from '@app/constants/plan-options/plan-types.constant.pre';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { NetworkStateService } from '@services/networkState/network-state.service';
import { PaymentFrequencyService } from '@services/paymentFrequency/payment-frequency.service';
import { CalculosService } from '@services/calculos/calculos.service';
import { GeneradorTablaValoresService } from '@services/generadorTablaValores/generador-tabla-valores.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import * as moment from 'moment';

declare let jsPDF;

/***
 * Servicio que maneja el PDF de Tarifas (Masivo)
 * @export
 * @class PdfTarifasService
 */
@Injectable({
  providedIn: 'root'
})
export class PdfTarifasService {
  // Colores rosas -> https://coolors.co/f06192-f97ca7-ff9ebf-ffb7d0-ffe8ef

  /**
   * Instancia de jsPDF
   * @property
   * @private
   * @memberof PdfTarifasService
   */
  private pdf;

  /**
   * Creates an instance of PdfTarifasService.
   * @param {OpcionesPlanService} opciones
   * @param {AuthenticationService} auth
   * @param {ProspectoService} prospecto
   * @param {NetworkStateService} network
   * @param {BaseDatosService} db
   * @param {PaymentFrequencyService} paymentFrecuency
   * @param {CalculosService} calculos
   * @param {string} locale
   * @param {GeneradorTablaValoresService} tabla
   * @param {DeviceTypeService} device
   * @memberof PdfTarifasService
   */
  constructor(
    private opciones: OpcionesPlanService,
    private auth: AuthenticationService,
    private prospecto: ProspectoService,
    private network: NetworkStateService,
    private paymentFrecuency: PaymentFrequencyService,
    private calculos: CalculosService,
    @Inject(LOCALE_ID) private locale: string,
    private tabla: GeneradorTablaValoresService,
    private device: DeviceTypeService
  ) { }

  /**
   * Inicializa el proceso de crear un PDF
   *
   * @private
   * @returns {Promise<void>}
   * @memberof PdfTarifasService
   */
  private async initPDF(folio: string): Promise<void> {
    this.pdf = new jsPDF('l', 'mm', 'letter', true);

    try {
      await this.setHeader();
      this.setBody(folio);
      this.setTable();
    } catch (error) { }

  }

  /**
   * Establece header del pdf (primera hoja)
   *
   * @private
   * @returns {Promise<boolean>}
   * @memberof PdfTarifasService
   */
  private setHeader(): Promise<boolean> {
    return new Promise(async (res, rej) => {
      try {
        const img = new Image();

          img.src = 'assets/images/Back_slash2.png';

        this.pdf.addImage(img, 'PNG', 0, 0, 100, 100, undefined, 'FAST');
      } catch (error) {
        rej(false);
        console.log(error);
      }

      this.writeVersion();
      this.writeProducto(this.opciones.tipoPlan);
      res(true);
    });
  }

  /**
   * Establece el cuerpo del pdf (primera hoja)
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private setBody(folio: string) {
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.rect(10, 50, 255, 135, 'FD');

    this.datosDelAgente(folio);

    this.pdf.line(10, 83, 265, 83);

    this.datosDelProspecto();

    this.pdf.line(10, 112, 265, 112);

    this.caracteristicasDelPlan();

    this.detalleDePrimas(true);

    this.writeCaducidad();

    this.pdf.addPage();
  }

  /**
   * Genera los datos del agente
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private datosDelAgente(folio: string): void {
    // Primerafila
    // *Agente
    this.writeBoldedText('Agente: ', 20, 60);
    this.writeText(this.auth.user.getValue().name, 37, 60);

    // *MXN
    this.writeBoldedText('MX: ', 20, 65);
    this.writeText(this.auth.user.getValue().code, 29, 65);

    // *Correo electrónico
    this.writeBoldedText('Correo electrónico: ', 20, 70);
    this.writeText(this.auth.user.getValue().email, 60, 70);

    // *Conexión
    this.writeBoldedText('Conexión: ', 20, 75);
    this.writeText(
      this.network._state.getValue() ? 'ONLINE' : 'OFFLINE',
      42,
      75
    );

    // Segunda fila
    // *Fecha
    this.writeBoldedText('Fecha: ', 150, 60);
    this.writeText(
      `${moment().format('DD')} de ${moment().format(
        'MMMM'
      )} del ${moment().format('YYYY')}`,
      165,
      60
    );

    // *Folio
    this.writeBoldedText('Folio: ', 150, 65);
    this.writeText(folio, 163, 65);

    // *Rol
    this.writeBoldedText('Rol: ', 150, 70);
    this.writeText(folio, 160, 70);
  }

  /**
   * Genera los datos del prospecto
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private datosDelProspecto(): void {
    this.writeTitle('Tarifas', 20, 95);

    // *Género
    this.writeBoldedText('Género: ', 20, 105);
    this.writeText(
      this.prospecto.prospecto.getValue().gender === 'H'
        ? 'Hombres'
        : 'Mujeres',
      38,
      105
    );

    // *Hábito
    this.writeBoldedText('Hábito: ', 150, 105);
    this.writeText(
      this.habitoHandler(
        this.prospecto.prospecto.getValue().fumador,
        this.prospecto.prospecto.getValue().gender
      ),
      166,
      105
    );
  }

  /**
   * Retorna el texto según el hábito y el género
   * @method
   * @private
   * @param {Fumador} habito
   * @param {Gender} genero
   * @returns {string}
   * @memberof PdfTarifasService
   */
  private habitoHandler(habito: Fumador, genero: Gender): string {
    switch (true) {
      case habito === 'NO' && genero === 'H':
        return 'No fumadores';
      case habito === 'SI' && genero === 'H':
        return 'Fumadores';
      case habito === 'NO' && genero === 'M':
        return 'No fumadoras';
      case habito === 'SI' && genero === 'M':
        return 'Fumadoras';
      default:
        return '';
    }
  }

  /**
   * Genera las características del plan
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private caracteristicasDelPlan(): void {
    this.writeTitle('Caracteristicas del plan', 20, 125);

    // *Plan
    this.writeBoldedText('Plan: ', 20, 135);
    this.writeText(this.opciones.tipoPlan, 32, 135);

    // * Plazo de pagos
    this.writeBoldedText('Plazo de pagos: ', 20, 140);
    this.writeText(`${this.opciones.plazoPago.getValue()} año`, 54, 140);

    // *Moneda
    this.writeBoldedText('Moneda: ', 150, 135);
    this.writeText('Pesos', 169, 135);
  }

  /**
   * Genera detalles de las primas
   * @method
   * @private
   * @param {boolean} [firstPage=false]
   * @memberof PdfTarifasService
   */
  private detalleDePrimas(firstPage: boolean = false): void {
    if (!firstPage) {
      this.writeTitle(
        `Prima ${this.paymentFrecuency.frecuenciaPago
          .getValue()
          .toLocaleLowerCase()}`,
        220,
        12
      );
    }
    this.pdf.autoTable({
      startY: firstPage ? 145 : 15,
      tableWidth: firstPage ? 245 : undefined,
      headStyles: { fontSize: 10 },
      styles: { halign: 'center', lineColor: [223, 223, 223], lineWidth: 0.4 },
      columnStyles: !firstPage ? {
        0: { columnWidth: 50 },
        1: { columnWidth: 66 },
        2: { columnWidth: 66 },
        3: { columnWidth: 66 },
      } : undefined,
      head: this.generateHeaderTablePrimas(this.opciones.tipoPlan),
      body: this.generateBodyTablePrimas(this.opciones.tipoPlan),
      tableLineColor: [223, 223, 223],
      tableLineWidth: 0.4,
      bodyStyles: { fontSize: 10 },
      willDrawCell: data => {
        if (data.section === 'head') {
          switch (data.column.index) {
            case '0':
              this.prospecto.prospecto.getValue().gender === 'H'
                ? this.pdf.setFillColor(0, 0, 143)
                : this.pdf.setFillColor(240, 97, 146);
              break;
            case '1':
              this.prospecto.prospecto.getValue().gender === 'H'
                ? this.pdf.setFillColor(0, 173, 198)
                : this.pdf.setFillColor(249, 124, 167);
              break;
            case '2':
              this.prospecto.prospecto.getValue().gender === 'H'
                ? this.pdf.setFillColor(61, 189, 209)
                : this.pdf.setFillColor(255, 158, 191);
              break;
            case '3':
              this.prospecto.prospecto.getValue().gender === 'H'
                ? this.pdf.setFillColor(102, 206, 221)
                : this.pdf.setFillColor(255, 183, 208);
              break;
          }
        }
      }
    });
  }

  /**
   * Genera tabla de tarifas
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private setTable(): void {
    const head = [this.tabla.vistaHeader];
    const body = this.tabla.vistaVg.map(col => col.map(item => item.value));
    let edadColumna: string;

    this.pdf.autoTable({
      margin: {
        top: 50
      },
      tableLineColor: [223, 223, 223],
      tableLineWidth: 0.4,
      styles: { halign: 'center', lineColor: [223, 223, 223], lineWidth: 0.4 },
      columnStyles: {
        0: { columnWidth: 50 },
        1: { columnWidth: 66 },
        2: { columnWidth: 66 },
        3: { columnWidth: 66 },
      },
      head,
      body: [...body],
      createdCell: data => {
        if (data.section === 'head') {
          if (data.cell.styles.cellPadding < 4) {
            data.cell.styles.cellPadding = 4;
          }
        }
      },
      willDrawCell: data => {
        if (data.section === 'head') {
          switch (data.cell.raw) {
            case 'edad':
              this.prospecto.prospecto.getValue().gender === 'H'
                ? this.pdf.setFillColor(0, 0, 143)
                : this.pdf.setFillColor(240, 97, 146);
              data.cell.text = ['EDAD'];
              edadColumna = data.column.dataKey;
              data.cell.height += 3;
              break;
            case 'prima1':
              this.prospecto.prospecto.getValue().gender === 'H'
                ? this.pdf.setFillColor(0, 173, 198)
                : this.pdf.setFillColor(249, 124, 167);
              data.cell.text = [
                formatCurrency(
                  this.calculos.BASICA_sumaAseguradaMasiva_1.getValue(),
                  this.locale,
                  '$ ',
                  'MXN'
                )
              ];
              data.cell.height += 3;
              break;
            case 'prima2':
              this.prospecto.prospecto.getValue().gender === 'H'
                ? this.pdf.setFillColor(61, 189, 209)
                : this.pdf.setFillColor(255, 158, 191);
              data.cell.text = [
                formatCurrency(
                  this.calculos.BASICA_sumaAseguradaMasiva_2.getValue(),
                  this.locale,
                  '$ ',
                  'MXN'
                )
              ];
              data.cell.height += 3;
              break;
            case 'prima3':
              this.prospecto.prospecto.getValue().gender === 'H'
                ? this.pdf.setFillColor(102, 206, 221)
                : this.pdf.setFillColor(255, 183, 208);
              data.cell.text = [
                formatCurrency(
                  this.calculos.BASICA_sumaAseguradaMasiva_3.getValue(),
                  this.locale,
                  '$ ',
                  'MXN'
                )
              ];
              data.cell.height += 3;
              break;
          }
        } else {
          if (this.prospecto.prospecto.getValue().gender === 'H') {
            (data.row.index + 1) % 2 === 0
              ? this.pdf.setFillColor(255, 255, 255)
              : this.pdf.setFillColor(220, 247, 240);
          } else {
            (data.row.index + 1) % 2 === 0
              ? this.pdf.setFillColor(255, 255, 255)
              : this.pdf.setFillColor(255, 232, 239);
          }

          if (data.column.dataKey === edadColumna) {
            this.prospecto.prospecto.getValue().gender === 'H'
              ? this.pdf.setTextColor(0, 0, 143)
              : this.pdf.setTextColor(240, 97, 146);
          } else {
            data.cell.text = [
              formatCurrency(data.cell.text, this.locale, '$ ', 'MXN')
            ];
          }
        }
      },
      didDrawPage: data => {
        this.detalleDePrimas();
        this.writeCaducidad();
      }
    });
  }

  /**
   * Inicia la generación del PDF y lo guarda
   * @method
   * @memberof PdfTarifasService
   */
  async generatePDF(folio: string) {
    await this.initPDF(folio);
    if ((this.device.getDeviceType() === 'iPad' || this.device.getDeviceType() === 'iPhone') && this.device.isSafari()) {
      const blob = URL.createObjectURL(this.pdf.output('blob'));
      window.open(blob);
    } else {
      this.pdf.save('Cotizacion');
    }
    // if ((this.device.getDeviceType() === 'iPad' || this.device.getDeviceType() === 'iPhone') && this.device.isSafari()) {
    //   this.initPDF(folio).then(() => {
    //     const blob = URL.createObjectURL(this.pdf.output('blob'));
    //     window.open(blob);
    //   });
    // } else {
    //   this.initPDF(folio).then(() => this.pdf.save('Cotizacion'));
    // }
  }

  /**
   * Genera texto en negritas
   * @method
   * @private
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @memberof PdfTarifasService
   */
  private writeBoldedText(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(12);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }

  /**
   * Escribe texto normal
   * @method
   * @private
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @memberof PdfTarifasService
   */
  private writeText(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(12);
    this.pdf.setFontStyle('normal');
    this.pdf.text(text, x, y);
  }

  /**
   * Escribe texto de título
   * @method
   * @private
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @memberof PdfTarifasService
   */
  private writeTitle(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 143);
    this.pdf.setFontSize(22);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }

  /**
   * Esctibe texto de versión
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private writeVersion(): void {
    this.pdf.setTextColor(230, 0, 38);
    this.pdf.setFontSize(12);
    this.pdf.setFontStyle('bold');
    this.pdf.text(`v${moment().year()}.${moment().month()}`, 250, 5);
  }

  /**
   * Escribe texto de producto
   * @method
   * @private
   * @param {PlanType} plan
   * @memberof PdfTarifasService
   */
  private writeProducto(plan: PlanType): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(32);
    this.pdf.setFontStyle('bold');
    this.pdf.text(plan, 70, 35);
  }

  /**
   * Genera header de la tabla de primas
   * @method
   * @private
   * @param {PlanType} plan
   * @returns {string[]}
   * @memberof PdfTarifasService
   */
  private generateHeaderTablePrimas(plan: PlanType): string[] {
    let arr: any[];

    switch (plan) {
      case 'PROTECANCER':
        arr = [
          [
            'COBERTURA',
            'SUMA ASEGURADA - 1',
            'SUMA ASEGURADA - 2',
            'SUMA ASEGURADA - 3'
          ]
        ];
        break;
      case 'PROTECCION EFECTIVA':
        arr = [
          [
            'COBERTURA',
            'SUMA ASEGURADA - 1',
            'SUMA ASEGURADA - 2',
            'SUMA ASEGURADA - 3'
          ]
        ];
        break;
    }

    return arr;
  }

  /**
   * Genera body de la tabla de primas
   * @method
   * @private
   * @param {PlanType} plan
   * @returns {any[]}
   * @memberof PdfTarifasService
   */
  private generateBodyTablePrimas(plan: PlanType): any[] {
    let arr: any[];
    switch (plan) {
      case 'PROTECANCER':
        arr = [
          [
            'Básica',
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_1.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_2.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_3.getValue(),
              this.locale,
              '$ ',
              'MXN'
            )
          ],
          [
            'IAC',
            formatCurrency(
              this.calculos.IAC_sumaAseguradaMasiva_1.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.IAC_sumaAseguradaMasiva_2.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.IAC_sumaAseguradaMasiva_3.getValue(),
              this.locale,
              '$ ',
              'MXN'
            )
          ]
        ];
        break;
      case 'PROTECCION EFECTIVA':
        arr = [
          [
            'Básica',
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_1.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_2.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_3.getValue(),
              this.locale,
              '$ ',
              'MXN'
            )
          ],
          [
            'PAI',
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_1.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_2.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_3.getValue(),
              this.locale,
              '$ ',
              'MXN'
            )
          ],
          [
            'PO',
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_1.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_2.getValue(),
              this.locale,
              '$ ',
              'MXN'
            ),
            formatCurrency(
              this.calculos.BASICA_sumaAseguradaMasiva_3.getValue(),
              this.locale,
              '$ ',
              'MXN'
            )
          ]
        ];
        break;
    }

    return arr;
  }

  /**
   * Escribe el texto referente a la caducidad en cada página
   *
   * @private
   * @memberof PdfTarifasService
   */
  private writeCaducidad(): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(7);
    this.pdf.setFontStyle('normal');
    this.pdf.text(
      // tslint:disable-next-line: max-line-length
      'Esta cotización tiene una validez de 15 días naturales contados a partir de la fecha de su elaboración, por lo que no garantiza la emisión del seguro. La misma es ilustrativa y de apoyo a la fuerza de ventas. No forma parte del contrato del seguro',
      7,
      205
    );
    this.pdf.text(
      'AXA Seguros se reserva del derecho de solicitar la respuesta a un cuestionario médico y de ocupación, así como estudios de laboratorio.',
      55,
      210
    );
  }
}
