import { Component, HostListener } from '@angular/core';
import { Prospectu } from '../../models';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TemplateRef } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

/**
 * Insurage-coverage (Cobertura del seguro).
 */
@Component({
  selector: 'cvida-insurage-coverage',
  templateUrl: './insurage-coverage.component.html',
  styleUrls: ['./insurage-coverage.component.sass']
})
export class InsurageCoverageComponent {
  /**
   * Variable del collapse
   * @property
   * @default "true"
   */
  isCollapseddr = true;
  /**
   * variable del collapse
   * @property
   * @default "true"
   */
  isCollapsedfn = true;
  /**
   * Collapse - Estado
   * @property
   * @default "true"
   */
  isCollapsed = true;
   /**
   * variable del collapse
   * @property
   * @default "false"
   */
  isCollapsedMed = false;
  /**
   * objeto del prospecto
   * @property
   * @type {Prospectu}
   */
  prospectu: Prospectu;
  /**
   * Width de la pantalla.
   * @property
   * @type {number}
   */
  screenWidth: number;
  /**
   * Valor del radiobutton
   * @property
   * @type {any}
   */
  radioValue: any;
  /**
   * Valor secundario del radiobutton
   * @property
   * @default "false"
   */
  radio2Value = false;
  /**
   * Referencia del modal
   * @property
   * @type {any}
   */
  bsModalRef: BsModalRef;
  /**
   * Referencia del modal
   * @property
   * @type {any}
   */
  modalRef: BsModalRef;
  /**
   * Valida el status del select
   * @property
   * @type {boolean}
   */
  check: boolean;
  /**
   * Máscara de la suma asegurada
   * @property
   */
  sumaAseguradaMask = createNumberMask({
    prefix      : '',
    suffix      : '',
    allowDecimal: false,
    decimalLimit: 0
  });
  /**
   * Declaración de variables.
   * @param prospectusDataService
   * @param modalService
   * @method
   */
  constructor(private prospectusDataService: ProspectoService,
    private modalService: BsModalService) {
    this.prospectusDataService.prospectoActual.subscribe(data => this.prospectu = data);
    this.screenWidth = window.innerWidth;
  }

  /**
   * Método que valida el select
   * @param event
   * @method
   */
  selected() {
    if (this.radio2Value === false) {
      this.check = true;
    }
  }
  /**
   * Método que detecta cuando hay un redimensinamiento de la pantalla, especificamente del Width.
   * @param event
   * @method
   */
  @HostListener('window:resize', ['event']) onResize(event) {
    this.screenWidth = window.innerWidth;
  }
  /**
   * Método que cambia los valores del radiobutton
   * @param status
   * @method
   */
  changeRadioValue(status: boolean): void {
    this.radio2Value = status;
  }
  /**
   * Método que abre el modal
   * @param template
   * @method
   */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: false });
  }

}
