import { PdfValuesService } from './../../services/pdf-values/pdf-values.service';
import { GeneradorTablaValoresService } from './../../services/generadorTablaValores/generador-tabla-valores.service';
// import { Router } from '@angular/router';
import { CalculosService } from '@services/calculos/calculos.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { INSURED_DEADLINES } from '@constants/plan-options/insured-deadlines.constant';
import {
  OnInit,
  HostListener,
  ViewChild,
  Component,
  OnDestroy,
  Renderer2,
  TemplateRef
} from '@angular/core';
import { Prospectu, PaymentType } from '../../models';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import {
  ValorGarantizado,
  ValoresGarantizadosTabla
} from '@models/valor-garantizado/ValorGarantizado';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { PageEvent, MatPaginator } from '@angular/material';
import { ScrollPercentageDetectionService } from '@services/scrollPercentageDetection/scroll-percentage-detection.service';
import { ResizeDetectionService } from '@services/resizeDetection/resize-detection.service';
import { BaseDatosService } from '@services/baseDatos/base-datos.service';
import { SortablejsModule } from 'angular-sortablejs';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { ExcedentesService } from '../../services/excedentes/excedentes.service';
import { BuildPlanComponent } from '../build-plan/build-plan.component';
import { FactoresService } from '@app/services/factores/factores.service';

import { NetworkStateService } from '@app/services/networkState/network-state.service';
import { SPLIT_PAYMENT } from '@app/constants';
import { PaymentFrequencyService } from '@app/services/paymentFrequency/payment-frequency.service';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { RoutesService } from '@app/services/routes/routes.service';
import { TarifasService } from '@app/services/tarifas/tarifas.service';
import { PdfTarifasService } from '@app/services/pdf-tarifas/pdf-tarifas.service';
import { ProjectionService } from './../../services/projection/projection.service';
import { InpcService } from '@app/services/inpc/inpc.service';
import { FactorsInpc } from './../../models/inpc/factors-inpc';
import { ValoresGarantizadosTablasService } from './../../services/valoresGarantizados/valores-garantizados-tablas.service';
import { ValorGarantizadoInpc } from '@app/models/valor-garantizado/valor-garantizado-inpc';
import { ValoresGarantizadosService } from '@app/services/valoresGarantizados/valores-garantizados.service';

import { ProyeccionVidaInteligente } from './../../models/universales/proyeccionVidaInteligente';
import { CalculosProyeccionUniversalesService } from './../../services/universales/calculos-proyeccion-universales.service';
import { PdfMiProyectoR } from '@app/services/pdf-patrimonial/pdf-mi-pr';
import { FrecuenciaPagoService } from '@app/services/universales/frecuencia-pago.service';
import { ModalService } from '@app/services/utils/modal.service';
import { AlertModalComponent } from '@app/shared/modal/alert-modal/alert-modal.component';

/**
 * Componente de Valores Garantizados
 */
@Component({
  selector: 'cvida-guaranteed-values',
  templateUrl: './guaranteed-values.component.html',
  styleUrls: ['./guaranteed-values.component.sass']
})
export class GuaranteedValuesComponent implements OnInit, OnDestroy {
  factor: BuildPlanComponent;
  @ViewChild('loader') private loader: TemplateRef<any>;
  /**
   * Checa el html del páginador
   * @property
   * @type {MatPaginator}
   */
  @ViewChild('paginator') pagin: MatPaginator;
  /**
   * Propiedades de la paginacion
   * @property
   * @type {PageEvent}
   */
  pageEvent: PageEvent;
  /**
   * Nombre de la opción a elegir
   * @property
   * @default "Filtrar"
   */
  optionName = 'Filtrar';
  /**
   * Número de página actual del paginador
   * @property
   * @default 0
   */
  numeroPagina = 0;
  /**
   * Tamaño total del paginador
   * @property
   * @type {number}
   */
  tamanio: number;
  /**
   * Númro de items por página en paginador
   * @property
   * @type {number}
   */
  numeroPaginador: number;
  /**
   * Tamaño en ancho de la pantalla
   * @property
   * @type {number}
   */
  screenWidth: number;
  /**
   * Edad en la cual inician los cálculos de los valores garantizados
   * @property
   * @type {number}
   */
  edadInicial: number;
  /**
   * Paginacion actual
   * @property
   * @type {number}
   */
  currentPage: number;
  /**
   * Arreglo validacion tabla
   * @property
   * @type {Array<number>}
   */
  myArr: Array<number> = [];
  /**
   * paginacion minima
   * @property
   * @type {number}
   */
  smallnumPages: number;
  /**
   * Número de filtros aplicados
   * @property
   * @default 999
   */
  indexFiltro = 999;
  /**
   * Bandera para reedimensionar
   * @property
   * @default 0
   */
  flagResize = 0;
  /**
   * Cantidad a iniciar con los items de la paginación
   * @property
   * @default 0
   */
  inicio = 0;
  /**
   * Cantdad de items en la paginacón
   * @property
   * @default 10
   */
  fin = 10;
  /**
   * Edad en el que finalizan los cálculos
   * @property
   * @default 99
   */
  edadFinal = 99;
  /**
   * Nueva instancia de un prospecto
   * @property
   * @type {Prospectu}
   */
  prospectu: Prospectu;
  /**
   * Referencia al modal
   * @property
   * @type {BsModalRef}
   */
  modalRef: BsModalRef;
  /**
   * Opciones elegidas del filtro
   * @property
   * @type {number[]}
   * @default []
   */
  arrFiltro: number[] = [];
  /**
   * Opcones del filtro
   * @property
   * @type {number[]}
   * @default []
   */
  myArrOptions: number[] = [];
  /**
   * Resultados de la tabla generada de valores garantizados
   * @property
   * @type {Array<ValoresGarantizadosTabla>}
   * @default []
   */
  resultados: ValorGarantizado[] = [];
  resultadosBa: ValorGarantizado[] = [];
  resultadosInpc: ValorGarantizadoInpc[] = [];
  resultadosUnivVI: ProyeccionVidaInteligente[] = [];
  /**
   * Columnas de valores de la tabla de valores garantizados
   * @property
   * @type {ValoresGarantizadosTabla[]}
   * @default []
   */
  cols: ValoresGarantizadosTabla[] = [];
  /**
   * Columnas de la tabla de valores garantizados
   * @property
   * @type {Array<ValoresGarantizadosTabla>}
   * @default []
   */
  vistaCols: ValoresGarantizadosTabla[] = [];
  /**
   * Última vista de la tabla de valores garantizados
   * @property
   * @type {Array<ValoresGarantizadosTabla>}
   * @default []
   */
  lstArrVgsTbl: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Vista por default de la tabla de valores garantizados
   * @property
   * @type {Array<ValoresGarantizadosTabla>}
   * @default []
   */
  vistaModVg: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Vista del filtro de la tabla de valores garantizados
   * @property
   * @type {Array<ValoresGarantizadosTabla>}
   * @default []
   */
  vistaFiltro: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Vista de la tabla de valores garantizados
   * @property
   * @type {Array<ValoresGarantizadosTabla>}
   * @default []
   */
  vistaVg: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Vista de la tabla de valores garantizados
   * @property
   * @type {Array<ValoresGarantizadosTabla>}
   * @default []
   */
  vistaVgFiltrada: Array<ValoresGarantizadosTabla[]> = [];

  // Sin fraccionar
  /**
   * Valor de la prima anual global de la primera moneda
   * @property
   */
  primaAnual: number;
  // Fraccionada
  /**
   * Valor de la prima total global de la primera moneda
   * @property
   */
  primaSeleccionada: number;

  /**
   * Frecuencia de pago seleccionada
   * @property
   */
  frecuenciapago = this.calculos.paymentFrecuency;
  /**
   * Tipo de plan seleccionada
   * @property
   */
  producto = this.planOptions.tipoPlan;
  /**
   * Moneda seleccionada
   * @property
   */
  moneda = this.planOptions.moneda;
  /**
   * Plazos asegurados seleccionados
   * @property
   */
  plazoSeguro = this.planOptions.plazosAsegurados;
  /**
   * Plazo de pagos seleccionados
   * @property
   */
  // plazoPago = this.planOptions.plazoPago;
  plazoPago: number;
  /**
   * Valor actual de la UDI directo de la API
   * @property
   */
  valorUDI_Iterativo = this.recursosExternos.valor_UDI;
  /**
   * Detecta si esta activo Aliados + 65 y Retiro
   *
   * @type {boolean}
   * @memberof GuaranteedValuesComponent
   */
  aliadosRetiro: boolean;
  // alidosPlan = this.planOptions.tipoPlan;

  /**
   * Variable para saber el estado de checkbox de Retiro
   */
  retiroIsActive = false;

  aportacionActive: number;
  retiro: boolean;
  /**
   * Obtendra el tipo de proyeccion
   * @property
   * @default 0
   */
  projectionType = 0;
  /**
   * Evento de drag and drop, cambia el orden de la tabla en valor
   */
  tituloImg: string;
  tipoImg = 'VG';
  eventOptions: SortablejsModule = {
    onUpdate: () => this.changeValue()
  };
  /**
   * Evento de drag and drop, cambia el orden de la tabla en vista
   */
  eventOptionsView: SortablejsModule = {
    onUpdate: () => this.changeValueView()
  };

  /**
   * Cambia el orden de la tabla en valor
   * @method
   */
  changeValue() {
    this.cols.forEach((item, index) => {
      this.cols[index].order = Number(index) + 1;
    });
    this.ordenarBody(true);
  }
  /**
   * Cambia el orden de la tabla en vista
   * @method
   */
  changeValueView() {

    this.cols = this.cols.map((item, index) => {
      item.order = index + 1;
      return item;
    });
    this.vistaCols.forEach((o, i) => (o.order = i + 1));
    this.cols = this.cols.sort((a, b) => a.order - b.order);

    this.ordenarBody(true);
  }

  /**
   * Constructor
   * @constructor
   * @param prospectoService Crea instancia del ProspectoService
   * @param modalService Crea instancia del BsModalService
   * @param planOptions Crea instancia del OpcionesPlanService
   * @param deviceservice Crea instancia del DeviceTypeService
   * @param recursosExternos Crea instancia del RecursosExternosService
   * @param calculos Crea instancia del CalcuclosService
   * @param router Crea instancia del Router
   * @param db Crea instancia del BaseDatpsService
   * @param scrollPercent Crea instancia del ScrollPercentageDetectionService
   * @param resizeDetection Crea instancia del ResizeDetectionService
   * @param generadorTabla Crea instancia del GeneradorTablaValoresService
   */
  constructor(
    private prospectoService: ProspectoService,
    private modalService: BsModalService,
    public planOptions: OpcionesPlanService,
    public deviceservice: DeviceTypeService,
    private recursosExternos: RecursosExternosService,
    private calculos: CalculosService,
    // private router: Router,
    private db: BaseDatosService,
    public scrollPercent: ScrollPercentageDetectionService,
    public resizeDetection: ResizeDetectionService,
    private generadorTabla: GeneradorTablaValoresService,
    private authentication: AuthenticationService,
    private excedentes: ExcedentesService,
    private network: NetworkStateService,
    private pdf: PdfValuesService,
    private paymentFrequency: PaymentFrequencyService,
    public routes: RoutesService,
    private tarifas: TarifasService,
    private tarifasPDF: PdfTarifasService,
    private factorD: FactoresService,
    private projection: ProjectionService,
    private inpc: InpcService,
    private columns: ValoresGarantizadosTablasService,
    // @Inject(LOCALE_ID) private locale: string,
    private renderer: Renderer2,
    private valoresG: ValoresGarantizadosService,
    private proyecUni: CalculosProyeccionUniversalesService,
    private pdfMPR: PdfMiProyectoR,
    public opciones: OpcionesPlanService,
    private frecuenciaPago: FrecuenciaPagoService,
    private modal: ModalService
  ) {
    this.projectionType = this.projection.getProjectionType();
    this.planOptions.screen.next(4);
    this.modalService.onHidden
      .delay(2000)
      .takeUntil(componentDestroyed(this))
      .subscribe(() => {
        if (this.modalService.config.class === 'loader-modal') {
          this.modalService.hide(1);

          if (document.body.classList.contains('modal-open')) {
            document.body.classList.remove('modal-open');
          }
        }
      });
    this.calculos.primaAnual_ConRecargo_Actual
      .takeUntil(componentDestroyed(this))
      .subscribe(primaAnual => {
        this.primaAnual =
          primaAnual /
          (1 +
            SPLIT_PAYMENT.filter(i => {
              let splitPayment: PaymentType = 'yearly';
              switch (this.paymentFrequency.frecuenciaPago.value) {
                case 'ANUAL':
                  splitPayment = 'yearly';
                  break;

                case 'SEMESTRAL':
                  splitPayment = 'semi-annually';
                  break;

                case 'TRIMESTRAL':
                  splitPayment = 'quarterly';
                  break;

                case 'MENSUAL':
                  splitPayment = 'monthly';
                  break;
              }

              return i.type === splitPayment;
            })[0].surcharge[this.planOptions.moneda]) +
          this.excedentes.aportacion_anual_UDI.value;
      });
    this.calculos.primaTotalFraccionadaDividida_Actual
      .takeUntil(componentDestroyed(this))
      .subscribe(primaTotal => {
        this.primaSeleccionada =
          primaTotal +
          this.excedentes.aportacion_anual_UDI.value /
          SPLIT_PAYMENT.filter(i => {
            let splitPayment: PaymentType = 'yearly';
            switch (this.paymentFrequency.frecuenciaPago.value) {
              case 'ANUAL':
                splitPayment = 'yearly';
                break;

              case 'SEMESTRAL':
                splitPayment = 'semi-annually';
                break;

              case 'TRIMESTRAL':
                splitPayment = 'quarterly';
                break;

              case 'MENSUAL':
                splitPayment = 'monthly';
                break;
            }

            return i.type === splitPayment;
          })[0].divider;
      });

    // Obtiene la edad del prospecto
    this.prospectoService.prospectoActual.subscribe(
      prospecto =>{
        if( this.planOptions.tipoPlan === 'ALIADOSKIDS'){
          this.edadInicial = prospecto.kids.ageKids;
        }else{
        (this.edadInicial = prospecto.mancomunado.mancomunado
          ? prospecto.mancomunado.edadEquivalente
          : prospecto.age)
        }
      } );
    // Obtiene la edad a llegar dependiendo del plan de proteccion
    if( this.planOptions.tipoPlan !== 'ALIADOSKIDS'){
      this.edadFinal = INSURED_DEADLINES.filter(
        coverage => coverage.plan === this.planOptions.tipoPlan
      )[0].term;
    }else{
      this.edadFinal = this.planOptions.plazoSeguro;
    }
    
    this.prospectoService.prospectoActual.subscribe(
      data => (this.prospectu = data)
    );
    this.screenWidth = window.innerWidth;
    for (let i = 0; i <= 17; i++) {
      this.myArr[i] = i + 29;
    }

    this.currentPage = 1;
    this.smallnumPages = 0;
  }

  /**
   * Lifecyrcle Hook de OnInit
   * @method
   */
  ngOnInit() {
    if (this.planOptions.plan === 'Universales') {
      this.getValoresUniversales();
    } else {
      if (this.planOptions.incrementosProgramados.value) {
        this.getValoresInpc();
      } else {
        if (this.generadorTabla.tabla_Actual.value.length > 0) {
          this.producto = this.generadorTabla.paramsElegido.value.tipoPlan;
          this.resultados = this.generadorTabla.tabla_Actual.value;
          this.resultadosBa = this.generadorTabla.tabla_ActualBa.value;
          this.plazoPago = this.generadorTabla.paramsElegido.value.plazoPago;
          this.plazoSeguro = this.generadorTabla.paramsElegido.value.plazoSeguro;
          this.setCols();
          this.resize();
          this.ordenarBody();
        } else {
          this.getValores();
        }
      }
    }
    this.tituloImg = this.routes.chosenCotizador.value !== 'AxaParaTodos' ?
    this.routes.chosenCotizador.value === 'Universales' ? 'Valores Proyectados' : 'Valores Garantizados' :
    this.generadorTabla.paramsElegido.value.tipoPlan;
  }

  async getValores() {
    this.factorD.retiroValue.subscribe(val => {
      this.aliadosRetiro = val;
    });
    this.aportacionActive = this.excedentes.aportacion_anual_UDI.value;
    this.plazoPago = this.planOptions.plazoPago.value;
    let pT = 0;
    switch (true) {
      case (this.planOptions.tipoPlan === 'DOTAL' && this.planOptions.plazoPago.value === 15) ||
        (this.planOptions.tipoPlan === 'DOTAL' && this.planOptions.plazoPago.value === 20):
        pT = Number(this.planOptions.plazoPago.value);
        break;
      case this.planOptions.tipoPlan === 'DOTAL' && this.planOptions.plazoPago.value === 65:
        pT = 65;
        break;
      case (this.planOptions.tipoPlan === 'TEMPORAL' && this.planOptions.plazoPago.value === 60) ||
        (this.planOptions.tipoPlan === 'TEMPORAL' && this.planOptions.plazoPago.value === 70) ||
        (this.planOptions.tipoPlan === 'TEMPORAL' && this.planOptions.plazoPago.value === 65):
        pT = this.planOptions.plazoPago.value - this.edadInicial;
        break;
      case this.planOptions.tipoPlan === 'TEMPORAL':
        pT = this.planOptions.plazoPago.value;
        break;
      case this.planOptions.tipoPlan === 'OV':
        pT = 100 - this.edadInicial;
        break;
      default:
        pT = this.edadFinal - this.edadInicial + 1;
        break;
    }
    this.calculos.primaAnual_ConRecargo_Actual
      .takeUntil(componentDestroyed(this))
      .subscribe(primaAnual => {
        const force = this.planOptions.tipoPlan === 'DOTAL';

        if (this.planOptions.tipoPlan === 'DOTAL' && this.planOptions.plazoPago.value === 65) {
          this.resultados =
            !(this.generadorTabla.tabla_Actual.value.length > 0) ||
              this.generadorTabla.tabla_Actual.value[0].primaElegida !==
              Math.round(primaAnual)
              ? this.generadorTabla.generarTablaValores(
                pT,
                this.edadInicial,
                'Elegido',
                this.planOptions.plazoPago.value,
                this.moneda,
                force
              ) || []
              : this.generadorTabla.tabla_Actual.value;
          if (this.projectionType !== 0) {
            this.resultadosBa =
              !(this.generadorTabla.tabla_ActualBa.value.length > 0) ||
                this.generadorTabla.tabla_ActualBa.value[0].primaElegida !==
                Math.round(primaAnual)
                ? this.generadorTabla.generarTablaValores(
                  pT,
                  this.edadInicial,
                  'ElegidoBA',
                  this.planOptions.plazoPago.value,
                  this.moneda,
                  force
                ) || []
                : this.generadorTabla.tabla_ActualBa.value;
          }
        } else {
          if (this.routes.chosenCotizador.value === 'AxaParaTodos') {
            this.tarifas.generarTablaTarifa(this.planOptions.tipoPlan);
            this.resultados = this.tarifas.tablaTarifas.value;
          } else {
            this.resultados =
              !(this.generadorTabla.tabla_Actual.value.length > 0) ||
                this.generadorTabla.tabla_Actual.value[0].primaElegida !==
                Math.round(primaAnual)
                ? this.generadorTabla.generarTablaValores(
                  pT,
                  this.edadInicial,
                  'Elegido',
                  this.planOptions.plazoPago.value,
                  this.moneda,
                  force
                ) || []
                : this.generadorTabla.tabla_Actual.value;
            if (this.projectionType !== 0) {
              this.resultadosBa =
                !(this.generadorTabla.tabla_ActualBa.value.length > 0) ||
                  this.generadorTabla.tabla_ActualBa.value[0].primaElegida !==
                  Math.round(primaAnual)
                  ? this.generadorTabla.generarTablaValores(
                    pT,
                    this.edadInicial,
                    'ElegidoBA',
                    this.planOptions.plazoPago.value,
                    this.moneda,
                    force
                  ) || []
                  : this.generadorTabla.tabla_ActualBa.value;
            }
          }
        }
        this.setCols();
        this.resize();
        this.ordenarBody();
      });
    //  });
    if (this.planOptions.tipoPlan === 'DOTAL') {
      this.plazoSeguro = this.planOptions.plazoPago.value;
    }
  }

  async getValoresInpc() {
    this.modalRef = this.modalService.show(this.loader, { class: 'loader-modal' });
    if (this.valoresG.activeCoberturas) {
      await this.planOptions.generarFactoresCoberturasINPC();
    }

    let factInpc: FactorsInpc[] = [];
    factInpc = await this.inpc.getInpcFactoresBasica();
    const factVr = await this.inpc.getFactValorRescate();
    this.resultadosInpc = this.generadorTabla.generarTablaInpc(factInpc, factVr);
    this.setCols();
    this.resize();
    this.ordenarBody();
    this.modalRef.hide();
  }

  async getValoresUniversales() {
    this.tipoImg = this.opciones.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR';
    this.resultadosUnivVI = await this.proyecUni.proyecciones();
    this.setCols();
    this.resize();
    this.ordenarBody();
  }

  /**
   * Establece los valores de las columnas
   * @method
   */
  setCols() {
    this.lstArrVgsTbl = [];
    let arrayValores: any[];
    let ordenarPorc = false;
    if (this.planOptions.plan === 'Universales') {
      arrayValores = this.resultadosUnivVI;
      this.cols = this.columns.getTableUnivVI(this.resultadosUnivVI);
    } else {
      if (this.planOptions.incrementosProgramados.value) {
        this.cols = this.columns.getTableINPC(this.resultadosInpc);
        arrayValores = this.resultadosInpc;
        const bolExcedente = this.resultadosInpc.reduce((a, b) => a + b.aportacionAdicionalProyectada, 0) > 0 ? true : false;
        if (!bolExcedente) {
          const arrCols = ['aportacionAdicionalProyectada', 'aportacionAdicional_MXN', 'valorEnEfectivo_Pesos'];
          this.cols = this.deleteCols(arrCols, this.cols);
        }
      } else {
        if (this.routes.chosenCotizador.value === 'AxaParaTodos') {
          this.cols = this.columns.getTableAxaParaTodos();
        } else {
          this.cols = this.columns.getTableVg();
        }
        this.factorD.retiroValue.subscribe(val => {
          this.retiro = val;
        });
        if (this.retiro === true) {
          this.cols.forEach(obj => {
            if (
              obj.field === 'primaDeducibleUDI' ||
              obj.field === 'primaDeducibleMXN'
            ) {
              obj.visible = true;
            }
          });
        }
        if (this.excedentes.aportacion_anual_UDI.value !== 0) {
          this.cols.forEach(col => {
            if (
              col.field === 'aportacionAdicional_UDI' ||
              col.field === 'aportacionAdicional_MXN' ||
              col.field === 'aportacionAdicional' ||
              col.field === 'aportacionAdicionalProyectada'
            ) {
              col.visible = true;
            }
          });
        }
        ordenarPorc = true;
        arrayValores = this.resultados;
      }
    }

    this.convertirTabla(arrayValores, ordenarPorc);
    this.cols = this.ordenarHeader(this.cols);
    this.tamanio = this.lstArrVgsTbl.length;
    this.vistaCols = this.cols.filter(s => s.visible);
    this.vistaModVg = this.lstArrVgsTbl;
  }

  convertirTabla(array: any[], ordenarPorc: boolean) {
    for (let i = 0; i < array.length; i++) {
      let valGaraTbl: ValoresGarantizadosTabla[] = [];
      for (const propiedad in array[0]) {
        if (true) {
          let porcentaje = '';
          if (ordenarPorc) {
            porcentaje = this.ordenarPorcentajeRecuperacion(propiedad, i);
          }
          const col = this.cols.filter(s => s.field === propiedad);
          if (col.length > 0) {
            let valorg: ValoresGarantizadosTabla;
            valorg = {
              field: propiedad,
              value: porcentaje === '' ? array[i][propiedad] : porcentaje,
              order: col[0].order,
              visible: col[0].visible,
              coin: col[0].coin,
              projection: col[0].projection
            };
            valGaraTbl.push(valorg);
          }

        }
      }
      valGaraTbl = this.ordenarHeader(valGaraTbl);
      this.lstArrVgsTbl.push(valGaraTbl);
    }
  }

  ordenarPorcentajeRecuperacion(propiedad: String, i: number): string {
    let resultado;
    switch (propiedad) {
      case 'porcentajeRecuperacionUdi':
        resultado = this.projectionType === 0 ? this.resultados[i]['porcentajeRecuperacionUdi']
          : this.resultadosBa[i]['porcentajeRecuperacionUdi'];
        break;
      case 'porcentajeRecuperacionPesos':
        resultado = this.projectionType === 0 ? this.resultados[i]['porcentajeRecuperacionPesos']
          : this.resultadosBa[i]['porcentajeRecuperacionPesos'];
        break;
      case 'porcentajeRecuperacionUdiBaCo':
        resultado = this.resultados[i]['porcentajeRecuperacionUdi'];
        break;
      case 'porcentajeRecuperacionPesosBaCo':
        resultado = this.resultados[i]['porcentajeRecuperacionPesos'];
        break;
      default:
        return '';
    }
    return resultado;
  }

  /**
   * Ordena el header según los datos del prospecto
   * @param valGaraTbl
   */
  ordenarHeader(
    valGaraTbl: ValoresGarantizadosTabla[]
  ): ValoresGarantizadosTabla[] {
    if (this.planOptions.moneda.toLocaleLowerCase() === 'udi') {
      valGaraTbl = valGaraTbl.filter(s => s.coin !== 'dolares');
    } else {
      const moneda = this.planOptions.moneda === 'MXN' ? 'pesos' : 'dolares';
      valGaraTbl = valGaraTbl.filter(
        s => s.coin === 'all' || s.coin === moneda
      );
    }
    const project = this.projectionType;
    valGaraTbl = valGaraTbl.filter(s => {
      const ret = s.projection.filter(k => k === project);
      if (ret.length > 0) {
        return true;
      } else {
        return false;
      }
    });
    valGaraTbl = valGaraTbl.sort((a, b) => a.order - b.order);
    this.generadorTabla.vistaHeader = valGaraTbl
      .filter(s => s.visible)
      .map(valGara => valGara.field);
    return valGaraTbl;
  }

  /**
   * Ordena el cuerpo de la tabla según el header
   * @method
   */
  ordenarBody(itMoved?: boolean, itemSelected?: number) {
    if (
      itemSelected &&
      this.cols.filter(i => i.visible === true).length === 17
    ) {
      this.cols[itemSelected].visible = false;
      document.getElementById(`chbx${itemSelected + 1}`)['checked'] = false;
      this.db.message = 'Sólo se puede agregar hasta 16 columnas.';
      this.db.textFolio = '';
      this.db.closeModal(false);
    }

    this.vistaCols = this.ordenarHeader(this.cols);
    const nVista: Array<ValoresGarantizadosTabla[]> = [];
    this.vistaVg = nVista;
    if (this.vistaCols.length > 0) {
      for (let i = 0; i < this.lstArrVgsTbl.length; i++) {
        const arrVgt: ValoresGarantizadosTabla[] = [];
        for (let j = 0; j < this.vistaCols.length; j++) {
          let vgt: ValoresGarantizadosTabla;
          vgt = this.lstArrVgsTbl[i].filter(
            s => s.field === this.vistaCols[j].field
          )[0];
          vgt.visible = this.vistaCols[j].visible;
          arrVgt.push(vgt);
        }
        nVista.push(arrVgt);
      }
      this.vistaModVg = nVista;
      if (this.arrFiltro.length === 0) {
        this.showFilterList();
      } else {
        if (!itMoved && this.vistaFiltro.length <= 0) {
          this.showFiltered(this.indexFiltro, 'd');
        }

        const nuevaVista: Array<ValoresGarantizadosTabla[]> = [];
        this.vistaFiltro = nuevaVista;
        this.vistaVg = nuevaVista;
        for (let i = 0; i <= this.arrFiltro.length; i++) {
          let filtro: ValoresGarantizadosTabla[] = [];
          filtro = this.vistaModVg[this.arrFiltro[i]];
          this.vistaFiltro.push(filtro);
        }
        this.vistaVg = this.vistaFiltro;
      }
    }

    this.vistaVg = this.vistaVg.filter(el => el !== undefined);
    this.vistaCols = this.vistaCols.filter(s => s.visible);
    this.vistaVg = this.vistaVg.map((e, i) =>
      e.filter(el => el.visible !== false)
    );
    if (this.planOptions.plan === 'Universales') {
      const frecuenciaPago = this.frecuenciaPago.frecuenciaPagoUniv.value.filter(s => s.seleccionado);
      this.primaAnual = frecuenciaPago[0].primaAnual;
      this.primaSeleccionada = frecuenciaPago[0].primaFormaPago;
    } else {
      let i_index = 0; this.vistaCols.forEach(ob => {
        if (ob.field === 'primaTotal') {
          this.primaSeleccionada = Number(this.vistaVg[0][i_index].value);
        }
        if (ob.field === 'primaBasica') {
          this.primaAnual = Number(this.vistaVg[0][i_index].value);
        } i_index++;
      });
    }

    this.plazoPago = this.opciones.plazoPago.value;
    this.plazoSeguro = this.opciones.plazoSeguro;
  }

  /**
   * Reestablece la vista de la tabla
   * @method
   */
  reset() {
    this.cols.forEach((item, index) => {
      switch (true) {
        case this.aliadosRetiro === true && this.aportacionActive === 0:
          if (
            this.cols[index].field === 'aportacionAdicional_UDI' ||
            this.cols[index].field === 'aportacionAdicional_MXN' ||
            this.cols[index].field === 'valorEnEfectivo_UDI' ||
            this.cols[index].field === 'valorEnEfectivo_Pesos' ||
            this.cols[index].field === 'aportacionAdicional' ||
            this.cols[index].field === 'aportacionAdicionalProyectada'
          ) {
            this.cols[index].visible = false;
          } else {
            this.cols[index].visible = true;
          }
          break;
        case this.aliadosRetiro === false && this.aportacionActive !== 0:
          if (
            this.cols[index].field === 'primaDeducibleUDI' ||
            this.cols[index].field === 'primaNoDeducibleUDI' ||
            this.cols[index].field === 'primaDeducibleMXN' ||
            this.cols[index].field === 'primaNoDeducibleMXN'
          ) {
            this.cols[index].visible = false;
          } else {
            this.cols[index].visible = true;
          }
          break;
        case this.aliadosRetiro === false && this.aportacionActive === 0:
          if (
            this.cols[index].field === 'aportacionAdicional_UDI' ||
            this.cols[index].field === 'aportacionAdicional_MXN' ||
            this.cols[index].field === 'primaDeducibleUDI' ||
            this.cols[index].field === 'primaNoDeducibleUDI' ||
            this.cols[index].field === 'primaDeducibleMXN' ||
            this.cols[index].field === 'primaNoDeducibleMXN' ||
            this.cols[index].field === 'valorEnEfectivo_UDI' ||
            this.cols[index].field === 'valorEnEfectivo_Pesos' ||
            this.cols[index].field === 'aportacionAdicional' ||
            this.cols[index].field === 'aportacionAdicionalProyectada'

          ) {
            this.cols[index].visible = false;
          } else {
            this.cols[index].visible = true;
          }
          break;
        default:
          this.cols[index].visible = true;
          break;
      }
    });
    this.vistaCols = this.ordenarHeader(this.cols);
    const nVista: Array<ValoresGarantizadosTabla[]> = [];
    this.vistaVg = nVista;
    if (this.vistaCols.length > 0) {
      for (let i = 0; i < this.lstArrVgsTbl.length; i++) {
        const arrVgt: ValoresGarantizadosTabla[] = [];
        for (let j = 0; j < this.vistaCols.length; j++) {
          let vgt: ValoresGarantizadosTabla;
          vgt = this.lstArrVgsTbl[i].filter(
            s => s.field === this.vistaCols[j].field
          )[0];
          arrVgt.push(vgt);
        }
        nVista.push(arrVgt);
      }
      this.vistaModVg = nVista;
      this.showFilterList();
    }
  }

  /**
   * Oculta la columna del header asignada
   * @method
   * @param col
   */
  ocultarColumnasHeader(col) {
    const cols = this.cols.filter(s => s.field === col.field);
    cols[0].visible = false;
    this.ordenarBody();
  }

  /**
   * Verifica si el género del prospecto es mujer
   * @method
   * @return {boolean}
   */
  isFemaleActive(): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    return this.prospectu.gender === 'M';
  }

  /**
   * Verifica si se reajusta la tabla conforme se detecta el tamaño de la pantalla
   * @method
   */
  @HostListener('window:resize', ['event']) onResize(event) {
    this.resize();
  }

  /**
   * Ajusta el tamaño de la tabla conforme el tamaño del dispositivo
   * @method
   */
  resize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1010 && this.flagResize !== 1) {
      this.numeroPaginador = 1;
      this.numeroPagina = 0;
      this.flagResize = 1;
      this.showFilterList();
    } else if (this.screenWidth > 1010 && this.flagResize !== 2) {
      this.numeroPaginador = 10;
      this.numeroPagina = 0;
      this.flagResize = 2;
      this.showFilterList();
    }
  }

  /**
   * Abre modal
   * @method
   * @param template
   */
  openModal(template) {
    this.modalRef = this.modalService.show(template);

    const modalContainerDOM: Element = document.getElementsByTagName('modal-container')[0];
    if (window.innerWidth > 1355) {
      this.renderer.setStyle(modalContainerDOM, 'top', '-20%');
    }

  }

  /**
   * Ajusta el contenido la tabla conforme el tamaño del dispositivo
   * @param evento
   */
  ajustarPaginaTblDinamic(evento) {
    const nuevaVista: Array<ValoresGarantizadosTabla[]> = [];
    this.vistaVg = nuevaVista;
    if (evento !== undefined) {
      this.numeroPagina = evento.pageIndex;
      this.inicio =
        evento.pageIndex === 0 ? 0 : evento.pageIndex * evento.pageSize;
      this.fin = this.inicio + evento.pageSize;
    } else {
      this.numeroPagina = 0;
      this.inicio = 0;
      this.fin = 10;
    }

    this.vistaVg = this.vistaModVg.slice(this.inicio, this.fin);

    this.vistaVg.forEach(el => {
      el.forEach((e, i) => {
        if (e.visible !== this.cols[i].visible) {
          e.visible = this.cols[i].visible;
        }
      });
    });

    for (let i = 0; i < this.vistaVg.length; i++) {
      this.vistaVg[i] = this.vistaVg[i].filter(el => el.visible);
    }
  }

  /**
   * Determina si el el dispositivo en uso es válido
   * @method
   * @return {boolean}
   */
  getDeviceType(): boolean {
    const device = this.deviceservice.getDeviceType();
    if (device === 'iPhone' || device === 'iPad' || device === 'Android') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Consigue la tabla de valores garantizados según el filtro asignado
   * @method
   * @param valor
   */
  filterType(valor) {
    let array: any[];
    if (this.planOptions.plan === 'Universales') {
      array = this.resultadosUnivVI;
    } else {
      if (this.planOptions.incrementosProgramados.value) {
        array = this.resultadosInpc;
      } else {
        array = this.resultados;
      }
    }

    const empty: number[] = [];
    this.myArrOptions = empty;
    if (valor === 'Todas') {
      this.indexFiltro = 999;
      this.showFilterList();
      const nuevaVista: Array<ValoresGarantizadosTabla[]> = [];
      this.vistaFiltro = nuevaVista;
      this.arrFiltro = [];
      this.vistaVgFiltrada = [];
    } else if (valor === 'Edad') {
      this.optionName = 'Seleccionar edad';
      for (const data of array) {
        this.myArrOptions.push(data.edad);
      }
    } else {
      this.optionName = 'Seleccionar año póliza';
      for (const data of array) {
        this.myArrOptions.push(data.anioPoliza);
      }
    }
  }

  /**
   * Consigue la páginación correcta para la tabla de valores garantizados
   * con el filtro dado
   * @method
   */
  showFilterList() {
    let pageEnv: PageEvent;
    pageEnv = {
      previousPageIndex: 0,
      pageIndex: this.numeroPagina,
      pageSize: this.numeroPaginador,
      length: this.vistaModVg.length
    };
    this.ajustarPaginaTblDinamic(pageEnv);
  }

  /**
   * Consigue la tabla de valores garantizados con los filtros asignados
   * @method
   * @param value
   * @param tipo
   */
  showFiltered(value: number, tipo: string) {
    const nuevaVista: Array<ValoresGarantizadosTabla[]> = [];
    if (Number(value) === 999) {
      this.showFilterList();
      this.vistaFiltro = nuevaVista;
      this.arrFiltro = [];
    } else {
      if (tipo === 'm') {
        this.vistaFiltro = nuevaVista;
        this.arrFiltro = [];
      }
      this.arrFiltro.push(value);
      this.vistaFiltro = nuevaVista;
      for (let i = 0; i < this.arrFiltro.length; i++) {
        this.vistaFiltro.push(this.vistaModVg[this.arrFiltro[i]]);
      }

      this.vistaVg = nuevaVista;
      this.vistaVg = this.vistaFiltro;
      this.vistaVg = this.vistaVg.map((e, i) =>
        e.filter(el => el.visible !== false)
      );
    }
  }

  /**
   * Reedirecciona al PDF de valores garantizados
   * @method
   * @param valor
   */
  verPDF(valor: boolean) {
    this.generadorTabla.vistaCols = this.vistaCols;
    this.generadorTabla.colsTest = this.cols;
    this.generadorTabla.vistaVg = this.vistaModVg;

    this.vistaFiltro.length !== 0
      ? (this.generadorTabla.vistaVg = this.vistaFiltro)
      : (this.generadorTabla.vistaVg = this.vistaModVg);

    if (!this.db.cotizacionGuardada.value) {
      this.saveCotizacion();
    }
    this.db.folioTest.subscribe(async folio => {
      if (folio !== '') {
        if (this.routes.chosenCotizador.getValue() === 'ProTGT' ||
          this.routes.chosenCotizador.getValue() === 'Aliados') {
          await this.pdf.generatePDF(folio);
        }

        if (this.routes.chosenCotizador.getValue() === 'AxaParaTodos') {
          await this.tarifasPDF.generatePDF(folio);
        }

        if (this.routes.chosenCotizador.getValue() === 'Universales') {
          await this.pdfMPR.generatePDF(folio);
        }
      }
    });
  }

  private mostrarModal(descripcion: string) {
    this.modal.bolBodalServ = true;
    this.modal.descripcion = descripcion;
    this.modalRef = this.modalService.show(AlertModalComponent, {
      backdrop: false,
      class: 'alert-modal'
    });
    this.modal.bolBodalServ = false;
  }

  /**
   * Guarda la cotización
   * @method
   * @param save
   */
  async saveCotizacion() {

    if(!navigator.onLine)
    {
      this.mostrarModal('Error de conexion, intenta de nuevo.');

      return;
    }/** */

    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const defaultValue = 'NO ESPECIFICADO';
    const plazoSeguro =
      this.planOptions.tipoPlan === 'VPL' ? 99 : this.planOptions.plazoSeguro;
    let nomFolio = 'ProTGT';
    if (this.opciones.plan === 'Universales') {
      nomFolio = this.opciones.tipoPlan === 'VIDA INTELIGENTE' ? 'VidaINT' : 'ProyR';
    }else if( this.opciones.tipoPlan === 'ALIADOSKIDS'){
      nomFolio = '  Kids';
    }

    const doc = {
      folio: await this.db.generateFolio(nomFolio),
      fecha: {
        day,
        month,
        year
      },
      agente: {
        name: this.authentication.user.value.name || defaultValue,
        code: this.authentication.user.value.code || defaultValue
      },
      prospecto: this.planOptions.prospecto,
      producto: {
        plan: this.planOptions.tipoPlan || defaultValue,
        plazoPago: this.planOptions.plazoPago.value || defaultValue,
        plazoSeguro: plazoSeguro || defaultValue,
        cesion_comision: this.excedentes.cesion_comision.value || defaultValue,
        esquema: this.excedentes.esquema_comision.value || defaultValue,
        inflacion_udi: this.excedentes.inflacion_udi.value || defaultValue,
        moneda: this.planOptions.moneda || defaultValue
      },
      aportaciones_adicionales: {
        cantidad: this.excedentes.numero_aportaciones.value || defaultValue,
        aportacion_anual:
          this.excedentes.aportacion_anual_UDI.value || defaultValue
      },
      valoresGarantizados: this.resultados,
      cotizacion: this.network._state.getValue() ? 'ONLINE' : 'OFFLINE'
    };
    // Se guarda cotización
    this.db.guardarCotizacion(doc, false, this.routes.chosenCotizador.getValue() !== 'AxaParaTodos' ?
      this.pdf : this.tarifasPDF).then(
        response => {
          this.db.cotizacionGuardada.next(true);
        }
      );
  }

  ngOnDestroy(): void { }

  private deleteCols(arrColumnas: String[], tabla: any[]) {
    tabla.forEach(function (currentValue, index, arr) {
      if (arrColumnas.includes(tabla[index].field)) {
        tabla.splice(index, index);
      }
    });
    return tabla;
  }

}
