/** Angular Core */
import { Injectable } from '@angular/core';
/** Rxjs */
import { Observable, BehaviorSubject } from 'rxjs';

/**
 * Servicio dedicado a la detección del tamaño (width) de la ventana.
 * @export
 * @class ResizeDetectionService
 */
@Injectable({
  providedIn: 'root'
})
export class ResizeDetectionService {
  /**
   * Width de la ventana
   * @property
   * @type {Observable<number>}
   */
  width$: Observable<number>;

  /**
   * Inicialización de variables y métodos.
   * Crea una instancia de ResizeDetectionService.
   */
  constructor() {
    const windowWidth$ = new BehaviorSubject(this.getWindowWidth());
    this.width$ = (windowWidth$.pluck('width') as Observable<number>).distinctUntilChanged();

    Observable.fromEvent(window, 'resize')
    .map(this.getWindowWidth)
    .subscribe(windowWidth$);
  }

  /**
   *  Método que obtiene el width de la ventana.
   * @method
   * @private
   * @returns {Object}
   */
  private getWindowWidth(): Object {
    return {
      width: window.innerWidth
    };
  }
}
