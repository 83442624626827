import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Componente
import { NotPermittedComponent } from '@components/not-permitted/not-permitted.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NotPermittedComponent
  ]
})
export class NotPermittedModule { }
