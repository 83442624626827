import { PlanType } from '@constants/plan-options/plan-types.constant';

/**
 * Plazos asegurados.
 * @const
 * @type {Array}
 * @param plan
 * @param term
 */
export const INSURED_DEADLINES: { plan: PlanType; term: number }[] = [
  { plan: 'TEMPORAL', term: 1 },
  { plan: 'OV', term: 99 },
  { plan: 'VPL', term: 99 },
  { plan: 'DOTAL', term: 10 },
  { plan: 'ALIADOSKIDS', term: 18 },
  { plan: 'PROTECANCER', term: 1 },
  { plan: 'PROTECCION EFECTIVA', term: 1 },
  { plan: 'MUJER UNICA', term: 99 },
  { plan: 'VIDA INTELIGENTE', term: 90},
  { plan: 'MI PROYECTO R', term: 85}
];
