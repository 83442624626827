import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
/**
 * Componente encargado de la configuración de las variables
 */
@Component({
  selector: 'cvida-variable-settings',
  templateUrl: './variable-settings.component.html',
  styleUrls: ['./variable-settings.component.sass']
})
export class VariableSettingsComponent implements OnInit {
   /**
   * Referencia del modal
   * @property
   * @type {BsModalRef}
   */
  modalRef: BsModalRef;
    /**
   * Mensaje
   * @property
   * @type {string}
   */
  message: string;

  /**
   * Lifecycle Hook de OnInit
   * @method
   */
  ngOnInit() {
  }

  /**
   * Método que acepta el modal
   * @method
   */
  confirm(): void {
    this.message = 'Confirmed!';
    this.modalRef.hide();
  }

   /**
   * Método que rechaza el modal
   * @method
   */
  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }

}
