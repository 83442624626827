/** ANGULAR CORE */
import { Component, OnInit, ViewChild } from '@angular/core';
/** NGX - BOOTSTRAP */
import { BsModalRef } from 'ngx-bootstrap/modal';
/** SERVICES */
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';
import { ModalService } from './../../../services/utils/modal.service';
/**
 * ALERTA PARA MOSTRAR DATOS Y EL FÓLIO DE LA COTIZACIÓN A GUARDAR
 * @export
 * @class AlertModalComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.sass']
})
export class AlertModalComponent implements OnInit {
  /** PROPIEDAD QUE PERMITE LA COMUNICACIÓN ENTRE COMPONETE PADRE Y COMPONENTE HIJO */
  @ViewChild('modalRef') mod: BsModalRef;

  /** VARIABLE QUE ALMACENA EL FÓLIO DE LA COTIZACIÓN */
  strFolio: string;
  /** VARIABLE QUE ALMACENA LA FECHA EN LA QUE SE REALIZA LA COTIZACIÓN */
  fecha = new Date();
  /** VARIABLE BOOLEANA QUE PERMITE MOSTRAR LA FECHA */
  dateVisible = true;
  /** VARIABLE PARA LA REFERENCIA AL MODAL */
  modalRef: BsModalRef;
  /** VARIABLE QUE ALMACENA EL LA DESCRIPCIÓN DE LA COTIZACIÓN REALIZADA */
  description: string;
  /**
   * Creates an instance of AlertModalComponent.
   * @param {BsModalRef} _bsModalRef
   * @param {ProtectionPlansGeneratorService} ProtectionPlansGenerator
   * @memberof AlertModalComponent
   */
  constructor(private _bsModalRef: BsModalRef,
    private ProtectionPlansGenerator: ProtectionPlansGeneratorService,
    private modal: ModalService
  ) { }
  /**
 * MÉTODO PRINCIPAL EN EL CUAL SE CARGAN LOS VALORES INICIALES
 *
 * @memberof AlertModalComponent
 */
  ngOnInit() {
    if (this.modal.bolBodalServ) {
      this.description = this.modal.descripcion;
      this.dateVisible = this.modal.fechaVisible;
      this.strFolio = this.modal.folio;
    } else {
      this.description = this.ProtectionPlansGenerator.title;
      this.dateVisible = this.ProtectionPlansGenerator.dateVisible;
      this.strFolio = this.ProtectionPlansGenerator.strFolio;
    }

  }

  /**
   * MÉTODO PARA CERRAR EL MODAL
   * @memberof AlertModalComponent
   */
  close(template) {
  }

  /**
   * MÉTODO PARA CERRAR EL MODAL
   * @memberof AlertModalComponent
   */

  test3() {
    this._bsModalRef.hide();
  }

}
