import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { ProtectionPlansGeneratorService } from '@app/services/protectionPlansGenerator/protection-plans-generator.service';
import { BaseDatosService } from '@app/services/baseDatos/base-datos.service';
import { ROUTE_NAMING } from '@app/constants';
import { RoutesService } from '@app/services/routes/routes.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineGuard implements CanActivate {
  constructor(
    private protectionPlans: ProtectionPlansGeneratorService,
    private router: Router,
    public baseDatos: BaseDatosService,
    private routes: RoutesService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const cotizador = state.url.split('/')[2];

    return this.validOffline(this.routes.offlinePlan(cotizador));
  }

  validOffline(planABuscar: string): boolean {
    this.router;
    this.protectionPlans;
    ROUTE_NAMING;
    return true;
  }
}
