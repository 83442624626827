/**
 * CONSTANTES DEL PROSPECTO
 * @description Archivo que contiene todas las constantes referentes al prospecto.
 */
export const PROSPECTU_CONSTANTS = {
  /**
   * EDAD MÍNIMA
   * @description Edad miníma que debe tener el prospecto
   */
  MINIMUN_AGE: 18,
  /**
   * EDAD MAXÍMA
   * @description Edad máxima que debe tener el prospecto
   */
  MAXIMUM_AGE: 70,
  /**
   * NOMBRE POR DEFECTO
   * @description Nombre por defecto del prospecto, en caso de no estar explícito.
   */
  DEFAULT_NAME: 'Prospecto',
  /**
   * NOMBRE POR DEFECTO PARA MANCOMUNADO
   * @description Nombre por defecto del prospecto, en caso de no estar explicíto.
   */
  DEFAULT_NAME_MANCOMUNADO : 'Mancomunado'
};

/**
 * CONSTANTES DEL PROSPECTO KIDS
 * @description Archivo que contiene todas las constantes referentes al prospecto.
 */
export const PROSPECTU_CONSTANTS_KIDS = {
  /**
   * EDAD MÍNIMA
   * @description Edad miníma que debe tener el menor
   */
  MINIMUN_AGE: 0,
  /**
   * EDAD MAXÍMA
   * @description Edad máxima que debe tener el menor
   */
  MAXIMUM_AGE: 10,
  /**
   * NOMBRE POR DEFECTO
   * @description Nombre por defecto del prospecto, en caso de no estar explícito.
   */
  DEFAULT_NAME: 'Prospecto_Kids',
  /**
   * NOMBRE POR DEFECTO PARA MANCOMUNADO
   * @description Nombre por defecto del prospecto, en caso de no estar explicíto.
   */
  DEFAULT_NAME_KIDS : 'Kids'
};