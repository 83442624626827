import { DeviceTypeService } from './../../services/deviceType/deviceType.service';
import { Component } from '@angular/core';

/**
 * Componente que se despliega cuando no se optiene un error 404
 */
@Component({
  selector: 'cvida-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.sass']
})
export class NotFoundComponent {

  /**
   * Establece el dispositivo
   * @property
   * @type {string}
   */
  dev: string;
  /**
   * Establece la orientación del dispositivo
   * @property
   * @type {string}
   */
  orient: string;
  /**
   * Establece si el dispositivo es válido
   * @property
   * @type {boolean}
   */
  dispositivo: boolean;

  /**
   * Metodo para inicializar las variables
   * @method
   * @param {DeviceTypeService} device
   */

  constructor(private device: DeviceTypeService) { }

  /**
   * Retorna tipo de dispositivo admitido
   * @method
   * @returns {boolean}
   */
  devtype() {
    if (this.device.getDeviceType() === 'iPad') {
      this.dev = 'iPad';
      this.orient = this.device.getOrientation(window.innerHeight, window.innerWidth);
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'iPhone') {
      this.dev = 'iPhone';
      this.orient = this.device.getOrientation(window.innerHeight, window.innerWidth);
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'Android') {
      this.dev = 'Android';
      this.orient = this.device.getOrientation(window.innerHeight, window.innerWidth);
      return this.dispositivo = true;
    } else {
      this.dev = 'Desktop';
      return this.dispositivo = false;
    }
  }

}
