/** Angular Core */
import { Injectable } from '@angular/core';
/** Models */
import { CommissionAssignmentFront, CommissionAssignment } from './../../models/commission-scheme/commission-scheme.interface';
import { CommissionSchemeFront, CommissionScheme } from '@models/.';
/** Services */
import { OpcionesPlanService } from './../opcionesPlan/opciones-plan.service';
import { CalculosService } from '@services/calculos/calculos.service';
import { ExcedentesService } from './../excedentes/excedentes.service';
/** Constants */
import { COMMISSION_SCHEMES, DEFAULT_COMMISSION_SCHEME } from '@constants/.';
import { COMMISSION_ASSIGNMENTS, DEFAULT_COMMISSION_ASSIGNMENT } from './../../constants/commission-scheme/commission-scheme.constant';

/**
 * SERVICIO PARA LA CONFIGURACION DE LAS VARIABLES
 * DE LA TABLA DE VALORES GERANTIZADOS
 * @export
 * @class VariableConfigurationService
 */
@Injectable({
  providedIn: 'root'
})
export class VariableConfigurationService {
  /**
   * Crwa una instancia del componente VariableConfigurationService.
   * @param {ExcedentesService} excedentes
   * @param {OpcionesPlanService} opciones
   * @param {CalculosService} calculos
   * @memberof VariableConfigurationService
   */
  constructor(
    private excedentes: ExcedentesService,
    private opciones: OpcionesPlanService,
    private calculos: CalculosService,
  ) { }
  /**
   * MÉTODO PARA REESTRABLECER LAS COMISIONES
   * @memberof VariableConfigurationService
   */
  resetCommissions(): void {
    this.excedentes.esquema_comision.next(DEFAULT_COMMISSION_SCHEME);
    this.excedentes.cesion_comision.next(0.025);
    this.excedentes.string_cesion_comision.next('Si');
  }

  /**
   * MÉTODO PARA GENERAR COMISIONES
   * @returns {CommissionSchemeFront[]}
   * @memberof VariableConfigurationService
   */
  generateCommissionSchemes(): CommissionSchemeFront[] {
    const actualScheme = this.excedentes.esquema_comision.value || DEFAULT_COMMISSION_SCHEME;
    let commissionSchemes: CommissionSchemeFront[] = [];
    const esquemaComision = this.opciones.plan === 'Universales' ? ['Decrecientes', 'SemiNiveladas'] : COMMISSION_SCHEMES;
    esquemaComision
      .forEach((item: CommissionScheme) => {
        commissionSchemes = [...commissionSchemes, {
          value: item,
          default: item === actualScheme
        }];
      });
    return commissionSchemes;
  }

  /**
   * MÉTODO PARA LA ASIGNACIÓN DE COMISIONES
   * @returns {CommissionAssignmentFront[]}
   * @memberof VariableConfigurationService
   */
  generateCommissionAssignment(): CommissionAssignmentFront[] {
    const actualAssignment = this.excedentes.string_cesion_comision.value || DEFAULT_COMMISSION_ASSIGNMENT;
    let commissionSchemes: CommissionAssignmentFront[] = [];
    COMMISSION_ASSIGNMENTS.forEach((item: CommissionAssignment) => {
      commissionSchemes = [...commissionSchemes, { value: item, default: String(item) === String(actualAssignment) }];
    });
    return commissionSchemes;
  }

  /**
   *
   * MÉTODO QUE CAMBIA LOS ESQUEMAS DECOMISIÓN
   * @param {CommissionScheme} scheme
   * @memberof VariableConfigurationService
   */
  changeCommissionScheme(scheme: CommissionScheme): void {
    if (this.opciones.plan !== 'Universales') {
      this.excedentes.esquema_comision.next(scheme);
      let arregloDeFactores: Promise<any>[] = [];
      if (this.opciones.tipoPlan === 'MUJER UNICA') {
        arregloDeFactores = [
          this.opciones.generarFactoresValoresG(),
          this.opciones.generarFactoresMujerUnica(),
          this.opciones.generarFactoresValoresGMujerUnica()
        ];
      } else {
        arregloDeFactores = [
          this.opciones.generarFactores(),
          this.opciones.generarFactoresValoresG()
        ];
      }
      Promise.all(arregloDeFactores).then(() => {
        this.calculos.RECALCULAR();
      }).catch(err => { });
    } else {
      this.opciones.esquemaComisionUniv = scheme;
    }
  }
  /**
   * Método para cambiar las comisiones asignadas
   * @param {CommissionAssignment} tipo
   * @memberof VariableConfigurationService
   */
  changeCommissionAssignment(tipo: CommissionAssignment): void {
    const cesionComision = tipo === 'Si' ? 0 : 0.025;
    this.excedentes.string_cesion_comision.next(tipo === 'Si' ? 'Si' : 'No');
    this.excedentes.cesion_comision.next(cesionComision);
    this.calculos.RECALCULAR();
  }

}
