import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ServicesApi {
  constructor(private http: HttpClient) {

  }
  /**
   * Servicio para la optencion de coberturas Basicas Adicionales
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getServiceBA(query: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/getbasic',  query , { headers }).pipe(map(response => {
      return response;
    }, (error) => {
      return error;
    }));
  }
  /**
   * Servicio para la consulta de VALORES GARANTIZADOS
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getServiceVG(query: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/garanteed',  query , { headers }).pipe(map(response => {
      return response;
    }, (error) => {
      return error;
    }));
  }
  /**
   * Servicio para la consulta de aliados Kids BA
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getServiceKidsBa(query: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/kidsba',  query , { headers }).pipe(map(response => {
      return response;
    }, (error) => {
      return error;
    }));
  }
  /**
   *  Servicio para la consulta de aliados Kids VG
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getServiceKidsvg(query: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/kidsvg',  query , { headers }).pipe(map(response => {
      return response;
    }, (error) => {
      return error;
    }));
  }

  /**
   * Servicio para la consulta de PROTECANCER
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getServiceCancer(query: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/prote-cancer',  query , { headers }).pipe(map(response => {
      return response;
    }, (error) => {
      return error;
    }));
  }
  /**
   * Servicio para la consulta de PROTECCIÓN EFECTIVA
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getServiceEfect(query: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/effective-protection',  query , { headers }).pipe(map(response => {
      return response;
    }, (error) => {
      return error;
    }));
  }
/**
 * Servicio para la consulta de EDADES EQUIVALENTES
 * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
 * @return {*} 
 * @memberof ServicesApi
 */
getServiceAges(query:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/equivalent-ages',  query , { headers }).pipe(map(response => {
      return response;
    }, (error) => {
      return error;
    }));
  }
  /**
   * Servicio para la consulta de EDADES EQUIVALENTES universales
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getServiceAgesUniversal(query:any) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.post(environment.services + '/find/equivalent-ages-universal',  query , { headers }).pipe(map(response => {
        return response;
      }, (error) => {
        return error;
      }));
    }

  /**
   * Servicio para la consulta de Factores Universales
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getFactoresUniversales(query: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/factors-uni',   query , { headers }).pipe(map( response => {
      return response;
      },(error) => {
        return error;
      }));
  }

  /**
   * Servicio para la consulta de Cargo Adquisiciones Universales
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getChargueAcqui(query: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/charge-acqui',   query , { headers }).pipe(map( response => {
      return response;
      },(error) => {
        return error;
      }));
  }

  /**
   * Servicio para la consulta de Fx universales
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getFxUniversals(query: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/fx-universal',   query , { headers }).pipe(map( response => {
      return response;
      },(error) => {
        return error;
      }));
  }

  /**
   * Servicio para la consulta de Qx Universales
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getQxUniversals(query: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/qx-universals',   query , { headers }).pipe(map( response => {
      return response;
      },(error) => {
        return error;
      }));
  }

  /**
   * Servicio para la consulta de Deducibles
   * @param {*} query : {
   *  selector : { // Example
          "Edad":{
            "$in":[
              "43",
              "44",
              "45",
            ]
          },
          "Sexo":"H",
          "Fumador":"SI",
          "Esquema":"DECRECIENTE"
        }
   * }
   * @return {*} 
   * @memberof ServicesApi
   */
  getDeductible(query: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/deductible',   query , { headers }).pipe(map( response => {
      return response;
      },(error) => {
        return error;
      }));
  }

  getInpcDocs(query){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/docs',   query , { headers }).pipe(map( response => {
      return response;
      },(error) => {
        return error;
      }));
  }

  getInpcDocsVg(query){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.services + '/find/docs-vg',   query , { headers }).pipe(map( response => {
      return response;
      },(error) => {
        return error;
      }));
  }

}