/** CORE */
import { Component, OnInit, TemplateRef } from '@angular/core';
/** ROUTER */
import { Router } from '@angular/router';
/** FORM */
import { FormGroup } from '@angular/forms';
/** INTERFACES */
import { CommissionAssignment } from '@models/commission-scheme/commission-scheme.interface';
import { CommissionScheme } from '@models/commission-scheme/commission-scheme.interface';
import { Proposal } from '@models/proposal/proposal.interface';
import { CustomTerms } from '@models/terms/custom-terms.interface';
/** SERVICES */
import { CalculosService } from '@services/calculos/calculos.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { FactoresService } from '@services/factores/factores.service';
import { GeneradorTablaValoresService } from '@services/generadorTablaValores/generador-tabla-valores.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { PaymentFrequencyService } from '@services/paymentFrequency/payment-frequency.service';
import { PropuestasService } from '@services/propuestas/propuestas.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
// import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';
import { ResizeDetectionService } from '@services/resizeDetection/resize-detection.service';
import { ScrollPercentageDetectionService } from '@services/scrollPercentageDetection/scroll-percentage-detection.service';
import { VariableConfigurationService } from '@services/variableConfiguration/variable-configuration.service';
/** MODELS */
import { PaymentFrequency, PaymentValues } from '@models/payment-frequency/payment-frequency.type';
import { CommissionSchemeFront, CommissionAssignmentFront, PaymentType } from '@models/.';
import { Prospectu } from '../../models';
import { Proposals_Options } from '@models/proposals-model/proposalsModel';
/** CONSTANTS */
import { INSURED_DEADLINES } from '@constants/plan-options/insured-deadlines.constant';
import { PROPOSALS_OPTION_DEFAULT } from '@constants/proposals-constant/proposalsConstant';
/** NGX-BOOTSTRAP */
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
/** RXJS */
import { Observable, BehaviorSubject } from 'rxjs';
import { RoutesService } from '@app/services/routes/routes.service';
import { SPLIT_PAYMENT } from '@app/constants';
import { Plazo } from '@models/.';
import { ProjectionService } from './../../services/projection/projection.service';
import { Subject } from 'rxjs/Subject';
import { OnDestroy } from '@angular/core';
import { ViewChild } from '@angular/core';
import { FactorsSearch } from './../../models/factors-search/factors-search.model';
import { ValorGarantizado } from '@app/models/valor-garantizado/ValorGarantizado';

/**
 * COMPONENTE PARA PROPUESTAS
 * @export
 * @class ProposalsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.sass']
})
export class ProposalsComponent implements OnInit, OnDestroy {

  /**
 * Modal Loader
 * @property
 */
  @ViewChild('loader') loader: TemplateRef<any>;
  /**
 * Referencia del modal
 * @property
 * @type {BsModalRef}
 */
  modalRef: BsModalRef;

  /**
   * MODAL DE AJUSTE DE VARIABLES
   * @type {BsModalRef}
   * @memberof ProposalsComponent
   */
  bsModalRef: BsModalRef;


  /**
   * VARIABLES QUE ALMACENAN LA ESTRUCTURA DEL TIPO DE MONEDA
   * Y LA SUMA SEGURADA DE LAS COBERTURAS PARA PROPUESTA1
   * @type {Proposal}
   * @memberof ProposalsComponent
   */
  proposal1: Proposal;

  /**
   * VARIABLE QUE ALMACENA LA ESTRUCTURA DEL TIPO DE MONEDA Y LA SUMA ASEGURADA
   * DE LAS COBERTURAS PARA LA PROPUESTA2
   * @type {Proposal}
   * @memberof ProposalsComponent
   */
  proposal2: Proposal;

  /**
   * VARIABLE QUE ALMACENA LA ESTRUCTURA DEL TIPO DE MONEDA
   * Y LA SUMA ASEGURADA DE LAS COBERTURAS PARA LA PROPUESTA3
   * @type {Proposal}
   * @memberof ProposalsComponent
   */
  proposal3: Proposal;

  /**
   * VARIABLE QUE ALMACENA LA ESTRUCTURA BÁSICA
   * DE LOS TÉRMINOS PERSONALIZADOS.
   * @type {CustomTerms}
   * @memberof ProposalsComponent
   */
  plazos: CustomTerms;

  /**
   * VARIABLE QUE ALMACENA UN IDENTIFICADOR DE LAS PROPUESTAS
   * @type {Proposals_Options}
   * @memberof ProposalsComponent
   */
  currentOption: Proposals_Options;

  /**
   * VARIABLE PARA LA CREACIÓN DEL FORMULARIO
   * @type {FormGroup}
   * @memberof ProposalsComponent
   */
  opcionFormGroup: FormGroup;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedB1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedBI1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedPAI1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedCC1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedDI1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedDIPO1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedDIPOC1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedIAC1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedPAE1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedPCD1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedGF1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedPB1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedPBC1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA1
   * @memberof ProposalsComponent
   */
  isCollapsedPBCA1 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedB2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedBI2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPAI2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedCC2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedDI2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedDIPO2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedDIPOC2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedIAC2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPAE2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPCD2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedGF2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPB2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPBC2 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA2
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPBCA2 = true;


  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedB3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedBI3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPAI3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedCC3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedDI3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedDIPO3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedDIPOC3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedIAC3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPAE3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPCD3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedGF3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPB3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPBC3 = true;

  /**
   * VARIABLE DE ESTADO PARA COLLAPSE PARA PROPUESTA3
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  isCollapsedPBCA3 = true;

  /**
   * VARIBLE QUE OBTIENE LA SUMA ASEGURADA EN UDI
   * PARA LA COBERTURA BÁSICA EN PROPUESTA1
   * @type {string}
   * @memberof ProposalsComponent
   */
  sumaAseguradaUdiBA1: string;

  /**
   * VARIBLE QUE OBTIENE LA PRIMA ANUAL EN UDI
   * PARA LA COBERTURA BÁSICA EN PROPUESTA1
   * @type {number}
   * @memberof ProposalsComponent
   */
  primaAnualUdiBA1: number;

  /**
   * VARIBLE QUE OBTIENE LA SUMA ASEGURADA EN UDI
   * PARA LA COBERTURA BÁSICA EN PROPUESTA2
   * @type {string}
   * @memberof ProposalsComponent
   */
  sumaAseguradaUdiBA2: string;

  /**
   * VARIBLE QUE OBTIENE LA PRIMA ANUAL EN UDI
   * PARA LA COBERTURA BÁSICA EN PROPUESTA2
   * @type {number}
   * @memberof ProposalsComponent
   */
  primaAnualUdiBA2: number;

  /**
   * VARIABLE QUE ALMACENA LA PROPUESTA ELEGIDA
   * @type {number}
   * @memberof ProposalsComponent
   */
  propuestaElegida: number;

  /**
   * ARRAY QUE ALMACENA LOS PLAZOS SUGERIDOS
   * @type {number[]}
   * @memberof ProposalsComponent
   */
  plazosSugeridos: number[] = [];

  /**
   * PROPIEDAD QUE ALMACENAN LA PRIMA TOTAL FRACCIONADA
   * EN UDIs DE LA PROPUESTA1
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalFraccionadaSinDividir: Observable<number>;

  /**
   * PROPIEDAD QUE ALMACENAN LA PRIMA TOTAL FRACCIONADA
   * EN PESOS DE LA PROPUESTA1
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalMoneda2SinDividir: Observable<number>;

  /**
   * PROPIEDAD QUE ALMACENAN LA PRIMA TOTAL
   * EN UDIs DE LA PROPUESTA1
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalFraccionadaDividida: Observable<number>;

  /**
   * PROPIEDAD QUE ALMACENAN LA PRIMA TOTAL
   * EN PESOS DE LA PROPUESTA1
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalMoneda2Dividida: Observable<number>;

  /**
   * PROPIEDAD QUE ALMACENAN LA PRIMA TOTAL FRACCIONADA
   * EN UDIs DE LA PROPUESTA SUGERIDA1
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalFraccionadaSinDividir_Sugerida1: Observable<number>;

  /**
   * PROPIEDAD QUE ALMACENAN LA PRIMA TOTAL FRACCIONADA
   * EN PESOS DE LA PROPUESTA SUGERIDA1
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalMoneda2SinDividir_Sugerida1: Observable<number>;

  /**
   * PROPIEDAD QUE ALMACENAN LA PRIMA TOTAL
   * EN UDIs DE LA PROPUESTA SUGERIDA1
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalFraccionadaDividida_Sugerida1: Observable<number>;

  /**
   * PROPIEDAD QUE ALMACENAN LA PRIMA TOTAL FRACCIONADA
   * EN PESOS DE LA PROPUESTA SUGERIDA1
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalMoneda2Dividida_Sugerida1: Observable<number>;

  /**
   * PROPIEDADES QUE ALMACENAN LA PRIMA TOTAL FRACCIONADA
   * EN UDIs DE LA PROPUESTA SUGERIDA2
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalFraccionadaSinDividir_Sugerida2: Observable<number>;

  /**
   * PROPIEDADES QUE ALMACENAN LA PRIMA TOTAL FRACCIONADA
   * EN PESOS DE LA PROPUESTA SUGERIDA2
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalMoneda2SinDividir_Sugerida2: Observable<number>;

  /**
   * PROPIEDADES QUE ALMACENAN LA PRIMA TOTAL
   * EN UDIs DE LA PROPUESTA SUGERIDA2
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalFraccionadaDividida_Sugerida2: Observable<number>;

  /**
   * PROPIEDADES QUE ALMACENAN LA PRIMA TOTAL FRACCIONADA
   * EN PESOS DE LA PROPUESTA SUGERIDA2
   * @type {Observable<number>}
   * @memberof ProposalsComponent
   */
  primaTotalMoneda2Dividida_Sugerida2: Observable<number>;

  /**
   * ARRAY | ARREGLO QUE TRAE LOS PLAZOS SUGERIDOS
   * @type {number[]}
   * @memberof ProposalsComponent
   */
  plazoGSugeridos: number[] = [];

  /**
   * VARIABLE QUE ALMACENA EL PORCENTAJE DE RECUPERACIÓN DE LA PROPUESTA ELEGIDA
   * @type {number}
   * @memberof ProposalsComponent
   */
  porcentajeRecuperacion_ElegidoBa: number;
  porcentajeRecuperacion_ElegidoBaCo: number;
  porcentajeRecuperacion_ElegidoBaCoExcd: number;

  /**
   * VARIABLE PARA EL VALOR DE RESCATE DE LA PROPUESTA ELEGIDA
   * @type {number}
   * @memberof ProposalsComponent
   */
  porcentajeRecuperacionPesosBa: number;
  porcentajeRecuperacionPesosBaCo: number;
  porcentajeRecuperacionPesosBaCoExcd: number;

  /**
   * VARIABLE PARA EL PORCENTAJE DE RECUPERACIÓN DE LA PROPUESTA SUGERIDA1
   * @type {number}
   * @memberof ProposalsComponent
   */
  porcentajeRecuperacion_Sugerido1: number;
  porcentajeRecuperacion_Sugerido1Co: number;
  porcentajeRecuperacion_Sugerido1CoExcd: number;
  /**
   * VARIABLE PARA EL VALOR DE RESCATE DE LA PROPUESTA SUGERIDA1
   * @type {number}
   * @memberof ProposalsComponent
   */
  porcentajeRecuperacionPesosBa_Sugerido1: number;
  porcentajeRecuperacionPesosBaCo_Sugerido1: number;
  porcentajeRecuperacionPesosBaCoExcd_Sugerido1: number;

  /**
   * VARIABLE PARA EL PORCENTAJE DE RECUPERACIÓN PARA LA PROPUESTA SUGERIDA2
   * @type {number}
   * @memberof ProposalsComponent
   */
  porcentajeRecuperacion_Sugerido2: number;
  porcentajeRecuperacion_Sugerido2Co: number;
  porcentajeRecuperacion_Sugerido2CoExcd: number;

  /**
   * VARIABLE PARA EL VALOR DE RESCATE DE LA PROPUESTA SUGERIDA2
   * @type {number}
   * @memberof ProposalsComponent
   */
  porcentajeRecuperacionPesosBa_Sugerido2: number;
  porcentajeRecuperacionPesosBaCo_Sugerido2: number;
  porcentajeRecuperacionPesosBaCoExcd_Sugerido2: number;

  valorRescateUdiBa: number;
  valorRescatePesosBa: number;
  valorRescateUdiBaCo: number;
  valorRescatePesosBaCo: number;
  valorRescateUdiBaCoExcd: number;
  valorRescatePesosBaCoExcd: number;

  valorRescateUdiBaSugerido1: number;
  valorRescatePesosBaSugerido1: number;
  valorRescateUdiBaCoSugerido1: number;
  valorRescatePesosBaCoSugerido1: number;
  valorRescateUdiBaCoExcdSugerido1: number;
  valorRescatePesosBaCoExcdSugerido1: number;

  valorRescateUdiBaSugerido2: number;
  valorRescatePesosBaSugerido2: number;
  valorRescateUdiBaCoSugerido2: number;
  valorRescatePesosBaCoSugerido2: number;
  valorRescateUdiBaCoExcdSugerido2: number;
  valorRescatePesosBaCoExcdSugerido2: number;

  /**
   * VARIABLE QUE ALMACENA LA ESTRUCTURA DEL ESQUEMA DE COMISIÓN
   * @type {CommissionSchemeFront[]}
   * @memberof ProposalsComponent
   */
  schemes: CommissionSchemeFront[] = [];

  /**
   * VARIABLE QUE ALMACENA LA ESTRUCTURA DEL VALOR DE LA ASIGNACIÓN DE COMISIONES
   * @type {CommissionAssignmentFront[]}
   * @memberof ProposalsComponent
   */
  assignments: CommissionAssignmentFront[] = [];

  /**
   * VARIABLE QUE ALMACENA LA ESTRUCTURA DEL VALOR DE LA ASIGNACIÓN DE COMISIONES
   * @type {PaymentValues[]}
   * @memberof ProposalsComponent
   */
  frecuenciasPago: PaymentValues[] = [];

  /**
   * VARIABLE PARA ALMACENAR EL RECARGO FIJO
   * @type {string}
   * @default "0%"
   * @memberof ProposalsComponent
   */
  recargoFijo = '0%';

  /**
   * VARIABLE DONDE ALMACENA EL RECARGO ACTUAL
   * @type {number}
   * @default "0"
   * @memberof ProposalsComponent
   */
  recargoActual = 0;

  /**
   * VARIABLE PARA DIVISOR ACTUAL
   * @type {number}
   * @default "0"
   * @memberof ProposalsComponent
   */
  divisorActual = 0;

  /**
   * VARIABLE QUE ALMACENA LA FRECUENCIA DE PAGO
   * @type {string}
   * @default "anual"
   * @memberof ProposalsComponent
   */
  frecuenciapago = 'anual';

  /**
   * VARIABLE BOOLEANA QUE IDENTIFICA
   * SI SE ESTA EJECUTANDO O NO EN UN DISPOSITIVO
   * @type {boolean}
   * @memberof ProposalsComponent
   */
  dispositivo: boolean;

  /**
   * VARIBLE PARA IDENTIFICAR LA ORIENTIACIÓN
   *  EN LA QUE EL DISPOSITIVO SE ENCUENTRA PORTRAIR | LANDSCAPE
   * @type {string}
   * @memberof ProposalsComponent
   */
  orient: string;

  /**
   * VARIABLE PARA IDENTIFICAR EL DISPOSITIVO
   * EN EL QUE SE ESTE EJECUTANDO
   * @type{string}
   * @memberof ProposalsComponent
   */
  dev: string;

  /**
   * VARIABLE QUE ALMACENA EL COMPORTAMIENTO
   * QUE TENDRÁ EL TOOLTIP EN MOBILE Y DESKTOP
   * click | hover
   * @type {string}
   * @memberof ProposalsComponent
   */
  toggle: string;

  /**
   * VARIABLE PARA ALMACENAR EL TIPO DE PROYECCIÓN
   * DE LA PROPUESTA ELEGIDA
   * @type {number}
   * @memberof ProposalsComponent
   */
  tipoProyeccion_Elegido: number;

  /**
   * VARIABLE PARA ALMACENAR EL TIPO DE PROYECCIÓN
   * DE LA PROPUESTA SUGERIDA1
   * @type {number}
   * @memberof ProposalsComponent
   */
  // tipoProyeccion_Sugerido1: number;

  /**
   * VARIABLE QUE ALMACENA EL TIPO DE PROYECCIÓN
   * DE LA PORPUESTA SUGERIDA2
   * @type {number}
   * @memberof ProposalsComponent
   */
  // tipoProyeccion_Sugerido2: number;

  /**
   * PROPIEDAD QUE ALMACENA LOS DATOS DEL PROSPECTO ACTUAL
   * @type {BehaviorSubject<Prospectu>}
   * @memberof ProposalsComponent
   */
  prospecto: BehaviorSubject<Prospectu>;

  /**
   * ALMACENA EL NÚMERO DE PROPUESTAS A MOSTRAR
   * @type {number}
   * @memberof ProposalsComponent
   */
  nProposals: number;

  /**
   * BOOLEANO QUE MUESTRA SI EL PLAN ES ALIADOS+
   * @type {string}
   * @memberof ProposalsComponent
   */
  isDotal: boolean;
  /**
   * Variable edad para realizar calculo edad prospecto / mancomunado
   */
  edadInicial: any;
  plazoPago1: String;
  plazoPago2: String;
  plazoPago3: String;
  private ngUnsubscribe = new Subject();


  /**
   * CREA UNA INSTANCIA DE ProposalsComponent.
   * @constructor
   * @param {ProspectoService} prospectoService CREA UNA INSTANCIA DE PospectoService
   * @param {CalculosService} calculos CREA UNA INSTANCIA DE CalculosService
   * @param {OpcionesPlanService} opciones CREA UNA INSTANCIA DE OpcionesPlanService
   * @param {PropuestasService} propuestas CREA UNA INSTANCIA DE PropuestasService
   * @param {Router} router CREA UNA INSTANCIA DE Router
   * @param {ResizeDetectionService} resizeDetection CREA UNA INSTANCIA DE ReziseDetectionService
   * @param {ScrollPercentageDetectionService} scrollPercent CREA UNA INSTANCIA DE ScrollPercentageDetectionService
   * @param {BsModalService} modalService CREA UNA INSTANCIA DE BsModalService
   * @param {ProtectionPlansGeneratorService} protectionPlans CREA UNA INSTANCIA DE ProtectionPlansGeneratorService
   * @param {GeneradorTablaValoresService} generadorTabla CREA UNA INSTANCIA DE GeneradorTablaValoresService
   * @param {ProtectionPlansGeneratorService} protectionPlans CREA UNA INSTANCIA DE ProtectionPlansGeneratorService
   * @param {FactoresService} factores CREA UNA INSTANCIA DE FactoresService
   * @param {ExcedentesService} excedentes CREA UNA INSTANCIA DE ExcedentesService
   * @param {VariableConfigurationService} variableConfiguration CREA UNA INSTANCIA DE VariableConfigurationService
   * @param {PaymentFrequencyService} paymentFrequency CREA UNA INSTANCIA DE PaymentFrequencyService
   * @param {DeviceTypeService} device CREA UNA INSTANCIA DE DeviceTypeService
   * @memberof ProposalsComponent
   */
   constructor(
    public prospectoService: ProspectoService,
    public calculos: CalculosService,
    public opciones: OpcionesPlanService,
    private propuestas: PropuestasService,
    private router: Router,
    public resizeDetection: ResizeDetectionService,
    public scrollPercent: ScrollPercentageDetectionService,
    private modalService: BsModalService,
    // private protectionPlans: ProtectionPlansGeneratorService,
    public generadorTabla: GeneradorTablaValoresService,
    private factores: FactoresService,
    private excedentes: ExcedentesService,
    private variableConfiguration: VariableConfigurationService,
    private paymentFrequency: PaymentFrequencyService,
    private device: DeviceTypeService,
    private routes: RoutesService,
    private projection: ProjectionService
  ) {
    const screen = this.opciones.screen.value;
    this.asignarPropuestas();
    this.prospecto = this.prospectoService.prospecto;
    if (screen === 2) {
      let arregloDeFactores: Promise<any>[] = [];
      this.asignarPropuestas();
      this.prospecto = this.prospectoService.prospecto;

      arregloDeFactores = [
        this.opciones.generarFactoresPropuestas(),
        this.opciones.generarFactoresValoresGPropuestas()
      ];
      Promise.all(arregloDeFactores).then(() => {
        this.calculos.RECALCULAR(true);
        this.values();
        this.getValoresGarantizados();
        this.modalRef.hide();
      });
      this.values();
    } else {
      this.values();
    }
    this.propuestaElegida = 0;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.unsubscribe();
  }

  /**
   * MÉTODO INICIAL
   * @memberof ProposalsComponent
   */
  ngOnInit() {
    if ( this.opciones.screen.value === 2 ) {
      this.modalRef = this.modalService.show(this.loader, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'loader-modal'
      });
    }
  }

  values() {
    this.nProposals = this.opciones.arrayListPlan.length;
    this.plazosSugeridos = this.propuestas.initPlazos();
    // GLOBALES

    // 1
    this.primaTotalFraccionadaSinDividir = this.calculos.primaTotalFraccionadaSinDividir$;
    this.primaTotalMoneda2SinDividir = this.calculos.primaTotalMoneda2SinDividir$;

    this.primaTotalFraccionadaDividida = this.calculos.primaTotalFraccionadaDividida$;
    this.primaTotalMoneda2Dividida = this.calculos.primaTotalMoneda2Dividida$;

    // 2

    this.primaTotalFraccionadaSinDividir_Sugerida1 = this.calculos.primaTotalFraccionadaSinDividir_Sugerida1.asObservable();
    this.primaTotalMoneda2SinDividir_Sugerida1 = this.calculos.primaTotalMoneda2SinDividir_Sugerida1.asObservable();

    this.primaTotalFraccionadaDividida_Sugerida1 = this.calculos.primaTotalFraccionadaDividida_Sugerida1.asObservable();
    this.primaTotalMoneda2Dividida_Sugerida1 = this.calculos.primaTotalMoneda2Dividida_Sugerida1.asObservable();


    // 3

    this.primaTotalFraccionadaSinDividir_Sugerida2 = this.calculos.primaTotalFraccionadaSinDividir_Sugerida2.asObservable();
    this.primaTotalMoneda2SinDividir_Sugerida2 = this.calculos.primaTotalMoneda2SinDividir_Sugerida2.asObservable();

    this.primaTotalFraccionadaDividida_Sugerida2 = this.calculos.primaTotalFraccionadaDividida_Sugerida2.asObservable();
    this.primaTotalMoneda2Dividida_Sugerida2 = this.calculos.primaTotalMoneda2Dividida_Sugerida2.asObservable();

    this.asignarPropuestas();
    this.plazoPago1 = 'Elegido';
    this.plazoPago2 = 'Sugerido1';
    this.plazoPago3 = 'Sugerido2';
    this.devtype();
    this.triggerTooltip();

    if (this.device.getDeviceType() === 'iPhone') {
      window.scrollTo({
        top: 345,
        behavior: 'smooth'
      });
    }

    this.frecuenciasPago = this.paymentFrequency.generatePaymentFrequencies();

    this.excedentes.esquema_comision.subscribe(() => {
      this.schemes = this.variableConfiguration.generateCommissionSchemes();
    });
    this.excedentes.cesion_comision.subscribe(() => {
      this.assignments = this.variableConfiguration.generateCommissionAssignment();
    });

    this.propuestaElegida = 0;
    this.currentOption = <Proposals_Options>PROPOSALS_OPTION_DEFAULT.DEFAULT_OPTIONS;
    this.tipoProyeccion_Elegido = this.projection.getProjectionType();
    if (this.opciones.tipoPlan === 'DOTAL') {
      return this.isDotal = true;
    } else {
      return this.isDotal = false;
    }

  }

  asignarPropuestas() {
    this.proposal1 = this.propuestas.createProposal_Elegida();
    this.proposal2 = this.propuestas.createProposal_Sugerida1();
    this.proposal3 = this.propuestas.createProposal_Sugerida2();
  }

  getValoresGarantizados() {
    Observable.combineLatest([
      this.excedentes.esquema_comision,
      this.excedentes.cesion_comision
    ]).subscribe((data) => {
      this.generateGuaranteedValues();
    });
  }

  /**
   * MÉTODO PARA DETECTAR EL DISPOSITIVO EN EL QUE SE EJECUTA
   *
   * @returns
   * @memberof ProposalsComponent
   */
  devtype() {
    if (this.device.getDeviceType() === 'iPad') {
      this.orient = this.device.getOrientation(window.innerWidth, window.innerHeight);
      this.dev = 'iPad';
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'iPhone') {
      this.dev = 'iPhone';
      this.orient = this.device.getOrientation(window.innerWidth, window.innerHeight);
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'Android') {
      this.dev = 'Android';
      this.orient = this.device.getOrientation(window.innerWidth, window.innerHeight);
      return this.dispositivo = true;
    } else {
      this.dev = 'Desktop';
      this.orient = this.device.getOrientation(window.innerWidth, window.innerHeight);
      return this.dispositivo = false;
    }
  }

  /**
   * MÉTODO PARA TOOLTIP TOGGLE MOBILE
   * @memberof ProposalsComponent
   */
  triggerTooltip() {
    if (this.dev === 'iPad' && this.orient === 'landscape') {
      this.toggle = 'click';
    } else if (this.dev === 'Desktop') {
      this.toggle = 'hover';
    }
  }

  /**
   * MÉTODO QUE TRAE EL AÑO DE POLIZA ELEGIDO
   * @param {number} anio
   * @memberof ProposalsComponent
   */
  elegirAnioPoliza_Elegido(anio: number) {
    if (this.tipoProyeccion_Elegido === 0) {
      this.porcentajeRecuperacion_ElegidoBa = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBa = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).porcentajeRecuperacionPesos;
      this.valorRescateUdiBa = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).valorRescate;
      this.valorRescatePesosBa = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).valorRescate_Pesos;

    } else {
      this.porcentajeRecuperacion_ElegidoBa = this.obtenerValorGarAnio(this.generadorTabla.tabla_ElegidaBA.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBa = this.obtenerValorGarAnio(this.generadorTabla.tabla_ElegidaBA.value, anio).porcentajeRecuperacionPesos;
      this.porcentajeRecuperacion_ElegidoBaCo = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBaCo = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).porcentajeRecuperacionPesos;

      this.valorRescateUdiBa = this.obtenerValorGarAnio(this.generadorTabla.tabla_ElegidaBA.value, anio).valorRescate;
      this.valorRescatePesosBa = this.obtenerValorGarAnio(this.generadorTabla.tabla_ElegidaBA.value, anio).valorRescate_Pesos;
      this.valorRescateUdiBaCo = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).valorRescate;
      this.valorRescatePesosBaCo = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).valorRescate_Pesos;
    }
    if (this.tipoProyeccion_Elegido === 2) {
      this.porcentajeRecuperacion_ElegidoBaCoExcd = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).porcentajeRecuperacionUdiBaCoExcd;
      this.porcentajeRecuperacionPesosBaCoExcd = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).porcentajeRecuperacionPesosBaCoExcd;
      this.valorRescateUdiBaCoExcd = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).valorEnEfectivo_UDI;
      this.valorRescatePesosBaCoExcd = this.obtenerValorGarAnio(this.generadorTabla.tabla_Elegida.value, anio).valorEnEfectivo_Pesos;
    }

  }

  /**
   * MÉTODO QUE TRAE EL AÑO DE POLIZA SUGERIDO 1
   * @param {number} anio
   * @memberof ProposalsComponent
   */
  elegirAnioPoliza_Sugerido1(anio: number) {
    if (this.tipoProyeccion_Elegido === 0) {
      this.porcentajeRecuperacion_Sugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBa_Sugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).porcentajeRecuperacionPesos;
      this.valorRescateUdiBaSugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).valorRescate;
      this.valorRescatePesosBaSugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).valorRescate_Pesos;
    } else {
      this.porcentajeRecuperacion_Sugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1BA.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBa_Sugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1BA.value, anio).porcentajeRecuperacionPesos;
      this.porcentajeRecuperacion_Sugerido1Co = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBaCo_Sugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).porcentajeRecuperacionPesos;

      this.valorRescateUdiBaSugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1BA.value, anio).valorRescate;
      this.valorRescatePesosBaSugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1BA.value, anio).valorRescate_Pesos;
      this.valorRescateUdiBaCoSugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).valorRescate;
      this.valorRescatePesosBaCoSugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).valorRescate_Pesos;
    }
    if (this.tipoProyeccion_Elegido === 2) {
      this.porcentajeRecuperacion_Sugerido1CoExcd = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).porcentajeRecuperacionUdiBaCoExcd;
      this.porcentajeRecuperacionPesosBaCoExcd_Sugerido1 =
        this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).porcentajeRecuperacionPesosBaCoExcd;
      this.valorRescateUdiBaCoExcdSugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).valorEnEfectivo_UDI;
      this.valorRescatePesosBaCoExcdSugerido1 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida1.value, anio).valorEnEfectivo_Pesos;
    }

  }

  /**
   * MÉTODO QUE TRAE EL AÑO DE POLIZA SUGERIDO 2
   * @param {number} anio
   * @memberof ProposalsComponent
   */
  elegirAnioPoliza_Sugerido2(anio: number) {
    if (this.tipoProyeccion_Elegido === 0) {
      this.porcentajeRecuperacion_Sugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBa_Sugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).porcentajeRecuperacionPesos;
      this.valorRescateUdiBaSugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).valorRescate;
      this.valorRescatePesosBaSugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).valorRescate_Pesos;
    } else {
      this.porcentajeRecuperacion_Sugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2BA.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBa_Sugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2BA.value, anio).porcentajeRecuperacionPesos;
      this.porcentajeRecuperacion_Sugerido2Co = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).porcentajeRecuperacionUdi;
      this.porcentajeRecuperacionPesosBaCo_Sugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).porcentajeRecuperacionPesos;

      this.valorRescateUdiBaSugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2BA.value, anio).valorRescate;
      this.valorRescatePesosBaSugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2BA.value, anio).valorRescate_Pesos;
      this.valorRescateUdiBaCoSugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).valorRescate;
      this.valorRescatePesosBaCoSugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).valorRescate_Pesos;
    }
    if (this.tipoProyeccion_Elegido === 2) {
      this.porcentajeRecuperacion_Sugerido2CoExcd = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).porcentajeRecuperacionUdiBaCoExcd;
      this.porcentajeRecuperacionPesosBaCoExcd_Sugerido2 =
        this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).porcentajeRecuperacionPesosBaCoExcd;
      this.valorRescateUdiBaCoExcdSugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).valorEnEfectivo_UDI;
      this.valorRescatePesosBaCoExcdSugerido2 = this.obtenerValorGarAnio(this.generadorTabla.tabla_Sugerida2.value, anio).valorEnEfectivo_Pesos;
    }
  }

  obtenerValorGarAnio(vg: ValorGarantizado[], anio: number): ValorGarantizado {
    return vg.filter(s => s.anioPoliza === Number(anio))[0];
  }



  /**
   * MÉTODO PARA GENERAR LOS  VALORES GARANTIZADOS SEGUN LA PROPUESTA ELEGIDA
   * @memberof ProposalsComponent
   */
  generateGuaranteedValues() {
    const plazosTotales_Elegido = this.obtenerPlazoTotal(this.opciones.arrParams[0]);
    const plazosTotales_Sugerida1 = this.obtenerPlazoTotal(this.opciones.arrParams[1]);
    let plazosTotales_Sugerida2;
    if (this.nProposals === 3) {
      plazosTotales_Sugerida2 = this.obtenerPlazoTotal(this.opciones.arrParams[2]);
    }

    let tablaValoresPropuesta_Elegida = [];
    let tablaValoresPropuesta_Sugerida1 = [];
    let tablaValoresPropuesta_Sugerida2 = [];
    let ElegidaBA = [];
    let Sugerido1BA = [];
    let Sugerido2BA = [];
    tablaValoresPropuesta_Elegida = this.generadorTabla.generarTablaValoresPropuestas(
      plazosTotales_Elegido, this.edadInicial, this.plazoPago1 as Plazo, this.opciones.arrParams[0].plazoPago, this.opciones.moneda, true,
      this.opciones.arrParams[0]) || [];

    tablaValoresPropuesta_Sugerida1 = this.generadorTabla.generarTablaValoresPropuestas(
      plazosTotales_Sugerida1, this.edadInicial, this.plazoPago2 as Plazo, this.opciones.arrParams[1].plazoPago, this.opciones.moneda, true,
      this.opciones.arrParams[1]) || [];
    if (this.nProposals === 3) {
      tablaValoresPropuesta_Sugerida2 = this.generadorTabla.generarTablaValoresPropuestas(
        plazosTotales_Sugerida2, this.edadInicial, this.plazoPago3 as Plazo, this.opciones.arrParams[2].plazoPago, this.opciones.moneda, false,
        this.opciones.arrParams[2]) || [];
    }

    if (this.tipoProyeccion_Elegido === 1 || this.tipoProyeccion_Elegido === 2) {
      ElegidaBA = this.generadorTabla.generarTablaValoresPropuestas(
        plazosTotales_Elegido, this.edadInicial, this.plazoPago1 + 'BA' as Plazo, this.opciones.arrParams[0].plazoPago, this.opciones.moneda, true,
        this.opciones.arrParams[0]) || [];
      Sugerido1BA = this.generadorTabla.generarTablaValoresPropuestas(
        plazosTotales_Sugerida1, this.edadInicial, this.plazoPago2 + 'BA' as Plazo, this.opciones.arrParams[1].plazoPago, this.opciones.moneda, true,
        this.opciones.arrParams[1]) || [];
      if (this.nProposals === 3) {
        Sugerido2BA = this.generadorTabla.generarTablaValoresPropuestas(
          plazosTotales_Sugerida2, this.edadInicial, this.plazoPago3 + 'BA' as Plazo, this.opciones.arrParams[2].plazoPago, this.opciones.moneda, false,
          this.opciones.arrParams[2]) || [];
      }

      this.generadorTabla.tabla_ElegidaBA.next(ElegidaBA);
      this.generadorTabla.tabla_Sugerida1BA.next(Sugerido1BA);
      this.generadorTabla.tabla_Sugerida2BA.next(Sugerido2BA);
    }
    this.generadorTabla.tabla_Elegida.next(tablaValoresPropuesta_Elegida);
    this.generadorTabla.tabla_Sugerida1.next(tablaValoresPropuesta_Sugerida1);
    this.generadorTabla.tabla_Sugerida2.next(tablaValoresPropuesta_Sugerida2);
  }

  obtenerPlazoTotal(params: FactorsSearch): number {
    let pT = 0;
    if (params.tipoPlan !== 'DOTAL' || this.opciones.generateProposals.value) {
      // this.opciones.generateProposals.next(false);
      let edadFinal = 0;
      if (params.tipoPlan === 'TEMPORAL') {
        edadFinal = params.plazoSeguro;
      } else {
        edadFinal = INSURED_DEADLINES.filter(coverage => coverage.plan === params.tipoPlan)[0].term;
      }
      if (this.prospectoService.prospecto.value.mancomunado.mancomunado) {

        this.edadInicial = this.prospectoService.prospecto.value.mancomunado.edadEquivalente;
      } else {
        this.edadInicial = this.prospectoService.prospecto.value.age;
      }

      if (params.tipoPlan === 'TEMPORAL') {
        edadFinal = (Number(params.plazoPago) + Number(this.prospectoService.prospecto.value.age));
      }
      switch (true) {
        case (params.tipoPlan === 'DOTAL' && params.plazoPago === 15) ||
          (params.tipoPlan === 'DOTAL' && params.plazoPago === 20):
          pT = Number(params.plazoPago);
          break;
        case params.tipoPlan === 'DOTAL' && params.plazoPago === 65:
          pT = 65;
          break;
        case (params.tipoPlan === 'TEMPORAL' && params.plazoPago === 60) ||
          (params.tipoPlan === 'TEMPORAL' && params.plazoPago === 70) ||
          (params.tipoPlan === 'TEMPORAL' && params.plazoPago === 65):
          pT = params.plazoPago - this.edadInicial;
          break;
        case params.tipoPlan === 'TEMPORAL':
          pT = params.plazoPago;
          break;
        case params.tipoPlan === 'OV':
          pT = 100 - this.edadInicial;
          break;
        default:
          pT = edadFinal - this.edadInicial + 1;
          break;
      }
    }
    return pT;
  }

  /**
   * MÉTODO PARA DETERMINAR LA OPCIÓN(PROPUESTA) ELEGIDA
   * @param {Proposals_Options} option
   * @memberof ProposalsComponent
   */
  setActiveOpc(option: Proposals_Options): void {
    switch (option) {
      case 'opc1':
        this.propuestaElegida = 0;
        break;
      case 'opc2':
        this.propuestaElegida = 1;
        break;
      case 'opc3':
        this.propuestaElegida = 2;
        break;
    }
    this.currentOption = option;
  }

  /**
   * MÉTODO PARA DETECTAR LA OPCIÓN(PROPUESTA) QUE ESTA ACTIVA
   * @param {Proposals_Options} option
   * @returns {boolean}
   * @memberof ProposalsComponent
   */
  isActiveOption(option: Proposals_Options): boolean {
    return this.currentOption === option;
  }

  /**
   * MÉTODO QUE REALIZA LA REDIRECCIÓN A VALORES GARANTIZADOS
   * GENERANDO LA TABLA DE VALORES DE ACUERDO
   * A LA PROPUESTA QUE SE HAYA ELEGIDO
   * @param {number} oferta
   * @memberof ProposalsComponent
   */
  goToValores(oferta: number) {
    const ofertaSeleccionada = oferta !== -1 ? oferta : this.propuestaElegida;
    const multiplier = SPLIT_PAYMENT.filter(i => {
      let splitPayment: PaymentType = 'yearly';
      switch (this.paymentFrequency.frecuenciaPago.value) {
        case 'ANUAL':
          splitPayment = 'yearly';
          break;

        case 'SEMESTRAL':
          splitPayment = 'semi-annually';
          break;

        case 'TRIMESTRAL':
          splitPayment = 'quarterly';
          break;

        case 'MENSUAL':
          splitPayment = 'monthly';
          break;
      }

      return i.type === splitPayment;
    })[0].surcharge[this.opciones.moneda];
    this.generadorTabla.ofertaSeleccionada.next(ofertaSeleccionada);
    switch (true) {
      case ofertaSeleccionada === 0:
        this.generadorTabla.tabla_Actual.next(this.generadorTabla.tabla_Elegida.value);
        this.generadorTabla.tabla_ActualBa.next(this.generadorTabla.tabla_ElegidaBA.value);
        this.generadorTabla.paramsElegido.next(this.opciones.arrParams[0]);
        this.calculos.primaTotalFraccionadaDividida_Actual.next(this.calculos.primaTotalFraccionadaDividida.value);
        this.factores.factoresActual = this.factores.factoresCoberturaElegido;
        this.factores.factoresValoresActual = this.factores.factoresValoresElegido;
        this.calculos.primaAnual_ConRecargo_Actual.next(this.generadorTabla.tabla_Elegida.value[0].primaElegida * (1 + multiplier));
        break;
      case ofertaSeleccionada === 1:
        this.generadorTabla.tabla_Actual.next(this.generadorTabla.tabla_Sugerida1.value);
        this.generadorTabla.tabla_ActualBa.next(this.generadorTabla.tabla_Sugerida1BA.value);
        this.generadorTabla.paramsElegido.next(this.opciones.arrParams[1]);
        this.calculos.primaTotalFraccionadaDividida_Actual.next(this.calculos.primaTotalFraccionadaDividida_Sugerida1.value);
        this.factores.factoresActual = this.factores.factoresCoberturaSugerido1;
        this.factores.factoresValoresActual = this.factores.factoresValoresSugerido1;
        this.calculos.primaAnual_ConRecargo_Actual.next(this.generadorTabla.tabla_Sugerida1.value[0].primaElegida * (1 + multiplier));
        break;
      case ofertaSeleccionada === 2:
        this.generadorTabla.tabla_Actual.next(this.generadorTabla.tabla_Sugerida2.value);
        this.generadorTabla.tabla_ActualBa.next(this.generadorTabla.tabla_Sugerida2BA.value);
        this.generadorTabla.paramsElegido.next(this.opciones.arrParams[2]);
        this.calculos.primaTotalFraccionadaDividida_Actual.next(this.calculos.primaTotalFraccionadaDividida_Sugerida2.value);
        this.factores.factoresActual = this.factores.factoresCoberturaSugerido2;
        this.factores.factoresValoresActual = this.factores.factoresValoresSugerido2;
        this.calculos.primaAnual_ConRecargo_Actual.next(this.generadorTabla.tabla_Sugerida2.value[0].primaElegida * (1 + multiplier));
        break;
      default:
        this.generadorTabla.tabla_Actual.next(this.generadorTabla.tabla_Elegida.value);
        this.generadorTabla.tabla_ActualBa.next(this.generadorTabla.tabla_ElegidaBA.value);
        this.opciones.plazoPago.next(this.plazoGSugeridos[1]);
        break;
    }

    this.router.navigate(['cotizador', this.routes.chosenCotizador.value, 'valores-garantizados']);
  }

  /**
   * MÉTODO PARA REGRESAR A LA PANTALLA DE ARMA TU PLAN
   * @memberof ProposalsComponent
   */
  goToArmaPlan(propuesta: String) {
    switch (propuesta) {
      case 'P1':
        this.opciones.tipoPlan = this.opciones.arrParams[0].tipoPlan;
        this.opciones.plazoPago.next(this.opciones.arrParams[0].plazoPago);
        this.calculos.changeTerm.next({ changed: true, plazo: this.opciones.arrParams[0].plazoPago });
        break;
      case 'P2':
        this.opciones.tipoPlan = this.opciones.arrParams[1].tipoPlan;
        this.opciones.plazoPago.next(this.opciones.arrParams[1].plazoPago);
        this.calculos.changeTerm.next({ changed: true, plazo: this.opciones.arrParams[1].plazoPago });
        break;
      case 'P3':
        this.opciones.tipoPlan = this.opciones.arrParams[2].tipoPlan;
        this.opciones.plazoPago.next(this.opciones.arrParams[2].plazoPago);
        this.calculos.changeTerm.next({ changed: true, plazo: this.opciones.arrParams[2].plazoPago });
        break;
    }

    this.router.navigate(['cotizador', this.routes.chosenCotizador.value, 'arma-tu-plan']);
  }

  /**
   * MÉTODO PARA ABRIR EL MODAL DE CONFIGURACIÓN
   * @param {TemplateRef<any>} template
   * @memberof ProposalsComponent
   */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static', animated: false, ignoreBackdropClick: true });
  }

  /**
   * MÉTODO QUE CAMBIA EL ESQUEMA DE COMISIONES
   * @param {CommissionScheme} scheme
   * @memberof ProposalsComponent
   */
  changeCommissionScheme(scheme: CommissionScheme) {
    this.variableConfiguration.changeCommissionScheme(scheme);
  }

  /**
   * MÉTODO QUE CAMBIA EL VALOR DE ASIGNACIÓN DE COMISIONES
   * @param {CommissionAssignment} tipo
   * @memberof ProposalsComponent
   */
  changeCommissionAssignment(tipo: CommissionAssignment) {
    this.variableConfiguration.changeCommissionAssignment(tipo);
  }

  /**
   * MÉTODO PARA CERRA EL MODAL DE CONFIGURACIÓN
   * @memberof ProposalsComponent
   */
  doQueryToValuesG() {
    this.modalRef.hide();
  }

  /**
   * MÉTODO QUE REALIZA LOS CÁLCULOS DEL RECARGO
   * DE ACUERDO A LA FRECUENCIA DE PAGO
   * @param {*} value
   * @memberof ProposalsComponent
   */
  setFrequency(value: any) {
    this.paymentFrequency.frecuenciaPago.next(value);
    this.calculos.paymentFrecuency = value as PaymentFrequency;
    this.calculos.calcularPagoDividido();
    switch (value as PaymentFrequency) {
      case 'ANUAL':
        this.recargoFijo = '0%';
        this.recargoActual = this.calculos.recargoActual;
        this.divisorActual = this.calculos.divisorActual;
        this.frecuenciapago = 'anual';
        break;
      case 'SEMESTRAL':
        this.recargoFijo = '3.5%';
        this.recargoActual = this.calculos.recargoActual;
        this.divisorActual = this.calculos.divisorActual;
        this.frecuenciapago = 'semestral';
        break;
      case 'TRIMESTRAL':
        this.recargoFijo = '5%';
        this.recargoActual = this.calculos.recargoActual;
        this.divisorActual = this.calculos.divisorActual;
        this.frecuenciapago = 'trimestral';
        break;
      case 'MENSUAL':
        this.recargoFijo = '6%';
        this.recargoActual = this.calculos.recargoActual;
        this.divisorActual = this.calculos.divisorActual;
        this.frecuenciapago = 'mensual';
        break;
    }
  }

}
