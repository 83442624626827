import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

/**
 * Servicio con el que se descifran las bases de datos
 */
@Injectable({
  providedIn: 'root'
})
export class DescipherService {

  /**
   * Llave para descifrar
   * @property
   * @type [String]
   */
  key: any;
  /**
   * Url de la api
   * @property
   * @type [string]
   */
  url: string;

  /**
   * Constructor
   * @param http
   */
  constructor(
    private http: HttpClient
  ) {
    this.url = environment.services + '/info/getCryptedPass';//'https://api-udi.herokuapp.com/getCryptedPass';
    this.setKey();
  }

  /**
   * Establece la llave en el localstorage
   * @method
   */
  setKey() {
    /*if (localStorage.getItem('key') !== null) {
      this.key = crypto.AES.decrypt(String(localStorage.getItem('key')), 'axaenelmundo');
    } else {/** */
      /**
       * Headers con CORS
       * @const
       * body de la petición con el token a enviar
       * @const
       * Observable de petición
       * @const
       */
      const headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
      const body = {token: 'hardcodedToken'};
      const petition = this.http.post(this.url, body, {headers})
        .map(response =>  response);

      petition.subscribe(val => {
        localStorage.setItem('key', val['cryptedPass']);
        this.key = crypto.AES.decrypt(String(localStorage.getItem('key')), 'axaenelmundo');
      });
    //}
  }

  /**
   * Decifra cada valor de la base de datos
   * @param val
   * @return [string]
   */
  desiph(val: string): string {
    return crypto.AES.decrypt(val, this.key.toString(crypto.enc.Utf8)).toString(crypto.enc.Utf8);
  }

}
