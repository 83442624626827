import { PaymentFrequency } from '@models/payment-frequency/payment-frequency.type';

/**
 * Frecuencias de pago.
 * @const
 * @type {PaymentFrequency[]}
 */
export const PAYMENT_FREQUENCIES: PaymentFrequency[] = ['ANUAL', 'SEMESTRAL', 'TRIMESTRAL', 'MENSUAL'];
/**
 * Frecuencia de pago por defecto.
 * @const
 * @type {PaymentFrequency}
 * @default "ANUAL"
 */
export const DEFAULT_PAYMENT_FREQUENCY: PaymentFrequency = 'ANUAL';
