//Angular Core
import { Injectable } from '@angular/core';

// Constants
import { DEFAULT_PAYMENT_FREQUENCY } from '@constants/payment-frequencies/payment-frequencies.constant';
import { PROTECTION_OPTIONS } from '@constants/protection-options/protection-options.constant';

// Servicios
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';

// Modelos
import { CoverageStatus } from '@models/coverage-status/coverage-status';
import { PaymentFrequency } from '@models/payment-frequency/payment-frequency.type';
import { Prospectu } from '@models/prospectu/prospectu.model';

// Rxjs
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentType } from '../../models/split-payment/split-payment.model';

// Services
import {
  BAService,
  BIService,
  PAIService,
  CCService,
  IACService,
  PAEService,
  DIService,
  DIPOService,
  DIPOCService,
  PCDService,
  GFService,
  CDCService,
  VPLService,
  TUTORService,
  EFIService
} from '@services/coverages';
import { SPLIT_PAYMENT, PROTEF, DEFAULT_ANUAL_INFLATION } from '@app/constants';
import { Currency } from '../../constants/currency/currency.constant.pre';
import { FactoresService } from '../factores/factores.service';
import { PaymentFrequencyService } from '../paymentFrequency/payment-frequency.service';
import { PlanType } from '@app/constants/plan-options/plan-types.constant.qa';
import { RoutesService } from '../routes/routes.service';
import { OpcionesPlanService } from '../opcionesPlan/opciones-plan.service';
import {
  DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_MXN,
  DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_USD
} from '@app/constants/plan-options/anual-inflatios-inpc-universal';
import { Plazo } from '@app/models';
import { BI } from '@constants/coverages';
import { PAI } from '@constants/coverages';
import { IAC } from '@constants/coverages';
import { PAE } from '@constants/coverages';
import { DI } from '@constants/coverages';
import { DIPO } from '@constants/coverages';
import { DIPOC } from '@constants/coverages';
import { CoverageConstants } from '@models/.';
import { CC } from '@constants/coverages/coverages.constants';
import { GF } from '@constants/coverages';
import { PCD } from './../../constants/coverages/coverages.constants';


/**
 * Servicio encargado de la operación de los cálculos.
 * Abstrae la vinculación de los calculos
 * @class
 * @export
 */
@Injectable({
  providedIn: 'root'
})
export class CalculosService {
  /**
   * Frecuencia de pagos por defecto
   * @property
   * @type {PaymentFrecuency}
   */
  paymentFrecuency: PaymentFrequency = DEFAULT_PAYMENT_FREQUENCY;
  /**
   * Nuevo objecto de prospecto
   * @property
   * @type {Prospectu}
   */
  prospecto: Prospectu;
  /**
   * Recargo actual seleccionado
   * @property
   */
  recargoActual = 0;
  /**
   * Divisor actual seleccionado
   * @property
   * @type {Number}
   */
  divisorActual = 0;

  // COBERTURA BÁSICA
  /**
   * Almacena la suma asegurada de Básica en primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   */
  BASICA_sumaAseguradaUDI: BehaviorSubject<number> = this.BA.sumaAsegurada_UDI;

  /**
   * Almacena la suma asegurada de Básica en segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   */
  BASICA_sumaAseguradaPesos: BehaviorSubject<number> = this.BA
    .sumaAsegurada_MXN;

  /**
   * Almacena la prima anual de Básica en primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   */
  BASICA_primaAnualUDI: BehaviorSubject<number> = this.BA.primaAnual_UDI;

  /**
   * Almacena la prima anual de Básica en segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   */
  BASICA_primaAnualPesos: BehaviorSubject<number> = this.BA.primaAnual_MXN;

  /**
   * Almacena la prima anual sugerida 1de Básica en primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   */
  BASICA_primaAnualUDI_Sugerida1: BehaviorSubject<number> = this.BA
    .primaAnual_UDI_Sugerida1;

  /**
   * Almacena la prima anual sugerida 2 de Básica en primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   */
  BASICA_primaAnualUDI_Sugerida2: BehaviorSubject<number> = this.BA
    .primaAnual_UDI_Sugerida2;

  BASICA_sumaAseguradaMasiva_1: BehaviorSubject<number> = this.BA
    .sumaAseguradaMasiva_1;
  BASICA_sumaAseguradaMasiva_2: BehaviorSubject<number> = this.BA
    .sumaAseguradaMasiva_2;
  BASICA_sumaAseguradaMasiva_3: BehaviorSubject<number> = this.BA
    .sumaAseguradaMasiva_3;

  BASICA_primaAnualMasiva_1: BehaviorSubject<number> = this.BA
    .primaAnualMasiva_1;
  BASICA_primaAnualMasiva_2: BehaviorSubject<number> = this.BA
    .primaAnualMasiva_2;
  BASICA_primaAnualMasiva_3: BehaviorSubject<number> = this.BA
    .primaAnualMasiva_3;

  BASICA_sumaAsegurada_Universal: BehaviorSubject<number> = this.BA.sumaAseguradaUniversal;
  BASICA_sumaAsegurada_Universal_Convert: BehaviorSubject<number> = this.BA.sumaAseguradaConvertUniversal;
  /**
   * COBERTURA BI
   */
  /**
   * Almacena la suma asegurada de BI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  BI_sumaAseguradaUDI = this.BI.sumaAsegurada_UDI;
  /**
   * Almacena la suma asegurada de BI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  BI_sumaAseguradaPesos = this.BI.sumaAsegurada_MXN;

  /**
   * Almacena la prima anual de BI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  BI_primaAnualUDI = this.BI.primaAnual_UDI;

  /**
   * Almacena la prima anual de BI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  BI_primaAnualPesos = this.BI.primaAnual_MXN;

  /**
   * Almacena la prima anual Sugerida1 de BI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  BI_primaAnualUDI_Sugerida1 = this.BI.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de BI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  BI_primaAnualPesos_Sugerida1 = this.BI.primaAnual_MXN_Sugerida1;

  /**
   * Almacena la prima anual Sugerida2 de BI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  BI_primaAnualUDI_Sugerida2 = this.BI.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de BI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  BI_primaAnualPesos_Sugerida2 = this.BI.primaAnual_MXN_Sugerida2;

  /**
   * COBERTURA PAI
   */
  /**
   * Almacena la suma asegurada de PAI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAI_sumaAseguradaUDI = this.PAI.sumaAsegurada_UDI;
  PAI_sumaAseguradaMXN_U = this.PAI.sumaAseguradaMXN_Universales;
  PAI_sumaAseguradaUSD_U = this.PAI.sumaAseguradaUSD_Universales;

  /**
   * Almacena la suma asegurada de PAI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAI_sumaAseguradaPesos = this.PAI.sumaAsegurada_MXN;

  /**
   * Almacena la prima anual de PAI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAI_primaAnualUDI = this.PAI.primaAnual_UDI;
  /**
   * Almacena la prima anual de PAI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAI_primaAnualPesos = this.PAI.primaAnual_MXN;

  /**
   * Almacena la prima anual Sugerida1 de PAI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAI_primaAnualUDI_Sugerida1 = this.PAI.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de PAI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAI_primaAnualPesos_Sugerida1 = this.PAI.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de PAI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAI_primaAnualUDI_Sugerida2 = this.PAI.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de PAI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAI_primaAnualPesos_Sugerida2 = this.PAI.primaAnual_MXN_Sugerida2;

  /**
   * COBERTURA CC
   */
  /**
   * Almacena la suma asegurada de CC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CC_sumaAseguradaUDI = this.CC.sumaAsegurada_UDI;
  /**
   * Almacena la suma asegurada de CC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CC_sumaAseguradaPesos = this.CC.sumaAsegurada_MXN;

  /**
   * Almacena la prima anual de CC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CC_primaAnualUDI = this.CC.primaAnual_UDI;
  /**
   * Almacena la prima anual de CC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CC_primaAnualPesos = this.CC.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de CC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CC_primaAnualUDI_Sugerida1 = this.CC.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual de CC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CC_primaAnualPesos_Sugerida1 = this.CC.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de CC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CC_primaAnualUDI_Sugerida2 = this.CC.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de CC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CC_primaAnualPesos_Sugerida2 = this.CC.primaAnual_MXN_Sugerida2;

  /**
   * COBERTURA DI
   */
  /**
   * Almacena la suma asegurada de DI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DI_sumaAseguradaUDI = this.DI.sumaAsegurada_UDI;
  DIU_sumaAseguradaMXN_U = this.DI.sumaAsegurada_MXN_U;
  DIU_sumaAseguradaUSD_U = this.DI.sumaAsegurada_USD_U;
  /**
   * Almacena la suma asegurada de DI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DI_sumaAseguradaPesos = this.DI.sumaAsegurada_MXN;
  /**
   * Almacena la prima anual de DI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DI_primaAnualUDI = this.DI.primaAnual_UDI;
  /**
   * Almacena la prima anual de DI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DI_primaAnualPesos = this.DI.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de DI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DI_primaAnualUDI_Sugerida1 = this.DI.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de DI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DI_primaAnualPesos_Sugerida1 = this.DI.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de DI en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DI_primaAnualUDI_Sugerida2 = this.DI.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de DI en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DI_primaAnualPesos_Sugerida2 = this.DI.primaAnual_MXN_Sugerida2;

  /**
   * COBERTURA DIPO
   */
  /**
   * Almacena la suma asegurada de DIPO en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPO_sumaAseguradaUDI = this.DIPO.sumaAsegurada_UDI;
  /**
   * Almacena la suma asegurada de DIPO en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPO_sumaAseguradaPesos = this.DIPO.sumaAsegurada_MXN;
  /**
   * Almacena la prima anual de DIPO en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPO_primaAnualUDI = this.DIPO.primaAnual_UDI;
  /**
   * Almacena la prima anual de DIPO en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPO_primaAnualPesos = this.DIPO.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de DIPO en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPO_primaAnualUDI_Sugerida1 = this.DIPO.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de DIPO en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPO_primaAnualPesos_Sugerida1 = this.DIPO.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de DIPO en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPO_primaAnualUDI_Sugerida2 = this.DIPO.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de DIPO en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPO_primaAnualPesos_Sugerida2 = this.DIPO.primaAnual_MXN_Sugerida2;

  /**
   * COBERTURA DIPOC
   */
  /**
   * Almacena la suma asegurada de DIPOC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPOC_sumaAseguradaUDI = this.DIPOC.sumaAsegurada_UDI;
  DIPOCU_sumaAseguradaMXN_U = this.DIPOC.sumaAsegurada_MXN_U;
  DIPOCU_sumaAseguradaUSD_U = this.DIPOC.sumaAsegurada_USD_U;
  /**
   * Almacena la suma asegurada de DIPOC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPOC_sumaAseguradaPesos = this.DIPOC.sumaAsegurada_MXN;
  /**
   * Almacena la prima anual de DIPOC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPOC_primaAnualUDI = this.DIPOC.primaAnual_UDI;
  /**
   * Almacena la prima anual de DIPOC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPOC_primaAnualPesos = this.DIPOC.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de DIPOC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPOC_primaAnualUDI_Sugerida1 = this.DIPOC.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de DIPOC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPOC_primaAnualPesos_Sugerida1 = this.DIPOC.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de DIPOC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPOC_primaAnualUDI_Sugerida2 = this.DIPOC.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de DIPOC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  DIPOC_primaAnualPesos_Sugerida2 = this.DIPOC.primaAnual_MXN_Sugerida2;

  /**
   * COBERTURA IAC
   */
  /**
   * Almacena la suma asegurada de IAC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  CDC_sumaAseguradaMXN_U = this.IAC.sumaAsegurada_MXN_U;
  CDC_sumaAseguradaUSD_U = this.IAC.sumaAsegurada_USD_U;
  /**
   * Almacena la suma asegurada de IAC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  IAC_sumaAseguradaUDI = this.IAC.sumaAsegurada_UDI;
  IAC_sumaAseguradaPesos = this.IAC.sumaAsegurada_MXN;
  /**
   * Almacena la prima anual de IAC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  IAC_primaAnualUDI = this.IAC.primaAnual_UDI;
  /**
   * Almacena la prima anual de IAC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  IAC_primaAnualPesos = this.IAC.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de IAC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  IAC_primaAnualUDI_Sugerida1 = this.IAC.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de IAC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  IAC_primaAnualPesos_Sugerida1 = this.IAC.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de IAC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  IAC_primaAnualUDI_Sugerida2 = this.IAC.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de IAC en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  IAC_primaAnualPesos_Sugerida2 = this.IAC.primaAnual_MXN_Sugerida2;

  IAC_sumaAseguradaMasiva_1: BehaviorSubject<number> = this.IAC
    .sumaAseguradaMasiva_1;
  IAC_sumaAseguradaMasiva_2: BehaviorSubject<number> = this.IAC
    .sumaAseguradaMasiva_2;
  IAC_sumaAseguradaMasiva_3: BehaviorSubject<number> = this.IAC
    .sumaAseguradaMasiva_3;

  IAC_primaAnualMasiva_1: BehaviorSubject<number> = this.IAC.primaAnualMasiva_1;
  IAC_primaAnualMasiva_2: BehaviorSubject<number> = this.IAC.primaAnualMasiva_2;
  IAC_primaAnualMasiva_3: BehaviorSubject<number> = this.IAC.primaAnualMasiva_3;

  /**
   * COBERTURA PAE
   */
  /**
   * Almacena la suma asegurada de PAE en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAE_sumaAseguradaUDI = this.PAE.sumaAsegurada_UDI;
  /**
   * Almacena la suma asegurada de PAE en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAE_sumaAseguradaPesos = this.PAE.sumaAsegurada_MXN;
  /**
   * Almacena la prima anual de PAE en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAE_primaAnualUDI = this.PAE.primaAnual_UDI;
  /**
   * Almacena la prima anual de PAE en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAE_primaAnualPesos = this.PAE.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de PAE en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAE_primaAnualUDI_Sugerida1 = this.PAE.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de PAE en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAE_primaAnualPesos_Sugerida1 = this.PAE.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de PAE en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAE_primaAnualUDI_Sugerida2 = this.PAE.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de PAE en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PAE_primaAnualPesos_Sugerida2 = this.PAE.primaAnual_MXN_Sugerida2;

  /**
   * COBERTURA PCD
   */
  /**
   * Almacena la suma asegurada de PCD en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PCD_sumaAseguradaUDI = this.PCD.sumaAsegurada_UDI;
  /**
   * Almacena la suma asegurada de PCD en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PCD_sumaAseguradaPesos = this.PCD.sumaAsegurada_MXN;
  /**
   * Almacena la prima anual de PCD en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PCD_primaAnualUDI = this.PCD.primaAnual_UDI;
  /**
   * Almacena la prima anual de PCD en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PCD_primaAnualPesos = this.PCD.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de PCD en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PCD_primaAnualUDI_Sugerida1 = this.PCD.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de PCD en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PCD_primaAnualPesos_Sugerida1 = this.PCD.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de PCD en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PCD_primaAnualUDI_Sugerida2 = this.PCD.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de PCD en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  PCD_primaAnualPesos_Sugerida2 = this.PCD.primaAnual_MXN_Sugerida2;

  /**
   * COBERTURA GF
   */
  /**
   * Almacena la suma asegurada de GF en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  GF_sumaAseguradaUDI = this.GF.sumaAsegurada_UDI;
  GFI_sumaAseguradaMXN = this.GF.sumaAsegurada_MXN_U_I;
  GFI_sumaAseguradaUSD = this.GF.sumaAsegurada_USD_U_I;
  GFC_sumaAseguradaMXN = this.GF.sumaAsegurada_MXN_U_C;
  GFC_sumaAseguradaUSD = this.GF.sumaAsegurada_USD_U_C;
  GFH_sumaAseguradaMXN = this.GF.sumaAsegurada_MXN_U_H;
  GFH_sumaAseguradaUSD = this.GF.sumaAsegurada_USD_U_H;
  /**
   * Almacena la suma asegurada de GF en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  GF_sumaAseguradaPesos = this.GF.sumaAsegurada_MXN;
  /**
   * Almacena la prima anual de GF en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  GF_primaAnualUDI = this.GF.primaAnual_UDI;
  /**
   * Almacena la prima anual de GF en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  GF_primaAnualPesos = this.GF.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de GF en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  GF_primaAnualUDI_Sugerida1 = this.GF.primaAnual_UDI_Sugerida1;
  /**
   * Almacena la prima anual Sugerida1 de GF en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  GF_primaAnualPesos_Sugerida1 = this.GF.primaAnual_MXN_Sugerida1;
  /**
   * Almacena la prima anual Sugerida2 de GF en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  GF_primaAnualUDI_Sugerida2 = this.GF.primaAnual_UDI_Sugerida2;
  /**
   * Almacena la prima anual Sugerida2 de GF en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  GF_primaAnualPesos_Sugerida2 = this.GF.primaAnual_MXN_Sugerida2;

  VPL_sumaAsegurada_PrimeraMoneda = this.VPL.sumaAsegurada_PrimeraMoneda;
  VPL_primaAnual_PrimeraMoneda = this.VPL.primaAnual_PrimeraMoneda;

  CDC_sumaAsegurada_PrimeraMoneda = this.CDC.sumaAsegurada_PrimeraMoneda;
  CDC_primaAnual_PrimeraMoneda = this.CDC.primaAnual_PrimeraMoneda;
  // Universales

  // ECMI
  ECMI_sumaAseguradaMXN = this.BI.sumaAsegurada_ECMI_MX;
  ECMI_sumaAseguradaUSD = this.BI.sumaAsegurada_ECMI_USD;


  // ALIADOSKIDS COBERTURAS.
  TUTOR_sumaAseguradaUDI = this.TUTOR.sumaAsegurada_UDI;
  TUTOR_sumaAseguradaPesos = this.TUTOR.sumaAsegurada_MXN;

  TUTOR_primaAnualUDI = this.TUTOR.primaAnual_UDI;
  TUTOR_primaAnualPesos = this.TUTOR.primaAnual_MXN;

  EFI_sumaAseguradaUDI = this.EFI.sumaAsegurada_UDI;
  EFI_sumaAseguradaPesos = this.EFI.sumaAsegurada_MXN;

  EFI_primaAnualUDI = this.EFI.primaAnual_UDI;
  EFI_primaAnualPesos = this.EFI.primaAnual_MXN;
  /**
   * Almacena la prima anual Sugerida1 de IAC en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  // TUTOR_primaAnualUDI_Sugerida1 = this.TUTOR.primaAnual_UDI_Sugerida1;
  // TUTOR_primaAnualPesos_Sugerida1 = this.TUTOR.primaAnual_MXN_Sugerida1;
  // TUTOR_primaAnualUDI_Sugerida2 = this.TUTOR.primaAnual_UDI_Sugerida2;
  // TUTOR_primaAnualPesos_Sugerida2 = this.TUTOR.primaAnual_MXN_Sugerida2;


  EFI_primaAnualUDI_Sugerida1 = this.EFI.primaAnual_UDI_Sugerida1;
  EFI_primaAnualPesos_Sugerida1 = this.EFI.primaAnual_MXN_Sugerida1;
  EFI_primaAnualUDI_Sugerida2 = this.EFI.primaAnual_UDI_Sugerida2;
  EFI_primaAnualPesos_Sugerida2 = this.EFI.primaAnual_MXN_Sugerida2;

  /**
   * GLOBAL
   */
  // Sin fraccionar
  /**
   * Almacena la prima anual global en primera moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaAnual_ConRecargo_Actual = new BehaviorSubject<number>(0);
  // Fraccionada
  /**
   * Almacena la prima total global en primera moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaDividida_Actual = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global fraccionada en primera moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaSinDividir = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global fraccionada en primera moneda en observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaSinDividir$ = this.primaTotalFraccionadaSinDividir.asObservable();
  /**
   * Almacena la prima total global fraccionada en segunda moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2SinDividir = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global fraccionada en segunda moneda en observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2SinDividir$ = this.primaTotalMoneda2SinDividir.asObservable();
  /**
   * Almacena la la suma de las primas
   * @property
   * @type {BehaviourSubject<number>}
   */
  sumaPrimas = new BehaviorSubject<number>(0);
  /**
   * Almacena la la suma de las primas en observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  sumaPrimas$ = this.sumaPrimas.asObservable();
  /**
   * Almacena la prima total de la primera moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  sumaPrimasSegMon = new BehaviorSubject<number>(0);

  primaTotalUDI = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total de la primera moneda como observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalUDI$ = this.primaTotalUDI.asObservable();
  /**
   * Almacena la prima total de la segunda moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalPesos = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total de la segunda moneda como observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalPesos$ = this.primaTotalPesos.asObservable();
  /**
   * Almacena la prima anual global de la primera moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaAnual_ConRecargo = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima anual global de la primera moneda como observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaAnual_ConRecargo$ = this.primaAnual_ConRecargo.asObservable();
  /**
   * Almacena la prima anual global de la segunda moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  G_primaAnualPesos = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima anual global de la segunda moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  G_primaAnualPesos$ = this.G_primaAnualPesos.asObservable();
  /**
   * Almacena la prima total global de la primera moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaDividida = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global de la primera moneda como observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaDividida$ = this.primaTotalFraccionadaDividida.asObservable();
  /**
   * Almacena la prima total global de la segunda moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2Dividida = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global de la segunda moneda como observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2Dividida$ = this.primaTotalMoneda2Dividida.asObservable();

  // Sugerida1
  /**
   * Almacena la prima total fraccionada sugerida 1 en primera moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaSinDividir_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total fraccionada sugerida 1 en primera moneda como observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaSinDividir_Sugerida1$ = this.primaTotalFraccionadaSinDividir_Sugerida1.asObservable();
  /**
   * Almacena la prima total fraccionada sugerida 1 en segunda moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2SinDividir_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total fraccionada sugerida 1 en segunda moneda como observable
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2SinDividir$_Sugerida1 = this.primaTotalMoneda2SinDividir_Sugerida1.asObservable();
  /**
   * Almacena la suma de las primas sugeridas 1
   * @type {BehaviourSubject<number>}
   */
  sumaPrimas_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la suma de las primas sugeridas 1 como observable
   * @type {BehaviourSubject<number>}
   */
  sumaPrimas_Sugerida1$ = this.sumaPrimas_Sugerida1.asObservable();
  /**
   * Almacena la prma total sugeridas 1 en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  primaTotalUDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prma total sugeridas 1 en la primera moneda como observable
   * @type {BehaviourSubject<number>}
   */
  primaTotalUDI_Sugerida1$ = this.primaTotalUDI_Sugerida1.asObservable();
  /**
   * Almacena la prma total sugeridas 1 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  primaTotalPesos_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prma total sugeridas 1 en la segunda moneda como observable
   * @type {BehaviourSubject<number>}
   */
  primaTotalPesos_Sugerida1$ = this.primaTotalPesos_Sugerida1.asObservable();
  /**
   * Almacena la prima anual global sugeridas 1 en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  primaAnual_ConRecargo_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima anual global sugeridas 1 en la primera moneda cpmo observable
   * @type {BehaviourSubject<number>}
   */
  primaAnual_ConRecargo_Sugerida1$ = this.primaAnual_ConRecargo_Sugerida1.asObservable();
  /**
   * Almacena la prima anual global sugeridas 1 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAnualPesos_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima anual global sugeridas 1 en la segunda moneda como observable
   * @type {BehaviourSubject<number>}
   */
  G_primaAnualPesos_Sugerida1$ = this.G_primaAnualPesos_Sugerida1.asObservable();
  /**
   * Almacena la prima total global sugeridas 1 en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaDividida_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global sugeridas 1 en la primera moneda como observable
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaDividida_Sugerida1$ = this.primaTotalFraccionadaDividida_Sugerida1.asObservable();
  /**
   * Almacena la prima total global sugeridas 1 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2Dividida_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global sugeridas 1 en la segunda moneda como observable
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2Dividida_Sugerida1$ = this.primaTotalMoneda2Dividida_Sugerida1.asObservable();

  // Sugerida2
  /**
   * Almacena la prima total fraccionada sugerida 2 en primera moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaSinDividir_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total fraccionada sugerida 2 en primera moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  primaTotalFraccionadaSinDividir_Sugerida2$ = this.primaTotalFraccionadaSinDividir_Sugerida2.asObservable();
  /**
   * Almacena la prima total fraccionada sugerida 2 en segunda moneda
   * @property
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2SinDividir_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total fraccionada sugerida 2 en segunda moneda
   * @property
   * @type {Observable<CoverageStatus>}
   */
  primaTotalMoneda2SinDividir_Sugerida2$ = this.primaTotalMoneda2SinDividir_Sugerida2.asObservable();
  /**
   * Almacena la suma de las primas sugeridas 2
   * @type {BehaviourSubject<number>}
   */
  sumaPrimas_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la suma de las primas sugeridas 2 como observable
   * @type {BehaviourSubject<number>}
   */
  sumaPrimas_Sugerida2$ = this.sumaPrimas_Sugerida2.asObservable();
  /**
   * Almacena la prima total sugerida 2 en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  primaTotalUDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total sugerida 2 en la primera monedacomo observable
   * @type {BehaviourSubject<number>}
   */
  primaTotalUDI_Sugerida2$ = this.primaTotalUDI_Sugerida2.asObservable();
  /**
   * Almacena la prima total sugerida 2 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  primaTotalPesos_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total sugerida 2 en la segunda moneda como observable
   * @type {BehaviourSubject<number>}
   */
  primaTotalPesos_Sugerida2$ = this.primaTotalPesos_Sugerida2.asObservable();
  /**
   * Almacena la prima anual gloval sugerida 2 en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  primaAnual_ConRecargo_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima anual gloval sugerida 2 en la primera moneda como observable
   * @type {BehaviourSubject<number>}
   */
  primaAnual_ConRecargo_Sugerida2$ = this.primaAnual_ConRecargo_Sugerida2.asObservable();
  /**
   * Almacena la prima anual global sugerida 2 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAnualPesos_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima anual global sugerida 2 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAnualPesos_Sugerida2$ = this.G_primaAnualPesos_Sugerida2.asObservable();
  /**
   * Almacena la prima total global sugerida 2 en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaDividida_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global sugerida 2 en la primera moneda como observable
   * @type {BehaviourSubject<number>}
   */
  primaTotalFraccionadaDividida_Sugerida2$ = this.primaTotalFraccionadaDividida_Sugerida2.asObservable();
  /**
   * Almacena la prima total global sugerida 2 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2Dividida_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima total global sugerida 2 en la segunda moneda como observable
   * @type {BehaviourSubject<number>}
   */
  primaTotalMoneda2Dividida_Sugerida2$ = this.primaTotalMoneda2Dividida_Sugerida2.asObservable();
  /**
   * Bandera para persistencia de datos
   * @type {boolean}
   */
  alreadyCalculated = new BehaviorSubject<boolean>(false);
  /**
   * Bandera para persistencia de datos al seleccinar propuesta sugerida 1 o 2
   * @type {boolean | number}
   */
  changeTerm = new BehaviorSubject<{ changed: boolean; plazo?: number }>({
    changed: false
  });
  /**
   * Almacena la prima adicional global en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAdicional_UDI = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima adicional global en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAdicional_Pesos = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima adicional global Sugerida1 en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAdicional_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima adicional global Sugerida1 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAdicional_Pesos_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Alamacena la prima adicional global Sugerida2 en la primera moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAdicional_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Almacena la prima adicional global Sugerida2 en la segunda moneda
   * @type {BehaviourSubject<number>}
   */
  G_primaAdicional_Pesos_Sugerida2 = new BehaviorSubject<number>(0);

  primaTotalMasiva1 = new BehaviorSubject<number>(0);
  primaTotalMasiva2 = new BehaviorSubject<number>(0);
  primaTotalMasiva3 = new BehaviorSubject<number>(0);
  primaTotalMasiva_SinFraccionar1 = new BehaviorSubject<number>(0);
  primaTotalMasiva_SinFraccionar2 = new BehaviorSubject<number>(0);
  primaTotalMasiva_SinFraccionar3 = new BehaviorSubject<number>(0);
  /**
   * Variables para el calculo de la prima deducible
   *
   */

  deducibleUDI: number;
  noDeducibleUDI: number;
  deduciblePESOS: number;
  noDeduciblesPESOS: number;
  /*deducibleUDI = new BehaviorSubject<number>(0);
  noDeducibleUDI = new BehaviorSubject<number>(0);
  deduciblePESOS = new BehaviorSubject<number>(0);
  noDeduciblesPESOS = new BehaviorSubject<number>(0);*/

  /**
   * Variables Deducible Observavle
   */
  /*deducibleUDI$ = this.deducibleUDI.asObservable();
  noDeducibleUDI$ = this.noDeducibleUDI.asObservable();
  deduciblePESOS$ = this.deduciblePESOS.asObservable();
  noDeduciblesPESOS$ = this.noDeduciblesPESOS.asObservable();
  */

  monedaUsada: BehaviorSubject<Currency> = new BehaviorSubject<Currency>('UDI');

  porcentajeINPC: number;
  saINPC: number;

  inflacionINPC: number;
  inflacion_inpc = new BehaviorSubject<number>(DEFAULT_ANUAL_INFLATION);
  inflacion_inpc_usd = new BehaviorSubject<number>(DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_USD);
  inflacion_inpc_mxn = new BehaviorSubject<number>(DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_MXN);

  activeCoberturas = new BehaviorSubject<boolean>(false);
  selectedCoberturas: string[];
  inpcIac_diezMas = 0;
  inpcPae_diezMas = 0;
  inpcGfi_diezMas = 0;
  inpcGo_diaezMas = 0;

  /**
   * Crea una instancia de CalculosService
   * @constructor
   * @param BA Crea una instancia de BAService (Cobertura: Básica)
   * @memberof CalculosService
   * @param BI Crea una instancia de BIService (Cobertura: Exención de pago de primas por invalidez total y permanente)
   * @memberof CalculosService
   * @param PAI Crea una instancia de PAIService (Cobertura: Pago adicional por invalidez total y permanente)
   * @memberof CalculosService
   * @param CC Crea una instancia de CCService (Cobertura: Cobertura Conyugal)
   * @memberof CalculosService
   * @param IAC Crea una instancia de IACService (Cobertura: Indemnización adicional por cáncer)
   * @memberof CalculosService
   * @param PAE  Crea una instancia de PAEService (Cobertura: Pago adicional por enfermedades graves)
   * @memberof CalculosService
   * @param DI Crea una instancia de DIService (Cobertura: Muerte accidental)
   * @memberof CalculosService
   * @param DIPO Crea una instancia de DIPOService (Cobertura: Muerte Accidental y Pérdidas Orgánicas)
   * @memberof CalculosService
   * @param DIPOC Crea una instancia de DIPOCService (Cobertura: Muerte Accidental y Pérdidas Orgánicas y Muerte Colectiva)
   * @memberof CalculosService
   * @param PCD Crea una instancia de PCDService (Cobertura: Protección continua por desempleo)
   * @memberof CalculosService
   * @param GF Crea una instancia de GFService (Cobertura: Gastos Funerarios)
   * @memberof CalculosService
   * @param recursos Crea una instancia de RecursosExternosService
   * @memberof CalculosService
   * @param prospectoService Crea una instancia de prospectoService
   * @memberof CalculosService
   */
  constructor(
    public BA: BAService,
    public BI: BIService,
    public PAI: PAIService,
    public CC: CCService,
    public IAC: IACService,
    public PAE: PAEService,
    public DI: DIService,
    public DIPO: DIPOService,
    public DIPOC: DIPOCService,
    public PCD: PCDService,
    public GF: GFService,
    public VPL: VPLService,
    public CDC: CDCService,
    public TUTOR: TUTORService,
    public EFI: EFIService,
    private recursos: RecursosExternosService,
    private prospectoService: ProspectoService,
    private factor: FactoresService,
    private paymentFrecuencyS: PaymentFrequencyService,
    private routes: RoutesService,
    private opciones: OpcionesPlanService
  ) {
    this.BASICA_sumaAseguradaUDI.distinctUntilChanged().subscribe(d => {
      this.monedaUsada.subscribe((moneda: Currency) => {
        if (moneda === 'MXN') {
          this.inflacion_inpc.subscribe(data => {
            this.inflacionINPC = data;
            this.calculaINPC();
          });
        }
      });
    });
    this.prospectoService.prospectoActual.subscribe(
      prospecto => (this.prospecto = prospecto)
    );
    this.monedaUsada.subscribe((moneda: Currency) => {
      Observable.combineLatest(
        this.BI_primaAnualUDI,
        this.PAI_primaAnualUDI,
        this.DI_primaAnualUDI,
        this.DIPO_primaAnualUDI,
        this.DIPOC_primaAnualUDI,
        this.IAC_primaAnualUDI,
        this.PAE_primaAnualUDI,
        this.CC_primaAnualUDI,
        this.PCD_primaAnualUDI,
        this.GF_primaAnualUDI,
        this.VPL_primaAnual_PrimeraMoneda,
        this.CDC_primaAnual_PrimeraMoneda
      )
        .distinctUntilChanged()
        .map(v => v.reduce((x, y) => x + y))
        .subscribe(v => this.activeCoberturas.next(v > 0 ? true : false));

      Observable.combineLatest(
        this.BI_sumaAseguradaUDI,
        this.PAI_sumaAseguradaUDI,
        this.CC_sumaAseguradaUDI,
        this.DI_sumaAseguradaUDI,
        this.DIPO_sumaAseguradaUDI,
        this.DIPOC_sumaAseguradaUDI,
        this.IAC_sumaAseguradaUDI,
        this.PAE_sumaAseguradaUDI,
        this.GF_sumaAseguradaUDI,
        this.PCD_sumaAseguradaUDI
      )
        .distinctUntilChanged()
        .map((arr: any[]) => {
          const coberturas = ['BI', 'PAI', 'CC', 'DI', 'DIPO', 'DIPOC', 'IAC', 'PAE', 'GF', 'PCD'];

          return arr.map((c: number | CoverageStatus, i) => {
            if (typeof c === 'string') {
              c = c === 'INCLUIDO' ? 1 : 0;
            }

            if (c > 0) {
              return coberturas[i];
            }

            return '';
          }).filter(c => c.length > 0);
        }).subscribe(c => this.selectedCoberturas = c);

      // Elegida
      if(this.opciones.tipoPlan === 'ALIADOSKIDS'){ 
        Observable.combineLatest(
          this.BASICA_primaAnualUDI,
          this.BI_primaAnualUDI,
          this.PAI_primaAnualUDI,
          this.DI_primaAnualUDI,
          this.DIPO_primaAnualUDI,
          this.DIPOC_primaAnualUDI,
          this.IAC_primaAnualUDI,
          this.PAE_primaAnualUDI,
          this.CC_primaAnualUDI,
          this.PCD_primaAnualUDI,
          this.GF_primaAnualUDI,
          this.VPL_primaAnual_PrimeraMoneda,
          this.CDC_primaAnual_PrimeraMoneda,
          this.TUTOR_primaAnualUDI,
          this.EFI_primaAnualUDI
        )
          .distinctUntilChanged()
          .subscribe(
            valoresPrimas => {
              let sumaPrimas = 0;
              for (const prima of valoresPrimas) {
                sumaPrimas += prima;
              }
              this.G_primaAdicional_UDI.next(
                sumaPrimas - this.BASICA_primaAnualUDI.value
              );
              this.sumaPrimas.next(sumaPrimas);
              this.sumaPrimasSegMon.next(this.recursos.FirstToSecond(sumaPrimas));
              this.primaAnual_ConRecargo.next(
                sumaPrimas + sumaPrimas * this.recargoActual
              );
              this.primaAnual_ConRecargo_Actual.next(
                this.primaAnual_ConRecargo.value
              );
              this.calcularPagoDividido(this.opciones.tipoPlan);
            },
            err => { }
          );
        }else {
          Observable.combineLatest(
            this.BASICA_primaAnualUDI,
            this.BI_primaAnualUDI,
            this.PAI_primaAnualUDI,
            this.DI_primaAnualUDI,
            this.DIPO_primaAnualUDI,
            this.DIPOC_primaAnualUDI,
            this.IAC_primaAnualUDI,
            this.PAE_primaAnualUDI,
            this.CC_primaAnualUDI,
            this.PCD_primaAnualUDI,
            this.GF_primaAnualUDI,
            this.VPL_primaAnual_PrimeraMoneda,
            this.CDC_primaAnual_PrimeraMoneda,
          )
            .distinctUntilChanged()
            .subscribe(
              valoresPrimas => {
                let sumaPrimas = 0;
                for (const prima of valoresPrimas) {
                  sumaPrimas += prima;
                }
                this.G_primaAdicional_UDI.next(
                  sumaPrimas - this.BASICA_primaAnualUDI.value
                );
                this.sumaPrimas.next(sumaPrimas);
                this.sumaPrimasSegMon.next(this.recursos.FirstToSecond(sumaPrimas));
                this.primaAnual_ConRecargo.next(
                  sumaPrimas + sumaPrimas * this.recargoActual
                );
                this.primaAnual_ConRecargo_Actual.next(
                  this.primaAnual_ConRecargo.value
                );
                this.calcularPagoDividido(this.opciones.tipoPlan);
              },
              err => { }
            );
        }
      

      this.primaAnual_ConRecargo$.subscribe(data => {
        this.G_primaAnualPesos.next(this.recursos.UDItoMXN(data));
        this.G_primaAdicional_Pesos.next(
          this.recursos.FirstToSecond(this.G_primaAdicional_UDI.value)
        );
      });

      // Sugerida1
      Observable.combineLatest(
        this.BASICA_primaAnualUDI_Sugerida1,
        this.BI_primaAnualUDI_Sugerida1,
        this.PAI_primaAnualUDI_Sugerida1,
        this.DI_primaAnualUDI_Sugerida1,
        this.DIPO_primaAnualUDI_Sugerida1,
        this.DIPOC_primaAnualUDI_Sugerida1,
        this.IAC_primaAnualUDI_Sugerida1,
        this.PAE_primaAnualUDI_Sugerida1,
        this.CC_primaAnualUDI_Sugerida1,
        this.PCD_primaAnualUDI_Sugerida1,
        this.GF_primaAnualUDI_Sugerida1
      )
        .distinctUntilChanged()
        .subscribe(
          valoresPrimas => {
            let sumaPrimas = 0;
            for (const prima of valoresPrimas) {
              sumaPrimas += prima;
            }
            this.G_primaAdicional_UDI_Sugerida1.next(
              sumaPrimas - this.BASICA_primaAnualUDI_Sugerida1.value
            );
            this.sumaPrimas_Sugerida1.next(sumaPrimas);
            this.primaAnual_ConRecargo_Sugerida1.next(
              sumaPrimas + sumaPrimas * this.recargoActual
            );
            this.calcularPagoDividido(this.opciones.tipoPlan);
          },
          err => { }
        );

      this.primaAnual_ConRecargo_Sugerida1.asObservable().subscribe(data => {
        this.G_primaAnualPesos_Sugerida1.next(this.recursos.UDItoMXN(data));
        this.G_primaAdicional_Pesos_Sugerida1.next(
          this.recursos.FirstToSecond(this.G_primaAdicional_UDI_Sugerida1.value)
        );
      });

      // Sugerida2
      Observable.combineLatest(
        this.BASICA_primaAnualUDI_Sugerida2,
        this.BI_primaAnualUDI_Sugerida2,
        this.PAI_primaAnualUDI_Sugerida2,
        this.DI_primaAnualUDI_Sugerida2,
        this.DIPO_primaAnualUDI_Sugerida2,
        this.DIPOC_primaAnualUDI_Sugerida2,
        this.IAC_primaAnualUDI_Sugerida2,
        this.PAE_primaAnualUDI_Sugerida2,
        this.CC_primaAnualUDI_Sugerida2,
        this.PCD_primaAnualUDI_Sugerida2,
        this.GF_primaAnualUDI_Sugerida2
      )
        .distinctUntilChanged()
        .subscribe(
          valoresPrimas => {
            let sumaPrimas = 0;
            for (const prima of valoresPrimas) {
              sumaPrimas += prima;
            }
            this.G_primaAdicional_UDI_Sugerida2.next(
              sumaPrimas - this.BASICA_primaAnualUDI_Sugerida2.value
            );
            this.sumaPrimas_Sugerida2.next(sumaPrimas);
            this.primaAnual_ConRecargo_Sugerida2.next(
              sumaPrimas + sumaPrimas * this.recargoActual
            );
            this.calcularPagoDividido(this.opciones.tipoPlan);
          },
          err => { }
        );

      Observable.combineLatest(
        this.BASICA_primaAnualMasiva_1,
        this.IAC_primaAnualMasiva_1
      )
        .distinctUntilChanged()
        .map(arr => arr.reduce((a, b) => a + b))
        .subscribe(primaTotal => {
          this.primaTotalMasiva1.next(primaTotal);
          this.primaTotalMasiva_SinFraccionar1.next(primaTotal);
        });

      Observable.combineLatest(
        this.BASICA_primaAnualMasiva_2,
        this.IAC_primaAnualMasiva_2
      )
        .distinctUntilChanged()
        .map(arr => arr.reduce((a, b) => a + b))
        .subscribe(primaTotal => {
          this.primaTotalMasiva2.next(primaTotal);
          this.primaTotalMasiva_SinFraccionar2.next(primaTotal);
        });

      Observable.combineLatest(
        this.BASICA_primaAnualMasiva_3,
        this.IAC_primaAnualMasiva_3
      )
        .distinctUntilChanged()
        .map(arr => arr.reduce((a, b) => a + b))
        .subscribe(primaTotal => {
          this.primaTotalMasiva3.next(primaTotal);
          this.primaTotalMasiva_SinFraccionar3.next(primaTotal);
        });

      this.primaAnual_ConRecargo_Sugerida2.asObservable().subscribe(data => {
        this.G_primaAnualPesos_Sugerida2.next(this.recursos.UDItoMXN(data));
        this.G_primaAdicional_Pesos_Sugerida2.next(
          this.recursos.FirstToSecond(this.G_primaAdicional_UDI_Sugerida2.value)
        );
      });

      this.paymentFrecuencyS.frecuenciaPago.subscribe(frecuencia => {
        this.paymentFrecuency = frecuencia;
        this.calcularPagoDividido(this.opciones.tipoPlan);
        this.primaAnual_ConRecargo.next(
          this.sumaPrimas.value + this.sumaPrimas.value * this.recargoActual
        );
        this.primaAnual_ConRecargo_Actual.next(
          this.primaAnual_ConRecargo.value
        );
      });
    });
  }
  /**
   * Consigue valor de la UDI de la API
   * @method
   * @returns {Promise<any>}
   */
  async API_UDI(): Promise<any> {
    return await new Promise<any>(resolve => {
      this.recursos.setUDI().then(() => resolve('Nothing'));
    });
  }

  /**
   * Método para obtener la prima anual con recargo por
   * pago fraccionado
   *
   * @param {number} primaAnual
   * @param {PaymentType} frecuenciaPago
   * @param {Currency} moneda
   * @param {boolean} [dividido=true]
   * @returns {number}
   * @memberof CalculosService
   */
  obtenerPrimaConRecargo(
    primaAnual: number,
    frecuenciaPago: PaymentType,
    moneda: Currency,
    dividido: boolean = true,
    plan?: PlanType
  ): number {
    // Recargo correspondiente a la frecuencia de pago elegida
    let recargo;
    if (plan === 'PROTECCION EFECTIVA' && frecuenciaPago === 'yearly') {
      recargo = 0;
    } else {
      recargo =
        plan === 'PROTECCION EFECTIVA'
          ? PROTEF
          : SPLIT_PAYMENT.filter(item => item.type === frecuenciaPago)[0]
            .surcharge[moneda] || 0;     
    }

    // Divisor correspondiente a la frecuencia de pago elegida
    const divisor =
      SPLIT_PAYMENT.filter(item => item.type === frecuenciaPago)[0].divider ||
      0;
    // Retorna el cálculo
    if (dividido) {
      if( plan === 'PROTECCION EFECTIVA' && divisor !== 1){
        return (primaAnual / 0.9) / divisor;
      } else {
        return (primaAnual + primaAnual * recargo) / divisor;
      }     
    }
    return primaAnual + primaAnual * recargo;
  }

  /**
   * Divide pagos por frecuencia
   * @method
   */
  calcularPagoDividido(plan?: PlanType) {
    this.calculaDeduccible();
    const frecuencia = this.paymentFrecuency;
    let splitPayment: PaymentType = 'yearly';

    switch (frecuencia) {
      case 'ANUAL':
        splitPayment = 'yearly';
        break;

      case 'SEMESTRAL':
        splitPayment = 'semi-annually';
        break;

      case 'TRIMESTRAL':
        splitPayment = 'quarterly';
        break;

      case 'MENSUAL':
        splitPayment = 'monthly';
        break;
    }

    if (plan === 'PROTECCION EFECTIVA' && frecuencia === 'ANUAL') {
      this.recargoActual = 0;
    } else {
      plan === 'PROTECCION EFECTIVA'
        ? (this.recargoActual = PROTEF)
        : (this.recargoActual = SPLIT_PAYMENT.filter(
          item => item.type === splitPayment
        )[0].surcharge[this.monedaUsada.value]);
    }
    this.divisorActual = SPLIT_PAYMENT.filter(
      item => item.type === splitPayment
    )[0].divider;
    this.primaTotalMasiva1.next(
      this.obtenerPrimaConRecargo(
        this.primaTotalMasiva_SinFraccionar1.value,
        splitPayment,
        this.monedaUsada.value,
        true,
        plan
      )
    );
    this.primaTotalMasiva2.next(
      this.obtenerPrimaConRecargo(
        this.primaTotalMasiva_SinFraccionar2.value,
        splitPayment,
        this.monedaUsada.value,
        true,
        plan
      )
    );
    this.primaTotalMasiva3.next(
      this.obtenerPrimaConRecargo(
        this.primaTotalMasiva_SinFraccionar3.value,
        splitPayment,
        this.monedaUsada.value,
        true,
        plan
      )
    );
    // Se establece prima anual con recargo dividido en el tipo de pago fraccionado
    this.primaTotalFraccionadaDividida.next(
      this.obtenerPrimaConRecargo(
        this.sumaPrimas.value,
        splitPayment,
        this.monedaUsada.value,
        true,
        plan
      )
    );
    this.primaTotalFraccionadaDividida_Sugerida1.next(
      this.obtenerPrimaConRecargo(
        this.sumaPrimas_Sugerida1.value,
        splitPayment,
        this.monedaUsada.value,
        true,
        plan
      )
    );
    this.primaTotalFraccionadaDividida_Sugerida2.next(
      this.obtenerPrimaConRecargo(
        this.sumaPrimas_Sugerida2.value,
        splitPayment,
        this.monedaUsada.value,
        true,
        plan
      )
    );
    this.primaTotalFraccionadaDividida_Actual.next(
      this.primaTotalFraccionadaDividida.value
    );

    // Se establece la prima anual con recargo sin dividir por el pago fraccionado
    this.primaTotalFraccionadaSinDividir.next(
      this.obtenerPrimaConRecargo(
        this.sumaPrimas.value,
        splitPayment,
        this.monedaUsada.value,
        false
      )
    );
    this.primaTotalFraccionadaSinDividir_Sugerida1.next(
      this.obtenerPrimaConRecargo(
        this.sumaPrimas_Sugerida1.value,
        splitPayment,
        this.monedaUsada.value,
        false
      )
    );
    this.primaTotalFraccionadaSinDividir_Sugerida2.next(
      this.obtenerPrimaConRecargo(
        this.sumaPrimas_Sugerida2.value,
        splitPayment,
        this.monedaUsada.value,
        false
      )
    );

    // Se convierte a la segunda moneda la prima anual con recargo dividido
    this.primaTotalMoneda2Dividida.next(
      this.recursos.UDItoMXN(this.primaTotalFraccionadaDividida.value)
    );
    this.primaTotalMoneda2Dividida_Sugerida1.next(
      this.recursos.UDItoMXN(this.primaTotalFraccionadaDividida_Sugerida1.value)
    );
    this.primaTotalMoneda2Dividida_Sugerida2.next(
      this.recursos.UDItoMXN(this.primaTotalFraccionadaDividida_Sugerida2.value)
    );

    // Se convierte a la segunda moneda la prima anual con recargo sin dividir por el pago fraccionado
    this.primaTotalMoneda2SinDividir.next(
      this.recursos.UDItoMXN(this.primaTotalFraccionadaSinDividir.value)
    );
    this.primaTotalMoneda2SinDividir_Sugerida1.next(
      this.recursos.UDItoMXN(
        this.primaTotalFraccionadaSinDividir_Sugerida1.value
      )
    );
    this.primaTotalMoneda2SinDividir_Sugerida2.next(
      this.recursos.UDItoMXN(
        this.primaTotalFraccionadaSinDividir_Sugerida2.value
      )
    );
  }
  /**
   * Reestablece los cálculos
   * @method
   */
  resetCalculus() {
    this.BA.primaAnual_UDI.next(0);
    this.BA.primaAnual_MXN.next(0);
    this.BA.primaAnual_UDI_Sugerida1.next(0);
    this.BA.primaAnual_MXN_Sugerida1.next(0);
    this.BA.primaAnual_UDI_Sugerida2.next(0);
    this.BA.primaAnual_MXN_Sugerida2.next(0);
    this.BA.sumaAsegurada_UDI.next(PROTECTION_OPTIONS.DEFAULT_VALUE);
    this.BA.sumaAsegurada_MXN.next(0);

    this.BI.primaAnual_UDI.next(0);
    this.BI.primaAnual_MXN.next(0);
    this.BI.primaAnual_UDI_Sugerida1.next(0);
    this.BI.primaAnual_MXN_Sugerida1.next(0);
    this.BI.primaAnual_UDI_Sugerida2.next(0);
    this.BI.primaAnual_MXN_Sugerida2.next(0);
    this.BI.sumaAsegurada_MXN.next('EXCLUIDO');
    this.BI.sumaAsegurada_UDI.next('EXCLUIDO');

    this.PAI.primaAnual_UDI.next(0);
    this.PAI.primaAnual_MXN.next(0);
    this.PAI.primaAnual_UDI_Sugerida1.next(0);
    this.PAI.primaAnual_MXN_Sugerida1.next(0);
    this.PAI.primaAnual_UDI_Sugerida2.next(0);
    this.PAI.primaAnual_MXN_Sugerida2.next(0);
    this.PAI.sumaAsegurada_MXN.next(0);
    this.PAI.sumaAsegurada_UDI.next(0);
    this.COBERTURA_PAI(-1);

    this.CC.primaAnual_UDI.next(0);
    this.CC.primaAnual_MXN.next(0);
    this.CC.primaAnual_UDI_Sugerida1.next(0);
    this.CC.primaAnual_MXN_Sugerida1.next(0);
    this.CC.primaAnual_UDI_Sugerida2.next(0);
    this.CC.primaAnual_MXN_Sugerida2.next(0);
    this.CC.sumaAsegurada_MXN.next(0);
    this.CC.sumaAsegurada_UDI.next(0);
    this.COBERTURA_CC(-1);

    this.DI.primaAnual_UDI.next(0);
    this.DI.primaAnual_MXN.next(0);
    this.DI.sumaAsegurada_MXN.next(0);
    this.DI.sumaAsegurada_UDI.next(0);
    this.COBERTURA_DI(-1);

    this.DIPO.primaAnual_UDI.next(0);
    this.DIPO.primaAnual_MXN.next(0);
    this.DIPO.sumaAsegurada_MXN.next(0);
    this.DIPO.sumaAsegurada_UDI.next(0);
    this.COBERTURA_DIPO(-1);

    this.DIPOC.primaAnual_UDI.next(0);
    this.DIPOC.primaAnual_MXN.next(0);
    this.DIPOC.sumaAsegurada_MXN.next(0);
    this.DIPOC.sumaAsegurada_UDI.next(0);
    this.COBERTURA_DIPOC(-1);

    this.IAC.primaAnual_UDI.next(0);
    this.IAC.primaAnual_MXN.next(0);
    this.IAC.primaAnual_UDI_Sugerida1.next(0);
    this.IAC.primaAnual_MXN_Sugerida1.next(0);
    this.IAC.primaAnual_UDI_Sugerida2.next(0);
    this.IAC.primaAnual_MXN_Sugerida2.next(0);
    this.IAC.sumaAsegurada_MXN.next(0);
    this.IAC.sumaAsegurada_UDI.next(0);
    this.COBERTURA_IAC(-1);

    this.PAI.primaAnual_UDI.next(0);
    this.PAI.primaAnual_MXN.next(0);
    this.PAI.primaAnual_UDI_Sugerida1.next(0);
    this.PAI.primaAnual_MXN_Sugerida1.next(0);
    this.PAI.primaAnual_UDI_Sugerida2.next(0);
    this.PAI.primaAnual_MXN_Sugerida2.next(0);
    this.PAI.sumaAsegurada_MXN.next(0);
    this.PAI.sumaAsegurada_UDI.next(0);
    this.COBERTURA_PAI(-1);

    this.PAE.primaAnual_UDI.next(0);
    this.PAE.primaAnual_MXN.next(0);
    this.PAE.primaAnual_UDI_Sugerida1.next(0);
    this.PAE.primaAnual_MXN_Sugerida1.next(0);
    this.PAE.primaAnual_UDI_Sugerida2.next(0);
    this.PAE.primaAnual_MXN_Sugerida2.next(0);
    this.PAE.sumaAsegurada_MXN.next(0);
    this.PAE.sumaAsegurada_UDI.next(0);
    this.COBERTURA_PAE(-1);

    this.PCD.primaAnual_UDI.next(0);
    this.PCD.primaAnual_MXN.next(0);
    this.PCD.primaAnual_UDI_Sugerida1.next(0);
    this.PCD.primaAnual_MXN_Sugerida1.next(0);
    this.PCD.primaAnual_UDI_Sugerida2.next(0);
    this.PCD.primaAnual_MXN_Sugerida2.next(0);
    this.PCD.sumaAsegurada_MXN.next('EXCLUIDO');
    this.PCD.sumaAsegurada_UDI.next('EXCLUIDO');

    this.GF.primaAnual_UDI.next(0);
    this.GF.primaAnual_MXN.next(0);
    this.GF.primaAnual_UDI_Sugerida1.next(0);
    this.GF.primaAnual_MXN_Sugerida1.next(0);
    this.GF.primaAnual_UDI_Sugerida2.next(0);
    this.GF.primaAnual_MXN_Sugerida2.next(0);
    this.GF.sumaAsegurada_MXN.next(0);
    this.GF.sumaAsegurada_UDI.next(0);
    this.COBERTURA_GF(-1);

    this.VPL.sumaAsegurada_PrimeraMoneda.next(0);
    this.VPL.primaAnual_PrimeraMoneda.next(0);
    this.COBERTURA_CDC(-1);

    this.CDC.sumaAsegurada_PrimeraMoneda.next(0);
    this.CDC.primaAnual_PrimeraMoneda.next(0);
    this.COBERTURA_CDC(-1);

    this.G_primaAnualPesos.next(0);
    this.primaAnual_ConRecargo.next(0);
    this.primaTotalMoneda2Dividida.next(0);
    this.primaTotalFraccionadaDividida.next(0);

    this.G_primaAnualPesos_Sugerida1.next(0);
    this.primaAnual_ConRecargo_Sugerida1.next(0);
    this.primaTotalMoneda2Dividida_Sugerida1.next(0);
    this.primaTotalFraccionadaDividida_Sugerida1.next(0);

    this.G_primaAnualPesos_Sugerida2.next(0);
    this.primaAnual_ConRecargo_Sugerida2.next(0);
    this.primaTotalMoneda2Dividida_Sugerida2.next(0);
    this.primaTotalFraccionadaDividida_Sugerida2.next(0);

    this.EFI_primaAnualUDI.next(0);
    this.EFI_primaAnualPesos.next(0);
    this.EFI_primaAnualUDI_Sugerida1.next(0);
    this.EFI_primaAnualUDI_Sugerida2.next(0);
    this.TUTOR_primaAnualUDI.next(0);
    this.TUTOR.primaAnual_MXN.next(0);
    // this.TUTOR_primaAnualUDI_Sugerida1.next(0);
    // this.TUTOR_primaAnualUDI_Sugerida2.next(0);
    this.EFI_primaAnualPesos_Sugerida1.next(0);
    this.EFI_primaAnualPesos_Sugerida2.next(0);
    // this.TUTOR.primaAnual_MXN_Sugerida1.next(0);
    // this.TUTOR.primaAnual_MXN_Sugerida2.next(0);
    this.TUTOR_primaAnualPesos.next(0);

    this.EFI_sumaAseguradaUDI.next('EXCLUIDO');
    this.EFI_sumaAseguradaPesos.next('EXCLUIDO');
    this.TUTOR_sumaAseguradaUDI.next(0);
    this.TUTOR_sumaAseguradaPesos.next(0);

    this.BASICA_sumaAsegurada_Universal.next(0);
    this.COBERTURA_PAIU(-1);
    this.GFI_sumaAseguradaMXN.next(0);
    this.GFH_sumaAseguradaMXN.next(0);
    this.GFC_sumaAseguradaMXN.next(0);
    this.PAI_sumaAseguradaMXN_U.next(0);
    this.DIPOCU_sumaAseguradaMXN_U.next(0);
    this.COBERTURA_DIPOCU(-1);
    this.COBERTURA_GFH(-1);
    this.COBERTURA_ECMI('EXCLUIDO');
    this.COBERTURA_GF(-1);
    this.COBERTURA_GFC(-1);
    this.COBERTURA_GFH(-1);
    this.COBERTURA_CDCUniv(-1);
    this.DIU_sumaAseguradaMXN_U.next(0);

    this.BASICA_sumaAsegurada_Universal.next(0);
    this.GFI_sumaAseguradaUSD.next(0);
    this.GFH_sumaAseguradaUSD.next(0);
    this.GFC_sumaAseguradaUSD.next(0);
    this.PAI_sumaAseguradaUSD_U.next(0);
    this.DIU_sumaAseguradaUSD_U.next(0);
    this.DIPOCU_sumaAseguradaUSD_U.next(0);
  }
  /**
   * Establece prima anual de la primera moneda de la cobertura Básica
   * @method
   * @param sumaAseguradaUDI
   */
  COBERTURA_BASICA(sumaAseguradaUDI: number) {
    this.BA.establecePrimaAnual(sumaAseguradaUDI);
  }
  COBERTURA_BASICA_UNIVERSAL(sumaAsegurada: number) {
    this.BA.establecePrimaAnualUniversal(sumaAsegurada);
  }
  COBERTURA_BASICA_SEGMONU(sumaAseguradaSegMon: number) {
    this.BA.establecePrimaAnualSegMonU(sumaAseguradaSegMon);
  }
  /**
   * Establece suma asegurada de la segunda moneda de la cobertura Básica
   * @method
   * @param primaAnual
   */
  COBERTURA_BASICA_INVERSA(primaAnual: number) {
    this.BA.estableceSumaAsegurada(primaAnual);
  }
  COBERTURA_BASICA_INVERSAU(primaAnual: number) {
    this.BA.estableceSumaAsegurada(primaAnual);
  }
  /**
   * Establece prima anual de la seguna moneda de la cobertura Básica
   * @method
   * @param sumaAseguradaSegMon
   */
  COBERTURA_BASICA_SEGMON(sumaAseguradaSegMon: number) {
    this.BA.establecePrimaAnualSegMon(sumaAseguradaSegMon);
  }

  COBERTURA_BASICA_MASIVA(sumaAsegurada: number, cualSuma: number) {
    this.BA.establecePrimaAnualMasivo(sumaAsegurada, cualSuma);
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura BI
   * @method
   * @param status
   */
  COBERTURA_BI(status: CoverageStatus) {
    this.BI.establecePrimaAnual(status, this.prospecto);
  }
  // ECMI
  COBERTURA_ECMI(status: CoverageStatus) {
    this.BI.establecePrimaAnualUniversal(status, this.prospecto);
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura PAI
   * @method
   * @param sumaAseguradaUDI
   */
  COBERTURA_PAI(sumaAseguradaUDI: number) {
    this.PAI.establecePrimaAnual(sumaAseguradaUDI);
  }
  COBERTURA_PAIU(sumaAseguradaUDI: number) {
    this.PAI.establecePrimaAnualU(sumaAseguradaUDI);
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura c
   * @method
   * @param sumaAseguradaUDI
   */
  COBERTURA_CC(sumaAseguradaUDI: number) {
    this.CC.establecePrimaAnual(sumaAseguradaUDI);
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura DI
   * @method
   * @param sumaAseguradaUDI
   */
  COBERTURA_DI(sumaAseguradaUDI) {
    this.DI.establecePrimaAnual(sumaAseguradaUDI);

    if (sumaAseguradaUDI !== -1) {
      this.DIPO.establecePrimaAnual(-1);
      this.DIPOC.establecePrimaAnual(-1);
    }
  }
  COBERTURA_DIU(sumaAseguradaUDI) {
    this.DI.establecePrimaAnualU(sumaAseguradaUDI);

    if (sumaAseguradaUDI !== -1) {
      this.DIPOC.establecePrimaAnualU(-1);
    }
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura DIPO
   * @method
   * @param sumaAseguradaUDI
   */
  COBERTURA_DIPO(sumaAseguradaUDI) {
    this.DIPO.establecePrimaAnual(sumaAseguradaUDI);

    if (sumaAseguradaUDI !== -1) {
      this.DI.establecePrimaAnual(-1);
      this.DIPOC.establecePrimaAnual(-1);
    }
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura DIPOC
   * @method
   * @param sumaAseguradaUDI
   */
  COBERTURA_DIPOC(sumaAseguradaUDI) {
    this.DIPOC.establecePrimaAnual(sumaAseguradaUDI);

    if (sumaAseguradaUDI !== -1) {
      this.DI.establecePrimaAnual(-1);
      this.DIPO.establecePrimaAnual(-1);
    }
  }
  COBERTURA_DIPOCU(sumaAseguradaUDI) {
    this.DIPOC.establecePrimaAnualU(sumaAseguradaUDI);

    if (sumaAseguradaUDI !== -1) {
      this.DI.establecePrimaAnualU(-1);
    }
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura IAC
   * @method
   * @param sumaAseguradaUDI
   */
  COBERTURA_IAC(sumaAseguradaUDI: number) {
    this.IAC.establecePrimaAnual(sumaAseguradaUDI);
  }

  // COBERTURA_CDC(sumaAseguradaUDI: number) {
  //   this.IAC.establecePrimaAnualU(sumaAseguradaUDI);
  // }

  COBERTURA_TUTOR(sumaAseguradaUDI: number) {
    this.TUTOR.establecePrimaAnual(sumaAseguradaUDI);
  }

  COBERTURA_EFI(sumaAsegurada: number){
    this.EFI.establecePrimaAnual(sumaAsegurada);
  }
  COBERTURA_IAC_MASIVA(sumaAsegurada: number, numeroSuma): void {
    this.IAC.obtenerPrimaMasiva(sumaAsegurada, numeroSuma);
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura PAE
   * @method
   * @param sumaAseguradaUDI
   */
  COBERTURA_PAE(sumaAseguradaUDI: number) {
    this.PAE.establecePrimaAnual(sumaAseguradaUDI);
  }
  /**
   * Establece prima anual de la primera moneda de  la cobertura GF
   * @method
   * @param sumaAsegurada
   */
  COBERTURA_GF(sumaAsegurada: number) {
    this.GF.establecePrimaAnual(sumaAsegurada);
  }
  COBERTURA_GFI(sumaAsegurada: number) {
    this.GF.establecePrimaAnualUI(sumaAsegurada, 'GFI');
  }
  COBERTURA_GFC(sumaAsegurada: number) {
    this.GF.establecePrimaAnualUI(sumaAsegurada, 'GFC');
  }
  COBERTURA_GFH(sumaAsegurada: number) {
    this.GF.establecePrimaAnualUI(sumaAsegurada, 'GFH');
  }
  /**
   * Establece la prima anual de la cobertura PCD
   * @method
   * @param status
   */
  COBERTURA_PCD(status: CoverageStatus) {
    this.PCD.establecePrimaAnual(status, this.prospecto);
  }

  COBERTURA_CDC(sumaAsegurada: number) {
    this.CDC.establecePrimaAnual(sumaAsegurada);
  }

  COBERTURA_CDCUniv(sumaAsegurada: number) {
    this.IAC.establecePrimaAnualU(sumaAsegurada);
  }

  COBERTURA_VPL(sumaAsegurada: number) {
    this.VPL.establecePrimaAnual(sumaAsegurada);
  }

  /**
   * Recalcula las coberturas
   * @method
   */
  RECALCULAR(bProp?: boolean): void {
    const bamasiva1 = this.BA.sumaAseguradaMasiva_1.value,
      bamasiva2 = this.BA.sumaAseguradaMasiva_2.value,
      bamasiva3 = this.BA.sumaAseguradaMasiva_3.value,
      ba = !bProp ? 0 : this.BA.sumaAsegurada_UDI.value,
      bi = this.BI.sumaAsegurada_UDI.value,
      pai =
        this.PAI.sumaAsegurada_UDI.value === 0
          ? -1
          : this.PAI.sumaAsegurada_UDI.value,
      cc =
        this.CC.sumaAsegurada_UDI.value === 0
          ? -1
          : this.CC.sumaAsegurada_UDI.value,
      di =
        this.DI.sumaAsegurada_UDI.value === 0
          ? -1
          : this.DI.sumaAsegurada_UDI.value,
      dipo =
        this.DIPO.sumaAsegurada_UDI.value === 0
          ? -1
          : this.DIPO.sumaAsegurada_UDI.value,
      dipoc =
        this.DIPOC.sumaAsegurada_UDI.value === 0
          ? -1
          : this.DIPOC.sumaAsegurada_UDI.value,
      iac =
        this.IAC.sumaAsegurada_UDI.value === 0
          ? -1
          : this.IAC.sumaAsegurada_UDI.value,
      iacmasiva1 = this.IAC.sumaAseguradaMasiva_1.value,
      iacmasiva2 = this.IAC.sumaAseguradaMasiva_2.value,
      iacmasiva3 = this.IAC.sumaAseguradaMasiva_3.value,
      pae =
        this.PAE.sumaAsegurada_UDI.value === 0
          ? -1
          : this.PAE.sumaAsegurada_UDI.value,
      gf =
        this.GF.sumaAsegurada_UDI.value === 0
          ? -1
          : this.GF.sumaAsegurada_UDI.value,
      pcd = this.PCD.sumaAsegurada_UDI.value,
      vpl = this.VPL.sumaAsegurada_PrimeraMoneda.value === 0 || this.opciones.tipoPlan !== 'MUJER UNICA'
        ? -1
        : this.VPL.sumaAsegurada_PrimeraMoneda.value,
      cdc = this.CDC.sumaAsegurada_PrimeraMoneda.value === 0 || this.opciones.tipoPlan !== 'MUJER UNICA'
        ? -1
        : this.CDC.sumaAsegurada_PrimeraMoneda.value;

    if (this.routes.chosenCotizador.getValue() === 'AxaParaTodos') {
      this.COBERTURA_BASICA_MASIVA(bamasiva1, 1);
      this.COBERTURA_BASICA_MASIVA(bamasiva2, 2);
      this.COBERTURA_BASICA_MASIVA(bamasiva3, 3);
      if (this.opciones.tipoPlan === 'PROTECANCER') {
        this.COBERTURA_IAC_MASIVA(iacmasiva1, 1);
        this.COBERTURA_IAC_MASIVA(iacmasiva2, 2);
        this.COBERTURA_IAC_MASIVA(iacmasiva3, 3);
      }
    }

    if (this.opciones.tipoPlan === 'MUJER UNICA' || (vpl === -1 && cdc === -1)) {
      this.COBERTURA_CDC(cdc);
      this.COBERTURA_VPL(vpl);
    }    
    this.COBERTURA_BASICA(ba);
    this.COBERTURA_BI(bi);
    this.COBERTURA_PAI(pai);
    this.COBERTURA_CC(cc);
    this.COBERTURA_DI(di);
    this.COBERTURA_DIPO(dipo);
    this.COBERTURA_DIPOC(dipoc);
    this.COBERTURA_IAC(iac);
    this.COBERTURA_PAE(pae);
    this.COBERTURA_GF(gf); 
    this.COBERTURA_PCD(pcd);

    if(this.opciones.tipoPlan === 'ALIADOSKIDS'){
      this.COBERTURA_TUTOR(this.TUTOR.sumaAsegurada_UDI.value);
      if( this.EFI_sumaAseguradaUDI.value === 'INCLUIDO')
        this.COBERTURA_EFI( this.TUTOR.primaAnual_UDI.value + this.BA.primaAnual_UDI.value);  
    }
  }

  calculaDeduccible() {
    let deducibleUdi: number;
    let deduciblePesos: number;
    let noDeducibleUdi: number;
    let noDeduciblePesos: number;
    const factorD = this.factor.factorDeducible;
    const saBAUDI = this.BASICA_sumaAseguradaUDI.value;
    const paBAUDI = this.BASICA_primaAnualUDI.value;
    const valueUDI = this.recursos.valor_UDI;

    deducibleUdi = (saBAUDI * factorD) / 1000;
    this.deducibleUDI = Math.round(deducibleUdi);

    deduciblePesos = this.deducibleUDI * valueUDI;
    this.deduciblePESOS = Math.round(deduciblePesos);

    noDeducibleUdi = paBAUDI - this.deducibleUDI;
    this.noDeducibleUDI = Math.round(noDeducibleUdi);

    noDeduciblePesos = this.recursos.UDItoMXN(noDeducibleUdi);
    this.noDeduciblesPESOS = Math.round(noDeduciblePesos);
  }

  calculaINPC() {
    this.porcentajeINPC =
      this.BASICA_sumaAseguradaUDI.value * this.inflacionINPC;
    this.saINPC = this.BASICA_sumaAseguradaUDI.value + this.porcentajeINPC;
  }

  calcularINPCCoberturas(prima: number, edad: number, sumasPasadas: number[] = [], aumentosPasados: number[] = [],
    aumentoBasica: number = 0, aumentoParaPCD: number = 0, anio: number, edadMancomunado: number): { last: number[], suma: number, sumasPasadas: number[] } {
    const plazo = 'ELEGIDO' as Plazo;
    const edadM = edadMancomunado === 0 ? edad : edadMancomunado;
    const primaBI = anio === 1 ? prima : aumentoBasica,
      sumaPAI = aumentosPasados.length === 0 ? this.PAI_sumaAseguradaUDI.value :
        sumasPasadas.length === 0 ? this.PAI_sumaAseguradaUDI.value * this.inflacionINPC
          : (sumasPasadas[0] + this.PAI_sumaAseguradaUDI.value) * this.inflacionINPC,
      sumaCC = aumentosPasados.length === 0 ? this.CC_sumaAseguradaUDI.value :
        sumasPasadas.length === 0 ? this.CC_sumaAseguradaUDI.value * this.inflacionINPC
          : (sumasPasadas[1] + this.CC_sumaAseguradaUDI.value) * this.inflacionINPC,
      sumaDI = aumentosPasados.length === 0 ? this.DI_sumaAseguradaUDI.value :
        sumasPasadas.length === 0 ? this.DI_sumaAseguradaUDI.value * this.inflacionINPC
          : (sumasPasadas[2] + this.DI_sumaAseguradaUDI.value) * this.inflacionINPC,
      sumaDIPO = aumentosPasados.length === 0 ? this.DIPO_sumaAseguradaUDI.value :
        sumasPasadas.length === 0 ? this.DIPO_sumaAseguradaUDI.value * this.inflacionINPC
          : (sumasPasadas[3] + this.DIPO_sumaAseguradaUDI.value) * this.inflacionINPC,
      sumaDIPOC = aumentosPasados.length === 0 ? this.DIPOC_sumaAseguradaUDI.value :
        sumasPasadas.length === 0 ? this.DIPOC_sumaAseguradaUDI.value * this.inflacionINPC
          : (sumasPasadas[4] + this.DIPOC_sumaAseguradaUDI.value) * this.inflacionINPC,
      sumaIAC = (edadM > IAC.CANCELLATION_DATE_INPC) ? sumasPasadas[5] :
        aumentosPasados.length === 0 ? this.IAC_sumaAseguradaUDI.value :
          sumasPasadas.length === 0 ? this.IAC_sumaAseguradaUDI.value * this.inflacionINPC
            : (sumasPasadas[5] + this.IAC_sumaAseguradaUDI.value) * this.inflacionINPC,
      sumaPAE = (edadM > PAE.CANCELLATION_DATE_INPC) ? sumasPasadas[6] :
        aumentosPasados.length === 0 ? this.PAE_sumaAseguradaUDI.value :
          sumasPasadas.length === 0 ? this.PAE_sumaAseguradaUDI.value * this.inflacionINPC
            : (sumasPasadas[6] + this.PAE_sumaAseguradaUDI.value) * this.inflacionINPC,
      sumaGF = (edadM > GF.ACCEPTANCE_AGE.MAX) ? sumasPasadas[7] :
        aumentosPasados.length === 0 ? this.GF_sumaAseguradaUDI.value :
          sumasPasadas.length === 0 ? this.GF_sumaAseguradaUDI.value * this.inflacionINPC
            : (sumasPasadas[7] + this.GF_sumaAseguradaUDI.value) * this.inflacionINPC;
    if (aumentosPasados.length > 0) {
      sumasPasadas = [sumaPAI + (sumasPasadas[0] || 0), sumaCC + (sumasPasadas[1] || 0), sumaDI + (sumasPasadas[2] || 0),
      sumaDIPO + (sumasPasadas[3] || 0), sumaDIPOC + (sumasPasadas[4] || 0),
      (edadM > IAC.CANCELLATION_DATE_INPC) ? sumasPasadas[5] : (sumaIAC + (sumasPasadas[5] || 0)),
      (edadM > PAE.CANCELLATION_DATE_INPC) ? sumasPasadas[6] : sumaPAE + (sumasPasadas[6] || 0),
      (edadM > GF.ACCEPTANCE_AGE.MAX) ? sumasPasadas[7] : sumaGF + (sumasPasadas[7] || 0), (sumasPasadas[8] || 0)];
    }

    const calculoCoberturas = {
      BI: edadM > BI.CANCELLATION_DATE_INPC ? 0 : this.BI.calculoPrimaBI(this.BI_sumaAseguradaUDI.value === 'INCLUIDO' ? primaBI : 0, plazo, true, edad),
      PAI: edadM > PAI.CANCELLATION_DATE_INPC ? 0 : this.PAI.calculoPrimaPAI(this.PAI_sumaAseguradaUDI.value > 0 ? sumaPAI : 0, plazo, true, edad),
      CC: edadM > CC.ACCEPTANCE_AGE.MAX ? 0 : this.CC.calculoPrimaCC(this.CC_sumaAseguradaUDI.value > 0 ? sumaCC : 0, plazo, true, edad),
      DI: edadM > DI.CANCELLATION_DATE_INPC ? 0 :
        this.DI.calculoPrimaDI(this.DI_sumaAseguradaUDI.value > 0 ? sumaDI : 0, plazo, true, edadM),
      DIPO: edadM > DIPO.CANCELLATION_DATE_INPC ? 0 :
        this.DIPO.calculoPrimaDIPO(this.DIPO_sumaAseguradaUDI.value > 0 ? sumaDIPO : 0, plazo, true, edadM),
      DIPOC: edadM > DIPOC.CANCELLATION_DATE_INPC ? 0 :
        this.DIPOC.calculoPrimaDIPOC(this.DIPOC_sumaAseguradaUDI.value > 0 ? sumaDIPOC : 0, plazo, true, edadM),
      IAC: edadM > IAC.CANCELLATION_DATE_INPC ? 0 : this.IAC.calculoPrimaIAC(this.IAC_sumaAseguradaUDI.value > 0 ? sumaIAC : 0, plazo, undefined, true, edad),
      PAE: edadM > PAE.CANCELLATION_DATE_INPC ? 0 : this.PAE.calculoPrimaPAE(this.PAE_sumaAseguradaUDI.value > 0 ? sumaPAE : 0, plazo, true, edad),
      GF: edadM > GF.ACCEPTANCE_AGE.MAX ? 0 :
        this.GF.calculoPrimaGF(this.GF_sumaAseguradaUDI.value > 0 ? sumaGF : 0, plazo, true, edadM),
    };
    // Funcionalidad / Incrementos 10 Años
    if (anio === 1) {
      this.inpcIac_diezMas = 0;
      this.inpcPae_diezMas = 0;
      this.inpcGfi_diezMas = 0;
    }
    if (this.opciones.arrEdad.includes(edadM.toString())) {
      // IAC
      const iacDiez = this.IAC.calculoPrimaIAC(this.IAC_sumaAseguradaUDI.value + sumasPasadas[5], plazo, undefined, true, edad);
      calculoCoberturas.IAC = (iacDiez - this.inpcIac_diezMas);
      this.inpcIac_diezMas = iacDiez;
      // PAE

      const paeDiez = this.PAE.calculoPrimaPAE(this.PAE_sumaAseguradaUDI.value + sumasPasadas[6], plazo, true, edad);
      calculoCoberturas.PAE = (paeDiez - this.inpcPae_diezMas);
      this.inpcPae_diezMas = paeDiez;

      // GF
      const gfDiez = this.GF.calculoPrimaGF(this.GF_sumaAseguradaUDI.value + sumasPasadas[7], plazo, true, edadM);
      calculoCoberturas.GF = (gfDiez - this.inpcGfi_diezMas);
      this.inpcGfi_diezMas = gfDiez;
    } else {
      this.inpcIac_diezMas += calculoCoberturas.IAC;
      this.inpcPae_diezMas += calculoCoberturas.PAE;
      this.inpcGfi_diezMas += calculoCoberturas.GF;
    }
    // end Incrementos 10 años

    const filteredCoberturas = [];
    let index = 0;
    for (const key in calculoCoberturas) {
      if (this.selectedCoberturas.indexOf(key) !== -1) {
        if (this.cancelationINPC(edad, key, edadMancomunado)) {
          filteredCoberturas.push(calculoCoberturas[key] + (aumentosPasados[index] || 0));
        } else {
          filteredCoberturas.push(0);
        }
        index++;
      }
    }
    const isPCD = this.selectedCoberturas.indexOf('PCD');
    if (isPCD !== -1) {
      /* if (edad > 60) {
        aumentoBasica = aumentoParaPCD;
      } */
      // const calculoCoberturasReduced = Object.values(calculoCoberturas).reduce((acc, value) => acc += value, 0);
      // const sumaPCD = edad > 65 ? 0 : this.PCD.calculoPrimaPCD([/*...filteredCoberturas*/ calculoCoberturasReduced,
      //   sumasPasadas.length === 0 ? prima : aumentoBasica], plazo, true, edad);
      const calculoCoberturasReduced = filteredCoberturas.reduce((acc, value) => acc += value, 0);
      const sumaPCD = edadM > 65 ? 0 : this.PCD.calculoPrimaPCD([/*...filteredCoberturas*/ calculoCoberturasReduced,
        prima], plazo, true, edad);

      const sumasReduced = sumasPasadas.reduce((a, b) => a + b, 0);
      const indexPCD = aumentosPasados.length - 1;

      if (sumasReduced !== 0 && (sumasPasadas[8] || 0) === 0) {
        sumasPasadas[8] = (aumentosPasados[indexPCD] || 0);
      }

      const toAdd = sumasReduced === 0 ? (aumentosPasados[indexPCD] || 0) : (sumasPasadas[8] || 0);
      const sumaToAdd = sumasPasadas.length === 0 ? sumaPCD : sumaPCD + toAdd;
      if (aumentosPasados.length > 0) {
        sumasPasadas[8] = sumaToAdd;
      }
      if (this.cancelationINPC(edad, 'PCD', 0)) {
        filteredCoberturas.push(sumaPCD);
      } else {
        filteredCoberturas.push(0);
      }
    }
    return { last: filteredCoberturas, suma: filteredCoberturas.reduce((x, y) => x + y), sumasPasadas };
  }

  private cancelationINPC(edad: number, cobertura: string, edadMancomunado?: number) {
    let b_valid;
    const edadM = edadMancomunado === 0 ? edad : edadMancomunado;
    switch (cobertura) {
      case 'BI':
        b_valid = this.yearOfCancel(edadM, BI);
        break;
      case 'PAI':
        b_valid = this.yearOfCancel(edadM, PAI);
        break;
      case 'CC':
        b_valid = this.yearOfCancel(edadM, CC);
        break;
      case 'DI':
        b_valid = this.yearOfCancel(edadM, DI);
        break;
      case 'DIPO':
        b_valid = this.yearOfCancel(edadM, DIPO);
        break;
      case 'DIPOC':
        b_valid = this.yearOfCancel(edadM, DIPOC);
        break;
      case 'IAC':
        b_valid = this.yearOfCancel(edadM, IAC);
        break;
      case 'PAE':
        b_valid = this.yearOfCancel(edadM, PAE);
        break;
      case 'GF':
        b_valid = this.yearOfCancel(edadM, GF);
        break;
      case 'PCD':
        b_valid = this.yearOfCancel(edadM, PCD);
        break;
      default:
        b_valid = false;
        break;
    }
    return b_valid;
  }

  private yearOfCancel(edad: number, cobertura: CoverageConstants) {
    let b_valid = true;
    if (edad > cobertura.CANCELLATION_DATE) {
      b_valid = false;
    }
    return b_valid;
  }


}
