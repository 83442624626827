/** CONSTANTS */
import { ROUTE_NAMING } from '@constants/routes/route-naming.constant';
/** ANGULAR CORE */
import { Injectable } from '@angular/core';
/** ROUTE */
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
/** RXJS */
import { Observable } from 'rxjs';
/** SERVICES */
import { ProspectoService } from '@services/prospecto/prospecto.service';
/** ENVIRONMENTS */
import { environment } from 'environments/environment';
/**
 * GUARD QUE NO PERMITE ACCEDER DESDE
 * RUTA ESPECÍFICA
 * @export
 * @class RouteGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  /**
   * CREA UNA INSTANCIA DE RouteGuard.
   * @param {ProspectoService} prospecto
   * @param {Router} router
   * @memberof RouteGuard
   */
  constructor(private prospecto: ProspectoService, private router: Router) {}
  /**
   * MÉTODO PARA REDIRIGIR A DATOS PROSPECTOS
   * EN CASO DE QUERER ACCEDER A UNA RUTA ESPECÍFICA
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof RouteGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {

    if (this.prospecto.isProspectuActive.value) {
      return this.prospecto.isProspectuActive.value;
    } else if (environment.activeRouteGuard) {
      // TODO
      /**
       * * Debería reedirecionar a landing, pero como esa ruta es inválida aún así te manda al landing.
       * ! Lo dejo pendiente.
       */
      this.router.navigate([ROUTE_NAMING.PASO1]);
      return false;
    }
    return true;
  }
}
