// Angular core
import { Injectable } from '@angular/core';
// Models
import { ValueFactors } from '@models/value-factors/value-factors';
import { FactorsSearch } from '@models/factors-search/factors-search.model';
import { CoverageFactors, Coverage, Plazo, Prospectu } from '@models/.';
// Servicios
import { BaseDatosService } from '@services/baseDatos/base-datos.service';
import { DeviceTypeService } from '../deviceType/deviceType.service';
import { DescipherService } from '../descipher/descipher.service';
import { PlanType } from '../../constants/plan-options/plan-types.constant.qa';
import { ProspectoService } from '../prospecto/prospecto.service';
import { ProtectionPlansGeneratorService } from '../protectionPlansGenerator/protection-plans-generator.service';
import { BehaviorSubject } from 'rxjs';
import { RoutesService } from '../routes/routes.service';
/**
 * Servicio de factores
 * Este servicio provee las caracteristicas y metodos relacionados con la petición y obtención
 * de factores de coberturas basica y adicionales y los factores de valores garantizados.
 * Almacena los factores del plazo de pago elegido y el de dos plazos sugeridos.
 *
 * @export
 * @class FactoresService
 */
@Injectable({
  providedIn: 'root'
})

export class FactoresService {

  /**variavle deducibles Factor */
  factorDeducible: number;

  /** Factores del plazo de pago elegido */
  factoresCoberturaElegido: CoverageFactors[] = [];

  factoresCoberturaINPC: CoverageFactors[] = [];

  /** Factores del plazo de pago elegido */
  factoresValoresElegido: ValueFactors[] = [];

  /** Factores del plazo de pago sugerido no. 1 */
  factoresCoberturaSugerido1: CoverageFactors[] = [];
  /** Factores del plazo de pago sugerido no. 1 */
  factoresValoresSugerido1: ValueFactors[] = [];

  /** Factores del plazo de pago sugerido no. 2 */
  factoresCoberturaSugerido2: CoverageFactors[] = [];
  /** Factores del plazo de pago sugerido no. 2 */
  factoresValoresSugerido2: ValueFactors[] = [];

  /**Factores Actuales */
  factoresActual: CoverageFactors[] = [];
  /**Factores Actuales */
  factoresValoresActual: ValueFactors[] = [];

  factoresValoresElegidoMU: ValueFactors[] = [];

  retiroValue = new BehaviorSubject<boolean>(false);

  plazosSugeridos: number[] = this.generator.plazosSugeridos.value;

  factorCDC: CoverageFactors[] = [];
  factorCDC_diezMas: CoverageFactors[] = [];
  factores_diezMas: CoverageFactors[] = [];
  factorVplMU: CoverageFactors[] = [];

  plan: PlanType;
  plazosPago: number;

  valorCkRetio = false;
  edad: Number;
  factor: any;
  planSelec: string;

  /**
   *Crea una instancia de FactoresService.
   * @param {BaseDatosService} DB
   * @memberof FactoresService
   */
  constructor(
    private DB: BaseDatosService,
    private device: DeviceTypeService,
    private descipher: DescipherService,
    private prospecto: ProspectoService,
    private generator: ProtectionPlansGeneratorService,
    private routes: RoutesService
  ) {
  }

  /**
   * Obtener Factores de coberturas por parametros
   *
   * @param FactorsSearch params
   * @param boolean [forceUpdate]
   * @memberof FactoresService
   */
  async generarFactoresCoberturas(params: FactorsSearch, forceUpdate?: boolean, specificCase: number = 0) {
    if (this.routes.chosenCotizador.value !== 'Universales') {
      return await new Promise<any>(async (resolve, reject) => {
        this.planSelec = params.tipoPlan;
        if (
          this.factoresCoberturaElegido === [] ||
          forceUpdate
        ) {
            
                this.DB.generarFactoresCoberturas(params)
                  .then((factores: CoverageFactors[][]) => {
                    const plazoF1 = factores[0][0].PlazoPago;
                    const plazoF2 = factores[1][0].PlazoPago;
                    const plazoF3 = factores[2][0].PlazoPago;
                    const dobleFactor = plazoF1 === plazoF2 || plazoF1 === plazoF3 || plazoF2 === plazoF3;
                  
                    switch (true) {
                      case dobleFactor:
                        if (plazoF1 === plazoF2) {
                          if (Number(plazoF1) === Number(params.plazoPago)) {
                            this.factoresCoberturaElegido = factores[0];
                            this.factoresCoberturaSugerido1 = this.factoresCoberturaSugerido2 = factores[2];
                          } else {
                            this.factoresCoberturaSugerido1 = this.factoresCoberturaSugerido2 = factores[0];
                            this.factoresCoberturaElegido = factores[2];
                          }
                        } else if (plazoF1 === plazoF3) {
                          if (Number(plazoF1) === Number(params.plazoPago)) {
                            this.factoresCoberturaElegido = factores[0];
                            this.factoresCoberturaSugerido1 = this.factoresCoberturaSugerido2 = factores[1];
                          } else {
                            this.factoresCoberturaSugerido1 = this.factoresCoberturaSugerido2 = factores[0];
                            this.factoresCoberturaElegido = factores[1];
                          }
                        } else {
                          if (Number(plazoF2) === Number(params.plazoPago)) {
                            this.factoresCoberturaElegido = factores[1];
                            this.factoresCoberturaSugerido1 = this.factoresCoberturaSugerido2 = factores[0];
                          } else {
                            this.factoresCoberturaSugerido1 = this.factoresCoberturaSugerido2 = factores[1];
                            this.factoresCoberturaElegido = factores[0];
                          }
                          this.factoresActual = this.factoresCoberturaElegido;
                        }
                        break;
                      case Number(plazoF1) === Number(params.plazoPago):
                        this.factoresCoberturaElegido = factores[0];
                        this.factoresCoberturaSugerido1 = factores[1];
                        this.factoresCoberturaSugerido2 = factores[2];
                        this.factoresActual = this.factoresCoberturaElegido;
                        break;
                      case Number(plazoF2) === Number(params.plazoPago):
                        this.factoresCoberturaSugerido1 = factores[0]; // 60
                        this.factoresCoberturaElegido = factores[1];
                        this.factoresCoberturaSugerido2 = factores[2];
                        this.factoresActual = this.factoresCoberturaElegido;
                        break;
                      case Number(plazoF3) === Number(params.plazoPago):
                        this.factoresCoberturaSugerido1 = factores[0];
                        this.factoresCoberturaSugerido2 = factores[1];
                        this.factoresCoberturaElegido = factores[2];
                        this.factoresActual = this.factoresCoberturaElegido;
                        break;
                    }

                    this.factoresActual = this.factoresCoberturaElegido;
                    resolve('Se han obtenido los factores de manera correcta!');
                  })
                  .catch(reason => {
                    console.log(reason);
                    reject('FACTORES::: Coberturas BA - ERROR');
                  });
            this.device;
        }
      });
    }


  }

  /**
 * Obtener Factores de coberturas para la seleccion de Propuestas
 *
 * @param FactorsSearch params
 * @param boolean [forceUpdate]
 * @memberof FactoresService
 */
  async generarFactoresCoberturasPropuestas(arrParams: FactorsSearch[]) {
    return await new Promise<any>((resolve, reject) => {
      
      
          arrParams = arrParams.slice(1);
          let res = 0;
          for (let i = 0; i < arrParams.length; i++) {
            this.DB.obtenerFactoresCoberturas(arrParams[i])
              .then((factor: CoverageFactors[]) => {
                if (i === 0) {
                  this.factoresCoberturaSugerido1 = factor;
                  res = res + 1;
                } else {
                  this.factoresCoberturaSugerido2 = factor;
                  res = res + 1;
                }
                if (res === arrParams.length) {
                  resolve('Se han obtenido los ' + arrParams.length + ' factores de manera correcta!');
                }
              })
              .catch(reason => {

                reject('FACTORES::: Coberturas BA - ERROR');
              });
          }
       

    });
  }

  async generarFactoresMujerUnica(params: FactorsSearch) {
    return await new Promise<any>(async (resolve, reject) => {
      
      const res = await this.DB.generarFactoresMujerUnica(params);
      this.factorVplMU = res[0];
      this.factoresCoberturaElegido = res[1];
      this.factorCDC = res[2];
      this.factorCDC_diezMas = res[3];
      resolve();
    });
  }

  async generarFactoresValoresGMujerUnica(params: FactorsSearch) {
    return await new Promise<any>(async (resolve, reject) => {
      const res = await this.DB.generarFactoresValoresGMujerUnica(params);
      this.factoresValoresElegidoMU = res[0];
      this.factoresValoresElegido = res[1];

      resolve();
    });
  }

  async generarFactoresCoberturasINPC(arrParams: FactorsSearch) {
    return await new Promise<any>(async (resolve, reject) => {
      try {
        
        const res = await this.DB.generarFactoresCoberturasINPC(arrParams);

        this.factoresCoberturaINPC = res as CoverageFactors[];

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Genera los factores de Valores Garantizados
   * @method
   * @param params
   */
  async generarFactoresValoresG(params: FactorsSearch) {
    if (this.routes.chosenCotizador.value !== 'Universales') {
      return await new Promise<any>((resolve, reject) => {
        this.DB.generarFactoresValoresG(params)
          .then((factores: ValueFactors[][]) => {
            const plazoF1 = factores[0][0].PlazoPago;
            const plazoF2 = factores[1][0].PlazoPago;
            const plazoF3 = factores[2][0].PlazoPago;

            switch (true) {
              case Number(plazoF1) === Number(params.plazoPago):
                this.factoresValoresElegido = factores[0];
                this.factoresValoresSugerido1 = factores[1];
                this.factoresValoresSugerido2 = factores[2];
                break;
              case Number(plazoF2) === Number(params.plazoPago):
                this.factoresValoresSugerido1 = factores[0]; // 60
                this.factoresValoresElegido = factores[1];
                this.factoresValoresSugerido2 = factores[2]; // 65 -> E
                break;
              case Number(plazoF3) === Number(params.plazoPago):
                this.factoresValoresSugerido1 = factores[0];
                this.factoresValoresSugerido2 = factores[1];
                this.factoresValoresElegido = factores[2];
                break;
            }

            resolve('Se han obtenido los factores de años polizas de manera correcta!');
          })
          .catch(reason => {
            console.log(reason);
            reject('FACTORES::: Valores Garantizados - ERROR');
          });
      });
    }
  }

  /**
 * Genera los factores de Valores Garantizados para Propuestas
 * @method
 * @param params
 */
  async generarFactoresValoresGPropuestas(arrParams: FactorsSearch[]) {
    if (this.routes.chosenCotizador.value !== 'Universales') {
      return await new Promise<any>((resolve, reject) => {
        arrParams = arrParams.slice(1);
        let res = 0;
        for (let i = 0; i < arrParams.length; i++) {
          this.DB.generarFactoresValoresGPropuestas(arrParams[i])
            .then((factores: ValueFactors[]) => {
              if (i === 0) {
                this.factoresValoresSugerido1 = factores;
                res = res + 1;
              } else {
                this.factoresValoresSugerido2 = factores;
                res = res + 1;
              }
              if (res === arrParams.length) {
                resolve('Se han obtenido los factores de años polizas de manera correcta!');
              }
          })
          .catch(reason => {
            console.log(reason);
            reject('FACTORES::: Valores Garantizados - ERROR');
          });
        }
      });
    }
  }

  /**
   * Busca y retorna el factor de una cobertura establecida dependiendo del plazo
   * de pago.
   *
   * @param Coverage Cobertura a obtener su factor
   * @param Plazo Tipo
   * @returns number Tarifa
   * @memberof FactoresService
   */
  obtenerFactorCobertura(cobertura: Coverage, plazo: Plazo, isINPC: boolean = false, edadINPC?: number, plan?: string): number {
    // Factores de coberturas toma un valor inicial con los factores de
    // Coberturas del plazo elegido
    if (cobertura === 'CDC') {
      return Number(this.descipher.desiph(this.factorCDC[0].Tarifa));
    }

    if (cobertura === ('VPL' as Coverage)) {
      return Number(this.descipher.desiph(this.factorVplMU[0].Tarifa));
    }
    let factores: CoverageFactors[] = this.factoresCoberturaElegido;
    if (isINPC) {
      factores = this.factoresCoberturaINPC;

      const tarifa = factores.filter(result => cobertura === result.Cobertura && Number(edadINPC) === Number(result.Edad));

      if (tarifa.length > 0) {
        return Number(this.descipher.desiph(tarifa[0].Tarifa));
      } else {
        return 0;
      }
    }
    // Evalua el plazo de pago
    switch (true) {
      case plazo === 'Elegido':
        factores = this.factoresCoberturaElegido;
        break;
      case plazo === 'Sugerido1':
        factores = this.factoresCoberturaSugerido1;
        break;
      case plazo === 'Sugerido2':
        factores = this.factoresCoberturaSugerido2;
        break;

    }
    // Se comprueba que los factores exista
    if( this.planSelec === 'ALIADOSKIDS' && factores.length !== 0){
      let planKids = plan ? plan : 'DOTAL';
      const tarifa = factores.filter(result => (cobertura === result.Cobertura) && result.Plan === planKids);
      if (tarifa.length > 0) {
        return Number(this.descipher.desiph(tarifa[0].Tarifa));
      } else {
        return 0;
      }
    }else if (factores.length !== 0) {      
      const edadProspecto = (this.prospecto.prospecto.value.mancomunado.mancomunado ?
        this.prospecto.prospecto.value.mancomunado.edadEquivalente : this.prospecto.prospecto.value.age);

      switch (this.prospecto.prospecto.value.mancomunado.mancomunado) {
        case this.planSelec === 'DOTAL' && cobertura === 'BASICA':
          this.edad = this.prospecto.prospecto.value.mancomunado.edadEquivalente;
          break;

        case this.planSelec === 'DOTAL'
          && (cobertura === 'BI'
            || cobertura === 'PAI'):
          this.edad = this.prospecto.prospecto.value.age;
          break;

        case this.planSelec !== 'DOTAL'
          && (cobertura === 'BASICA'
            || cobertura === 'DI'
            || cobertura === 'DIPO'
            || cobertura === 'DIPOC'
            || cobertura === 'GF'):
          this.edad = this.prospecto.prospecto.value.mancomunado.edadEquivalente;
          break;

        case this.planSelec !== 'DOTAL'
          && (cobertura === 'BI'
            || cobertura === 'PAI'
            || cobertura === 'IAC'
            || cobertura === 'PAE'):
          this.edad = this.prospecto.prospecto.value.age;
          break;

      }

      if (!this.prospecto.prospecto.value.mancomunado.mancomunado) {
        this.edad = this.prospecto.prospecto.value.age;
      }

      if (this.planSelec === 'DOTAL' && cobertura === 'BASICA' && edadProspecto >= 61 && plazo === 'Sugerido2') {
        this.factor = 0;
      } else if (this.planSelec === 'DOTAL' && cobertura === 'BASICA' && edadProspecto >= 61 && this.plazosPago === 65) {
        this.factor = 0;
      }

      // return Number(
        const tarifa = factores.filter(result => (cobertura === result.Cobertura
          || (cobertura === 'BASICA' && result.Cobertura === 'BA')) && Number(this.edad) === Number(result.Edad));
        if (tarifa.length > 0) {
          return Number(this.descipher.desiph(tarifa[0].Tarifa));
        } else {
          return 0;
        }
        // factores.filter(result => cobertura === result.Cobertura && edad === result.Edad)[0].Tarifa
    } else {
      return 0;
    }
  }

    /**
 * MÉTODO QUE OBTIENE LA TARIFA Y LA DESCIFRA DE LAS COBERTURAS QUE NECESITAN NUEVA PRIMA CADA DIEZ AÑOS
 * @param {Coverage} cobertura
 * @param {number} edad
 * @memberof FactoresService
 */
  obtenerFactoresDiezMas(cobertura: Coverage, edad: number) {
    if (edad > 70) {
      // Cuando sea un incremento y la edad supere los 70 años este devolvera 0
      return 0;
    } else {
      if (cobertura === 'CDC') {
        return Number(this.descipher.desiph(this.factorCDC_diezMas.filter(s => Number(s.Edad) === Number(edad))[0].Tarifa));
      } else {
        return Number(this.descipher.desiph(this.factores_diezMas.filter(s => Number(s.Edad) === Number(edad) &&
        s.Cobertura === cobertura)[0].Tarifa));
      }
    }


  }

  getEspecificFactors(cobertura: Coverage) {
    return this.descipher.desiph(this.factoresActual.filter(result => cobertura === result.Cobertura && Number(this.edad) === Number(result.Edad))[0].Tarifa);
  }

  // async obtenerFactorDedicibleAliasdosMas65(gender: string, habit: string, age: string) {
  async obtenerFactorDedicibleAliasdosMas65(params: Prospectu) {
    return await new Promise<any>((resolve, reject) => {
        this.DB.getAliadosMas65Deducible(params.gender, params.fumador, String(params.age))
          .then(data => {
            const fDeducible = data.docs[0].Factor;
            this.factorDeducible = Number(this.descipher.desiph(String(fDeducible)));
            console.log('valor factor deducible factores.service: ' + this.factorDeducible);
            resolve('Se ha obtenido el factor de deducible correctamente');
          })
          .catch(reason => {
            reject('FACTOR::: DECIBLE: ERROR');
          });
    });
  }

    /**
 * MÉTODO QUE LLAMA A LA BASE DE DATOS Y GUARDA LOS FACTORES DENTRO DE UNA VARIABLE DEL SERVICIO
 * @param {FactorsSearch} params
 * @memberof FactoresService
 */
  async generarFactoresDiezMas(params: FactorsSearch) {
    const arrDiezMas = await this.DB.generarFactoresDiezMas(params);
    this.factores_diezMas = arrDiezMas[0];
  }

}
