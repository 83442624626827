/** Angular Core */
import { Injectable } from '@angular/core';
/** Models & Constants */
import { Prospectu, User, PaymentType } from '../../models';
import {
  ValorGarantizado,
  ValoresGarantizadosTabla
} from '@models/valor-garantizado/ValorGarantizado';
import { Currency, SPLIT_PAYMENT } from '../../constants';
/** Pipes */
import { CurrencyPipe } from '@angular/common';
import { CurrencyToTextPipe } from './../../pipes/currency-to-text/currency-to-text.pipe';
/** JSPDF */
declare let jsPDF;
/** Services */
import { NetworkStateService } from './../../services/networkState/network-state.service';
import { ValoresGarantizadosService } from '@services/valoresGarantizados/valores-garantizados.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { GeneradorTablaValoresService } from '@services/generadorTablaValores/generador-tabla-valores.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { CalculosService } from '@services/calculos/calculos.service';
import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { PaymentFrequencyService } from '../paymentFrequency/payment-frequency.service';
import { FactoresService } from '../factores/factores.service';

class COBERTURASDEFAULT {
  constructor(
      public nombreCobertura: String,
      public sumaAseguradaUdi: String,
      public sumaAseguradaUsd: String,
      public sumaAseguradaMxn: String,
      public primaUdi: String,
      public primaUsd: String,
      public primaMxn: String,
      public visible: boolean
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class PdfValuesService {
  /**
   * Valida si cobertura BI es Seleccionada
   * @property
   * @type {boolean}
   */
  isBasicaActive: boolean;
  /**
   * Valida si cobertura BI es Seleccionada
   * @property
   * @type {boolean}
   */
  isBiActive: boolean;
  /**
   * Valida si coberturA PAI es Seleccionada
   * @property
   * @type {boolean}
   */
  isPaiActive: boolean;
  /**
   * Valida si cobertura DI es Seleccionada
   * @property
   * @type {boolean}
   */
  isDiActive: boolean;
  /**
   * Valida si cobertura DIPO es Seleccionada
   * @property
   * @type {boolean}
   */
  isDipoActive: boolean;
  /**
   * Valida si cobertura DIPOC es Seleccionada
   * @property
   * @type {boolean}
   */
  isDipocActive: boolean;
  /**
   * Valida si cobertura IAC es Seleccionada
   * @property
   * @type {boolean}
   */
  isIacActive: boolean;
  /**
   * Valida si cobertura PAE es Seleccionada
   * @property
   * @type {boolean}
   */
  isPaeActive: boolean;
  /**
   * Valida si cobertura CC es Seleccionada
   * @property
   * @type {boolean}
   */
  isCCActive: boolean;
  /**
   * Valida si cobertura PCD es Seleccionada
   * @property
   * @type {boolean}
   */
  isPcdActive: boolean;
  /**
   * Valida si cobertura GF es Seleccionada
   * @property
   * @type {boolean}
   */
  isGfActive: boolean;
  
  isEFIActive: boolean;

  isTutorActive: boolean;
  /**
   * Parametros de la tabla
   * @property
   * @type {Array<ValoresGarantizadosTabla[]>}
   */
  vistaVg: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Primeros parametros de la tabla (en valores mayores a 60)
   * @property
   * @type {Array<ValoresGarantizadosTabla[]>}
   */
  vistaVg1: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Segundos parametros de la tabla (en valores mayores a 60)
   * @property
   * @type {Array<ValoresGarantizadosTabla[]>}
   */
  vistaVg2: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Valores garantizados en columnas
   * @property
   * @type {ValoresGarantizadosTablas[]}
   */
  vistaCols: ValoresGarantizadosTabla[] = [];

  /**
   * Modelo de los resultados de los valores garantizados
   * @property
   * @type {ValorGarantizado[]}
   */
  resultados: ValorGarantizado[] = [
    {
      edad: 0,
      anioPoliza: 0,
      primaElegida: 0,
      aportacionAdicional_UDI: 0,
      prima_AcumuladaUDI: 0,
      primaDeducibleUDI: 0,
      // primaNoDeducibleUDI: 0,
      valorRescate: 0,
      porcentajeRecuperacionUdi: 0,
      sumaAsegurada: 0,
      valorUDI: 0,
      primaElegida_Pesos: 0,
      aportacionAdicional_MXN: 0,
      prima_AcumuladaMXN: 0,
      primaDeducibleMXN: 0,
      // primaNoDeducibleMXN: 0,
      valorRescate_Pesos: 0,
      porcentajeRecuperacionPesos: 0,
      sumaAsegurada_Pesos: 0,
      valorEnEfectivo_UDI: 0,
      valorEnEfectivo_Pesos: 0,
      aportacionAdicional: 0,
      aportacionAdicionalProyectada: 0,
      porcentajeRecuperacionUdiBaCoExcd: 0,
      porcentajeRecuperacionPesosBaCoExcd: 0,
      porcentajeRecuperacionUdiBaCo: 0,
      porcentajeRecuperacionPesosBaCo: 0
    }
  ];
  /**
   * Propecto actual
   * @property
   * @type {Prospectu}
   */
  prospecto: Prospectu;

  /**
   * Folio generado
   * @property
   * @type {string}
   */
  strFolio: string;

  /**
   * Fecha actual
   * @property
   */
  date: any;
  /**
   * String para fecha
   * @property
   */
  de = ' de ';
  /**
   * Meses actuales
   * @property
   * @type {number}
   */
  meses;
  /**
   * Fecha actual
   * @property
   * @type {number}
   */
  f;
  /**
   * Tipo de plan seleccionado
   * @property
   * @type {any}
   */
  plan: any;
  /**
   * Tiempo de espera la impresión
   * @property
   */
  time;
  /**
   * Tipo de moneda seleccionado
   * @property
   * @type {Currency}
   */
  moneda: Currency;
  /**
   * Tipo de moneda seleccionado
   * @property
   * @type {Currency}
   */
  segundaMoneda: Currency;
  /**
   * Tipo de plzo de pagos seleccionado
   * @property
   * @type {number}
   */
  plazoPagos: number;
  /**
   * Primas totales de todas las coberturas en primera moneda
   * @property
   * @type {number}
   */
  primaTotalUDI: number;
  /**
   * Sumas aseguradas de todas las coberturas en primera moneda
   * @property
   * @type {number}
   */
  sumaAseguradaUDI: number;
  /**
   * Valor actual de UDI
   * @property
   * @type {number}
   */
  valorUDI: number;
  /**
   * Primas totales de todas las coberturas en segunda moneda
   * @property
   * @type {number}
   */
  primaTotalPeso: number;
  /**
   * Sumas aseguradas de todas las coberturas en segunda moneda
   * @property
   * @type {number}
   */
  primaAnualPeso: number;
  /**
   * Inflación anual seleccionada
   * @property
   * @type {string}
   */
  inflacion_anual: number;
  /**
   * Valores de la tabla
   * @property
   * @type {ValorGarantizado[}
   */
  tabladata: ValorGarantizado[] = [];
  /**
   * Versión del pdf
   * @property
   * @type {string}
   */
  version: string;

  /**
   * Datos del usuario
   * @property
   * @type {User}
   */
  user: User;

  /**
   * Variable booleana para identificar cuando
   * el plan es Aliados+
   * @type {boolean}
   */
  isDotal: boolean;
  /**
   * Variable booleana para identificar que se ejecuta en iOS
   * @type {boolean}
   */
  isiOS: boolean;
  /**
   * Variable numérica que almacena las coberturas activas
   * en la cortización
   * @type {number}
   * @memberof PdfValuesComponent
   */
  activeCoverages: number;
  /**
   * Variable numérica que almacena el valor de la posición Y
   * @type {number}
   * @memberof PdfValuesComponent
   */
  poscY: number;
  /**
   * Variable numérica que almacena el valor de la posición X
   * @type {number}
   * @memberof PdfValuesComponent
   */
  poscX: number;
  /**
   * Variable booleana que almacena el valor actual de mancomunado (activo/desactivado)
   * @type {boolean}
   * @memberof PdfValuesComponent
   */
  mancomunado: boolean;
  /**
   * Variable que almacena el tipo de producto (PROTGT/ALIADOS)
   * @type {string}
   * @memberof PdfValuesComponent
   */
  product: string;
  idUDIValue: BehaviorSubject<number> = new BehaviorSubject(undefined);
  rowNumb: BehaviorSubject<number> = new BehaviorSubject(0);
  totalPages = 2;
  poscYs = 0;

  page: number;
  a: number;

  moneda1: string;
  moneda2: string;

  tasa_aportacion: number;

  aporte: number;

  isRetiro: boolean;
  retiro: string;

  private bannerBlobURL: string;

  projectionBA: Boolean = false;
  projectionBACO: Boolean = false;
  projectionBACOAA: Boolean = false;

  valoresCoberturasDefault: Array<COBERTURASDEFAULT> = [];
  /**
   *Creates an instance of PdfValuesComponent.
   * @param {GeneradorTablaValoresService} generadorTabla
   * @param {ValoresGarantizadosService} valoresG
   * @param {ProspectoService} prospectu
   * @param {BaseDatosService} bd
   * @param {DeviceTypeService} deviceservice
   * @param {OpcionesPlanService} opciones
   * @param {CalculosService} calculos
   * @param {ExcedentesService} excedentes
   * @param {RecursosExternosService} recursos
   * @param {AuthenticationService} authentication
   * @param {CurrencyPipe} currency
   * @param {CurrencyToTextPipe} currencyText
   * @param {ProductTypeService} productType
   * @memberof PdfValuesComponent
   */
  constructor(
    private generadorTabla: GeneradorTablaValoresService,
    private valoresG: ValoresGarantizadosService,
    private prospectu: ProspectoService,
    private deviceservice: DeviceTypeService,
    public opciones: OpcionesPlanService,
    public calculos: CalculosService,
    public excedentes: ExcedentesService,
    private recursos: RecursosExternosService,
    private authentication: AuthenticationService,
    private connect: NetworkStateService,
    private paymentFrequency: PaymentFrequencyService,
    private factorD: FactoresService
  ) {
    this.authentication.user.subscribe(user => (this.user = user));
    this.meses = new Array(
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    );
    this.factorD.retiroValue.subscribe(val => {
      this.isRetiro = val;
      if (this.isRetiro) {
        this.retiro = 'Retiro ';
      } else {
        this.retiro = '';
      }
    });
    this.f = new Date();
    this.valoresG.tablavalores.subscribe(
      valores => (this.resultados = valores)
    );
    this.date =
      this.f.getDate() +
      this.de +
      this.meses[this.f.getMonth()] +
      this.de +
      this.f.getFullYear();
    // this.tabladata = this.generadorTabla.saveTable;
    this.vistaCols = this.generadorTabla.vistaCols;
    this.vistaVg = this.generadorTabla.vistaVg;
    this.activeCoverages = 0;
  }

  /**
   * Consigue el tipo de dispositivo en el que se encuentra es válido
   * @method
   * @return {boolean}
   */
  getDeviceType(): boolean {
    const device = this.deviceservice.getDeviceType();
    if (device === 'iPhone' || device === 'iPad' || device === 'Android') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checa si el género del prospecto es mujer
   * @method
   * @return {boolean}
   */
  isFemaleActive(): boolean {
    // BUG - Se repite letias veces la compobación - arreglar
    return this.prospecto.gender === 'M';
  }

  /**
   * Consigue la versión del pdf
   * @method
   * @return {string}
   */
  getVersion(): string {
    let v = '';
    const vanio = this.f.getFullYear();
    const vmes = this.f.getMonth() + 1;
    v = vanio + '.' + vmes;
    return v;
  }

  /**
   * Método para detectar las coberturas que estan Activas
   * @param {jsPDF} pdf
   * @param {number} poscY
   * @returns
   * @memberof PdfValuesComponent
   */
  async getActiveCoverages(pdf: any, poscY: number) {
    this.isBasicaActive = false;
    this.isBiActive = false;
    this.isPaiActive = false;
    this.isDiActive = false;
    this.isDipoActive = false;
    this.isDipocActive = false;
    this.isIacActive = false;
    this.isPaeActive = false;
    this.isCCActive = false;
    this.isPcdActive = false;
    this.isGfActive = false;
    // BA
    if (this.calculos.BASICA_primaAnualUDI.value > 0 && this.plan !== 'ALIADOSKIDS') {
      poscY = poscY + 4;
      let primaAnualUdiBA, primaAnualPesosBA;
      let primaAnualUdiVPL, primaAnualPesosVPL;
      let primaAnualUdiCDC, primaAnualPesosCDC;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
          primaAnualUdiBA = this.calculos.BASICA_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
          primaAnualUdiBA = this.calculos.BASICA_primaAnualUDI_Sugerida2.value;
          break;
        default:
          primaAnualUdiBA = this.calculos.BASICA_primaAnualUDI.value;
          break;
      }

      primaAnualPesosBA = this.recursos.UDItoMXN(primaAnualUdiBA);
      primaAnualPesosVPL = this.recursos.UDItoMXN(this.calculos.VPL_primaAnual_PrimeraMoneda.value);
      primaAnualPesosCDC = this.recursos.UDItoMXN(this.calculos.CDC_primaAnual_PrimeraMoneda.value);

      primaAnualUdiVPL = this.recursos.UDItoMXN(this.calculos.VPL_sumaAsegurada_PrimeraMoneda.value);
      primaAnualUdiCDC = this.recursos.UDItoMXN(this.calculos.CDC_sumaAsegurada_PrimeraMoneda.value);

      this.poscYs = poscY;
      this.valoresCoberturasDefault = [];

      this.setCoveragesListDefault(primaAnualUdiBA, primaAnualPesosBA, primaAnualUdiVPL,
        primaAnualPesosVPL, primaAnualUdiCDC, primaAnualPesosCDC);

      for (let j = 0; j < this.valoresCoberturasDefault.length; j++) {
        if (j > 0) {
          this.poscYs = this.poscYs + Number(4);
        }
        pdf.text(20, this.poscYs, String(this.valoresCoberturasDefault[j].nombreCobertura));
        pdf.text(
          105,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.valoresCoberturasDefault[j].sumaAseguradaUdi,
              this.moneda,
              this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          160,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.valoresCoberturasDefault[j].primaUdi,
              this.moneda,
              this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        if (this.moneda === 'UDI') {
          pdf.text(
            190,
            this.poscYs,
            CurrencyPipe.prototype
              .transform(
                this.valoresCoberturasDefault[j].sumaAseguradaMxn,
                this.segundaMoneda,
                'symbol-narrow',
                '1.0-0',
                'en-US'
              )
              .toString()
          );

          pdf.text(
            245,
            this.poscYs,
            CurrencyPipe.prototype
              .transform(
                this.valoresCoberturasDefault[j].primaMxn,
                this.segundaMoneda,
                'symbol-narrow',
                '1.0-0',
                'en-US'
              )
              .toString()
          );
        }
      }
      this.activeCoverages++;
      this.isBasicaActive = true;
    }

    // BI
    if (this.calculos.BI_sumaAseguradaUDI.value !== 'EXCLUIDO') {
      this.poscYs = this.poscYs + Number(4);

      pdf.text(
        20,
        this.poscYs,
        'Exención de pago de primas por invalidez total\ny permanente(BI)'
      );

      if (this.prospecto.mancomunado.mancomunado === true) {
        pdf.setTextColor(255, 0, 0);
        pdf.text(47, this.poscYs + 4, '*');
        pdf.setTextColor(0, 0, 0);
      }
      let primaAnualUdiBI, primaAnualPesosBI;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
          primaAnualUdiBI = this.calculos.BI_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
          primaAnualUdiBI = this.calculos.BI_primaAnualUDI_Sugerida2.value;
          break;
        default:
          primaAnualUdiBI = this.calculos.BI_primaAnualUDI.value;
          break;
      }
      primaAnualPesosBI = this.recursos.UDItoMXN(primaAnualUdiBI);
      pdf.text(106, this.poscYs + 2, this.calculos.BI_sumaAseguradaUDI.value);

      pdf.text(
        160,
        this.poscYs + 2,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiBI,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(190, this.poscYs + 2, this.calculos.BI_sumaAseguradaPesos.value);

        pdf.text(
          245,
          this.poscYs + 2,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosBI,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }
      this.activeCoverages++;
      this.isBiActive = true;
    }
    // PAI
    if (this.calculos.PAI_sumaAseguradaUDI.value > 0) {
      this.poscYs = this.isBiActive ? this.poscYs + 8 : this.poscYs + 4;

      pdf.text(
        20,
        this.poscYs,
        'Pago adicional por invalidez total\ny permanente(PAI)'
      );

      if (this.prospecto.mancomunado.mancomunado === true) {
        pdf.setTextColor(255, 0, 0);
        pdf.text(50, this.poscYs + 4, '*');
        pdf.setTextColor(0, 0, 0);
      }

      let primaAnualUdiPAI, primaAnualPesosPAI;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
          primaAnualUdiPAI = this.calculos.PAI_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
          primaAnualUdiPAI = this.calculos.PAI_primaAnualUDI_Sugerida2.value;
          break;
        default:
          primaAnualUdiPAI = this.calculos.PAI_primaAnualUDI.value;
          break;
      }
      primaAnualPesosPAI = this.recursos.UDItoMXN(primaAnualUdiPAI);

      pdf.text(
        105,
        this.poscYs + 2,
        CurrencyPipe.prototype
          .transform(
            this.calculos.PAI_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs + 2,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiPAI,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs + 2,
          CurrencyPipe.prototype
            .transform(
              this.calculos.PAI_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs + 2,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosPAI,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }
      this.activeCoverages++;
      this.isPaiActive = true;
    }
    // DI
    if (this.calculos.DI_sumaAseguradaUDI.value > 0) {
      this.poscYs = this.isBiActive || this.isPaiActive ? this.poscYs + 8 : this.poscYs + 4;

      let primaAnualUdiDI, primaAnualPesosDI;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
          primaAnualUdiDI = this.calculos.DI_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
          primaAnualUdiDI = this.calculos.DI_primaAnualUDI_Sugerida2.value;
          break;
        default:
          primaAnualUdiDI = this.calculos.DI_primaAnualUDI.value;
          break;
      }
      primaAnualPesosDI = this.recursos.UDItoMXN(primaAnualUdiDI);

      pdf.text(20, this.poscYs, 'Muerte Accidental(DI)');

      pdf.text(
        105,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            this.calculos.DI_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiDI,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.calculos.DI_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosDI,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }
      this.activeCoverages++;
      this.isDiActive = true;
    }
    // DIPO
    if (this.calculos.DIPO_sumaAseguradaUDI.value > 0) {
      this.poscYs = this.isBiActive || this.isPaiActive ? this.poscYs + 8 : this.poscYs + 4;

      let primaAnualUdiDIPO, primaAnualPesosDIPO;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
        primaAnualUdiDIPO = this.calculos.DIPO_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
        primaAnualUdiDIPO = this.calculos.DIPO_primaAnualUDI_Sugerida2.value;
          break;
        default:
        primaAnualUdiDIPO = this.calculos.DIPO_primaAnualUDI.value;
          break;
      }
      primaAnualPesosDIPO = this.recursos.UDItoMXN(primaAnualUdiDIPO);

      pdf.text(20, this.poscYs, 'Muerte Accidental y Pérdidas Orgánicas(DIPO)');

      pdf.text(
        105,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            this.calculos.DIPO_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiDIPO,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.calculos.DIPO_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosDIPO,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }

      this.activeCoverages++;
      this.isDipoActive = true;
    }
    // DIPOC
    if (this.calculos.DIPOC_sumaAseguradaUDI.value > 0) {
      this.poscYs = this.isBiActive || this.isPaiActive ? this.poscYs + 8 : this.poscYs + 4;
      let primaAnualUdiDIPOC, primaAnualPesosDIPOC;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
        primaAnualUdiDIPOC = this.calculos.DIPOC_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
        primaAnualUdiDIPOC = this.calculos.DIPOC_primaAnualUDI_Sugerida2.value;
          break;
        default:
        primaAnualUdiDIPOC = this.calculos.DIPOC_primaAnualUDI.value;
          break;
      }
      primaAnualPesosDIPOC = this.recursos.UDItoMXN(primaAnualUdiDIPOC);

      pdf.text(
        20,
        this.poscYs,
        'Muerte Accidental y Pérdidas Orgánicas\ny Muerte Colectiva(DIPOC)'
      );

      pdf.text(
        105,
        this.poscYs + 2,
        CurrencyPipe.prototype
          .transform(
            this.calculos.DIPOC_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs + 2,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiDIPOC,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs + 2,
          CurrencyPipe.prototype
            .transform(
              this.calculos.DIPOC_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs + 2,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosDIPOC,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }
      this.activeCoverages++;
      this.isDipocActive = true;
    }
    // IAC
    if (this.calculos.IAC_sumaAseguradaUDI.value > 0) {
      this.poscYs =
        (this.isBiActive || this.isPaiActive || this.isDipocActive) &&
          (!this.isDiActive && !this.isDipoActive)
          ? this.poscYs + 8
          : this.poscYs + 4;

      pdf.text(20, this.poscYs, 'Indemización adicional por cáncer(IAC)');

      let primaAnualUdiIAC, primaAnualPesosIAC;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
        primaAnualUdiIAC = this.calculos.IAC_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
        primaAnualUdiIAC = this.calculos.IAC_primaAnualUDI_Sugerida2.value;
          break;
        default:
        primaAnualUdiIAC = this.calculos.IAC_primaAnualUDI.value;
          break;
      }
      primaAnualPesosIAC = this.recursos.UDItoMXN(primaAnualUdiIAC);

      if (this.prospecto.mancomunado.mancomunado === true) {
        pdf.setTextColor(255, 0, 0);
        pdf.text(82, this.poscYs, '*');
        pdf.setTextColor(0, 0, 0);
      }

      pdf.text(
        105,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            this.calculos.IAC_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiIAC,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.calculos.IAC_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosIAC,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }

      this.activeCoverages++;
      this.isIacActive = true;
    }
    // PAE
    if (this.calculos.PAE_sumaAseguradaUDI.value > 0) {
      this.poscYs =
        (this.isBiActive || this.isPaiActive || this.isDipocActive) &&
          (!this.isDiActive && !this.isDipoActive && !this.isIacActive)
          ? this.poscYs + 8
          : this.poscYs + 4;

      pdf.text(20, this.poscYs, 'Pago adicional por enfermedades graves(PAE)');

      let primaAnualUdiPAE, primaAnualPesosPAE;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
        primaAnualUdiPAE = this.calculos.PAE_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
        primaAnualUdiPAE = this.calculos.PAE_primaAnualUDI_Sugerida2.value;
          break;
        default:
        primaAnualUdiPAE = this.calculos.PAE_primaAnualUDI.value;
          break;
      }
      primaAnualPesosPAE = this.recursos.UDItoMXN(primaAnualUdiPAE);

      if (this.prospecto.mancomunado.mancomunado === true) {
        pdf.setTextColor(255, 0, 0);
        pdf.text(94, this.poscYs, '*');
        pdf.setTextColor(0, 0, 0);
      }

      pdf.text(
        105,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            this.calculos.PAE_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiPAE,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.calculos.PAE_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosPAE,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }

      this.activeCoverages++;
      this.isPaeActive = true;
    }
    // CC
    if (this.calculos.CC_sumaAseguradaUDI.value > 0) {
      this.poscYs =
        (this.isBiActive || this.isPaiActive || this.isDipocActive) &&
          (!this.isDiActive &&
            !this.isDipoActive &&
            !this.isIacActive &&
            !this.isPaeActive)
          ? this.poscYs + 8
          : this.poscYs + 4;

      pdf.text(20, this.poscYs, 'Cobertura Conyugal(CC)');

      let primaAnualUdiCC, primaAnualPesosCC;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
        primaAnualUdiCC = this.calculos.CC_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
        primaAnualUdiCC = this.calculos.CC_primaAnualUDI_Sugerida2.value;
          break;
        default:
        primaAnualUdiCC = this.calculos.CC_primaAnualUDI.value;
          break;
      }
      primaAnualPesosCC = this.recursos.UDItoMXN(primaAnualUdiCC);

      pdf.text(
        105,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            this.calculos.CC_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiCC,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.calculos.CC_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosCC,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }

      this.activeCoverages++;
      this.isCCActive = true;
    }
    // PCD
    if (this.calculos.PCD_sumaAseguradaUDI.value !== 'EXCLUIDO') {
      this.poscYs =
        (this.isBiActive || this.isPaiActive || this.isDipocActive) &&
          (!this.isDiActive &&
            !this.isDipoActive &&
            !this.isIacActive &&
            !this.isPaeActive &&
            !this.isCCActive)
          ? this.poscYs + 8
          : this.poscYs + 4;

      pdf.text(20, this.poscYs, 'Protección continua por desempleo(PCD)');

      let primaAnualUdiPCD, primaAnualPesosPCD;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
        primaAnualUdiPCD = this.calculos.PCD_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
        primaAnualUdiPCD = this.calculos.PCD_primaAnualUDI_Sugerida2.value;
          break;
        default:
        primaAnualUdiPCD = this.calculos.PCD_primaAnualUDI.value;
          break;
      }
      primaAnualPesosPCD = this.recursos.UDItoMXN(primaAnualUdiPCD);

      pdf.text(106, this.poscYs, this.calculos.PCD_sumaAseguradaUDI.value.toString());

      pdf.text(
        160,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiPCD,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs,
          this.calculos.PCD_sumaAseguradaPesos.value.toString()
        );

        pdf.text(
          245,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosPCD,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }

      this.activeCoverages++;
      this.isPcdActive = true;
    }
    // GF
    if (this.calculos.GF_sumaAseguradaUDI.value > 0) {
      this.poscYs =
        (this.isBiActive || this.isPaiActive || this.isDipocActive) &&
          (!this.isDiActive &&
            !this.isDipoActive &&
            !this.isIacActive &&
            !this.isPaeActive &&
            !this.isCCActive &&
            !this.isPcdActive)
          ? this.poscYs + 8
          : this.poscYs + 4;

      pdf.text(20, this.poscYs, 'Gastos Funerarios(GF)');

      let primaAnualUdiGF, primaAnualPesosGF;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
        primaAnualUdiGF = this.calculos.GF_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
        primaAnualUdiGF = this.calculos.GF_primaAnualUDI_Sugerida2.value;
          break;
        default:
        primaAnualUdiGF = this.calculos.GF_primaAnualUDI.value;
          break;
      }
      primaAnualPesosGF = this.recursos.UDItoMXN(primaAnualUdiGF);

      pdf.text(
        105,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            this.calculos.GF_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiGF,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.calculos.GF_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosGF,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }
      this.activeCoverages++;
      this.isGfActive = true;
    }
    //Coberturas Aliados +Kids
    if( this.plan === 'ALIADOSKIDS'){
      //Tutor Básica
      poscY = poscY + 5;
      this.poscYs = poscY + 4;      
      pdf.text( 20, this.poscYs, 'Básica Titular' );

      let primaAnualUdiBaTutor, primaAnualPesosBaTutor;      
      primaAnualUdiBaTutor = this.calculos.TUTOR_primaAnualUDI.value;
      primaAnualPesosBaTutor = this.recursos.UDItoMXN(primaAnualUdiBaTutor);
      pdf.text(
        105,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            this.calculos.TUTOR_sumaAseguradaUDI.value,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      pdf.text(
        160,
        this.poscYs,
        CurrencyPipe.prototype
          .transform(
            primaAnualUdiBaTutor,
            this.moneda,
            this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
            '1.0-0',
            'en-US'
          )
          .toString()
      );

      if (this.moneda === 'UDI') {
        pdf.text(
          190,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.calculos.TUTOR_sumaAseguradaPesos.value,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          245,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              primaAnualPesosBaTutor,
              this.segundaMoneda,
              'symbol-narrow',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
      }
      this.activeCoverages++;
      this.isTutorActive = true;

      //BÁSICA MENOR
      this.poscYs = this.poscYs + 4;
      let primaAnualUdiBA, primaAnualPesosBA;
      let primaAnualUdiVPL, primaAnualPesosVPL;
      let primaAnualUdiCDC, primaAnualPesosCDC;
      switch (this.generadorTabla.ofertaSeleccionada.value) {
        case 1:
          primaAnualUdiBA = this.calculos.BASICA_primaAnualUDI_Sugerida1.value;
          break;
        case 2:
          primaAnualUdiBA = this.calculos.BASICA_primaAnualUDI_Sugerida2.value;
          break;
        default:
          primaAnualUdiBA = this.calculos.BASICA_primaAnualUDI.value;
          break;
      }

      primaAnualPesosBA = this.recursos.UDItoMXN(primaAnualUdiBA);
      primaAnualPesosVPL = this.recursos.UDItoMXN(this.calculos.VPL_primaAnual_PrimeraMoneda.value);
      primaAnualPesosCDC = this.recursos.UDItoMXN(this.calculos.CDC_primaAnual_PrimeraMoneda.value);

      primaAnualUdiVPL = this.recursos.UDItoMXN(this.calculos.VPL_sumaAsegurada_PrimeraMoneda.value);
      primaAnualUdiCDC = this.recursos.UDItoMXN(this.calculos.CDC_sumaAsegurada_PrimeraMoneda.value);

      this.valoresCoberturasDefault = [];

      this.setCoveragesListDefault(primaAnualUdiBA, primaAnualPesosBA, primaAnualUdiVPL,
        primaAnualPesosVPL, primaAnualUdiCDC, primaAnualPesosCDC);

      for (let j = 0; j < this.valoresCoberturasDefault.length; j++) {
        if (j > 0) {
          this.poscYs = this.poscYs + Number(4);
        }
        pdf.text(20, this.poscYs, 'Básica Menor');
        pdf.text(
          105,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.valoresCoberturasDefault[j].sumaAseguradaUdi,
              this.moneda,
              this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        pdf.text(
          160,
          this.poscYs,
          CurrencyPipe.prototype
            .transform(
              this.valoresCoberturasDefault[j].primaUdi,
              this.moneda,
              this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
              '1.0-0',
              'en-US'
            )
            .toString()
        );

        if (this.moneda === 'UDI') {
          pdf.text(
            190,
            this.poscYs,
            CurrencyPipe.prototype
              .transform(
                this.valoresCoberturasDefault[j].sumaAseguradaMxn,
                this.segundaMoneda,
                'symbol-narrow',
                '1.0-0',
                'en-US'
              )
              .toString()
          );

          pdf.text(
            245,
            this.poscYs,
            CurrencyPipe.prototype
              .transform(
                this.valoresCoberturasDefault[j].primaMxn,
                this.segundaMoneda,
                'symbol-narrow',
                '1.0-0',
                'en-US'
              )
              .toString()
          );
        }
      }
      this.activeCoverages++;
      this.isBasicaActive = true;

      //EFI
      if (this.calculos.EFI_sumaAseguradaUDI.value !== 'EXCLUIDO') {
        this.poscYs = this.poscYs + Number(4);
  
        pdf.text(
          20,
          this.poscYs,
          'Exención de pago de primas por\nfallecimiento o invalidez (EFI)'
        );  

        pdf.setTextColor(255, 0, 0);
        pdf.text(67, this.poscYs + 4, '*');
        pdf.setTextColor(0, 0, 0);

        let primaAnualUdiEFI, primaAnualPesosEFI;        
        primaAnualUdiEFI = this.calculos.EFI_primaAnualUDI.value;
        primaAnualPesosEFI = this.recursos.UDItoMXN(primaAnualUdiEFI);
        pdf.text(106, this.poscYs + 2, this.calculos.EFI_sumaAseguradaUDI.value);
  
        pdf.text(
          160,
          this.poscYs + 2,
          CurrencyPipe.prototype
            .transform(
              primaAnualUdiEFI,
              this.moneda,
              this.moneda !== 'UDI' ? 'symbol-narrow' : ' ',
              '1.0-0',
              'en-US'
            )
            .toString()
        );
  
        if (this.moneda === 'UDI') {
          pdf.text(190, this.poscYs + 2, this.calculos.EFI_sumaAseguradaPesos.value);
  
          pdf.text(
            245,
            this.poscYs + 2,
            CurrencyPipe.prototype
              .transform(
                primaAnualPesosEFI,
                this.segundaMoneda,
                'symbol-narrow',
                '1.0-0',
                'en-US'
              )
              .toString()
          );
        }
        this.poscYs = this.poscYs + 8;
        pdf.setTextColor(255, 0, 0);
        pdf.setFontStyle('bold');
        pdf.text(
          20,
          this.poscYs,
          '* No aplica para ' + this.prospecto.kids.nameKids);
        pdf.setFontStyle('normal');
        pdf.setTextColor(0, 0, 0);  
        this.activeCoverages++;
        this.isEFIActive = true;
      }
    }   
  return pdf;
  }

  /**
   * Método que genera el PDF de valores Garantizados
   * @memberof PdfValuesComponent
   */
  async generatePDF(folio: string) {
    // Inicialización de variables
    this.page = 1;
    this.strFolio = folio;

    this.prospectu.prospectoActual.subscribe(value => {
      this.prospecto = value;
    });
    this.valoresG.tablavalores.asObservable().subscribe(data => {
      this.resultados = data;
    });
    this.calculos.primaTotalFraccionadaDividida_Actual.subscribe(data => {
      this.primaTotalUDI = data;
    });

    this.calculos.primaAnual_ConRecargo_Actual.subscribe(data => {
      this.sumaAseguradaUDI = data;
    });

    this.excedentes.inflacion_udi.subscribe(data => {
      this.inflacion_anual = data;
    });

    this.excedentes.crecimiento_anual.subscribe(data => {
      this.tasa_aportacion = data;
    });

    this.excedentes.aportacion_anual_UDI.subscribe(aportacion => {
      this.aporte = aportacion;
    });
    const planEleg = this.generadorTabla.paramsElegido.value;
    this.plan = planEleg !== null ? planEleg.tipoPlan : this.opciones.tipoPlan;
    this.product = this.plan === 'DOTAL' ? 'Aliados+ ' : 'ProTGT ';
    this.moneda = this.opciones.moneda;
    this.segundaMoneda = this.opciones.segundaMoneda;
    const pPago = this.generadorTabla.paramsElegido.value;
    this.plazoPagos = pPago !== null ? pPago.plazoPago : Number(this.opciones.plazoPago.value);
    this.valorUDI = this.recursos.valor_UDI;
    this.primaTotalPeso =
      this.opciones.moneda === 'UDI'
        ? this.recursos.UDItoMXN(this.primaTotalUDI)
        : this.primaTotalUDI;
    this.primaAnualPeso =
      this.opciones.moneda === 'UDI'
        ? this.recursos.UDItoMXN(this.sumaAseguradaUDI)
        : this.sumaAseguradaUDI;
    this.version = this.getVersion();
    this.isDotal = this.opciones.tipoPlan === 'DOTAL';
    this.mancomunado = this.prospecto.mancomunado.mancomunado;
    this.moneda1 = CurrencyToTextPipe.prototype.transform(this.moneda);
    this.moneda2 = CurrencyToTextPipe.prototype.transform(this.segundaMoneda);

    // Inicia PDF

    let pdf = new jsPDF('l', 'mm', 'letter');

    let valGaran: Array<ValoresGarantizadosTabla[]> = this.generadorTabla
      .vistaVg;

    valGaran = valGaran.filter(el => el !== undefined);

    valGaran.forEach(el => {
      el.forEach((e, i) => {
        if (e.visible !== this.generadorTabla.colsTest[i].visible) {
          e.visible = this.generadorTabla.colsTest[i].visible;
        }
      });
    });

    switch (true) {
      case valGaran.length <= 26:
        this.totalPages = 2;
        break;
      case valGaran.length > 26 && valGaran.length <= 52:
        this.totalPages = 3;
        break;
      case valGaran.length > 52 && valGaran.length <= 78:
        this.totalPages = 4;
        break;
      case valGaran.length > 78:
        this.totalPages = 5;
        break;
    }

    // Header y datos de Agente

    try {
      const iconoImg = new Image();

        iconoImg.src = 'assets/icons/icon-96x96.png';

      pdf.addImage(iconoImg, 'PNG', 5, 3, 20, 20);
    } catch (error) { }

    pdf.setFontSize(12);

    pdf.setFontStyle('bold');

    if( this.opciones.tipoPlan !== 'ALIADOSKIDS'){
      pdf.text(5, 30, 'Vida ' + this.product + new Date().getFullYear());
    } else{
      pdf.text(5, 30, 'Vida Aliados+ Kids ' + new Date().getFullYear());
    }
    

    try {
      const lineredImg = new Image();

        lineredImg.src = 'assets/images/linered.png';

      pdf.addImage(lineredImg, 'PNG', 120, 0, 36, 33);
    } catch (error) { }

    pdf.setFontSize(9);

    pdf.text(190, 5, this.date);

    pdf.setFontStyle('normal');

    pdf.text(190, 9, 'FOLIO:');

    pdf.text(209, 9, this.strFolio);

    pdf.setTextColor(255, 0, 0);

    pdf.setFontSize(6);

    pdf.text(226, 5, 'v' + this.version);

    pdf.setTextColor(0, 0, 0);

    pdf.setFontStyle('normal');

    pdf.setFontSize(9);

    pdf.text(190, 13, 'ROL:');

    pdf.text(209, 13, this.user.type === null ? '' : this.user.type);

    pdf.text(190, 17, 'AGENTE:');

    pdf.text(209, 17, this.user.name === null ? '' : this.user.name);

    pdf.text(190, 21, 'MX:');

    pdf.text(209, 21, this.user.code === null ? '' : this.user.code);

    pdf.text(190, 25, 'EMAIL:');

    pdf.text(209, 25, this.user.email === null ? '' : this.user.email);

    pdf.text(190, 29, 'CONEXIÓN:');

    pdf.text(209, 29, this.connect._state.value ? 'ONLINE' : 'OFFLINE');

    pdf.setDrawColor(142, 169, 219);

    pdf.setLineWidth(1.5);

    pdf.line(5, 33, 275, 33);

    // IMG Valores
    try {
      const gValuesImg = new Image();
      this.bannerBlobURL;
        gValuesImg.src = 'assets/images/g-values_icon.jpg';

      pdf.addImage(gValuesImg, 'JPG', 5, 34, 270, 30);
    } catch (error) { }

    pdf.setFontSize(14);

    pdf.setFontStyle('bold');

    pdf.text(15, 52, 'VALORES GARANTIZADOS');

    // Datos del Prospecto

    pdf.setFontSize(10);

    if (this.prospecto.mancomunado.mancomunado === true) {
      pdf.text(15, 69, 'Plan Mancomunado');

      pdf.text(97, 69, 'Titular');

      pdf.text(170, 69, 'Mancomunado');

      pdf.text(75, 74, 'Nombre:');

      pdf.setFontStyle('normal');

      pdf.text(118, 74, this.prospecto.name, this.resultados);

      pdf.setFontStyle('bold');

      pdf.text(75, 78, 'Edad:');

      pdf.setFontStyle('normal');

      pdf.text(118, 78, this.prospecto.age.toString() + ' Años');

      pdf.setFontStyle('bold');

      pdf.text(75, 82, 'Género:');

      pdf.setFontStyle('normal');

      pdf.text(118, 82, this.prospecto.gender === 'H' ? 'Hombre' : 'Mujer');

      pdf.setFontStyle('bold');

      pdf.text(75, 86, 'Fumador:');

      pdf.setFontStyle('normal');

      pdf.text(118, 86, this.prospecto.fumador);

      // PROSPECTO MANCOMUNADO

      pdf.setFontStyle('bold');

      pdf.text(155, 74, 'Nombre:');

      pdf.setFontStyle('normal');

      pdf.text(193, 74, this.prospecto.mancomunado.nameMancomunado);

      pdf.setFontStyle('bold');

      pdf.text(155, 78, 'Edad:');

      pdf.setFontStyle('normal');

      pdf.text(193, 78, this.prospecto.mancomunado.ageMancomunado + ' Años');

      pdf.setFontStyle('bold');

      pdf.text(155, 82, 'Género:');

      pdf.setFontStyle('normal');

      pdf.text(
        193,
        82,
        this.prospecto.mancomunado.genderMancomunado === 'H'
          ? 'Hombre'
          : 'Mujer'
      );

      pdf.setFontStyle('bold');

      pdf.text(155, 86, 'Fumador:');

      pdf.setFontStyle('normal');

      pdf.text(193, 86, this.prospecto.mancomunado.fumadorMancomunado);
    } else if( this.opciones.tipoPlan === 'ALIADOSKIDS'){
        pdf.text(15, 69, 'Datos de los prospectos');
  
        pdf.text(97, 69, 'Titular');
  
        pdf.text(170, 69, 'Menor');
  
        pdf.text(75, 74, 'Nombre:');
  
        pdf.setFontStyle('normal');
  
        pdf.text(118, 74, this.prospecto.name, this.resultados);
  
        pdf.setFontStyle('bold');
  
        pdf.text(75, 78, 'Edad:');
  
        pdf.setFontStyle('normal');
  
        pdf.text(118, 78, this.prospecto.age.toString() + ' Años');
  
        pdf.setFontStyle('bold');
  
        pdf.text(75, 82, 'Género:');
  
        pdf.setFontStyle('normal');
  
        pdf.text(118, 82, this.prospecto.gender === 'H' ? 'Hombre' : 'Mujer');
  
        pdf.setFontStyle('bold');
  
        pdf.text(75, 86, 'Fumador:');
  
        pdf.setFontStyle('normal');
  
        pdf.text(118, 86, this.prospecto.fumador);
  
        // PROSPECTO MENOR
  
        pdf.setFontStyle('bold');
  
        pdf.text(155, 74, 'Nombre:');
  
        pdf.setFontStyle('normal');
  
        pdf.text(193, 74, this.prospecto.kids.nameKids );
  
        pdf.setFontStyle('bold');
  
        pdf.text(155, 78, 'Edad:');
  
        pdf.setFontStyle('normal');
  
        pdf.text(193, 78, this.prospecto.kids.ageKids + ' Años');
  
        pdf.setFontStyle('bold');
  
        pdf.text(155, 82, 'Género:');
  
        pdf.setFontStyle('normal');
  
        pdf.text(
          193,
          82,
          this.prospecto.kids.genderKids === 'H'
            ? 'Hombre'
            : 'Mujer'
        );
    } else {
      pdf.setFontStyle('bold');

      pdf.text(15, 69, 'Datos del Prospecto');

      pdf.text(75, 74, 'Nombre:');

      pdf.setFontStyle('normal');

      pdf.text(118, 74, this.prospecto.name);

      pdf.setFontStyle('bold');

      pdf.text(75, 78, 'Edad:');

      pdf.setFontStyle('normal');

      pdf.text(118, 78, this.prospecto.age.toString() + ' Años');

      pdf.setFontStyle('bold');

      pdf.text(75, 82, 'Género:');

      pdf.setFontStyle('normal');

      pdf.text(118, 82, this.prospecto.gender === 'H' ? 'Hombre' : 'Mujer');

      pdf.setFontStyle('bold');

      pdf.text(75, 86, 'Fumador:');

      pdf.setFontStyle('normal');

      pdf.text(118, 86, this.prospecto.fumador);
    }

    pdf.line(5, 64, 275, 64);

    // Características del Plan
    pdf.setFontStyle('bold');

    pdf.text(15, 95, 'Características del Plan');

    pdf.text(75, 100, 'Plan:');

    pdf.setFontStyle('normal');
    if( this.plan !== 'ALIADOSKIDS'){
      pdf.text(118, 100, this.plan === 'DOTAL' ? 'Aliados + ' + this.retiro : this.plan);
    } else {
      pdf.text(118, 100, 'Aliados+ Kids');
    }
    


    pdf.setFontStyle('bold');

    pdf.text(75, 104, 'Moneda:');

    pdf.setFontStyle('normal');

    pdf.text(118, 104, CurrencyToTextPipe.prototype.transform(this.moneda));

    pdf.setFontStyle('bold');

    pdf.text(75, 108, 'Plazo de pagos:');

    pdf.setFontStyle('normal');

    if( this.plan !== 'ALIADOSKIDS'){
      pdf.text(
        118,
        108,
        this.plazoPagos >= 60 && this.plazoPagos < 99
          ? this.plazoPagos + ' Edad Alcanzada'
          : this.plazoPagos + ' Años'
      );
    } else {
      pdf.text(118, 108, this.plazoPagos + ' Edad Alcanzada');
    }

    pdf.setFontStyle('bold');

    pdf.text(
      75,
      112,
      'Prima anual ' + CurrencyToTextPipe.prototype.transform(this.moneda) + ':'
    );

    pdf.setFontStyle('normal');

    let splitPayment: PaymentType = 'yearly';
    switch (this.paymentFrequency.frecuenciaPago.value) {
      case 'ANUAL':
        splitPayment = 'yearly';
        break;

      case 'SEMESTRAL':
        splitPayment = 'semi-annually';
        break;

      case 'TRIMESTRAL':
        splitPayment = 'quarterly';
        break;

      case 'MENSUAL':
        splitPayment = 'monthly';
        break;
    }

    const divisor = SPLIT_PAYMENT.filter(i => i.type === splitPayment)[0]
      .divider;
    let sumasPrimas;
    switch (this.generadorTabla.ofertaSeleccionada.value) {
      case 1:
        sumasPrimas = this.calculos.sumaPrimas_Sugerida1.value;
        break;
      case 2:
        sumasPrimas = this.calculos.sumaPrimas_Sugerida2.value;
        break;
      default:
        sumasPrimas = this.calculos.sumaPrimas.value;
        break;
    }
    pdf.text(
      117,
      112,
      CurrencyPipe.prototype.transform(
        sumasPrimas,
        this.moneda,
        this.moneda === 'UDI' ? ' ' : 'symbol-narrow',
        '1.0-0',
        'en-US'
      )
    );

    this.poscY = 116;

    if (this.excedentes.aportacion_anual_UDI.value !== 0) {
      pdf.setFontStyle('bold');

      pdf.text(
        75,
        this.poscY,
        'Aportación ' +
        CurrencyToTextPipe.prototype.transform(this.moneda) +
        ':'
      );

      pdf.setFontStyle('normal');

      pdf.text(
        117,
        this.poscY,
        CurrencyPipe.prototype.transform(
          this.excedentes.aportacion_anual_UDI.value,
          this.moneda,
          this.moneda === 'UDI' ? ' ' : 'symbol-narrow',
          '1.0-0',
          'en-US'
        )
      );

      this.poscY = 120;
    } else {
      this.poscY = 116;
    }

    pdf.setFontStyle('bold');

    pdf.text(
      75,
      this.poscY,
      'Prima ' +
      (this.calculos.paymentFrecuency.toLocaleLowerCase() === 'anual'
        ? 'total'
        : this.calculos.paymentFrecuency.toLocaleLowerCase()) +
      ' ' +
      CurrencyToTextPipe.prototype.transform(this.moneda) +
      ':'
    );

    pdf.setFontStyle('normal');

    pdf.text(
      117,
      this.poscY,
      CurrencyPipe.prototype.transform(
        this.calculos.primaTotalFraccionadaDividida_Actual.value +
        this.aporte / divisor,
        this.moneda,
        this.moneda === 'UDI' ? ' ' : 'symbol-narrow',
        '1.0-0',
        'en-US'
      )
    );

    if (this.moneda === 'UDI') {
      pdf.setFontStyle('bold');

      pdf.text(75, this.poscY + 4, 'Valor UDI:');

      pdf.setFontStyle('normal');

      pdf.text(
        117,
        this.poscY + 4,
        CurrencyPipe.prototype.transform(
          this.valorUDI,
          this.moneda,
          ' ',
          '1.4-4',
          'en-US'
        )
      );

      pdf.setFontStyle('bold');

      pdf.text(
        75,
        this.poscY + 12,
        'Prima anual ' +
        CurrencyToTextPipe.prototype.transform(this.segundaMoneda) +
        ':'
      );

      pdf.setFontStyle('normal');

      pdf.text(
        117,
        this.poscY + 12,
        CurrencyPipe.prototype.transform(
          this.recursos.FirstToSecond(
            this.calculos.sumaPrimas.value
          ),
          this.segundaMoneda,
          'symbol-narrow',
          '1.0-0',
          'en-US'
        )
      );

      if (this.excedentes.aportacion_anual_UDI.value !== 0) {
        pdf.setFontStyle('bold');

        pdf.text(
          75,
          this.poscY + 16,
          'Aportación' +
          (this.moneda === 'UDI'
            ? ' ' +
            CurrencyToTextPipe.prototype.transform(this.segundaMoneda) +
            ':'
            : ':')
        );

        pdf.setFontStyle('normal');

        pdf.text(
          117,
          this.poscY + 16,
          CurrencyPipe.prototype.transform(
            this.recursos.FirstToSecond(this.aporte),
            this.segundaMoneda,
            'symbol-narrow',
            '1.0-0',
            'en-US'
          )
        );

        this.poscY = this.poscY + 20;
      } else {
        this.poscY = this.poscY + 16;
      }

      pdf.setFontStyle('bold');

      pdf.text(
        75,
        this.poscY,
        'Prima ' +
        (this.calculos.paymentFrecuency.toLocaleLowerCase() === 'anual'
          ? 'total'
          : this.calculos.paymentFrecuency.toLocaleLowerCase()) +
        ' ' +
        CurrencyToTextPipe.prototype.transform(this.segundaMoneda) +
        ':'
      );

      pdf.setFontStyle('normal');

      pdf.text(
        117,
        this.poscY,
        CurrencyPipe.prototype.transform(
          this.recursos.FirstToSecond(
            this.calculos.primaTotalFraccionadaDividida_Actual.value +
            this.aporte / divisor
          ),
          this.segundaMoneda,
          'symbol-narrow',
          '1.0-0',
          'en-US'
        )
      );

      pdf.setFontStyle('bold');

      pdf.text(75, this.poscY + 4, 'Inflación Anual:');

      pdf.setFontStyle('normal');

      pdf.text(
        117,
        this.poscY + 4,
        Number(this.inflacion_anual) === 0.035 ? (this.inflacion_anual * 100).toFixed(1).toString() + '%'
        : (this.inflacion_anual * 100).toString() + '%'
      );
    }
    if (this.moneda === 'MXN' && this.opciones.incrementosProgramados.value) {
      pdf.setFontStyle('bold');

      pdf.text(75, this.poscY + 4, 'INPC:');

      pdf.setFontStyle('normal');

      pdf.text(117, this.poscY + 4, `${(this.calculos.inflacion_inpc.value * 100).toPrecision(2)} %`);
    }

    if (this.excedentes.aportacion_anual_UDI.value !== 0) {
      pdf.setFontStyle('bold');

      pdf.text(
        50,
        this.poscY + 9,
        'La tasa de rendimiento para las aportaciones adicionales es del ' + (this.tasa_aportacion * 100).toFixed(2) +
        '% anual, esta tasa no es garantizada.');


      pdf.setFontStyle('normal');

      this.poscY = this.poscY + 13;
    } else {
      if (this.moneda === 'MXN' && this.opciones.incrementosProgramados.value) {
        pdf.setFontStyle('bold');

        pdf.text(75, this.poscY + 4, 'INPC:');

        pdf.setFontStyle('normal');

        pdf.text(117, this.poscY + 4, `${(this.calculos.inflacion_inpc.value * 100).toPrecision(2)} %`);
      }

      this.poscY = this.poscY + 8;
    }
    pdf.line(5, 90, 275, 90);

    // Detalle de Primas Coberturas

    pdf.setFontStyle('bold');

    pdf.text(15, this.poscY + 4, 'Detalle de primas');

    pdf.text(20, this.poscY + 10, 'COBERTURA');

    pdf.text(
      105,
      this.poscY + 10,
      'SUMA ASEGURADA ' +
      (this.moneda === 'UDI'
        ? CurrencyToTextPipe.prototype.transform(this.moneda.toUpperCase())
        : ' ')
    );

    pdf.text(
      160,
      this.poscY + 10,
      'PRIMA ' +
      (this.moneda === 'UDI'
        ? CurrencyToTextPipe.prototype.transform(this.moneda.toUpperCase())
        : ' ')
    );

    if (this.moneda === 'UDI') {
      pdf.text(
        190,
        this.poscY + 10,
        'SUMA ASEGURADA ' +
        CurrencyToTextPipe.prototype.transform(
          this.segundaMoneda.toUpperCase()
        )
      );

      pdf.text(
        245,
        this.poscY + 10,
        'PRIMA ' + CurrencyToTextPipe.prototype.transform(this.segundaMoneda)
      );
    }

    pdf.setFontStyle('normal');

    this.poscY = this.poscY + 10;

    // cobertura
    pdf = await this.getActiveCoverages(pdf, this.poscY);

    const pageSize = pdf.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    pdf.setFontSize(7);
    pdf.setFontStyle('bold');
    pdf.text('Página 1', 260, pageHeight - 4);
    pdf.setFontStyle('normal');
    // Termina Coberturas
    this.poscY = this.poscY - 11;

    pdf.line(5, this.poscY, 275, this.poscY);

    pdf.addPage();

    this.page = this.page + 1;

    this.poscY = 5;

    // Inicia HEADER Tabla VG
    pdf = this.pdfTabla(pdf);

    if ((this.deviceservice.getDeviceType() === 'iPad' || this.deviceservice.getDeviceType() === 'iPhone') && this.deviceservice.isSafari()) {
      const blob = URL.createObjectURL(pdf.output('blob'));
      window.open(blob);
    } else {
      pdf.save('valoresGarantizados.pdf');
    }
  }

  /**
   * Agrega header en cada hoja que contiene tabla
   * @method
   * @private
   * @param {*} pdf
   * @memberof PdfValuesService
   */
  private async addHeader(pdf: any): Promise<void> {
    try {
      const newImg = new Image();

        newImg.src = 'assets/images/g-values_icon.jpg';

      pdf.addImage(newImg, 'JPG', 5, 10, 270, 30);
    } catch (error) { }

    pdf.setDrawColor(142, 169, 219);

    pdf.setLineWidth(1.5);

    pdf.line(5, 10, 275, 10);

    pdf.line(5, 40, 275, 40);

    pdf.setFontStyle('bold');

    pdf.setFontSize(14);

    pdf.text(15, 27, 'VALORES GARANTIZADOS');

  }

  /**
   * Agrega tabla
   * @method
   * @private
   * @param {*} pdf
   * @returns {*}
   * @memberof PdfValuesService
   */
  private pdfTabla(pdf: any): any {
    const bodyTable = this.generadorTabla.vistaVg.map(
      vista => vista.filter(row => row.visible)
    );

    pdf.autoTable({
      tableWidth: 270,
      margin: { left: 5, right: 0, top: 44 },
      styles: { halign: 'center', lineColor: [255, 255, 255], lineWidth: 1, fontSize: 8 },
      headStyles: { fontSize: 7, fillColor: [48, 84, 150] },
      head: [
        this.generadorTabla.vistaHeader.map(i => {
          switch (i) {
            case 'edad':
              return this.mancomunado === true ? 'Edad\nequivalente' : 'Edad';
            case 'anioPoliza':
              return 'Año';
            case 'primaElegida':
              return 'Prima\nAnual\nUDI';
            case 'aportacionAdicional_UDI':
              return 'Aportación +\nRendimiento\nUDI';
            case 'aportacionAdicional':
              return `Aportación\nAdicional\n${this.moneda1}`;
            case 'prima_AcumuladaUDI':
              return 'Prima\nAcumulada\nUDI';
            case 'primaDeducibleUDI':
              return 'Prima\nDeducibleUDI';
            case 'primaNoDeducibleUDI':
              return 'Prima\nNo\nDeducible\nUDI';
            case 'valorRescate':
              return 'Valor de\nRescate\nUDI';
            case 'porcentajeRecuperacionUdi':
              this.projectionBA = true;
              return 'Porcentaje de\nRecuperación\nP.B.\nUDI';
            case 'sumaAsegurada':
              return 'Suma\nAsegurada\nUDI';
            case 'valorUDI':
              return 'Valor de la\nUDI';
            case 'primaElegida_Pesos':
              return `Prima\nAnual\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'aportacionAdicionalProyectada':
              return 'Aportación\nAdicional\nPesos';
            case 'aportacionAdicional_MXN':
              return `Aportación +\nRendimiento\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'prima_AcumuladaMXN':
              return `Prima\nAcumulada\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'primaDeducibleMXN':
              return `Prima\nDeducible\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'primaNoDeducibleMXN':
              return `Prima\nNo Deducible\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'valorRescate_Pesos':
              return `Valor de\nRescate\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'porcentajeRecuperacionPesos':
              this.projectionBA = true;
              return `Porcentaje de\nRecuperación\nP.B.\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'sumaAsegurada_Pesos':
              return `Suma\nAsegurada\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'valorEnEfectivo_UDI':
              return `Valor En\nEfectivo\nUDI`;
            case 'valorEnEfectivo_Pesos':
              return `Valor En\nEfectivo\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'porcentajeRecuperacionUdiBaCo':
              this.projectionBACO = true;
              return '%\nRecuperacion\nP.B.\n+\nC.A.\nUDI';
            case 'porcentajeRecuperacionPesosBaCo':
              this.projectionBACO = true;
              return `% Recuperacion\nP.B.\n+\nC.A.\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'porcentajeRecuperacionUdiBaCoExcd':
              this.projectionBACOAA = true;
              return `%\nRecuperacion\nP.B.\n+\nC.A.\n+\nA.A.\nUDI`;
            case 'porcentajeRecuperacionPesosBaCoExcd':
              this.projectionBACOAA = true;
              return `% Recuperacion\nP.B.\n+\nC.A.\n+\nA.A.\n${
                this.moneda1 === 'UDI' ? this.moneda2 : this.moneda1
                }`;
            case 'prima1':
              return `Prima 1\nSuma ${this.calculos.BASICA_sumaAseguradaMasiva_1.value}`;
            case 'prima2':
              return `Prima 2\nSuma ${this.calculos.BASICA_sumaAseguradaMasiva_2.value}`;
            case 'prima3':
              return `Prima 3\nSuma ${this.calculos.BASICA_sumaAseguradaMasiva_3.value}`;
            default:
              return '';
          }
        })
      ],
      body: bodyTable.map(vista =>
        vista.map((row, i) => {
          switch (row.field) {
            case 'edad':
              return String(vista[i].value);
            case 'anioPoliza':
              return String(vista[i].value);
            case 'primaElegida':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.moneda,
                    ' ',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'aportacionAdicional':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype.transform(vista[i].value, this.moneda, ' ', '1.0-0', 'en-US').toString() : '-';
            case 'aportacionAdicional_UDI':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.moneda,
                    ' ',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'prima_AcumuladaUDI':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.moneda,
                    ' ',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'primaDeducibleUDI':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.moneda,
                    ' ',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'primaNoDeducibleUDI':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.moneda,
                    ' ',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'valorRescate':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype.transform(
                  vista[i].value,
                  ' ',
                  ' ',
                  '1.0-0',
                  'en-US'
                )
                : '-';
            case 'porcentajeRecuperacionUdi':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'sumaAsegurada':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    ' ',
                    ' ',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'valorUDI':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.4-4',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'primaElegida_Pesos':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'aportacionAdicionalProyectada':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype.transform(vista[i].value, this.moneda, ' ', '1.0-0', 'en-US').toString() : '-';
            case 'aportacionAdicional_MXN':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'prima_AcumuladaMXN':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'primaDeducibleMXN':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'primaNoDeducibleMXN':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'valorRescate_Pesos':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'porcentajeRecuperacionPesos':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'sumaAsegurada_Pesos':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'valorEnEfectivo_UDI':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    ' ',
                    ' ',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'valorEnEfectivo_Pesos':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  )
                  .toString()
                : '-';
            case 'valorEnEfectivo_Pesos':
              return Number(vista[i].value) !== 0
                ? CurrencyPipe.prototype
                  .transform(
                    vista[i].value,
                    this.segundaMoneda,
                    'symbol-narrow',
                    '1.0-0',
                    'en-US'
                  ).toString()
                : '-';
            case 'porcentajeRecuperacionUdiBaCo':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'porcentajeRecuperacionPesosBaCo':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'porcentajeRecuperacionUdiBaCoExcd':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'porcentajeRecuperacionPesosBaCoExcd':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'prima1':
              return vista[i].value;
            case 'prima2':
              return vista[i].value;
            case 'prima3':
              return vista[i].value;
            case 'porcentajeRecuperacionUdiBaCo':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'porcentajeRecuperacionPesosBaCo':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'porcentajeRecuperacionUdiBaCoExcd':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            case 'porcentajeRecuperacionPesosBaCoExcd':
              return Number(vista[i].value) !== 0
                ? Number(vista[i].value)
                  .toFixed(0)
                  .toString() + ' %'
                : '- %';
            default:
              return '';
          }
        })
      ),
      didDrawPage: async data => {
        this.printEsquema(pdf);
        this.printPageNumber(pdf);
        await this.addHeader(pdf);
      },
      willDrawCell: data => {
        if (data.section === 'body') {
          (data.row.index + 1) % 2 === 0
            ? pdf.setFillColor(255, 255, 255)
            : pdf.setFillColor(189, 215, 238);
        }
      }
    });

    this.printCotizacionValidation(pdf);

    let posy = pdf.autoTableEndPosY();
    pdf.setFontSize(8);
    pdf.setTextColor(255, 0, 0);
    if (this.projectionBA) {
      posy = posy + 2;
      pdf.text(5, posy, '•	% Recuperación P.B: Porcentaje de recuperación tomando en cuenta solo la prima básica.');
    }
    if (this.projectionBACO) {
      posy = posy + 2;
      pdf.text(5, posy, '•	% Recuperación P.B + C.A: Porcentaje de recuperación tomando en cuenta la prima básica + coberturas adicionales.');
    }
    if (this.projectionBACOAA) {
      posy = posy + 2;
      pdf.text(5, posy, '•	% Recuperación P.B + C.A + A.A: Porcentaje de recuperación tomando en cuenta ' +
        'la prima básica + coberturas adicionales + aportación adicional.');
    }
    this.projectionBA = false;
    this.projectionBACO = false;
    this.projectionBACOAA = false;
    return pdf;
  }

  /**
   * Escribe el número de la página actual dada por la tabla
   * @method
   * @private
   * @param {*} pdf
   * @memberof PdfValuesService
   */
  private printPageNumber(pdf: any): void {
    const str = `Página ${pdf.internal.getNumberOfPages()}`;
    const pageSize = pdf.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    pdf.setFontSize(8);
    pdf.setFontStyle('bold');

    pdf.text(str, 260, pageHeight - 4);
  }

  /**
   * Escribe leyenda en la última hoja del pdf
   * @method
   * @private
   * @param {*} pdf
   * @memberof PdfValuesService
   */
  private printCotizacionValidation(pdf: any): void {
    const pageSize = pdf.internal.pageSize;
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    pdf.setFontSize(6.5);

    pdf.text(
      20,
      pageHeight - 9,
      'Esta cotización tiene una validez de 15 días naturales contados a partir de la fecha de su elaboración, por lo que no garantiza la emisión del seguro.'
    );

    pdf.text(
      170,
      pageHeight - 9,
      ' La misma es ilustrativa y de apoyo a la fuerza de ventas. No forma parte del contrato del seguro.'
    );

    pdf.text(
      80,
      pageHeight - 6,
      'AXA Seguros se reserva del derecho de solicitar la respuesta a un cuestionario médico y de ocupación, así como estudios de laboratorio.'
    );
  }

  /**
   * Agrega la información del esquema
   *
   * @private
   * @param {*} pdf
   * @memberof PdfValuesService
   */
  private printEsquema(pdf: any): void {
    pdf.setFontStyle('bold');
    pdf.setFontSize(10);
    pdf.text(250, 44, this.excedentes.esquema_comision.value);
  }

  /**
   * Agrega las coberturas básica, VPL y CDC a la lista de coberturas incluidas
   * @method
   * @param primaAnualUdiBA
   * @param primaAnualPesosBA
   * @param primaAnualUdiVPL
   * @param primaAnualPesosVPL
   * @param primaAnualUdiCDC
   * @param primaAnualPesosCDC
   */
  async setCoveragesListDefault(primaAnualUdiBA: String, primaAnualPesosBA: String,
    primaAnualUdiVPL: String, primaAnualPesosVPL: String, primaAnualUdiCDC: String, primaAnualPesosCDC: String) {
    try {
      if (this.opciones.tipoPlan.toString() === 'MUJER UNICA') {
        this.valoresCoberturasDefault.push({
          nombreCobertura: 'Temporal',
          sumaAseguradaUdi: String(this.calculos.BASICA_sumaAseguradaUDI.value),
          sumaAseguradaUsd: '',
          sumaAseguradaMxn: String(this.calculos.BASICA_sumaAseguradaPesos.value),
          primaUdi: String(primaAnualUdiBA),
          primaUsd: '',
          primaMxn: String(primaAnualPesosBA),
          visible: true
        });
        this.valoresCoberturasDefault.push({
          nombreCobertura: 'Vida Pagos Limitados',
          sumaAseguradaUdi: String(this.calculos.VPL_sumaAsegurada_PrimeraMoneda.value),
          sumaAseguradaUsd: '',
          sumaAseguradaMxn: String(primaAnualUdiVPL),
          primaUdi: String(this.calculos.VPL_primaAnual_PrimeraMoneda.value),
          primaUsd: '',
          primaMxn: String(primaAnualPesosVPL),
          visible: true
        });
        this.valoresCoberturasDefault.push({
          nombreCobertura: 'Cobertura de Cáncer (CDC)',
          sumaAseguradaUdi: String(this.calculos.CDC_sumaAsegurada_PrimeraMoneda.value),
          sumaAseguradaUsd: '',
          sumaAseguradaMxn: String(primaAnualUdiCDC),
          primaUdi: String(this.calculos.CDC_primaAnual_PrimeraMoneda.value),
          primaUsd: '',
          primaMxn: String(primaAnualPesosCDC),
          visible: true
        });
      } else {
        this.valoresCoberturasDefault.push({
          nombreCobertura: 'BÁSICA',
          sumaAseguradaUdi: String(this.calculos.BASICA_sumaAseguradaUDI.value),
          sumaAseguradaUsd: '',
          sumaAseguradaMxn: String(this.calculos.BASICA_sumaAseguradaPesos.value),
          primaUdi: String(primaAnualUdiBA),
          primaUsd: '',
          primaMxn: String(primaAnualPesosBA),
          visible: true
        });
      }
    } catch (error) { }
  }
}
