/**
 * Inflaciones anual de UDI
 */
export const ANUAL_INFLATIONS: { title: string, value: number }[] = [
  { title: '0% Ninguna', value: 0 },
  { title: '3.5% Baja', value: .035 },
  { title: '4% Media', value: .04 },
  { title: '4.5% Alta', value: .045 },
];
/**
 * Inflación anual por defecto.
 * @const
 * @default ".04"
 */
export const DEFAULT_ANUAL_INFLATION = .04;
