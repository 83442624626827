/**
 * MODELOS
 * @description Archivo dedicado para indexar todos los modelos.
 */
export * from '@models/commission-scheme/commission-scheme.interface';
export * from '@models/coverage/coverage.type';
export * from '@models/coverage-constants/coverage-constants.interface';
export * from '@models/coverage-factors/coverage-factors';
export * from '@models/coverage-status/coverage-status';
export * from '@models/factors-search/factors-search.model';
export * from '@models/gender/gender.model';
export * from '@models/habit/habit.model';
export * from '@models/payment-frequency/payment-frequency.type';
export * from '@models/payment-term/payment-term';
export * from '@models/plazo/plazo.interface';
export * from '@models/prospectu/prospectu.model';
export * from '@models/protection-plan/protection-plan';
export * from '@models/replication/replication.model';
export * from '@models/split-payment/split-payment.model';
export * from '@models/terms/custom-terms.interface';
export * from '@models/user/user.model';
export * from '@models/utils/utils.model';
export * from '@models/validationtype-constants/validationtype-constants.interface';
export * from '@models/user/user.model';
export * from '@models/questions/answerQuestion.model';
export * from '@models/questions/profillingQuiz.model';

