/**
 * COMPONENTES
 * @description Archivo dedicado para indexar los componentes.
 */

export * from '@components/build-plan/build-plan.component';
export * from '@components/guaranteed-values/guaranteed-values.component';
export * from '@components/necessity/necessity.component';
export * from '@components/not-permitted/not-permitted.component';
export * from '@components/proposals/proposals.component';
export * from '@components/prospectus-data/prospectus-data.component';
export * from '@components/template/template.component';
export * from '@components/valores-template/valores-template.component';
export * from '@components/landing/landing.component';
export * from '@components/requirements-coverage/requirements-coverage.component';
export * from '@components/download-plans/download-plans.component';
export * from '@components/template-protecion-efectiva/template-protefectiva.component';
export * from '@components/profiling/profiling.component';
export * from'@components/surplus/surplus.component';





