/** CONSTANTS */
import { ROUTE_NAMING } from '@constants/routes/route-naming.constant';
/** ANGULAR CORE */
import { Injectable } from '@angular/core';
/** ROUTER */
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
/** RXJS */
import { Observable } from 'rxjs';

/**
 * GUARD PARA NAVEGADOR NO COMPATIBLE
 * @export
 * @class BrowserGuard
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root'
})
export class BrowserGuard implements CanActivate {
  /**
   * CREA UNA INSTANCIA DE BrowserGuard.
   * @param {Router} router
   * @memberof BrowserGuard
   */
  constructor(private router: Router) {}
  /**
   * MÉTODO QUE REDIRIGE A NOT FOUND
   * CUANDO EL NAVEGADOR NO SEA COMPATIBLE
   * @param {ActivatedRouteSnapshot} next
   * @param {RouterStateSnapshot} state
   * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
   * @memberof BrowserGuard
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const currentRute = String(window.location.href.split('/')[5] );

      if (currentRute === ROUTE_NAMING.PASO1) {
        this.router.navigate([ROUTE_NAMING.NO_ENCONTRADO]);
        return false;
      }
    return true;
  }
}
