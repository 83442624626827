/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { DIPOC, DIPOCUniversal } from '@constants/coverages';
/** Models */
import { Plazo } from '@models/plazo/plazo.interface';
/** Services */
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { BAService } from '@services/coverages/BA/ba.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { FactoresService } from '@services/factores/factores.service';
/** Rxjs */
import { BehaviorSubject, Subscription } from 'rxjs';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';

/**
 * Servicio de cobertura DIPOC
 */
@Injectable({
  providedIn: 'root'
})
export class DIPOCService {
  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_UDI = new BehaviorSubject<number>(0);
  sumaAsegurada_MXN_U = new BehaviorSubject<number>(0);
  sumaAsegurada_USD_U = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida1 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  sumaAsegurada_MXN_U$ = this.sumaAsegurada_MXN_U.asObservable();
  sumaAsegurada_USD_U$ = this.sumaAsegurada_USD_U.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();
  /**
   * Selección por defecto
   * @property
   * @default true
   */
  DEFAULT = true;
  /**
   * Crea una suscripción almacenando la suma asegurada de la cobertura Básica
   * @property {Subscription}
   */
  sumaAsegurada_BA: Subscription;
  /**
   * Constructor
   * @constructor
   * @param recursosExternos Crea una instancia de RecursosExternosService
   * @param BaService Crea una instanca de BAServce
   * @param prospectoService Crea una instancia de ProspectoService
   */
  constructor(
    private recursosExternos: RecursosExternosService,
    private BaService: BAService,
    private prospectoService: ProspectoService,
    private factores: FactoresService,
    private opciones: OpcionesPlanService,

  ) {
    this.sumaAsegurada_BA = this.BaService.sumaAseguradaUniversal.distinctUntilChanged()
    .subscribe(sumaAsegurada => {
      if (this.sumaAsegurada_MXN_U.value > 0 &&
        this.sumaAsegurada_MXN_U.value > sumaAsegurada) {
        this.obtenerPrimaU(0);
      }
    });
  }
  /**
   * Establece la prima anual en la cobertura dado el valor
   * @param sumaAseguradaUDI
   */
  establecePrimaAnual(sumaAseguradaUDI) {
    this.sumaAsegurada_BA = this.BaService.sumaAsegurada_UDI.distinctUntilChanged()
    .subscribe(sumaAsegurada => {
      this.obtenerPrima(sumaAseguradaUDI);
    });
  }

  /**
   * Obtiene la prima apartir del valor asignado
   * @param {number} sumaAsegurada
   */
  obtenerPrima(sumaAsegurada: number): void {
    // Variable de suma asegurada que contendra el valor final de la suma asegurada despues de ser evaluada.
    let suma = 0;

    /**
     * Si la suma asegurada inicial es igual a 0, tomará por defecto el valor de la suma asegurada basica
     * de lo contrario continuará evaluando la suma asegurada introducida.
    */
    this.DEFAULT = sumaAsegurada === 0;

    // Se obtienen la informacion del prospecto actual
    const prospecto = this.prospectoService.prospecto.value;

    // Evalua si la edad del prospecto esta dentro del rango de aceptación de la cobertura
    if (
      prospecto.age >= DIPOC.ACCEPTANCE_AGE.MIN &&
      prospecto.age <= DIPOC.ACCEPTANCE_AGE.MAX
    ) {

      if (sumaAsegurada !== -1) {
        if (this.DEFAULT) {
          suma = this.BaService.sumaAsegurada_UDI.value;
          this.DEFAULT = false;
        } else {
          suma = sumaAsegurada || 0;
        }

        if (
          suma > this.BaService.sumaAsegurada_UDI.value ||
          suma < this.recursosExternos.GetMinMax('BA', 'MIN')
        ) {
          suma = this.BaService.sumaAsegurada_UDI.value;
        }

        if (this.opciones.tipoPlan === 'TEMPORAL' && this.opciones.moneda === 'MXN') {
          suma = this.recursosExternos.obtenerSumaAsegurada('PlanTmpDIPOC', suma);
        } else {
          suma = this.recursosExternos.obtenerSumaAsegurada('DIPOC', suma);
        }

        // if (suma <= this.recursosExternos.GetMinMax('DIPOC', 'MIN')) {
        //   suma = this.recursosExternos.GetMinMax('DIPOC', 'MIN');
        // }

        // if (suma >= this.recursosExternos.GetMinMax('DIPOC', 'MAX')) {
        //   suma = this.recursosExternos.GetMinMax('DIPOC', 'MAX');
        // }

        const primaDIPOC_UDI = this.calculoPrimaDIPOC(this.recursosExternos.FirstToUDI(suma), 'Elegido');
        const primaDIPOC_MXN = this.recursosExternos.UDIToSecond(primaDIPOC_UDI);

        const primaDIPOC_UDI_Sugerida1 = this.calculoPrimaDIPOC(this.recursosExternos.FirstToUDI(suma), 'Sugerido1');
        const primaDIPOC_MXN_Sugerida1 = this.recursosExternos.UDIToSecond(primaDIPOC_UDI_Sugerida1);

        const primaDIPOC_UDI_Sugerida2 = this.calculoPrimaDIPOC(this.recursosExternos.FirstToUDI(suma), 'Sugerido2');
        const primaDIPOC_MXN_Sugerida2 = this.recursosExternos.UDIToSecond(primaDIPOC_UDI_Sugerida2);

        const sumaAsegurada_UDI = suma;
        const sumaAsegurada_MXN = this.recursosExternos.FirstToSecond(sumaAsegurada_UDI);

        this.sumaAsegurada_UDI.next(sumaAsegurada_UDI);
        this.sumaAsegurada_MXN.next(sumaAsegurada_MXN);

        this.primaAnual_UDI.next(this.recursosExternos.UDIToFirst(primaDIPOC_UDI));
        this.primaAnual_MXN.next(primaDIPOC_MXN);

        this.primaAnual_UDI_Sugerida1.next(this.recursosExternos.UDIToFirst(primaDIPOC_UDI_Sugerida1));
        this.primaAnual_MXN_Sugerida1.next(primaDIPOC_MXN_Sugerida1);

        this.primaAnual_UDI_Sugerida2.next(this.recursosExternos.UDIToFirst(primaDIPOC_UDI_Sugerida2));
        this.primaAnual_MXN_Sugerida2.next(primaDIPOC_MXN_Sugerida2);

        return;

      }

    }
      this.resetValues();
      return;
  }

  /**
   * Realiza el calculo de la tarifa
   *
   * @param {number} calculoPrimaDIPOC
   * @returns {number} Prima DIPOC
   * @memberof DIService
   */
  calculoPrimaDIPOC(primaBasicaFallecimiento: number, plazo: Plazo, isINPC: boolean = false, edadInpc?: number): number {

    // Variables necesarias para el calculo
    const factorDIPOC = this.factores.obtenerFactorCobertura('DIPOC', plazo, isINPC, edadInpc);

    // Ejecuta la formula dada
    const primaDIPOC = factorDIPOC !== 0 ? (
      primaBasicaFallecimiento * (factorDIPOC / 1000)
    ) : 0;

    // Retorna el resultado de la operación
    return primaDIPOC;
  }

  resetValues() {
    this.primaAnual_UDI.next(0);
    this.primaAnual_MXN.next(0);
    this.primaAnual_UDI_Sugerida1.next(0);
    this.primaAnual_MXN_Sugerida1.next(0);
    this.primaAnual_UDI_Sugerida2.next(0);
    this.primaAnual_MXN_Sugerida2.next(0);
    this.sumaAsegurada_UDI.next(0);
    this.sumaAsegurada_MXN.next(0);
    this.DEFAULT = true;
    this.sumaAsegurada_MXN_U.next(0);
    this.sumaAsegurada_USD_U.next(0);
  }
  // Método Para universales

  establecePrimaAnualU(sumaAseguradaUDI) {
    this.obtenerPrimaU(sumaAseguradaUDI);
  }
  obtenerPrimaU(sumaAsegurada: number): void {
    // Variable de suma asegurada que contendra el valor final de la suma asegurada despues de ser evaluada.
    let suma = 0;

    /**
     * Si la suma asegurada inicial es igual a 0, tomará por defecto el valor de la suma asegurada basica
     * de lo contrario continuará evaluando la suma asegurada introducida.
    */
    this.DEFAULT = sumaAsegurada === 0;

    // Se obtienen la informacion del prospecto actual
    const prospecto = this.prospectoService.prospecto.value;

    // Evalua si la edad del prospecto esta dentro del rango de aceptación de la cobertura
    if (
      prospecto.age >= DIPOCUniversal.ACCEPTANCE_AGE.MIN &&
      prospecto.age <= DIPOCUniversal.ACCEPTANCE_AGE.MAX
    ) {

      if (sumaAsegurada !== -1) {
        if (this.DEFAULT) {
          suma = this.BaService.sumaAseguradaUniversal.value;
          this.DEFAULT = false;
        } else {
          suma = sumaAsegurada || 0;
        }

        if (suma > this.BaService.sumaAseguradaUniversal.value) {
          suma = this.BaService.sumaAseguradaUniversal.value;
        }

        if (suma <= this.recursosExternos.GetMinMaxU('DIPOC', 'MIN', this.opciones.moneda, this.opciones.tipoPlan)) {
          suma = this.recursosExternos.GetMinMaxU('DIPOC', 'MIN', this.opciones.moneda, this.opciones.tipoPlan);
        }

        if (suma >= this.recursosExternos.GetMinMaxU('DIPOC', 'MAX', this.opciones.moneda, this.opciones.tipoPlan)) {
          suma = this.recursosExternos.GetMinMaxU('DIPOC', 'MAX', this.opciones.moneda, this.opciones.tipoPlan);
        }


        const sumaAsegurada_MXN = suma;
        const sumaAsegurada_USD = this.recursosExternos.MXNtoUSDU(sumaAsegurada_MXN);

        this.sumaAsegurada_MXN_U.next(sumaAsegurada_MXN);
        this.sumaAsegurada_USD_U.next(sumaAsegurada_USD);

        return;

      }

    }
      this.resetValues();
      return;
  }

}
