import { Pipe, PipeTransform } from '@angular/core';
import { REACHED_AGES } from '@app/constants';

/**
 * Clase de la Pipe del plazo pago para la cobertura conyugal (CC)
 * @export
 * @class ConyugalPlazoPagoPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'conyugalPlazoPago'
})
export class ConyugalPlazoPagoPipe implements PipeTransform {

  /**
   * Transforma el plazo del pago dentro del
   * template de la cotización para la cobertura conyugal (CC)
   * @param {number} plazoPago
   * @returns {number}
   * @memberof ConyugalPlazoPagoPipe
   */
  transform(plazoPago: number, edadProspecto: number): number {
    const isNotEdadAlcanzada: number = REACHED_AGES.filter(plazo => plazoPago === plazo)[0] || 0;

    if (isNotEdadAlcanzada !== 0) {
      const edadAlcanzada: number = isNotEdadAlcanzada === 99 ? 90 : isNotEdadAlcanzada;

      return edadAlcanzada - edadProspecto;
    }

    return plazoPago;
  }

}
