import { Component, HostListener, OnInit } from '@angular/core';
import { Prospectu } from '../../models';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {FINANCIAL_REQUIREMENTS} from '@constants/requirements/financial/financial.constant';
import { EXCHANGE_RATE_REQUIREMENTS } from '@constants/requirements/exchange-rate/exchange-rate.constant';
import { ProtectionPlansGeneratorService } from '@app/services/protectionPlansGenerator/protection-plans-generator.service';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
import { MEDICAL_REQUIREMENTS } from '@constants/requirements/medical/medical.constant';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { ValoresGarantizadosService } from '@app/services/valoresGarantizados/valores-garantizados.service';

// import { HttpClient } from '@angular/common/http';
import {MailService} from '@app/services/mail/mail.service';
import * as moment from 'moment';
import { CartaPdfService } from '@app/services/carta-pdf/carta-pdf.service';
import { RoutesService } from '@app/services/routes/routes.service';
import { CalculosUniversalesService } from './../../services/universales/calculos-universales.service';

@Component({
  selector: 'cvida-requirements-coverage',
  templateUrl: './requirements-coverage.component.html',
  styleUrls: ['./requirements-coverage.component.sass']
})
export class RequirementsCoverageComponent implements OnInit {
  screenWidth: number;
  /**
   * Valor del radiobutton
   * @property
   * @type {any}
   */
  radioValue: any;
  /**
   * Valor secundario del radiobutton
   * @property
   * @default "false"
   */
  radio2Value = false;
  /**
   * Referencia del modal
   * @property
   * @type {any}
   */

  /**
   * Valida el status del select
   * @property
   * @type {boolean}
   */
  check: boolean;
  /**
   * Máscara de la suma asegurada
   * @property
   */
  sumaAseguradaMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    decimalLimit: 0
  });

  requisitosMedicos: FormGroup;
  registerForm: FormGroup;
  submitted = false;

  /**
   * objeto del prospecto
   * @property
   * @type {Prospectu}
   */
  prospectu: Prospectu;

  tipoCambioUDI = EXCHANGE_RATE_REQUIREMENTS.UDI;
  tipoCambioUSD = EXCHANGE_RATE_REQUIREMENTS.USD;
  tipodecambio = this.protectionPlansGenerator.currencies.value;
  ageMedical = MEDICAL_REQUIREMENTS.AGE_STAGE;
  sumaMedical = MEDICAL_REQUIREMENTS.SUM_ASSURED;
  notesMedical = MEDICAL_REQUIREMENTS.DOCUMENTS;
  saFinalcial = FINANCIAL_REQUIREMENTS.SUM_ASSURED;
  noteFinalcial = FINANCIAL_REQUIREMENTS.DOCUMENTS;

  indexAge = 0;
  posAge = 0;
  indexSA = 0;
  posSA = 0;
  indexDoc = 0;
  posDoc = 0;
  indexDocF = 0;
  posDocF = 0;
  indexSaF = 0;
  calc: Number = 0;

  saValidate: Number[];
  cumulos: Object[] = [];
  docMed: { name: string } = { name: '' };
  docFin: { name: string } = { name: '' };

  showCumulo = 1;
  cumulo2 = false;
  cumulo3 = false;
  cumulo4 = false;
  cumulo5 = false;
  reqMed = false;
  reqFin = false;
  go1 = true;
  go2 = true;
  go3 = true;
  cumuloTotal: number;
  saCumulo1: string;
  saCumulo2: string;
  saCumulo3: string;
  saCumulo4: string;
  saCumulo5: string;

  btnAgendarMed = false;
  bntDescargarF = false;
  btnAgendarF = false;

  agente: { nombre: string; correo: string } = { nombre: '', correo: '' };
  estados: { nom: string[] };

  tipoMoneda: { title: string; value: string }[] = this.routes.chosenCotizador.value !== 'Universales' ? [
    { title: 'PESOS', value: 'MXN' },
    { title: 'DÓLARES', value: 'USD' },
    { title: 'UDI', value: 'UDI' }
  ] :
  [
    { title: 'PESOS', value: 'MXN' },
    { title: 'DÓLARES', value: 'USD' },
  ] ;

  mailValid = true;
  reqMail = true;
  phoneInvalid = true;
  phoneValid = true;
  nameValid = true;
  reqName = true;
  lastNameF = true;
  lastNameFValid = true;
  lastNameM = true;
  lastNameMValid = true;
  dateFecNacValid = true;
  reqFecNac = true;
  dateFenCitValid = true;
  reqFecCit = true;
  hourValid = true;
  reqHour = true;
  phoneProsValid = true;
  reqPhonePros = true;
  mailV: boolean;
  phoneAV: boolean;
  nameV: boolean;
  lastNameFV: boolean;
  lastNameMV: boolean;
  dateFnV: boolean;
  dateFcV: boolean;
  hourV: boolean;
  phonePV: boolean;
  timeValid = true;
  isValidForm = false;
  /**Variable para emnsajes de Moneda */
  validaMon1 = false;
  validaMon2 = false;
  validaMon3 = false;
  validaMon4 = false;
  /**Variable spara validar sA */
  validaSA1 = false;
  validaSA2 = false;
  validaSA3 = false;
  validaSA4 = false;
  /**Variables para mostrar mensaje de error de SA con Riesgo Preferente */
  validaSAR1 = false;
  validaSAR2 = false;
  validaSAR3 = false;
  validaSAR4 = false;
  /**Variables para deshabilitar el boton */
  validaBtn1 = false;
  validaBtn2 = false;
  validaBtn3 = false;
  validaBtn4 = false;

  monSelected: string;

  /**
   * Declaración de variables.
   * @param prospectusDataService
   * @param modalService
   * @method
   */
  constructor(
    private protectionPlansGenerator: ProtectionPlansGeneratorService,
    public opciones: OpcionesPlanService,
    // private http: HttpClient,
    private mail: MailService,
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private sat: ValoresGarantizadosService,
    public mon: OpcionesPlanService,
    private prospecto: ProspectoService,
    private cartaPDF: CartaPdfService,
    public routes: RoutesService,
    private univCal: CalculosUniversalesService
  ) {
    this.prospecto.prospectoActual.subscribe(data => (this.prospectu = data));
    this.screenWidth = window.innerWidth;
    // Moneda
    this.protectionPlansGenerator.currenciesGenerator(
      this.opciones.moneda,
      this.opciones.tipoPlan
    );
    this.protectionPlansGenerator.currencies.subscribe(currencies => {
      this.opciones.tipodecambio = currencies;
      this.tipodecambio = currencies;
    });

    this.auth.user.subscribe(usuario => {
      this.agente.nombre = usuario.name;
      this.agente.correo = usuario.email;
    });
  }

  ngOnInit() {
    if (this.opciones.plan === 'Universales') {
      this.saCumulo1 = this.univCal.basica.value.sumaAsegurada.toString();
    } else {
      this.saCumulo1 = this.sat.generarSumaAsegurada().toString();
    }
    this.generateForm1();
    this.changeCurrency(this.opciones.moneda, this.saCumulo1, 0);
  }

  /**
   * Método que valida el select
   * @param event
   * @method
   */
  selected() {
    if (this.radio2Value === false) {
      this.check = true;
    }
  }
  /**
   * Método que detecta cuando hay un redimensinamiento de la pantalla, especificamente del Width.
   * @param event
   * @method
   */
  @HostListener('window:resize', ['event']) onResize(event) {
    this.screenWidth = window.innerWidth;
  }
  /**
   * Método que cambia los valores del radiobutton
   * @param status
   * @method
   */
  changeRadioValue(status: boolean): void {
    this.radio2Value = status;
    this.validaSA1 = false;
    this.validaBtn1 = false;
    this.validaBtn2 = false;
    this.validaBtn3 = false;
    this.validaBtn4 = false;
    if (!status) {
      this.restC2('Moneda', '0' , 1);
      this.restC3('Moneda', '0' , 2);
      this.restC4('Moneda', '0' , 3);
      this.restC5('Moneda', '0' , 4);
    }

  }
  /**
   * Método que abre el modal
   * @param template
   * @method
   */

  addC1(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.cumulo2 = true;
  }
  addC2(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.cumulo3 = true;
    this.validaSA1 = false;
    this.validaMon1 = false;
    this.validaSAR1 = false;
    this.validaSA2 = false;
    this.validaSAR2 = false;
  }
  addC3(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.cumulo4 = true;
    this.validaSA2 = false;
    this.validaMon2 = false;
    this.validaSAR2 = false;
    this.validaSA3 = false;
    this.validaSAR3 = false;
  }
  addC4(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.cumulo5 = true;
    this.validaSA3 = false;
    this.validaMon3 = false;
    this.validaSAR3 = false;
    this.validaSA4 = false;
    this.validaSAR4 = false;
  }
  addC5(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.validaSA4 = false;
    this.validaMon4 = false;
    this.validaSAR4 = false;
  }
  restC1(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.saCumulo1 = '';
  }
  restC2(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.cumulo2 = false;
    this.saCumulo2 = '';
  }
  restC3(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.cumulo3 = false;
    this.saCumulo3 = '';
  }
  restC4(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.cumulo4 = false;
    this.saCumulo4 = '';
  }
  restC5(cmb: string, sa: string, pos: number) {
    this.changeCurrency(cmb, sa, pos);
    this.cumulo5 = false;
    this.saCumulo5 = '';
  }
  actualizaValor(cmb: string, sa: string, pos: number) {
    // this.changeCurrency(cmb, sa, pos);

    // this.validaSA(cmb, sa, pos);
    // this.validaMoneda(cmb, sa, pos);

    this.validaCamposCumulos(cmb, sa, pos);
  }

  showCumulos() {
    if (this.showCumulo < 5) {
      this.showCumulo += 1;
      if (this.showCumulo === 2) {
        this.cumulo2 = true;
      }
      if (this.showCumulo === 3) {
        this.cumulo3 = true;
      }
      if (this.showCumulo === 4) {
        this.cumulo4 = true;
      }
      if (this.showCumulo === 5) {
        this.cumulo5 = true;
      }
    }
  }
  notShowCumulos(mon: string, sa: string, pos: string) {
    if (this.showCumulo <= 5) {
      this.showCumulo -= 1;
      if (this.showCumulo === 1) {
        this.cumulo2 = false;
      }
      if (this.showCumulo === 2) {
        this.cumulo3 = false;
      }
      if (this.showCumulo === 3) {
        this.cumulo4 = false;
      }
      if (this.showCumulo === 4) {
        this.cumulo5 = false;
      }
    }
  }

  changeReqMed() {
    this.reqMed = true;
    this.obtenerEstados();
  }
  changeReqFin() {
    this.reqFin = true;
  }

  changeCurrency(value: any, sa: string, po: number) {
    this.monSelected = value;
    let moc = 0;
    const saMod = this.deleteCurrencyComma(sa);
    const saNumber = Number(saMod);
    // if (this.opciones.tipoRiesgo.value !== 'Preferente') {
      switch (value) {
        case 'UDI':
          if (po === 0 && this.saCumulo1.length > 0) {
            this.cumulos[po] = saNumber * this.tipoCambioUDI;
            moc = 1;
          }
          if (po === 1 && (this.saCumulo2 !== undefined  && this.saCumulo2 !== '0' && this.saCumulo2 !== '')) {
            this.validaSA1 = false;
            this.validaMon1 = false;
            this.validaBtn1 = true;
            this.cumulos[po] = saNumber * this.tipoCambioUDI;
            moc = 1;
          }
          if (po === 1 && this.saCumulo2 === undefined  || this.saCumulo2 === '0') {
            this.validaSA1 = true;
            this.validaMon1 = false;
            this.validaBtn1 = false;
            this.cumulos[po] = 0;
            moc = 1;
          }
          if (po === 2 &&  (this.saCumulo3 !== undefined  && this.saCumulo3 !== '0' && this.saCumulo3 !== '')) {
            this.cumulos[po] = saNumber * this.tipoCambioUDI;
            moc = 1;
          }
          if (po === 2 && this.saCumulo3 === undefined  || this.saCumulo3 === '0') {
            this.validaSA2 = true;
            this.validaMon2 = false;
            this.validaBtn2 = false;
            this.cumulos[po] = 0;
            moc = 1;
          }
          if (po === 3 && (this.saCumulo4 !== undefined  && this.saCumulo4 !== '0' && this.saCumulo4 !== '')) {
            this.cumulos[po] = saNumber * this.tipoCambioUDI;
            moc = 1;
          }
          if (po === 3 && this.saCumulo4 === undefined  || this.saCumulo4 === '0') {
            this.validaSA3 = true;
            this.validaMon3 = false;
            this.validaBtn3 = false;
            this.cumulos[po] = 0;
            moc = 1;
          }
          if (po === 4 && (this.saCumulo5 !== undefined  && this.saCumulo5 !== '0' && this.saCumulo5 !== '')) {
            this.cumulos[po] = saNumber * this.tipoCambioUDI;
            moc = 1;
          }
          if (po === 4 && this.saCumulo5 === undefined  || this.saCumulo5 === '0') {
            this.validaSA4 = true;
            this.validaMon4 = false;
            this.validaBtn4 = false;
            this.cumulos[po] = 0;
            moc = 1;
          }
          if (moc === 0) {
            this.cumulos.push(Number(saNumber));
          }
          break;
        case 'USD':
          if (po === 0 && this.saCumulo1.length > 0) {
            this.cumulos[po] = saNumber * this.tipoCambioUSD;
            moc = 1;
          }
          if (po === 1 && (this.saCumulo2 !== undefined  && this.saCumulo2 !== '0' && this.saCumulo2 !== '')) {
            this.validaSA1 = false;
            this.validaMon1 = false;
            this.validaBtn1 = true;
            this.cumulos[po] = saNumber * this.tipoCambioUSD;
            moc = 1;
          }
          if (po === 1 && this.saCumulo2 === undefined  || this.saCumulo2 === '0') {
            this.validaSA1 = true;
            this.validaMon1 = false;
            this.validaBtn1 = false;
            this.cumulos[po] = 0;
            moc = 1;
          }
          if (po === 2 && (this.saCumulo3 !== undefined  && this.saCumulo3 !== '0' && this.saCumulo3 !== '')) {
            this.validaSA2 = false;
            this.validaMon2 = false;
            this.validaBtn2 = true;
            this.cumulos[po] = saNumber * this.tipoCambioUSD;
            moc = 1;
          }
          if (po === 2 && this.saCumulo3 === undefined  || this.saCumulo3 === '0') {
            this.validaSA2 = true;
            this.validaMon2 = false;
            this.validaBtn2 = false;
            this.cumulos[po] = 0;
            moc = 1;
          }
          if (po === 3 && (this.saCumulo4 !== undefined  && this.saCumulo4 !== '0' && this.saCumulo4 !== '')) {
              this.validaSA3 = false;
              this.validaMon3 = false;
              this.validaBtn3 = true;
              this.cumulos[po] = saNumber * this.tipoCambioUSD;
              moc = 1;
          }
          if (po === 3 && this.saCumulo4 === undefined  || this.saCumulo4 === '0') {
            this.validaSA3 = true;
            this.validaMon3 = false;
            this.validaBtn3 = false;
            this.cumulos[po] = 0;
            moc = 1;
          }
          if (po === 4 && (this.saCumulo5 !== undefined  && this.saCumulo5 !== '0' && this.saCumulo5 !== '')) {
            this.validaSA4 = false;
            this.validaMon4 = false;
            this.validaBtn4 = true;
            this.cumulos[po] = saNumber * this.tipoCambioUSD;
            moc = 1;
          }
          if (po === 4 && this.saCumulo5 === undefined  || this.saCumulo5 === '0') {
            this.validaSA4 = true;
            this.validaMon4 = false;
            this.validaBtn4 = false;
            this.cumulos[po] = 0;
            moc = 1;
          }
          if (moc === 0) {
            this.cumulos.push(Number(saNumber));
          }
          break;
        case 'MXN':
          if (po === 0 && this.saCumulo1.length > 0) {
            this.cumulos[po] = saNumber;
            moc = 1;
          }
          if (po === 1 && (this.saCumulo2 !== undefined  && this.saCumulo2 !== '0' && this.saCumulo2 !== '')) {
              this.validaSA1 = false;
              this.validaMon1 = false;
              this.validaBtn1 = true;
              this.cumulos[po] = saNumber;
              moc = 1;
          }
          if (po === 1 && this.saCumulo2 === undefined  || this.saCumulo2 === '0') {
            this.validaSA1 = true;
            this.validaMon1 = false;
            this.validaBtn1 = false;
            this.cumulos[po] = saNumber;
            moc = 1;
          }
          if (po === 2 && (this.saCumulo3 !== undefined  && this.saCumulo3 !== '0' && this.saCumulo3 !== '')) {
              this.validaSA2 = false;
              this.validaMon2 = false;
              this.validaBtn2 = true;
              this.cumulos[po] = saNumber;
              moc = 1;
          }
          if (po === 2 && this.saCumulo3 === undefined  || this.saCumulo3 === '0') {
            this.validaSA2 = true;
            this.validaMon2 = false;
            this.validaBtn2 = false;
            this.cumulos[po] = saNumber;
            moc = 1;
          }
          if (po === 3 && (this.saCumulo4 !== undefined  && this.saCumulo4 !== '0' && this.saCumulo4 !== '')) {
            this.validaSA3 = false;
            this.validaMon3 = false;
            this.validaBtn3 = true;
            this.cumulos[po] = saNumber;
            moc = 1;
          }
          if (po === 3 && this.saCumulo4 === undefined  || this.saCumulo4 === '0') {
            this.validaSA3 = true;
            this.validaMon3 = false;
            this.validaBtn3 = false;
            this.cumulos[po] = saNumber;
            moc = 1;
          }
          if (po === 4 && (this.saCumulo5 !== undefined  && this.saCumulo5 !== '0' && this.saCumulo5 !== '')) {
            this.validaSA4 = false;
            this.validaMon4 = false;
            this.validaBtn4 = true;
            this.cumulos[po] = saNumber;
            moc = 1;
          }
          if (po === 4 && this.saCumulo5 === undefined  || this.saCumulo5 === '0') {
            this.validaSA4 = true;
            this.validaMon4 = false;
            this.validaBtn4 = false;
            this.cumulos[po] = saNumber;
            moc = 1;
          }
          if (moc === 0) {
            this.cumulos.push(Number(saNumber));
          }
          break;
      }
    // }
    this.calculaCumuloTotal(this.cumulos);
  }

  deleteCurrencyComma(text: string): string {
    return String(text)
      .replace(/\,/g, '')
      .replace(', ', '');
  }
  validaMoneda(mon: string, sa: string, pos: number) {
    // console.log('entro a la validacion de moneda' + ' ' + mon + ' ' + sa + ' ' + pos);
    // console.log(mon);
    if (mon === 'MXN' || mon === 'USD' && (sa !== undefined || sa !== null || sa !== '')) {
      switch (true) {
        case pos === 1:
          this.validaMon1 = false;
          this.validaSA1 = false;
          // this.validaBtn1 = true;
          this.changeCurrency(mon, sa, pos);
        break;
        case pos === 2:
          this.validaMon2 = false;
          this.validaSA2 = false;
          // this.validaBtn2 = true;
          this.changeCurrency(mon, sa, pos);
        break;
        case pos === 3:
          this.validaMon3 = false;
          this.validaSA3 = false;
          // this.validaBtn3 = true;
          // this.changeCurrency(mon, sa, pos);
        break;
        case pos === 4:
          this.validaMon4 = false;
          this.validaSA4 = false;
          // this.validaBtn4 = true;
          this.changeCurrency(mon, sa, pos);
        break;
      }
    }
    if (sa === null || sa === undefined || sa === '') {
      switch (true) {
        case pos === 1:
          this.validaSA1 = true;
          this.validaMon1 = false;
        break;
        case pos === 2:
          this.validaSA2 = true;
          this.validaMon2 = false;
        break;
        case pos === 3:
          this.validaSA3 = true;
          this.validaMon3 = false;
      break;
        case pos === 4:
          this.validaSA4 = true;
          this.validaMon4 = false;
        break;
      }
    }
    if (mon !== 'MXN' && mon !== 'USD') {
      switch (true) {
        case pos === 1:
          this.validaMon1 = true;
        break;
        case pos === 2:
          this.validaMon2 = true;
        break;
        case pos === 3:
          this.validaMon3 = true;
      break;
        case pos === 4:
          this.validaMon4 = true;
        break;
      }
    }
  }

  validaCamposCumulos (mon: string, sa: string, pos: number) {
    switch (true) {
      case pos === 1:
        // Para Cumulo 2
        if (this.saCumulo2 === '' || this.cumulo2 === undefined || sa === undefined) {
          this.validaSA1 = true;
        } else {
          this.validaSA1 = false;
        }
        if (mon === 'Moneda' && this.saCumulo2 !== '' || this.saCumulo2 !== undefined || sa !== undefined) {
          this.validaMon1 = true;
        } else {
        this.validaMon1 = false;
        }
        if (mon !== 'Moneda' && this.saCumulo2 !== undefined || this.saCumulo2 !== '' || sa !== undefined) {
          this.changeCurrency(mon, sa, pos);
        }
      break;
      case pos === 2:
        // Para cumulo 3
        if (this.saCumulo3 === '' || this.cumulo3 === undefined || sa === undefined) {
          this.validaSA2 = true;
        } else {
          this.validaSA2 = false;
        }
        if (mon === 'Moneda' && this.saCumulo3 !== '' || this.saCumulo3 !== undefined || sa !== undefined) {
          this.validaMon2 = true;
        } else {
          this.validaMon2 = false;
        }
        if (mon !== 'Moneda' && this.saCumulo3 !== undefined || this.saCumulo3 !== '' || sa !== undefined) {
          this.changeCurrency(mon, sa, pos);
        }
      break;
      case pos === 3:
        // Para cumulo 4
        // console.log('valor 3 cumulo ' + this.saCumulo4 + ' ' + mon);
        if (this.saCumulo4 === '' || this.cumulo4 === undefined || sa === undefined) {
          this.validaSA3 = true;
        } else {
          this.validaSA3 = false;
        }
        if (mon === 'Moneda' && this.saCumulo4 !== '' || this.saCumulo4 !== undefined || sa !== undefined) {
          this.validaMon3 = true;
        } else {
          this.validaMon3 = false;
        }
        if (mon !== 'Moneda' && this.saCumulo4 !== undefined || this.saCumulo4 !== '' || sa !== undefined) {
          this.changeCurrency(mon, sa, pos);
        }
      break;
      case pos === 4:
        // console.log('valor 4 cumulo ' + this.saCumulo5 + ' ' + mon);
        if (this.saCumulo5 === '' || this.cumulo5 === undefined || sa === undefined) {
          this.validaSA4 = true;
        } else {
          this.validaSA4 = false;
        }
        if (mon === 'Moneda' && this.saCumulo5 !== '' || this.saCumulo5 !== undefined || sa !== undefined) {
          this.validaMon4 = true;
        } else {
          this.validaMon3 = false;
        }
        if (mon !== 'Moneda' && this.saCumulo5 !== undefined || this.saCumulo5 !== '' || sa !== undefined) {
          this.changeCurrency(mon, sa, pos);
        }
      break;
    }
  }

  validaSA (mon: string, sa: string, pos: number) {
    if (sa !== undefined && sa !== null && sa !== '' && mon === 'MXN' || mon === 'USD') {
      switch (true) {
        case pos === 1:
        this.validaSA1 = false;
        this.validaMon1 = false;
        this.changeCurrency(mon, sa, pos);
        break;
        case pos === 2:
        this.validaSA2 = false;
        this.validaMon1 = false;
        this.changeCurrency(mon, sa, pos);
        break;
        case pos === 3:
        this.validaSA3 = false;
        this.validaMon3 = false;
        this.changeCurrency(mon, sa, pos);
        break;
        case pos === 4:
        this.validaSA4 = false;
        this.validaMon4 = false;
        this.changeCurrency(mon, sa, pos);
        break;
      }
    }
    if (sa !== undefined || sa !== null || sa !== '') {
      switch (true) {
        case pos === 1:
        this.validaSA1 = false;
        break;
        case pos === 2:
        this.validaSA2 = false;
        break;
        case pos === 3:
        this.validaSA3 = false;
        break;
        case pos === 4:
        this.validaSA4 = false;
        break;
      }
    }
    if (mon !== 'MXN' && mon !== 'USD') {
      switch (true) {
        case pos === 1:
        this.validaMon1 = true;
        break;
        case pos === 2:
        this.validaMon2 = true;
        break;
        case pos === 3:
        this.validaMon3 = true;
        break;
        case pos === 4:
        this.validaMon4 = true;
        break;
      }
    }
    if ((mon !== 'MXN' && mon !== 'USD') && (sa === undefined || sa === '' || sa === null)) {
      switch (true) {
        case pos === 1:
          this.validaSA1 = true;
          this.validaMon1 = true;
        break;
        case pos === 2:
          this.validaSA2 = true;
          this.validaMon2 = true;
        break;
        case pos === 3:
          this.validaSA3 = true;
          this.validaMon3 = true;
        break;
        case pos === 4:
          this.validaSA4 = true;
          this.validaMon4 = true;
        break;
      }
    }
  }

  calculaCumuloTotal(value: Object[]) {
    this.cumuloTotal = 0;
    let valuesc = 0;
    value.forEach(n => {
      valuesc = valuesc + Number(n);
    });
    this.cumuloTotal = valuesc;
    this.validaReqMed(this.cumuloTotal);
    this.validaReqFin(this.cumuloTotal);
  }

  validaReqMed(saTotal: number) {
    this.go1 = true;
    this.go2 = true;
    this.indexSA = 0;
    this.posSA = 0;
    this.ageMedical.forEach(() => {
      const edad = this.prospecto.prospecto.value.age;
      if (
        edad >= this.ageMedical[this.indexAge].start &&
        edad <= this.ageMedical[this.indexAge].end &&
        this.go1 === true
      ) {
        this.posAge = this.indexAge;
        this.go1 = false;
      }
      if (this.go1) {
        this.indexAge++;
      }
    });
    this.posAge = this.indexAge;
    this.saValidate = this.sumaMedical[this.posAge];
    this.saValidate.forEach(() => {
      if (saTotal <= this.saValidate[this.indexSA] && this.go2 === true) {
        this.posSA = this.indexSA;
        this.go2 = false;
        if (this.posSA === 1) {
          this.btnAgendarMed = true;
        }
        if (this.posSA === 0) {
          this.btnAgendarMed = false;
          this.reqMed = false;
        }
      }
      if (this.go2) {
        this.indexSA++;
      }
    });
    this.posSA = this.indexSA;
    if (this.go2) {
      this.posSA = 2;
      this.btnAgendarMed = true;
    }
    this.docMed = this.notesMedical[this.posSA];
  }
  validaReqFin(sa: number) {
    this.go3 = true;
    this.posDocF = 0;
    this.indexSaF = 0;
    this.saFinalcial.forEach(() => {
      if (sa <= this.saFinalcial[this.indexSaF] && this.go3 === true) {
        this.posDocF = this.indexSaF;
        this.go3 = false;
        if (this.posDocF >= 1) {
          this.bntDescargarF = true;
        }
        if (sa > this.saFinalcial[1] && sa <= this.saFinalcial[2]) {
          this.btnAgendarF = true;
        }
        if (sa <= this.saFinalcial[0] && this.posDocF === 0) {
          this.bntDescargarF = false;
          this.btnAgendarF = false;
          this.posDocF = 0;
        }
      }
      if (this.go3) {
        this.indexSaF++;
      }
    });
    this.posDocF = this.indexSaF;
    if (this.go3) {
      this.posDocF = 3;
      this.bntDescargarF = true;
      this.btnAgendarF = true;
    }
    this.docFin = this.noteFinalcial[this.posDocF];
  }

  generateForm1() {
    this.registerForm = this.fb.group({
      nombreAgente: [''],
      emailAgente: ['', Validators.required],
      telAgente: ['', Validators.required],
      nombrePros: ['', Validators.required],
      apellidoP: ['', Validators.required],
      apellidoM: ['', Validators.required],
      fechaNac: ['', Validators.required],
      folio: [''],
      fechaCit: ['', Validators.required],
      horaCit: ['', Validators.required],
      telPros: ['', Validators.required]
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
  }

  obtenerEstados() {
    this.estados = {
      nom: [
        'AGUASCALIENTES',
        'BAJA CALIFORNIA',
        'BAJA CALIFORNIA SUR',
        'CAMPECHE',
        'CHIAPAS',
        'CHIHUAHUA',
        'CIUDAD DE MÉXICO',
        'COAHUILA',
        'COLIMA',
        'DURANGO',
        'ESTADO DE MÉXICO',
        'GUANAJUATO',
        'GUERRERO',
        'HIDALGO',
        'JALISCO',
        'MICHOACÁN',
        'MORELOS',
        'NAYARIT',
        'NUEVO LEÓN',
        'OAXACA',
        'PUEBLA',
        'QUERÉTARO',
        'QUINTANA ROO',
        'SAN LUIS POTOSÍ',
        'SINALOA',
        'SONORA',
        'TABASCO',
        'TAMAULIPAS',
        'TLAXCALA',
        'VERACRUZ',
        'YUCATÁN',
        'ZACATECAS'
      ]
    };
  }
  // Validaciones de campos
  isValidEmail(mail: string) {
    const patternMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    if (patternMail.test(mail)) {
      this.mailValid = true;
      this.mailV = this.mailValid;
      this.validaCampos();
    } else {
      this.mailValid = false;
    }
    this.emptyMail();
  }
  emptyMail() {
    if (
      this.registerForm.value.emailAgente === '' ||
      this.registerForm.value.emailAgente === undefined
    ) {
      this.reqMail = false;
    } else {
      this.reqMail = true;
    }
  }
  isValidPhone(number: string) {
    this.phoneValid =
      this.registerForm.controls['telAgente'].invalid &&
      this.registerForm.controls['telAgente'].touched;
    const patternPhone = /^[0-9]*$/i;
    if (patternPhone.test(number)) {
      this.phoneValid = true;
      this.phoneAV = this.phoneValid;
      this.validaCampos();
    } else {
      this.phoneValid = false;
    }
    this.emptyPhone();
    // console.log(this.registerForm.invalid);
  }
  emptyPhone() {
    if (
      this.registerForm.value.telAgente === '' ||
      this.registerForm.value.telAgente === undefined
    ) {
      this.phoneInvalid = false;
    } else {
      this.phoneInvalid = true;
    }
  }
  isValidName(name: string) {
    const patternName = /^[ a-záéíóúüñ]*$/i;
    if (patternName.test(name)) {
      this.nameValid = true;
      this.nameV = this.nameValid;
      this.validaCampos();
    } else {
      this.nameValid = false;
    }
    this.emptyName();
    this.emptyLastNF();
    this.emptyLastNM();
  }
  emptyName() {
    if (
      this.registerForm.value.nombrePros === '' ||
      this.registerForm.value.nombrePros === undefined ||
      this.registerForm.value.nombrePros === ' '
    ) {
      this.reqName = false;

    } else {
      this.reqName = true;

    }
  }
  isValidLastNameF(name: string) {
    const patternLastName = /^[ a-záéíóúüñ]*$/i;
    if (patternLastName.test(name)) {
      this.nameValid = true;
      this.lastNameFValid = true;
      this.lastNameFV = this.lastNameFValid;
      this.validaCampos();
    } else {
      this.lastNameFValid = false;
    }
    this.emptyLastNF();
    this.emptyName();
    this.emptyLastNM();
  }
  emptyLastNF() {
    if (
      this.registerForm.value.apellidoP === '' ||
      this.registerForm.value.apellidoP === undefined ||
      this.registerForm.value.apellidoP === ' '
    ) {
      this.lastNameF = false;
    } else {
      this.lastNameF = true;
    }
  }
  isValidLastNameM(name: string) {
    const patternLastName = /^[ a-záéíóúüñ]*$/i;
    if (patternLastName.test(name)) {
      this.nameValid = true;
      this.lastNameMValid = true;
      this.lastNameMV = this.lastNameMValid;
      this.validaCampos();
    } else {
      this.lastNameMValid = false;
    }
    this.emptyLastNM();
    this.emptyName();
    this.emptyLastNF();
  }
  emptyLastNM() {
    if (
      this.registerForm.value.apellidoM === '' ||
      this.registerForm.value.apellidoM === undefined ||
      this.registerForm.value.apellidoM === ' '
    ) {
      this.lastNameM = false;
    } else {
      this.lastNameM = true;
    }
  }

  isFecNacValid(date: string) {
    // tslint:disable-next-line:max-line-length
    const patternDate = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i;
    // const patternDate = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
    if (patternDate.test(date)) {
      this.dateFecNacValid = true;
      this.dateFnV = this.dateFecNacValid;
      this.validaCampos();
    } else {
      this.dateFecNacValid = false;
    }
    this.emptyDateFecNac();
  }
  emptyDateFecNac() {
    if (
      this.registerForm.value.fechaNac === '' ||
      this.registerForm.value.fechaNac === undefined
    ) {
      this.reqFecNac = false;
    } else {
      this.reqFecNac = true;
    }
  }
  isFecCitValid(date: string) {
    // tslint:disable-next-line:max-line-length
    const patternDateCit = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i;
    // const patternDateCit = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
    if (patternDateCit.test(date)) {
      this.dateFenCitValid = true;
      // this.dateFcV = this.dateFenCitValid;
      this.fecInicioVigente(date);
    } else {
      this.dateFenCitValid = false;
    }
    this.emptyDateFecCit();
  }
  emptyDateFecCit() {
    if (
      this.registerForm.value.fechaCit === '' ||
      this.registerForm.value.fechaCit === undefined
    ) {
      this.reqFecCit = false;
    } else {
      this.reqFecCit = true;
    }
  }
  isHourValid(hour: string) {
    // const patternHour = /^(?:0?[1-9]|1[0-2]):[0-5][0-9]\s?(?:[aApP](\.?)[mM]\1)?$/;
    const patternHour = /(0?[01]\d|2[0123]):(?:[012345]\d)/gm;
    if (patternHour.test(hour)) {
      this.hourValid = true;
      this.hourV = this.hourValid;
      this.validaCampos();
    } else {
      this.hourValid = false;
    }
    this.emptyHour();
  }
  emptyHour() {
    if (
      this.registerForm.value.horaCit === '' ||
      this.registerForm.value.horaCit === undefined
    ) {
      this.reqHour = false;
    } else {
      this.reqHour = true;
    }
  }
  isValidPhonePros(numberPros: string) {
    const patternPhonePros = /^[0-9]*$/i;
    if (patternPhonePros.test(numberPros)) {
      this.phoneProsValid = true;
      this.phonePV = this.phoneProsValid;
      this.validaCampos();
    } else {
      this.phoneProsValid = false;
    }
    this.emptyPhonePros();
  }
  emptyPhonePros() {
    if (
      this.registerForm.value.telPros === '' ||
      this.registerForm.value.telPros === undefined
    ) {
      this.reqPhonePros = false;
    } else {
      this.reqPhonePros = true;
    }
  }
  validaCampos() {
    if (
      this.mailV &&
      this.phoneAV &&
      this.nameV &&
      this.lastNameFV &&
      this.lastNameMV &&
      this.dateFnV &&
      this.dateFcV &&
      this.hourV &&
      this.phonePV
    ) {
      this.isValidForm = true;
    } else {
      this.isValidForm = false;
    }
  }
  fecInicioVigente(fecMod) {
    // console.log(fecMod);
    const fecIngresada = fecMod.split('/');
    const dateObject = new Date(
      +fecIngresada[2],
      fecIngresada[1] - 1,
      +fecIngresada[0]
    );
    const fechaAc = new Date();
    fechaAc.setDate(fechaAc.getDate() + 2);
    const currentDay = new Date();
    const fI = moment(dateObject).format('YYYY-MM-DD');
    const fA = moment(fechaAc).format('YYYY-MM-DD');
    const cD = moment(currentDay).format('YYYY-MM-DD');
    if (fI >= fA) {
      // console.log('agenda');
      this.timeValid = true;
      this.dateFcV = this.timeValid;
      this.validaCampos();
    } else {
      // console.log('tampoco agenda');
      this.timeValid = false;
      this.dateFcV = this.timeValid;
      this.validaCampos();
    }
    if (fI < cD || fI === cD) {
      // console.log('no agenda');
      this.timeValid = false;
      this.dateFcV = this.timeValid;
      this.validaCampos();
    }
  }

  async sendMail() {
    if (this.isValidForm) {
      // tslint:disable-next-line:triple-equals
      if (this.registerForm.value.nombreAgente === '') {
        this.registerForm.value.nombreAgente = '-';
      } else {
        this.registerForm.value.nombreAgente = this.agente.nombre;
      }
      if (this.registerForm.value.folio === '') {
        this.registerForm.value.folio = '-';
      }

      try {
        await this.mail
          .sendMail(
            this.registerForm.value.nombreAgente,
            this.registerForm.value.emailAgente,
            this.registerForm.value.telAgente,
            this.registerForm.value.nombrePros,
            this.registerForm.value.fechaNac,
            this.registerForm.value.folio,
            this.docMed.name,
            this.registerForm.value.fechaCit,
            this.registerForm.value.horaCit,
            this.registerForm.value.telPros,
            this.cartaPDF.generarPDF()
          )
          .toPromise();

        // console.log('Correo enviado!');
      } catch (error) {}
    }
  }
}
