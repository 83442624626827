/**
 * Cobertura Básica, es la única que está incluida por defecto.
 * @const
 */

export const BASIC = {
  DISCOUNT_PER_VOLUME: {
    UDI: [
      { MIN: 0, MAX: 80000, DISCOUNT: 1 },
      { MIN: 80000, MAX: 350000, DISCOUNT: 1 },
      { MIN: 350000, MAX: 500000, DISCOUNT: .97 },
      { MIN: 500000, MAX: 1000000, DISCOUNT: .95 },
      { MIN: 1000000, MAX: Infinity, DISCOUNT: .93 },
    ],
    MXN: [
      { MIN: 0, MAX: 300000, DISCOUNT: 1 },
      { MIN: 300000, MAX: 3500000, DISCOUNT: 1 },
      { MIN: 3500000, MAX: 5000000, DISCOUNT: .97 },
      { MIN: 5000000, MAX: 10000000, DISCOUNT: .95 },
      { MIN: 10000000, MAX: Infinity, DISCOUNT: .93 },
    ],
    USD: [
      { MIN: 0, MAX: 30000, DISCOUNT: 1 },
      { MIN: 30000, MAX: 350000, DISCOUNT: 1 },
      { MIN: 350000, MAX: 500000, DISCOUNT: .97 },
      { MIN: 500000, MAX: 1000000, DISCOUNT: .95 },
      { MIN: 1000000, MAX: Infinity, DISCOUNT: .93 },
    ],
    MXN_TMP: [
      { MIN: 500000, MAX: 4999999, DISCOUNT: 1 },
      { MIN: 5000000, MAX: 9999999, DISCOUNT: .97 },
      { MIN: 10000000, MAX: Infinity, DISCOUNT: .95 }
    ],
  },
  DISCOUNT_PROTECCION_EFECTIVA: {
    MXN: [
      { MIN: 0, MAX: 100000, DISCOUNT: 1, },
      { MIN: 100000, MAX: 600000, DISCOUNT: 1 },
      { MIN: 600000, MAX: 1100000, DISCOUNT: 1 },
      { MIN: 1100000, MAX: Infinity, DISCOUNT: 1}
    ]
  },
  DISCOUNT_PER_VOLUME_PRTG: {
    UDI: [
      { MIN: 60000, MAX: 500000, DISCOUNT: 1 },
      { MIN: 500000, MAX: 1000000, DISCOUNT: .97 },
      { MIN: 1000000, MAX: Infinity, DISCOUNT: .95 },
    ]
  },
  FIXED_SURCHARGE: [
    { CURRENCY: 'MXN', SURCHARGE: 1100 },
    { CURRENCY: 'UDI', SURCHARGE: 200 },
    { CURRENCY: 'USD', SURCHARGE: 105 },
  ]
};
