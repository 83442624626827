import { BaseDatosService } from '@services/baseDatos/base-datos.service';
import { FrontTerms, PlanList } from './../../models/terms/custom-terms.interface';
import { DEFAULT_TERMS } from './../../constants/plan-options/protection-plans.constant';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { PROSPECTU_CONSTANTS } from './../../constants/prospectu/prospectu.constant';
import {
  CommissionSchemeFront,
  CommissionAssignment,
  CommissionAssignmentFront
} from './../../models/commission-scheme/commission-scheme.interface';
import { GeneradorTablaValoresService } from './../../services/generadorTablaValores/generador-tabla-valores.service';
import { PROTECTION_TERMS } from '@constants/plan-options/protection-plans.constant';
import { CommissionScheme } from '@models/commission-scheme/commission-scheme.interface';
import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { Plan, PlanType, PROTECANCER_AMOUNTS } from '../../constants';
// Core Angular
import {
  Component,
  OnInit,
  TemplateRef,
  OnDestroy,
  HostListener,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

// RXJS
import { Subject } from 'rxjs/Subject';
import { Subscription, of, fromEvent, Observable } from 'rxjs';
import 'rxjs/add/operator/takeUntil';

// Servicios
import { CalculosService } from '@services/calculos/calculos.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { ValidationTypeService } from '@services/validationTypes/validationType.service';
import { ScrollPercentageDetectionService } from '@services/scrollPercentageDetection/scroll-percentage-detection.service';

// NGX Bootstrap
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

// Módelos
import { Coverage } from '@models/coverage/coverage.type';
import { CoverageStatus } from '@models/coverage-status/coverage-status';

import {
  // BA,
  BI,
  CC,
  DI,
  DIPO,
  DIPOC,
  PAI,
  PAE,
  IAC,
  PCD,
  GF,
  BAUniversal,
  ECMIUniversal,
  PAIUniversal,
  DIUniversal,
  DIPOCUniversal,
  GFIUniversal,
  GFCUniversal,
  GFHUniversal,
  CDC
} from '@constants/coverages/coverages.constants';

// TextMask
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

// Pipes
import { CurrencyToNumberPipe } from '@pipes/currency-to-number/currency-to-number.pipe';

// Alertas
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';
import { ResizeDetectionService } from '@services/resizeDetection/resize-detection.service';
import { Prospectu } from '../../models';
import { VariableConfigurationService } from '@services/variableConfiguration/variable-configuration.service';
import { FactoresService } from '@app/services/factores/factores.service';
import { RoutesService } from '@app/services/routes/routes.service';
import { DEFAULT_PLANTYPE } from '@app/constants/plan-options/plan-types.constant.qa';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { mapTo } from 'rxjs/operators';
import { PdfMiProyectoR } from '@app/services/pdf-patrimonial/pdf-mi-pr';
import { PdfVidaInteligente } from '@app/services/pdf-patrimonial/pdf-vi';

import { CalculosUniversalesService } from './../../services/universales/calculos-universales.service';
import { CalculosExcedentesUniversalesService } from '@app/services/universales/calculos-excedentes-universales.service';
import { CoverageConstants } from './../../models/coverage-constants/coverage-constants.interface';
// tslint:disable-next-line:component-class-suffix
/**
 * build-plan component - Arma tu plan.
 */
@Component({
  selector: 'cvida-build-plan',
  templateUrl: './build-plan.component.html',
  styleUrls: ['./build-plan.component.sass'],
  providers: [CurrencyToNumberPipe]
})
export class BuildPlanComponent implements OnInit, OnDestroy {
  /**
   * Toogle - Estado
   *@public
   *@property
   */
  public show = false;
  // Luis

  /**
   * Estado de la variable de retiro para aliados+65
   */
  boolRetiro = false;
  validaRetiro = false;
  retiroResumen = false;
  valuePlan: string;
  valueTerm: string;

  valueRadioTEMP: any;
  valueRadioTEMPEA: any;
  valueRadioVPL: any;
  valueRadioVPLEA: any;
  valueRadioOV: any;

  valueMsj = false;

  @ViewChild('comparar')
  comparadorModal: TemplateRef<any>;

  // arrayListPlan: Array<PLANLIST> = [];

  /**
   * variable para almacenar el valor para el radio button de retito
   *  @property
   * @type {BehaviorSubject<Prospectu>}
   * @default false
   * */
  retiroValue = this.factoresD.retiroValue;
  /**
   * variable para almacenar el valor de la calidacion de aliados
   *  @property
   * @type {BehaviorSubject<Prospectu>}
   * @default false
   * */
  aliadosValue = new BehaviorSubject<boolean>(false);

  /**
   * Modal Loader
   * @property
   */
  @ViewChild('loader')
  loader: TemplateRef<any>;

  /**
   * Variables para detectar la conexión online/offline
   * @property
   */
  online$ = Observable.merge(
    of(navigator.onLine),
    fromEvent(window, 'online').pipe(mapTo(true)),
    fromEvent(window, 'offline').pipe(mapTo(true))
  );
  /**
   * Desubcriptor
   * @private
   * @property
   */
  private ngUnsubscribe = new Subject();

  // Modal de ajustes de variables
  /**
   *  Referencia del modal (bootstrap).
   * @property
   * @type {BsModalRef}
   */
  bsModalRef: BsModalRef;
  /**
   * Referencia del modal
   * @property
   * @type {BsModalRef}
   */
  modalRef: BsModalRef;

  // Plegables de coberturas
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsed = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedOp = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedB = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedBI = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedPAI = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedCC = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedDI = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedDIPO = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedDIPOC = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedIAC = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedPAE = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedPCD = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedGF = false;

  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedBAIProC = false;

  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedIACIProC = false;

  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedBAMProCE = false;

  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedIACMProC = false;

  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedPAIIProE = false;

  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedPOIProE = false;

  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedPAIMProE = false;

  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedPOMProcE = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedBAUni = false;
/**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedKdMen = false;
  isCollapsedKdTut = false;
/**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedECMI = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedPAIUni = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedDIUni = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedDIPOCUni = false;
  /**
   * Variable de estado. (Collapse)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isCollapsedCDC = false;
  /**
  * Variable de estado. (Collapse)
  * @property
  * @type {boolean}
  * @default "false"
  */
  isCollapsedGFI = false;
  /**
  * Variable de estado. (Collapse)
  * @property
  * @type {boolean}
  * @default "false"
  */
  isCollapsedGFC = false;
  /**
  * Variable de estado. (Collapse)
  * @property
  * @type {boolean}
  * @default "false"
  */
  isCollapsedGFH = false;


  /**
   *  Variable que almacena el valor de estado por defecto de las coberturas, respecto a si están modificadas.
   * @property
   */
  isModified = {
    PAI: false,
    CC: false,
    DI: false,
    DIPO: false,
    DIPOC: false,
    IAC: false,
    PAE: false,
    PAIU: false,
    DIU: false,
    DIPOCU: false,
    CDC: false
  };

  /**
   *  Constante que almacena las opciones de plan.
   * @property
   */
  tipodeplanes = this.protectionPlansGenerator.products.value;

  /**
   *  Constante que almacena el tipo de moneda.
   * @property
   */
  tipodecambio = this.protectionPlansGenerator.currencies.value;

  /**
   * Array inmutable con los planes de protección.
   * @type {FrontTerms[]}
   * @memberof BuildPlanComponent
   */
  plazos: FrontTerms[];

  // Cobertura D[I, IPO, IPOC] activa
  /**
   *  Cobertura actual.
   * @type {Coverage}
   * @default "DI"
   * @memberof BuildPlanComponent
   */
  actualCoverage: Coverage = 'DI';

  /**
   * Variable de estado (Scroll)
   * @property
   * @type {boolean}
   * @default "false"
   */
  isScrolled = false;
  /**
   * Variable de estado (Componente Payment-Frequency Scroll)
   * @property
   * @type {boolean}
   * @default "false"
   */
  scrollPaymentFrequency = false;
  /**
   * Variable de estado (Mostrar niveladas).
   * @property
   * @type {boolean}
   */
  showNiveladas: boolean;

  // ** OPCIONES DE PROTECCIÓN ////////////////////////////////

  // BASICA (Fallecimiento)
  /**
   * Suma Asegurada en UDI
   * @property
   * @type {string}
   */
  BASICA_sumaAseguradaUDI: string;
  /**
   * Suma Asegurada en Pesos
   * @property
   * @type {number}
   */
  BASICA_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI
   * @property
   * @type {number}
   */
  BASICA_primaAnualUDI: number;
  /**
   * Prima anual en Pesos
   * @property
   * @type {number}
   */
  BASICA_primaAnualPesos: number;

  BASICA_sumaAseguradaMasivo_1: string;
  BASICA_sumaAseguradaMasivo_2: string;
  BASICA_sumaAseguradaMasivo_3: string;

  BASICA_sumaAsegurada_Universal: string;
  BASICA_sumaAsegurada_Universal_usd: string;

  // BASICA_sumaAseguradaPesos_Universal: string;
  // BASICA_sumaAseguradaDolares_Universal: string;
  BASICA_primaAnualMXN_Universal: string;
  // BASICA_primaAnualUSD_Universal: string;


  // BI (Exención de pago de primas por invalidez total y permanente)
  /**
   * Suma Asegurada en UDI
   * @property
   * @type {CoverageStatus}
   */
  BI_sumaAseguradaUDI: CoverageStatus;
  /**
   * Suma Asegurada en Pesos
   * @property
   * @type {CoverageStatus}
   */
  BI_sumaAseguradaPesos: CoverageStatus;
  /**
   * Prima anual en UDI
   * @property
   * @type {number}
   */
  BI_primaAnualUDI: number;
  /**
   * Prima anual en Pesos
   * @property
   * @type {number}
   */
  BI_primaAnualPesos: number;

  ECMI_sumaAsegurada_Universal: CoverageStatus;
  // ECMI_sumaAseguradaDolares_Universal: string;
  ECMI_primaAnualMXN_Universal: string;
  // ECMI_primaAnualUSD_Universal: string;

  // PAI (Pago adicional por invalidez total y permanente)
  /**
   * Suma Asegurada en UDI.
   * @property
   * @type {string}
   */
  PAI_sumaAseguradaUDI: string;
  /**
   * Suma Asegurada en Pesos.
   * @property
   * @type {number}
   */
  PAI_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  PAI_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  PAI_primaAnualPesos: number;

  PAI_sumaAseguradaPesos_Universal: string;
  PAI_sumaAseguradaDolares_Universal: string;
  PAI_primaAnualMXN_Universal: string;
  // PAI_primaAnualUSD_Universal: string;

  // DI (Muerte accidental)
  /**
   * Suma Asegurada en UDI.
   * @property
   * @type {string}
   */
  DI_sumaAseguradaUDI: string;
  /**
   * Suma Asegurada en Pesos.
   * @property
   * @type {number}
   */
  DI_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  DI_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  DI_primaAnualPesos: number;

  DI_sumaAseguradaMXN_Universal: string;
  DI_sumaAseguradaUSD_Universal: string;
  DI_primaAnualMXN_Universal: string;
  // DMI_primaAnualUSD_Universal: string;

  // DIPO (Muerte Accidental y Pérdidas Orgánicas)
  /**
   * Suma asegurada en UDI.
   * @property
   * @type {string}
   */
  DIPO_sumaAseguradaUDI: string;
  /**
   * Suma asegurada en Pesos.
   * @property
   * @type {number}
   */
  DIPO_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  DIPO_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  DIPO_primaAnualPesos: number;

  // DIPOC (Muerte Accidental y Pérdidas Orgánicas y Muerte Colectiva)
  /**
   * Suma Asegurada en UDI.
   * @property
   * @type {string}
   */
  DIPOC_sumaAseguradaUDI: string;
  /**
   * Suma Asegurada en Pesos.
   * @property
   * @type {number}
   */
  DIPOC_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  DIPOC_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  DIPOC_primaAnualPesos: number;

  DIPOC_sumaAseguradaMXN_Universal: string;
  DIPOC_sumaAseguradaUSD_Universal: string;
  DIPOC_primaAnualMXN_Universal: string;
  // DIPOC_primaAnualUSD_Universal: string;

  // IAC (Indemnización adicional por cáncer)
  /**
   * Suma asegurada en UDI.
   * @property
   * @type {string}
   */  
  CDC_sumaAseguradaMXN_Universal: string;
  CDC_sumaAseguradaUSD_Universal: string;

  /**
   * Suma asegurada en Pesos.
   * @property
   * @type {number}
   */
  IAC_sumaAseguradaUDI: string;
  IAC_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  IAC_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  IAC_primaAnualPesos: number;

  IAC_sumaAseguradaMasivo_1: string;
  IAC_sumaAseguradaMasivo_2: string;
  IAC_sumaAseguradaMasivo_3: string;

  // PAE (Pago adicional por enfermedades graves)
  /**
   * Suma Asegurada en UDI.
   * @property
   * @type {string}
   */
  PAE_sumaAseguradaUDI: string;
  /**
   * Suma Asegurada en Pesos.
   * @property
   * @type {number}
   */
  PAE_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  PAE_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  PAE_primaAnualPesos: number;

  // CC (Cobertura Conyugal)
  /**
   * Suma Asegurada en UDI.
   * @property
   * @type {string}
   */
  CC_sumaAseguradaUDI: string;
  /**
   * Suma Asegurada en Pesos.
   * @property
   * @type {number}
   */
  CC_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  CC_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  CC_primaAnualPesos: number;

  // PCD (Protección continua por desempleo)
  /**
   * Suma Asegurada en UDI.
   * @property
   * @type {CoverageStatus}
   */
  PCD_sumaAseguradaUDI: CoverageStatus;
  /**
   * Suma Asegurada en Pesos.
   * @property
   * @type {CoverageStatus}
   */
  PCD_sumaAseguradaPesos: CoverageStatus;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  PCD_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  PCD_primaAnualPesos: number;

  // GF (Gastos Funerarios)
  /**
   * Suma Asegurada en UDI.
   * @property
   * @type {number}
   */
  GF_sumaAseguradaUDI: number;
  /**
   * Suma Asegurada en Pesos.
   * @property
   * @type {number}
   */
  GF_sumaAseguradaPesos: number;
  /**
   * Prima anual en UDI.
   * @property
   * @type {number}
   */
  GF_primaAnualUDI: number;
  /**
   * Prima anual en Pesos.
   * @property
   * @type {number}
   */
  GF_primaAnualPesos: number;

  VPL_sumaAsegurada_PrimeraMoneda: string;
  VPL_primaAnual_PrimeraMoneda: number;

  CDC_sumaAsegurada_PrimeraMoneda: string;
  CDC_primaAnual_PrimeraMoneda: number;
  GFI_sumaAseguradaPesos_Universal: number;
  GFI_sumaAseguradaDolares_Universal: number;
  GFI_primaAnualMXN_Universal: string;
  // GFI_primaAnualUSD_Universal: string;

  GFC_sumaAseguradaPesos_Universal: number;
  GFC_sumaAseguradaDolares_Universal: number;
  GFC_primaAnualMXN_Universal: string;
  // GFC_primaAnualUSD_Universal: string;

  GFH_sumaAseguradaPesos_Universal: number;
  GFH_sumaAseguradaDolares_Universal: number;
  GFH_primaAnualMXN_Universal: string;
  // GFH_primaAnualUSD_Universal: string;

  TUTOR_sumaAseguradaUDI: string;
  TUTOR_sumaAseguradaPesos: number;
  TUTOR_primaAnualUDI: number;
  TUTOR_primaAnualPesos: number;

  EFI_sumaAseguradaUDI: string;
  // EFI_coberturaInfo: string;
  EFI_sumaAseguradaPesos: string;
  EFI_primaAnualUDI: number;
  EFI_primaAnualPesos: number;

  /**
   *  Inputs seleccionado en cobertura básica.
   * @property
   * @memberof BuildPlanComponent
   */
  inputSelected = {
    fallecimiento: {
      A: true,
      B: false,
      C: false,
      D: false
    }
  };

  /**
   *  Incrementos programados - Variable de estado.
   * @type {boolean}
   * @memberof BuildPlanComponent
   */
  incrementosProgramados: boolean;

  /**
   *Valor de la UDI
   * @type {number}
   * @memberof BuildPlanComponent
   */
  UDI_VALUE: number;
  /**
   *  Suscripción del valor de la UDI.
   * @type {Subscription}
   * @memberof BuildPlanComponent
   */
  UDI_VALUE$: Subscription;

  /**
   *  Arreglo que almacena las diversas inflaciones.
   * @type {{ title: string; value: number }[]}
   * @memberof BuildPlanComponent
   * @default "[ ]"
   */
  inflaciones: { title: string; value: number }[] = [];
  /**
   *  Inflación de UDI.
   * @property
   * @memberof BuildPlanComponent
   */
  inflacion_udi = this.protectionPlansGenerator.inflations.value;

  /**
   *  Plazos de pago por defecto.
   * @property
   * @default "99"
   * @memberof BuildPlanComponent
   */
  plazosPagos = 99;

  /**
   * Plazo asegurado seleccionado
   * @property
   * @type {number}
   * @memberof BuildPlanComponent
   */
  plazosAsegurados: number;
  /**
   * Variable de estado (Dispositivo iPad)
   * @property
   * @type {boolean}
   */
  ipadDevice: boolean;
  /**
   * Dispositivo.
   * @property
   */
  device = '';
  /**
   * Dispositivo Desktop
   * @property
   */
  isDesktop: boolean;
  /**
   * Mensaje de error - Cobertura PAI
   * @property
   */
  messageErrorPai = '';
  /**
   * Validación cobertura PAI - Variable de estado.
   * @property
   * @type {boolean}
   */
  valPai: boolean;
  /**
   * Mensaje de error - Cobertura Básica.
   * @property
   */
  messageErrorBasica = '';
  messageErrorBasicaU = '';
  messageErrorBasicaTut = '';
  /**
   * Validación cobertura Básica - Variable de estado.
   * @property
   * @type {boolean}
   * @default "false"
   */
  validBasic = false;
  validBasicU = false;
  validBasicTut = false;
  /**
   * Mensaje de error - Cobertura CC
   * @property
   */
  messageErrorCC = '';
  /**
   * Validación cobertura CC - Variable de estado.
   * @property
   * @type {boolean}
   */
  valCC: boolean;
  /**
   * Mensaje de error - Cobertura DI
   * @property
   */
  messageErrorDI = '';
  /**
   * Validación cobertura DI - Variable de estado.
   * @property
   * @type {boolean}
   */
  valDI: boolean;
  /**
   * Validación cobertura DIPO - Variable de estado.
   * @property
   * @type {boolean}
   */
  valDIPO: boolean;
  /**
   * Mensaje de error  - Cobertura DIPO.
   * @property
   */
  messageErrorDIPO = '';
  /**
   * Validación cobertura DIPOC - Variable de estado.
   * @property
   */
  valDIPOC: boolean;
  /**
   * Mensaje de error - Cobertura DIPOC.
   * @property
   */
  messageErrorDIPOC = '';
  /**
   * Validación cobertura IAC.
   * @property
   * @type {boolean}
   */
  valIAC: boolean;
  /**
   * Mensaje de error - Cobertura IAC.
   * @property
   */
  messageErrorIAC = '';
  /**
   * Validación cobertura PAE
   * @property
   * @type {boolean}
   */
  valPAE: boolean;
  /**
   * Mensaje de error - Cobertura PAE.
   * @property
   */
  messageErrorPAE = '';
  valVPL: boolean;
  messageErrorVPL = '';
  valCDC: boolean;
  messageErrorCDC = '';

  /**
   * Prospecto. Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<Prospectu>}
   * @default "new Prospectu()"
   */
  prospecto = new BehaviorSubject<Prospectu>(new Prospectu());

  // * Mascara de suma asegurada en UDI
  /**
   * Máscara de suma asegurada en UDI
   * @property
   */
  sumaAseguradaMask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    decimalLimit: 0
  });

  /**
   * Máscara de suma asegurada en MXN
   * @property
   */
  sumaAseguradaPesosMask = createNumberMask({
    prefix: '$ ',
    thousandsSeparatorSymbol: ',',
    suffix: '',
    allowDecimal: false
  });

  // * Switch de coberturas DI, DIPO y DIPOC
  /**
   * Variable de estado (Empleada en el switch)
   * @property
   * @type {boolean}
   */
  DI_COVERAGE: boolean;
  /**
   * Variable de estado (Empleada en el switch)
   * @property
   * @type {boolean}
   */
  DIPO_COVERAGE: boolean;
  /**
   * Variable de estado (Empleada en el switch)
   * @property
   * @type {boolean}
   */
  DIPOC_COVERAGE: boolean;
  /**
   * Variable de estado.
   * @property
   * @type {boolean}
   * @default "true"
   */
  valoresGListos = true;

  GFI_COVERAGE: boolean;
  GFC_COVERAGE: boolean;
  GFH_COVERAGE: boolean;

  /**
   *Esquemas de comisión (Frontend).
   * @property
   * @type {CommissionAssignmentFront[]}
   * @default "[]"
   */
  schemes: CommissionSchemeFront[] = [];
  /**
   *Asignación de comisión (Frontend).
   * @property
   * @type {CommissionAssignmentFront[]}
   * @default "[]"
   */
  assignments: CommissionAssignmentFront[] = [];
  /**
   *Porcentaje total de datos.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  totalDataPercentage = new BehaviorSubject<number>(0);

  mode: boolean;

  isMancomunado: boolean;

  isIndividual = !this.routes.isIndividual.value;

  sumasProtecancer = PROTECANCER_AMOUNTS;

  agePros: number;

  filteredAvailable: {
    plan: PlanType;
    terms: number[];
  }[] = this.protectionPlansGenerator.available.value.filter(val =>
    this.routes.chosenCotizador.value === 'Aliados'
      ? val.plan === 'DOTAL'
      : val.plan === 'VPL' || val.plan === 'TEMPORAL' || val.plan === 'OV'
  );

  btnDisabled =
    this.filteredAvailable[0] !== undefined &&
      ((this.routes.chosenCotizador.value === 'Aliados' &&
        this.filteredAvailable[0].terms.length > 1) ||
        (this.routes.chosenCotizador.value === 'ProTGT' &&
          this.proTGTPlansAvailableOffline(this.filteredAvailable)))
      ? true
      : false;

  infla_inpc: { title: string; value: number }[] = [];
  infla_inpc_uni_mxn: { title: string; value: number }[] = [];
  infla_inpc_uni_usd: { title: string; value: number }[] = [];
  valorDefault: number;
  /**
   * Se crea una instancia de build-plan.component.
   * Declaración de variables.
   * @param opciones Se crea una instancia de OpcionesPlanService
   * @param calculos Se crea una instancia de CalculosService
   * @param recursosExternos Se crea una instancia de RecursosExternosService
   * @param currencyPipe Se crea una instancia de CurrencyToNumberPipe
   * @param router Se crea una instancia de Router
   * @param modalService Se crea una instancia de BsModalService
   * @param deviceType Se crea una instancia de DeviceTypeService
   * @param validationType Se crea una instancia de ValidationTypeService
   * @param protectionPlansGenerator Se crea una instancia de ProtectionPlansGeneratorService
   * @param excedentes Se crea una instancia de ExcedentesService
   * @param scrollPercent Se crea una instancia de ScrollPercentageDetectionService
   * @param resizeDetection Se crea una instancia de ResizeDetectionService
   * @param generadorTabla Se crea una instancia de GeneradorTablaValoresService
   * @param variableConfiguration Se crea una instancia de VariableConfigurationService
   * @param prospectu Se crea una instancia de ProspectoService
   * @param bd Se crea una instancia de BaseDatosService
   */
  constructor(
    public opciones: OpcionesPlanService,
    public calculos: CalculosService,
    private recursosExternos: RecursosExternosService,
    private currencyPipe: CurrencyToNumberPipe,
    private router: Router,
    private modalService: BsModalService,
    private deviceType: DeviceTypeService,
    private validationType: ValidationTypeService,
    private protectionPlansGenerator: ProtectionPlansGeneratorService,
    private excedentes: ExcedentesService,
    public scrollPercent: ScrollPercentageDetectionService,
    public resizeDetection: ResizeDetectionService,
    private generadorTabla: GeneradorTablaValoresService,
    private variableConfiguration: VariableConfigurationService,
    private prospectu: ProspectoService,
    private bd: BaseDatosService,
    private factoresD: FactoresService,
    public routes: RoutesService,
    public pdfmR: PdfMiProyectoR,
    public pdfvi: PdfVidaInteligente,
    public univ: CalculosUniversalesService,
    private univExcd: CalculosExcedentesUniversalesService
  ) {
    this.opciones.screen.next(2);
    this.modalService.onHidden
      .delay(2000)
      .takeUntil(componentDestroyed(this))
      .subscribe(() => {
        if (this.modalService.config.class === 'loader-modal') {
          this.modalService.hide(1);

          if (document.body.classList.contains('modal-open')) {
            document.body.classList.remove('modal-open');
          }
        }
      });
    if(this.opciones.tipoPlan !== 'PROTECANCER'){
      this.calculos.resetCalculus();
      this.univ.borrarCoberturas();
    }
    this.univExcd.borrarAportaciones();
    switch (true) {
      case this.routes.chosenCotizador.value === 'Aliados' &&
        this.routes.firstTimeChoose.value:
        this.setAliados();
        this.routes.firstTimeChoose.next(false);
        this.recursosExternos.moneda = 'UDI';
        this.recursosExternos.segundaMoneda = 'MXN';
        this.calculos.monedaUsada.next('UDI');
        break;
      case this.routes.chosenCotizador.value === 'ProTGT' &&
        this.routes.firstTimeChoose.value:
        this.opciones.tipoPlan = 'VPL';
        this.opciones.plan = 'ProTGT';
        this.opciones.plazoSeguro = 99;
        this.opciones.plazoPago.next(
          this.protectionPlansGenerator.getDefaultTerm(DEFAULT_PLANTYPE)
        );
        this.valuePlan = 'VPL';
        this.routes.firstTimeChoose.next(false);
        this.recursosExternos.moneda = 'UDI';
        this.recursosExternos.segundaMoneda = 'MXN';
        this.calculos.monedaUsada.next('UDI');
        break;
      case this.routes.chosenCotizador.value === 'AxaParaTodos' &&
        this.routes.firstTimeChoose.value:
        this.opciones.tipoPlan = 'PROTECCION EFECTIVA';
        this.opciones.plan = 'AxaParaTodos';
        this.opciones.moneda = 'MXN';
        this.opciones.plazoSeguro = 1;
        this.opciones.plazoPago.next(1);
        this.valuePlan = 'Protección Efectiva';
        this.routes.firstTimeChoose.next(false);
        this.recursosExternos.moneda = 'MXN';
        this.recursosExternos.segundaMoneda = 'UDI';
        this.calculos.monedaUsada.next('MXN');
        break;
      case this.routes.chosenCotizador.value === 'Universales' &&
        this.routes.firstTimeChoose.value:
        this.calculos.monedaUsada.next('MXN');
        this.opciones.tipoPlan = 'VIDA INTELIGENTE';
        this.opciones.plan = 'Universales';
        this.opciones.moneda = 'MXN';
        this.opciones.plazoSeguro = 90;
        this.opciones.plazoPago.next(90);
        this.valuePlan = 'Vida Inteligente';
        this.opciones.inpcUniversal.next(false);
        this.routes.firstTimeChoose.next(false);
        this.incrementosProgramados = false;
        this.opciones.incrementosProgramados.next(false);
        this.opciones.inpcUniversal.next(false);
        this.opciones.gfiIncluido.next(false);
        this.opciones.gfcIncluido.next(false);
        this.opciones.gfhIncluido.next(false);
        break;
    }

    this.bd.totalDataPercentage.subscribe(data => {
      this.totalDataPercentage.next(data);
    });

    this.bd.cotizacionGuardada.next(false);

    this.online$.subscribe(status => {
      // Genera tipos de planes
      this.protectionPlansGenerator.productsGenerator(this.opciones.tipoPlan);
      const PG_Products = this.protectionPlansGenerator.products;
      PG_Products.subscribe(products => {
        this.opciones.tipodeplanes = products;

      
          this.tipodeplanes = products;
      });
      // Moneda
      this.protectionPlansGenerator.currenciesGenerator(
        this.opciones.moneda,
        this.opciones.tipoPlan
      );
      this.protectionPlansGenerator.currencies.subscribe(currencies => {
        this.opciones.tipodecambio = currencies;
        this.tipodecambio = currencies;
      });
      // Plazo pagos
      this.protectionPlansGenerator.generatePlansFront(
        this.opciones.tipoPlan,
        this.opciones.plazoPago.value,
        this.opciones.moneda
      );
      this.protectionPlansGenerator.NewTermsToExport.subscribe(plazo => {
        this.opciones.plazos = plazo.terms;
        this.plazos = plazo.terms;
      });
    });

    this.prospectu.prospecto.subscribe(() => {
      this.protectionPlansGenerator.sugerenciaPlazos(
        this.opciones.plazoPago.value
      );
      this.protectionPlansGenerator.generatePlansFront(
        this.opciones.tipoPlan,
        this.opciones.plazoPago.value,
        this.opciones.moneda
      );
      this.protectionPlansGenerator.NewTermsToExport.subscribe(plazo => {
        this.opciones.plazos = plazo.terms;
        this.plazos = plazo.terms;
      });
    });
    this.prospectu.prospecto.subscribe(age => {
      this.agePros = age.age;
    });
    this.showNiveladas = true;

    // * Se establece el valor de la UDI desde los recursos externos
    this.UDI_VALUE = this.recursosExternos.valor_UDI;
    // * Inflaciones anuales por defecto
    this.inflaciones = this.protectionPlansGenerator.inflations.value;
    this.infla_inpc = this.protectionPlansGenerator.inf_inpc.value;
    this.infla_inpc_uni_mxn = this.protectionPlansGenerator.inf_inpc_universal_mxn.value;
    this.infla_inpc_uni_usd = this.protectionPlansGenerator.inf_inpc_universal_usd.value;

    // * Ninguna cobertura D, seleccio nada
    this.DI_COVERAGE = true;
    this.DIPO_COVERAGE = true;
    this.DIPOC_COVERAGE = true;
    this.protectionPlansGenerator.generatePlansFront(
      this.opciones.tipoPlan || 'VPL',
      this.opciones.plazoPago.value,
      this.opciones.moneda
    );

    // * Se llama al método que genera los factores al iniciar, para que ya se pueda cotizar desde el inicio con los valores predeterminados.
    this.initConstructor();
  }

  /**
   * Genera los factores al iniciar, para que ya se pueda cotizar desde el inicio con los valores predeterminados
   * @method
   */
  async initConstructor() {
    this.validaAlidos65(this.opciones.tipoPlan, String(this.opciones.plazoSeguro));
      const vp = this.prospectu.prospecto.value;
      if (vp.age <= 60 && this.opciones.tipoPlan === 'DOTAL') {
        this.factoresD.obtenerFactorDedicibleAliasdosMas65(vp);
      }

    let edadEqui;
    if (
      this.prospectu.prospecto.value.mancomunado !== undefined &&
      this.prospectu.prospecto.value.mancomunado.mancomunado &&
      this.routes.chosenCotizador.value !== 'Universales'
    ) {
      this.validaRetiro = false;
      this.aliadosValue.next(false);
      const antProsp = this.prospectu.prospecto.value;
      await this.bd
        .getEquivalentAge(
          String(antProsp.age),
          String(antProsp.mancomunado.ageMancomunado),
          this.opciones.plan
        )
        .then(info => (edadEqui = info.docs[0].EdadEquivalente));
      antProsp.mancomunado.edadEquivalente = edadEqui;
      this.prospectu.changeProspecto(antProsp);
    }
    if (
      this.prospectu.prospecto.value.mancomunado !== undefined &&
      this.prospectu.prospecto.value.mancomunado.mancomunado &&
      this.routes.chosenCotizador.value === 'Universales'
    ) {
      this.univ.obtenerEdades();
    }
    Promise.all([this.opciones.tipoPlan === 'MUJER UNICA' ?
      this.opciones.generarFactoresMujerUnica() : this.opciones.generarFactores(true)]).then(() => {
        this.calculos.changeTerm.distinctUntilChanged().subscribe(value => {
          if (value.changed) {
            this.changeTerm(value.plazo);
            this.calculos.changeTerm.next({ changed: false, plazo: undefined });
          } else {
            if (value.plazo === 0) {
              this.changeTerm(15);
              this.calculos.changeTerm.next({ changed: false, plazo: undefined });
            }
          }
        });
        this.calculos.COBERTURA_BASICA(0);

        if (this.opciones.tipoPlan === 'MUJER UNICA') {
          this.selectMujerUnica();
        }else if( this.opciones.tipoPlan === 'ALIADOSKIDS'){
          this.calculaCoberturasKids();
        }

        this.modalRef.hide(); //TODO:ALIADOSKIDS --Se comento linea
      });
    // Para universales
    Promise.all([this.opciones.generarFactores(true)]).then(() => {
      const amount = this.obtenerSumAsegUniversales();
      const valor = this.BASICA_sumaAsegurada_Universal === '' || isNaN(Number(this.BASICA_sumaAsegurada_Universal)) ||
        this.BASICA_sumaAsegurada_Universal === undefined || this.BASICA_sumaAsegurada_Universal === '0'
        ? this.currencyPipe.transform(amount)
        : this.BASICA_sumaAsegurada_Universal;
      this.calculos.COBERTURA_BASICA_UNIVERSAL(Number(valor));
    });
  }


  private setAliados(){
    let v_tPlan: PlanType, v_plan: Plan, v_vPlan: string, v_pSeg: number, v_pPago: number;
    if(!this.opciones.prospecto.kids.kids){
      v_tPlan = 'DOTAL';
      v_vPlan = 'DOTAL'
      v_plan  = 'Aliados+';
      v_pSeg  = 15;
      v_pPago = 15;
    }else{
      let plazo = (this.opciones.prospecto.kids.ageKids <= 8 ? 18 : 22);
      v_tPlan = 'ALIADOSKIDS';
      v_vPlan = 'DOTAL'
      v_plan  = 'Aliados+';
      v_pSeg  = plazo;
      v_pPago = plazo;
    }
    this.opciones.tipoPlan    = v_tPlan;
    this.opciones.plan        = v_plan;
    this.valuePlan            = v_vPlan;
    this.opciones.plazoSeguro = v_pSeg;
    this.opciones.plazoPago.next(v_pPago);    
}

  private calculaCoberturasKids(){
    this.calculos.COBERTURA_TUTOR(0);
    if( this.prospecto.value.age <= 55 ){
      this.calculos.COBERTURA_EFI(Number(this.calculos.TUTOR.primaAnual_UDI.value) + Number(this.calculos.BASICA_primaAnualUDI.value));
    }else{
      this.calculos.COBERTURA_EFI(0);
    }       
  }
  /**
   * Método que detecta cuando se lleva a cabo el evento scroll y retorna un valor con el cual se le aplica un estilo a un componente interno.
   * @param $event
   */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const scrollTop = $event.target.scrollingElement.scrollTop;
    const BREAKPOINT = 207;

    const BREAKPOINT_STUFF_RIGHT = 184;

    this.isScrolled = scrollTop > BREAKPOINT;
    this.scrollPaymentFrequency = scrollTop >= BREAKPOINT_STUFF_RIGHT;
  }

  /**
   * Método que Limpia el input.
   * @param input
   */
  cleanInput(input: HTMLInputElement) {
    if (input.value === '0') {
      input.value = '';
    }
  }

  // ************************/
  /**
   * Inicialización de variables.
   */
  ngOnInit() {
    this.factoresD.valorCkRetio = false;
    this.excedentes.setValue(this.opciones.moneda);
    window.scroll(0, 0);
    this.protectionPlansGenerator.productsGenerator(this.opciones.tipoPlan);
    this.protectionPlansGenerator.products.subscribe(products => {
      this.opciones.tipodeplanes = products;
      
        this.tipodeplanes = products;
    });
    // Moneda
    this.protectionPlansGenerator.currenciesGenerator(
      this.opciones.moneda,
      this.opciones.tipoPlan
    );
    this.protectionPlansGenerator.currencies.subscribe(currencies => {
      this.opciones.tipodecambio = currencies;
    });
    // Plazo pagos
    this.protectionPlansGenerator.generatePlansFront(
      this.opciones.tipoPlan,
      this.opciones.plazoPago.value,
      this.opciones.moneda
    );
    this.protectionPlansGenerator.NewTermsToExport.subscribe(plazo => {
      this.opciones.plazos = plazo.terms;
      this.plazos = this.opciones.plazos;
    });
    // Inflacion Anual
    this.protectionPlansGenerator.inflationsGenerator(
      this.excedentes.inflacion_udi.value
    );

    this.protectionPlansGenerator.inflationsINPCGenerator(
      this.calculos.inflacion_inpc.value
    );

    this.protectionPlansGenerator.inflationsINPCUniversalGenerator(
      this.calculos.inflacion_inpc_mxn.value
    );

    this.protectionPlansGenerator.inflationsIPCUniversalGenerator(
      this.calculos.inflacion_inpc_usd.value
    );

    this.protectionPlansGenerator.inflations.subscribe(inflations => {
      this.inflaciones = inflations;
    });
    this.protectionPlansGenerator.inf_inpc.subscribe(inpcInfla => {
      if (this.opciones.incrementoInpcPorc.value === 0) {
        const defaultInflaINPC = inpcInfla.filter(inf => inf.selected)[0].value;
        this.infla_inpc = inpcInfla;
        this.establecerInflacionINPC(defaultInflaINPC);
      }

    });
    this.protectionPlansGenerator.inf_inpc_universal_mxn.subscribe(inpcUni => {
      this.infla_inpc_uni_mxn = inpcUni;
    });
    this.protectionPlansGenerator.inf_inpc_universal_usd.subscribe(inpcUniUSD => {
      this.infla_inpc_uni_usd = inpcUniUSD;
    });
    this.generadorTabla.tabla_Actual.next([]);

    this.device = this.deviceType.getDeviceType();

    if (this.device === 'iPad') {
      this.ipadDevice = true;
    }

    if (this.device === 'Desktop') {
      this.isDesktop = true;
    }
    if (this.opciones.plan !== 'AxaParaTodos') {
      this.opciones.generarFactoresValoresG(true);
    }

    // ** Inicializacion de variables

    if (this.opciones.tipoPlan === 'DOTAL') {
      this.plazos[0].default = true;

      this.plazosAsegurados =
        this.plazos.filter(item => item.plan === 'DOTAL' && item.default)[0]
          .value ||
        PROTECTION_TERMS.filter(plan => plan.plan === this.opciones.tipoPlan)[0]
          .safeTerm;
    } else {
      this.plazosAsegurados = PROTECTION_TERMS.filter(
        plan => plan.plan === this.opciones.tipoPlan
      )[0].safeTerm;
    }

    if (
      this.opciones.tipoPlan === 'TEMPORAL' ||
      this.opciones.tipoPlan === 'DOTAL'
    ) {
      this.plazosAsegurados = this.opciones.plazoPago.value;
    }
    if(this.opciones.tipoPlan === 'ALIADOSKIDS'){
      this.plazosAsegurados = this.protectionPlansGenerator.NewTermsToExport.value.terms[0].value;
    }

    this.opciones.plazosAsegurados = this.plazosAsegurados;

    this.UDI_VALUE$ = this.recursosExternos
      .getUdiValue()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(data => (this.UDI_VALUE = data));

    this.initCalculos();

    this.excedentes.esquema_comision.subscribe(() => {
      this.schemes = this.variableConfiguration.generateCommissionSchemes();
    });
    this.excedentes.cesion_comision.subscribe(() => {
      this.assignments = this.variableConfiguration.generateCommissionAssignment();
    });

    this.opciones.generateProposals.next(true);
    this.prospectu.prospecto.subscribe(
      pros => (this.isMancomunado = pros.mancomunado.mancomunado)
    );
    this.establecerPrimasUniversales();
    this.selectProtecancer();
  }

  /**
   * Desuscribe al componente cuando éste es destruido.
   */
  ngOnDestroy() {
    this.ngUnsubscribe.unsubscribe();
  }

  // ************************/

  // ** FUNCIONES DE FRONT //
  // ? OPCIONES DE PLAN //

  /**
   * Método que permite cambiar el plazo del seguro.
   * @param anios
   * @method
   */
  changePlazoSeguro(anios: string) {
    const regex = new RegExp('[^\\d]', 'g');
    const nuevoPlazo = Number(
      anios
        .replace(regex, '')
        .trim()
        .substr(0, 2)
    );

    this.modalRef = this.modalService.show(this.loader, {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'loader-modal'
    });

    if (nuevoPlazo > 0 && nuevoPlazo < 99) {
      this.opciones.plazoPago.next(nuevoPlazo);
      this.opciones.plazosPagos = [nuevoPlazo];
      Promise.all([
        this.opciones.generarFactores(),
        this.opciones.generarFactoresValoresG()
      ]).then(() => {
        this.calculos.RECALCULAR(true);
        this.modalRef.hide();
      });
    }
    this.validaAlidos65(
      this.opciones.tipoPlan,
      String(this.opciones.plazoSeguro)
    );
  }
  /**
   * Cambiar plan.
   * @param value
   * @method
   */
  changePlan(value: PlanType) {
    this.excedentes.aportacion_anual_UDI.next(0);
    this.excedentes.primerAnioAportacion.next(1);
    this.excedentes.segundoAnioAportacion.next(2);
    this.excedentes.montoAdicionalInput.next(0);

    this.valuePlan = value;
    this.boolRetiro = false;
    this.validaRetiro = false;

    this.opciones.tipoPlan = value;

    switch (value) {
      case 'DOTAL':
        this.opciones.moneda = 'UDI';
        this.opciones.segundaMoneda = 'MXN';
        this.showNiveladas = false;
        // Se limpian valores de primas de coberturas no disponibles en este plan
        this.calculos.CC.resetValues();
        this.calculos.DI.resetValues();
        this.calculos.DIPO.resetValues();
        this.calculos.DIPOC.resetValues();
        this.calculos.IAC.resetValues();
        this.calculos.PAE.resetValues();
        this.calculos.GF.resetValues();
        break;
      case 'PROTECCION EFECTIVA':
        this.BASICA_sumaAseguradaUDI = String(100000);
        this.changeBasica();
        this.calculos.IAC.resetValues();
        break;
      default:
        this.showNiveladas = true;
    }

    this.modalRef = this.modalService.show(this.loader, {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'loader-modal'
    });

      this.opciones.plazoPago.next(
        DEFAULT_TERMS.filter(res => res.plan === value)[0].default || 0
      );
      this.opciones.tipoPlan_Ob.next(value);

    this.protectionPlansGenerator.selectedPlan.next(
      this.opciones.plazoPago.value
    );

    this.schemes = this.variableConfiguration.generateCommissionSchemes();

    this.plazosAsegurados = PROTECTION_TERMS.filter(
      plan => plan.plan === this.opciones.tipoPlan
    )[0].safeTerm;
    this.protectionPlansGenerator.productsGenerator(value);
    this.checkIsValidTerm();

    if (this.opciones.tipoPlan === 'OV' && this.opciones.moneda === 'USD'){
      this.opciones.moneda = 'UDI';
    }

    if (this.opciones.tipoPlan === 'OV' && this.opciones.moneda === 'USD'){
      this.opciones.moneda = 'UDI';
    }
    
    this.tipodecambio = this.protectionPlansGenerator.currenciesGenerator(
      this.opciones.moneda,
      this.opciones.tipoPlan
    );
    this.opciones.plazosAsegurados = this.plazosAsegurados;
    this.opciones.plazoSeguro = this.plazosAsegurados;

    let arregloDeFactores: Promise<any>[] = [];

      if (this.opciones.plan === 'AxaParaTodos') {
        arregloDeFactores = [this.opciones.generarFactores()];
      } else if (this.opciones.tipoPlan === 'MUJER UNICA') {
        arregloDeFactores = [
          this.opciones.generarFactoresMujerUnica(),
          this.opciones.generarFactoresValoresGMujerUnica(),
          this.opciones.generarFactoresValoresG()
        ];
      } else {
        arregloDeFactores = [
          this.opciones.generarFactores(),
          this.opciones.generarFactoresValoresG()
        ];
      }
    Promise.all(arregloDeFactores).then(() => {
      this.calculos.RECALCULAR(true);

      this.selectProtecancer();
      if (this.opciones.tipoPlan === 'MUJER UNICA') {
        this.selectMujerUnica();
      } else {
        this.BASICA_sumaAseguradaMasivo_1 = String(100000);
        this.BASICA_sumaAseguradaMasivo_2 = String(100000);
        this.BASICA_sumaAseguradaMasivo_3 = String(100000);

        if (this.isIndividual) {
          this.changeAxaParaTodos(true);
        } else {
          this.changeAxaParaTodos(false);
        }
      }

      this.modalService.hide(1);
      this.modalRef.hide();
    });

    this.routes.chosenCotizador.next(this.routes.chosenCotizador.getValue());
  }

  private selectProtecancer() {
    if (this.opciones.tipoPlan === 'PROTECANCER') {
      if (this.isIndividual) {
        this.BASICA_sumaAseguradaMasivo_1 = String(
          this.sumasProtecancer[0].value
        );
        this.BASICA_sumaAseguradaMasivo_2 = String(
          this.sumasProtecancer[1].value
        );
        this.BASICA_sumaAseguradaMasivo_3 = String(
          this.sumasProtecancer[2].value
        );
        this.changeAxaParaTodos(true);
      } else {
        this.changeAxaParaTodos(false);
      }

      this.calculos.COBERTURA_IAC(0);
    }
  }

  private selectMujerUnica() {
    this.VPL_sumaAsegurada_PrimeraMoneda =
      this.CDC_sumaAsegurada_PrimeraMoneda =
      this.deleteCurrencySigns(this.BASICA_sumaAseguradaUDI);
    this.changeCDC();
    this.changeVPL();
    this.opciones.incrementosProgramados.next(false);

  }
  /**
   * Cambiar moneda.
   * @param value
   */
  changeCurrency(value: any) {
    this.excedentes.aportacion_anual_UDI.next(0);
    this.excedentes.primerAnioAportacion.next(1);
    this.excedentes.segundoAnioAportacion.next(2);
    this.excedentes.montoAdicionalInput.next(0);

    this.opciones.moneda = value;
    this.modalRef = this.modalService.show(this.loader, {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'loader-modal'
    });

    this.calculos.monedaUsada.next(value);

    switch (value) {
      case 'MXN':
        this.opciones.segundaMoneda = 'USD';
        this.recursosExternos.moneda = this.opciones.moneda;
        this.recursosExternos.segundaMoneda = this.opciones.segundaMoneda;
        this.changeActiveInput('fallecimiento', 'A');
        this.calculos.COBERTURA_BASICA(0);
        this.excedentes.setValue('MXN');
        break;
      case 'UDI':
        this.opciones.segundaMoneda = 'MXN';

        this.recursosExternos.moneda = this.opciones.moneda;
        this.recursosExternos.segundaMoneda = this.opciones.segundaMoneda;
        this.changeActiveInput('fallecimiento', 'A');
        this.calculos.COBERTURA_BASICA(0);
        this.excedentes.setValue('UDI');
        this.opciones.incrementosProgramados.next(false);
        break;
      case 'USD':
        this.opciones.segundaMoneda = 'MXN';
        this.recursosExternos.moneda = this.opciones.moneda;
        this.recursosExternos.segundaMoneda = this.opciones.segundaMoneda;
        this.changeActiveInput('fallecimiento', 'A');
        this.calculos.COBERTURA_BASICA(0);
        this.excedentes.setValue('USD');
        break;
    }
    this.checkIsValidTerm();
    Promise.all(this.opciones.tipoPlan !== 'MUJER UNICA' ? [
      this.opciones.generarFactores(),
      this.opciones.generarFactoresValoresG()
    ] : [
        this.opciones.generarFactoresValoresG(),
        this.opciones.generarFactoresMujerUnica(),
        this.opciones.generarFactoresValoresGMujerUnica(),
      ]).then(() => {
        if (this.opciones.tipoPlan === 'MUJER UNICA') {
          this.selectMujerUnica();
        }

        this.calculos.RECALCULAR();
        this.modalRef.hide();
      });
  }

  checkIsValidTerm() {
    const terms = this.protectionPlansGenerator.generatePlansFront(
      this.opciones.tipoPlan,
      this.opciones.plazoPago.value,
      this.opciones.moneda
    );
    if (terms.length > 0) {
      const arrsTerms = terms.filter(s => Number(s.value) === Number(this.opciones.plazoPago.value));
      if (arrsTerms.length === 0) {
        this.changeTerm(this.protectionPlansGenerator.plazoDefault);
      }
    }
  }
  /**
   * Cambiar término (plazo de pago).
   * @param value
   */
  changeTerm(value: any, wasManuallyChanged: boolean = false) {
    if (wasManuallyChanged) {
      this.protectionPlansGenerator.selectedPlan.next(Number(value));
    }

    this.opciones.changePaymentTerm(value);
    this.protectionPlansGenerator.generatePlansFront(
      this.opciones.tipoPlan,
      value
    );
    switch (this.opciones.tipoPlan) {
      case 'TEMPORAL':
        this.plazosAsegurados = value;
        this.opciones.plazosAsegurados = Number(value);
        this.opciones.plazoPago.next(Number(value));
        this.opciones.plazoSeguro = Number(value);

        if (Number(value) === 1) {
          this.excedentes.segundoAnioAportacion.next(1);
        }

        break;
      case 'VPL':
        this.plazosAsegurados = 99;
        this.opciones.plazoSeguro = 99;
        break;
      case 'DOTAL':
        this.plazosAsegurados = value;
        this.opciones.plazoPago.next(Number(value));
        this.valueTerm = value;
        this.validaAlidos65(this.valuePlan, this.valueTerm);
        break;
      case 'ALIADOSKIDS':
        this.plazosAsegurados = value;
    }

    this.modalRef = this.modalService.show(this.loader, {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'loader-modal'
    });
    //TODO:ALIADOSKIDS Recalcular Tutor y EFI 18 y 22
    Promise.all([
      this.opciones.generarFactores(),
      this.opciones.generarFactoresValoresG()
    ]).then(() => {
      this.calculos.RECALCULAR(true);
      this.modalRef.hide();
      this.modalService.hide(1);
    });
    this.checkIsValidTerm();
  }

  // ? ****************** //

  // ? COBERTURAS //
  changeMasiva(masivo: number) {
    switch (masivo) {
      case 1:
        this.validBasico(1);
        this.calculos.COBERTURA_BASICA_MASIVA(
          Number(this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_1)),
          1
        );

        if (this.opciones.tipoPlan === 'PROTECANCER') {
          this.calculos.COBERTURA_IAC_MASIVA(
            Number(this.deleteCurrencySigns(this.IAC_sumaAseguradaMasivo_1)),
            1
          );
        }
        break;
      case 2:
        this.validBasico(2);
        this.calculos.COBERTURA_BASICA_MASIVA(
          Number(this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_2)),
          2
        );

        if (this.opciones.tipoPlan === 'PROTECANCER') {
          this.calculos.COBERTURA_IAC_MASIVA(
            Number(this.deleteCurrencySigns(this.IAC_sumaAseguradaMasivo_2)),
            2
          );
        }
        break;
      case 3:
        this.validBasico(3);
        this.calculos.COBERTURA_BASICA_MASIVA(
          Number(this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_3)),
          3
        );

        if (this.opciones.tipoPlan === 'PROTECANCER') {
          this.calculos.COBERTURA_IAC_MASIVA(
            Number(this.deleteCurrencySigns(this.IAC_sumaAseguradaMasivo_3)),
            3
          );
        }
        break;
      default:
        this.calculos.COBERTURA_BASICA_MASIVA(
          Number(this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_1)),
          1
        );
        this.calculos.COBERTURA_BASICA_MASIVA(
          Number(this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_2)),
          2
        );
        this.calculos.COBERTURA_BASICA_MASIVA(
          Number(this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_3)),
          3
        );

        if (this.opciones.tipoPlan === 'PROTECANCER') {
          this.calculos.COBERTURA_IAC_MASIVA(
            Number(this.deleteCurrencySigns(this.IAC_sumaAseguradaMasivo_1)),
            1
          );
          this.calculos.COBERTURA_IAC_MASIVA(
            Number(this.deleteCurrencySigns(this.IAC_sumaAseguradaMasivo_2)),
            2
          );
          this.calculos.COBERTURA_IAC_MASIVA(
            Number(this.deleteCurrencySigns(this.IAC_sumaAseguradaMasivo_3)),
            3
          );
        }
        break;
    }

    this.calculos.calcularPagoDividido(this.opciones.tipoPlan);
  }
  /**
   * Método que permite cambiar la cobertura Básica.
   * @method
   */
  changeBasica() {
    this.validBasico();
    this.calculos.COBERTURA_BASICA(
      Number(this.deleteCurrencySigns(this.BASICA_sumaAseguradaUDI))
    );
    /// Forza la actualion de los pagos divididos
    if (this.opciones.tipoPlan === 'MUJER UNICA') {
      this.changeCDC();
    }
    if(this.opciones.tipoPlan === 'ALIADOSKIDS' && this.EFI_sumaAseguradaUDI !== 'EXCLUIDO'){
      this.calculos.COBERTURA_EFI( Number(this.calculos.TUTOR.primaAnual_UDI.value) + Number(this.calculos.BASICA_primaAnualUDI.value));
    }
    this.calculos.calcularPagoDividido(this.opciones.tipoPlan);
  }
  /**
   * Método que permite cambiar la cobertura Básica Inversa.
   * @method
   */
  changeBasicaInversa() {
    this.calculos.COBERTURA_BASICA_INVERSA(
      Number(this.deleteCurrencySigns(this.BASICA_primaAnualPesos.toString()))
    );
  }
  /**
   *Cambia la cobertura básica desde la segunda moneda.
   * @method
   * @memberof BuildPlanComponent
   */
  changeBasicaSegMon() {
    this.calculos.COBERTURA_BASICA_SEGMON(
      Number(
        this.deleteCurrencySigns(this.BASICA_sumaAseguradaPesos.toString())
      )
    );
  }
  /**
   * Método que permite cambiar el estado de la cobertura Básica Inversa.
   */
  changeBI(status: CoverageStatus) {
    this.calculos.COBERTURA_BI(status);
  }
  changeECMI(status: CoverageStatus) {
    this.calculos.COBERTURA_ECMI(status);
    if (status === 'INCLUIDO') {
      this.obtenerPrimaUniversales('ECMI', Number(this.BASICA_sumaAsegurada_Universal));
    } else {
      this.borrarCobertura('ECMI');
    }
  }
  /**
   * Método que permite cambiar la Cobertura PAI
   * @method
   */
  changePAI() {
    this.validPai();
    this.calculos.COBERTURA_PAI(
      Number(this.deleteCurrencySigns(this.PAI_sumaAseguradaUDI))
    );
    this.isModified.PAI = false;
  }
  // PAI Universales
  changePAIU() {
    this.validPai();
    this.calculos.COBERTURA_PAIU(
      Number(this.deleteCurrencySigns(this.PAI_sumaAseguradaPesos_Universal))
    );
    this.isModified.PAIU = false;
    this.obtenerPrimaUniversales('PAI', Number(this.PAI_sumaAseguradaPesos_Universal));
  }

  changeBATut(){
    let sumaAsegurata = Number(this.deleteCurrencySigns(this.TUTOR_sumaAseguradaUDI));
    let value = this.validationType.getValidationData( sumaAsegurata,'BASICA', this.opciones.tipoPlan );
    this.validBasicTut = value.status as boolean;
    this.messageErrorBasica = value.mesage as string;
    // this.calculos.calculaDeduccible(); TODO:ALIADOS+KIDS No entra por que se trata de una cobertura complementaria y no del la BASICA
    this.validBasicTut = value.status as boolean;
    this.messageErrorBasicaTut = value.mesage as string;
    this.calculos.COBERTURA_TUTOR(Number(this.deleteCurrencySigns(this.TUTOR_sumaAseguradaUDI)));  
    if(this.EFI_sumaAseguradaUDI !== 'EXCLUIDO'){
      this.calculos.COBERTURA_EFI( Number(this.calculos.TUTOR.primaAnual_UDI.value) + Number(this.calculos.BASICA_primaAnualUDI.value));
    }    
  }
  /**
   * Método que excluye a la cobertura PAI
   * @method
   */
  excluirPAI() {
    this.calculos.COBERTURA_PAI(-1);
    this.isModified.PAI = false;
  }
  excluirPAIU() {
    this.calculos.COBERTURA_PAIU(-1);
    this.isModified.PAIU = false;
    this.borrarCobertura('PAI');
  }
  /**
   * Método que limpia el input y cambia el estado de la cobertura.
   * @method
   */
  clickPAI(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.PAI = true;
  }
  clickPAIU(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.PAIU = true;
    this.obtenerPrimaUniversales('PAI', Number(this.PAI_sumaAseguradaPesos_Universal));
  }
  /**
   * Método que permite cambiar la Cobertura CC
   * @method
   */
  changeCC() {
    this.validCC();
    this.calculos.COBERTURA_CC(
      Number(this.deleteCurrencySigns(this.CC_sumaAseguradaUDI))
    );
    this.isModified.CC = false;
  }
  /**
   * Método que excluye a la cobertura CC
   * @method
   */
  excluirCC() {
    this.calculos.COBERTURA_CC(-1);
    this.isModified.CC = false;
  }
  /**
   * Método que limpia el input y cambia el estado de la cobertura.
   * @method
   */
  clickCC(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.CC = true;
  }
  /**
   * Método que permite cambiar la Cobertura DI
   * @method
   */
  changeDI() {
    this.validDI();
    this.actualCoverage = 'DI';
    this.calculos.COBERTURA_DI(
      Number(this.deleteCurrencySigns(this.DI_sumaAseguradaUDI))
    );
    this.DI_COVERAGE = true;
    this.DIPO_COVERAGE = false;
    this.DIPOC_COVERAGE = false;
    this.isModified.DI = false;
  }
  changeDIU() {
    this.validDI();
    this.actualCoverage = 'DI';
    this.calculos.COBERTURA_DIU(
      Number(this.deleteCurrencySigns(this.DI_sumaAseguradaMXN_Universal))
    );
    this.DI_COVERAGE = true;
    this.DIPO_COVERAGE = false;
    this.DIPOC_COVERAGE = false;
    this.isModified.DIU = false;
    this.obtenerPrimaUniversales('DI', Number(this.DI_sumaAseguradaMXN_Universal));
  }
  /**
   * Método que permite cambiar la Cobertura DIPO
   * @method
   */
  changeDIPO() {
    this.validDIPO();
    this.actualCoverage = 'DIPO';
    this.calculos.COBERTURA_DIPO(
      Number(this.deleteCurrencySigns(this.DIPO_sumaAseguradaUDI))
    );
    this.DI_COVERAGE = false;
    this.DIPO_COVERAGE = true;
    this.DIPOC_COVERAGE = false;
    this.isModified.DIPO = false;
  }
  /**
   * Método que permite cambiar la Cobertura DIPOC
   * @method
   */
  changeDIPOC() {
    this.validDIPOC();
    this.actualCoverage = 'DIPOC';
    this.calculos.COBERTURA_DIPOC(
      Number(this.deleteCurrencySigns(this.DIPOC_sumaAseguradaUDI))
    );
    this.DI_COVERAGE = false;
    this.DIPO_COVERAGE = false;
    this.DIPOC_COVERAGE = true;
    this.isModified.DIPOC = false;
  }
  changeDIPOCU() {
    this.validDIPOC();
    this.actualCoverage = 'DIPOC';
    this.calculos.COBERTURA_DIPOCU(
      Number(this.deleteCurrencySigns(this.DIPOC_sumaAseguradaMXN_Universal))
    );
    this.DI_COVERAGE = false;
    this.DIPO_COVERAGE = false;
    this.DIPOC_COVERAGE = true;
    this.isModified.DIPOCU = false;
    this.obtenerPrimaUniversales('DIPOC', Number(this.DIPOC_sumaAseguradaMXN_Universal));
  }

  changeEFI(status: string){
    if( status === 'INCLUIDO'){
      this.calculos.COBERTURA_EFI(Number(this.calculos.TUTOR.primaAnual_UDI.value) + Number(this.calculos.BASICA_primaAnualUDI.value));
    }else{
      this.calculos.COBERTURA_EFI(0);
    }
  }
  /**
   * Método que excluye a la cobertura DI
   * @method
   */
  excluirDI() {
    this.calculos.COBERTURA_DI(-1);
    this.resetD();
    this.isModified.DI = false;
  }
  excluirDIU() {
    this.calculos.COBERTURA_DIU(-1);
    this.resetD();
    this.isModified.DIU = false;
    this.borrarCobertura('DI');
  }
  /**
   * Método que excluye a la cobertura DIPO
   * @method
   */
  excluirDIPO() {
    this.calculos.COBERTURA_DIPO(-1);
    this.resetD();
    this.isModified.DIPO = false;
  }
  /**
   * Método que excluye a la cobertura DIPOC
   * @method
   */
  excluirDIPOC() {
    this.calculos.COBERTURA_DIPOC(-1);
    this.resetD();
    this.isModified.DIPOC = false;
  }
  excluirDIPOCU() {
    this.calculos.COBERTURA_DIPOC(-1);
    this.resetD();
    this.isModified.DIPOC = false;
    this.borrarCobertura('DIPOC');
  }
  /**
   * Método que limpia el input y cambia el estado de la cobertura.
   * @method
   */
  clickDI(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.DI = true;
  }
  clickDIU(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.DI = true;
    this.obtenerPrimaUniversales('DI', Number(this.DI_sumaAseguradaMXN_Universal));
  }
  /**
   * Método que limpia el input y cambia el estado de la cobertura.
   * @method
   */
  clickDIPO(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.DIPO = true;
  }
  /**
   * Método que limpia el input y cambia el estado de la cobertura.
   * @method
   */
  clickDIPOC(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.DIPOC = true;
  }
  clickDIPOCU(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.DIPOC = true;
    this.obtenerPrimaUniversales('DIPOC', Number(this.DIPOC_sumaAseguradaMXN_Universal));
  }
  /**
   * Método que resetea el valor de las coberturas DI, DIPO Y DIPOC, respecto a su sumas aseguradas y su estado.
   */
  resetD() {
    this.DI_COVERAGE = true;
    this.DIPO_COVERAGE = true;
    this.DIPOC_COVERAGE = true;
    this.DI_sumaAseguradaUDI = '0';
    this.DIPO_sumaAseguradaUDI = '0';
    this.DIPOC_sumaAseguradaUDI = '0';
    this.DIPOC_sumaAseguradaMXN_Universal = '0';
    this.DI_sumaAseguradaMXN_Universal = '0';
  }
  /**
   * Método que permite cambiar la Cobertura IAC
   * @method
   */
  changeIAC() {
    this.validIAC();
    this.calculos.COBERTURA_IAC(
      Number(this.deleteCurrencySigns(this.IAC_sumaAseguradaUDI))
    );
    this.isModified.IAC = false;
  }

  changeCDCUniv() {
    this.validIAC();
    this.calculos.COBERTURA_CDCUniv(
      Number(this.deleteCurrencySigns(this.CDC_sumaAseguradaMXN_Universal))
    );
    this.isModified.CDC = false;
    this.obtenerPrimaUniversales('CDC', Number(this.CDC_sumaAseguradaMXN_Universal));
  }
  /**
   * Método que excluye a la cobertura IAC
   * @method
   */
  excluirIAC() {
    this.calculos.COBERTURA_IAC(-1);
    this.isModified.IAC = false;
  }
  excluirCDC() {
    this.calculos.COBERTURA_CDCUniv(-1);
    this.isModified.CDC = false;
    this.borrarCobertura('CDC');
  }
  /**
   * Método que limpia el input y cambia el estado de la cobertura.
   * @method
   */
  clickIAC(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.IAC = true;
  }
  clickCDC(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.CDC = true;
  }
  /**
   * Método que permite cambiar la Cobertura PAE
   * @method
   */
  changePAE() {
    this.validPAE();
    this.calculos.COBERTURA_PAE(
      Number(this.deleteCurrencySigns(this.PAE_sumaAseguradaUDI))
    );
    this.isModified.PAE = false;
  }
  /**
   * Método que excluye a la cobertura PAE
   * @method
   */
  excluirPAE() {
    this.calculos.COBERTURA_PAE(-1);
    this.isModified.PAE = false;
  }
  /**
   * Método que limpia el input y cambia el estado de la cobertura.
   * @method
   */
  clickPAE(input: HTMLInputElement) {
    this.cleanInput(input);
    this.isModified.PAE = true;
  }
  /**
   * Método que permite cambiar la Cobertura GF
   * @method
   */
  changeGF() {
    this.calculos.COBERTURA_GF(
      this.currencyPipe.transform(this.GF_sumaAseguradaUDI)
    );
  }
  /**
   * Método que excluye a la cobertura GF
   * @method
   */
  excluirGF() {
    this.calculos.COBERTURA_GF(-1);
  }
  /**
   * Método que permite cambiar la Cobertura PCD
   * @method
   */
  changePCD(status: CoverageStatus) {
    this.calculos.COBERTURA_PCD(status);
  }

  changeVPL() {
    this.validVPL();
    this.calculos.COBERTURA_VPL(
      Number(this.deleteCurrencySigns(this.VPL_sumaAsegurada_PrimeraMoneda))
    );
  }

  changeCDC() {
    this.validCDC();
    this.calculos.COBERTURA_CDC(
      Number(this.deleteCurrencySigns(this.CDC_sumaAsegurada_PrimeraMoneda))
    );
  }

  /**
   * Método que redirecciona hacia el componente "reporte"
   * @method
   */
  verPDF() {
    this.routes.chosenCotizador.getValue() === 'AxaParaTodos'
      ? this.router.navigate(['/pdf-protefecan'])
      : this.router.navigate(['/reporte']);
  }
  /**
   * Método que redirecciona hacia el componente "propuestas"
   * @method
   */
  getPropuestas() {
    if (this.routes.chosenCotizador.value === 'ProTGT') {
      this.valorPlazos();
      this.openModal(this.comparadorModal);
    }
    if (this.routes.chosenCotizador.value === 'Aliados') {
      this.addAliados();
      this.router.navigate([
        `/cotizador/${this.routes.chosenCotizador.value}/propuestas`
      ]);
    }
  }

  goProposals() {
    this.router.navigate([
      `/cotizador/${this.routes.chosenCotizador.value}/propuestas`
    ]);
  }
  /**
   * Método que redirecciona hacia el componente "valores-garantizados"
   * @method
   */
  goToValores() {
    this.calculos.calcularPagoDividido();
    this.router.navigate([
      `/cotizador/${this.routes.chosenCotizador.value}/valores-garantizados`
    ]);
  }

  // ? ************************ //
  /**
   * Método que evalúa qué cobertura está activa.
   * @param cobertura
   * @method
   */
  isThisCoverageActive(cobertura: Coverage): boolean {
    return this.actualCoverage === cobertura;
  }

  /**
   * Método que abre el modal.
   * @param template
   * @method
   */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      backdrop: 'static',
      animated: false,
      ignoreBackdropClick: true,
      class: 'variablesetingsmodal'
    });
  }

  /**
   * Método que detecta el redimensionamiento de la pantalla del dispositivo desde el que se accede, para posteriormente retornar el tipo de dispositivo.
   * @param event
   * @event
   */
  @HostListener('window:resize', ['event'])
  onResize(event) {
    this.ipadDevice = /iPad/.test(navigator.userAgent);
    this.device = this.deviceType.getDeviceType();
  }
  /**
   * Método que valida que la suma asegurada coincida con las normas establecidas para esa cobertura.
   * @method
   */
  validBasico(masivo?: number): void {
    let sumaAsegurata;
    if (masivo) {
      switch (masivo) {
        case 1:
          sumaAsegurata = Number(
            this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_1)
          );
          break;
        case 2:
          sumaAsegurata = Number(
            this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_2)
          );
          break;
        case 3:
          sumaAsegurata = Number(
            this.deleteCurrencySigns(this.BASICA_sumaAseguradaMasivo_3)
          );
          break;
      }
    } else {
      if (this.routes.chosenCotizador.getValue() !== 'Universales') {
        sumaAsegurata = Number(
          this.deleteCurrencySigns(this.BASICA_sumaAseguradaUDI)
        );
      }
      if (this.routes.chosenCotizador.getValue() === 'Universales') {
        sumaAsegurata = Number(
          this.deleteCurrencySigns(this.BASICA_sumaAsegurada_Universal)
        );
      }

    }
    if (this.routes.chosenCotizador.getValue() !== 'Universales') {

      //let value = this.validationType.getValidationData( sumaAsegurata, 'BASICA', this.opciones.tipoPlan );
      let value = this.validationType.getValidationData( sumaAsegurata, this.opciones.tipoPlan === 'MUJER UNICA' ? 'VPL' : 'BASICA', this.opciones.tipoPlan );
      this.validBasic = value.status as boolean;
      this.messageErrorBasica = value.mesage as string;
      this.calculos.calculaDeduccible();
    } else {
      const value = this.validationType.getValidationData(
        sumaAsegurata,
        this.opciones.tipoPlan === 'VIDA INTELIGENTE' ? 'BAU_VI' :
          this.opciones.tipoPlan === 'MI PROYECTO R' ? 'BAU_MR' : 'BASICA',
        this.opciones.tipoPlan
      );

      this.validBasic = value.status as boolean;
      this.messageErrorBasica = value.mesage as string;

    }
  }

  validVPL(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.VPL_sumaAsegurada_PrimeraMoneda)
    );

    if (sumaAsegurata !== 0) {
      const value = this.validationType.getValidationData(sumaAsegurata, 'VPL');
      this.valVPL = value.status as boolean;
      this.messageErrorVPL = value.mesage as string;
    }
  }

  validCDC(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.CDC_sumaAsegurada_PrimeraMoneda)
    );

    if (sumaAsegurata !== 0) {
      const value = this.validationType.getValidationData(sumaAsegurata, 'CDC');
      this.valCDC = value.status as boolean;
      this.messageErrorCDC = value.mesage as string;
    }
  }

  /**
   * Método que valida que la suma asegurada coincida con las normas establecidas para esa cobertura.
   * @method
   */
  validPai(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.routes.chosenCotizador.getValue() !== 'Universales' ? this.PAI_sumaAseguradaUDI
        : this.PAI_sumaAseguradaPesos_Universal)
    );
    if (sumaAsegurata !== 0) {
      const value = this.validationType.getValidationData(sumaAsegurata, 'PAI', this.opciones.tipoPlan);
      this.valPai = value.status as boolean;
      this.messageErrorPai = value.mesage as string;
    }
  }
  /**
   * Método que valida que la suma asegurada coincida con las normas establecidas para esa cobertura.
   * @method
   */
  validCC(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.CC_sumaAseguradaUDI)
    );
    let a: number;
    a = sumaAsegurata;
    if (a !== 0) {
      const value = this.validationType.getValidationData(sumaAsegurata, 'CC', this.opciones.tipoPlan);
      this.valCC = value.status as boolean;
      this.messageErrorCC = value.mesage as string;
    }
  }
  /**
   * Método que valida que la suma asegurada coincida con las normas establecidas para esa cobertura.
   * @method
   */
  validDI(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.routes.chosenCotizador.getValue() !== 'Universales' ? this.DI_sumaAseguradaUDI
        : this.DI_sumaAseguradaMXN_Universal)
    );

    if (sumaAsegurata !== 0) {
      const value = this.validationType.getValidationData(sumaAsegurata, 'DI', this.opciones.tipoPlan);
      this.valDI = value.status as boolean;
      this.messageErrorDI = value.mesage as string;
    }
  }
  /**
   * Método que valida que la suma asegurada coincida con las normas establecidas para esa cobertura.
   * @method
   */
  validDIPO(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.DIPO_sumaAseguradaUDI)
    );

    if (sumaAsegurata !== 0) {
      const value = this.validationType.getValidationData(
        sumaAsegurata,
        'DIPO',
        this.opciones.tipoPlan
      );
      this.valDIPO = value.status as boolean;
      this.messageErrorDIPO = value.mesage as string;
    }
  }
  /**
   * Método que valida que la suma asegurada coincida con las normas establecidas para esa cobertura.
   * @method
   */
  validDIPOC(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.routes.chosenCotizador.getValue() !== 'Universales' ? this.DIPOC_sumaAseguradaUDI
        : this.DIPOC_sumaAseguradaMXN_Universal)
    );

    if (sumaAsegurata !== 0) {
      const value = this.validationType.getValidationData(
        sumaAsegurata,
        'DIPOC',
        this.opciones.tipoPlan
      );
      this.valDIPOC = value.status as boolean;
      this.messageErrorDIPOC = value.mesage as string;
    }
  }
  /**
   * Método que valida que la suma asegurada coincida con las normas establecidas para esa cobertura.
   * @method
   */
  validIAC(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.routes.chosenCotizador.getValue() !== 'Universales' ? this.IAC_sumaAseguradaUDI
        : this.CDC_sumaAseguradaMXN_Universal)
    );

    if (sumaAsegurata !== 0) {
      const value = this.validationType.getValidationData(sumaAsegurata, 'IAC', this.opciones.tipoPlan);
      this.valIAC = value.status as boolean;
      this.messageErrorIAC = value.mesage as string;
    }
  }
  /**
   * Método que valida que la suma asegurada coincida con las normas establecidas para esa cobertura.
   * @method
   */
  validPAE(): void {
    const sumaAsegurata = Number(
      this.deleteCurrencySigns(this.PAE_sumaAseguradaUDI)
    );

    if (sumaAsegurata !== 0) {
      const value = this.validationType.getValidationData(sumaAsegurata, 'PAE', this.opciones.tipoPlan);
      this.valPAE = value.status as boolean;
      this.messageErrorPAE = value.mesage as string;
    }
  }
  /**
   * Método que oculta el modal.
   * @method
   */
  doQueryToValuesG() {
    this.modalRef.hide();
  }
  /**
   * Método que modifica el esquema de comisión.
   * @param scheme
   * @method
   */
  changeCommissionScheme(scheme: CommissionScheme) {
    this.variableConfiguration.changeCommissionScheme(scheme);
  }
  /**
   * Método que modifica la asignación del esquema de comisión.
   * @param tipo
   * @method
   */
  changeCommissionAssignment(tipo: CommissionAssignment) {
    this.variableConfiguration.changeCommissionAssignment(tipo);
  }

  /**
   * Método que establece la tasa de inflación en UDI.
   * @param value
   */
  establecerInflacion(value) {
    this.excedentes.inflacion_udi.next(value);
  }

  /**
   * Método que establece los incrementos programados.
   * @param value
   */
  establecerIncrementosProgramados(value) {
    switch (value) {
      case 'Si':
        this.incrementosProgramados = true;
        this.opciones.incrementosProgramados.next(true);
        break;
      case 'No':
        this.incrementosProgramados = false;
        this.opciones.incrementosProgramados.next(false);
        break;
    }
  }

  /**
   * Método que cambia el input activo.
   * @param option
   * @param newActive
   */
  changeActiveInput(option: string, newActive: string) {
    for (const val in this.inputSelected[option]) {
      if (this.inputSelected[option][val]) {
        this.inputSelected[option][val] = false;
      }
    }

    this.inputSelected[option][newActive] = true;
  }

  /**
   * Toogle (to review)
   */
  toggle() {
    this.show = !this.show;
  }

  /**
   * Método que inicializa los cálculos.
   * @method
   */
  initCalculos() {
    // Basica
    this.calculos.BASICA_sumaAseguradaUDI.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => (this.BASICA_sumaAseguradaUDI = String(value)));
    this.calculos.BASICA_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.BASICA_primaAnualUDI = value;
      }
    );
    this.calculos.BASICA_sumaAsegurada_Universal.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => {
      this.BASICA_sumaAsegurada_Universal = String(value);
    });
    this.calculos.BASICA_sumaAseguradaPesos.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => {
      this.BASICA_sumaAseguradaPesos = Math.round(value);
    });
    this.calculos.BASICA_primaAnualPesos.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => {
      this.BASICA_primaAnualPesos = value;
    });
    this.calculos.BASICA_sumaAseguradaMasiva_1.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => (this.BASICA_sumaAseguradaMasivo_1 = String(value)));
    this.calculos.BASICA_sumaAseguradaMasiva_2.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => (this.BASICA_sumaAseguradaMasivo_2 = String(value)));
    this.calculos.BASICA_sumaAseguradaMasiva_3.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => (this.BASICA_sumaAseguradaMasivo_3 = String(value)));

    // BI
    this.calculos.BI_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.BI_sumaAseguradaUDI = value;
      }
    );
    this.calculos.BI_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.BI_primaAnualUDI = value;
      }
    );
    this.calculos.BI_sumaAseguradaPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.BI_sumaAseguradaPesos = value;
      }
    );
    this.calculos.BI_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.BI_primaAnualPesos = value;
      }
    );

    // PAI
    this.calculos.PAI_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => (this.PAI_sumaAseguradaUDI = String(value))
    );
    this.calculos.PAI_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.PAI_primaAnualUDI = value;
      }
    );
    this.calculos.PAI_sumaAseguradaPesos.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => {
      this.PAI_sumaAseguradaPesos = Math.round(value);
    });
    this.calculos.PAI_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.PAI_primaAnualPesos = value;
      }
    );

    // CC
    this.calculos.CC_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => (this.CC_sumaAseguradaUDI = String(value))
    );
    this.calculos.CC_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.CC_primaAnualUDI = value;
      }
    );
    this.calculos.CC_sumaAseguradaPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.CC_sumaAseguradaPesos = Math.round(value);
      }
    );
    this.calculos.CC_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.CC_primaAnualPesos = value;
      }
    );

    // ? COBERTURA DI
    // 1. Suma A. UDI
    this.calculos.DI_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      sumaAseguradaUDI => {
        this.DI_sumaAseguradaUDI = String(sumaAseguradaUDI);
      }
    );
    // 2. Suma A. MXN
    this.calculos.DI_sumaAseguradaPesos.takeUntil(this.ngUnsubscribe).subscribe(
      sumaAseguradaMXN => {
        this.DI_sumaAseguradaPesos = Math.round(sumaAseguradaMXN);
      }
    );
    // 3. Prima Anual UDI
    this.calculos.DI_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      primaAnualUDI => {
        this.DI_primaAnualUDI = Math.round(primaAnualUDI);
      }
    );
    // 4. Prima Anual MXN
    this.calculos.DI_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      primaAnualMXN => {
        this.DI_primaAnualPesos = Math.round(primaAnualMXN);
      }
    );

    this.calculos.DIU_sumaAseguradaMXN_U.takeUntil(this.ngUnsubscribe).subscribe(
      sumaAseguradaMXN => {
        this.DI_sumaAseguradaMXN_Universal = String(sumaAseguradaMXN);
      }
    );

    // ? COBERTURA DIPO
    // 1. Suma A. UDI
    this.calculos.DIPO_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      sumaAseguradaUDI => {
        this.DIPO_sumaAseguradaUDI = String(sumaAseguradaUDI);
      }
    );
    // 2. Suma A. MXN
    this.calculos.DIPO_sumaAseguradaPesos.takeUntil(
      this.ngUnsubscribe
    ).subscribe(sumaAseguradaMXN => {
      this.DIPO_sumaAseguradaPesos = Math.round(sumaAseguradaMXN);
    });
    // 3. Prima Anual UDI
    this.calculos.DIPO_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      primaAnualUDI => {
        this.DIPO_primaAnualUDI = Math.round(primaAnualUDI);
      }
    );
    // 4. Prima Anual MXN
    this.calculos.DIPO_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      primaAnualMXN => {
        this.DIPO_primaAnualPesos = Math.round(primaAnualMXN);
      }
    );

    // ? COBERTURA DIPOC
    // 1. Suma A. UDI
    this.calculos.DIPOC_sumaAseguradaUDI.takeUntil(
      this.ngUnsubscribe
    ).subscribe(sumaAseguradaUDI => {
      this.DIPOC_sumaAseguradaUDI = String(sumaAseguradaUDI);
    });
    // 2. Suma A. MXN
    this.calculos.DIPOC_sumaAseguradaPesos.takeUntil(
      this.ngUnsubscribe
    ).subscribe(sumaAseguradaMXN => {
      this.DIPOC_sumaAseguradaPesos = Math.round(sumaAseguradaMXN);
    });
    // 3. Prima Anual UDI
    this.calculos.DIPOC_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      primaAnualUDI => {
        this.DIPOC_primaAnualUDI = Math.round(primaAnualUDI);
      }
    );
    // 4. Prima Anual MXN
    this.calculos.DIPOC_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      primaAnualMXN => {
        this.DIPOC_primaAnualPesos = Math.round(primaAnualMXN);
      }
    );
    this.calculos.DIPOCU_sumaAseguradaMXN_U.takeUntil(this.ngUnsubscribe).subscribe(
      sumaAseguradaMXN => {
        this.DIPOC_sumaAseguradaMXN_Universal = String(sumaAseguradaMXN);
      });
    // IAC
    this.calculos.IAC_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => (this.IAC_sumaAseguradaUDI = String(value))
    );
    this.calculos.IAC_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.IAC_primaAnualUDI = value;
      }
    );
    this.calculos.IAC_sumaAseguradaPesos.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => {
      this.IAC_sumaAseguradaPesos = Math.round(value);
    });
    this.calculos.IAC_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.IAC_primaAnualPesos = value;
      }
    );
    this.calculos.IAC_sumaAseguradaMasiva_1.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => (this.IAC_sumaAseguradaMasivo_1 = String(value)));
    this.calculos.IAC_sumaAseguradaMasiva_2.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => (this.IAC_sumaAseguradaMasivo_2 = String(value)));
    this.calculos.IAC_sumaAseguradaMasiva_3.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => (this.IAC_sumaAseguradaMasivo_3 = String(value)));
    this.calculos.CDC_sumaAseguradaMXN_U.takeUntil(this.ngUnsubscribe).subscribe(
      value => (this.CDC_sumaAseguradaMXN_Universal = String(value))
    );
    // PAE
    this.calculos.PAE_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => (this.PAE_sumaAseguradaUDI = String(value))
    );
    this.calculos.PAE_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.PAE_primaAnualUDI = value;
      }
    );
    this.calculos.PAE_sumaAseguradaPesos.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => {
      this.PAE_sumaAseguradaPesos = Math.round(value);
    });
    this.calculos.PAE_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.PAE_primaAnualPesos = value;
      }
    );

    // PCD
    this.calculos.PCD_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.PCD_sumaAseguradaUDI = value;
      }
    );
    this.calculos.PCD_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.PCD_primaAnualUDI = value;
      }
    );
    this.calculos.PCD_sumaAseguradaPesos.takeUntil(
      this.ngUnsubscribe
    ).subscribe(value => {
      this.PCD_sumaAseguradaPesos = value;
    });
    this.calculos.PCD_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.PCD_primaAnualPesos = value;
      }
    );

    // GF
    this.calculos.GF_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.GF_sumaAseguradaUDI = value;
      }
    );
    this.calculos.GF_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.GF_primaAnualUDI = value;
      }
    );
    this.calculos.GF_sumaAseguradaPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.GF_sumaAseguradaPesos = Math.round(value);
      }
    );
    this.calculos.GF_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.GF_primaAnualPesos = value;
      }
    );

    // VPL
    this.calculos.VPL_sumaAsegurada_PrimeraMoneda.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.VPL_sumaAsegurada_PrimeraMoneda = String(value);
      }
    );
    this.calculos.VPL_primaAnual_PrimeraMoneda.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.VPL_primaAnual_PrimeraMoneda = value;
      }
    );

    // CDC
    this.calculos.CDC_sumaAsegurada_PrimeraMoneda.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.CDC_sumaAsegurada_PrimeraMoneda = String(value);
      }
    );
    this.calculos.CDC_primaAnual_PrimeraMoneda.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.CDC_primaAnual_PrimeraMoneda = value;
      }
    );
    this.calculos.GFI_sumaAseguradaMXN.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.GFI_sumaAseguradaPesos_Universal = value;
      }
    );
    this.calculos.GFC_sumaAseguradaMXN.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.GFC_sumaAseguradaPesos_Universal = value;
      }
    );
    this.calculos.GFH_sumaAseguradaMXN.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.GFH_sumaAseguradaPesos_Universal = value;
      }
    );
    // ECMI
    this.calculos.ECMI_sumaAseguradaMXN.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.ECMI_sumaAsegurada_Universal = value;
      }
    );
    // PAI Universales
    this.calculos.PAI_sumaAseguradaMXN_U.takeUntil(this.ngUnsubscribe).subscribe(
      value => (this.PAI_sumaAseguradaPesos_Universal = String(value))
    );
    // TEMPORAL ALIADOSKIDS (Tutor)
    this.calculos.TUTOR_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => ( this.TUTOR_sumaAseguradaUDI = String(value))
    );
    this.calculos.TUTOR_sumaAseguradaPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => ( this.TUTOR_sumaAseguradaPesos = value)
    );
    this.calculos.TUTOR_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.TUTOR_primaAnualUDI = value;
      }
    );
    this.calculos.TUTOR_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.TUTOR_primaAnualPesos = value;
      }
    );
    // ALIADOSKIDS (EFI)
    this.calculos.EFI_sumaAseguradaUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => ( this.EFI_sumaAseguradaUDI = String(value))
    );
    this.calculos.EFI_sumaAseguradaPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => ( this.EFI_sumaAseguradaPesos = value)
    );
    this.calculos.EFI_primaAnualUDI.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.EFI_primaAnualUDI = value;
      }
    );
    this.calculos.EFI_primaAnualPesos.takeUntil(this.ngUnsubscribe).subscribe(
      value => {
        this.EFI_primaAnualPesos = value;
      }
    );
  }

  /**
   * Método que valida la disponibilidad de las coberturas con base en la edad del prospecto.
   * @param coverage
   */
  prospectuValid(coverage: string): boolean {
    let LIMIT = PROSPECTU_CONSTANTS.MAXIMUM_AGE;
    let MIN_LIMIT = 18;
    let edad = 0;
    if (['MI PROYECTO R', 'VIDA INTELIGENTE'].indexOf(this.opciones.tipoPlan) >= 0) {
      let accepAge = {
        LIMIT: LIMIT,
        MIN_LIMIT: 18
      };
      switch (coverage) {
        case 'BI':
          accepAge = this.acceptanceAge(ECMIUniversal);
          break;
        case 'PAI':
          accepAge = this.acceptanceAge(PAIUniversal);
          break;
        case 'DI':
          accepAge = this.acceptanceAge(DIUniversal);
          break;
        case 'DIPOC':
          accepAge = this.acceptanceAge(DIPOCUniversal);
          break;
        case 'GFI':
          accepAge = this.acceptanceAge(GFIUniversal);
          break;
        case 'GFC':
          accepAge = this.acceptanceAge(GFCUniversal);
          break;
        case 'GFH':
          accepAge = this.acceptanceAge(GFHUniversal);
          break;
        case 'IAC':
          accepAge = this.acceptanceAge(CDC);
          break;
      }
      edad = this.prospectu.prospecto.value.mancomunado.mancomunado
      ? this.univ.edadEquivalenteCalculada
      : this.prospectu.prospecto.value.age;
      LIMIT = accepAge.LIMIT;
      MIN_LIMIT = accepAge.MIN_LIMIT;
    } else {
      switch (coverage) {
        case 'BI':
          LIMIT = BI.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = BI.ACCEPTANCE_AGE.MIN;
          break;
        case 'CC':
          LIMIT = CC.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = CC.ACCEPTANCE_AGE.MIN;
          break;
        case 'PAI':
          LIMIT = PAI.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = PAI.ACCEPTANCE_AGE.MIN;
          break;
        case 'DI':
          LIMIT = DI.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = DI.ACCEPTANCE_AGE.MIN;
          break;
        case 'DIPO':
          LIMIT = DIPO.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = DIPO.ACCEPTANCE_AGE.MIN;
          break;
        case 'DIPOC':
          LIMIT = DIPOC.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = DIPOC.ACCEPTANCE_AGE.MIN;
          break;
        case 'IAC':
          LIMIT = IAC.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = IAC.ACCEPTANCE_AGE.MIN;
          break;
        case 'PAE':
          LIMIT = PAE.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = PAE.ACCEPTANCE_AGE.MIN;
          break;
        case 'PCD':
          LIMIT = PCD.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = PCD.ACCEPTANCE_AGE.MIN;
          break;
        case 'GF':
          LIMIT = GF.ACCEPTANCE_AGE.MAX;
          MIN_LIMIT = GF.ACCEPTANCE_AGE.MIN;
          break;
      }
      edad = this.prospectu.prospecto.value.mancomunado.mancomunado
      ? this.prospectu.prospecto.value.mancomunado.edadEquivalente
      : this.prospectu.prospecto.value.age;
    }
    const isValid = edad >= MIN_LIMIT && edad <= LIMIT;
    return isValid;
  }

  private acceptanceAge(coverage: CoverageConstants) {
    return {
      LIMIT: coverage.ACCEPTANCE_AGE.MAX,
      MIN_LIMIT: coverage.ACCEPTANCE_AGE.MIN
    }
  }

  /**
   * Método que borra los signos de las monedas.
   * @param text
   */
  deleteCurrencySigns(text: string): string {
    return String(text)
      .replace(/\,/g, '')
      .replace('$ ', '');
  }
  /**
   * Método para identificar si acepto retiro en el plan de aliados + 65
   * @param boolean
   */
  changeRetiro(boolRetiroParam: boolean) {
    if (
      this.opciones.tipoPlan === 'DOTAL' &&
      this.opciones.plazoPago.value === 65 &&
      boolRetiroParam
    ) {
      this.boolRetiro = true;
      this.factoresD.valorCkRetio = this.boolRetiro;
      this.factoresD.retiroValue.next(true);
    } else {
      this.boolRetiro = false;
      this.factoresD.valorCkRetio = this.boolRetiro;
      this.factoresD.retiroValue.next(false);
    }
  }

  changeAxaParaTodos(boolAxaPT: boolean) {
    if (boolAxaPT) {
      this.isIndividual = boolAxaPT;

      if (this.opciones.tipoPlan === 'PROTECANCER') {
        this.BASICA_sumaAseguradaMasivo_1 = String(
          this.sumasProtecancer[0].value
        );
        this.BASICA_sumaAseguradaMasivo_2 = String(
          this.sumasProtecancer[1].value
        );
        this.BASICA_sumaAseguradaMasivo_3 = String(
          this.sumasProtecancer[2].value
        );
        this.IAC_sumaAseguradaMasivo_1 = String(this.sumasProtecancer[0].value);
        this.IAC_sumaAseguradaMasivo_2 = String(this.sumasProtecancer[1].value);
        this.IAC_sumaAseguradaMasivo_3 = String(this.sumasProtecancer[2].value);
      }

      this.changeMasiva(4);
    } else {
      this.isIndividual = boolAxaPT;
    }

    this.routes.isIndividual.next(!this.isIndividual);
  }

  // Método para mostrar el apartado de Retiro
  validaAlidos65(valuePlan: string, valuePlazoSeguro: string) {
    if (
      valuePlan === 'DOTAL' &&
      valuePlazoSeguro === '65' 
    ) {
      this.validaRetiro = true;
      this.aliadosValue.next(true);
    } else {
      this.validaRetiro = false;
      this.aliadosValue.next(false);
    }
    if (
      this.prospectu.prospecto.value.mancomunado !== undefined &&
      this.prospectu.prospecto.value.mancomunado.mancomunado
    ) {
      this.validaRetiro = false;
      this.aliadosValue.next(this.validaRetiro);
    }
  }

  /**
   * Reedirige a tarifas
   * @method
   * @memberof BuildPlanComponent
   */
  irATarifas(): void {
    this.calculos.calcularPagoDividido();
    this.router.navigate([
      `/cotizador/${this.routes.chosenCotizador.value}/tarifas`
    ]);
  }
  valorPlazos() {
    PROTECTION_TERMS.filter(plan => {
      if (plan.plan === 'TEMPORAL') {
        this.valueRadioTEMP = plan.paymentTerms.map(pl => pl.term);
        this.valueRadioTEMPEA = plan.reachedAge.map(ea => ea.term);
      }
      if (plan.plan === 'VPL') {
        this.valueRadioVPL = plan.paymentTerms.map(pl => pl.term);
        this.valueRadioVPLEA = plan.reachedAge.map(ea => ea.term);
      }
      if (plan.plan === 'OV') {
        this.valueRadioOV = plan.reachedAge.map(pl => pl.term);
      }
    });


    this.opciones.arrayListPlan = [];

    const shown$ = this.modalService.onShown
      .auditTime(50)
      .subscribe(() => this.elegido(shown$));
  }
  addAliados() {
    const product = this.opciones.tipoPlan;
    this.opciones.arrayListPlan = [];
    const arrFilter = PROTECTION_TERMS.filter(plan => plan.plan === 'DOTAL');

    const arr = arrFilter.map(e => e.paymentTerms.map(i => i.term))[0];

    if (this.agePros < 61) {
        arr.push(arrFilter.map(e => e.reachedAge.map(i => i.term))[0][0]);
    }

    const arrToSearch = arr
      .filter(v => v !== this.opciones.plazoPago.value)
      .sort((a, b) => (a > b ? a : b));
    arrToSearch.unshift(this.opciones.plazoPago.value);
    for (let i = 0; i < arrToSearch.length; i++) {
      if (this.opciones.arrayListPlan.length < 3) {
        this.opciones.arrayListPlan.push(
          new PlanList(String(i), product, String(arrToSearch[i]))
        );
      }
    }
  }
  elegido(s$: Subscription) {
    const prodElegido = this.opciones.tipoPlan;
    const plazoElegido = this.opciones.plazoPago.value;
    const nodelist = document.getElementsByName('ck');
    const nodeArray = Array.prototype.slice.call(nodelist);

    for (let i = 0; nodeArray[i]; i++) {
      const arrProd = nodeArray[i].attributes.data.value;
      const arrPlazo = nodeArray[i].value;

      if (arrProd === prodElegido && arrPlazo === String(plazoElegido)) {
        nodeArray[i].checked = true;
        nodeArray[i].disabled = true;
        this.opciones.arrayListPlan.push(
          new PlanList(nodeArray[i].id, prodElegido, String(plazoElegido))
        );
      }
    }

    s$.unsubscribe();
  }

  countCheck(obj) {
    const prod = obj.attributes.data.value;
    if (obj.checked && this.opciones.arrayListPlan.length < 3) {
      if (this.opciones.arrayListPlan.length < 3) {
        this.opciones.arrayListPlan.push(new PlanList(obj.id, prod, obj.value));
      }
      if (this.opciones.arrayListPlan.length > 3) {
        obj.checked = false;
        this.valueMsj = true;
      }
    } else {
      obj.checked = false;
      let countPosition = 0;
      let arrayPosition = 0;
      this.opciones.arrayListPlan.forEach(ob => {
        if (ob['id'] === obj.id) {
          arrayPosition = countPosition;
          this.opciones.arrayListPlan.splice(arrayPosition, 1);
          this.valueMsj = false;
        }
        countPosition++;
      });
    }
  }

  /**
   *
   * @param {{ plan: PlanType; terms: number[]; }} plans
   * @memberof BuildPlanComponent
   */
  proTGTPlansAvailableOffline(plans: {
    plan: PlanType;
    terms: number[];
  }[]): boolean {
    const mergeTerms = [];

    plans.forEach(p => mergeTerms.push(...p.terms));

    return mergeTerms.length > 2 ? true : false;
  }

  /**
   * Método que establece la tasa de inflación en INPC
   * @param valuess
   */
  establecerInflacionINPC(value) {
    this.calculos.inflacion_inpc.next(value);
    this.opciones.incrementoInpcPorc.next(Number(value));
  }


  /**
   * Verifica disponibilidad de planes para pintar en gris en el modal
   *
   * @param {PlanType} plan
   * @param {number} term
   * @returns {boolean}
   * @memberof BuildPlanComponent
   */
  verifyAvailableness(plan: PlanType, term: number): boolean {
    const offlineAvailable = this.protectionPlansGenerator.available.value;

    const planAvailableness = offlineAvailable.filter(p => p.plan === plan);

    if (planAvailableness.length > 0) {
      const termAvailableness = planAvailableness[0].terms.filter(t => t === term);

      return termAvailableness.length > 0 ? false : true;
    }

    return false;
  }

  /*Métodos para Universales*/
  goToProfiling() {
    this.router.navigate([
      `/cotizador/${this.routes.chosenCotizador.value}/estrategia-inversion`
    ]);
    // this.pdfmR.generatePDF('a');
    // this.pdfvi.generatePDF('a');

  }
  goToValProyectados() {
    this.router.navigate([
      `/cotizador/${this.routes.chosenCotizador.value}/valores-proyectados`
    ]);
  }
  changePlans(value: PlanType) {
    this.valuePlan = value;
    this.opciones.tipoPlan = value;
      this.opciones.plazoPago.next(
        DEFAULT_TERMS.filter(res => res.plan === value)[0].default || 0
      );
      this.opciones.tipoPlan_Ob.next(value);
    this.protectionPlansGenerator.selectedPlan.next(
      this.opciones.plazoPago.value
    );
    this.opciones.plazoSeguro = this.opciones.plazoPago.value;
    this.schemes = this.variableConfiguration.generateCommissionSchemes();

    this.plazosAsegurados = PROTECTION_TERMS.filter(
      plan => plan.plan === this.opciones.tipoPlan
    )[0].safeTerm;
    this.protectionPlansGenerator.productsGenerator(value);
    this.protectionPlansGenerator.generatePlansFront(
      this.opciones.tipoPlan,
      this.opciones.plazoPago.value
    );
    this.protectionPlansGenerator.currenciesGenerator('MXN', this.opciones.tipoPlan);
    this.opciones.moneda = this.opciones.tipoPlan === 'MI PROYECTO R' ? 'MXN' : this.opciones.moneda;
    this.cambiarMontoBA(value);
    this.establecerPrimasUniversales();
  }

  changeBasicaUniversal() {
    this.validBasico();
    this.calculos.COBERTURA_BASICA_UNIVERSAL(
      Number(this.deleteCurrencySigns(this.BASICA_sumaAsegurada_Universal))
    );
    // this.obtenerPrimaUniversales('BASICA', Number(this.BASICA_sumaAsegurada_Universal));
    this.establecerPrimasUniversales();
  }

  cambiarMontoBA(value?: PlanType) {
    switch (value) {
      case 'VIDA INTELIGENTE':
        if (this.opciones.moneda === 'MXN') {
          this.BASICA_sumaAsegurada_Universal = String(1000000);
          this.changeBasicaUniversal();
        }
        if (this.opciones.moneda === 'USD') {
          this.BASICA_sumaAsegurada_Universal = String(100000);
          this.changeBasicaUniversal();
        }
        // this.changeBasicaUniversal();
        break;
      case 'MI PROYECTO R':
        if (this.opciones.moneda === 'MXN') {
          this.BASICA_sumaAsegurada_Universal = String(1000000);
          this.changeBasicaUniversal();
        }
        if (this.opciones.moneda === 'USD') {
          this.BASICA_sumaAsegurada_Universal = String(100000);
          this.changeBasicaUniversal();
        }
        // this.changeBasicaUniversal();
        break;
    }
    this.establecerPrimasUniversales();
  }
  changeGFI() {
    this.opciones.gfiIncluido.next(true);
    this.calculos.COBERTURA_GFI(
      this.currencyPipe.transform(this.GFI_sumaAseguradaPesos_Universal)
    );
    this.obtenerPrimaUniversales('GFI', Number(this.GFI_sumaAseguradaPesos_Universal));
  }

  changeGFIEx() {
    this.opciones.gfiIncluido.next(false);
    this.calculos.COBERTURA_GFI(-1);
    this.borrarCobertura('GFI');
    this.changeGFCEx();
    this.changeGFHEx();
  }
  changeGFC() {
    this.opciones.gfcIncluido.next(true);
    this.calculos.COBERTURA_GFC(
      this.currencyPipe.transform(this.GFC_sumaAseguradaPesos_Universal)
    );
    this.obtenerPrimaUniversales('GFC', Number(this.GFC_sumaAseguradaPesos_Universal));
  }

  changeGFCEx() {
    this.opciones.gfcIncluido.next(false);
    this.calculos.COBERTURA_GFC(-1);
    this.borrarCobertura('GFC');
  }
  changeGFH() {
    this.opciones.gfhIncluido.next(true);
    this.calculos.COBERTURA_GFH(
      this.currencyPipe.transform(this.GFH_sumaAseguradaPesos_Universal)
    );
    this.obtenerPrimaUniversales('GFH', Number(this.GFH_sumaAseguradaPesos_Universal));
  }

  changeGFHEx() {
    this.opciones.gfhIncluido.next(false);
    this.calculos.COBERTURA_GFH(-1);
    this.borrarCobertura('GFH');
  }

  incrementos(value) {
    switch (value) {
      case 'Si':
        this.incrementosProgramados = true;
        this.opciones.inpcUniversal.next(true);
        this.opciones.incrementosProgramados.next(true);
        const increm = this.opciones.moneda === 'MXN' ? '0.04' : '0.01';
        this.estableceINPC(increm);
        break;
      case 'No':
        this.incrementosProgramados = false;
        this.opciones.incrementosProgramados.next(false);
        this.opciones.inpcUniversal.next(false);
        // document.getElementById('cmdInpcEnable').selectedIndex = 2;
        break;
    }
  }
  changeCurr(value: any) {
    this.opciones.moneda = value;
    this.opciones.inpcUniversal.next(false);
    this.calculos.COBERTURA_BASICA_UNIVERSAL(0);
    this.opciones.incrementosProgramados.next(false);
    this.valorDefault = this.obtenerSumAsegUniversales();
    switch (value) {
      case 'MXN':
        this.opciones.segundaMoneda = 'USD';
        this.recursosExternos.moneda = this.opciones.moneda;
        this.recursosExternos.segundaMoneda = this.opciones.segundaMoneda;
        this.changeActiveInput('fallecimiento', 'A');
        this.calculos.COBERTURA_BASICA_UNIVERSAL(this.valorDefault);
        this.univExcd.porcentajeFondoBasico.next(5);
        this.univExcd.porcentajeFondoExcedente.next(5);
        this.univExcd.cambiarFondo();
        break;
      case 'USD':
        this.opciones.segundaMoneda = 'MXN';
        this.recursosExternos.moneda = this.opciones.moneda;
        this.recursosExternos.segundaMoneda = this.opciones.segundaMoneda;
        this.changeActiveInput('fallecimiento', 'A');
        this.calculos.COBERTURA_BASICA_UNIVERSAL(this.valorDefault);
        this.univExcd.porcentajeFondoBasico.next(2);
        this.univExcd.porcentajeFondoExcedente.next(2);
        this.univExcd.cambiarFondo();
        break;
    }
    this.establecerPrimasUniversales();
  }
  estableceINPC(value) {
    this.calculos.inflacion_inpc_mxn.next(value);
    this.opciones.incrementoInpcPorc.next(value);
  }
  estableceIPC(value) {
    this.calculos.inflacion_inpc_usd.next(value);
  }
  estableceRiesgo(value) {
    this.valorDefault = 0;
    this.opciones.tipoRiesgo.next(value);
    this.valorDefault = this.obtenerSumAsegUniversales();
    this.calculos.COBERTURA_BASICA_UNIVERSAL(this.valorDefault);
    this.establecerPrimasUniversales();
  }

  private obtenerSumAsegUniversales() {
    let cantidad = 0;
    switch (this.opciones.tipoPlan) {
      case 'VIDA INTELIGENTE':
        if (this.opciones.moneda === 'MXN') {
          if (this.opciones.tipoRiesgo.value === 'Normal') {
            cantidad = 1000000;
          }
          if (this.opciones.tipoRiesgo.value === 'Preferente') {
            cantidad = BAUniversal.MIN_SUM_RIES_PREF_MXN;
          }
        }
        if (this.opciones.moneda === 'USD') {
          if (this.opciones.tipoRiesgo.value === 'Normal') {
            cantidad = 100000;
          }
          if (this.opciones.tipoRiesgo.value === 'Preferente') {
            cantidad = BAUniversal.MIN_SUM_RIES_PREF_USD;
          }
        }
        // this.calculos.COBERTURA_BASICA_UNIVERSAL(this.valorDefault);
        break;
      case 'MI PROYECTO R':
        if (this.opciones.moneda === 'MXN') {
          if (this.opciones.tipoRiesgo.value === 'Normal') {
            cantidad = 1000000;
          }
          if (this.opciones.tipoRiesgo.value === 'Preferente') {
            cantidad = BAUniversal.MIN_SUM_RIES_PREF_MXN;
          }
        }
        if (this.opciones.moneda === 'USD') {
          if (this.opciones.tipoRiesgo.value === 'Normal') {
            cantidad = 100000;
          }
          if (this.opciones.tipoRiesgo.value === 'Preferente') {
            cantidad = BAUniversal.MIN_SUM_RIES_PREF_USD;
          }
        }
        // this.calculos.COBERTURA_BASICA_UNIVERSAL(this.valorDefault);
        break;
    }
    return cantidad;
  }

  // Este metodo establecera todas las primas cuando se realice un cambio de producto, riesgo, moneda
  // siempre y cuendo estas contengan sumaAsegurada mayor a 0
  private establecerPrimasUniversales() {
    this.borrarCoberturasUniversales();
    this.validarPrimaUniversales('BASICA', Number(this.BASICA_sumaAsegurada_Universal));
    this.validarPrimaUniversales('GFI', Number(this.GFI_sumaAseguradaPesos_Universal));
    this.validarPrimaUniversales('GFH', Number(this.GFH_sumaAseguradaPesos_Universal));
    this.validarPrimaUniversales('GFC', Number(this.GFC_sumaAseguradaPesos_Universal));
    this.validarPrimaUniversales('PAI', Number(this.PAI_sumaAseguradaPesos_Universal));
    this.validarPrimaUniversales('DI', Number(this.DI_sumaAseguradaMXN_Universal));
    this.validarPrimaUniversales('DIPOC', Number(this.DIPOC_sumaAseguradaMXN_Universal));
    this.validarPrimaUniversales('CDC', Number(this.CDC_sumaAseguradaMXN_Universal));
    if (this.calculos.ECMI_sumaAseguradaMXN.value === 'INCLUIDO') {
      this.validarPrimaUniversales('ECMI', Number(this.BASICA_sumaAsegurada_Universal));
    }

  }

  private validarPrimaUniversales(cobertura: string, sumAsegurada: number) {
    if (sumAsegurada > 0) {
      this.obtenerPrimaUniversales(cobertura, sumAsegurada);
    }
  }

  // Solo obtendra la prima de una cobertura en particular
  private async obtenerPrimaUniversales(tipoCobertura: string, sumAsegurada: number) {
    this.borrarCobertura(tipoCobertura);
    await this.univ.obtenerPrima(tipoCobertura, sumAsegurada);
    this.asignarCoberturas(tipoCobertura);
  }

  private asignarCoberturas(tipoCobertura: string) {
    switch (tipoCobertura) {
      case 'BASICA':
        this.BASICA_primaAnualMXN_Universal = this.univ.basica.value.prima.toString();
        break;
      case 'GFI':
        this.GFI_primaAnualMXN_Universal = this.univ.gfi.value.prima.toString();
        break;
      case 'GFH':
        this.GFH_primaAnualMXN_Universal = this.univ.gfh.value.prima.toString();
        break;
      case 'GFC':
        this.GFC_primaAnualMXN_Universal = this.univ.gfc.value.prima.toString();
        break;
      case 'PAI':
        this.PAI_primaAnualUDI = this.univ.pai.value.prima;
        break;
      case 'DI':
        this.DI_primaAnualUDI = this.univ.di.value.prima;
        break;
      case 'DIPOC':
        this.DIPOC_primaAnualUDI = this.univ.dipoc.value.prima;
        break;
      case 'ECMI':
        this.BI_primaAnualUDI = this.univ.ecmi.value.prima;
        break;
      case 'CDC':
        this.IAC_primaAnualUDI = this.univ.cdc.value.prima;
        break;
    }
  }

  private borrarCobertura(tipoCobertura: string) {
    this.univ.borrarCobertura(tipoCobertura);
    this.asignarCoberturas(tipoCobertura);
  }

  private borrarCoberturasUniversales() {
    this.univ.borrarCoberturas();
    this.BASICA_primaAnualMXN_Universal = this.univ.basica.value.prima.toString();
    this.GFI_primaAnualMXN_Universal = this.univ.gfi.value.prima.toString();
    this.GFH_primaAnualMXN_Universal = this.univ.gfh.value.prima.toString();
    this.GFC_primaAnualMXN_Universal = this.univ.gfc.value.prima.toString();
    this.PAI_primaAnualMXN_Universal = this.univ.pai.value.prima.toString();
    this.DI_primaAnualMXN_Universal = this.univ.di.value.prima.toString();
    this.DIPOC_primaAnualMXN_Universal = this.univ.dipoc.value.prima.toString();
    this.ECMI_primaAnualMXN_Universal = this.univ.ecmi.value.prima.toString();
    this.CDC_sumaAseguradaMXN_Universal = this.univ.cdc.value.prima.toString();
  }

  stringToNumber(value: string): number {
    return Number(value);
  }
}
