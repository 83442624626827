import { Injectable } from '@angular/core';
import { FactoresService } from '../factores/factores.service';
import { CoverageFactors, PaymentType, PaymentFrequency } from '@app/models';
import { PlanType } from '@app/constants';
import { CalculosService } from '../calculos/calculos.service';
import { DescipherService } from '../descipher/descipher.service';
import { BAService, IACService } from '../coverages';
import { BehaviorSubject } from 'rxjs';
import { PaymentFrequencyService } from '../paymentFrequency/payment-frequency.service';

@Injectable({
  providedIn: 'root'
})
export class TarifasService {

  tablaTarifas = new BehaviorSubject<Array<any>>([]);

  /**
   * Creates an instance of TarifasService.
   * @param {FactoresService} factores
   * @param {ProspectoService} prospecto
   * @param {CalculosService} calculos
   * @param {DescipherService} deciph
   * @memberof TarifasService
   */
  constructor(
    private factores: FactoresService,
    private calculos: CalculosService,
    private deciph: DescipherService,
    private BA: BAService,
    private IAC: IACService,
    private paymentFrecuency: PaymentFrequencyService
  ) {}

  /**
   * Consigue los factores de las tarifas
   * @private
   * @method
   * @returns {CoverageFactors[]}
   * @memberof TarifasService
   */
  private getFactoresTarifas(): CoverageFactors[] {
    return this.factores.factoresCoberturaElegido;
  }

  /**
   * Decifra los factores
   * @private
   * @method
   * @param {CoverageFactors[]} factores
   * @returns {CoverageFactors[]}
   * @memberof TarifasService
   */
  private deciphFactores(factores: CoverageFactors[]): CoverageFactors[] {
    factores.forEach(el => {
      el.Tarifa = this.deciph.desiph(el.Tarifa);
      return el;
    });

    return factores;
  }

  generarTablaTarifa(tipoPlan: PlanType) {

    const LIMIT = tipoPlan === 'PROTECCION EFECTIVA' ? 65 : 60;
    const edad = 18;
    const factores: CoverageFactors[] = this.deciphFactores(this.getFactoresTarifas().filter(dato => dato.Edad >= edad && dato.Edad <= LIMIT));
    const tabla = [];

    const sumaAseguradaBASICA = [this.calculos.BASICA_sumaAseguradaMasiva_1.value,
      this.calculos.BASICA_sumaAseguradaMasiva_2.value, this.calculos.BASICA_sumaAseguradaMasiva_3.value];

    for (let i = edad; i <= LIMIT; i++) {
      const factor = Number(factores.filter(dato => Number(dato.Edad) === i)[0].Tarifa);
      let primasAseguradas = [];

      sumaAseguradaBASICA.forEach(suma => primasAseguradas.push(this.BA.calculoPrimaBasica(suma, 'Elegido', factor )));

      if (tipoPlan === 'PROTECANCER') {
        const factorIAC = i > 64 ? 0 : Number(factores.filter(dato => Number(dato.Edad) === i && dato.Cobertura === 'IAC')[0].Tarifa);
        const primasAseguradasIAC = [];

        sumaAseguradaBASICA.forEach(suma => primasAseguradasIAC.push(i > 64 ? 0 : this.IAC.calculoPrimaIAC(suma, 'Elegido', factorIAC)));

        primasAseguradas = primasAseguradas.map((suma, index) => suma += primasAseguradasIAC[index]);
      }

      primasAseguradas = primasAseguradas.map(
        (suma, index) =>
          (suma = {
            [`prima${index + 1}`]: Math.round(
              this.calculos.obtenerPrimaConRecargo(
                suma,
                this.handlePaymentFrecuency(
                  this.paymentFrecuency.frecuenciaPago.getValue()
                ),
                this.calculos.monedaUsada.getValue()
              )
            )
          })
      );

      tabla.push({edad: i, ...primasAseguradas[0], ...primasAseguradas[1], ...primasAseguradas[2]});

    }

    this.tablaTarifas.next(tabla);
  }

  private handlePaymentFrecuency(paymentFrecuency: PaymentFrequency): PaymentType {
    switch (paymentFrecuency) {
      case 'ANUAL':
        return 'yearly';
      case 'MENSUAL':
        return 'monthly';
      case 'SEMESTRAL':
        return 'semi-annually';
      case 'TRIMESTRAL':
        return 'quarterly';
    }
  }

}
