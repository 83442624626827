import { PaymentFrequencyService } from './../../services/paymentFrequency/payment-frequency.service';
import { WIDTH_BREAKPOINTS } from '@constants/screen/width-breakpoints.constant';
import { Observable } from 'rxjs';
import { CalculosService } from '@services/calculos/calculos.service';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import {
  PaymentFrequency,
  PaymentValues
} from '@models/payment-frequency/payment-frequency.type';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
// import { BuildPlanComponent } from '@app/components';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { FactoresService } from '@app/services/factores/factores.service';
import { ExcedentesService } from '@app/services/excedentes/excedentes.service';
import { SPLIT_PAYMENT } from '@app/constants';
import { PaymentType} from '@app/models';
import { ProspectoService } from '@app/services/prospecto/prospecto.service';
import { RoutesService } from '@app/services/routes/routes.service';
import { FrecuenciaPagoService } from '@app/services/universales/frecuencia-pago.service';
import { FrecuenciaPago } from '@app/models/universales/frecuencia-pago';

/**
 * payment-frecuency component (Frecuencia de pago)
 */
@Component({
  selector: 'cvida-payment-frecuency',
  templateUrl: './payment-frecuency.component.html',
  styleUrls: ['./payment-frecuency.component.sass']
})
export class PaymentFrecuencyComponent implements OnInit {
  /**
   * Width del dispositivo desde el que se accede.
   * @property
   */
  screenWidth = window.innerWidth;
  /**
   * Collapsed - Estado.
   * @property
   */
  isCollapsed = false;

  /**
   * Collapsed - Estado.
   * @property
   */
  isCollapsedArt185 = true;

  isCollapseArt = true;

  /**
   * Frecuencias de pago
   * @property
   * @type {PaymentValues[]}
   * @default "[ ]"
   */
  frecuenciasPago: PaymentValues[] = [];
  /**
   * Prima Total en UDI, fraccionada.
   * @property
   * @global
   * @type {Observable<number>}
   */
  primaTotalFraccionadaSinDividir: Observable<number>;
  /**
   * Prima Total en Pesos, fraccionada.
   * @property
   * @global
   * @type {Observable<number>}
   */
  primaTotalMoneda2SinDividir: Observable<number>;
  /**
   * Prima Total en UDI.
   * @property
   * @global
   * @type {Observable<number>}
   */
  primaTotalFraccionadaDividida: number;
  /**
   * Prima Total en Pesos.
   * @property
   * @global
   * @type {Observable<number>}
   */
  primaTotalMoneda2Dividida: number;
  /**
   * Recargo Fijo
   * @property
   * @default "0%"
   */
  recargoFijo = '0%';
  /**
   * Recargo Actual
   * @property
   * @default "0"
   */
  recargoActual = this.calculos.recargoActual;
  /**
   * Divisor Actual
   * @property
   * @default "0"
   */
  divisorActual = this.calculos.divisorActual;
  /**
   * Scrolled - Estado
   * @property
   * @default "false"
   */
  isScrolled = false;
  /**
   * Frecuencia de pago por defecto.
   * @property
   * @default "anual"
   */
  frecuenciapago = 'anual';
  /**
   * MobileWidth - Estado
   * @property
   * @default "false"
   */
  isMobileWidth = false;

  /**
   *
   * Valor de retiro - Estado
   * @param retiro
   * @type boolean
   */
  isRetiro = false;

  /**
   * Valor para sacar el deducible
   */
  deducibleUDI: number;
  deduciblePESOS: number;

  /**
   * Declaración de variables.
   * @param calculos
   * @param opciones Se crea una instancia de OpcionesPlanService.
   * @param paymentFrequency
   */

  /**
   * Valor para sacar el deducible
   */
  deducible: number;
  fctDeducible: number;
  saDeducibleUDI: number;
  saDeducibleMXN: number;
  calDeduciblePesos: number;
  valorUDI: number;
  deducibleUDI_redondeo: number;
  deducibleMXN_redondeo: number;
  saReplaceDeducible: string;

  valorExcedente: boolean;
  primaTotalValue: number;
  pTpesos: number;
  primaTPesos: number;
  pT: number;
  ptPesos: number;

  primaAnualMasivo_1: string;
  primaAnualMasivo_2: string;
  primaAnualMasivo_3: string;
  arrPagoFrecuencias: FrecuenciaPago[];

  @Input() isIndividual: boolean;

  constructor(
    private calculos: CalculosService,
    public opciones: OpcionesPlanService,
    private paymentFrequency: PaymentFrequencyService,
   // private retiro: BuildPlanComponent,
    private recursos: RecursosExternosService,
    private factorD: FactoresService,
    private excedentes: ExcedentesService,
    private prospecto: ProspectoService,
    private frec: FrecuenciaPagoService,
    public routes: RoutesService
  ) {
    if (this.opciones.plan === 'Universales') {
      this.universales();
    } else {
      this.protgt();
    }
  }

  universales() {
    this.frec.frecuenciaPagoUniv.subscribe(values => {
      if (values.length > 0) {
        this.arrPagoFrecuencias = values;
        this.setFrequency(this.paymentFrequency.frecuenciaPago.value);
      }

    });
  }

  protgt() {
    this.valorUDI = this.recursos.valor_UDI;
    this.screenWidth = window.innerWidth;

    // GLOBALES
    this.prospecto.prospectoActual.distinctUntilChanged().last().subscribe(data => {
      if (data.age <= 60 && this.opciones.tipoPlan === 'DOTAL') {
        this.factorD.obtenerFactorDedicibleAliasdosMas65(data);
      }
    });
    this.factorD.retiroValue.subscribe(val => {
      this.isRetiro = val;
    });
    this.fctDeducible = Number(this.factorD.factorDeducible);
    Observable.combineLatest(this.calculos.BASICA_sumaAseguradaUDI, this.excedentes.aportacion_anual_UDI).distinctUntilChanged()
    .map(val => val.reduce((val1, val2) => val1)).map(valu => valu).subscribe(valor => {
    // this.isRetiro = this.retiro.boolRetiro;
    this.deducible = (valor * this.factorD.factorDeducible) / 1000;
    this.deducibleUDI_redondeo = Math.round(this.deducible);
    this.calDeduciblePesos = Math.round(this.deducible) * this.valorUDI;
    this.deducibleMXN_redondeo = Math.round(this.calDeduciblePesos);
    if (this.deducibleMXN_redondeo > 152000) {
      this.deducibleMXN_redondeo = 152000;
      let ejemplo;
      ejemplo = this.deducibleMXN_redondeo / this.valorUDI;
      this.deducibleUDI = ejemplo;
      this.deducibleUDI_redondeo = Math.round(this.deducibleUDI);
    }
    });

    this.primaTotalFraccionadaSinDividir = this.calculos.primaTotalFraccionadaSinDividir;

    this.calculos.sumaPrimas.subscribe(prima => this.pT = prima + this.excedentes.aportacion_anual_UDI.value);
    this.calculos.sumaPrimasSegMon.subscribe(prima => this.ptPesos = prima);

    this.primaTotalMoneda2SinDividir = this.calculos.primaTotalMoneda2SinDividir$;

    this.calculos.monedaUsada.subscribe(() => {
      this.recargoFijo = (this.calculos.recargoActual * 100).toFixed(1) + '%';
    });

    this.excedentes.aportacion_anual_UDI.subscribe(aportacion =>
      aportacion !== 0
        ? (this.valorExcedente = true)
        : (this.valorExcedente = false)
    );

    Observable.combineLatest([
      this.calculos.primaAnual_ConRecargo_Actual,
      this.excedentes.aportacion_anual_UDI
    ]).map(el => el.reduce((el1, el2) => el1 + el2)).map(el => el).
    subscribe(value => {
      this.valorUDI = this.recursos.valor_UDI;
      this.primaTotalFraccionadaDividida = value;
      this.primaTotalMoneda2Dividida = Math.round(value * this.valorUDI);
    });

    this.excedentes.aportacion_anual_UDI.distinctUntilChanged()
      .subscribe(aporte => {
        this.pT = this.calculos.sumaPrimas.value + aporte;
        this.ptPesos = this.calculos.sumaPrimasSegMon.value + this.recursos.FirstToSecond(aporte);
      });

    Observable.combineLatest([
      this.calculos.primaTotalFraccionadaDividida$,
      this.excedentes.aportacion_anual_UDI
    ])
      .do(el => {
        switch (this.paymentFrequency.frecuenciaPago.value) {
          case 'ANUAL':
            this.frecuenciapago = 'anual';
          break;

          case 'SEMESTRAL':
          this.frecuenciapago = 'semestral';
            break;

          case 'TRIMESTRAL':
          this.frecuenciapago = 'trimestral';
            break;

          case 'MENSUAL':
          this.frecuenciapago = 'mensual';
            break;
        }

        if (el[1] !== 0) {
          let splitPayment: PaymentType = 'yearly';

          switch (this.paymentFrequency.frecuenciaPago.value) {
            case 'ANUAL':
              splitPayment = 'yearly';
              break;

            case 'SEMESTRAL':
              splitPayment = 'semi-annually';
              break;

            case 'TRIMESTRAL':
              splitPayment = 'quarterly';
              break;

            case 'MENSUAL':
              splitPayment = 'monthly';
              break;
          }

          const divisor = SPLIT_PAYMENT.filter(i => i.type === splitPayment)[0]
            .divider;

          return (el[1] = Math.round(el[1] / divisor));
        }
        return el;
      })
      .map(el => el.reduce((el1, el2) => el1 + el2))
      .map(el => el)
      .subscribe(val => {
        this.valorUDI = this.recursos.valor_UDI;
        this.primaTotalValue = val;
        this.pTpesos = val * this.valorUDI;
        this.primaTPesos = Math.round(this.pTpesos);
      });

    this.initMasivoValues();
  }


  /**
   *  Inicialización de variables.
   */
  ngOnInit() {
    this.frecuenciasPago = this.paymentFrequency.generatePaymentFrequencies();
    this.opciones.tipoPlan_Ob.distinctUntilChanged().subscribe(value => this.setFrequency(this.paymentFrequency.frecuenciaPago.value));
  }

  initMasivoValues(): void {
    this.calculos.primaTotalMasiva1
      .subscribe(prima => this.primaAnualMasivo_1 = String(prima));
    this.calculos.primaTotalMasiva2
      .subscribe(prima => this.primaAnualMasivo_2 = String(prima));
    this.calculos.primaTotalMasiva3
      .subscribe(prima => this.primaAnualMasivo_3 = String(prima));
  }

  /**
   * Listener que detecta el redimensionamiento de la ventana, especificamente, el width.
   * @event
   * @param event
   */
  @HostListener('window:resize', ['event']) onResize(event) {
    this.screenWidth = window.innerWidth;
    this.isMobileWidth = this.screenWidth <= WIDTH_BREAKPOINTS['5'];
  }

  /**
   * Evento scroll de ventana
   * Si se esta en una resolución móvil, al realizar un scroll este
   * activara en el BREAKPOINT a la propiedad 'isScrolled' a true
   * para dejar fijo este componente y asi mismo quitar el desplegable.
   * @param $event
   * @memberof PaymentFrecuencyComponent
   */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const scrollTop = $event.target.scrollingElement.scrollTop;
    const BREAKPOINT = 207;
    if (
      scrollTop > BREAKPOINT &&
      (this.isMobileWidth || this.screenWidth <= WIDTH_BREAKPOINTS['5'])
    ) {
      this.isScrolled = true;
      this.isCollapsed = true;
    } else {
      this.isScrolled = false;
      this.isCollapsed = false;
    }
  }

  @HostListener('window:change', ['$event']) onChange(event) {
   }

  /**
   * Método que determina las diversas frecuencias de pago con sus factores correspondientes.
   * @method
   * @param {*} value
   */
  setFrequency(value: PaymentFrequency) {
    const univ = this.opciones.plan === 'Universales' ? true : false;
    this.paymentFrequency.frecuenciaPago.next(value);
    this.calculos.paymentFrecuency = value as PaymentFrequency;
    this.calculos.calcularPagoDividido(this.opciones.tipoPlan);
    switch (value as PaymentFrequency) {
      case 'ANUAL':
        this.frecuenciapago = 'anual';
        this.recargoFijo = univ ? '0' : ((this.calculos.recargoActual * 100).toFixed(0) + '%');
        break;
      case 'SEMESTRAL':
        this.frecuenciapago = 'semestral';
        this.recargoFijo = univ ? '0' : ((this.calculos.recargoActual * 100).toFixed(1) + '%');
        break;
      case 'TRIMESTRAL':
        this.frecuenciapago = 'trimestral';
        this.recargoFijo = univ ? '0' : ((this.calculos.recargoActual * 100).toFixed(1) + '%');
        break;
      case 'MENSUAL':
        this.frecuenciapago = 'mensual';
        this.recargoFijo = univ ? '0' :  ((this.calculos.recargoActual * 100).toFixed(1) + '%');
        break;
    }
    if (univ) {
      this.arrPagoFrecuencias.filter(s => s.seleccionado === true)
      .map( s => {
          s.seleccionado = false;
      });
      const frecuencia = this.arrPagoFrecuencias.filter(s => s.nombre === value)[0];
      this.pT = frecuencia.primaAnual;
      this.primaTotalValue = frecuencia.primaFormaPago;
      frecuencia.seleccionado = true;
    }
  }
}
