import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertModalComponent } from '@app/shared/modal/alert-modal/alert-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalService } from '../utils/modal.service';
@Injectable()
export class ConnectionInterceptor implements HttpInterceptor {

    modalRef: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private modal: ModalService
    ){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!navigator.onLine)
        {
            this.mostrarModal('Error de conexion, intenta de nuevo.');
        }

        return next.handle(req);
    }

    private mostrarModal(descripcion: string) {
        this.modal.bolBodalServ = true;
        this.modal.descripcion = descripcion;
        this.modalRef = this.modalService.show(AlertModalComponent, {
            backdrop: false,
            class: 'alert-modal'
        });
        this.modal.bolBodalServ = false;
    }

}