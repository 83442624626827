/** Constantes para el nombrado de rutas del flujo de cotización*/

export const ROUTE_NAMING = {
  PRINCIPAL: 'cotizador',
  TEMPLATE: 'reporte',
  PASO1: 'datos-prospecto',
  PASO2A: 'arma-tu-plan',
  PASO2B: 'identificar-objetivo',
  PASO3: 'propuestas',
  PASO4: 'valores-garantizados',
  HISTORY: 'historial-cotizaciones',
  NO_ENCONTRADO: '404',
  VALORESTEMPLATE: 'pdf-valores',
  PLAZOPAGO_CONFIG: 'configurar-plazo',
  LANDING: 'landing',
  PROTGT: 'ProTGT',
  ALIADOS: 'Aliados',
  REQUISITOS: 'requisitos-asegurabilidad',
  AXAPARATODOS: 'AxaParaTodos',
  TARIFAS: 'tarifas',
  PROTEFECAN: 'pdf-protefecan',
  UNIVERSALES: 'Universales',
  PERFILAMIENTO: 'estrategia-inversion',
  PASO4UNI: 'primas-excedentes',
  PASO5UNI: 'valores-proyectados',

};
