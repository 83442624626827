import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';

// Componente
import { TextMaskModule } from 'angular2-text-mask';
import { LandingComponent } from './landing.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MatTabsModule } from '@angular/material';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    MatRadioModule,
    TextMaskModule,
    MatTabsModule,
    CollapseModule,
    ReactiveFormsModule,
    CarouselModule.forRoot()
  ],
  declarations: [
    LandingComponent
  ],
  exports: [
    LandingComponent,
    MatRadioModule
  ]
})
export class LandingModule { }
