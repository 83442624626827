/** PIPES */
import { PipesModule } from '@pipes/pipes.module';
/** FORMS */
import { FormsModule } from '@angular/forms';
/** CORE */
import { NgModule } from '@angular/core';
/** COMMON  */
import { CommonModule } from '@angular/common';
/** BROWSER */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
/** COMPONENTS */
import { DownloadPlansComponent } from '@components/download-plans/download-plans.component';
/** MODULE */
import { SharedModule } from '@shared/shared.module';
/** MATERIAL */
import { MatSlideToggleModule, MatBadgeModule } from '@angular/material';
import { CollapsibleModule } from 'angular2-collapsible';
/** NGX-BOOTSTRAP */
import { CollapseModule } from 'ngx-bootstrap/collapse';
/**
 * MODULO DE COMPONENTE DESCARGAR PLANES
 * @export
 * @class DownloadPlansModule
 */
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    CollapsibleModule,
    CollapseModule.forRoot(),
    SharedModule,
    PipesModule,
    MatSlideToggleModule,
    MatBadgeModule,
    FormsModule
  ],
  declarations: [
    DownloadPlansComponent
  ],
  exports: [
    MatSlideToggleModule,
    MatBadgeModule
  ]
})
export class DownloadPlansModule { }
