/** Angular Core */
import { Injectable } from '@angular/core';
/** Models */
import { Prospectu } from '@models/prospectu/prospectu.model';
import { CoverageStatus } from '@models/coverage-status/coverage-status';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { Plazo } from '../../../models';
/** Services */
import { FactoresService } from '@services/factores/factores.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { BAService } from '@services/coverages/BA/ba.service';
import { BIService } from '@services/coverages/BI/bi.service';
import { PAIService } from '@services/coverages/PAI/pai.service';
import { CCService } from '@services/coverages/CC/cc.service';
import { IACService } from '@services/coverages/IAC/iac.service';
import { PAEService } from '@services/coverages/PAE/pae.service';
import { DIService } from '@services/coverages/DI/di.service';
import { DIPOService } from '@services/coverages/DIPO/dipo.service';
import { DIPOCService } from '@services/coverages/DIPOC/dipoc.service';
import { GFService } from '@services/coverages/GF/gf.service';
// import { TUTORService } from '@services/coverages/TUTOR/tutor.service'; TODO:ALIADOSKIDS
// import { EFIService } from '@services/coverages/EFI/efi.service'; 
/** Rxjs */
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import 'rxjs/Observable';
/** Constants */
import { PCD } from '@constants/coverages';
import { VPLService } from '../VPL/vpl.service';
import { CDCService } from '../CDC/cdc.service';
import { OpcionesPlanService } from './../../opcionesPlan/opciones-plan.service';

/**
 * Servicio para cobertura PCD
 */
@Injectable({
  providedIn: 'root'
})
export class PCDService {
  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_UDI = new BehaviorSubject<CoverageStatus>('EXCLUIDO');
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_MXN = new BehaviorSubject<CoverageStatus>('EXCLUIDO');
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);
  // Prima anual sugerida 2
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();

  factorDiezMasPcd = 0;

  /**
   * Primas de todas las coberturas
   * @property
   * @type {Subscription}
   * @private
   */
  private primasCoberturas: Subscription;

  /**
   * Constructor
   * @constructor
   * @param recursosExternos Crea instancia de RecursosExternosService
   * @param BA Crea instancia de BAService
   * @param BI Crea instancia de BIService
   * @param PAI Crea instancia de PAIService
   * @param CC Crea instancia de CCService
   * @param IAC Crea instancia de IACService
   * @param PAE Crea instancia de PAEService
   * @param DI Crea instancia de DIService
   * @param DIPO Crea instancia de DIPOService
   * @param DIPOC Crea instancia de DIPOCService
   * @param GF Crea instancia de GFService
   * @param prospectoService Crea instancia de ProspectoService
   * @param factores Crea instancia de FactoresService
   */
  constructor(
    private recursosExternos: RecursosExternosService,
    private BA: BAService,
    private BI: BIService,
    private PAI: PAIService,
    private CC: CCService,
    private IAC: IACService,
    private PAE: PAEService,
    private DI: DIService,
    private DIPO: DIPOService,
    private DIPOC: DIPOCService,
    private GF: GFService,
    private prospectoService: ProspectoService,
    private factores: FactoresService,
    private VPL: VPLService,
    private CDC: CDCService,
    private opc: OpcionesPlanService
  ) {
    this.BA.primaAnual_UDI$.distinctUntilChanged().subscribe(() => {
      this.establecePrimaAnual(
        this.sumaAsegurada_UDI.value,
        this.prospectoService.prospecto.value);
    });
  }
  /**
   * Establece prima anual de la cobertura
   * @param {CoverageStatus} status
   * @param {Prospecto} prospecto
   */
  establecePrimaAnual(status: CoverageStatus, prospecto: Prospectu): void {
    const edad = (prospecto.mancomunado.mancomunado ? prospecto.mancomunado.edadEquivalente : prospecto.age);
    this.sumaAsegurada_UDI.next(status);
    this.sumaAsegurada_MXN.next(status);

    if (
      this.sumaAsegurada_UDI.value === 'INCLUIDO' &&
      this.sumaAsegurada_MXN.value === 'INCLUIDO'
    ) {

      if (
        edad >= PCD.ACCEPTANCE_AGE.MIN &&
        edad <= PCD.ACCEPTANCE_AGE.MAX
      ) {
        this.primasCoberturas = combineLatest(
          this.BA.primaAnual_UDI$,
          this.BI.primaAnual_UDI$,
          this.PAI.primaAnual_UDI$,
          this.DI.primaAnual_UDI$,
          this.DIPO.primaAnual_UDI$,
          this.DIPOC.primaAnual_UDI$,
          this.IAC.primaAnual_UDI$,
          this.PAE.primaAnual_UDI$,
          this.CC.primaAnual_UDI$,
          this.GF.primaAnual_UDI$,
          this.CDC.primaAnual_PrimeraMoneda,
          this.VPL.primaAnual_PrimeraMoneda
        ).subscribe(primasTotales => {
          if (this.sumaAsegurada_UDI.value === 'INCLUIDO') {
            const arr: number[] = [];
            primasTotales.forEach(item => arr.push(this.recursosExternos.FirstToUDI(item)));

            const primaAnual_UDI = this.calculoPrimaPCD(arr, 'Elegido');
            const primaAnual_MXN = this.recursosExternos.UDIToSecond(primaAnual_UDI);

            this.primaAnual_UDI.next(this.recursosExternos.UDIToFirst(primaAnual_UDI));
            this.primaAnual_MXN.next(primaAnual_MXN);
          }

        });

        this.primasCoberturas = combineLatest(
          this.BA.primaAnual_UDI_Sugerida1,
          this.BI.primaAnual_UDI_Sugerida1,
          this.PAI.primaAnual_UDI_Sugerida1,
          this.DI.primaAnual_UDI$,
          this.DIPO.primaAnual_UDI$,
          this.DIPOC.primaAnual_UDI$,
          this.IAC.primaAnual_UDI_Sugerida1,
          this.PAE.primaAnual_UDI_Sugerida1,
          this.CC.primaAnual_UDI_Sugerida1,
          this.GF.primaAnual_UDI_Sugerida1
        ).subscribe(primasTotales => {
          if (this.sumaAsegurada_UDI.value === 'INCLUIDO') {
            const arr: number[] = [];
            primasTotales.forEach(item => arr.push(this.recursosExternos.FirstToUDI(item)));

            const primaAnual_UDI_Sugerida1 = this.calculoPrimaPCD(arr, 'Sugerido1');
            const primaAnual_MXN_Sugerida1 = this.recursosExternos.UDIToSecond(primaAnual_UDI_Sugerida1);

            this.primaAnual_UDI_Sugerida1.next(this.recursosExternos.UDIToFirst(primaAnual_UDI_Sugerida1));
            this.primaAnual_MXN_Sugerida1.next(primaAnual_MXN_Sugerida1);
          }

        });

        this.primasCoberturas = combineLatest(
          this.BA.primaAnual_UDI_Sugerida2,
          this.BI.primaAnual_UDI_Sugerida2,
          this.PAI.primaAnual_UDI_Sugerida2,
          this.DI.primaAnual_UDI$,
          this.DIPO.primaAnual_UDI$,
          this.DIPOC.primaAnual_UDI$,
          this.IAC.primaAnual_UDI_Sugerida2,
          this.PAE.primaAnual_UDI_Sugerida2,
          this.CC.primaAnual_UDI_Sugerida2,
          this.GF.primaAnual_UDI_Sugerida2,
        ).subscribe(primasTotales => {
          if (this.sumaAsegurada_UDI.value === 'INCLUIDO') {
            const arr: number[] = [];
            primasTotales.forEach(item => arr.push(this.recursosExternos.FirstToUDI(item)));

            const primaAnual_UDI_Sugerida2 = this.calculoPrimaPCD(arr, 'Sugerido2');
            const primaAnual_MXN_Sugerida2 = this.recursosExternos.UDIToSecond(primaAnual_UDI_Sugerida2);

            this.primaAnual_UDI_Sugerida2.next(this.recursosExternos.UDIToFirst(primaAnual_UDI_Sugerida2));
            this.primaAnual_MXN_Sugerida2.next(primaAnual_MXN_Sugerida2);

          }
        });

      }

    } else {
      if (this.primasCoberturas !== undefined) {
        this.primasCoberturas.unsubscribe();
      }
      this.resetValues();
    }
  }

  /**
   * Realiza el calculo de la tarifa
   *
   * @param {number} calculoPrimaPCD
   * @returns {number} Prima PCD
   * @memberof BAService
   */

  calculoPrimaPCD(
    primasTotales: number[], plazo: Plazo, isINCP: boolean = false, edadInpc?: number
  ): number {
    const factorPCD = this.factores.obtenerFactorCobertura('PCD', plazo, isINCP, edadInpc);

    let sumaPrimas = 0;

    for (const prima of primasTotales) {
      sumaPrimas += prima;
    }

    // Retorna el resultado de la operación
    const primaPcd = sumaPrimas * factorPCD / 1000;
    if (plazo === 'Elegido') {
      this.factorDiezMasPcd = factorPCD;
    }
    return primaPcd;
  }

  resetValues() {
    this.primaAnual_UDI.next(0);
    this.primaAnual_MXN.next(0);
    this.primaAnual_UDI_Sugerida1.next(0);
    this.primaAnual_MXN_Sugerida1.next(0);
    this.primaAnual_UDI_Sugerida2.next(0);
    this.primaAnual_MXN_Sugerida2.next(0);
  }

  calcularprimaDiezMasPcd(primasTotales: number, edad: number) {
    let primasPcd = 0;
    const prPcd = this.opc.coberturaEnVigencia(this.primaAnual_UDI.value, PCD, edad);
    if (prPcd > 0) {
      primasTotales -= prPcd;
      primasPcd = primasTotales * this.factorDiezMasPcd / 1000;
      primasTotales += primasPcd;
    }
    return primasTotales;
  }

}
