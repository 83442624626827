/**
 * Breakpoints respecto al Width.
 * @const
 */
export const WIDTH_BREAKPOINTS = {
  0: 320,
  1: 380,
  2: 430,
  3: 520,
  4: 720,
  5: 992
};
