import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CalculosService } from '@app/services/calculos/calculos.service';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
// import { PlanType, SPLIT_PAYMENT, PROTEF} from '@app/constants';
import { PlanType, SPLIT_PAYMENT } from '@app/constants';
import { ProspectoService } from '@app/services/prospecto/prospecto.service';
import { Prospectu, User } from '@app/models';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import * as moment from 'moment';
import { PaymentFrequencyService } from '@app/services/paymentFrequency/payment-frequency.service';

moment.locale('es');

/**
 * Componente del template
 */
@Component({
  selector: 'cvida-template-protefectiva',
  templateUrl: './template-protefectiva.component.html',
  styleUrls: ['./template-protefectiva.component.sass']
})
export class TemplateProtefectivaComponent implements OnInit {

  /**
   * Selecciona y checa item de html pdfQuotation
   * @property
   * @type {ElementRef}
   */
  @ViewChild('pdfQuotation') pdfTranslate: ElementRef;

  tipoPlan: PlanType = this.opciones.tipoPlan;
  prospecto: Prospectu = this.prospectoS.prospecto.value;
  agente: User = this.auth.user.value;
  date: string;
  frecuenciaPago: string;

  sumaAsegurada: number = this.calculos.BASICA_sumaAseguradaUDI.value;
  sumaPrimas: number = this.calculos.sumaPrimas.value;

  primaBasica: number = this.calculos.BASICA_primaAnualUDI.value;
  primaIAC: number = this.calculos.IAC_primaAnualUDI.value;

  primaSemestral: number;
  primaMensual: number;
  primaTrimestral: number;

  primaFraccionadaElegida: number = Math.round(this.calculos.primaTotalFraccionadaDividida.value);

  constructor(
    private calculos: CalculosService,
    private opciones: OpcionesPlanService,
    private prospectoS: ProspectoService,
    private auth: AuthenticationService,
    private paymentFrequency: PaymentFrequencyService
    ) {
      this.date = `${moment().format('DD')} de ${moment().format('MMMM')} del ${moment().format('YYYY')}`;
      this.frecuenciaPago = this.paymentFrequency.frecuenciaPago.value.toLowerCase();
      this.establecerPagosDivididos();
    }

  establecerPagosDivididos(): void {
    const divisores = SPLIT_PAYMENT.map(payment => ({ type: payment.type, divider: payment.divider }));

    if (this.tipoPlan === 'PROTECCION EFECTIVA') {
      //const multiplier = PROTEF + 1;
      const divisorSemestral = divisores.filter(divisor => divisor.type === 'semi-annually')[0].divider;
      const divisorMensual = divisores.filter(divisor => divisor.type === 'monthly')[0].divider;
      const divisorTrimestral = divisores.filter(divisor => divisor.type === 'quarterly')[0].divider;

      this.primaMensual     = Math.round((this.sumaPrimas / 0.9) / divisorMensual);
      this.primaSemestral   = Math.round((this.sumaPrimas / 0.9 ) / divisorSemestral);
      this.primaTrimestral  = Math.round((this.sumaPrimas / 0.9) / divisorTrimestral);

    } 
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  
  imprimirPDF() {
    window.print();
  }

  volver() {
    window.history.back();
  }
}
