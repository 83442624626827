/** Angular Core */
import { Injectable } from '@angular/core';

// Utilidades


import { HttpClient } from '@angular/common/http';
// import { HttpClientModule } from '@angular/common/http';
// import { HttpModule } from '@angular/http';

/**
 * Servicio dedicado al envio de correos
 * @export
 * @class MailService
 */
@Injectable({
  providedIn: 'root'
})
export class MailService {
  proxy = 'https://cors-anywhere.herokuapp.com/';


  constructor(private http: HttpClient) {}

  /**
   *
   * @method
   */
  sendMail(nomAgente: string, mailAgente: string, numAgente: string, nomPros: string, fecNac: string, folio: string,
          docMed: string , fecCit: string, hrCit: string, numPros: string, pdf: string | ArrayBuffer) {
    return this.http.post(
      `http://servicioswebqa.axa.com.mx:9614/AXA/CxF/sendEmailService`,
      `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ws="http://ws.pyme.praxis.com/">
        <soapenv:Header/>
        <soapenv:Body>
         <ws:sendEmailUseTemplateID>
            <idUser>2</idUser>
            <tokenUser>1A6c9d7310f8cb96aXvsfake0pLa2c4</tokenUser>
            <idTemplate>120</idTemplate>
            <subject>cita medica</subject>
            <sender>citamedicavidaindividual@axa.com.mx</sender>
            <targets>veronicalizbeth.terres.consultor@axa.com.mx</targets>
            <targets>andrey.cordero.consultor@axa.com.mx</targets>
            <!--Zero or more repetitions:-->
            <dinamicContent>${nomAgente}</dinamicContent>
            <dinamicContent>${mailAgente}</dinamicContent>
            <dinamicContent>${numAgente}</dinamicContent>
            <dinamicContent>${nomPros}</dinamicContent>
            <dinamicContent>${fecNac}</dinamicContent>
            <dinamicContent>${folio}</dinamicContent>
            <dinamicContent>${docMed}</dinamicContent>
            <dinamicContent>${fecCit}</dinamicContent>
            <dinamicContent>${hrCit}</dinamicContent>
            <dinamicContent>${numPros}</dinamicContent>
            <attachments>
            <contentType>pdf</contentType>
            <file>${pdf}</file>
            <name>Cotizador Vida - AXA.pdf</name>
            </attachments>
         </ws:sendEmailUseTemplateID>
      </soapenv:Body>
    </soapenv:Envelope>`,
    {
        responseType: 'text'
    }
    );
  }

}
