import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '@env/environment';
import { ShellModule } from '@shell/shell.module';
import { AppRoutingModule } from '@app/app.routing';
import { AppComponent } from '@app/app.component';
import { BuildPlanModule } from '@components/build-plan/build-plan.module';
import { GuaranteedValuesModule } from '@components/guaranteed-values/guaranteed-values.module';
import { NecessityModule } from '@components/necessity/necessity.module';
import { ProposalsModule } from '@components/proposals/proposals.module';
import { ProspectusDataModule } from '@components/prospectus-data/prospectus-data.module';
import { TemplateModule } from '@components/template/template.module';
import { NotPermittedModule } from '@components/not-permitted/not-permitted.module';
import { ValoresTemplateModule } from '@components/valores-template/valores-template.module';
import { DownloadPlansModule } from '@components/download-plans/download-plans.module';
import { LandingModule } from '@components/landing/landing.module';
import { RequirementsCoverageModule } from '@components/requirements-coverage/requirements-coverage.module';
import { TemplateProtefectivaModule } from '@components/template-protecion-efectiva/template-protefectiva.module';
import { ConyugalPlazoPagoPipe } from '@pipes/conyugal-plazo-pago/conyugal-plazo-pago.pipe';
import { ProfilingModule} from '@components/profiling/profiling.module';

import { BnNgIdleService } from 'bn-ng-idle';
import { SurplusModule } from './components/surplus/surplus.module';
import { ConnectionInterceptor } from './services/interceptors/Connection.interceptor';
import { HttpErrorInterceptor } from './services/interceptors/HttpErrorInterceptor';

/**
 * APP MODULE
 * @description Modulo principal de la aplicación, contiene todos las importaciones
 */
@NgModule({
  // Directivas
  declarations: [
    // Componentes
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    HttpClientModule,
    ShellModule,
    BuildPlanModule,
    GuaranteedValuesModule,
    NecessityModule,
    ProposalsModule,
    ProspectusDataModule,
    TemplateModule,
    ValoresTemplateModule,
    BuildPlanModule,
    DownloadPlansModule,
    LandingModule,
    RequirementsCoverageModule,
    TemplateProtefectivaModule,
    NotPermittedModule,
    ProfilingModule,
    SurplusModule
  ],
  providers: [BnNgIdleService, 
    ConyugalPlazoPagoPipe, 
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: ConnectionInterceptor, multi: true 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor, multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
