/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { PAYMENT_FREQUENCIES } from '@constants/payment-frequencies/payment-frequencies.constant';
import { DEFAULT_PAYMENT_FREQUENCY } from './../../constants/payment-frequencies/payment-frequencies.constant';
/** Models */
import { PaymentValues } from './../../models/payment-frequency/payment-frequency.type';
import { PaymentFrequency } from '@models/payment-frequency/payment-frequency.type';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';

/**
 * Servicio encargado de generar las diversas frecuencias de pago y cuestiones relacionadas a ellas.
 */
@Injectable({
  providedIn: 'root'
})
export class PaymentFrequencyService {
 /**
  * Recargo Fijo. Se crea una instancia reactiva
  * @property
  * @type {BehaviorSubject<string>}
  * @default "0%"
  */
  recargoFijo = new BehaviorSubject<string>('0%');
 /**
  * Recargo Actual. Se crea una instancia reactiva
  * @property
  * @type {BehaviorSubject<number>}
  * @default "0"
  */
  recargoActual = new BehaviorSubject<number>(0);
 /**
  * Divisor Actual. Se crea una instancia reactiva.
  * @property
  * @type {BehaviorSubject<>}
  * @default "0"
  */
  divisorActual = new BehaviorSubject<number>(0);
 /**
  * Frecuencia de pago por defecto. Se crea una instancia reactiva.
  * @property
  * @type {BehaviorSubject<>}
  * @default DEFAULT_PAYMENT_FREQUENCY
  */
  frecuenciaPago = new BehaviorSubject<PaymentFrequency>(DEFAULT_PAYMENT_FREQUENCY);

  /**
   * Método que genera las distintas frecuencias de pago.
   * @method
   */
  generatePaymentFrequencies(): PaymentValues[] {

    const actualPaymentFrequency = this.frecuenciaPago.value || DEFAULT_PAYMENT_FREQUENCY;
    let paymentFrequencies: PaymentValues[] = [];

    PAYMENT_FREQUENCIES.forEach( (item: PaymentFrequency) => {
      paymentFrequencies = [...paymentFrequencies, { value: item, default: item === actualPaymentFrequency }];
    });
    return paymentFrequencies;

  }

}
