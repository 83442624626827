/** Angular Core */
import { Injectable } from '@angular/core';
/**
 * Servicio encargado de detectar el tipo de Sistema operativo del dispositivo desde el que se accede y clasificar el dispositivo.
 */
@Injectable({
  providedIn: 'root'
})
export class DeviceOSService {
  /**
   * Almacena el estado del tipo de dispositivo Desktop
   * @property
   * @public
   * @type {boolean}
   */
  public isDesktop: boolean;
  /**
   * Almacena el estado del tipo de Sistema Operativo Android
   * @property
   * @public
   * @type {boolean}
   */
  public isAndroid: boolean;
  /**
   * Almacena el estado del tipo de dispositivo Mobile
   * @property
   * @public
   * @type {boolean}
   */
  public isMobile: boolean;
  /**
   * Almacena el estado del tipo de Sistema Operativo Linux
   * @property
   * @public
   * @type {boolean}
   */
  public isLinux: boolean;
  /**
   * Almacena el estado del tipo de Sistema Operativo iOS
   * @property
   * @public
   * @type {boolean}
   */
  public isIOS: boolean;
  /**
   * Propiedad que almacena y abstrae el valor de navigator.userAgent.
   * @property
   * @private
   * @default navigator.userAgent.toLowerCase()
   */
  private nav = navigator.userAgent.toLowerCase();

  /**
   * Obtiene el Sistema Operativo del dispositivo desde el que se accede, asimismo clasifica dicho dispositivo.
   * @method
   */
  getSystemType() {
    if (/(ipod|phone|ipad)/.test(this.nav)) {
      this.isIOS = true;
      this.isMobile = true;
    } else if (/android/.test(this.nav)) {
      this.isAndroid = true;
      this.isMobile = true;
    } else if (/linux/.test(this.nav)) {
      this.isLinux = true;
    } else {
      this.isDesktop = true;
    }
  }

  /**
   * Obtiene el Sistema Operativo del dispositivo desde el que se accede, y retorna el valor obtenido.
   * @method
   */
  getSysType(): 'android' | 'ios' | 'linux' | 'desktop' {
    switch (true) {
      case (/(ipod|phone|ipad)/.test(this.nav)):
        return 'ios';
      case (/android/.test(this.nav)):
        return 'android';
      case (/linux/.test(this.nav)):
        return 'linux';
      default:
        return 'desktop';
    }
  }

}
