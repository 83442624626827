/** Angular Core */
import { Injectable } from '@angular/core';
/** Models */
import { Prospectu } from '@models/prospectu/prospectu.model';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';
/**
 * Servicio encargado de la creación, seguimiento y actualización del prospecto.
 */
@Injectable({
  providedIn: 'root'
})
export class ProspectoService {

  /**
   * Prospecto. Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<Prospectu>}
   * @default "new Prospectu()"
  */
  prospecto = new BehaviorSubject<Prospectu>(new Prospectu());
  /**
   * Prospecto actual (observable).
   * @property
   */
  prospectoActual = this.prospecto.asObservable();
  /**
   * Estado del prospecto. Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<boolean>}
   * @default "false"
  */
  isProspectuActive = new BehaviorSubject<boolean>(false);

  constructor() {}

  /**
   * Método que actualiza al prospecto.
   * @method
   * @param {Prospectu} prospecto
   */
  changeProspecto(prospecto: Prospectu) {
    this.prospecto.next(prospecto);
  }



}
