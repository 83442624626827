/**
 * REQUISITOS DE ASEGURABILIDAD DE VIDA
 * Médicos
 * @description Se describen las constantes de requistos médicos de asegurabiidad
 * de vida.
 * Las constantes de efectivo se encuentran en moneda nacional.
 *
 * Vigente a partir del 1° de Noviembre de 2016
 */

export const MEDICAL_REQUIREMENTS = {
  /**
   * ETAPA DE EDAD
   * @description Especificación de etapas de edad de un prospecto
   * @property {number} start - Edad de inicio de una etapa
   * @property {number} end - Edad de fin de una etapa
  */
  AGE_STAGE: [
    { start: 18, end: 29 },
    { start: 30, end: 49 },
    { start: 50, end: 59 },
    { start: 60, end: 70 },
  ],
  /**
   * INTERVALOS POR SUMA ASEGURADA
   * @description Arreglo que define los intervalos de suma asegurada para
   * petición de documentos dependiendo de la etapa de edad del prospecto.
   * Debe tener el mismo orden que el arreglo de ETAPA DE EDAD.
  */
  SUM_ASSURED: [
    [ 5000000,  7000000,  7000001 ],
    [ 5000000,  10000000, 10000001],
    [ 4000000,  5000000,  5000001 ],
    [ 1500000,  1800000, 1800001 ],
  ],
  /**
   * DOCUMENTOS
   * @description Documentos que son requisitados.
   * @property {string} name - Nombre del documento
   * @property {string} source - URL del documento.
  */
  DOCUMENTS: [
    {
      name: 'SOLICITUD DE SEGURO CON CUESTIONARIO MÉDICO'
    },
    {
      name: 'EXAMEN MÉDICO, EXAMEN GENERAL DE ORINA Y LABORATORIO EN SANGRE '

    },
    {
      name: 'EXAMEN MÉDICO, EXAMEN GENERAL DE ORINA, LABORATORIO EN SANGRE Y ELECTROCARDIOGRAMA EN REPOSO'
    },
  ],
  /**
   * NOTAS
   * @description Notas de puntos a considerar.
  */
  NOTES: {
    laboratorioEnSangre:
    'El Laboratorio en sangre icluye las siguientes determinaciones: ' +
    'Perfil XII (glucosa, urea, creatinina, ácido úrico, colesterol, ' +
    'triglicéridos, bilirrubinas directa, indirecta y total, gammaglutamil ' +
    'transpeptidasa, transaminasa glutámico oxalacética, transaminasa glutámico' +
    'pirúvica, fosfatada alcalina, deshidrogenasa láctica), Biometría Hemática, ' +
    'Antígeno Prostático Específico (PSA) en solicitantes masculinos de 50 años ' +
    'en adelante, Prueba de E.L.I.S.A. para VIH en sumas aseguradas a partir de $,5,000,001.'
  }
};
