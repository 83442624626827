/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { BA } from '@constants/coverages';
/** Models */
import { Plazo } from '@models/plazo/plazo.interface';
/** Services */
// import { ProspectoService } from '@services/prospecto/prospecto.service';
import { BAService } from '@services/coverages/BA/ba.service';
import { FactoresService } from '@services/factores/factores.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
/** Rxjs */
import { BehaviorSubject, Subscription } from 'rxjs';
// import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';

/**
 * Servicio para la cobertura TUTOR KIDS
 */
@Injectable({
    providedIn: 'root'
})
export class TUTORService {
/**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
/**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_UDI = new BehaviorSubject<number>(0);
  sumaAsegurada_MXN_U = new BehaviorSubject<number>(0);
  sumaAsegurada_USD_U = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  // primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  // primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);
  // Prima anual sugerida 2
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  // primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  // primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  // sumaAseguradaMasiva_1 = new BehaviorSubject<number>(0);
  // sumaAseguradaMasiva_2 = new BehaviorSubject<number>(0);
  // sumaAseguradaMasiva_3 = new BehaviorSubject<number>(0);

  // primaAnualMasiva_1 = new BehaviorSubject<number>(0);
  // primaAnualMasiva_2 = new BehaviorSubject<number>(0);
  // primaAnualMasiva_3 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  sumaAsegurada_MXN_U$ = this.sumaAsegurada_MXN_U.asObservable();
  sumaAsegurada_USD_U$ = this.sumaAsegurada_USD_U.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();

  /**
   * Crea una suscripción almacenando la suma asegurada de la cobertura Básica
   * @property {Subscription}
   */
  sumaAsegurada_BA: Subscription;

  prima_DiezMasPae = 0;

  /**
   * Constructor
   * @constructor
   * @param recursosExternos Crea instancia de RecursosExternosService
   * @param factores Crea instancia de FactoresService
   * @param BaService Crea instancia de BAService
   * @param prospectoService Crea instancia de ProspectoService
   */
  constructor(
    private recursosExternos: RecursosExternosService,
    private factores: FactoresService,
    private BaService: BAService,
    // private prospectoService: ProspectoService,
    // private opciones: OpcionesPlanService
  ) { }
  /**
   * Establece la prima anual en la cobertura dado el valor
   * @param sumaAseguradaUDI
   */
  establecePrimaAnual(sumaAseguradaUDI) {
    this.sumaAsegurada_BA = this.BaService.sumaAsegurada_UDI.distinctUntilChanged()
      .subscribe(() => {
        this.obtenerPrima(sumaAseguradaUDI);
      });
  }
  /**
   * Obtiene la prima apartir del valor asignado
   * @param {number} sumaAsegurada
   */
  obtenerPrima(sumaAsegurada: number): void {

    let suma;    

    if( sumaAsegurada > BA.MAX_SUM_KIDS ){
      suma =  BA.MAX_SUM_KIDS;
    } else{
      suma = sumaAsegurada < BA.MIN_SUM_KIDS ? BA.MIN_SUM_KIDS : sumaAsegurada;
    }
    
    

    const primaTUTOR_UDI = this.calculoPrimaTUTOR( suma, 'Elegido' );
    const primaTUTOR_MXN = this.recursosExternos.UDIToSecond(primaTUTOR_UDI);

    const sumaAsegurada_UDI = suma;
    const sumaAsegurada_MXN = this.recursosExternos.FirstToSecond(sumaAsegurada_UDI);

    this.primaAnual_UDI.next( primaTUTOR_UDI );
    this.primaAnual_MXN.next( primaTUTOR_MXN );

    this.sumaAsegurada_UDI.next( sumaAsegurada_UDI );
    this.sumaAsegurada_MXN.next( sumaAsegurada_MXN );

  }

  /**
   * Realiza el calculo de la tarifa
   *
   * @param {number} calculoPrimaTUTOR
   * @returns {number} Prima 
   * @memberof PAIService
   */
  calculoPrimaTUTOR(sumaAsegurada: number, plazo: Plazo, factor?: number, isINPC: boolean = false, edadInpc?: number): number {
    
    let factorTutor = this.factores.obtenerFactorCobertura('BASICA', plazo, isINPC, edadInpc,'TEMPORAL');
    const primaTUTOR = this.obtenerPrimaTUTOR(sumaAsegurada, factorTutor);
    return primaTUTOR;
  }

  calcularPrimaDiezMas(edad: number) {
    // let primaIAC = 0;
    // if (this.opciones.coberturaEnVigencia(this.primaAnual_UDI.value, IAC, edad) > 0) {
    //   const factorIac = this.factores.obtenerFactoresDiezMas('IAC', edad);
    //   primaIAC = this.obtenerPrimaTUTOR(this.sumaAsegurada_UDI.value, factorIac);
    //   primaIAC = primaIAC - this.primaAnual_UDI.value;
    // }
    // this.prima_DiezMasPae = primaIAC;
  }

  obtenerPrimaTUTOR(sumaAsegurada: number, factorTutor: number){
    const primaTutor = factorTutor !== 0 ? (
      sumaAsegurada * factorTutor / 1000
    ) : 0;
    return primaTutor;
  }

  resetValues() {
    this.primaAnual_UDI.next(0);
    this.primaAnual_MXN.next(0);
    this.sumaAsegurada_UDI.next(0);
    this.sumaAsegurada_MXN.next(0);
    this.sumaAsegurada_MXN_U.next(0);
    this.sumaAsegurada_USD_U.next(0);
  }
}
