import { Pipe, PipeTransform } from '@angular/core';

/**
 *Pipe que reemplaza el caracter "/" por "-"
 * @export
 * @class MenuPipe
 * @implements {PipeTransform}
 */
@Pipe({
    name: 'menuPipe'
  })
/** Método básico de la pipe */
export class MenuPipe implements PipeTransform {
    transform(name: string | string): string {
        if (typeof name === 'string') {
            if (name === null || name === '' || name === undefined) {
              return '';
            }
            return name.replace('/', '-');
          } else {
            if (name === null || name === undefined) {
              return '';
            }
            return name;
          }
    }
}
