/**
 * Mensajes de error obtenidos de:
 * - Mensajes de Error.xlsx
 * proporcionado por Diana Hernández Camargo
 */

import { Coverage } from '@models/coverage/coverage.type';

import { BA , CC, DI, DIPO, DIPOC, IAC, PAE, PAI, BAUniversal } from '@constants/coverages/coverages.constants';

/**
 * Constante que almacena los mensajes de errores respecto a sumas aseguradas mínimas y máximas de cada una de las coberturas.
 *@const
 */

export const ERROR_MESSAGES: ErrorMessage = {
  COVERAGES: [
    { COVERAGE: 'BASICA',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + BA.MIN_SUM_UDI  + ' UDI',
        MAX: ''
      }
    },
    { COVERAGE: 'BI',
      MESSAGE: {
        MIN: '',
        MAX: ''
      }
    },
    { COVERAGE: 'PAI',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + PAI.MIN_SUM_UDI  + ' UDI',
        MAX: 'La Suma Asegurada máxima es de ' + PAI.MAX_SUM_UDI + ' UDI'
      }
    },
    { COVERAGE: 'CC',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + CC.MIN_SUM_UDI  + ' UDI',
        MAX: 'La Suma Asegurada máxima es de ' + CC.MAX_SUM_UDI + ' UDI'
      }
    },
    { COVERAGE: 'DI',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + DI.MIN_SUM_UDI  + ' UDI',
        MAX: 'La Suma Asegurada máxima es de ' + DI.MAX_SUM_UDI + ' UDI'
      }
    },
    { COVERAGE: 'DIPO',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + DIPO.MIN_SUM_UDI  + ' UDI',
        MAX: 'La Suma Asegurada máxima es de ' + DIPO.MAX_SUM_UDI + ' UDI'
      }
    },
    { COVERAGE: 'DIPOC',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + DIPOC.MIN_SUM_UDI  + ' UDI',
        MAX: 'La Suma Asegurada máxima es de ' + DIPOC.MAX_SUM_UDI + ' UDI'
      }
    },
    { COVERAGE: 'IAC',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + IAC.MIN_SUM_UDI  + ' UDI',
        MAX: 'La Suma Asegurada máxima es de ' + IAC.MAX_SUM_UDI + ' UDI'
      }
    },
    { COVERAGE: 'PAE',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + PAE.MIN_SUM_UDI  + ' UDI',
        MAX: 'La Suma Asegurada máxima es de ' + PAE.MAX_SUM_UDI + ' UDI'
      }
    },
    { COVERAGE: 'PCD',
      MESSAGE: {
        MIN: '',
        MAX: ''
      }
    },
    { COVERAGE: 'BAU_VI',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + BAUniversal.MIN_SUM_MXN_VI  + ' pesos',
        MAX: ''
      }
    },
    { COVERAGE: 'BAU_MR',
      MESSAGE: {
        MIN: 'La Suma Asegurada miníma es de ' + BAUniversal.MIN_SUM_MXN_MR  + ' pesos',
        MAX: ''
      }
    },
  ]
};

/**
 *Interfaz que contiene la estructura básica del mensaje de error de cada cobertura.
 * @interface ErrorMessage
 */

interface ErrorMessage {
    /**
   * Propiedad que almacena la interfaz CoverageMessage
   *  @property
   */
  COVERAGES: CoverageMessage[];
}

 /**
 * Interfaz que contiene la estructura básica del mensaje de cada Cobertura.
 * [COBERTURA] + [MENSAJE]
 * @interface CoverageMessage
 */
interface CoverageMessage {
   /**
   * Propiedad que contiene las coberturas.
   * @property
   */
  COVERAGE: Coverage;
    /**
   * Propiedad que almacena la interfaz Message
   * @property
   */
  MESSAGE: Message;
}
/**
 *Interfaz que contiene la estructura básica del mensaje.
 * @interface Message
 */

interface Message {
    /**
   * Suma Mímina asegurada.
   * @property
   */
  MIN?: string;
  /**
   * Suma Máxima asegurada.
   * @property
   */
  MAX?: string;
  /**
   * Suma asegurada.
   * @property
   */
  SUM?: string;
}
