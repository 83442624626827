
import { QuizAnswer } from '@app/models/questions/answerQuestion.model';


export const QUIZ: QuizAnswer[] =
    [
        {
            question:
            {
                titleQuestion: '¿En qué rango de edad te encuentras', totalQuestion: 0, collapse: false
            },
            answers:
            [
                {title: '35 años o menos', value: 5, checked: false},
                {title: '36 a 50 años', value: 3, checked: false},
                {title: '51 años o más', value: 0, checked: false}
            ]
        },
        {
            question:
            {
                titleQuestion: 'El porcentaje mensual de tu ingreso que destinas para tu ahorro es de :', totalQuestion: 0, collapse: false
            },
            answers:
            [
                {title: 'Más de 20 %', value: 5, checked: false},
                {title: 'de 5 a 20 %', value: 3, checked: false},
                {title: 'Menos de 5 %', value: 0, checked: false}]
        },
        {
            question:
            {
                titleQuestion: '¿Cuánto tiempo estás dispuesto a esperar obtener una ganancia de tu inversión?', totalQuestion: 0, collapse: false
            },
            answers:
            [
                {title: 'Más de 20 años', value: 5, checked: false},
                {title: 'De 10 a 20 años', value: 3, checked: false},
                {title: 'Menos de 10 años', value: 0, checked: false}
            ]
        },
        {
            question:
            {
                titleQuestion: '¿Con qué frecuencia planeas disponer de tu inversión?', totalQuestion: 0, collapse: false
            },
            answers:
            [
                {title: 'Nunca o lo menos posible', value: 5, checked: false},
                {title: 'Anual', value: 3, checked: false},
                {title: 'Mensual', value: 0, checked: false}
            ]
        },
        {
            question:
            {
                titleQuestion: 'En el supuesto de que decidas invertir en la bolsa de valores,' +
                ' y si a consecuencia de una crisis ecónomica tu inversión disminuyera 15 %, ¿qué harías?', totalQuestion: 0, collapse: false
            },
            answers:
            [
                {title: 'Compraría más acciones', value: 5, checked: false},
                {title: 'Mantendría mi inversión', value: 3, checked: false},
                {title: 'Vendería todas mis acciones', value: 0, checked: false}
            ]
        },
        {
            question:
            {
                titleQuestion: '¿Con cuál de los siguientes objetivos de inversión te identificas más?', totalQuestion: 0, collapse: false
            },
            answers:
            [
                {title: 'Maximizar rendimiento, arriesgando capital', value: 5, checked: false},
                {title: 'Excelentes rendimientos, arriesgando el mínimo de capital', value: 3, checked: false},
                {title: 'Obtener rendimientos moderados sin comprometer capital', value: 0, checked: false}
            ]
        }
  ];
