import { Component, OnInit, HostListener } from '@angular/core';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { ResizeDetectionService } from '@app/services/resizeDetection/resize-detection.service';
import { ScrollPercentageDetectionService } from '@app/services/scrollPercentageDetection/scroll-percentage-detection.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { PageEvent } from '@angular/material';
import { CalculosExcedentesUniversalesService } from '@app/services/universales/calculos-excedentes-universales.service';
import { RoutesService } from '@app/services/routes/routes.service';
import { Router } from '@angular/router';
import { FrecuenciaPagoService } from './../../services/universales/frecuencia-pago.service';

@Component({
  selector: 'cvida-surplus',
  templateUrl: './surplus.component.html',
  styleUrls: ['./surplus.component.sass']
})
export class SurplusComponent implements OnInit {

  numberMask = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: ',',
    suffix: '',
    allowDecimal: false
  });

  cantidadMask = createNumberMask({
    prefix: '$ ',
    thousandsSeparatorSymbol: ',',
    suffix: '',
    allowDecimal: false
  });
  screenWidth: number;
  flagResize = 0;
  numeroPaginador: number;
  numeroPagina = 0;
  tamanio = 3; // this.tamanio = this.lstArrVgsTbl.length;
  inicio = 0;
  fin = 10;

  montoMB: string;
  anioMB: number;
  aportacionMB: number;

  delAnio: number;
  alAnio: number;
  montoRecu: string;

  anioUnic: number;
  montoUnic: string;
  error = '';

  valoresTabla = [];
  vistaCols = [];
  vistaVg = [];

  constructor(
    public opciones: OpcionesPlanService,
    public resizeDetection: ResizeDetectionService,
    public scrollPercent: ScrollPercentageDetectionService,
    public deviceservice: DeviceTypeService,
    public calculos: CalculosExcedentesUniversalesService,
    private routes: RoutesService,
    private router: Router,
    private frecuenciaPago: FrecuenciaPagoService
  ) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.resize();
  }

  obtenerMB(anios: number, aporta: number, monto: string) {
    const castMonto = Number(this.deleteCurrencySigns(monto));
    this.calculos.metaBuscada(anios, aporta, Number(castMonto), 0, true);

    const aportes = this.calculos.arrAportAdi_BS.value;
    this.setCols(Object.keys(aportes[0]) || []);
    this.setBody(aportes);
  }

  obtenerRecurrente(anioInicio: number, anioFin: number, monto: string) {
    this.calculos.recurrente(Number(anioInicio), Number(anioFin),
      Number(this.deleteCurrencySigns(monto)), 0);
    // this.calculos.retiro(2, 22, 0);
    // this.calculos.quitarAportacionRetiro(2, 0);

    const aportes = this.calculos.arrAportAdi_BS.value;
    this.setCols(Object.keys(aportes[0]) || []);
    this.setBody(aportes);


  }

  obtenerUnic(monto: string, anio: number) {
    this.calculos.unica(Number(anio), Number(this.deleteCurrencySigns(monto)), 0);

    const aportes = this.calculos.arrAportAdi_BS.value;
    this.setCols(Object.keys(aportes[0]) || []);
    this.setBody(aportes);
  }

  private setCols(cols: string[]): void {
    cols = cols.map(col => {
      switch (col) {
        case 'anio':
          return 'Año';
        case 'aportacion':
          return 'Aportación';
        case 'proyeccion':
          return 'Proyección';
        case 'numeroAportacion':
          return 'Numero de Aportación';
        case 'retiro':
          return 'Retiro';
        default:
          return '';
      }
    });

    this.vistaCols = cols;
  }

  private setBody(values: any): void {
    this.vistaVg = [];
    this.valoresTabla = values;

    values.forEach(v => this.vistaVg.push(Object.values(v)));

    this.tamanio = this.vistaVg.length;
    this.showFilterList();
  }

  // Métodos para el diseño de la tabla en desktop y mobile
  /**
  * Determina si el el dispositivo en uso es válido
  * @method
  * @return {boolean}
  */
  getDeviceType(): boolean {
    const device = this.deviceservice.getDeviceType();
    if (device === 'iPhone' || device === 'iPad' || device === 'Android') {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Verifica si se reajusta la tabla conforme se detecta el tamaño de la pantalla
   * @method
   */
  @HostListener('window:resize', ['event']) onResize(event) {
    this.resize();
  }

  /**
   * Ajusta el tamaño de la tabla conforme el tamaño del dispositivo
   * @method
   */
  resize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 1010 && this.flagResize !== 1) {
      this.numeroPaginador = 1;
      this.numeroPagina = 0;
      this.flagResize = 1;
      this.showFilterList();
    } else if (this.screenWidth > 1010 && this.flagResize !== 2) {
      this.numeroPaginador = 10;
      this.numeroPagina = 0;
      this.flagResize = 2;
      this.showFilterList();
    }
  }

  showFilterList() {
    let pageEnv: PageEvent;
    pageEnv = {
      previousPageIndex: 0,
      pageIndex: this.numeroPagina,
      pageSize: this.numeroPaginador,
      length: this.tamanio
    };

    this.ajustarPaginaTblDinamic(pageEnv);
  }

  ajustarPaginaTblDinamic(evento) {
    const nuevaVista: any[] = [];
    this.vistaVg = nuevaVista;
    if (evento !== undefined) {
      this.numeroPagina = evento.pageIndex;
      this.inicio =
        evento.pageIndex === 0 ? 0 : evento.pageIndex * evento.pageSize;
      this.fin = this.inicio + evento.pageSize;
    } else {
      this.numeroPagina = 0;
      this.inicio = 0;
      this.fin = 10;
    }

    const tablaCortada = this.valoresTabla.slice(this.inicio, this.fin);

    tablaCortada.forEach(v => this.vistaVg.push(Object.values(v)));
  }

  deleteCurrencySigns(text: string): number {
    return Number(String(text)
      .replace(/\,/g, '')
      .replace('$ ', ''));
  }

  goToValores() {
    const excdente = this.calculos.arrAportAdi_BS.value.length === 0 ? 0 : this.calculos.arrAportAdi_BS.value[0].aportacion;
    const primaTotal = this.frecuenciaPago.frecuenciaPagoUniv.value.filter(s => s.seleccionado)[0].primaAnual;
    this.frecuenciaPago.obtenerFrecuencias(primaTotal, excdente);
    this.router.navigate([
      `/cotizador/${this.routes.chosenCotizador.value}/valores-proyectados`
    ]);
  }

  /**
   * Cambia el valor del retiro en la celda elegida
   *
   * @param {FocusEvent} e
   * @param {number} anio
   * @param {number} proyeccion
   * @memberof SurplusComponent
   */
  changeRetiro(e: FocusEvent, anio: number, proyeccion: number): void {
    const aportacionRaw = e.target['value'];
    const aportacion = Number(this.deleteCurrencySigns(aportacionRaw));
    if (isNaN(aportacion) || aportacion > proyeccion) {
      const aportes = this.calculos.arrAportAdi_BS.value;
      this.setCols(Object.keys(aportes[0]) || []);
      this.setBody(aportes);
    } else {
      this.calculos.retiro(Number(anio), aportacion, 0);
      const aportes = this.calculos.arrAportAdi_BS.value;
      this.setCols(Object.keys(aportes[0]) || []);
      this.setBody(aportes);
    }
  }

  /**
  * Elimina la tabla de los valores Excedentes
  *
  * @memberof SurplusComponent
  */
  eliminarExcedente(): void {
    this.calculos.borrarAportaciones();
    this.vistaVg = [];
    this.vistaCols = [];
  }

  numberToDecimal(value: number) {
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  validAnioInicio() {
    if (this.delAnio < 1) {
      this.delAnio = 1;
    }
  }

  // validRecurrente() {
  //   if (this.alAnio <= this.delAnio) {
  //     this.error = 'Al Año debe de ser mayor Del Año';
  //     return true;
  //   }
  //   this.error = '';
  //   return false;
  // }
}
