import { Injectable } from '@angular/core';
import { FormulasService } from './formulas.service';
import { BaseDatosUniversalesService } from './../baseDatos/base-datos-universales.service';
import { Cobertura } from './../../models/universales/cobertura';
import { BehaviorSubject } from 'rxjs';
import { Banda } from './../../models/universales/banda';
import { RecargoFijo } from './../../models/universales/recargoFijo';
import { DescipherService } from './../descipher/descipher.service';
import { BANDAS, DESCUENTO_EDAD } from './../../constants/universales/universales.constant';
import { DescuentoEdad } from './../../models/universales/descuentoEdad';
import { CoberturaTarifa } from './../../models/universales/coberturaTarifa';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
import { FrecuenciaPagoService } from './frecuencia-pago.service';


@Injectable({
  providedIn: 'root'
})
export class CalculosUniversalesService {

  bandaVacia: Banda[] = [];
  recargoFijoVacio: RecargoFijo[] = [];
  cobVacia: Cobertura = {
    cobertura: '',
    sumaAsegurada: 0,
    prima: 0
  };

  basica = new BehaviorSubject<Cobertura>(this.cobVacia);
  gfi = new BehaviorSubject<Cobertura>(this.cobVacia);
  gfh = new BehaviorSubject<Cobertura>(this.cobVacia);
  gfc = new BehaviorSubject<Cobertura>(this.cobVacia);
  pai = new BehaviorSubject<Cobertura>(this.cobVacia);
  di = new BehaviorSubject<Cobertura>(this.cobVacia);
  dipoc = new BehaviorSubject<Cobertura>(this.cobVacia);
  ecmi = new BehaviorSubject<Cobertura>(this.cobVacia);
  cdc = new BehaviorSubject<Cobertura>(this.cobVacia);
  primaTotal = new BehaviorSubject<number>(0);
  edadCalculada = new BehaviorSubject<number>(0);
  edadEquivalenteCalculada = 0;
  recargoFijoBasica = new BehaviorSubject<number>(0);
  bandaBasica = new BehaviorSubject<string>('B1');
  edadMancomunadoCalculada = 0;

  constructor(private formulas: FormulasService,
    private bdUniv: BaseDatosUniversalesService,
    private descipher: DescipherService,
    private opc: OpcionesPlanService,
    private frec: FrecuenciaPagoService
  ) {
  }

  async obtenerPrima(tipoCobertura: string, sumAsegurada: number): Promise<string> {
    let cobertura, err = '';
    try {
      cobertura = await this.calcularPrima(tipoCobertura, sumAsegurada);
      switch (tipoCobertura) {
        case 'BASICA':
          this.basica.next(cobertura);
          break;
        case 'GFI':
          this.gfi.next(cobertura);
          break;
        case 'GFH':
          this.gfh.next(cobertura);
          break;
        case 'GFC':
          this.gfc.next(cobertura);
          break;
        case 'PAI':
          this.pai.next(cobertura);
          break;
        case 'DI':
          this.di.next(cobertura);
          break;
        case 'DIPOC':
          this.dipoc.next(cobertura);
          break;
        case 'ECMI':
          this.ecmi.next(cobertura);
          break;
        case 'CDC':
          this.cdc.next(cobertura);
          break;
      }
      this.calcularPrimaTotal();
    } catch (error) {
      err = error;
    }

    return await new Promise<string>((resolve, reject) => {
      if (err === '') {
        resolve(cobertura.prima.toString());
      } else {
        this.frec.obtenerFrecuencias(0, 0);
        reject(err);
      }
    });

  }

  private async calcularPrima(tipoCobertura: string, sumAsegurada: number) {
    let banda, recFijo;
    if (tipoCobertura === 'BASICA') {
      const bandaSel = this.obtenerBanda(sumAsegurada);
      banda = bandaSel.banda;
      recFijo = bandaSel.recargo_fijo;
      this.bandaBasica.next(banda);
      this.recargoFijoBasica.next(recFijo);
      await this.obtenerEdades();
    } else {
      banda = 'T';
      recFijo = 0;
    }
    const edad = this.obtenerEdad();
    const cobTar: CoberturaTarifa = {
      Producto: this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR',
      ArrCobertura: [tipoCobertura],
      Moneda: this.opc.moneda === 'MXN' ? '1' : '2',
      Riesgo: this.opc.tipoRiesgo.value === 'Normal' ? 'N' : 'P',
      ArrBanda: [banda],
      ArrEdad: [edad.toString()],
      Genero: this.opc.prospecto.gender,
      Fumador: this.opc.prospecto.fumador
    };
    const tarifaEnc = await this.bdUniv.getTarifa(cobTar);
    const tarifa = this.descipher.desiph(tarifaEnc[0].Tarifa);
    const prima = this.formulas.obtenerPrima(sumAsegurada, Number(tarifa), Number(recFijo));
    const cober: Cobertura = {
      cobertura: tipoCobertura,
      sumaAsegurada: sumAsegurada,
      prima: prima
    };
    return cober;
  }

  private obtenerEdad() {
    let edad = this.edadCalculada.value;
    if (this.opc.prospecto.mancomunado.mancomunado) {
      edad = this.edadEquivalenteCalculada;
    }
    return edad;
  }

  public async obtenerEdades() {
    this.edadMancomunadoCalculada = 0;
    let edadCalculada = this.opc.prospecto.age;
    if (this.opc.prospecto.gender === 'M' || this.opc.prospecto.fumador === 'NO') {
      edadCalculada = this.obtenerEdadCalculada(this.opc.prospecto.age, this.opc.prospecto.gender, this.opc.prospecto.fumador);
    }
    if (this.opc.prospecto.mancomunado.mancomunado) {
      this.edadMancomunadoCalculada = this.opc.prospecto.mancomunado.ageMancomunado;
      if (this.opc.prospecto.mancomunado.genderMancomunado === 'M' || this.opc.prospecto.mancomunado.fumadorMancomunado === 'NO') {
        this.edadMancomunadoCalculada = this.obtenerEdadCalculada(this.opc.prospecto.mancomunado.ageMancomunado,
          this.opc.prospecto.mancomunado.genderMancomunado, this.opc.prospecto.mancomunado.fumadorMancomunado);
      }
      const edadEquivalenteCalculada = await this.bdUniv.getEquivalentAge(edadCalculada.toString(), this.edadMancomunadoCalculada.toString());
      this.edadEquivalenteCalculada = Number(edadEquivalenteCalculada.docs[0].EdadEquivalente);
    }
    this.edadCalculada.next(edadCalculada);
  }

  public obtenerBanda(sumAsegurada: number) {
    const arrbandas: Banda[] = BANDAS;
    const riesgo = this.opc.tipoRiesgo.value === 'Normal' ? 'N' : 'P';
    const producto = this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR';
    const bandas = arrbandas.filter(s => s.moneda === this.opc.moneda && s.riesgo === riesgo &&
      s.rango_min <= sumAsegurada && s.rango_max >= sumAsegurada && s.producto === producto);
    let banda;
    if (bandas.length > 0) {
      banda = bandas[0];
    } else {
      throw new Error('ERR->B01: NO SE ENCONTRO LA BANDA PARA LA MONEDA: ' + this.opc.moneda + ', RIESGO: ' +
        this.opc.tipoRiesgo.value + ', SUMA_ASEGURADA: ' + sumAsegurada);
    }
    return banda;
  }

  private obtenerEdadCalculada(edad: number, genero: string, habito: string) {
    const edades: DescuentoEdad[] = DESCUENTO_EDAD;
    const producto = this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR';
    const riesgo = this.opc.tipoRiesgo.value === 'Normal' ? 'N' : 'P';
    const descuento = edades.filter(s => s.producto === producto && s.moneda === this.opc.moneda && s.riesgo === riesgo);
    let edadCalculada = edad;
    if (descuento.length > 0) {
      const descMuj = genero === 'M' ? descuento[0].descuento_mujer : 0;
      const descHab = habito === 'NO' ? descuento[0].descuento_no_fumador : 0;
      edadCalculada = this.formulas.edadCalculada(edad, descMuj, descHab);
    }
    edadCalculada = Math.max(edadCalculada, 18);
    return edadCalculada;
  }

  private calcularPrimaTotal() {
    const primaTot = this.basica.value.prima
      + this.gfi.value.prima
      + this.gfc.value.prima
      + this.gfh.value.prima
      + this.pai.value.prima
      + this.di.value.prima
      + this.dipoc.value.prima
      + this.ecmi.value.prima
      + this.cdc.value.prima;
    this.primaTotal.next(primaTot);
    this.frec.obtenerFrecuencias(primaTot, 0);
  }

  borrarCobertura(tipoCobertura: string) {
    switch (tipoCobertura) {
      case 'BASICA':
        this.basica.next(this.cobVacia);
        break;
      case 'GFI':
        this.gfi.next(this.cobVacia);
        break;
      case 'GFH':
        this.gfh.next(this.cobVacia);
        break;
      case 'GFC':
        this.gfc.next(this.cobVacia);
        break;
      case 'PAI':
        this.pai.next(this.cobVacia);
        break;
      case 'DI':
        this.di.next(this.cobVacia);
        break;
      case 'DIPOC':
        this.dipoc.next(this.cobVacia);
        break;
      case 'ECMI':
        this.ecmi.next(this.cobVacia);
        break;
      case 'CDC':
        this.cdc.next(this.cobVacia);
        break;
    }
    this.calcularPrimaTotal();
  }

  borrarCoberturas() {
    this.basica.next(this.cobVacia);
    this.gfi.next(this.cobVacia);
    this.gfc.next(this.cobVacia);
    this.gfh.next(this.cobVacia);
    this.pai.next(this.cobVacia);
    this.di.next(this.cobVacia);
    this.dipoc.next(this.cobVacia);
    this.ecmi.next(this.cobVacia);
    this.cdc.next(this.cobVacia);
  }

}
