
// Módulos de Angular
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CollapsibleModule } from 'angular2-collapsible';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RequirementsCoverageComponent } from '@components/requirements-coverage/requirements-coverage.component';
// import { HttpClient} from '@angular/common/http';



// Componentes
import { CollapseDirective } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TextMaskModule, MaskedInputDirective } from 'angular2-text-mask';

import {MatCardModule, MatRadioModule, MatTabsModule} from '@angular/material';
// Pipes
import { PipesModule } from '@pipes/pipes.module';
// import { MailService } from '@app/services/requirements-coverage/requirements-coverage.service';
import {NgxMaskModule} from 'ngx-mask';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatRadioModule,
    CollapsibleModule,
    FormsModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    TextMaskModule,
    MatRadioModule,
    PipesModule,
    ReactiveFormsModule,
    // HttpClientModule,
    // HttpClient
    NgxMaskModule.forRoot()

  ],
  declarations: [
    RequirementsCoverageComponent
  ],
  exports: [

    CollapseDirective,
    MaskedInputDirective,
    FormsModule,
    MatCardModule,
    MatRadioModule,
    MatTabsModule
  ],
  entryComponents: [
    // MailService
     ]
})
export class RequirementsCoverageModule { }
