/**ANGULAR CORE*/
import { Component, OnInit } from '@angular/core';
/**ROUTER */
import { Router } from '@angular/router';
/**SERVICES */
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
/** MODELOS */
import { User } from '../../models';
import { RoutesService } from '@app/services/routes/routes.service';

/**
 * Componente encargado del header del sitio
 */
@Component({
  selector: 'cvida-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  /**
   * Estado del collapse
   * @var
   * @default [false]
   */
  // isCollapsed = false;
  /**
   * Tipo de cliente
   * @var
   * @type [boolean]
   */
  dispositivo: boolean;
  /**
   * Tipo del dispositivo del cliente
   * @var
   * @type [string]
   */
  dev: string;
  /**
   * Datos del usuario
   * @property
   * @type {User}
   */
  user: User;
  show: boolean;

  /**
   * Metodo constructor, crea la istancia del headerComponent
   * @param device
   * @param router
   * @param authentication
   * @method
   */
  constructor(
    private device: DeviceTypeService,
    private router: Router,
    private authentication: AuthenticationService,
    private routes: RoutesService
  ) {
    this.authentication.user.subscribe(user => this.user = user);
    this.routes.chosenCotizador.subscribe( val => {
      // console.log('valor de val header::::: ' + val);
      if (val === 'ProTGT' || val === 'Aliados' || val === 'AxaPAraTodos' || val === 'configurar-plazo') {
      this.show = true;
      } else {
      this.show = false;
      }
    });
  }
  /**
   * MÉTODO PRINCIPAL
   *
   * @memberof HeaderComponent
   */
  ngOnInit() {

  }
  /**
   * Metodo que detecta el tipo de dispositvo del cliente
   * @method
   */
  devtype() {
    if (this.device.getDeviceType() === 'iPad') {
      this.dev = 'iPad';
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'iPhone') {
      this.dev = 'iPhone';
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'Android') {
      this.dev = 'Android';
      return this.dispositivo = true;
    } else {
      this.dev = 'Desktop';
      return this.dispositivo = false;
    }
  }
  /**
   * Metodo que redirecciona a la configuracion del plazo
   * @method
   */
  navigate() {
    this.router.navigate(['/cotizador/configurar-plazo']);
    // this.isCollapsed = false;
  }
  /**
   *  Método que redirecciona al inicio de la aplicación.
   *@method
   */
  goToInit() {
    localStorage.setItem('isActive', 'true');
    this.router.navigate(['/cotizador/landing']);
  }

}
