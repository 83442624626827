export const ANUAL_INFLATIONS_INPC_UNIVERSALES_MXN: { title: string, value: number}[] = [
    { title: '1.0%', value: .01 },
    { title: '1.5%', value: .015 },
    { title: '2%', value: .02 },
    { title: '2.5%', value: .025 },
    { title: '3%', value: .03 },
    { title: '3.5%', value: .035 },
    { title: '4%', value: .04 },
    { title: '4.5%', value: .045 },
    { title: '5%', value: .05 },
    { title: '5.5%', value: .055 },
    { title: '6%', value: .06 },
  ];
  /**
   * Inflación anual por defecto.
   * @const
   * @default ".04"
   */
  export const DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_MXN = .04;

  export const ANUAL_INFLATIONS_INPC_UNIVERSALES_USD: { title: string, value: number}[] = [
    { title: '1.0%', value: .01 },
    // { title: '1.5%', value: .015 },
    { title: '2%', value: .02 },
    // { title: '2.5%', value: .025 },
    { title: '3%', value: .03 },
    // { title: '3.5%', value: .035 },
    { title: '4%', value: .04 },
  ];
  /**
   * Inflación anual por defecto.
   * @const
   * @default ".04"
   */
  export const DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_USD = .01;

