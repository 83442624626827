/** ANGULAR CORE */
import { Component, OnInit, ViewChild } from '@angular/core';
/** NGX - BOOTSTRAP */
import { BsModalRef } from '../../../../../node_modules/ngx-bootstrap/modal';
/**
 * ALERTA DE PASOS PARA DESCARGAR EL PDF EN iOS
 * @export
 * @class AlertIosComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-alert-ios',
  templateUrl: './alert-ios.component.html',
  styleUrls: ['./alert-ios.component.sass']
})
export class AlertIosComponent implements OnInit {
  /** VARIABLE PARA LA COMUNICACIÓN ENTRE LA ALERTA Y EL COMPONENTE */
  @ViewChild('modalIos') mod: BsModalRef;

  /** VARIABLE QUE ALMACENA LA REFERENCIA DE UN COMPONENTE EN LA ALERTA */
  modalRef: BsModalRef;

    /** VARIABLE QUE MUESTRA LA DESCRIPCIÓN DE UN COMPONENTE EN LA ALERTA */
  description: string;

  /**
   * Creates an instance of AlertIosComponent.
   * @param {BsModalRef} _bsModalRef
   * @memberof AlertIosComponent
   */
  constructor(private _bsModalRef: BsModalRef,
  ) { }

  /**
   * Metodo ngOnInit
   * @method
   */
  ngOnInit() {
  }
  /**
   * MÉTODO PARA CERRAR ALERTA
   * @memberof AlertIosComponent
   */
  close() {
    this._bsModalRef.hide();
  }

}
