/** Angular Core */
import { Injectable, OnDestroy } from '@angular/core';
/** Services */
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { RoutesService } from '../routes/routes.service';
// import { DeviceTypeService } from '../deviceType/deviceType.service';
/** Models */
import { FrontTerms, FrontCustomTerm } from './../../models/terms/custom-terms.interface';
// import { Terms } from '@models/terms/custom-terms.interface';
import { MobileDatabases } from '../../models/replication/replication.model';
/** Constants */
import { DEFAULT_ANUAL_INFLATION, ANUAL_INFLATIONS } from './../../constants/plan-options/anual-inflations.constant';
import { Currency, DEFAULT_CURRENCY, CURRENCIES, CURRENCIES_SUPPORTED } from './../../constants/currency/currency.constant';
import { PLANTYPES, DEFAULT_PLANTYPE } from '@constants/plan-options/plan-types.constant';
import { PROTECTION_TERMS, DEFAULT_TERMS, PlanType, DEFAULT_ANUAL_INFLATION_INPC, ANUAL_INFLATIONS_INPC } from '@constants/plan-options';
/** Rxjs */
import { Subscription, BehaviorSubject } from 'rxjs';
//import { ProtectionPlan } from '../../models/protection-plan/protection-plan';
import {
  DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_MXN,
  DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_USD,
  ANUAL_INFLATIONS_INPC_UNIVERSALES_MXN,
  ANUAL_INFLATIONS_INPC_UNIVERSALES_USD
} from '@app/constants/plan-options/anual-inflatios-inpc-universal';

/**
 * Servicio dedicado a la generación de planes de protección
 * @export
 * @class ProtectionPlansGeneratorService
 * @implements {OnDestroy}
 */
@Injectable({
  providedIn: 'root'
})
export class ProtectionPlansGeneratorService implements OnDestroy {
  /**
   * Variable que contiene el folio
   * @type {string}
   */
  strFolio: string;
  /**
   * Variable que contiene el título del modal
   * @type {string}
   */
  title: string;

  /**
   *
   * Propiedad donde se almacenan los plazos de pago dependiendo el plan
   * @type {FrontCustomTerm}
   */
  TERMS: FrontCustomTerm = { plan: 'VPL', terms: [] };

  /**
   * Se obtiene el sujeto
   *
   * @type {Subscription}
   * @memberof ProtectionPlansGeneratorService
   */
  prospecto: Subscription;

  /**
   * Edad del prospecto
   * @type  {number}
   */
  prospectuAge  : number;
  prospectuAgeK : number;

  /**
   * Se crea una instancia reactiva donde se almacenan los plazos de pago que se pueden exportar.
   *@type {BehaviorSubject<FrontCustomTerm>}
   *@default {this.TERMS}
   */
  NewTermsToExport: BehaviorSubject<FrontCustomTerm> = new BehaviorSubject<FrontCustomTerm>(this.TERMS);
  /**
   * Define como observable la variable que almacena los plazos de pago que se pueden exportar
   */
  NewTermsToExport$ = this.NewTermsToExport.asObservable();
  /**
   * Se crea una instancia reactiva de tipo arreglo numérico, determinándole ningún valor.
  */
  plazosSugeridos = new BehaviorSubject<number[]>([]);
  /**
   * Se crea una instancia reactiva de tipo booleana determinándole valor 'false'
   * La variable creada se utiliza como valor inicial del Último plazo.
  */
  esUltimoPlazo = new BehaviorSubject<boolean>(false);
  /**
   * Se crea una instancia reactiva
   * La variable creada se utiliza como valor inicial del penúltimo plazo.
   * @type {BehaviorSubject<boolean>}
   * @default "false"
  */
  esPenultimoPlazo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Fecha visible.
   * @property dateVisible
   * @type {boolean}
   */
  dateVisible: boolean;
  /**
   * Plazo de pago elegido. Se crea una instancia reactiva
   * @type {BehaviorSubject<number>}
   * @default {0}
   */
  plazoPagoElegido: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  /**
   *  Bases de datos replicadas en mobile.
   * @type {MobileDatabases}
   */
  mobileDatabasesReplicated: MobileDatabases;
  /**
   *C rea una instancia reactiva, teniendo como valores los distintos planes. Dichos valores son guardados en un arreglo.
   * @param title
   */
  products = new BehaviorSubject<{ title: string, value: PlanType, selected: boolean }[]>([]);
  /**
   * Crea una instancia reactiva, teniendo como valores las distintas unidades. Dichos valores son guardados en un arreglo
   */
  currencies = new BehaviorSubject<{ title: string, value: Currency, selected: boolean }[]>([]);
  /**
   * Crea una instancia reactiva, teniendo como valores las distintas tasas de inflación. Dichos valores son guardados en un arreglo
   */
  inflations = new BehaviorSubject<{ title: string, value: number, selected: boolean }[]>([]);
  inf_inpc = new BehaviorSubject<{ title: string, value: number, selected: boolean }[]>([]);
  inf_inpc_universal_mxn = new BehaviorSubject<{ title: string, value: number, selected: boolean }[]>([]);
  inf_inpc_universal_usd = new BehaviorSubject<{ title: string, value: number, selected: boolean }[]>([]);
  /**
   * Numero de plazos posibles sugeridos
   *
   * @type {BehaviorSubject<number>}
   * @memberof ProtectionPlansGeneratorService
   */
  numeroPlazosPosibles: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  /**
   * Disponible (Plan y término).
   * @type {{plan: PlanType, term: number}[]}
   * @memberof ProtectionPlansGeneratorService
   */
  available = new BehaviorSubject<{ plan: PlanType, terms: number[] }[]>([]);

  planDefault: PlanType = 'VPL';
  plazoDefault = 0;

  plazo_Elegido = new BehaviorSubject<number>(0);
  plazo_Sugerido1 = new BehaviorSubject<number>(0);
  plazo_Sugerido2 = new BehaviorSubject<number>(0);

  selectedPlan = new BehaviorSubject<number>(this.routes.chosenCotizador.value === 'ProTGT' ? 10 : 15);

  tipoPlan: PlanType;

  /**
   * Crea una instancia de ProtectionPlansGeneratorService.
   * @param prospectoService Crea una instancia de Prospecto Service
   * @param device Crea una instancia de DeviceTypeService
   * @memberof ProtectionPlansGeneratorService
   */
  constructor(
    private prospectoService: ProspectoService,
    // private device: DeviceTypeService,
    private routes: RoutesService,
  ) {
    this.prospecto = this.prospectoService.prospectoActual.subscribe(prospectoData => {
      this.prospectuAge = prospectoData.mancomunado.mancomunado === true ? Number(prospectoData.mancomunado.edadEquivalente) : Number(prospectoData.age);
      this.prospectuAgeK = prospectoData.kids.ageKids;
    });
  }

  /**
   * Cuando se cambia a otra pantalla , se desuscribe el prospecto.
   */
  ngOnDestroy() {
    this.prospecto.unsubscribe();
  }

  /**
   * Generador de los planes para ser mostrados en el frontend-
   * @description Apartir de un plan
   * @param {PlanType} planSearch
   * @returns {void}
   * @memberof ProtectionPlansGeneratorService
   */
  generatePlansFront(planSearch: PlanType, plazosSearch: number, moneda?: string): FrontTerms[] {
    let newFrontCustom: FrontCustomTerm;
    newFrontCustom;
      const terms = JSON.parse(JSON.stringify(PROTECTION_TERMS.filter(item => item.plan === planSearch)[0]));
      const defaultTerm = DEFAULT_TERMS.filter(item => item.plan === planSearch)[0];
      this.plazoDefault = defaultTerm.default;
      let newTerms: FrontTerms[] = [];
      let plazos = plazosSearch > 0 ? plazosSearch : defaultTerm.default;
      if (planSearch === 'TEMPORAL' && moneda === 'MXN') {
        terms.paymentTerms = terms.paymentTerms.filter(s => s.currency === 'MXN');
        terms.reachedAge = terms.reachedAge.filter(s => s.currency === 'MXN');
        if (plazos < 60) {
          const arrTerm = terms.paymentTerms.filter(s => s.term === plazos);
          plazos = arrTerm.length > 0 ? arrTerm[0].term : defaultTerm.default;
        } else {
          const arrTerm = terms.reachedAge.filter(s => s.term === plazos);
          plazos = arrTerm.length > 0 ? arrTerm[0].term : defaultTerm.default;
        }
      }
      else if ( planSearch === 'TEMPORAL' && moneda === 'USD'){
        terms.reachedAge = terms.reachedAge.filter( s => s.term ===65 );
    }
      if (planSearch === 'VPL' && moneda === 'UDI') {
        terms.reachedAge = terms.reachedAge.filter(s => s.term !=60 );
      }
      if(planSearch === 'ALIADOSKIDS' && this.prospectuAgeK){
        if( this.prospectuAgeK > 8){
          terms.paymentTerms = terms.paymentTerms.filter(s => s.term === 22);
        }        
      }
      for (const term of terms.paymentTerms) {
        if (term.term < 55 && this.prospectuAge >= term.minAge && this.prospectuAge <= term.maxAge) {
          // Nuevo plazo de pago para front
          const newItem = {
            title: term.term + (Number(term.term) === 1 ? ' año' : ' años'),
            value: Number(term.term),
            default: Number(term.term) === Number(plazos),
            plan: planSearch
          };

          newTerms = [...newTerms, newItem];
        }
      }

      for (const term of terms.reachedAge) {
        if (term.term > this.prospectuAge && this.prospectuAge >= term.minAge && this.prospectuAge <= term.maxAge) {
          // Nuevo plazo de pago para front
          const newItem = {
            title: term.term + ' edad alcanzada',
            value: Number(term.term), default: Number(term.term) === Number(plazos), plan: planSearch
          };
          newTerms = [...newTerms, newItem];
        }
      }
      this.NewTermsToExport.next({ plan: planSearch, terms: newTerms });

    return this.NewTermsToExport.value.terms;
  }

  /**
   * Este método obtiene el término por defecto correspondiente al plan seleccionado.
   * @param plan
   */
  getDefaultTerm(plan: PlanType): number {
    return DEFAULT_TERMS.filter(item => item.plan === plan)[0].default;
  }

  /**
   * Este metodo genera un arreglo compuesto de 3 plazos de pago recomendados,
   * el plazo elegido y dos adelante o dos atras, dependiedo de si es el primer
   * o último plazo, obteniendo los posibles plazos de pago del arreglo
   * "NewTermsToExport" del metodo previo.
   *
   * @param {number} plazoPagoElegido
   * @returns {number[]}
   * @memberof ProtectionPlansGeneratorService
   */
  sugerenciaPlazos(plazoPagoElegido: number, igualarPlazo: boolean = false): number[] {
    // Variable que almacenará los plazo de pagos recomendados

    const plazosPagoGenerados: number[] = [plazoPagoElegido, plazoPagoElegido, plazoPagoElegido];

    this.plazo_Elegido.next(plazoPagoElegido);
    this.plazo_Sugerido1.next(plazoPagoElegido);
    this.plazo_Sugerido2.next(plazoPagoElegido);
    this.numeroPlazosPosibles.next(3);
    this.plazoPagoElegido.next(plazoPagoElegido);
    this.plazosSugeridos.next(plazosPagoGenerados);

    return plazosPagoGenerados;
  }

  /**
   * Genera los productos disponibles 'VPL', 'OV', 'TEMPORAL'
   *
   * @param {PlanType} [plan=DEFAULT_PLANTYPE]
   * @returns {{title: string, value: PlanType, selected: boolean}[]}
   * @memberof ProtectionPlansGeneratorService
   */
  productsGenerator(planDeseado: PlanType = DEFAULT_PLANTYPE): { title: string, value: PlanType, selected: boolean }[] {
    let productsGenerated: { title: string, value: PlanType, selected: boolean }[] = [];
    
      switch (this.routes.chosenCotizador.value) {
        case 'ProTGT':
          productsGenerated = PLANTYPES.filter(
            plan =>
              plan.value === planDeseado ||
              plan.value === 'OV' ||
              plan.value === 'TEMPORAL' ||
              plan.value === 'VPL' 
              //|| plan.value === 'MUJER UNICA'
          ).map(plan => ({
            title: plan.title,
            value: plan.value,
            selected: plan.value === planDeseado
          }));
          break;
        case 'Aliados':
          productsGenerated = PLANTYPES.filter(
            plan => 
              plan.value ===  planDeseado
          ).map(plan => ({
            title: plan.title,
            value: plan.value,
            selected: plan.value === planDeseado
          }));
          break;
        case 'AxaParaTodos':
          productsGenerated = PLANTYPES.filter(
            plan =>
              plan.value === planDeseado ||
              plan.value === 'PROTECANCER' ||
              plan.value === 'PROTECCION EFECTIVA'
          ).map(plan => ({
            title: plan.title,
            value: plan.value,
            selected: plan.value === planDeseado
          }));
          break;
        case 'Universales':
          const planVi = this.prospectoService.prospecto.value.mancomunado.mancomunado ||
            Number(this.prospectoService.prospecto.value.age) > 60;
          productsGenerated = PLANTYPES.filter(
            plan =>
              planVi ?
                // plan.value === planDeseado ||
                plan.value === 'VIDA INTELIGENTE' :
                plan.value === planDeseado ||
                plan.value === 'VIDA INTELIGENTE'
                ||
                plan.value === 'MI PROYECTO R'
          ).map(plan => ({
            title: plan.title,
            value: plan.value,
            selected: plan.value === planDeseado
          }));
          break;
      }
    this.products.next(productsGenerated);
    return this.products.value;
  }

  /**
   * Método que obtiene el plan por defecto.
   * @method
   */
  getDefaultPlan(): PlanType {
    return DEFAULT_PLANTYPE;
  }

  /**
   * Método que genera las monedas soportadas para las cotizaciones.
   * @param {Currency} [currency=DEFAULT_CURRENCY]
   * @param {PlanType} plan
   * @returns {{title: string, value: Currency, selected: boolean}[]}
   * @memberof ProtectionPlansGeneratorService
   */
  currenciesGenerator(currency: Currency = DEFAULT_CURRENCY, plan: PlanType): { title: string, value: Currency, selected: boolean }[] {
    let currenciesGenerated: { title: string, value: Currency, selected: boolean }[] = [];

    const CURRENCY_SUPPORTED = CURRENCIES_SUPPORTED.filter(item => plan === item.plan)[0].currencies;

    CURRENCIES.forEach((item, index) => {
      if (CURRENCY_SUPPORTED.indexOf(item.value) !== -1) {
        currenciesGenerated = [
          ...currenciesGenerated, { title: item.title, value: item.value, selected: item.value === currency }
        ];
      }
    });

    this.currencies.next(currenciesGenerated);
    return this.currencies.value;
  }

  /**
   * Genera las distintas inflaciones y las guarda en un arreglo.
   * @var inflationsGenerated es donde se guarda el valor de cada una de las inflaciones, con su titulo, valor y si ha sido seleccionada o no.
   * @method
   * @param inflation
   */
  inflationsGenerator(inflation: number = DEFAULT_ANUAL_INFLATION): { title: string, value: number, selected: boolean }[] {
    let inflationsGenerated: { title: string, value: number, selected: boolean }[] = [];

    ANUAL_INFLATIONS.forEach((item, index) => {
      inflationsGenerated = [
        ...inflationsGenerated, { title: item.title, value: item.value, selected: Number(item.value) === Number(inflation) }
      ];
    });

    this.inflations.next(inflationsGenerated);

    return this.inflations.value;
  }
  inflationsINPCGenerator(inflation: number = DEFAULT_ANUAL_INFLATION_INPC): { title: string, value: number, selected: boolean }[] {
    let inflationsGenerated: { title: string, value: number, selected: boolean }[] = [];

    ANUAL_INFLATIONS_INPC.forEach((item, index) => {
      inflationsGenerated = [
        ...inflationsGenerated, { title: item.title, value: item.value, selected: Number(item.value) === Number(inflation) }
      ];
    });

    this.inf_inpc.next(inflationsGenerated);

    return this.inf_inpc.value;
  }
  inflationsINPCUniversalGenerator(inflation: number = DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_MXN): { title: string, value: number, selected: boolean }[] {
    let inflationsGenerated: { title: string, value: number, selected: boolean }[] = [];

    ANUAL_INFLATIONS_INPC_UNIVERSALES_MXN.forEach((item, index) => {
      inflationsGenerated = [
        ...inflationsGenerated, { title: item.title, value: item.value, selected: Number(item.value) === Number(inflation) }
      ];
    });


    this.inf_inpc_universal_mxn.next(inflationsGenerated);

    return this.inf_inpc_universal_mxn.value;
  }
  inflationsIPCUniversalGenerator(inflation: number = DEFAULT_ANUAL_INFLATION_INPC_UNIVERSAL_USD): { title: string, value: number, selected: boolean }[] {
    let inflationsGenerated: { title: string, value: number, selected: boolean }[] = [];

    ANUAL_INFLATIONS_INPC_UNIVERSALES_USD.forEach((item, index) => {
      inflationsGenerated = [
        ...inflationsGenerated, { title: item.title, value: item.value, selected: Number(item.value) === Number(inflation) }
      ];
    });

    this.inf_inpc_universal_usd.next(inflationsGenerated);

    return this.inf_inpc_universal_usd.value;
  }
  /**
   * Plazos y términos de pago disponibles.
   * @method
   * @param db
   */
  availablePlansAndTerms(db: MobileDatabases): { plan: PlanType, terms: number[] }[] {
    let result: { plan: PlanType, term: number }[] = [];
    let resultByGroup: { plan: PlanType, terms: number[] }[] = [];

    for (const item in db) {
      if (item) {
        // VPL_10
        const split = item.split('_');

        let plan;
        let term;

        if (split[0] === 'PR') {
          plan = (split[1] === 'CA' ? 'PROTECANCER' : 'PROTECCION EFECTIVA') as PlanType;
          term = 1;
        } else if (split[0] === 'OV') {
          plan = 'OV';
          term = 99;
        } else {
          plan = split[0] as PlanType;
          term = Number(split[1]);
        }

        // VPL - PLAN
        // const plan = split[0] as PlanType;
        // 10 - PLAZO PAGO
        // const term = Number(split[1]);

        if (db[item]) {
          result = [...result, { plan, term }];
        }
      }
    }

    const vpl_term = result
      .filter(item => item.plan === 'VPL');

    const temp_term = result
      .filter(item => item.plan === 'TEMP' as string);

    const ov_term = result
      .filter(item => item.plan === 'OV');

    const aliados_terms = result
      .filter(item => item.plan === 'DOTAL');

    const axaparatodos_terms = result
      .filter(item => item.plan === 'PROTECANCER' || item.plan === 'PROTECCION EFECTIVA');

    let termsVPL: number[] = [];
    let termsTEMP: number[] = [];
    let termsDOTAL: number[] = [];

    if (vpl_term.length > 0) {
      vpl_term.forEach(value => {
        termsVPL = [...termsVPL, value.term];
      });
      resultByGroup = [{ plan: 'VPL', terms: termsVPL }];
    }

    if (temp_term.length > 0) {
      temp_term.forEach(value => {
        termsTEMP = [...termsTEMP, value.term];
      });
      resultByGroup = [...resultByGroup, { plan: 'TEMPORAL', terms: termsTEMP }];
    }

    if (ov_term.length > 0) {
      ov_term.forEach(value => {
        resultByGroup = [...resultByGroup, { plan: value.plan, terms: [value.term] }];
      });
    }

    if (aliados_terms.length > 0) {
      aliados_terms.forEach(value => {
        termsDOTAL = [...termsDOTAL, value.term];
      });
      resultByGroup = [...resultByGroup, { plan: 'DOTAL', terms: termsDOTAL }];
    }

    if (axaparatodos_terms.length > 0) {
      axaparatodos_terms.forEach(value => {
        resultByGroup = [...resultByGroup, { plan: value.plan, terms: [1] }];
      });
    }

    return resultByGroup;
  }


}
