/**ANGULAR CORE */
import { Component, OnInit } from '@angular/core';
/**SERVICES */
import { ResizeDetectionService } from './../../services/resizeDetection/resize-detection.service';

/**
 * Componente encargado del footer del sitio
 */
@Component({
  selector: 'cvida-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  /**
   * Nombre del agente encargado
   * @var
   */
  private nav = navigator.userAgent.toLowerCase();
  /**
   * Estado del dispositivo
   * @var
   * @type [boolean]
   */
  isMobile: boolean;

  year: number;

  /**
   * Ancho
   * @var
   * @type [any]
   */
  width: any;

  /**
   * Metodo constructor inicializar las variables
   * @method
   * @param resizeDetection Crea una instancia de ResizeDetectionService
   */
  constructor(public resizeDetection: ResizeDetectionService) {
    this.isMobile = true;
  }

  /**
   * Metodo ngOnInit para inicializar al instanciarse el componente
   */
  ngOnInit() {
    this.getSystemType();
    this.width = window.innerWidth;
    this.year = (new Date()).getFullYear();
  }

  /**
   * Metodo que determina el tipo de movil que es
   * @method
   */
  getSystemType() {
    if (/(ipod|phone|ipad|android)/.test(this.nav)) {
      this.isMobile = false;
    }
  }
}
