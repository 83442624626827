import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { CollapsibleModule } from 'angular2-collapsible';
import { TextMaskModule } from 'angular2-text-mask';
import { CollapseModule } from 'ngx-bootstrap/collapse';
// Componente
import { BuildPlanComponent } from '@components/build-plan/build-plan.component';
import { SharedModule } from '@shared/shared.module';
import { PipesModule } from '@pipes/pipes.module';

// Servicios
@NgModule({
  imports: [
    CommonModule,
    CollapsibleModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CollapseModule.forRoot(),
    FormsModule,
    TextMaskModule,
    PipesModule
  ],
  declarations: [
    BuildPlanComponent
  ],
  providers: [
  ]
})
export class BuildPlanModule { }

