import { Injectable } from '@angular/core';
import { ProyeccionVidaInteligente } from './../../models/universales/proyeccionVidaInteligente';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { CalculosProyeccionUniversalesVipprService } from './calculos-proyeccion-universales-vippr.service';
import { CalculosProyeccionUniversalesInpcService } from './calculos-proyeccion-universales-inpc.service';



@Injectable({
  providedIn: 'root'
})
export class CalculosProyeccionUniversalesService {



  constructor(private opc: OpcionesPlanService,
    private viPpr: CalculosProyeccionUniversalesVipprService,
    private inpc: CalculosProyeccionUniversalesInpcService) {
  }

  async proyecciones() {
    let arrProyec: ProyeccionVidaInteligente[] = [];
    if (!this.opc.inpcUniversal.value) {
      arrProyec = await this.viPpr.proyeccion();
    } else {
      arrProyec = await this.inpc.proyeccion();
    }
    return arrProyec;
  }

}
