import { Injectable,
    // Inject, LOCALE_ID
    } from '@angular/core';
// import { formatCurrency } from '@angular/common';

// import { Gender, Fumador } from '@app/models';
import { PlanType } from '@app/constants/plan-options/plan-types.constant.pre';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
// import { ProspectoService } from '@services/prospecto/prospecto.service';
// import { NetworkStateService } from '@services/networkState/network-state.service';
// import { PaymentFrequencyService } from '@services/paymentFrequency/payment-frequency.service';
// import { CalculosService } from '@services/calculos/calculos.service';
// import { GeneradorTablaValoresService } from '@services/generadorTablaValores/generador-tabla-valores.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import * as moment from 'moment';

declare let jsPDF;

/**
 * Servicio que maneja el PDF de Tarifas (Masivo)
 * @export
 * @class PdfTarifasService
 */
@Injectable({
  providedIn: 'root'
})
export class PdfVidaInteligente {
  // Colores rosas -> https://coolors.co/f06192-f97ca7-ff9ebf-ffb7d0-ffe8ef

  /**
   * Instancia de jsPDF
   * @property
   * @private
   * @memberof PdfVidaInteligente
   */
  private pdf;
  x = 0;
  y = 0;
  yBody: number;
  yCobertua: number;
  yBeneficios: number;
  yPortafolio: number;

  /**
   * Creates an instance of PdfTarifasService.
   * @param {OpcionesPlanService} opciones
   * @param {AuthenticationService} auth
   * @param {ProspectoService} prospecto
   * @param {NetworkStateService} network
   * @param {BaseDatosService} db
   * @param {PaymentFrequencyService} paymentFrecuency
   * @param {CalculosService} calculos
   * @param {string} locale
   * @param {GeneradorTablaValoresService} tabla
   * @param {DeviceTypeService} device
   * @memberof PdfTarifasService
   */
  constructor(
    private opciones: OpcionesPlanService,
    private auth: AuthenticationService,
    // private prospecto: ProspectoService,
    // private network: NetworkStateService,
    // private paymentFrecuency: PaymentFrequencyService,
    // private calculos: CalculosService,
//    @Inject(LOCALE_ID) private locale: string,
    // private tabla: GeneradorTablaValoresService,
    private device: DeviceTypeService
  ) {}

  /**
   * Inicializa el proceso de crear un PDF
   *
   * @private
   * @returns {Promise<void>}
   * @memberof PdfTarifasService
   */
  private async initPDF(folio?: string): Promise<void> {
    this.pdf = new jsPDF('p', 'mm', 'legal', 1);
    try {
      await this.setHeader();
      this.setBody();
    } catch (error) {}

  }

  /**
   * Establece header del pdf (primera hoja)
   *
   * @private
   * @returns {Promise<boolean>}
   * @memberof PdfTarifasService
   */
  private setHeader(): Promise<boolean> {
    return new Promise(async (res, rej) => {
      try {
        const img = new Image();

          img.src = 'assets/images/ico_AXA.png';

        this.pdf.addImage(img, 'PNG', 18, 0, 18, 18, undefined, 'FAST');
      } catch (error) {
        rej(false);
        console.log(error);
      }

      this.writedDate();
      this.footer();
      res(true);
    });
  }
  private writedDate(): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(12);
    this.pdf.setFontStyle('bold');
    this.pdf.text('Fecha: ', 140, 17);
    this.pdf.setFontStyle('normal');
    this.pdf.text(`${moment().format('DD')} de ${moment().format('MMMM')} del ${moment().format('YYYY')}`, 155, 17);
    this.banner();
  }
  private banner() {
    if (this.opciones.tipoPlan === 'VIDA INTELIGENTE') {
        this.addImage(0, 23, 230, 65, '/assets/images/banner_2_VI.png');
    }
    if (this.opciones.tipoPlan === 'MI PROYECTO R') {
        this.addImage(0, 23, 230, 65, '/assets/images/banner_1_MR.png');
    }
    this.writeProducto(this.opciones.tipoPlan);
  }

  /**
   * Establece el cuerpo del pdf (primera hoja)
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private setBody(folio?: string) {
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, 65, 203, 280, 'FD');
    this.encabezado();
    this.detallesCoberturas();
  }



  private encabezado(folio?: string): void {
    this.writeBoldedText('Estimado: ', 12, 82);
    this.writeBoldedTextB(this.auth.user.getValue().name = 'Guillermo Garcia López', 12 , 94);
  }

  private detallesCoberturas(): void {
    this.titlePrincipal('VIDA INTELIGENTE', 12, 106);
    this.yCobertua = 106;
    this.writeText('Es un seguro de vida que, además de protegerte, te ofrece la oportunidad de tener una estrategia de inversión a', 12, this.yCobertua += 8);
    this.writeText('través de diferentes opciones de inversión de acuerdo a tu nivel de tolerancia al riesgo para así alcanzar tus' +
    ' metas.', 12, this.yCobertua += 4);
    this.titlePrincipalb('Tu plan te ofrece:', 12, this.yCobertua += 10);
    this.writeBoldedTextBlue('En caso de fallecimiento', 12, this.yCobertua += 8);
    this.writeText('tus beneficiarios recibirán la suma asegurada alcanzada, más el valor acululado de la', 58, this.yCobertua);
    this.writeText('inversión.', 12, this.yCobertua += 4);
    this.pdf.setFillColor(15, 159, 179);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, this.yCobertua += 6, 203, 12, 'FD');
    this.writeTextW('Puedes incrementar la protección al contratar cualquiera de las  siguientes coberturas con un', 21, this.yCobertua += 5);
    this.writeTextW('costo adicional.', 96, this.yCobertua += 4);
    this.writeBoldedTextBlue('Pago Adicional por Invalidez Total y Permanente:', 12, this.yCobertua += 14);
    this.writeText('en caso de sufrir un accidente o una enfermedad que te', 107, this.yCobertua);
    this.writeText('provoque invalidez total ypermanente, recibirás la suma asegurada contratada para esta cobertura.', 12, this.yCobertua += 4);
    this.writeBoldedTextBlue('Excención de Costos del Seguro por Invalidez Total y Permanente:', 12, this.yCobertua += 8);
    this.writeText('en caso de un accidente o enfermedad', 137, this.yCobertua);
    this.writeText('que te provoque invalidez total ypermanente, quedarás excento de los costos del seguro y continuarás protegido', 12, this.yCobertua += 4);
    this.writeText('por fallecimiento y se cancelarán las coberturas adicionales contratadas.', 12, this.yCobertua += 4);
    if (this.opciones.tipoPlan === 'MI PROYECTO R') {
        this.writeBoldedTextBlue('Cobertura de Cáncer:', 12, this.yCobertua += 8);
        this.writeText('Cambiar texto', 52, this.yCobertua);
    }
    this.writeBoldedTextBlue('Muerte Accidental:', 12, this.yCobertua += 8);
    this.writeText('en caso de fallecimiento a causa de un accidente, tus beneficiarios, recibirán la suma asegurada', 48, this.yCobertua);
    this.writeText('contratada para esta cobertura.', 12, this.yCobertua += 4);
    this.writeBoldedTextBlue('Muerte Accidental, Pérdidas Orgánicas y/o Muerte Accidental Colectiva:', 12, this.yCobertua += 8);
    this.writeText('en caso de fallecimiento o pérdida', 146, this.yCobertua);
    this.writeText('de alguna parte de tu cuerpo a causa de un accidente, tú o tus beneficiarios recibirán la suma asegurada' +
    'contratada', 12, this.yCobertua += 4);
    this.writeText('para esta cobertura, además de una doble indemnización por muerte accidental colectiva, si ocurre en un lugar', 12, this.yCobertua += 4);
    this.writeText('público', 12, this.yCobertua += 4);
    this.writeBoldedTextBlue('Gastos Funerarios:', 12, this.yCobertua += 8);
    this.writeText('en caso de fallecimiento del asegurado, su cónyugue e hijos dependientes económicos recibirán', 48, this.yCobertua);
    this.writeText('la suma contratada.', 12, this.yCobertua += 4);

    this.writeTextbold('Gracias por su preferencia', 12, this.yCobertua += 10);

    this.writeText('Nombre del Agente', 12, this.yCobertua += 10);
    this.titlePrincipalb('Diana Figueroa Cedillo', 12, this.yCobertua += 8);

    this.writeText('Este documento es informativo, el producto se rigirá por las disposiciones contractuales y legales aplicables', 12, this.yCobertua += 8);

    this.pdf.setFillColor(15, 159, 179);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, 284, 203, 36, 'FD');

    this.writeTextWb('¿Qué ventajas ofrece?', 12, 289);
    let y;
    y = 289;
    this.writeTextW('° Tener la tranquilidad por contar con un plan con el que puedas modificar tu proteccion en cualquier ', 13, y += 5);
    this.writeTextW('momento.', 16, y += 4 );
    this.writeTextW('° Diversificar tu dinero en nuestros planes de inversión.', 13, y += 4 );
    this.writeTextW('° Generar una inversión destinada a concretar tu proyecto de vida.', 13, y += 4 );
    this.writeTextW('° Contar con un anticipo del 30 % sobre la suma asegurada para tus beneficiarios por fallecimiento,', 13, y += 4 );
    this.writeTextW('o enfermedades terminales, para ti.', 16, y += 4 );
    this.writeTextW('° Tener asistencia en viajes nacionales e internacionales y asistencia funeraria.', 13, y += 4 );

    this.writeTextBH('Para mayor información:', 90, 330);
    this.writeText('Lamanos al', 42, 338);
    this.writeTextBH('01 800 900 1291', 63, 338);
    this.writeText('ó consulta nuestra página de internet www.axa.mx', 93, 338);

  }



//   private legacy(): void {
//     this.pdf.setDrawColor(15, 192, 217);
//     this.pdf.setFillColor(15, 192, 217);
//     this.pdf.rect(6, 320, 203, 28, 'FD');
//     // tslint:disable-next-line:max-line-length
//     this.writeTextWTable('Esta cotización tiene una validez de 15 días naturales contado a partir de la fecha de su elaboración'
//     + ', por lo que no se garantiza la emisión del seguro.', 7, 324);
//     this.writeTextWTable('La misma es ilustrativa y de apoyo a la fuerza de ventas. No forma parte del contrato del seguro, por lo que no' +
//     ' se garantiza el comportamiento futuro', 7, 328);
//     this.writeTextWTable('de la tasa de rendimiento que se muestra como ejemplo en la misma.', 7, 332);
//     this.writeTextWTable('AXA Seguros se reserva el derecho de solicitar la respuesta a un cuestionario médico y de ocupación, así como' +
//     'estudios de laboratorio. Los montos', 7, 338);
//     this.writeTextWTable('que aparecen en el documento se reflejan de acuerdo a la moneda o unidad de valor  elegida al momento de la cotización. Los' +
//     'retiros totales o', 7, 342);
//     this.writeTextWTable('parciales de la póliza estarán sujetos a la  retención de los impuestos de acuerdo a
//     las disposiciones fiscales vigentes.', 7, 346);
//   }

  private footer(): void {
    this.pdf.setDrawColor(6, 0, 101);
    this.pdf.setFillColor(6, 0, 101);
    this.pdf.rect(0, 320, 220, 37, 'F');
    this.writeTextW('AXA SEGUROS, S.A DE C.V Tels. 5169 1000      .     01 800 900 1292     .     www.axa.mx', 31, 353);
  }

  /**
   * Inicia la generación del PDF y lo guarda
   * @method
   * @memberof PdfTarifasService
   */
  generatePDF(folio: string): void {
    if ((this.device.getDeviceType() === 'iPad' || this.device.getDeviceType() === 'iPhone') && this.device.isSafari()) {
      this.initPDF(folio).then(() => {
        const blob = URL.createObjectURL(this.pdf.output('blob'));
        window.open(blob);
      });
    } else {
      this.initPDF(folio).then(() => this.pdf.save('Cotizacion'));
    }
  }

  /**
   * Genera texto en negritas
   * @method
   * @private
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @memberof PdfTarifasService
   */
  private writeBoldedText(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(19);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }
  private writeBoldedTextB(text: string, x: number, y: number): void {
    this.pdf.setTextColor(15, 159, 179);
    this.pdf.setFontSize(20);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }

  /**
   * Escribe texto normal
   * @method
   * @private
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @memberof PdfTarifasService
   */
  private writeText(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(10.5);
    this.pdf.setFontStyle('normal');
    this.pdf.text(text, x, y);
  }
  private writeTextbold(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(10.5);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }

  private writeTextW(text: string, x: number, y: number): void {
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(11);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }

  private writeTextBH(text: string, x: number, y: number): void {
    this.pdf.setTextColor(6, 0, 101);
    this.pdf.setFontSize(11);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }
  private writeTextWb(text: string, x: number, y: number): void {
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(12.5);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }
//   private writeTextWTable(text: string, x: number, y: number): void {
//     this.pdf.setTextColor(255, 255, 255);
//     this.pdf.setFontSize(8);
//     this.pdf.setFontStyle('bold');
//     this.pdf.text(text, x, y);
//   }
  private writeBoldedTextBlue(text: string, x: number, y: number): void {
    this.pdf.setTextColor(15, 159, 179);
    this.pdf.setFontSize(11);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }
  private writeProducto(plan: PlanType): void {
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(32);
    this.pdf.setFontStyle('bold');
    if (this.opciones.tipoPlan === 'VIDA INTELIGENTE') {
        this.pdf.text(plan, 64, 55);
    }
    if (this.opciones.tipoPlan === 'MI PROYECTO R') {
        this.pdf.text(plan, 70, 55);
    }
    this.pdf.setFontSize(20);
    this.pdf.text('VIDA Y  AHORRO', 87, 40);
  }
  // para agregar imagenes

  private addImage(x: number, y: number, w: number, h: number, file: string) {
    try {
      const image = new Image();
      image.src = file;
      this.pdf.addImage(
        image,
        file.split('.')[1].toUpperCase(),
        x,
        y,
        w,
        h,
        undefined,
        'FAST'
      );
    } catch (error) {}
  }

  private titlePrincipal(text: string, x: number, y: number) {
    this.pdf.setFontType('bold');
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(14);
    this.pdf.text(text, x, y);
  }
  private titlePrincipalb(text: string, x: number, y: number) {
    this.pdf.setFontType('bold');
    this.pdf.setTextColor(15, 159, 179);
    this.pdf.setFontSize(14);
    this.pdf.text(text, x, y);
  }
}

