/** Angular Core */
import { Injectable } from '@angular/core';
/** Models */
/** Services */
// import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { FactoresService } from '@services/factores/factores.service';
import {
  BAService, BIService, PAIService, CCService, IACService, PAEService,
  DIService, DIPOService, DIPOCService, PCDService, GFService, VPLService, EFIService, TUTORService
} from '@services/coverages';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
/** Constants */
import { BI, PAI, CC, IAC, PAE, DI, DIPO, DIPOC, PCD, GF, CDC_MU, EFI } from '@constants/coverages';
import { Currency } from './../../constants/currency/currency.constant';
/** Models */
import { Plazo } from '@models/plazo/plazo.interface';
// import { CoverageConstants } from '@models/coverage-constants/coverage-constants.interface';
import { ValorGarantizado } from '@models/valor-garantizado/ValorGarantizado';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';
import { DescipherService } from '../descipher/descipher.service';
import { ValueFactors } from '@app/models/value-factors/value-factors';
import { CalculosService } from '../calculos/calculos.service';
// import { ExcedentesService } from '../excedentes/excedentes.service';
import { CDCService } from './../coverages/CDC/cdc.service';


/**
 * Servicio para los Valores Garantizados
 *
 * @export
 * @class ValoresGarantizadosService
 */
@Injectable({
  providedIn: 'root'
})
export class ValoresGarantizadosService {

  /**
   * Variable bandera
   * @type {boolean}
   */
  flag = true;
  /**
   * Almacena el valor de la Currencia
   * @type {Currency}
   */
  moneda: Currency;
  /**
   * Almacena el valor de Rescate
   * @type {BehaviorSubject<number>}
   */
  valorRescate = new BehaviorSubject<number>(0);
  /**
   * ALmacena la tabla de valores
   * @type {BehaviorSubject<ValorGarantizado[]>}
   */
  tablavalores = new BehaviorSubject<ValorGarantizado[]>([]);
  /**
   * ALmacena el valor de la proyección
   * @type {BehaviorSubject<number>}
   */
  proyeccion = new BehaviorSubject<number>(0);
  /**
   * ALmacena el valor de la proyección anterior
   * @type {BehaviorSubject<number>}
   */
  proyeccionAnterior = new BehaviorSubject<number>(0);
  /**
   * Almacena arreglo de los Valores Garantizados
   * @type {ValorGarantizado[]}
   */
  valoresGarantizados: ValorGarantizado[] = [];
  /**
   * ALmacena el porcentaje de recuperación
   * @type {BehaviorSubject<number>}
   */
  porcentajeRecuperacion = new BehaviorSubject<number>(0);
  /**
   * ALmacena el porcentaje de recuperación en UDIs
   * @type {BehaviorSubject<number>}
   */
  porcentajeRecuperacionUDI = new BehaviorSubject<number>(0);
  /**
   * ALmacena la prima acumulada en Pesos
   * @type {BehaviorSubject<number>}
   */
  primaAcumulada_MXN = new BehaviorSubject<number>(0);
  /**
   * ALmacena el valor de rescate
   * @type {BehaviorSubject<number}
   */
  valorRescate_MXN = new BehaviorSubject<number>(0);
  /**
   * ALmacena los UDIs en el plazo de año 1
   * @type {number>}
   */
  udiAno1: number;
  /**
   * Variable para deducible
   */
  deducibleUDI: number;
  noDeducibleUDI: number;
  deducibleMXN: number;
  noDeducibleMXN: number;

  /*
  * Variable que almacena la plima acumulada en UDIs
  *
  * @type {number}
  * @memberof ValoresGarantizadosService
  */
  primaAcumuladaUDI: number;

  porcentaje_SA_INPC_VG: number;
  saINPC_VG: number;
  porcentaje_INPC: number;


  /**
   * Crea una instancia del componente ValoresGarantizadosService.
   * @param {FactoresService} factores
   * @param {ExcedentesService} excedentes
   * @param {BAService} BA_Service
   * @param {BIService} BI_Service
   * @param {PAIService} PAI_Service
   * @param {CCService} CC_Service
   * @param {IACService} IAC_Service
   * @param {PAEService} PAE_Service
   * @param {DIService} DI_Service
   * @param {DIPOService} DIPO_Service
   * @param {DIPOCService} DIPOC_Service
   * @param {PCDService} PCD_Service
   * @param {GFService} GF_Service
   * @param {OpcionesPlanService} planOptions
   * @param {DescipherService} descipher
   */
  constructor(
    private factores: FactoresService,
    // private excedentes: ExcedentesService,
    private BA_Service: BAService,
    private BI_Service: BIService,
    private PAI_Service: PAIService,
    private CC_Service: CCService,
    private IAC_Service: IACService,
    private PAE_Service: PAEService,
    private DI_Service: DIService,
    private DIPO_Service: DIPOService,
    private DIPOC_Service: DIPOCService,
    private PCD_Service: PCDService,
    private GF_Service: GFService,
    private VPL_Service: VPLService,
    private planOptions: OpcionesPlanService,
    private descipher: DescipherService,
    private calculoDeducible: CalculosService,
    private CDC_Service: CDCService,
    private EFI_Service: EFIService, 
    private TUTOR_Service: TUTORService
  ) {
  }

  /**
   * MÉTODO PARA GENERAR LA SUMA ASEGURADA
   * @returns {number}
   * @memberof ValoresGarantizadosService
   */
  generarSumaAsegurada(plazo?: number): number {
    let sumaAsegurada = 0;
    if (this.planOptions.tipoPlan === 'MUJER UNICA') {
      if (plazo <= (65 - Number(this.planOptions.prospecto.age))) {
        sumaAsegurada = this.BA_Service.sumaAsegurada_UDI.value + this.VPL_Service.sumaAsegurada_PrimeraMoneda.value;
      } else {
        sumaAsegurada = this.VPL_Service.sumaAsegurada_PrimeraMoneda.value;
      }
    } else {
      sumaAsegurada = this.BA_Service.sumaAsegurada_UDI.value;
    }
    return sumaAsegurada;
  }
  asignaCalculoDeducibleUDI(): number {
    return (
      this.deducibleUDI = this.calculoDeducible.deducibleUDI
    );
  }

  asignaCalculoDeducibleMXN(): number {
    return (this.deducibleMXN = this.calculoDeducible.deduciblePESOS);
  }
  asignaCalculoNoDeducibleUDI(): number {
    return (
      this.noDeducibleUDI = this.calculoDeducible.noDeducibleUDI
    );
  }
  asignaCalculoNoDeducibleMXN(): number {
    return (
      this.noDeducibleMXN = this.calculoDeducible.noDeduciblesPESOS
    );
  }


  /**
   * MÉTODO QUE GENERA EL VALOR DE RESCATE
   * @param {number} anioPoliza
   * @param {Plazo} plazo
   * @param {number} plazoPago
   * @returns {number}
   * @memberof ValoresGarantizadosService
   */
  generarValorDeRescate(anioPoliza: number, plazo: Plazo, plazoPago: number, edadProspecto: number = 0, isMU = false): number {
    const anioPolizaActual = anioPoliza - 1;
    let valor = 0;
    let tarifa = 0;
    let factoresValores = this.factores.factoresValoresElegido;
    if (isMU) {
      factoresValores = this.factores.factoresValoresElegidoMU;
      tarifa = this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual) || 0;
      valor = (((tarifa * this.VPL_Service.sumaAsegurada_PrimeraMoneda.value) / 1000 || 0));
      return valor;
    }
    switch (true) {

      case this.planOptions.tipoPlan === 'TEMPORAL':

        switch (Number(plazoPago)) {
          case Number(this.factores.factoresValoresElegido[0].PlazoPago):
            factoresValores = this.factores.factoresValoresElegido;
            break;

          case Number(this.factores.factoresValoresSugerido1[0].PlazoPago):
            factoresValores = this.factores.factoresValoresSugerido1;
            break;

          case Number(this.factores.factoresValoresSugerido2[0].PlazoPago):
            factoresValores = this.factores.factoresValoresSugerido2;
            break;
        }

        break;

      case (this.planOptions.tipoPlan === 'TEMPORAL' && Number(plazoPago) === 65):
        factoresValores = this.factores.factoresValoresElegido;
        break;
      case (this.planOptions.tipoPlan === 'TEMPORAL' && Number(plazoPago) === 70):
        factoresValores = this.factores.factoresValoresElegido;
        break;
      case (this.planOptions.tipoPlan === 'DOTAL') && Number(plazoPago) <= 60:

        if (Number(plazoPago) === Number(this.factores.factoresValoresElegido.length)) {
          factoresValores = this.factores.factoresValoresElegido;
        } else if (Number(plazoPago) === Number(this.factores.factoresValoresSugerido1.length)) {
          factoresValores = this.factores.factoresValoresSugerido1;
        } else if (Number(plazoPago) === Number(this.factores.factoresValoresSugerido2.length)) {
          factoresValores = this.factores.factoresValoresSugerido2;
        }
        break;
      case (this.planOptions.tipoPlan === 'DOTAL') && Number(plazoPago) >= 60:
        if ((Number(plazoPago) - Number(edadProspecto)) === Number(this.factores.factoresValoresElegido.length)) {
          factoresValores = this.factores.factoresValoresElegido;
        } else if ((Number(plazoPago) - Number(edadProspecto)) === Number(this.factores.factoresValoresSugerido1.length)) {
          factoresValores = this.factores.factoresValoresSugerido1;
        } else if ((Number(plazoPago) - Number(edadProspecto)) === Number(this.factores.factoresValoresSugerido2.length)) {
          factoresValores = this.factores.factoresValoresSugerido2;
        }
        break;
      case (this.planOptions.tipoPlan === 'ALIADOSKIDS'):
        factoresValores = this.factores.factoresValoresElegido;
        //TODO:ALIADOSKIDS realizar al parecer siempre queda como elegido
        break;
      case Number(plazoPago) === 60 || Number(plazoPago) === 65 || Number(plazoPago) === 70:
        factoresValores = this.factores.factoresValoresElegido;
        break;

    }
  
    if (plazoPago <= 65 && this.planOptions.tipoPlan !== 'DOTAL') {
      switch (plazo) {
        case 'Sugerido1':
          factoresValores = this.factores.factoresValoresSugerido1;
          break;
        case 'Sugerido2':
          factoresValores = this.factores.factoresValoresSugerido2;
          break;
      }
    }

    switch (this.planOptions.tipoPlan) {
      case 'DOTAL':

        if (Number(plazoPago) === Number(this.factores.factoresValoresElegido.length)) {
          factoresValores = this.factores.factoresValoresElegido;
        } else if (Number(plazoPago) === Number(this.factores.factoresValoresSugerido1.length)) {
          factoresValores = this.factores.factoresValoresSugerido1;
        } else if (Number(plazoPago) === Number(this.factores.factoresValoresSugerido2.length)) {
          factoresValores = this.factores.factoresValoresSugerido2;
        }

        break;
    }
    const coin = this.planOptions.moneda;
    switch (coin) {
      case 'UDI':
        // tarifa = Number(parseFloat(this.descipher.desiph(String(this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual))))) || 0;
        tarifa = this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual) || 0;
        break;
      default:
        factoresValores.sort(function (obj1, obj2) {
          return Number(obj1.AnioPoliza) - Number(obj2.AnioPoliza);
        }); // ordenamiento de valores de menor a mayor
        // tarifa = Number(parseFloat(this.descipher.desiph(String(this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual))))) || 0;
        tarifa = this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual) || 0;
    }
    if (plazo === 'ElegidoBA') {
      factoresValores = this.factores.factoresValoresElegido;
    } else if (plazo === 'Sugerido1BA') {
      factoresValores = this.factores.factoresValoresSugerido1;
    } else if (plazo === 'Sugerido2BA') {
      factoresValores = this.factores.factoresValoresSugerido2;
    }
    switch (this.moneda) {
      case 'UDI':
        valor = (((tarifa * this.BA_Service.sumaAsegurada_UDI.value) / 1000 || 0));
        break;
      default:
        valor = (((tarifa * this.BA_Service.sumaAsegurada_UDI.value) / 1000 || 0));
    }


    this.valorRescate.next(valor);

    return Number(this.valorRescate.value);
  }

  /**
  * MÉTODO QUE GENERA EL VALOR DE RESCATE
  * @param {number} anioPoliza
  * @param {Plazo} plazo
  * @param {number} plazoPago
  * @returns {number}
  * @memberof ValoresGarantizadosService
  */
  generarValorDeRescatePropuestas(anioPoliza: number, plazo: Plazo): number {
    const anioPolizaActual = anioPoliza - 1;
    let valor = 0;
    let tarifa = 0;
    let factoresValores;
    switch (true) {
      case plazo === 'Elegido' || plazo === 'ElegidoBA':
        factoresValores = this.factores.factoresValoresElegido;
        break;
      case plazo === 'Sugerido1' || plazo === 'Sugerido1BA':
        factoresValores = this.factores.factoresValoresSugerido1;
        break;
      case plazo === 'Sugerido2' || plazo === 'Sugerido2BA':
        factoresValores = this.factores.factoresValoresSugerido2;
        break;
    }
    const coin = this.planOptions.moneda;
    switch (coin) {
      case 'UDI':
        // tarifa = Number(parseFloat(this.descipher.desiph(String(this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual))))) || 0;
        tarifa = this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual) || 0;
        break;
      default:
        factoresValores.sort(function (obj1, obj2) {
          return Number(obj1.AnioPoliza) - Number(obj2.AnioPoliza);
        }); // ordenamiento de valores de menor a mayor
        // tarifa = Number(parseFloat(this.descipher.desiph(String(this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual))))) || 0;
        tarifa = this.obtenerFactorValorGarantizado(factoresValores, anioPolizaActual) || 0;
    }
    switch (this.moneda) {
      case 'UDI':
        valor = (((tarifa * this.BA_Service.sumaAsegurada_UDI.value) / 1000 || 0));
        break;
      default:
        valor = (((tarifa * this.BA_Service.sumaAsegurada_UDI.value) / 1000 || 0));
    }

    this.valorRescate.next(valor);

    return Number(this.valorRescate.value);
  }


  // ? 1 ATENCION
  /**
   * MÉTODO QUE GENERA PORCENTAJE DE RECUPERACIÓN EN UDIs
   * @param {number} valorRescate
   * @param {number} anioPoliza
   * @param {number} edad
   * @param {number} plaActual
   * @param {number} plaTotal
   * @param {number} valorUDI
   * @param {Plazo} plazo
   * @returns {number}
   * @memberof ValoresGarantizadosService
   */
  generarPorcentajeRecUDI(
    valorRescate: number, anioPoliza: number, edad: number, plaActual: number, plaTotal: number,
    valorUDI: number, plazo: Plazo, specificCase: number = 0, edadTitular: number): number {
    const porcentajeRecup = this.generarPorcentajeRecuperacion(anioPoliza, edad, plaActual, plaTotal, valorUDI, plazo, specificCase, edadTitular);
    const a = (valorRescate / porcentajeRecup) * 100;
    this.porcentajeRecuperacionUDI.next(a);
    return this.porcentajeRecuperacionUDI.value;
  }
  /**
 * MÉTODO QUE GENERA PORCENTAJE DE RECUPERACIÓN EN UDIs PROPUESTAS
 * @param {number} valorRescate
 * @param {number} anioPoliza
 * @param {number} edad
 * @param {number} plaActual
 * @param {number} plaTotal
 * @param {number} valorUDI
 * @param {Plazo} plazo
 * @returns {number}
 * @memberof ValoresGarantizadosService
 */
  generarPorcentajeRecUDIPropuestas(
    valorRescate: number, anioPoliza: number, edad: number, plaActual: number, plaTotal: number,
    valorUDI: number, plazo: Plazo): number {
    const porcentajeRecup = this.generarPorcentajeRecuperacionPropuestas(anioPoliza, edad, plaActual, plaTotal, valorUDI, plazo);
    const a = (valorRescate / porcentajeRecup) * 100;
    this.porcentajeRecuperacionUDI.next(a);
    return this.porcentajeRecuperacionUDI.value;
  }

  // ? 2 ATENCION
  /**
   * MÉTODO QUE GENERA EL PORCENTAJE DE RECUPERACIÓN
   * @param {number} anioPoliza
   * @param {number} edad
   * @param {number} plazoAct
   * @param {number} plazoTot
   * @param {number} valorUDI
   * @param {Plazo} plazo
   * @returns {number}
   * @memberof ValoresGarantizadosService
   */
  generarPorcentajeRecuperacion(
    anioPoliza: number, edad: number, plazoAct: number, plazoTot: number, valorUDI: number, plazo: Plazo, specificCase: number = 0,
    edadTitular: number
  ): number {
    this.primaAcumuladaUDI = 0;
    let nuevaProyeccion = 0;
    if( this.planOptions.tipoPlan === 'ALIADOSKIDS' && anioPoliza !== 1){
      edadTitular = edadTitular + anioPoliza - 1;
    }
    if (anioPoliza === 1) {

      if (this.BA_Service.primaAnual_UDI.value !== 0) {
        nuevaProyeccion = this.primasCoberturasAdicionales(false, plazo, 0, null, 0, false, specificCase, edadTitular);
        this.proyeccionAnterior.next(nuevaProyeccion);
        this.primaAcumuladaUDI = nuevaProyeccion;
        this.proyeccion.next(this.primaAcumuladaUDI);
        this.udiAno1 = valorUDI;

        switch (this.planOptions.moneda) {
          case 'UDI':
            this.primaAcumulada_MXN.next(this.primaAcumuladaUDI * valorUDI || 0);
            break;
          default:
            this.primaAcumulada_MXN.next(this.primaAcumuladaUDI || 0);
        }

      } else {
        return 0;
      }

    } else if (anioPoliza === 2) {

      const proyeccionAnterior = Number(this.proyeccion.value);

      if (Number(plazoTot) === 1) {
        this.proyeccion.next(proyeccionAnterior);
      } else {

        if (edad >= plazoTot) {
          switch (Number(plazoTot)) { // validacon para edades alcanzadas  // primaAcumuladaUDI = proyeccionAnterior;
            case 60:
              this.primaAcumuladaUDI = proyeccionAnterior;
              break;

            case 65:
              this.primaAcumuladaUDI = proyeccionAnterior;
              break;

            case 70:
              this.primaAcumuladaUDI = proyeccionAnterior;
              break;

            default: // validacion para edades no alcanzadas // primaAcumuladaUDI = proyeccionAnterior + proyeccionAnterior;
              this.primaAcumuladaUDI = proyeccionAnterior + proyeccionAnterior;
          }
        } else {
          this.primaAcumuladaUDI = proyeccionAnterior + proyeccionAnterior;
        }

        this.proyeccion.next(this.primaAcumuladaUDI);
        switch (this.planOptions.moneda) {
          case 'UDI':
            if (edad >= plazoTot) {

              switch (Number(plazoTot)) {
                case 5:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                case 10:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                case 15:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                case 20:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                case 25:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                default:
                  this.primaAcumulada_MXN.next(proyeccionAnterior * this.udiAno1);
              }

            } else {
              this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
            }
            break;
          default:
            if (edad >= plazoTot) {
              this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + proyeccionAnterior);
            } else {
              this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + proyeccionAnterior);
            }
        }
      }
    } else if (anioPoliza > 2) {
      const proyeccionAnterior = Number(this.proyeccion.value);
      let proyeccionNueva = 0;

      this.proyeccionAnterior.next(proyeccionAnterior);
      proyeccionNueva = this.primasCoberturasAdicionales(true, plazo, edad, null, 0, false, specificCase, edadTitular);
      if (plazoAct > plazoTot) {
        this.primaAcumuladaUDI = proyeccionAnterior;
        this.proyeccion.next(this.primaAcumuladaUDI);
      } else {
        switch (Number(plazoTot)) {

          case 60:
            this.getValidarProyecciones(this.primaAcumuladaUDI, edad, plazoTot, proyeccionAnterior, proyeccionNueva, valorUDI, true);
            break;

          case 65:
            this.getValidarProyecciones(this.primaAcumuladaUDI, edad, plazoTot, proyeccionAnterior, proyeccionNueva, valorUDI, true);
            break;

          case 70:
            this.getValidarProyecciones(this.primaAcumuladaUDI, edad, plazoTot, proyeccionAnterior, proyeccionNueva, valorUDI, true);
            break;

          default:
            this.getValidarProyecciones(this.primaAcumuladaUDI, edad, plazoTot, proyeccionAnterior, proyeccionNueva, valorUDI, false);
        }
      }
    }
    return this.proyeccion.value;
  }

  /**
 * MÉTODO QUE GENERA EL PORCENTAJE DE RECUPERACIÓN PROPUESTAS
 * @param {number} anioPoliza
 * @param {number} edad
 * @param {number} plazoAct
 * @param {number} plazoTot
 * @param {number} valorUDI
 * @param {Plazo} plazo
 * @returns {number}
 * @memberof ValoresGarantizadosService
 */
  generarPorcentajeRecuperacionPropuestas(
    anioPoliza: number, edad: number, plazoAct: number, plazoTot: number, valorUDI: number, plazo: Plazo): number {
    this.primaAcumuladaUDI = 0;
    let nuevaProyeccion = 0;

    if (anioPoliza === 1) {
      if (this.BA_Service.primaAnual_UDI.value !== 0) {
        nuevaProyeccion = this.primasCoberturasAdicionalesPropuestas(false, plazo, 0);
        this.proyeccionAnterior.next(nuevaProyeccion);
        this.primaAcumuladaUDI = nuevaProyeccion;
        this.proyeccion.next(this.primaAcumuladaUDI);
        this.udiAno1 = valorUDI;

        switch (this.planOptions.moneda) {
          case 'UDI':
            this.primaAcumulada_MXN.next(this.primaAcumuladaUDI * valorUDI || 0);
            break;
          default:
            this.primaAcumulada_MXN.next(this.primaAcumuladaUDI || 0);
        }

      } else {
        return 0;
      }

    } else if (anioPoliza === 2) {

      const proyeccionAnterior = Number(this.proyeccion.value);

      if (Number(plazoTot) === 1) {
        this.proyeccion.next(proyeccionAnterior);
      } else {

        if (edad >= plazoTot) {
          switch (Number(plazoTot)) { // validacon para edades alcanzadas  // primaAcumuladaUDI = proyeccionAnterior;
            case 60:
              this.primaAcumuladaUDI = proyeccionAnterior;
              break;

            case 65:
              this.primaAcumuladaUDI = proyeccionAnterior;
              break;

            case 70:
              this.primaAcumuladaUDI = proyeccionAnterior;
              break;

            default: // validacion para edades no alcanzadas // primaAcumuladaUDI = proyeccionAnterior + proyeccionAnterior;
              this.primaAcumuladaUDI = proyeccionAnterior + proyeccionAnterior;
          }
        } else {
          this.primaAcumuladaUDI = proyeccionAnterior + proyeccionAnterior;
        }

        this.proyeccion.next(this.primaAcumuladaUDI);
        switch (this.planOptions.moneda) {
          case 'UDI':
            if (edad >= plazoTot) {

              switch (Number(plazoTot)) {
                case 5:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                case 10:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                case 15:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                case 20:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                case 25:
                  this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
                  break;

                default:
                  this.primaAcumulada_MXN.next(proyeccionAnterior * this.udiAno1);
              }

            } else {
              this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + (proyeccionAnterior * valorUDI));
            }
            break;
          default:
            if (edad >= plazoTot) {
              this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + proyeccionAnterior);
            } else {
              this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + proyeccionAnterior);
            }
        }
      }
    } else if (anioPoliza > 2) {
      const proyeccionAnterior = Number(this.proyeccion.value);
      let proyeccionNueva = 0;

      this.proyeccionAnterior.next(proyeccionAnterior);

      proyeccionNueva = this.primasCoberturasAdicionalesPropuestas(true, plazo, edad);
      if (plazoAct > plazoTot) {
        this.primaAcumuladaUDI = proyeccionAnterior;
        this.proyeccion.next(this.primaAcumuladaUDI);
      } else {
        switch (Number(plazoTot)) {

          case 60:
            this.getValidarProyecciones(this.primaAcumuladaUDI, edad, plazoTot, proyeccionAnterior, proyeccionNueva, valorUDI, true);
            break;

          case 65:
            this.getValidarProyecciones(this.primaAcumuladaUDI, edad, plazoTot, proyeccionAnterior, proyeccionNueva, valorUDI, true);
            break;

          case 70:
            this.getValidarProyecciones(this.primaAcumuladaUDI, edad, plazoTot, proyeccionAnterior, proyeccionNueva, valorUDI, true);
            break;

          default:
            this.getValidarProyecciones(this.primaAcumuladaUDI, edad, plazoTot, proyeccionAnterior, proyeccionNueva, valorUDI, false);
        }
      }
    }
    return this.proyeccion.value;
  }

  /**
   * MÉTODO QUE OBTIENE LA VALIDACIÓN DE LAS PROYECCIONES
   * @param {number} primaAcumuladaUDI
   * @param {number} edad
   * @param {number} plazo
   * @param {number} proyAnterior
   * @param {number} proNueva
   * @param {number} valUdi
   * @param {boolean} val
   * @memberof ValoresGarantizadosService
   */
  getValidarProyecciones(primaAcumuladaUDI: number, edad: number, plazo: number, proyAnterior: number, proNueva: number, valUdi: number, val: boolean) {
    if (edad >= plazo && val) {
      primaAcumuladaUDI = proyAnterior;
      this.proyeccion.next(primaAcumuladaUDI);

    } else {
      primaAcumuladaUDI = proNueva + proyAnterior;
      switch (this.planOptions.moneda) {
        case 'UDI':
          this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + proNueva * valUdi);
          break;
        default:
          this.primaAcumulada_MXN.next(this.primaAcumulada_MXN.value + proNueva);
      }

      this.proyeccion.next(primaAcumuladaUDI);
    }
  }

  obtenerFactorValorGarantizado(factoresValores: ValueFactors[] = this.factores.factoresValoresElegido, anioPolizaActual: number): number {
    const anioPoliza = String(anioPolizaActual + 1);

    // console.log(factoresValores);
    if (typeof factoresValores.filter(item => item.AnioPoliza === anioPoliza)[0].Factor === 'string') {
      // console.log(this.descipher.desiph(String(factoresValores.filter(item => item.AnioPoliza === anioPoliza)[0].Factor)).replace(/,/g, ''));
      return Number(parseFloat(this.descipher.desiph(String(factoresValores.filter(item => item.AnioPoliza === anioPoliza)[0].Factor)).replace(/,/g, '')));
      // return Number(parseFloat(String(factoresValores.filter(item => item.AnioPoliza === anioPoliza)[0].Factor).replace(/,/g, '')));
    }
    return factoresValores.filter(item => item.AnioPoliza === anioPoliza)[0].Factor;
  }

  /**
   * MÉTODO QUE GENERA LA PRIMA DE LAS COBERTURAS ADICIONALES
   * @param {boolean} evaluarVigencia
   * @param {Plazo} plazo
   * @param {number} [edad=0]
   * @param {*} [test=null]
   * @param {number} [plazoTest=0]
   * @returns {number}
   * @memberof ValoresGarantizadosService
   */
  primasCoberturasAdicionales(
    evaluarVigencia: boolean, plazo: Plazo, edad: number = 0, test: any = null, plazoTest: number = 0, force: boolean = false, specificCase: number = 0,
    edadTitular: number
  ): number {
    let proyeccion = 0;
    // coberturas
    let BA_prima = this.BA_Service.primaAnual_UDI.value,
      BI_prima = this.BI_Service.primaAnual_UDI.value,
      PAI_prima = this.PAI_Service.primaAnual_UDI.value,
      CC_prima = this.CC_Service.primaAnual_UDI.value,
      DI_prima = this.DI_Service.primaAnual_UDI.value,
      DIPO_prima = this.DIPO_Service.primaAnual_UDI.value,
      DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value,
      IAC_prima = this.IAC_Service.primaAnual_UDI.value,
      PAE_prima = this.PAE_Service.primaAnual_UDI.value,
      PCD_prima = this.PCD_Service.primaAnual_UDI.value,
      GF_prima = this.GF_Service.primaAnual_UDI.value,
      EFI_prima = this.EFI_Service.primaAnual_UDI.value,
      TUTOR_prima = this.TUTOR_Service.primaAnual_UDI.value;
    // const excedente = this.excedentes.total_excedente.value;
    switch (true) {
      case specificCase === 1 || specificCase === 5:
        BA_prima = this.BA_Service.primaAnual_UDI_Sugerida1.value;
        BI_prima = this.BI_Service.primaAnual_UDI_Sugerida1.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI_Sugerida1.value;
        CC_prima = this.CC_Service.primaAnual_UDI_Sugerida1.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI_Sugerida1.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI_Sugerida1.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI_Sugerida1.value;
        GF_prima = this.GF_Service.primaAnual_UDI_Sugerida1.value;
        break;
      case specificCase === 2 || specificCase === 4 || specificCase === 6:
        BA_prima = this.BA_Service.primaAnual_UDI.value;
        BI_prima = this.BI_Service.primaAnual_UDI.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI.value;
        CC_prima = this.CC_Service.primaAnual_UDI.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI.value;
        GF_prima = this.GF_Service.primaAnual_UDI.value;
        break;
      case specificCase === 3:
        BA_prima = this.BA_Service.primaAnual_UDI_Sugerida2.value;
        BI_prima = this.BI_Service.primaAnual_UDI_Sugerida2.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI_Sugerida2.value;
        CC_prima = this.CC_Service.primaAnual_UDI_Sugerida2.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI_Sugerida2.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI_Sugerida2.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI_Sugerida2.value;
        GF_prima = this.GF_Service.primaAnual_UDI_Sugerida2.value;
        break;
      case plazo === 'Sugerido2' &&
        plazoTest < 65 || (plazo === 'Sugerido2' &&
          plazoTest === 65 && test === 70) || (plazo === 'Sugerido1' &&
            plazoTest === 70 && test === 65):
        BA_prima = this.BA_Service.primaAnual_UDI_Sugerida2.value;
        BI_prima = this.BI_Service.primaAnual_UDI_Sugerida2.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI_Sugerida2.value;
        CC_prima = this.CC_Service.primaAnual_UDI_Sugerida2.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI_Sugerida2.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI_Sugerida2.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI_Sugerida2.value;
        GF_prima = this.GF_Service.primaAnual_UDI_Sugerida2.value;
        break;
      case plazo === 'Sugerido1' &&
        plazoTest < 65 || (plazo === 'Elegido' &&
          plazoTest === 65 && test === 60) || (plazo === 'Elegido' &&
            plazoTest === 70 && test === 60) || (plazoTest === 15 && plazo === 'Elegido' && force):

        BA_prima = this.BA_Service.primaAnual_UDI_Sugerida1.value;
        BI_prima = this.BI_Service.primaAnual_UDI_Sugerida1.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI_Sugerida1.value;
        CC_prima = this.CC_Service.primaAnual_UDI_Sugerida1.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI_Sugerida1.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI_Sugerida1.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI_Sugerida1.value;
        GF_prima = this.GF_Service.primaAnual_UDI_Sugerida1.value;
        break;
      case plazo === 'Sugerido2' &&
        plazoTest < 65 || (plazo === 'Elegido' &&
          plazoTest === 65 && test === 60) || (plazo === 'Sugerido2' &&
            plazoTest === 70 && test === 70):

        BA_prima = this.BA_Service.primaAnual_UDI.value;
        BI_prima = this.BI_Service.primaAnual_UDI.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI.value;
        CC_prima = this.CC_Service.primaAnual_UDI.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI.value;
        GF_prima = this.GF_Service.primaAnual_UDI.value;
        break;
      case specificCase === 7:
        BI_prima = 0;
        PAI_prima = 0;
        CC_prima = 0;
        DI_prima = 0;
        DIPO_prima = 0;
        DIPOC_prima = 0;
        IAC_prima = 0;
        PAE_prima = 0;
        PCD_prima = 0;
        GF_prima = 0;
        EFI_prima = 0;
        TUTOR_prima = 0;
        break;
    }
    if (plazo === 'ElegidoBA') {
      BA_prima = this.BA_Service.primaAnual_UDI.value;
    }
    if (plazo === 'Sugerido1BA') {
      BA_prima = this.BA_Service.primaAnual_UDI_Sugerida1.value;
    }
    if (plazo === 'Sugerido2BA') {
      BA_prima = this.BA_Service.primaAnual_UDI_Sugerida2.value;
    }

    // Plazotest === 65
    // test -> 60 -> Sugerido1 -> E
    // test -> 65 -> Elegido ->S1
    // test -> 70 -> Sugerido2 ->S2

    // suma
    if (evaluarVigencia && this.planOptions.tipoPlan !== 'ALIADOSKIDS') {
      proyeccion = (
        BA_prima +
        this.planOptions.coberturaEnVigencia(BI_prima, BI, edad) +
        this.planOptions.coberturaEnVigencia(PAI_prima, PAI, edad) +
        this.planOptions.coberturaEnVigencia(CC_prima, CC, edad) +
        this.planOptions.coberturaEnVigencia(DI_prima, DI, edad) +
        this.planOptions.coberturaEnVigencia(DIPO_prima, DIPO, edad) +
        this.planOptions.coberturaEnVigencia(DIPOC_prima, DIPOC, edad) +
        this.planOptions.coberturaEnVigencia(IAC_prima, IAC, edad) +
        this.planOptions.coberturaEnVigencia(PAE_prima, PAE, edad) +
        this.planOptions.coberturaEnVigencia(PCD_prima, PCD, edad) +
        this.planOptions.coberturaEnVigencia(GF_prima, GF, edad)
        // excedente
      );
    } else if(this.planOptions.tipoPlan !== 'ALIADOSKIDS') {
      proyeccion = (
        BA_prima +
        BI_prima +
        PAI_prima +
        CC_prima +
        DI_prima +
        DIPO_prima +
        DIPOC_prima +
        IAC_prima +
        PAE_prima +
        PCD_prima +
        GF_prima   
      );
    } else{
      let efiPrima;
      if(!EFI_prima){
        efiPrima = 0
      }   
      else{        
        efiPrima = this.planOptions.coberturaEnVigencia(EFI_prima, EFI, edadTitular);
      }
      
      proyeccion = (
        BA_prima +
        TUTOR_prima +
        efiPrima
      );
    }

    if (this.planOptions.tipoPlan === 'MUJER UNICA') {
      proyeccion += this.VPL_Service.primaAnual_PrimeraMoneda.value;
      proyeccion += this.CDC_Service.primaAnual_PrimeraMoneda.value;
    }
    if (plazo === 'Elegido' && this.planOptions.tipoPlan !== 'ALIADOSKIDS') {
      proyeccion = this.primasDiezMas(proyeccion, edad, edadTitular);
    } else if (plazo === 'ElegidoBA') {
      proyeccion = this.primasDiezMasElegidoBa(proyeccion, edad);
    }
    return proyeccion;
  }


  private primasDiezMas(proyeccion: number, edad: number, edadTitular: number) {
    if (edad === 0) {
      this.GF_Service.prima_DiezMasGf = 0;
      this.CDC_Service.prima_DiezMasCdc = 0;
      this.PAE_Service.prima_DiezMasPae = 0;
      this.IAC_Service.prima_DiezMasPae = 0;
    }
    if (this.planOptions.arrEdad.includes(edad.toString())) {
      if (this.planOptions.moneda === 'MXN') {
        this.PAE_Service.calcularPrimaDiezMas(edadTitular);
        this.IAC_Service.calcularPrimaDiezMas(edadTitular);
        if (this.planOptions.tipoPlan === 'MUJER UNICA' && edad <= 65) {
          this.CDC_Service.calculoPrimaCDCDiezMas(edad);
        }
      }
      this.GF_Service.calcularPrimaDiezMas(edad);
    }

    proyeccion += this.planOptions.coberturaEnVigencia(this.IAC_Service.prima_DiezMasPae, IAC, edad);
    proyeccion += this.planOptions.coberturaEnVigencia(this.PAE_Service.prima_DiezMasPae, PAE, edad);
    proyeccion += this.planOptions.coberturaEnVigencia(this.CDC_Service.prima_DiezMasCdc, CDC_MU, edad);
    proyeccion += this.planOptions.coberturaEnVigencia(this.GF_Service.prima_DiezMasGf, GF, edad) ;
    if (this.PCD_Service.primaAnual_UDI.value > 0) {
      proyeccion = this.PCD_Service.calcularprimaDiezMasPcd(proyeccion, edad);
    }
    return proyeccion;
  }

  private primasDiezMasElegidoBa(proyeccion: number, edad: number) {
    if (edad === 0) {
      this.CDC_Service.prima_DiezMasCdc = 0;
    }
    if (this.planOptions.moneda === 'MXN') {
      if (this.planOptions.tipoPlan === 'MUJER UNICA' && edad <= 65 && this.planOptions.arrEdad.includes(edad.toString())) {
        this.CDC_Service.calculoPrimaCDCDiezMas(edad);
      }
      proyeccion += this.CDC_Service.prima_DiezMasCdc;
    }
    return proyeccion;
  }

  /**
 * MÉTODO QUE GENERA LA PRIMA DE LAS COBERTURAS ADICIONALES
 * @param {boolean} evaluarVigencia
 * @param {Plazo} plazo
 * @param {number} [edad=0]
 * @returns {number}
 * @memberof ValoresGarantizadosService
 */
  primasCoberturasAdicionalesPropuestas(evaluarVigencia: boolean, plazo: Plazo, edad: number): number {
    let proyeccion = 0;
    // coberturas
    let BA_prima = 0,
      BI_prima = 0,
      PAI_prima = 0,
      CC_prima = 0,
      DI_prima = 0,
      DIPO_prima = 0,
      DIPOC_prima = 0,
      IAC_prima = 0,
      PAE_prima = 0,
      PCD_prima = 0,
      GF_prima = 0;

    switch (true) {
      case plazo === 'Elegido':
        BA_prima = this.BA_Service.primaAnual_UDI.value;
        BI_prima = this.BI_Service.primaAnual_UDI.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI.value;
        CC_prima = this.CC_Service.primaAnual_UDI.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI.value;
        GF_prima = this.GF_Service.primaAnual_UDI.value;
        break;
      case plazo === 'Sugerido1':
        BA_prima = this.BA_Service.primaAnual_UDI_Sugerida1.value;
        BI_prima = this.BI_Service.primaAnual_UDI_Sugerida1.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI_Sugerida1.value;
        CC_prima = this.CC_Service.primaAnual_UDI_Sugerida1.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI_Sugerida1.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI_Sugerida1.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI_Sugerida1.value;
        GF_prima = this.GF_Service.primaAnual_UDI_Sugerida1.value;
        break;
      case plazo === 'Sugerido2':
        BA_prima = this.BA_Service.primaAnual_UDI_Sugerida2.value;
        BI_prima = this.BI_Service.primaAnual_UDI_Sugerida2.value;
        PAI_prima = this.PAI_Service.primaAnual_UDI_Sugerida2.value;
        CC_prima = this.CC_Service.primaAnual_UDI_Sugerida2.value;
        DI_prima = this.DI_Service.primaAnual_UDI.value;
        DIPO_prima = this.DIPO_Service.primaAnual_UDI.value;
        DIPOC_prima = this.DIPOC_Service.primaAnual_UDI.value;
        IAC_prima = this.IAC_Service.primaAnual_UDI_Sugerida2.value;
        PAE_prima = this.PAE_Service.primaAnual_UDI_Sugerida2.value;
        PCD_prima = this.PCD_Service.primaAnual_UDI_Sugerida2.value;
        GF_prima = this.GF_Service.primaAnual_UDI_Sugerida2.value;
        break;
      case plazo === 'ElegidoBA':
        BA_prima = this.BA_Service.primaAnual_UDI.value;
        break;
      case plazo === 'Sugerido1BA':
        BA_prima = this.BA_Service.primaAnual_UDI_Sugerida1.value;
        break;
      case plazo === 'Sugerido2BA':
        BA_prima = this.BA_Service.primaAnual_UDI_Sugerida2.value;
        break;
    }
    // suma
    if (evaluarVigencia) {
      proyeccion = (
        BA_prima +
        this.planOptions.coberturaEnVigencia(BI_prima, BI, edad) +
        this.planOptions.coberturaEnVigencia(PAI_prima, PAI, edad) +
        this.planOptions.coberturaEnVigencia(CC_prima, CC, edad) +
        this.planOptions.coberturaEnVigencia(DI_prima, DI, edad) +
        this.planOptions.coberturaEnVigencia(DIPO_prima, DIPO, edad) +
        this.planOptions.coberturaEnVigencia(DIPOC_prima, DIPOC, edad) +
        this.planOptions.coberturaEnVigencia(IAC_prima, IAC, edad) +
        this.planOptions.coberturaEnVigencia(PAE_prima, PAE, edad) +
        this.planOptions.coberturaEnVigencia(PCD_prima, PCD, edad) +
        this.planOptions.coberturaEnVigencia(GF_prima, GF, edad)
        // excedente
      );
    } else {
      proyeccion = (
        BA_prima +
        BI_prima +
        PAI_prima +
        CC_prima +
        DI_prima +
        DIPO_prima +
        DIPOC_prima +
        IAC_prima +
        PAE_prima +
        PCD_prima +
        GF_prima 
        // excedente
      );
    } 
    return proyeccion;
  }

  porcentajeINPC() {
    this.porcentaje_INPC = this.calculoDeducible.inflacionINPC;
    return this.porcentaje_INPC;
  }

  saINPC() {
    this.saINPC_VG = this.calculoDeducible.saINPC;
    return this.saINPC_VG;
  }

  porcentajeSAinpc() {
    this.porcentaje_SA_INPC_VG = this.calculoDeducible.porcentajeINPC;
    return this.porcentaje_SA_INPC_VG;
  }

  calculoINPCCoberturas(prima: number, edad: number, sumasPasadas: number[] = [], aumentosPasados: number[] = [],
    aumentoBasica: number = 0, aumentoParaPCD: number = 0, anio: number, edadMancomunado: number): 
    { last: number[], suma: number, sumasPasadas: number[] } {
    return this.calculoDeducible.calcularINPCCoberturas(prima, edad, sumasPasadas, aumentosPasados, aumentoBasica, aumentoParaPCD, anio, edadMancomunado);
  }

  get primaBA(): number {
    return this.calculoDeducible.BASICA_primaAnualUDI.value;
  }

  get activeCoberturas(): boolean {
    return this.calculoDeducible.activeCoberturas.value;
  }

  cancelarCoberturas(edad: number, sumasPasadas: number[]): number {
    const coberturas = this.calculoDeducible.selectedCoberturas;
    let resta = 0;

    /**
      1.       BI cancela a los 60 años.
      2.       PAI cancela a los 60 años.
      3.       PAE cancela a los 60 años.
      4.       DI cancela a los 70 años
      5.       DIPO cancela a los 70 años
      6.       DIPOC cancela a los 70 años
      7.       IAC cancela a los 65 años
      8.       PCD cancela a los 65 años
      9.       CC cancela a los 90 años
      10.   GF cancela a los 80 años
     */

    if (edad === 61) {
      const indexBI = coberturas.indexOf('BI');
      const indexPAI = coberturas.indexOf('PAI');
      const indexPAE = coberturas.indexOf('PAE');

      resta += indexBI !== -1 ? sumasPasadas[indexBI] : 0;
      resta += indexPAI !== -1 ? sumasPasadas[indexPAI] : 0;
      resta += indexPAE !== -1 ? sumasPasadas[indexPAE] : 0;
    }

    if (edad === 66) {
      const indexIAC = coberturas.indexOf('IAC');
      const indexPCD = coberturas.indexOf('PCD');

      resta += indexIAC !== -1 ? sumasPasadas[indexIAC] : 0;
      resta += indexPCD !== -1 ? sumasPasadas[indexPCD] : 0;
    }

    if (edad === 71) {
      const indexDI = coberturas.indexOf('DI');
      const indexDIPO = coberturas.indexOf('DIPO');
      const indexDIPOC = coberturas.indexOf('DIPOC');

      resta += indexDI !== -1 ? sumasPasadas[indexDI] : 0;
      resta += indexDIPO !== -1 ? sumasPasadas[indexDIPO] : 0;
      resta += indexDIPOC !== -1 ? sumasPasadas[indexDIPOC] : 0;
    }

    if (edad === 81 || (edad > 70 && this.planOptions.arrEdad.includes(edad.toString()))) {
      const indexGF = coberturas.indexOf('GF');

      resta += indexGF !== -1 ? sumasPasadas[indexGF] : 0;
      sumasPasadas[indexGF] = 0;
    }

    if (edad === 91) {
      const indexCC = coberturas.indexOf('CC');

      resta += indexCC !== -1 ? sumasPasadas[indexCC] : 0;
    }

    return resta;
  }

  porcentajeRecuperacionN(valorRescate: number, primaAcumulada: number, valorUdi: number): number {
    switch (this.planOptions.moneda) {
      case 'UDI':
        this.primaAcumulada_MXN.next(primaAcumulada * valorUdi || 0);
        break;
      default:
        this.primaAcumulada_MXN.next(primaAcumulada || 0);
    }
    const a = (valorRescate / primaAcumulada) * 100;
    this.porcentajeRecuperacionUDI.next(a);
    return this.porcentajeRecuperacionUDI.value;
  }

}
