import { PipesModule } from '@pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsibleModule } from 'angular2-collapsible';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { ProposalsComponent } from '@components/proposals/proposals.component';
import { SharedModule } from '@shared/shared.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MatRadioModule } from '@angular/material/radio';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    CollapsibleModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    CollapseModule.forRoot(),
    PipesModule,
    TooltipModule.forRoot()
  ],
  declarations: [
    ProposalsComponent,
  ],
  exports: [
    MatRadioModule,
  ]
})
export class ProposalsModule { }
