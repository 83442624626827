import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { Gender, Fumador } from '@models/index';
import { OfflineconfigModalComponent } from '@shared/modal/offlineconfig-modal/offlineconfig-modal.component';
import { NetworkStateService } from '@services/networkState/network-state.service';
import { FactoresService } from '@services/factores/factores.service';
import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { DateUtilsService } from '@services/date-utils/date-utils.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { CalculosService } from '@services/calculos/calculos.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { RoutesService } from '@services/routes/routes.service';
import { ScrollPercentageDetectionService } from '@services/scrollPercentageDetection/scroll-percentage-detection.service';
import { ResizeDetectionService } from '@services/resizeDetection/resize-detection.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { VariableConfigurationService } from '@services/variableConfiguration/variable-configuration.service';
import { PaymentFrequencyService } from '@services/paymentFrequency/payment-frequency.service';
import { BaseDatosService } from '@services/baseDatos/base-datos.service';
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';
import { DEFAULT_CURRENCY, DEFAULT_SECONDCURRENCY } from '@constants/currency/currency.constant';
import { DEFAULT_ANUAL_INFLATION } from '@constants/plan-options/anual-inflations.constant';
import { DEFAULT_PLANTYPE } from '@constants/plan-options/plan-types.constant';
import { ROUTE_NAMING } from '@constants/routes/route-naming.constant';
import { PROSPECTU_CONSTANTS, PROSPECTU_CONSTANTS_KIDS } from '@constants/prospectu/prospectu.constant';
import { PROSPECTUS_DATA_CONSTANTS } from '@constants/prospectus-data/prospectus-data.constant';

import { BehaviorSubject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal/.';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

/**
 * Componente de prospectus data
 */
@Component({
  selector: 'cvida-prospectus-data',
  templateUrl: './prospectus-data.component.html',
  styleUrls: ['./prospectus-data.component.sass']
})
export class ProspectusDataComponent implements OnInit {
  /**
   * Constantes del proyecto
   * @property
   */
  PROSPECTU_CONSTANTS = PROSPECTU_CONSTANTS;
  PROSPECTU_CONSTANTS_KIDS = PROSPECTU_CONSTANTS_KIDS;
  /**
   * Formulario de los datos del prospecto
   * @property
   * @type {FormGroup}
   */
  datosProspecto: FormGroup;
  /**
   * Verifica si el input de edad está activo
   * @property
   * @type {boolean}
   */
  isEdadActive: boolean;
  isEdadMancomunado = true;
  /**
   * Fechas disponibles
   * @property
   */
  fecha: { dias: number[]; meses: string[]; anios: number[] };
  fechaKids: { dias: number[]; meses: string[]; anios: number[] };
  /**
   * Año actual
   * @property
   * @type {any}
   */
  actualYear: any;
  /**
   * Género actual seleccionado
   * @property
   * @type {Gender}
   */
  currentGender: Gender;
  currentGenderMancomunado: Gender;
  currentGenderKids: Gender;
  /**
   * Hábito actual seleccionado
   * @property
   * @type {Fumador}
   */
  currentFumador: Fumador;
  currentFumadorMancomunado: Fumador;
  boolMancomunado = false;
  /**
   * Hace referencia a un template
   * @property
   * @type {TemplateRef<any>}
   */
  template: TemplateRef<any>;
  /**
   * Mes seleccionado
   * @property
   */
  selectedMonth = 0;
  selectedMonthMan = 0;
  selectedMonthKids = 0;
  /**
   * Día seleccionado
   * @property
   */
  selectedDay = 0;
  selectedDayMan = 0;
  selectedDayKids = 0;
  /**
   * Año seleccionado
   * @property
   */
  selectedYear = 0;
  selectedYearMan = 0;
  selectedYearKids = 0;
  /**
   * Verifica si es un dispositivo mobile
   * @property
   * @type {boolean}
   */
  dispositivo: boolean;
  /**
   * Referencia a un modal
   * @property
   * @type {BsModalRef}
   */
  bsModalRef: BsModalRef;
  /**
   * Referencia a un modal
   * @property
   * @type {BsModalRef}
   */
  modalRef: BsModalRef;
  /**
   * Máscara de edad
   * @property
   */
  ageMask = [/[1-9]/, /[1-9]/];
  /**
   * Nombres de rutas
   * @property
   */
  rutas = ROUTE_NAMING;
  /**
   * Máscara de números
   * @property
   */
  onlyNums = createNumberMask({
    prefix: '',
    integerLimit: 2,
    allowDecimal: false
  });
  /**
   * Fecha actual
   * @property
   * @type {Date}
   */
  actualDate = new Date();

  /**
   *  Variable que almacena el valor de estado [online/offline] de la bd.
   * @property
   * @memberof ProspectusDataComponent
   */
  online$ = this.bd.online$;
  mode: boolean;
  edadEqui = new BehaviorSubject<number>(0);
  edadEquiActive: boolean;

  /**
   *  Variable almacena el valor de despliegue componentes kids.
   * @property
   * @memberof None
   */
  kidsProduct: any ={
    selected: false,
    isEdadKids: true
  };
  

  /**
   *Crea una instancia de ProspectusDataComponent.
   * @param {FormBuilder} fb
   * @param {DateUtilsService} dateUtils
   * @param {Router} router
   * @param {ProspectoService} prospectoService
   * @param {CalculosService} calculosService
   * @param {RoutesService} routesService
   * @param {DeviceTypeService} device
   * @param {ScrollPercentageDetectionService} scrollPercent Importa el servicio de detección de porcentaje del scroll.
   * @param {ResizeDetectionService} resizeDetection Importa el servicio de detección de Redimensionamiento.
   * @param {OpcionesPlanService} opcionesPlan
   * @param {VariableConfigurationService} variableSettings
   * @param {ProtectionPlansGeneratorService} generator
   * @param {ExcedentesService} excedentes
   * @param {BsModalService} modal
   * @param {BaseDatosService} bd
   * @param {OpcionesPlanService} opciones
   * @memberof ProspectusDataComponent
   */
  constructor(
    private fb: FormBuilder,
    private dateUtils: DateUtilsService,
    private router: Router,
    private prospectoService: ProspectoService,
    private calculosService: CalculosService,
    private device: DeviceTypeService,
    public scrollPercent: ScrollPercentageDetectionService,
    public resizeDetection: ResizeDetectionService,
    private opcionesPlan: OpcionesPlanService,
    private variableSettings: VariableConfigurationService,
    private generator: ProtectionPlansGeneratorService,
    private excedentes: ExcedentesService,
    private modal: BsModalService,
    private bd: BaseDatosService,
    private opciones: OpcionesPlanService,
    private paymentFrequency: PaymentFrequencyService,
    private network: NetworkStateService,
    public routes: RoutesService,
    private retiro: FactoresService
  ) {
    this.actualYear = this.dateUtils.getActualYear();

    this.retiro.retiroValue.next(false);

    this.routes.firstTimeChoose.next(true);

    this.routes.isIndividual.next(true);
    this.opciones.tipoRiesgo.next('Normal');

    if (this.calculosService.alreadyCalculated.value) {
      this.calculosService.alreadyCalculated.next(false);
    }

    this.opcionesPlan.tipoPlan = DEFAULT_PLANTYPE;
    this.opcionesPlan.moneda = DEFAULT_CURRENCY;
    this.opcionesPlan.segundaMoneda = DEFAULT_SECONDCURRENCY;
    this.generator.available.distinctUntilChanged().subscribe(value => {
      this.generator.productsGenerator(DEFAULT_PLANTYPE);
      this.generator.currenciesGenerator(DEFAULT_CURRENCY, DEFAULT_PLANTYPE);
      this.generator.generatePlansFront(DEFAULT_PLANTYPE, 0);
      this.generator.inflationsGenerator();
    });
    this.generator.productsGenerator(DEFAULT_PLANTYPE);
    this.generator.currenciesGenerator(DEFAULT_CURRENCY, DEFAULT_PLANTYPE);
    this.generator.generatePlansFront(DEFAULT_PLANTYPE, 0);
    this.generator.inflationsGenerator();
    this.excedentes.inflacion_udi.next(DEFAULT_ANUAL_INFLATION);
    this.excedentes.aportacion_anual_UDI.next(0);
    this.paymentFrequency.frecuenciaPago.next('ANUAL');
    this.calculosService.divisorActual = 1;
    this.calculosService.recargoActual = 0;
    this.calculosService.paymentFrecuency = 'ANUAL';
    this.calculosService.calcularPagoDividido();
    this.calculosService.resetCalculus();
    this.opciones.incrementosProgramados.next(false);

    this.online$.subscribe(status => {
       // Plan
      this.generator.productsGenerator(this.opciones.tipoPlan);
      this.generator.products.subscribe(products => {
        this.opciones.tipodeplanes = products;
      });
      // Plazo pagos
      this.generator.generatePlansFront('VPL', this.opciones.plazoPago.value);
      this.generator.NewTermsToExport.subscribe(plazo => {
        this.opciones.plazos = plazo.terms;
      });
    });
  }

  /**
   * Lifecycle Hook de OnInit
   * @method
   */
  ngOnInit() {
    this.edadEquiActive = false;

    this.network.lastNetworkState = this.network.state;
     // this.mode = this.network.lastNetworkState;
    this.mode = this.network.state;
    // console.log('valor:: mode:::: ' + this.mode );
    window.scroll(0, 0);

    this.prospectoService.isProspectuActive.next(false);

    // Limpia los datos temporales de arma tu plan
    this.variableSettings.resetCommissions();
    localStorage.removeItem('frecuenciaPago');
    // Inicializar variables
    this.devtype();
    this.currentGender = <Gender>PROSPECTUS_DATA_CONSTANTS.DEFAULT_GENDER;
    this.currentFumador = PROSPECTUS_DATA_CONSTANTS.DEFAULT_HABIT as Fumador;
    this.isEdadActive = true;
    this.currentGenderMancomunado = <Gender>PROSPECTUS_DATA_CONSTANTS.DEFAULT_GENDER;
    this.currentFumadorMancomunado = PROSPECTUS_DATA_CONSTANTS.DEFAULT_HABIT as Fumador;
    this.currentGenderKids= <Gender>PROSPECTUS_DATA_CONSTANTS.DEFAULT_GENDER;
    // Genera formulario base
    this.generateForm();

    // Genera las fechas por primera vez
    this.fecha = this.generateDates(PROSPECTU_CONSTANTS.MAXIMUM_AGE, PROSPECTU_CONSTANTS.MINIMUN_AGE);
    this.fechaKids = this.generateDates(PROSPECTU_CONSTANTS_KIDS.MAXIMUM_AGE, PROSPECTU_CONSTANTS_KIDS.MINIMUN_AGE);

    this.opcionesPlan.valoresGarantizadosListos.next(false);

    if (localStorage.getItem('showConfigModal') !== 'true') {
      localStorage.setItem('showConfigModal', 'true');
      this.openModal();
    }

    this.excedentes.aportacion_anual_UDI.next(0);
    this.excedentes.primerAnioAportacion.next(1);
    this.excedentes.segundoAnioAportacion.next(2);
    this.excedentes.montoAdicionalInput.next(0);

  }

  /**
   * Abre modal
   * @method
   */
  openModal() {
    if (this.dispositivo === true) {
      if ( localStorage.getItem('showwhentEmptyPlans') !== 'true') {
        this.modalRef = this.modal.show(OfflineconfigModalComponent, { backdrop: false, class: 'alert-modal' });
        localStorage.setItem('showwhentEmptyPlans', 'true');
      }
    }
  }

  /**
   * Genera formulario
   * @method
   */
  generateForm() {
    this.datosProspecto = this.fb.group({
      name: '',
      age: [
        ,
        Validators.compose([
          Validators.required,
          this.routes.chosenCotizador.value !== 'AxaParaTodos' ? this.validateAgeInput : this.validateAgeInputAxaParaTodos
        ])
      ],
      gender: ['H', Validators.compose([Validators.required])],
      fumador: ['NO', Validators.compose([Validators.required])],
      nameMancomunado: '',
      genderMancomunado: 'H',
      ageManc: '',
      fumadorMancomunado: 'NO',
      nameKids: '',
      genderKids: 'H',
      ageKids:['', 
        Validators.compose([
          Validators.required, this.validateAgeKidsInput 
        ])      
      ],
    });
  }

  /**
   * Devuelve el tipo de dispositivo
   * @method
   * @return {boolean}
   */
  devtype() {
    if (this.device.getDeviceType() === 'iPad') {
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'iPhone') {
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'Android') {
      return this.dispositivo = true;
    } else {
      return this.dispositivo = false;
    }
  }

  /**
   * Valida el input de la edad
   * @method
   * @param input validateYearInit
   */
  private validateAgeInput(input: FormControl) {
    const valor = input.value;    
    const isValid = (isFinite(Number(valor)) && Number(valor) >= PROSPECTU_CONSTANTS.MINIMUN_AGE && Number(valor) <= PROSPECTU_CONSTANTS.MAXIMUM_AGE);     
    return isValid ? null : {notValid: true};
  }

  private validateAgeKidsInput(input: FormControl){    
    const valor = input.value;
    const isValid = ( isFinite(Number(valor)) && Number(valor) >= PROSPECTU_CONSTANTS_KIDS.MINIMUN_AGE && Number(valor) <= PROSPECTU_CONSTANTS_KIDS.MAXIMUM_AGE);
    return isValid ? null : {notValid: true};
  }

  private validateAgeInputAxaParaTodos(input: FormControl) {
    const valor = input.value;
    const isValid = isFinite(Number(valor)) && Number(valor) >= PROSPECTU_CONSTANTS.MINIMUN_AGE && Number(valor) <= 65;
    return isValid ? null : {notValid: true};
  }

  /**
   * Máscara de nombre
   * @method
   * @param rawValue
   * @return {RegExp[]}
   */
  nameMask(rawValue: string): RegExp[] {
    const mask = /[A-Za-zÁÉÍÓÚáéíóú ]/;
    const strLength = String(rawValue).length;
    const nameMask: RegExp[] = [];

    for (let i = 0; i <= strLength; i++) {
      nameMask.push(mask);
    }

    return nameMask;

  }

  /**
   * Genera fechas
   * @method
   */
  generateDates( max: number, min: number): any {
    const fecha = {
      dias: this.dateUtils.getDaysInMonth(
        this.dateUtils.getActualYear(),
        this.dateUtils.getActualMonth()
      ),
      meses: [
        'ENERO',
        'FEBRERO',
        'MARZO',
        'ABRIL',
        'MAYO',
        'JUNIO',
        'JULIO',
        'AGOSTO',
        'SEPTIEMBRE',
        'OCTUBRE',
        'NOVIEMBRE',
        'DICIEMBRE'
      ],
      anios: []
    };
    const minAnio = Number(this.actualYear) - max,
      maxAnio = Number(this.actualYear) - min;
    for (let i = minAnio; i <= maxAnio; i++) {
      fecha.anios = [...fecha.anios, i];
    }
    return fecha;
  }

  /**
   * Cambia el día
   * @method
   * @param input_day
   */
  changeDay(input_day: number, isMan = false, isKids = false): void {
    const DAY = Number(input_day);
    if( !isKids ){
      isMan ? this.selectedDayMan = DAY : this.selectedDay = DAY;
      this.validateAge(isMan);
    }else{
      this.selectedDayKids = DAY;
      this.validateAge(false, isKids);
      }    
  }

  /**
   * Cambia el mes
   * @method
   * @param input_month
   */
  changeMonth(input_month: number, isMan = false, isKids = false): void {
    const MONTH = Number(input_month);
    this.selectedMonth = MONTH;
    let newDays: number[] = [];
    newDays = this.dateUtils.getDaysInMonth(
      this.dateUtils.getActualYear(),
      MONTH,
      false
    );
    this.fecha.dias = newDays;
    if( !isKids ){
      isMan ? this.selectedMonthMan = MONTH : this.selectedMonth = MONTH;
      this.validateAge(isMan);
    }else{
      this.selectedMonthKids = MONTH;
      this.validateAge(false, isKids);
    }    
  }

  /**
   * Cambia el año
   * @method
   * @param input_year
   */
  changeYear(input_year: number, isMan = false, isKids = false): void {
    const YEAR = Number(input_year);
    if( !isKids ){
      isMan ? this.selectedYearMan = YEAR : this.selectedYear = YEAR;
      this.validateAge(isMan);
    }else{
      this.selectedYearKids = YEAR;
      this.validateAge(false, isKids);
    }    
  }

  /**
   * Valida la edad
   * @method
   */
  validateAge(isMan = false, isKids = false) {
    const
    input_year  = isMan ? this.selectedYearMan  : isKids ? this.selectedYearKids  : this.selectedYear,
    input_month = isMan ? this.selectedMonthMan : isKids ? this.selectedMonthKids : this.selectedMonth,
    input_day   = isMan ? this.selectedDayMan   : isKids ? this.selectedDayKids   : this.selectedDay;
    const
    actual_year  = this.actualDate.getFullYear(),
    actual_month = this.actualDate.getMonth(),
    actual_day   = this.actualDate.getDate();
    let edad = 0;

    if (
      input_month === actual_month && input_day <= actual_day ||
      input_month  <  actual_month) {
      edad = actual_year - input_year;
    } else if (input_year > 0) {
      edad = actual_year - input_year - 1;
    }

    if (edad >= actual_year - 1) {
      edad = 0;
    }
    
    isMan  ?  this.datosProspecto.controls['ageManc'].setValue(edad) : 
    isKids ?  this.datosProspecto.controls['ageKids'].setValue(edad) :
              this.datosProspecto.controls['age'].setValue(edad);

    if(!isKids)
      this.getEdadEquivalente();
  }

  /**
   * Cambia la manera en cómo se introduce la edad
   * @method
   * @param isEdad
   */
  changeRadio(isEdad: boolean) {
    this.datosProspecto.controls['age'].setValue(null);
    if (isEdad !== this.isEdadActive) {
      this.isEdadActive = isEdad;
    }
  }

  changeRadioMancomunado(edad: boolean) {
    if (edad !== this.isEdadMancomunado) {
      this.isEdadMancomunado = edad;
    }
  }

  changeRadioKids(edad: boolean) {
    if (edad !== this.kidsProduct.isEdadKids) {
      this.kidsProduct.isEdadKids = edad;
    }
  }

  changeMancomunado(boolMancomunado: boolean) {
    if (boolMancomunado !== this.boolMancomunado) {
      this.boolMancomunado = boolMancomunado;
      if (!this.boolMancomunado) {
          this.validProspoectuAge();
      }
    }
  }

  /**
   * Selecciona el día
   * @method
   * @param value
   */
  selectDay(value: any) {
    const day = Number(value);
    this.selectedDay = day;
  }

  /**
   * Esstablece un género
   * @method
   * @param gender
   */
  setActiveGender(gender: Gender): void {
    if (gender !== this.currentGender) {
      this.currentGender = gender;
      this.datosProspecto.controls['gender'].setValue(gender);
    } else {
      return;
    }
  }

  setActiveGenderMancomunado(gender: Gender): void {
    if (gender !== this.currentGenderMancomunado) {
      this.currentGenderMancomunado = gender;
      this.datosProspecto.controls['genderMancomunado'].setValue(gender);
    } else {
      return;
    }
  }

  setActiveGenderKids(gender: Gender): void {
    if (gender !== this.currentGenderKids) {
      this.currentGenderKids = gender;
      this.datosProspecto.controls['genderKids'].setValue(gender);
    } else {
      return;
    }
  }
  /**
   * Checa si hay un géneto seleccionado
   * @method
   * @param gender
   */
  isActiveGender(gender: Gender): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    return this.currentGender === gender;
  }

  isActiveGenderMancomunado(gender: Gender): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    return this.currentGenderMancomunado === gender;
  }

  isActiveGenderKids(gender: Gender): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    return this.currentGenderKids === gender;
  }

  /**
   * Establece un hábito
   * @method
   * @param fumador
   */
  setActiveHabit(fumador: Fumador): void {
    if (this.currentFumador !== fumador) {
      this.currentFumador = fumador;
      this.datosProspecto.controls['fumador'].setValue(fumador);
    } else {
      return;
    }
  }

  setActiveHabitMancomunado(fumador: Fumador): void {
    if (this.currentFumadorMancomunado !== fumador) {
      this.currentFumadorMancomunado = fumador;
      this.datosProspecto.controls['fumadorMancomunado'].setValue(fumador);
    } else {
      return;
    }
  }

  /**
   * Checa si hay un hábito activo
   * @method
   * @param habit
   * @return {boolean}
   */
  isActiveHabit(habit: Fumador): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    return this.currentFumador === habit;
  }

  isActiveHabitMancomunado(habit: Fumador): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    return this.currentFumadorMancomunado === habit;
  }

  /**
   * Continua el flujo
   * @method
   * @param flow
   */
  continueFlow(flow: number) {
    this.prospectoService.isProspectuActive.next(true);
    const datos = this.datosProspecto.value;
    const edad = datos.age;
    const prospectu: any = {
      name: datos.name || PROSPECTU_CONSTANTS.DEFAULT_NAME,
      gender: datos.gender,
      age: edad,
      fumador: datos.fumador,
      mancomunado: {
        mancomunado: false,
        nameMancomunado: '',
        genderMancomunado: '',
        ageMancomunado: '',
        fumadorMancomunado: '',
        edadEquivalente: 0
      },
      kids: {
        kids: false,
        nameKids: '',
        genderKids:'',
        ageKids: ''
      }
    };

    if (this.boolMancomunado) {
      prospectu.mancomunado.mancomunado = true;
      prospectu.mancomunado.nameMancomunado = datos.nameMancomunado;
      prospectu.mancomunado.genderMancomunado = datos.genderMancomunado;
      prospectu.mancomunado.ageMancomunado = datos.ageManc;
      prospectu.mancomunado.fumadorMancomunado = datos.fumadorMancomunado;
      prospectu.mancomunado.edadEquivalente = this.edadEqui.value;
    }

    if(this.kidsProduct.selected){
      prospectu.kids.kids = true;
      prospectu.kids.nameKids = datos.nameKids !== '' ? datos.nameKids : 'Nombre Menor';
      prospectu.kids.genderKids = datos.genderKids;
      prospectu.kids.ageKids = datos.ageKids;
    }

    this.prospectoService.changeProspecto(prospectu);
    if (flow === 1) {
      this.routes.chosenCotizador.value !== 'AxaParaTodos' ?
      this.routes.routes.next([
        ROUTE_NAMING.PRINCIPAL,
        ROUTE_NAMING.PASO1,
        ROUTE_NAMING.PASO2A,
        ROUTE_NAMING.PASO3,
        ROUTE_NAMING.PASO4,
        ROUTE_NAMING.REQUISITOS
      ]) : this.routes.routes.next([
        ROUTE_NAMING.PRINCIPAL,
        ROUTE_NAMING.PASO1,
        ROUTE_NAMING.PASO2A,
        ROUTE_NAMING.TARIFAS,
        ROUTE_NAMING.REQUISITOS
      ]);
    } else {
      this.routes.routes.next([
        ROUTE_NAMING.PRINCIPAL,
        ROUTE_NAMING.PASO1,
        ROUTE_NAMING.PASO2B,
        ROUTE_NAMING.PASO3,
        ROUTE_NAMING.PASO4,
        ROUTE_NAMING.REQUISITOS
      ]);
    }
    this.router.navigate([ROUTE_NAMING.PRINCIPAL, this.routes.chosenCotizador.value, this.routes.routes.value[2]]);
  }

  /**
   * Válida la edad del prospecto
   * @method
   * @return {boolean}
   */
  validProspoectuAge() {         
    const validacion = this.datosProspecto.controls['age'].invalid && this.datosProspecto.controls['age'].touched;
    
    if (!this.boolMancomunado && !this.kidsProduct.selected ) {  
      this.datosProspecto.controls['ageManc'].clearValidators();
      this.datosProspecto.controls['ageManc'].updateValueAndValidity();      
      this.datosProspecto.controls['ageKids'].clearValidators();
      this.datosProspecto.controls['ageKids'].updateValueAndValidity();
      this.datosProspecto.controls['age'].updateValueAndValidity();      
    } else {
      this.datosProspecto.controls['ageManc'].setValidators(this.validateAgeInput);
      this.datosProspecto.controls['ageKids'].setValidators([Validators.required, this.validateAgeKidsInput]);
    }    
    return validacion;   
  }

  validProspoectuAgeMancomunado(): boolean {   
    const validacion = this.datosProspecto.controls['ageManc'].invalid && this.datosProspecto.controls['ageManc'].touched;
    return validacion;
  }

  get validProspoectuAgeKids(): boolean {
    return (this.datosProspecto.controls['ageKids'].invalid && this.datosProspecto.controls['ageKids'].touched);
  }

  getEdadEquivalente() {
    this.edadEquiActive = false;
    const datos = this.datosProspecto.value;
    // console.log('Edad del Titular === ', datos.age);
    // console.log('Edad del Mancomunado === ', datos.ageManc);
    if ( (datos.age >= 18 && datos.age <= 70) && (datos.ageManc >= 18 && datos.ageManc <= 70)) {
    // base datos
      this.bd.getEquivalentAge(String(datos.age), String(datos.ageManc), this.opciones.plan).then(info => {
        this.edadEqui.next(info.docs[0].EdadEquivalente);
        this.edadEquiActive = true;
        // console.log('Edad Equivalente === ', this.edadEqui.value);
      }).catch(err => console.log(err));
    }
  }

  /**
   * Verifica si el input de edad (individual) está vacío
   * @method
   * @param {event}
   */
  checkEmptinessAge(event) {
    const age = event.target.value;

    if (age === '') {
      this.datosProspecto.controls['age'].setValue(age);
    }
  }

  /**
   * Verifica si el input de edad (mancomunado) está vacío
   * @method
   * @param {event}
   */
  checkEmptinessAgeManc(event) {
    const age = event.target.value;

    if (age === '') {
      this.datosProspecto.controls['ageManc'].setValue(age);
    }
  }
  /**
   * Verifica si el input de edad (kids) está vacío
   * @method
   * @param {event}
   */
  checkEmptinessAgeKids(event) {
    const age = event.target.value;
    if (age === '') {
      this.datosProspecto.controls['ageKids'].setValue(age);
    }
  }

}
