/**
 * Constante que almacena las distintas velocidades de descarga de las bases de datos.
 * @const
 */
export const REPLICATION = {
  X1: 500,
  X2: 1000,
  X3: 2000,
  X4: 4000,
  X5: 8000,
  X6: 10000
};
