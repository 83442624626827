import { PlanType } from '@constants/plan-options/plan-types.constant';

/**
 * Estructura básica de los términos personalizados.
 * @interface
 */
export interface CustomTerms {
  /**
   * Plan
   * @property
   * @type {PlanType}
   */
  plan: PlanType;
  /**
   * Término
   * @property
   */
  terms: Terms[];
}

/**
 * Estructura básica de los términos personalizados (para el frontend)
 * @interface
 */
export interface FrontCustomTerm {
  /**
   * Plan
   * @property
   * @type {PlanType}
   */
  plan: PlanType;
  /**
   * Término (en el frontend)
   * @property
   */
  terms: FrontTerms[];
}

/**
 * Estructura básica de los términos.
 * @interface
 */
export interface Terms {
  /**
 * Valor del término
 * @property
 */
  value: number;
  /**
 *Estado del término
 * @property
 */
  default: boolean;
}

/**
 * Estructura básica de los términos (para el frontend)
 * @interface
 */
export interface FrontTerms {
  /**
   * Título del término
   * @property
   * @type {string}
   */
  title: string;
  /**
   * Valor del término
   * @property
   * @type {number}
   */
  value: number;
  /**
   * Estado del término
   * @property
   * @type {boolean}
   */
  default: boolean;
  /**
   * Plan.
   * @type {boolean}
   */
  plan: PlanType;
  /**
   * Desabilitado - Estado
   * @type {boolean}
   */
  disabled?: boolean;
}

export class PlanList {
  constructor(
    public id: string,
    public producto: string,
    public plazo: string
    ) {}

}
