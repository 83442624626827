import { environment } from '../../../environments/environment';
// Modelos
import {
  Gender,
  Fumador
} from '..';

/**
 * PROSPECTO
 * @description Clase que abstrae las propiedades del prospecto.
 * @param {string} name
 * @param {Gender} gender
 * @param {number} age
 * @param {Fumador} fumador
 * @memberof Prospectu
 */
export class Prospectu {
  /**Nombre del Prospecto*/
  name: string;
  /**Género del Prospecto*/
  gender: Gender;
  /**Edad del Prospecto*/
  age: number;
 /**Hábito del Prospecto*/
  fumador: Fumador;
    /** Mancomunado */
    mancomunado: {
      mancomunado: boolean,
      nameMancomunado: string,
      genderMancomunado: Gender,
      ageMancomunado: number,
      fumadorMancomunado: Fumador,
      edadEquivalente: number
    };

  /** Kids */
  kids: {
    kids: boolean,
    nameKids: string,
    genderKids: Gender,
    ageKids: number,
  };

    /**
   *Crea una instancia de Prospectu. Inicializa las variables.
   * @memberof Prospectu
   */
  constructor() {
    this.name = environment.production ?
      'PROSPECTO' :
      'DEV-PROSPECTU';
    this.gender = 'H';
    this.age = 18;
    this.fumador = 'NO';
    this.mancomunado = {
      mancomunado: false,
      nameMancomunado: null,
      genderMancomunado: null,
      ageMancomunado: null,
      fumadorMancomunado: null,
      edadEquivalente: null
    };
    this.kids = {
      kids      : false,
      nameKids  : null,
      genderKids: null,
      ageKids   : null
    };
  }

}
