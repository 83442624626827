
// Angular Core
import { Component, OnInit } from '@angular/core';

/**
 * Componente encaragado de la necesidad de retiro
 */
@Component({
  selector: 'cvida-retirement',
  templateUrl: './retirement.component.html',
  styleUrls: ['./retirement.component.sass']
})
export class RetirementComponent implements OnInit {

  /**
   * Pregunta uno activa
   * @property
   * @type [boolean]
   */
  isAskOneActive: boolean;
  /**
   * Mácara de edad
   *
   * @memberof RetirementComponent
   */
  ageMask = [/[1-9]/, /[1-9]/];

  /**
   * Lifecycle hook de NgOnInit
   * @method
   */
  ngOnInit() {
    this.isAskOneActive = true;
  }

  /**
   * Cambia la pregunta seleccionada
   * @method
   * @param isEdad
   */
  changeQuestion(isEdad: boolean) {
    if (isEdad !== this.isAskOneActive) {
      this.isAskOneActive = isEdad;
    }
  }

}
