import { Currency } from '../currency/currency.constant';

export const fondoBasicoMXVI = [
  [100, 100, 100, 100, 0, 0], [3, 5, 6, 7, 0, 0]
];

// ¿Sólo va a tener disponible dólares y dinámico eua?
export const fondoBasicoUSVI = [
  [0, 100], [1, ]
];

// ¿Cuánto de porcentaje?
export const fondoExcedenteMXVI = [
  [0, 0, 0, 0, 0], [3, 5, 6, 7, 0, 0]
];

export const fondoExcedenteUSVI = [
  [], [0, 0, 0, 0, 1, 4]
];

// Mi Proyecto R

export const fondoBasicoMXMPR = [
  [100, 100, 100, 100, 0, 0], [3, 5, 6, 7, 0, 0]
];

// ¿Sólo va a tener disponible dólares y dinámico eua?
export const fondoBasicoUSMPR = [
  [0, 100], [1, ]
];

// ¿Cuánto de porcentaje?
export const fondoExcedenteMXMPR = [
  [], [3, 5, 6, 7, 0, 0]
];

export const fondoExcedenteUSMPR = [
  [], [0, 0, 0, 0, 1, 4]
];


export function fillFondoBasico(fondoBasico: object, moneda: 'MXN' | 'USD' ): object {
  return {};
}

export function fillFondoExcedente(fondoExcedente: object, moneda: Currency): object {
  return {};
}
