import { CalculosService } from '@services/calculos/calculos.service';
import { Component, OnInit } from '@angular/core';

// TextMask
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { CurrencyToNumberPipe } from '@pipes/currency-to-number/currency-to-number.pipe';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProspectoService } from '@app/services/prospecto/prospecto.service';
import { combineLatest, BehaviorSubject } from 'rxjs';

/**
 * Surplus component (Aportaciones adicionales)
 * @export
 * @class SurplusComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-surplus',
  templateUrl: './surplus.component.html',
  styleUrls: ['./surplus.component.sass']
})
export class SurplusComponent implements OnInit {
  /**
   * Estado del collapse
   * @property
   * @type {boolean}
   */
  isCollapsed = false;

  /**
   * Estado de surplus
   * @property
   * @type {boolean}
   */
  surplus = false;
  /**
   * Máscara de aportaciones
   * @property
   */
  aportacionesMask = createNumberMask({
    prefix: '',
    integerLimit: 2,
    allowDecimal: false
  });

  /**
   * Aportación anual en UDI.
   * @property
   * @type {number}
   */
  aportacionAnualUDI: number;
  /**
   * Número de aportaciones.
   * @property
   * @type {number}
   */
  numeroAportaciones: number;
  /**
   * rendimiento actual (porcentaje)
   * @property
   * @type {number}
   */
  rendimientoActual: number;
  /**
   * Estado de aportaciones.
   * @property
   * @type {boolean}
   */
  isaported: boolean;

  /**
   *Máscara de moneda.
   * @type {number}
   */
  currency = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: false,
    decimalLimit: 0
  });

  /** Tasa de interes  */
  tasaInteres: string[] = [];
  plazo: number;

  /**
   * Año seleccionado
   * @property
   */
  selectedYearFinal = 0;
  selectedYearInicial = 0;
  isValidYearInicial: boolean;
  isValidYearFinal: boolean;
  isValidAportacion: boolean;

  anioPlazo: FormGroup;

  tasaInteresSelected: number;
  firstAdded = true;
  sumaAportacion: number;
  aportacion: number;
  prima: number;

  excedentesValues = new BehaviorSubject<(number | string)[]>([1, 2, 0, '1']);

  activeMancomunado: boolean;
  edadEquivalente: number;

  inputText: string;

  /**
   *  Detecta el estado del radio button.
   * @method
   * @returns {boolean}
   */
  isChecked(): boolean {
    return this.aportacionAnualUDI !== 0;
  }

  /**
   * Declaración de variables.
   * @param excedentes
   * @param currencyPipe
   * @param {FormBuilder} fb
   **/

  constructor(
    private excedentes: ExcedentesService,
    private currencyPipe: CurrencyToNumberPipe,
    public opcionesPlan: OpcionesPlanService,
    private prospecto: ProspectoService,
    private calculos: CalculosService
  ) {
    this.excedentes.aportacion_anual_UDI
      .distinctUntilChanged()
      .subscribe(aportacionAnual => {
        this.aportacionAnualUDI = aportacionAnual;
      });

    this.excedentes.numero_aportaciones
      .distinctUntilChanged()
      .subscribe(numero => {
        this.numeroAportaciones = numero;
      });

    this.rendimientoActual = this.excedentes.rendimiento_actual;
    this.excedentes.cambioMoneda.subscribe(TI => {
      this.tasaInteres = TI;
    });

    combineLatest(
      this.excedentes.primerAnioAportacion,
      this.excedentes.segundoAnioAportacion,
      this.excedentes.montoAdicionalInput,
      this.excedentes.tasaInteresInput
    ).subscribe(values => this.excedentesValues.next(values));

    this.anioPlazo = new FormGroup({
      yearInit: new FormControl('', [Validators.required]),
      yearFi: new FormControl('', [Validators.required])
    });

    this.selectedYearInicial = 1;
    this.selectedYearFinal = 2;
    this.isValidYearFinal = true;
    this.isValidYearInicial = true;

    this.prospecto.prospectoActual.subscribe(prospectoActual => {
      this.activeMancomunado = prospectoActual.mancomunado.mancomunado;
      this.edadEquivalente = prospectoActual.mancomunado.edadEquivalente;
    });
    this.tasaInteresSelected = Number(this.tasaInteres[0]);
    this.excedentes.aportacion_anual_UDI.subscribe(
      aport => (this.aportacion = aport)
    );
    this.calculos.primaTotalFraccionadaDividida$.subscribe(
      primaTotal => (this.prima = primaTotal)
    );
  }

  /**
   * Lifecycle Hook de OnInit
   * @method
   */
  ngOnInit() {
    this.opcionesPlan.plazoPago.subscribe(p => (this.plazo = p));

    this.tasaInteresSelected = Number(this.tasaInteres.filter(tasa => tasa === this.excedentes.tasaInteresInput.value)[0]);

    this.inputText = `${this.excedentesValues.value[2]}`;
  }

  /**
   * Valida el input de año inicial
   * @method
   * @param input
   */
  private validateYearInit(inputInit: number): boolean {
    const valorInit = inputInit;
    let isValidInit;
    if (
      this.opcionesPlan.tipoPlan === 'TEMPORAL' ||
      this.opcionesPlan.tipoPlan === 'DOTAL' ||
      this.opcionesPlan.tipoPlan === 'ALIADOSKIDS'
    ) {
      isValidInit =
        isFinite(Number(valorInit)) &&
        (Number(valorInit) > 0 && Number(valorInit) <= this.plazo);
    } else if (this.opcionesPlan.tipoPlan === 'VPL') {
      isValidInit =
        isFinite(Number(valorInit)) &&
        (Number(valorInit) > 0 && Number(valorInit) <= 99);
    } else if (this.opcionesPlan.tipoPlan === 'OV') {
      isValidInit =
        isFinite(Number(valorInit)) &&
        (Number(valorInit) > 0 &&
          Number(valorInit) <= 99 - this.opcionesPlan.prospecto.age);
    } else if (
      this.opcionesPlan.tipoPlan === 'MUJER UNICA' && !this.activeMancomunado
    ) {
      isValidInit =
        isFinite(Number(valorInit)) &&
        (Number(valorInit) > 0 &&
          Number(valorInit) <= 99 - this.opcionesPlan.prospecto.age);
    }

    const valid = isValidInit ? true : false;

    if (valid) {
      this.selectedYearInicial = valorInit;
      this.excedentes.primerAnioAportacion.next(valorInit);
    } else {
      this.selectedYearInicial = 1;
    }

    return isValidInit;
  }
  /**
   * Valida el input de año final
   * @method
   * @param input
   */
  private validateYearFinal(inputFinal: number): boolean {
    const valorFinal = inputFinal;
    let isValidFinal;
    // Ordinario de Vida
    if (
      (this.opcionesPlan.tipoPlan === 'OV' ||
        this.opcionesPlan.tipoPlan === 'VPL') &&
      !this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= 99 - this.opcionesPlan.prospecto.age);
    } else if (
      (this.opcionesPlan.tipoPlan === 'OV' ||
        this.opcionesPlan.tipoPlan === 'VPL') &&
      this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= 99 - this.edadEquivalente);
    } else if (
      this.opcionesPlan.tipoPlan === 'TEMPORAL' &&
      (this.plazo !== 60 && this.plazo !== 65 && this.plazo !== 70)
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= this.plazo);
    } else if (
      this.opcionesPlan.tipoPlan === 'TEMPORAL' &&
      (this.plazo === 60 || this.plazo === 65 || this.plazo === 70) &&
      !this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <=
            this.plazo - this.prospecto.prospecto.value.age);
    } else if (
      this.opcionesPlan.tipoPlan === 'TEMPORAL' &&
      (this.plazo === 60 || this.plazo === 65 || this.plazo === 70) &&
      this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <=
            this.plazo -
              this.prospecto.prospecto.value.mancomunado.edadEquivalente);
    } else if (
      this.opcionesPlan.tipoPlan === 'DOTAL' &&
      (this.plazo === 15 || this.plazo === 20) &&
      !this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= this.plazo);
    } else if (
      this.opcionesPlan.tipoPlan === 'DOTAL' &&
      (this.plazo === 15 || this.plazo === 20) &&
      this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= this.plazo);
    } else if (
      this.opcionesPlan.tipoPlan === 'DOTAL' &&
      this.plazo === 65 &&
      this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= 65 - this.edadEquivalente);
    } else if (
      this.opcionesPlan.tipoPlan === 'DOTAL' &&
      this.plazo === 65 &&
      !this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= 65 - this.opcionesPlan.prospecto.age);
    } else if (
      this.opcionesPlan.tipoPlan === 'MUJER UNICA' && !this.activeMancomunado
    ) {
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= 99 - this.opcionesPlan.prospecto.age);
    } else if( this.opcionesPlan.tipoPlan === 'ALIADOSKIDS'){
      isValidFinal =
        isFinite(Number(valorFinal)) &&
        (Number(valorFinal) >= this.selectedYearInicial &&
          Number(valorFinal) <= this.plazo);
    }

    const valid = isValidFinal ? true : false;

    if (valid) {
      this.selectedYearFinal = valorFinal;
      this.excedentes.segundoAnioAportacion.next(valorFinal);
    }

    return isValidFinal;
  }

  /**
   * toma valor del input
   * @method
   * @param gender
   */
  setActiveGender(inputFin: number): void {
    this.anioPlazo.controls['yearFi'].setValue(null);
  }

  /**
   *  Cambiar estado de aportaciones adicionales.
   * @method
   * @param {boolean} status
   * @memberof SurplusComponent
   */
  changeSurplus(status: boolean): void {
    this.surplus = status;
    if (!status) {
      this.eliminarAportaciones();
    }
  }

  /**
   * Limpia el input
   * @param input
   * @method
   */
  cleanInput(input: HTMLInputElement) {
    if (input.value === '0') {
      input.value = '';
    }
  }

  /**
   *
   * Guarda las aportaciones ingresadas.
   * @param {*} aportacion
   * @param {*} aportaciones
   * @memberof SurplusComponent
   */
  guardarAportaciones(aportacion: any, aportaciones: any): void {
    this.excedentes.aportacion_anual_UDI.next(
      this.currencyPipe.transform(aportacion)
    );
    this.excedentes.primerAportacion.next(Number(aportaciones.primera));
    this.excedentes.segundaAportacion.next(Number(aportaciones.segunda));
    this.excedentes.numero_aportaciones.next(
      Number(aportaciones.segunda - aportaciones.primera)
    );
    this.excedentes.crecimiento_anual.next(this.tasaInteresSelected / 100);

    this.isaported = true;
    setTimeout(() => {
      this.isaported = false;
    }, 3500);
  }
  /**
   * Elimina las aportaciones ingresadas.
   * @method
   */
  eliminarAportaciones(): void {
    this.excedentes.aportacion_anual_UDI.next(0);
    this.excedentes.numero_aportaciones.next(0);
    this.excedentes.primerAportacion.next(0);
    this.excedentes.segundaAportacion.next(0);
  }

  selectTi(tasaIn: any) {
    this.tasaInteresSelected = Number(tasaIn);
    this.excedentes.tasaInteresInput.next(tasaIn);
  }

  validateFirstYear(event) {
    const yearValue = Number(event.value);
    this.isValidYearInicial = this.validateYearInit(yearValue);
  }

  validateSecondYear(event) {
    const yearValue = Number(event.value);
    this.isValidYearFinal = this.validateYearFinal(yearValue);
  }

  validateAportacion() {
    const aportacion = this.removeChars(this.inputText);

    if (this.firstAdded) {
      this.firstAdded = false;
    }

    aportacion > 0
      ? (this.isValidAportacion = true)
      : (this.isValidAportacion = false);

    if (this.isValidAportacion) {
      this.excedentes.montoAdicionalInput.next(aportacion);
    }

  }

  removeChars(value: string): number {
    return Number(`${value}`.replace(/\,/g, ''));
  }

}
