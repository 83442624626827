/** Angular Core **/
import { Injectable, LOCALE_ID, Inject } from '@angular/core';
/** Constants */
import {
  BAS, MU, BAUniversal, PAIUniversal, DIUniversal, DIPOCUniversal, CDC, PLAN_TEMP_PAI,
  PLAN_TEMP_CC, PLAN_TEMP_DI, PLAN_TEMP_DIPO, PLAN_TEMP_DIPOC, PLAN_TEMP_IAC, PLAN_TEMP_PAE
} from '@constants/coverages/coverages.constants';
import { PAI, CC, DI, DIPO, DIPOC, IAC, PAE } from '@constants/coverages';
/** Services */
import { BAService } from '@services/coverages/BA/ba.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
/** Models */
import { Coverage } from '../../models';
import { ValidationType } from '@models/validationtype-constants/validationtype-constants.interface';
import { PlanType } from '@app/constants/plan-options/plan-types.constant.pre';
import { CDC_MU } from '@constants/coverages';
import { PLAN_TEMP } from './../../constants/coverages/coverages.constants';
import { CoverageConstants } from '@models/.';
import { formatCurrency } from '@angular/common';

/**
 *
 *  Servicio dedicado a las validaciones de las coberturas respecto a sus montos y monedas.
 * @export
 * @class ValidationTypeService
 */
@Injectable({
  providedIn: 'root'
})

export class ValidationTypeService {
  /**
   *  Valor.
   * @property
   * @type {ValidationType}
   */
  value: ValidationType;
  /**
   *  Datos.
   * @property
   * @type {ValidationType}
   */
  data: ValidationType;
  /**
   *  Mensaje.
   * @property
   * @type {string}
   */
  msg: string;
  /**
   *  Estado.
   * @property
   * @type {boolean}
   */
  status: boolean;
  /**
   *  Prueba
   * @property
   */
  test = '';

  /**
   *Crea una instancia de ValidationTypeService e inicializa las variables..
   * @param {BAService} baServices
   * @param {OpcionesPlanService} coverageCoin
   */
  constructor(
    private baServices: BAService,
    private coverageCoin: OpcionesPlanService,
    @Inject(LOCALE_ID) private locale: string
  ) { }
  /**
   *  Valida que la moneda y el monto elegido correspondan con las tarifas mínimas y máximas de la cobertura.
   * @method
   * @param {number} amount
   * @param {Coverage} key
   * @returns {ValidationType}
   */
  getValidationData(amount: number, key: Coverage, plan?: PlanType): ValidationType {
    switch (this.coverageCoin.moneda) {
      case 'UDI':        
        this.data = this.validateAmountUID(amount, key, plan);
        break;
      case 'MXN':
        this.data = this.validateAmountMXN(amount, key, plan);
        break;

      case 'USD':
        this.data = this.validateAmountUSD(amount, key);
        break;
    }
    return this.data;
  }
  /**
   *
   * Si la moneda seleccionada es UDI, este método valida los montos de cada cobertura respecto a sus tarifas mínima y máxima.
   * @param {number} amount
   * @param {Coverage} key
   * @returns {ValidationType}
   * @memberof ValidationTypeService
   */
  validateAmountUID(amount: number, key: Coverage, plan?: PlanType): ValidationType {
    switch (key) {
      case 'BASICA':
        // if (amount < (BAS.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > (BAS.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }     
          this.validateAmounts(amount, BAS, false, plan);        
        break;
      case 'PAI':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(PAI.MAX_SUM_UDI)) {
        //   this.msg = 'La Suma Asegurada no puede ser mayor a la Cobertura Básica';
        //   this.status = true;
        // } else if (amount < Number(PAI.MIN_SUM_UDI)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > Number(PAI.MAX_SUM_UDI)) {
        //   this.msg = 'La Suma Asegurada máxima es de 4,000,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, PAI, true);
        break;
      case 'CC':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(CC.MAX_SUM_UDI)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        //   this.status = true;
        // } else if (amount < (CC.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > (CC.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 800,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, CC, true);
        break;
      case 'DI':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DI.MAX_SUM_UDI)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        //   this.status = true;
        // } else if (amount < (DI.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > (DI.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 4,000,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, DI, true);
        break;
      case 'DIPO':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DIPO.MAX_SUM_UDI)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        //   this.status = true;
        // } else if (amount < (DIPO.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > (DIPO.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 4,000,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, DIPO, true);
        break;
      case 'DIPOC':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DIPOC.MAX_SUM_UDI)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        //   this.status = true;
        // } else if (amount < (DIPOC.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > (DIPOC.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 4,000,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, DIPOC, true);
        break;
      case 'IAC':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(IAC.MAX_SUM_UDI)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        //   this.status = true;
        // } else if (amount < (IAC.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > (IAC.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 400,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, IAC, true);
        break;
      case 'PAE':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(PAE.MAX_SUM_UDI)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        // } else if (amount < (PAE.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > (PAE.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 200,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, PAE, true);
        break;
      case 'VPL':
        // if (amount < (MU.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 80,000 UDI';
        //   this.status = true;
        // } else if (amount > (MU.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 200,000,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, MU, false);
        break;
      case 'CDC':
        if (amount > this.baServices.sumaAsegurada_UDI.value && amount <= Number(CDC_MU.MAX_SUM_UDI)) {
          this.msg = 'La suma asegurada no puede ser mayor a la Cobertura de Temporal';
          this.status = true;
        }
        // else if (amount < (CDC_MU.MIN_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 0 UDI';
        //   this.status = true;
        // } else if (amount > (CDC_MU.MAX_SUM_UDI || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 400,000 UDI';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, CDC_MU, true);
        break;
    }
    const values: ValidationType = ({
      mesage: this.msg,
      status: this.status
    });

    return values;
  }

  /**
   * Si la moneda seleccionada es USD, este método valida los montos de cada cobertura respecto a sus tarifas mínima y máxima.
   * @param amount
   * @param key
   */
  validateAmountUSD(amount: number, key: Coverage) {
    let sumMin;
    let sumMax;
    let sumas = { sumMin: sumMin, sumMax: sumMax };
    switch (key) {
      case 'BASICA':
        // if (amount < (BAS.MIN_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
        //   this.status = true;
        // } else if (amount > (BAS.MAX_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 dólares';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, BAS, false);
        break;

      case 'PAI':
        if (this.coverageCoin.tipoPlan !== 'VIDA INTELIGENTE' && this.coverageCoin.tipoPlan !== 'MI PROYECTO R') {
          // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(PAI.MAX_SUM_USD)) {
          //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //   this.status = true;
          // } else if (amount < (PAI.MIN_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
          //   this.status = true;
          // } else if (amount > (PAI.MAX_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 1,250,000 dólares';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          this.validateAmounts(amount, PAI, true);
        } else {
          // if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
          //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(PAIUniversal.MAX_SUM_MXN)) {
          //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //     this.status = true;
          //   } else if (amount < (PAIUniversal.MIN_SUM_VI_USD || 0)) {
          //     this.msg = 'La Suma Asegurada miníma es de 35,000 dólares';
          //     this.status = true;
          //   } else if (amount > (PAIUniversal.MAX_SUM_USD || 0)) {
          //     this.msg = 'La Suma Asegurada máxima es de 1,176,471 dólares';
          //     this.status = true;
          //   } else {
          //     this.msg = '';
          //     this.status = false;
          //   }
          // }
          // if (this.coverageCoin.tipoPlan === 'MI PROYECTO R') {
          //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(PAIUniversal.MAX_SUM_MXN)) {
          //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //     this.status = true;
          //   } else if (amount < (PAIUniversal.MIN_SUM_MR_USD || 0)) {
          //     this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
          //     this.status = true;
          //   } else if (amount > (PAIUniversal.MAX_SUM_USD || 0)) {
          //     this.msg = 'La Suma Asegurada máxima es de 1,176,471 dólares';
          //     this.status = true;
          //   } else {
          //     this.msg = '';
          //     this.status = false;
          //   }
          // }
          this.validateAmounts(amount, PAIUniversal, true);
        }
        break;

      case 'CC':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(CC.MAX_SUM_USD)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        //   this.status = true;
        // } else if (amount < (CC.MIN_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
        //   this.status = true;
        // } else if (amount > (CC.MAX_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de $250,000 dólares';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, CC, true);
        break;

      case 'DI':
        if (this.coverageCoin.tipoPlan !== 'VIDA INTELIGENTE' && this.coverageCoin.tipoPlan !== 'MI PROYECTO R') {
          // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DI.MAX_SUM_USD)) {
          //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //   this.status = true;
          // } else if (amount < (DI.MIN_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
          //   this.status = true;
          // } else if (amount > (DI.MAX_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de $1,250,000 dólares';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          this.validateAmounts(amount, DI, true);
        } else {
          // if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
          //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(DIUniversal.MAX_SUM_USD)) {
          //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //     this.status = true;
          //   } else if (amount < (DIUniversal.MIN_SUM_VI_USD || 0)) {
          //     this.msg = 'La Suma Asegurada miníma es de 35,000 dólares';
          //     this.status = true;
          //   } else if (amount > (DIUniversal.MAX_SUM_USD || 0)) {
          //     this.msg = 'La Suma Asegurada máxima es de 1,176,471 dólares';
          //     this.status = true;
          //   } else {
          //     this.msg = '';
          //     this.status = false;
          //   }
          // }
          // if (this.coverageCoin.tipoPlan === 'MI PROYECTO R') {
          //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(DIUniversal.MAX_SUM_USD)) {
          //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //     this.status = true;
          //   } else if (amount < (DIUniversal.MIN_SUM_MR_USD || 0)) {
          //     this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
          //     this.status = true;
          //   } else if (amount > (DIUniversal.MAX_SUM_USD || 0)) {
          //     this.msg = 'La Suma Asegurada máxima es de 1,176,471 dólares';
          //     this.status = true;
          //   } else {
          //     this.msg = '';
          //     this.status = false;
          //   }
          // }
          this.validateAmounts(amount, DIUniversal, true);
        }
        break;

      case 'DIPO':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DIPO.MAX_SUM_USD)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        //   this.status = true;
        // } else if (amount < (DIPO.MIN_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 30,000.00 dólares';
        //   this.status = true;
        // } else if (amount > (DIPO.MAX_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de $1,250.00 dólares';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, DIPO, true);
        break;

      case 'DIPOC':

        if (this.coverageCoin.tipoPlan !== 'VIDA INTELIGENTE' && this.coverageCoin.tipoPlan !== 'MI PROYECTO R') {
          // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DIPOC.MAX_SUM_USD)) {
          //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //   this.status = true;
          // } else if (amount < (DIPOC.MIN_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
          //   this.status = true;
          // } else if (amount > (DIPOC.MAX_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de $1,250,000 dólares';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          this.validateAmounts(amount, DIPOC, true);
        } else {
          // if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
          //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(DIPOCUniversal.MAX_SUM_USD)) {
          //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //     this.status = true;
          //   } else if (amount < (DIPOCUniversal.MIN_SUM_VI_USD || 0)) {
          //     this.msg = 'La Suma Asegurada miníma es de 35,000 dólares';
          //     this.status = true;
          //   } else if (amount > (DIPOCUniversal.MAX_SUM_USD || 0)) {
          //     this.msg = 'La Suma Asegurada máxima es de 1,176,471 dólares';
          //     this.status = true;
          //   } else {
          //     this.msg = '';
          //     this.status = false;
          //   }
          // }
          // if (this.coverageCoin.tipoPlan === 'MI PROYECTO R') {
          //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(DIPOCUniversal.MAX_SUM_USD)) {
          //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //     this.status = true;
          //   } else if (amount < (DIPOCUniversal.MIN_SUM_MR_USD || 0)) {
          //     this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
          //     this.status = true;
          //   } else if (amount > (DIPOCUniversal.MAX_SUM_USD || 0)) {
          //     this.msg = 'La Suma Asegurada máxima es de 1,176,471 dólares';
          //     this.status = true;
          //   } else {
          //     this.msg = '';
          //     this.status = false;
          //   }
          // }
          this.validateAmounts(amount, DIPOCUniversal, true);
        }
        break;

      case 'IAC':
        if (this.coverageCoin.tipoPlan !== 'VIDA INTELIGENTE' && this.coverageCoin.tipoPlan !== 'MI PROYECTO R') {
          // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(IAC.MAX_SUM_USD)) {
          //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          // } else if (amount < (IAC.MIN_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
          //   this.status = true;
          // } else if (amount > (IAC.MAX_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 125,000 dólares';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          this.validateAmounts(amount, IAC, true);
        } else {
          // if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
          //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(CDC.MAX_SUM_USD)) {
          //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //     this.status = true;
          //   } else if (amount < (CDC.MIN_SUM_VI_USD || 0)) {
          //     this.msg = 'La Suma Asegurada miníma es de 35,000 dólares';
          //     this.status = true;
          //   } else if (amount > (CDC.MAX_SUM_USD || 0)) {
          //     this.msg = 'La Suma Asegurada máxima es de 117,647 dólares';
          //     this.status = true;
          //   } else {
          //     this.msg = '';
          //     this.status = false;
          //   }
          // }
          // if (this.coverageCoin.tipoPlan === 'MI PROYECTO R') {
          //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(CDC.MAX_SUM_USD)) {
          //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //     this.status = true;
          //   } else if (amount < (CDC.MIN_SUM_MR_USD || 0)) {
          //     this.msg = 'La Suma Asegurada miníma es de 35,000 dólares';
          //     this.status = true;
          //   } else if (amount > (CDC.MAX_SUM_USD || 0)) {
          //     this.msg = 'La Suma Asegurada máxima es de 117,647 dólares';
          //     this.status = true;
          //   } else {
          //     this.msg = '';
          //     this.status = false;
          //   }
          // }
          this.validateAmounts(amount, CDC, true);
        }

        break;

      case 'PAE':

        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(PAE.MAX_SUM_USD)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        // } else if (amount < (PAE.MIN_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 30,000 dólares';
        //   this.status = true;
        // } else if (amount > (PAE.MAX_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 62,500 dólares';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, PAE, true);
        break;
      case 'VPL':
        // if (amount < (MU.MIN_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 35,000 dólares';
        //   this.status = true;
        // } else if (amount > (MU.MAX_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 58,823,530 dólares';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        sumMin = MU.MIN_SUM_USD;
        sumMax = MU.MAX_SUM_USD;
        sumas = { sumMin: sumMin, sumMax: sumMax };
        this.validacionCoberturas(amount, 0, false, sumas);
        break;
      case 'CDC':
        // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(CDC_MU.MAX_SUM_USD)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura de Temporal';
        //   this.status = true;
        // } else if (amount < (CDC_MU.MIN_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 0 dólares';
        //   this.status = true;
        // } else if (amount > (CDC_MU.MAX_SUM_USD || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 117,647 dólares';
        //   this.status = true;
        // } else {
        //   this.msg = '';
        //   this.status = false;
        // }
        this.validateAmounts(amount, CDC_MU, true);
        break;
      case 'BAU_VI':
        if (this.coverageCoin.tipoRiesgo.value !== 'Preferente') {
          // if (amount < Number(BAUniversal.MIN_SUM_USD_VI) || 0) {
          //   this.msg = 'La suma asegurada no puede ser menor a $35,000 dólares';
          //   this.status = true;
          // } else if (amount > (BAUniversal.MAX_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 dólares';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          sumMin = BAUniversal.MIN_SUM_USD_VI;
          sumMax = BAUniversal.MAX_SUM_USD;
          sumas = { sumMin: sumMin, sumMax: sumMax };
          this.validacionCoberturas(amount, 0, false, sumas);
        }
        if (this.coverageCoin.tipoRiesgo.value === 'Preferente') {
          // if (amount < Number(BAUniversal.MIN_SUM_RIES_PREF_USD) || 0) {
          //   this.msg = 'La suma asegurada no puede ser menor a $200,000 dólares';
          //   this.status = true;
          // } else if (amount > (BAUniversal.MAX_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 dólares';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          sumMin = BAUniversal.MIN_SUM_RIES_PREF_USD;
          sumMax = BAUniversal.MAX_SUM_USD;
          sumas = { sumMin: sumMin, sumMax: sumMax };
          this.validacionCoberturas(amount, 0, false, sumas);
        }
        break;
      case 'BAU_MR':
        if (this.coverageCoin.tipoRiesgo.value !== 'Preferente') {
          // if (amount < Number(BAUniversal.MIN_SUM_USD_MR) || 0) {
          //   this.msg = 'La suma asegurada no puede ser menor a $30,000 pesos';
          //   this.status = true;
          // } else if (amount > (BAUniversal.MAX_SUM_USD || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 dólares';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          sumMin = BAUniversal.MIN_SUM_USD_MR;
          sumMax = BAUniversal.MAX_SUM_USD;
          sumas = { sumMin: sumMin, sumMax: sumMax };
          this.validacionCoberturas(amount, 0, false, sumas);
        }
        if (this.coverageCoin.tipoRiesgo.value === 'Preferente') {
          // if (amount < Number(BAUniversal.MIN_SUM_RIES_PREF_MXN) || 0) {
          //   this.msg = 'La suma asegurada no puede ser menor a $200,000 dólares';
          //   this.status = true;
          // } else if (amount > (BAUniversal.MAX_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 dólares';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          sumMin = BAUniversal.MIN_SUM_RIES_PREF_USD;
          sumMax = BAUniversal.MAX_SUM_USD;
          sumas = { sumMin: sumMin, sumMax: sumMax };
          this.validacionCoberturas(amount, 0, false, sumas);
        }
        break;

    }
    const values: ValidationType = ({
      mesage: this.msg,
      status: this.status
    });

    return values;
  }

  /**
   * Si la moneda seleccionada es MXN, este método valida los montos de cada cobertura respecto a sus tarifas mínima y máxima.
   * @param {number} amount
   * @param {Coverage} key
   * @returns
   * @memberof ValidationTypeService
   */
  validateAmountMXN(amount: number, key: Coverage, plan?: PlanType) {
    if (plan === 'TEMPORAL') {
      switch (key) {
        case 'BASICA':
          this.validateAmounts(amount, PLAN_TEMP, false);
          break;
        case 'PAI':
          this.validateAmounts(amount, PLAN_TEMP_PAI, true);
          break;
        case 'CC':
          this.validateAmounts(amount, PLAN_TEMP_CC, true);
          break;
        case 'DI':
          this.validateAmounts(amount, PLAN_TEMP_DI, true);
          break;
        case 'DIPO':
          this.validateAmounts(amount, PLAN_TEMP_DIPO, true);
          break;
        case 'DIPOC':
          this.validateAmounts(amount, PLAN_TEMP_DIPOC, true);
          break;
        case 'IAC':
          this.validateAmounts(amount, PLAN_TEMP_IAC, true);
          break;
        case 'PAE':
          this.validateAmounts(amount, PLAN_TEMP_PAE, true);
          break;
      }
    } else {
      let sumMin;
      let sumMax;
      let sumas = { sumMin: sumMin, sumMax: sumMax };
      switch (key) {
        case 'BASICA':
          if (plan === 'PROTECCION EFECTIVA') {
            if (amount < (BAS.MIN_SUM_PRO_EF || 0)) {
              this.msg = 'La Suma Asegurada miníma es de 200,000 pesos';
              this.status = true;
            } else if (amount > (BAS.MAX_SUM_PRO_EF)) {
              this.msg = 'La Suma Asegurada máxima es de 1,200,000 pesos';
              this.status = true;
            } else {
              const multiple = 10e4;

              if ((amount % multiple) !== 0) {
                this.msg = 'La Suma Asegurada debe ser multiplo de 100,000';
                this.status = true;
              } else {
                this.msg = '';
                this.status = false;
              }
            }
          } else {
            // if (amount < (BAS.MIN_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
            //   this.status = true;
            // } else if (amount > (BAS.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            this.validateAmounts(amount, BAS, false);
          }
          break;

        case 'PAI':
          if (this.coverageCoin.tipoPlan !== 'MI PROYECTO R' && this.coverageCoin.tipoPlan !== 'VIDA INTELIGENTE') {
            // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(PAI.MAX_SUM_MXN)) {
            //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            // } else if (amount < (PAI.MIN_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
            //   this.status = true;
            // } else if (amount > (PAI.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 20,000,000 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            this.validateAmounts(amount, PAI, true);
          } else {
            // if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
            //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(PAIUniversal.MAX_SUM_MXN)) {
            //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //     this.status = true;
            //   } else if (amount < (PAIUniversal.MIN_SUM_VI_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada miníma es de 350,000 pesos';
            //     this.status = true;
            //   } else if (amount > (PAIUniversal.MAX_SUM_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada máxima es de 18,000,000 pesos';
            //     this.status = true;
            //   } else {
            //     this.msg = '';
            //     this.status = false;
            //   }
            // }
            // if (this.coverageCoin.tipoPlan === 'MI PROYECTO R') {
            //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(PAIUniversal.MAX_SUM_MXN)) {
            //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //     this.status = true;
            //   } else if (amount < (PAIUniversal.MIN_SUM_MR_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
            //     this.status = true;
            //   } else if (amount > (PAIUniversal.MAX_SUM_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada máxima es de 18,000,000 pesos';
            //     this.status = true;
            //   } else {
            //     this.msg = '';
            //     this.status = false;
            //   }
            // }
            this.validateAmounts(amount, PAIUniversal, true);
          }
          break;

        case 'CC':

          // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(CC.MAX_SUM_USD)) {
          //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //   this.status = true;
          // } else if (amount < (CC.MIN_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
          //   this.status = true;
          // } else if (amount > (CC.MAX_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 4,000,000 pesos';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          this.validateAmounts(amount, CC, true);
          break;

        case 'DI':
          if (this.coverageCoin.tipoPlan !== 'MI PROYECTO R' && this.coverageCoin.tipoPlan !== 'VIDA INTELIGENTE') {
            // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DI.MAX_SUM_MXN)) {
            //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //   this.status = true;
            // } else if (amount < (DI.MIN_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada miníma es de 30,000 pesos';
            //   this.status = true;
            // } else if (amount > (DI.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 20,000,000 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            this.validateAmounts(amount, DI, true);
          } else {
            // if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
            //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(DIUniversal.MAX_SUM_MXN)) {
            //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //     this.status = true;
            //   } else if (amount < (DIUniversal.MIN_SUM_VI_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada miníma es de 350,000 pesos';
            //     this.status = true;
            //   } else if (amount > (DIUniversal.MAX_SUM_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada máxima es de 18,000,000 pesos';
            //     this.status = true;
            //   } else {
            //     this.msg = '';
            //     this.status = false;
            //   }
            // }
            // if (this.coverageCoin.tipoPlan === 'MI PROYECTO R') {
            //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(DIUniversal.MAX_SUM_MXN)) {
            //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //     this.status = true;
            //   } else if (amount < (DIUniversal.MIN_SUM_MR_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada miníma es de $350,000 pesos';
            //     this.status = true;
            //   } else if (amount > (DIUniversal.MAX_SUM_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada máxima es de $18,000,000 pesos';
            //     this.status = true;
            //   } else {
            //     this.msg = '';
            //     this.status = false;
            //   }
            // }
            this.validateAmounts(amount, DIUniversal, true);
          }
          break;

        case 'DIPO':

          // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DIPO.MAX_SUM_MXN)) {
          //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          //   this.status = true;
          // } else if (amount < (DIPO.MIN_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
          //   this.status = true;
          // } else if (amount > (DIPO.MAX_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 20,000,000 pesos';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          this.validateAmounts(amount, DIPO, true);
          break;

        case 'DIPOC':

          if (this.coverageCoin.tipoPlan !== 'MI PROYECTO R' && this.coverageCoin.tipoPlan !== 'VIDA INTELIGENTE') {
            // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(DIPOC.MAX_SUM_MXN)) {
            //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //   this.status = true;
            // } else if (amount < (DIPOC.MIN_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada miníma es de 30,000 pesos';
            //   this.status = true;
            // } else if (amount > (DIPOC.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 20,000,000 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            this.validateAmounts(amount, DIPOC, true);
          } else {
            // if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
            //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(DIPOCUniversal.MAX_SUM_MXN)) {
            //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //     this.status = true;
            //   } else if (amount < (DIPOCUniversal.MIN_SUM_VI_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada miníma es de 350,000 pesos';
            //     this.status = true;
            //   } else if (amount > (DIPOCUniversal.MAX_SUM_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada máxima es de 18,000,000 pesos';
            //     this.status = true;
            //   } else {
            //     this.msg = '';
            //     this.status = false;
            //   }
            // }
            // if (this.coverageCoin.tipoPlan === 'MI PROYECTO R') {
            //   if (amount > this.baServices.sumaAseguradaUniversal.value && amount < Number(DIPOCUniversal.MAX_SUM_MXN)) {
            //     this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //     this.status = true;
            //   } else if (amount < (DIPOCUniversal.MIN_SUM_MR_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada miníma es de $350,000 pesos';
            //     this.status = true;
            //   } else if (amount > (DIPOCUniversal.MAX_SUM_MXN || 0)) {
            //     this.msg = 'La Suma Asegurada máxima es de $18,000,000 pesos';
            //     this.status = true;
            //   } else {
            //     this.msg = '';
            //     this.status = false;
            //   }
            // }
            this.validateAmounts(amount, DIPOCUniversal, true);
          }
          break;

        case 'IAC':
          if (this.coverageCoin.tipoPlan !== 'MI PROYECTO R' && this.coverageCoin.tipoPlan !== 'VIDA INTELIGENTE') {
            // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(IAC.MAX_SUM_MXN)) {
            //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            //   this.status = true;
            // } else if (amount < (IAC.MIN_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
            //   this.status = true;
            // } else if (amount > (IAC.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 2,000,000 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            this.validateAmounts(amount, IAC, true);
          } else {
            // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(CDC.MAX_SUM_MXN)) {
            //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
            // } else if (amount < (CDC.MIN_SUM_MR_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
            //   this.status = true;
            // } else if (amount > (CDC.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 2,000,000 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            this.validateAmounts(amount, CDC, true);
          }
          break;

        case 'PAE':

          // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(PAE.MAX_SUM_MXN)) {
          //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
          // } else if (amount < (PAE.MIN_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
          //   this.status = true;
          // } else if (amount > (PAE.MAX_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 1,000,000 pesos';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          this.validateAmounts(amount, PAE, true);
          break;
        case 'VPL':
          // if (amount < (MU.MIN_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 350,000 pesos';
          //   this.status = true;
          // } else if (amount > (MU.MAX_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 999,999,99 pesos';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          sumMin = MU.MIN_SUM_MXN;
          sumMax = MU.MAX_SUM_MXN;
          sumas = { sumMin: sumMin, sumMax: sumMax };
          this.validacionCoberturas(amount, 0, false, sumas);
          break;
        case 'CDC':
          // if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(CDC_MU.MAX_SUM_MXN)) {
          //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura de Temporal';
          //   this.status = true;
          // } else if (amount < (CDC_MU.MIN_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada miníma es de 0 pesos';
          //   this.status = true;
          // } else if (amount > (CDC_MU.MAX_SUM_MXN || 0)) {
          //   this.msg = 'La Suma Asegurada máxima es de 2,000,000 pesos';
          //   this.status = true;
          // } else {
          //   this.msg = '';
          //   this.status = false;
          // }
          this.validateAmounts(amount, CDC_MU, true);
          break;
        // case 'BAU_MR':
        //   if (this.coverageCoin.tipoRiesgo.value !== 'Preferente') {
        //     if (amount < Number(BAUniversal.MIN_SUM_MXN_MR) || 0) {
        //       this.msg = 'La suma asegurada no puede ser menor a $350,000 pesos';
        //       this.status = true;
        //     } else if (amount > (BAUniversal.MAX_SUM_MXN || 0)) {
        //       this.msg = 'La Suma Asegurada máxima es de 999,999,999 pesos';
        //       this.status = true;
        //     } else {
        //       this.msg = '';
        //       this.status = false;
        //     }
        //   }
        //   if (this.coverageCoin.tipoRiesgo.value === 'Preferente') {
        //     if (amount < Number(BAUniversal.MIN_SUM_RIES_PREF_MXN) || 0) {
        //       this.msg = 'La suma asegurada no puede ser menor a $2,000,000 pesos';
        //       this.status = true;
        //     } else if (amount > (BAUniversal.MAX_SUM_MXN || 0)) {
        //       this.msg = 'La Suma Asegurada máxima es de 999,999,999 pesos';
        //       this.status = true;
        //     } else {
        //       this.msg = '';
        //       this.status = false;
        //     }
        //   }
        //   break;
        case 'BAU_VI':
          if (this.coverageCoin.tipoRiesgo.value !== 'Preferente') {
            // if (amount < Number(BAUniversal.MIN_SUM_MXN_VI) || 0) {
            //   this.msg = 'La suma asegurada no puede ser menor a $350,000 pesos';
            //   this.status = true;
            // } else if (amount > (BAUniversal.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            sumMin = BAUniversal.MIN_SUM_MXN_VI;
            sumMax = BAUniversal.MAX_SUM_MXN;
            sumas = { sumMin: sumMin, sumMax: sumMax };
            this.validacionCoberturas(amount, 0, false, sumas);
          }
          if (this.coverageCoin.tipoRiesgo.value === 'Preferente') {
            // if (amount < Number(BAUniversal.MIN_SUM_RIES_PREF_MXN) || 0) {
            //   this.msg = 'La suma asegurada no puede ser menor a $2,000,000 pesos';
            //   this.status = true;
            // } else if (amount > (BAUniversal.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            sumMin = BAUniversal.MIN_SUM_RIES_PREF_MXN;
            sumMax = BAUniversal.MAX_SUM_MXN;
            sumas = { sumMin: sumMin, sumMax: sumMax };
            this.validacionCoberturas(amount, 0, false, sumas);
          }
          break;
        case 'BAU_MR':
          if (this.coverageCoin.tipoRiesgo.value !== 'Preferente') {
            // if (amount < Number(BAUniversal.MIN_SUM_MXN_MR) || 0) {
            //   this.msg = 'La suma asegurada no puede ser menor a $300,000 pesos';
            //   this.status = true;
            // } else if (amount > (BAUniversal.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            sumMin = BAUniversal.MIN_SUM_MXN_MR;
            sumMax = BAUniversal.MAX_SUM_MXN;
            sumas = { sumMin: sumMin, sumMax: sumMax };
            this.validacionCoberturas(amount, 0, false, sumas);
          }
          if (this.coverageCoin.tipoRiesgo.value === 'Preferente') {
            // if (amount < Number(BAUniversal.MIN_SUM_RIES_PREF_MXN) || 0) {
            //   this.msg = 'La suma asegurada no puede ser menor a $2,000,000 pesos';
            //   this.status = true;
            // } else if (amount > (BAUniversal.MAX_SUM_MXN || 0)) {
            //   this.msg = 'La Suma Asegurada máxima es de 999,999,999 pesos';
            //   this.status = true;
            // } else {
            //   this.msg = '';
            //   this.status = false;
            // }
            sumMin = BAUniversal.MIN_SUM_RIES_PREF_MXN;
            sumMax = BAUniversal.MAX_SUM_MXN;
            sumas = { sumMin: sumMin, sumMax: sumMax };
            this.validacionCoberturas(amount, 0, false, sumas);
          }
          break;
        // case 'PAI':

        //   if (amount > this.baServices.sumaAsegurada_UDI.value && amount < Number(PAI.MAX_SUM_MXN)) {
        //   this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
        //   } else if (amount < (PAI.MIN_SUM_MXN || 0)) {
        //   this.msg = 'La Suma Asegurada miníma es de 300,000 pesos';
        //   this.status = true;
        //    } else if (amount > (PAI.MAX_SUM_MXN || 0)) {
        //   this.msg = 'La Suma Asegurada máxima es de 20,000,000 pesos';
        //   this.status = true;
        //    } else {
        //   this.msg = '';
        //   this.status = false;
        //   }
        //   break;

      }
    }

    const values: ValidationType = ({
      mesage: this.msg,
      status: this.status
    });

    return values;
  }

  private validateAmounts(amount: number, coverage: CoverageConstants, cobAdc: boolean, plan?: PlanType) {
    let sumas;
    let sumaAsegBasica = this.baServices.sumaAsegurada_UDI.value;
    if (this.coverageCoin.plan === 'Universales') {
      sumaAsegBasica = this.baServices.sumaAseguradaUniversal.value;
      sumas = this.obtenerSumMinMaxUniv(coverage);
    }else {
      sumas = this.obtenerSumMinMaxProTGT(coverage, plan);
    }
    this.validacionCoberturas(amount, sumaAsegBasica, cobAdc, sumas);
  }

  private obtenerSumMinMaxUniv(coverage: CoverageConstants) {
    let sumMin = 0;
    let sumMax = 0;
    if (this.coverageCoin.moneda === 'MXN') {
      if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
        sumMin = coverage.MIN_SUM_VI_MXN;
        sumMax = coverage.MAX_SUM_MXN;
      } else {
        sumMin = coverage.MIN_SUM_MR_MXN;
        sumMax = coverage.MAX_SUM_MXN;
      }
    } else {
      if (this.coverageCoin.tipoPlan === 'VIDA INTELIGENTE') {
        sumMin = coverage.MIN_SUM_VI_USD;
        sumMax = coverage.MAX_SUM_USD;
      } else {
        sumMin = coverage.MIN_SUM_MR_USD;
        sumMax = coverage.MAX_SUM_USD;
      }
    }
    return { sumMin: sumMin, sumMax: sumMax };
  }

  private obtenerSumMinMaxProTGT(coverage: CoverageConstants, plan?: PlanType) {
    let sumMin = 0;
    let sumMax = 0;
    if( plan !== 'ALIADOSKIDS'){
      if (this.coverageCoin.moneda === 'MXN') {
        sumMin = coverage.MIN_SUM_MXN;
        sumMax = coverage.MAX_SUM_MXN;
      } else if (this.coverageCoin.moneda === 'USD') {
        sumMin = coverage.MIN_SUM_USD;
        sumMax = coverage.MAX_SUM_USD;
      } else if (this.coverageCoin.moneda === 'UDI') {
        sumMin = coverage.MIN_SUM_UDI;
        sumMax = coverage.MAX_SUM_UDI;
      }
    }else{
      sumMin = coverage.MIN_SUM_KIDS;
      sumMax = coverage.MAX_SUM_KIDS;
    }
    
    return { sumMin: sumMin, sumMax: sumMax };
  }

  private validacionCoberturas(amount: number, sumaAsegBasica, cobAdc: boolean, sumas: { sumMin: number, sumMax: number }) {
    const moneda = this.coverageCoin.moneda !== 'UDI' ?
      this.coverageCoin.moneda === 'MXN' ? 'pesos' : 'dolares' : 'udi';
    const signo = this.coverageCoin.moneda === 'UDI' ? '' : '$';
    const tipo = this.coverageCoin.moneda === 'UDI' ? '' : this.coverageCoin.moneda;
    // if (amount > sumaAsegBasica && amount < Number(sumMax) && cobAdc) {
    if (amount > sumaAsegBasica && cobAdc) {
      this.msg = 'La suma asegurada no puede ser mayor a la Cobertura Básica';
      this.status = true;
    } else if (amount < (Number(sumas.sumMin) || 0)) {
      this.msg = 'La Suma Asegurada miníma es de ' + formatCurrency(sumas.sumMin, this.locale, signo, tipo) + ' ' + moneda;
      this.status = true;
    } else if (amount > (Number(sumas.sumMax) || 0)) {
      this.msg = 'La Suma Asegurada máxima es de ' + formatCurrency(sumas.sumMax, this.locale, signo, tipo) + ' ' + moneda;
      this.status = true;
    } else {
      this.msg = '';
      this.status = false;
    }
  }
}
