/**
 * Inflaciones de inpc
 */
export const ANUAL_INFLATIONS_INPC: { title: string, value: number }[] = [
    { title: '3.0%', value: .03 },
    { title: '3.5%', value: .035 },
    { title: '4%', value: .04 },
    { title: '4.5%', value: .045 },
    { title: '5%', value: .05 },
    { title: '5.5%', value: .055 },
    { title: '6%', value: .06 },
  ];
  /**
   * Inflación anual por defecto.
   * @const
   * @default ".04"
   */
  export const DEFAULT_ANUAL_INFLATION_INPC = .04;
