import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValoresTemplateComponent } from '@components/valores-template/valores-template.component';
import { SharedModule } from '@shared/shared.module';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    PipesModule
  ],
  declarations: [ValoresTemplateComponent]
})
export class ValoresTemplateModule { }
