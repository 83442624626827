/**
 * Utilidad que retorna la cadena ingresada solo permitiendo letras y números
 * @export
 * @class Utils
 */
export class Utils {
  /**
   * Utilidad que retorna la cadena ingresada solo permitiendo letras y números.
   * @param string
   * @returns string
   * @memberof Utils
   */
  public onlyWords(string: string): string {
    /**
     * Expresión regular.
     * @const
     */
    const regex = new RegExp('[^a-zA-ZáéíóúÁÉÍÓÚñÑ\\d\\s]', 'g');
    return string.replace(regex, '').trim();
  }
}
