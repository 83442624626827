/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { BI, ECMIUniversal } from '@constants/coverages';
/** Models */
import { Prospectu } from '@models/prospectu/prospectu.model';
import { CoverageStatus } from '@models/coverage-status/coverage-status';
import { Plazo } from '../../../models';
/** Services */
import { BAService } from '@services/coverages/BA/ba.service';
import { FactoresService } from '@services/factores/factores.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/distinctUntilChanged';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
import { VPLService } from '../VPL/vpl.service';

  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
@Injectable({
  providedIn: 'root'
})
export class BIService {
  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default "EXCLUIDO"
   */
  sumaAsegurada_UDI = new BehaviorSubject<CoverageStatus>('EXCLUIDO');
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default "EXCLUIDO"
   */
  sumaAsegurada_MXN = new BehaviorSubject<CoverageStatus>('EXCLUIDO');
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();

  sumaAsegurada_ECMI_MX = new BehaviorSubject<CoverageStatus>('EXCLUIDO');
  sumaAsegurada_ECMI_MX$ = this.sumaAsegurada_ECMI_MX.asObservable();
  sumaAsegurada_ECMI_USD = new BehaviorSubject<CoverageStatus>('EXCLUIDO');
  sumaAsegurada_ECMI_USD$ = this.sumaAsegurada_ECMI_MX.asObservable();

  /**
   * Constructor
   * @constructor
   * @param recursosExternos Crea nstancia de RecursosExternosService
   * @param factores Crea instancia de FactoresService
   * @param BA Crea instancia de BAService
   * @param prospectoService Crea instancia de ProspectoService
   */
  constructor(
    private recursosExternos: RecursosExternosService,
    private factores: FactoresService,
    private BA: BAService,
    private prospectoService: ProspectoService,
    private opciones: OpcionesPlanService,
    private VPL: VPLService
  ) {
    this.BA.primaAnual_UDI$.distinctUntilChanged().subscribe( () => {
      this.establecePrimaAnual(
        this.sumaAsegurada_UDI.value,
        this.prospectoService.prospecto.value);
    });
  }
  /**
   * Establece la prima anual en la moneda principal
   * @param {CoverageStatus} status
   * @param {Prospectu} prospecto
   */
  establecePrimaAnual(status: CoverageStatus, prospecto: Prospectu): void {
    this.sumaAsegurada_UDI.next(status);
    this.sumaAsegurada_MXN.next(status);

    let primaBasica = 0;
    let primaBasica_Sugerida1 = 0;
    let primaBasica_Sugerida2 = 0;

    if (
      this.sumaAsegurada_UDI.value === 'INCLUIDO' &&
      this.sumaAsegurada_MXN.value === 'INCLUIDO'
    ) {

      if (
        prospecto.age >= BI.ACCEPTANCE_AGE.MIN &&
        prospecto.age <= BI.ACCEPTANCE_AGE.MAX
      ) {
        primaBasica = this.BA.primaAnual_UDI.value;
        primaBasica_Sugerida1 = this.BA.primaAnual_UDI_Sugerida1.value;
        primaBasica_Sugerida2 = this.BA.primaAnual_UDI_Sugerida2.value;
      }

      if (this.opciones.tipoPlan === 'MUJER UNICA') {
        primaBasica += this.VPL.primaAnual_PrimeraMoneda.value;
      }

      const primaBI_UDI =
        this.recursosExternos.UDIToFirst(this.calculoPrimaBI(this.recursosExternos.FirstToUDI(primaBasica), 'Elegido'));
      const primaBI_MXN =
        this.recursosExternos.FirstToSecond(primaBI_UDI);

      const primaBI_UDI_Sugerida1 =
        this.recursosExternos.UDIToFirst(this.calculoPrimaBI(this.recursosExternos.FirstToUDI(primaBasica_Sugerida1), 'Sugerido1'));
      const primaBI_MXN_Sugerida1 =
        this.recursosExternos.FirstToSecond(primaBI_UDI_Sugerida1);

      const primaBI_UDI_Sugerida2 =
        this.recursosExternos.UDIToFirst(this.calculoPrimaBI(this.recursosExternos.FirstToUDI(primaBasica_Sugerida2), 'Sugerido2'));
      const primaBI_MXN_Sugerida2 =
        this.recursosExternos.FirstToSecond(primaBI_UDI_Sugerida2);

      this.primaAnual_UDI.next(primaBI_UDI);
      this.primaAnual_MXN.next(primaBI_MXN);

      this.primaAnual_UDI_Sugerida1.next(primaBI_UDI_Sugerida1);
      this.primaAnual_MXN_Sugerida1.next(primaBI_MXN_Sugerida1);

      this.primaAnual_UDI_Sugerida2.next(primaBI_UDI_Sugerida2);
      this.primaAnual_MXN_Sugerida2.next(primaBI_MXN_Sugerida2);

    } else {
      this.resetValues();
    }
  }

  /**
   * Realiza el calculo de la tarifa
   *
   * @param {number} calculoPrimaBI
   * @returns {number} Prima BI
   * @memberof BAService
   */
  calculoPrimaBI(primaBasicaFallecimiento: number, plazo: Plazo, isINPC: boolean = false, edadInpc?: number): number {

    if ( primaBasicaFallecimiento !== 0 ) {
      // Variables necesarias para el calculo
      const factorBI = this.factores.obtenerFactorCobertura('BI', plazo, isINPC, edadInpc);

      // Ejecuta la formula dada
      const primaBI = factorBI !== 0 ?  (
        primaBasicaFallecimiento * factorBI / 1000
      ) : 0;

      // Retorna el resultado de la operación
      return primaBI;
    }
    return 0;
  }

  resetValues(): void {
    this.primaAnual_UDI.next(0);
    this.primaAnual_MXN.next(0);
    this.primaAnual_UDI_Sugerida1.next(0);
    this.primaAnual_MXN_Sugerida1.next(0);
    this.primaAnual_UDI_Sugerida2.next(0);
    this.primaAnual_MXN_Sugerida2.next(0);
  }

  // métodos para universales

  establecePrimaAnualUniversal(status: CoverageStatus, prospecto: Prospectu): void {
    this.sumaAsegurada_ECMI_MX.next(status);
    this.sumaAsegurada_ECMI_USD.next(status);

    // let primaBasica = 0;

     if (
       this.sumaAsegurada_ECMI_MX.value === 'INCLUIDO' &&
      this.sumaAsegurada_ECMI_USD.value === 'INCLUIDO'
    ) {

      if (
        prospecto.age >= ECMIUniversal.ACCEPTANCE_AGE.MIN &&
        prospecto.age <= ECMIUniversal.ACCEPTANCE_AGE.MAX
      ) {
        // primaBasica = this.BA.sumaAseguradaUniversal.value;
      }

    //   const primaBI_UDI =
    //     this.recursosExternos.UDIToFirst(this.calculoPrimaBI(this.recursosExternos.FirstToUDI(primaBasica), 'Elegido'));
    //   const primaBI_MXN =
    //     this.recursosExternos.FirstToSecond(primaBI_UDI);

    //   const primaBI_UDI_Sugerida1 =
    //     this.recursosExternos.UDIToFirst(this.calculoPrimaBI(this.recursosExternos.FirstToUDI(primaBasica_Sugerida1), 'Sugerido1'));
    //   const primaBI_MXN_Sugerida1 =
    //     this.recursosExternos.FirstToSecond(primaBI_UDI_Sugerida1);

    //   const primaBI_UDI_Sugerida2 =
    //     this.recursosExternos.UDIToFirst(this.calculoPrimaBI(this.recursosExternos.FirstToUDI(primaBasica_Sugerida2), 'Sugerido2'));
    //   const primaBI_MXN_Sugerida2 =
    //     this.recursosExternos.FirstToSecond(primaBI_UDI_Sugerida2);

    //   this.primaAnual_UDI.next(primaBI_UDI);
    //   this.primaAnual_MXN.next(primaBI_MXN);

    //   this.primaAnual_UDI_Sugerida1.next(primaBI_UDI_Sugerida1);
    //   this.primaAnual_MXN_Sugerida1.next(primaBI_MXN_Sugerida1);

    //   this.primaAnual_UDI_Sugerida2.next(primaBI_UDI_Sugerida2);
    //   this.primaAnual_MXN_Sugerida2.next(primaBI_MXN_Sugerida2);


    } else {
      this.resetValues();
    }
  }

}
