/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { IAC, CDC } from '@constants/coverages';
/** Models */
import { Plazo } from '@models/plazo/plazo.interface';
/** Services */
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { BAService } from '@services/coverages/BA/ba.service';
import { FactoresService } from '@services/factores/factores.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
/** Rxjs */
import { BehaviorSubject, Subscription } from 'rxjs';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';

/**
 * Servicio para la cobertura IAC
 */
@Injectable({
  providedIn: 'root'
})
export class IACService {
  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_UDI = new BehaviorSubject<number>(0);
  sumaAsegurada_MXN_U = new BehaviorSubject<number>(0);
  sumaAsegurada_USD_U = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);
  // Prima anual sugerida 2
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  sumaAseguradaMasiva_1 = new BehaviorSubject<number>(0);
  sumaAseguradaMasiva_2 = new BehaviorSubject<number>(0);
  sumaAseguradaMasiva_3 = new BehaviorSubject<number>(0);

  primaAnualMasiva_1 = new BehaviorSubject<number>(0);
  primaAnualMasiva_2 = new BehaviorSubject<number>(0);
  primaAnualMasiva_3 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  sumaAsegurada_MXN_U$ = this.sumaAsegurada_MXN_U.asObservable();
  sumaAsegurada_USD_U$ = this.sumaAsegurada_USD_U.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();
  /**
   * Selección por defecto
   * @property
   * @default true
   */
  DEFAULT = true;

  /**
   * Crea una suscripción almacenando la suma asegurada de la cobertura Básica
   * @property {Subscription}
   */
  sumaAsegurada_BA: Subscription;

  prima_DiezMasPae = 0;

  /**
   * Constructor
   * @constructor
   * @param recursosExternos Crea instancia de RecursosExternosService
   * @param factores Crea instancia de FactoresService
   * @param BaService Crea instancia de BAService
   * @param prospectoService Crea instancia de ProspectoService
   */
  constructor(
    private recursosExternos: RecursosExternosService,
    private factores: FactoresService,
    private BaService: BAService,
    private prospectoService: ProspectoService,
    private opciones: OpcionesPlanService
  ) { }
  /**
   * Establece la prima anual en la cobertura dado el valor
   * @param sumaAseguradaUDI
   */
  establecePrimaAnual(sumaAseguradaUDI) {
    this.sumaAsegurada_BA = this.BaService.sumaAsegurada_UDI.distinctUntilChanged()
      .subscribe(() => {
        this.obtenerPrima(sumaAseguradaUDI);
      });
  }
  /**
   * Obtiene la prima apartir del valor asignado
   * @param {number} sumaAsegurada
   */
  obtenerPrima(sumaAsegurada: number): void {
    // Variable de suma asegurada que contendra el valor final de la suma asegurada despues de ser evaluada.
    let suma = 0;
    /**
     * Si la suma asegurada inicial es igual a 0, tomará por defecto el valor de la suma asegurada basica
     * de lo contrario continuará evaluando la suma asegurada introducida.
    */
    this.DEFAULT = sumaAsegurada === 0;

    // Se obtienen la informacion del prospecto actual
    const prospecto = this.prospectoService.prospecto.value;

    // Evalua si la edad del prospecto esta dentro del rango de aceptación de la cobertura
    if (
      prospecto.age >= IAC.ACCEPTANCE_AGE.MIN &&
      prospecto.age <= IAC.ACCEPTANCE_AGE.MAX
    ) {

      if (sumaAsegurada !== -1) {
        if (this.DEFAULT) {
          suma = this.BaService.sumaAsegurada_UDI.value;
          this.DEFAULT = false;
        } else {
          suma = sumaAsegurada || 0;
        }

        if (
          suma > this.BaService.sumaAsegurada_UDI.value ||
          suma < this.recursosExternos.GetMinMax('BA', 'MIN')
        ) {
          suma = this.BaService.sumaAsegurada_UDI.value;
        }

        if (this.opciones.tipoPlan === 'TEMPORAL' && this.opciones.moneda === 'MXN') {
          suma = this.recursosExternos.obtenerSumaAsegurada('PlanTmpIAC', suma);
        } else {
          suma = this.recursosExternos.obtenerSumaAsegurada('IAC', suma);
        }

        // if (suma <= this.recursosExternos.GetMinMax('IAC', 'MIN')) {
        //   suma = this.recursosExternos.GetMinMax('IAC', 'MIN');
        // }

        // if (suma >= this.recursosExternos.GetMinMax('IAC', 'MAX')) {
        //   suma = this.recursosExternos.GetMinMax('IAC', 'MAX');
        // }

        const primaIAC_UDI = this.calculoPrimaIAC(this.recursosExternos.FirstToUDI(suma), 'Elegido');
        const primaIAC_MXN = this.recursosExternos.UDIToSecond(primaIAC_UDI);

        const primaIAC_UDI_Sugerida1 = this.calculoPrimaIAC(this.recursosExternos.FirstToUDI(suma), 'Sugerido1');
        const primaIAC_MXN_Sugerida1 = this.recursosExternos.UDIToSecond(primaIAC_UDI_Sugerida1);

        const primaIAC_UDI_Sugerida2 = this.calculoPrimaIAC(this.recursosExternos.FirstToUDI(suma), 'Sugerido2');
        const primaIAC_MXN_Sugerida2 = this.recursosExternos.UDIToSecond(primaIAC_UDI_Sugerida2);

        const sumaAsegurada_UDI = suma;
        const sumaAsegurada_MXN = this.recursosExternos.FirstToSecond(sumaAsegurada_UDI);

        this.primaAnual_UDI_Sugerida1.next(this.recursosExternos.UDIToFirst(primaIAC_UDI_Sugerida1));
        this.primaAnual_MXN_Sugerida1.next(primaIAC_MXN_Sugerida1);

        this.primaAnual_UDI_Sugerida2.next(this.recursosExternos.UDIToFirst(primaIAC_UDI_Sugerida2));
        this.primaAnual_MXN_Sugerida2.next(primaIAC_MXN_Sugerida2);

        this.primaAnual_UDI.next(this.recursosExternos.UDIToFirst(primaIAC_UDI));
        this.primaAnual_MXN.next(primaIAC_MXN);

        this.sumaAsegurada_UDI.next(sumaAsegurada_UDI);
        this.sumaAsegurada_MXN.next(sumaAsegurada_MXN);

        return;

      }

    }
    this.resetValues();
    return;
  }

  obtenerPrimaMasiva(sumaAsegurada: number, numeroSuma: number): void {
    // Variable de suma asegurada que contendra el valor final de la suma asegurada despues de ser evaluada.
    let suma = 0;
    /**
     * Si la suma asegurada inicial es igual a 0, tomará por defecto el valor de la suma asegurada basica
     * de lo contrario continuará evaluando la suma asegurada introducida.
    */
    this.DEFAULT = sumaAsegurada === 0;

    // Se obtienen la informacion del prospecto actual
    const prospecto = this.prospectoService.prospecto.value;

    // Evalua si la edad del prospecto esta dentro del rango de aceptación de la cobertura
    if (
      prospecto.age >= IAC.ACCEPTANCE_AGE.MIN &&
      prospecto.age <= IAC.ACCEPTANCE_AGE.MAX
    ) {

      if (sumaAsegurada !== -1) {
        if (this.DEFAULT) {
          suma = this.BaService.sumaAsegurada_UDI.value;
          this.DEFAULT = false;
        } else {
          suma = sumaAsegurada || 0;
        }

        if (
          numeroSuma === 1 && sumaAsegurada !== 300000
        ) {
          suma = 300000;
        }

        if (numeroSuma === 2 && sumaAsegurada !== 400000) {
          suma = 400000;
        }

        if (numeroSuma === 3 && sumaAsegurada !== 500000) {
          suma = 500000;
        }

        const primaIAC_UDI = this.calculoPrimaIAC(this.recursosExternos.FirstToUDI(suma), 'Elegido');

        const sumaAsegurada_UDI = suma;

        switch (numeroSuma) {
          case 1:
            this.sumaAseguradaMasiva_1.next(sumaAsegurada_UDI);
            this.primaAnualMasiva_1.next(this.recursosExternos.UDIToFirst(primaIAC_UDI));
            break;
          case 2:
            this.sumaAseguradaMasiva_2.next(sumaAsegurada_UDI);
            this.primaAnualMasiva_2.next(this.recursosExternos.UDIToFirst(primaIAC_UDI));
            break;
          case 3:
            this.sumaAseguradaMasiva_3.next(sumaAsegurada_UDI);
            this.primaAnualMasiva_3.next(this.recursosExternos.UDIToFirst(primaIAC_UDI));
            break;
        }

        return;

      }

    }
    this.resetValues();
    return;
  }

  /**
   * Realiza el calculo de la tarifa
   *
   * @param {number} calculoPrimaIAC
   * @returns {number} Prima IAC
   * @memberof PAIService
   */
  calculoPrimaIAC(sumaAsegurada: number, plazo: Plazo, factor?: number, isINPC: boolean = false, edadInpc?: number): number {
    let factorIAC;

    try {
      factorIAC = factor ? factor : this.factores.obtenerFactorCobertura('IAC', plazo, isINPC, edadInpc);
    } catch (error) {
      factorIAC = this.factores.getEspecificFactors('IAC');
    }

    // Ejecuta la formula dada
    const primaIAC = this.obtenerPrimaIac(sumaAsegurada, factorIAC);
    // Retorna el resultado de la operación
    return primaIAC;
  }

  calcularPrimaDiezMas(edad: number) {
    let primaIAC = 0;
    if (this.opciones.coberturaEnVigencia(this.primaAnual_UDI.value, IAC, edad) > 0) {
      const factorIac = this.factores.obtenerFactoresDiezMas('IAC', edad);
      primaIAC = this.obtenerPrimaIac(this.sumaAsegurada_UDI.value, factorIac);
      primaIAC = primaIAC - this.primaAnual_UDI.value;
    }
    this.prima_DiezMasPae = primaIAC;
  }

  obtenerPrimaIac(sumaAsegurada: number, factorPAI: number){
    const primaPAI = factorPAI !== 0 ? (
      sumaAsegurada * factorPAI / 1000
    ) : 0;
    // Retorna el resultado de la operación
    return primaPAI;
  }

  resetValues() {
    this.primaAnual_UDI.next(0);
    this.primaAnual_MXN.next(0);
    this.primaAnual_UDI_Sugerida1.next(0);
    this.primaAnual_MXN_Sugerida1.next(0);
    this.primaAnual_UDI_Sugerida2.next(0);
    this.primaAnual_MXN_Sugerida2.next(0);
    this.sumaAsegurada_UDI.next(0);
    this.sumaAsegurada_MXN.next(0);
    this.sumaAseguradaMasiva_1.next(0);
    this.sumaAseguradaMasiva_2.next(0);
    this.sumaAseguradaMasiva_3.next(0);
    this.primaAnualMasiva_3.next(0);
    this.primaAnualMasiva_1.next(0);
    this.primaAnualMasiva_2.next(0);
    this.DEFAULT = true;
    this.sumaAsegurada_MXN_U.next(0);
    this.sumaAsegurada_USD_U.next(0);
  }

  // Métodos pra universales
  establecePrimaAnualU(sumaAseguradaUDI) {
    this.sumaAsegurada_BA = this.BaService.sumaAseguradaUniversal.distinctUntilChanged()
      .subscribe(() => {
        this.obtenerPrimaU(sumaAseguradaUDI);
      });
  }
  obtenerPrimaU(sumaAsegurada: number): void {
    // Variable de suma asegurada que contendra el valor final de la suma asegurada despues de ser evaluada.
    let suma = 0;
    /**
     * Si la suma asegurada inicial es igual a 0, tomará por defecto el valor de la suma asegurada basica
     * de lo contrario continuará evaluando la suma asegurada introducida.
    */
    this.DEFAULT = sumaAsegurada === 0;

    // Se obtienen la informacion del prospecto actual
    const prospecto = this.prospectoService.prospecto.value;

    // Evalua si la edad del prospecto esta dentro del rango de aceptación de la cobertura
    if (
      prospecto.age >= CDC.ACCEPTANCE_AGE.MIN &&
      prospecto.age <= CDC.ACCEPTANCE_AGE.MAX
    ) {

      if (sumaAsegurada !== -1) {
        if (this.DEFAULT) {
          suma = this.BaService.sumaAseguradaUniversal.value;
          this.DEFAULT = false;
        } else {
          suma = sumaAsegurada || 0;
        }
        if (
          suma > this.BaService.sumaAseguradaUniversal.value
        ) {
          suma = this.BaService.sumaAseguradaUniversal.value;
        }

        if (suma <= this.recursosExternos.GetMinMaxU('CDC', 'MIN', this.opciones.moneda, this.opciones.tipoPlan)) {
          suma = this.recursosExternos.GetMinMaxU('CDC', 'MIN', this.opciones.moneda, this.opciones.tipoPlan);
        }

        if (suma >= this.recursosExternos.GetMinMaxU('CDC', 'MAX', this.opciones.moneda, this.opciones.tipoPlan)) {
          suma = this.recursosExternos.GetMinMaxU('CDC', 'MAX', this.opciones.moneda, this.opciones.tipoPlan);
        }

        const sumaAsegurada_MXN = suma;
        const sumaAsegurada_USD = this.recursosExternos.MXNtoUSDU(sumaAsegurada_MXN);

        this.sumaAsegurada_MXN_U.next(sumaAsegurada_MXN);
        this.sumaAsegurada_USD_U.next(sumaAsegurada_USD);

        return;

      }

    }
    this.resetValues();
    return;
  }

}
