/** Angular Core */
import { Injectable } from '@angular/core';

// Módelos
import { User } from '../../models';

// Utilidades
import { BehaviorSubject, Observable } from 'rxjs';
import { UserType } from '@app/constants';
import * as uuid from 'uuid/v4';
import { HttpClient } from '@angular/common/http';

/**
 * Servicio dedicado a la autenticación de usuarios
 * Abstrae las características necesarias para la autenticación
 * @export
 * @class AuthenticationService
 */
@Injectable()
export class AuthenticationService {
  proxy = 'https://cors-anywhere.herokuapp.com/';
  /**
   * Datos del usuario reactivas
   * @property
   * @type {BehaviourSubject<User>}
   */
  user = new BehaviorSubject<User>(this.getActualUser());
  token = new BehaviorSubject<any>(null);
  detallesAgente = new BehaviorSubject<any>(null);
  estructura = new BehaviorSubject<any>(null);
  subordinados = new BehaviorSubject<any>(null);
  roles = new BehaviorSubject<any>(null);
  preventFailReload = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {

    // *QA
    // this.getToken('MXS00101544A', 'Servicio1900.')
    // *dev
    // this.getToken('MXS00101541A', 'Axacfdi3.3*')
    // .subscribe(token => {
        // this.obtenerDetallesAgente('MXS00101544A', `${token.token_type} ${token.access_token}`, 'CPR00062623').subscribe(console.log);
        // this.obtenerEstructuraOrganizacional('MXS00101544A', `${token.token_type} ${token.access_token}`, 'CPR00062623').subscribe(console.log);
        // this.obtenerSubordinados('MXS00101544A', token, 'CPR00062623').subscribe(console.log);
        // this.validarRol('MXS00101544A', token, '00062623', ['PRM', 'SCP', 'GTE', 'AGT', 'ASE']).subscribe(console.log);
      // });
  }

  /**
   * Obtiene el usuario activo
   * @method
   */
  private getActualUser(): User {
    const cookies = document.cookie;
    const name = this.parseCookie(cookies, 'agenteNombre');
    const code = this.parseCookie(cookies, 'agenteID');
    const rawRoles = this.parseCookie(cookies, 'agenteRoles');
    const type = this.userTypeParse(rawRoles, code);
    const roles = this.rolUserParse(rawRoles);
    const email = this.parseCookie(cookies, 'agenteEmail');

    // Checamos en LocalStorage si ya existe el objeto
    // Ya existe existe
    if (localStorage.getItem('user') !== null) {
      const userLoaded = JSON.parse(localStorage.getItem('user'));

      // Checamos que tengan lo mismo o sea vacío -> Enviamos el usuario actual en LocalStorage
      if (
        (userLoaded.name === name &&
          userLoaded.code === code &&
          userLoaded.rawRoles === rawRoles &&
          userLoaded.type === type &&
          userLoaded.roles === roles &&
          userLoaded.email === email) ||
        (name === '' && code === '' && rawRoles === '' && email === '')
      ) {
        // tienen lo mismo -> retornamos usuario del LocalStorage
        return new User(
          userLoaded.name,
          userLoaded.code,
          userLoaded.email,
          userLoaded.roles,
          userLoaded.rawRoles,
          userLoaded.type
        );
      } else {
        // Entonces el usuario es distinto -> Creamos uno nuevo
        const newUser = {
          name,
          code,
          email,
          roles,
          rawRoles,
          type
        };

        localStorage.setItem('user', JSON.stringify(newUser));

        return new User(
          newUser.name,
          newUser.code,
          newUser.email,
          newUser.roles,
          newUser.rawRoles,
          newUser.type
        );
      }
    }

    // No existe
    // Creamos el usuario y lo guardamos -> retornamos el mismo
    const user = {
      name,
      code,
      email,
      roles,
      rawRoles,
      type
    };
    localStorage.setItem('user', JSON.stringify(user));

    return new User(
      user.name,
      user.code,
      user.email,
      user.roles,
      user.rawRoles,
      user.type
    );
  }

  /**
   * Parsea las cookies
   * @method
   * @param cookies
   * @param code
   */
  private parseCookie(cookies: string, code: string): string {
    const cookieRegex = new RegExp(`(?:^|;)\\s?${code}=(.*?)(?:;|$)`, 'i');
    const match = cookies.match(cookieRegex);

    return (match && unescape(match[1])) || '';
  }

  /**
   * Parsea string de cookie a UserType
   * @method
   * @param userType
   * @return {UserType}
   */
  userTypeParse(roles: string, code: string): UserType {
    /*
     * Agente = MXApp_FV_AGENTE
     * Promotor = MXApp_FV_PROMOTOR
     * Funcionario Comercial = MXApp_FV_FUNCIONARIOVENTAS
     */
    const agente = /(mxapp_fv_agente)/gi;
    const promotor = /(mxapp_fv_promotor)/gi;
    const funcionario = /(mxapp_fv_funcionarioventas)/gi;

    if (code.slice(code.length - 1) !== 'A' && code !== '') {
      return 'COLABORADOR';
    }

    if (
      (roles.match(agente) &&
        roles.match(promotor) &&
        roles.match(funcionario)) ||
      (roles.match(agente) && roles.match(promotor)) ||
      (roles.match(agente) && roles.match(funcionario)) ||
      (roles.match(promotor) && roles.match(funcionario)) ||
      (roles.match(agente) && roles.match(funcionario))
    ) {
      if (
        (roles.match(agente) &&
          roles.match(promotor) &&
          roles.match(funcionario)) ||
        (roles.match(agente) && roles.match(funcionario)) ||
        (roles.match(promotor) && roles.match(funcionario)) ||
        (roles.match(agente) && roles.match(funcionario))
      ) {
        return 'FUNCIONARIO COMERCIAL';
      }
      return 'PROMOTOR';
    } else if (roles.match(agente)) {
      return 'AGENTE';
    } else if (roles.match(promotor)) {
      return 'PROMOTOR';
    } else if (roles.match(funcionario)) {
      return 'FUNCIONARIO COMERCIAL';
    }

    return 'AGENTE';
  }

  rolUserParse(roles: string): string[] {
    const rolesArr = [];

    const ProTGT = /(MXAppS_ D14301CotizadorVida_Agente)/gi;
    const Aliados = /(MXAppS_D14301CotizadorVida_AgenteAliados)/gi;
    const Promotores = /(MXAppS_D14301CotizadorVida_Promotor)/gi;
    const Funcionarios = /(MXAppS_D14301CotizadorVida_FuncionarioComercial)/gi;

    if (roles.match(ProTGT)) {
      rolesArr.push('ProTGT');
    }

    if (roles.match(Aliados)) {
      rolesArr.push('Aliados+');
    }

    if (roles.match(Promotores)) {
      rolesArr.push('Promotor');
    }

    if (roles.match(Funcionarios)) {
      rolesArr.push('Funcionario');
    }

    return rolesArr;
  }

  /**
   *
   * @method
   * @param {string} username
   * @param {string} password
   * @returns {Observable<any>}
   * @memberof AuthenticationService
   */
  getToken(username: string, password: string): Observable<any> {
    return this.http.request(
      'POST',
      `https://servicioswebqa.axa.com.mx:9339/v1/oauth/token?grant_type=password&username=${username}&password=${password}&scope=/agentes/*`,
      //  `https://servicioswebdev.axa.com.mx:9200/token?grant_type=password&username=${username}&password=${password}&scope=/agentes/*`,
      {
        headers: {
          'Authorization':
            'Basic T0NQLUNMSUVOVENSRURFTlRJQUw6SFE6cllNTUVNMTNld0x6'
        }
      }
    );
  }

  obtenerDetallesAgente(
    usuario: string,
    token: string,
    claveAgente: string
  ): Observable<any> {
    const body = {
      axaHeaderReq: {
        usuario,
        UUID: uuid(),
        fechaHora: String(new Date().toISOString()).slice(0, String(new Date().toISOString()).length - 5).replace('.', ':')
      },
      data: {
        claveAgente
      }
    };

    return this.http.request(
      'POST',
      `https://servicioswebdev.axa.com.mx:9199/agentes/rest/obtenerDetallesAgente`,
      {
        body,
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
  }

  obtenerEstructuraOrganizacional(
    usuario: string,
    token: string,
    claveAgente: string
  ): Observable<any> {
    const body = {
      axaHeaderReq: {
        usuario,
        UUID: uuid(),
        fechaHora: String(new Date().toISOString()).slice(0, String(new Date().toISOString()).length - 5).replace('.', ':')
      },
      data: {
        claveAgente
      }
    };

    return this.http.post(
      'https://servicioswebdev.axa.com.mx:9199/agentes/rest/obtenerEstructuraOrganizacional',
      body,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
  }

  obtenerSubordinados(
    usuario: string,
    token: string,
    claveAgente: string
  ): Observable<any> {
    const body = {
      axaHeaderReq: {
        usuario,
        UUID: uuid(),
        fechaHora: String(new Date().toISOString()).slice(0, String(new Date().toISOString()).length - 5).replace('.', ':')
      },
      data: {
        claveAgente
      }
    };

    return this.http.post(
      'https://servicioswebdev.axa.com.mx:9199/agentes/rest/obtenerSubordinados',
      body,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
  }

  /**
   * * Validar la estructura del agente (no letras) y de los roles
   * 
   */
  validarRol(
    usuario: string,
    token: string,
    agente: string,
    roles: string[]
  ): Observable<any> {
    const body = {
      axaHeaderReq: {
        usuario,
        UUID: uuid(),
        fechaHora: String(new Date().toISOString()).slice(0, String(new Date().toISOString()).length - 5).replace('.', ':')
      },
      data: {
        agente,
        roles
      }
    };

    return this.http.post(
      'https://servicioswebdev.axa.com.mx:9199/agentes/rest/validarRol',
      body,
      {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
  }
}
