import { SplitPayment } from '@models/split-payment/split-payment.model';

export const SPLIT_PAYMENT: SplitPayment[] = [
  {
    type: 'yearly',
    surcharge: {
      MXN: 0,
      USD: 0,
      UDI: 0,
    },
    divider: 1
  },
  {
    type: 'semi-annually',
    surcharge: {
      MXN: 0.05,
      USD: 0.035,
      UDI: 0.035
    },
    divider: 2
  },
  {
    type: 'quarterly',
    surcharge: {
      MXN: 0.075,
      USD: 0.05,
      UDI: 0.05
    },
    divider: 4
  },
  {
    type: 'monthly',
    surcharge: {
      MXN: 0.09,
      USD: 0.06,
      UDI: 0.06
    },
    divider: 12
  }
];

export const PROTEF = 0.10;
