import { Injectable } from '@angular/core';
import { FactorsInpc } from './../../models/inpc/factors-inpc';
import { BaseDatosInpcService } from './../baseDatos/base-datos-inpc.service';
import { DescipherService } from './../descipher/descipher.service';
import { OpcionesPlanService } from '../opcionesPlan/opciones-plan.service';
import { FactorsSearch } from '@models/factors-search/factors-search.model';

@Injectable({
  providedIn: 'root'
})
export class InpcService {

  constructor(private inpcDb: BaseDatosInpcService,
    private descipher: DescipherService,
    public opciones: OpcionesPlanService) { }

  async getInpcFactoresBasica() {
    return await new Promise<FactorsInpc[]>((resolve, reject) => {
      let params: FactorsSearch;
      params = {
        plan: this.opciones.plan,
        moneda: this.opciones.moneda,
        tipoPlan: this.opciones.tipoPlan,
        plazoPago: Number(this.opciones.plazoPago.value),
        plazoSeguro: this.opciones.plazoSeguro,
        sexo: this.opciones.prospecto.gender,
        fumador: this.opciones.prospecto.fumador,
        edad: this.opciones.prospecto.mancomunado.mancomunado ? this.opciones.prospecto.mancomunado.edadEquivalente :
        this.opciones.prospecto.age,
        mancomunado: {
          mancomunado: this.opciones.prospecto.mancomunado.mancomunado,
          edadEquivalente: this.opciones.prospecto.mancomunado.edadEquivalente
        }
      };
      this.inpcDb.getDocs().then(bd => {
        const resultDb = bd.docs;
        const arrFacInpc: FactorsInpc[] = [];
        let plazo = 0;
        let ultimaTarifa;
        const edad = params.edad;
        let pPago =  0;
        if (params.tipoPlan === 'TEMPORAL' && Number(params.plazoPago) === 65) {
          pPago = Number(params.plazoPago) - 1;
        } else {
          pPago = Number(params.plazoPago) >= 60 ? Number(params.plazoPago) : ((Number(edad) + Number(params.plazoPago)) - 1);
        }
        let plazoDec = params.plazoPago;
        for (let i = edad; i <= pPago; i++) {
          plazo++;
          if (i === 71) {
            break;
          }
          const fact: FactorsInpc = { edad: '', plazoPago: '', tarifa: '' };
          if (plazo === 1) {
            fact.edad = i.toString();
            fact.plazoPago = plazoDec.toString();
            fact.tarifa = '0';
          } else {
            fact.edad = i.toString();
            fact.plazoPago = plazoDec.toString();
            let tar, arrTar;
            if (plazo <= 26 || Number(params.plazoPago) >= 60) {
              arrTar = resultDb.filter(res => res.Edad === i.toString() && res.PlazoPago === plazoDec.toString());
              if (arrTar.length > 0) {
                tar = this.descipher.desiph(arrTar[0].Tarifa);
              } else {
                throw new Error('No se encontro el factor para la edad ' + i.toString() + ' en el plazo ' + plazoDec.toString());
              }
            } else {
              tar = ultimaTarifa;
            }
            fact.tarifa = tar;
            if (plazo === 26 && Number(params.plazoPago) < 60) {
              ultimaTarifa = tar;
            }
          }
          arrFacInpc.push(fact);
          if (this.opciones.tipoPlan !== 'OV' && Number(params.plazoPago) < 60) {
            plazoDec--;
          }
        }
        resolve(arrFacInpc);
      }
      ).catch(reason => {
        reject('No se Obtubieron los factores INPC: ' + reason);
      });
    });

  }

  async getFactValorRescate(): Promise<any> {
    const datos = await this.inpcDb.getDocsVg();
    const plazosPagos = this.opciones.tipoPlan !== 'OV' ?
    Array(Number(this.opciones.plazoPago.value))
      .fill(1).map((v, i) => (v + i).toString()) : [99];

    // Damos formato
    let cont = plazosPagos.length;
    const formatedData = plazosPagos.reduce((x: any[], y) /* x será el array, y los valores de los plazos */=> {
      // Creamos objeto que contendrá el formato de 'vpl' y 'factores'
      const obj = {};
      obj['vpl'] = y; // Asignamos el plazo
      // filtramos donde el plazo sea el plazo Y, después de ahí sólo conseguimos los factores
      // que ya vienen en array
      if (this.opciones.tipoPlan === 'OV') {
        return this.handleVGOVData(datos.docs);
      }

      if (Number(this.opciones.plazoPago.value) >= 60) {
        return this.handleVGEdadAlcanzada(datos.docs);
      }

      obj['factores'] = this.handleVGData(datos.docs, y, cont);

      if (this.opciones.tipoPlan === 'TEMPORAL' && Number(y) <= 9) {
        obj['factores'] = Array(Number(y)).fill(0);
      }
      cont--;

      // al array vacío (la primera vez), le agregamos el objeto, después le sumamos los otros objetos
      // al array con los datos conforme va iterando
      x.push(obj);
      return x;
    }, [] /* Se inicializa el valor con un array vacío */);

    return formatedData;
  }

  private handleVGData(data: any[], y, cont): any[] {
    const edadM = Number(this.opciones.prospecto.mancomunado.mancomunado ? this.opciones.prospecto.mancomunado.edadEquivalente :
    this.opciones.prospecto.age) + cont - 1;
    return data.sort((aSort, bSort) => Number(aSort.AnoPoliza) > Number(bSort.AnoPoliza) ? 1 : -1)
      .filter(d => Number(d.PlazoPago) === Number(y) && Number(d.Edad) === Number(edadM))
      .map(d => {
        d.Factor = this.descipher.desiph(d.Factor);
        return d.Factor;
      });
  }

  private handleVGOVData(data: any[]): any[] {
    const edadM = Number(this.opciones.prospecto.mancomunado.mancomunado ? this.opciones.prospecto.mancomunado.edadEquivalente :
      this.opciones.prospecto.age);
    const edades = Array(71 - edadM)
      .fill(edadM).map((a, b) => a + b);

    const filteredTarifas = edades.reduce((a, b) => {
      a.push({ 'vpl': b,
        factores: data.sort((aSort, bSort) => Number(aSort.AnoPoliza) > Number(bSort.AnoPoliza) ? 1 : -1).filter(t => t.Edad === b.toString()).map(d => {
        d.Factor = this.descipher.desiph(d.Factor);
        return d.Factor;
      }) } );
      return a;
    }, []);

    return filteredTarifas;
  }

  handleVGEdadAlcanzada(data: any[]) {
    const edadM = Number(this.opciones.prospecto.mancomunado.mancomunado ? this.opciones.prospecto.mancomunado.edadEquivalente :
      this.opciones.prospecto.age);
    const edades = Array(Number(this.opciones.plazoPago.value) - edadM)
      .fill(edadM).map((x, y) => x + y);

    const filteredTarifas = edades.reduce((a, b) => {
      const obj = {};
      const filter = data.sort((aSort, bSort) => Number(aSort.AnoPoliza) > Number(bSort.AnoPoliza) ? 1 : -1)
        .filter(d => Number(d.Edad) === b)
        .map(d => this.descipher.desiph(d.Factor));
      obj['vpl'] = b;
      obj['factores'] = filter;
      a.push(obj);
      return a;
    }, []);

    return filteredTarifas;
  }

}
