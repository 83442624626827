import { PipesModule } from '@pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SortablejsModule} from 'angular-sortablejs';

// Componente
import { GuaranteedValuesComponent } from '@components/guaranteed-values/guaranteed-values.component';
import { SharedModule } from '@shared/shared.module';
import { CollapsibleModule } from 'angular2-collapsible';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CollapsibleModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    PipesModule,
    MatPaginatorModule,
    SortablejsModule,
    MatIconModule,
    MatDividerModule
  ],
  declarations: [
    GuaranteedValuesComponent
  ]
})
export class GuaranteedValuesModule { }
