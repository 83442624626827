import { Injectable } from '@angular/core';
import { AportacionAdicional } from './../../models/universales/aportacionAdicional';
import { FormulasService } from './formulas.service';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
import { BehaviorSubject } from 'rxjs';
import { Retiro } from './../../models/universales/retiro';
import { Fondos } from './../../models/universales/fondos';
import { Fondo } from './../../models/universales/fondo';

@Injectable({
  providedIn: 'root'
})
export class CalculosExcedentesUniversalesService {

  arrAportAdi_BS = new BehaviorSubject<AportacionAdicional[]>([]);
  porcentajeFondoBasico = new BehaviorSubject<number>(5);
  porcentajeFondoExcedente = new BehaviorSubject<number>(5);
  tipoExcedente = 0;
  metaBuscadaObj = {
    anios: 0,
    numAportacion: 0,
    montoDeseado: 0
  };
  retiroObj: Retiro[] = [];

  fondoBasico: Fondos;
  fondoExcedente: Fondos;



  constructor(private formulas: FormulasService,
    private opc: OpcionesPlanService) {
    this.cambiarFondo();
  }

  metaBuscada(anios: number, numAportaciones: number, montoDeseado: number, comision: number, bNueva?: boolean) {
    this.arrAportAdi_BS.next([]);
    if (bNueva) {
      this.retiroObj = [];
    }
    this.tipoExcedente = 1;
    this.metaBuscadaObj.anios = anios;
    this.metaBuscadaObj.numAportacion = numAportaciones;
    this.metaBuscadaObj.montoDeseado = montoDeseado;
    const aport1 = 100;
    const aport2 = 200;
    let interpolacion = 0;
    let arrApotacion1: AportacionAdicional[] = this.llenarAportacionesAnios(aport1, 0, anios, numAportaciones);
    let arrApotacion2: AportacionAdicional[] = this.llenarAportacionesAnios(aport2, 0, anios, numAportaciones);
    let arrApotacion3 = null;
    arrApotacion1 = this.formulas.asignarProyeccionAportacionAdicional(this.porcentajeFondoExcedente.value, comision, arrApotacion1);
    arrApotacion2 = this.formulas.asignarProyeccionAportacionAdicional(this.porcentajeFondoExcedente.value, comision, arrApotacion2);
    if (arrApotacion1.length > 0 && arrApotacion2.length > 0) {
      const proyeccion1 = arrApotacion1[arrApotacion1.length - 1].proyeccion;
      const proyeccion2 = arrApotacion2[arrApotacion2.length - 1].proyeccion;
      interpolacion = this.formulas.obtenerMetaBuscadaAportacionAdicional(montoDeseado, proyeccion1, proyeccion2, aport1, aport2);
      arrApotacion3 = this.llenarAportacionesAnios(interpolacion, 0, anios, numAportaciones);
      arrApotacion3 = this.formulas.asignarProyeccionAportacionAdicional(this.porcentajeFondoExcedente.value, comision, arrApotacion3);
    }
    this.arrAportAdi_BS.next(arrApotacion3);
  }

  recurrente(aportacion: number, anioInicio: number, anioFin: number, comision: number) {
    this.arrAportAdi_BS.next([]);
    this.tipoExcedente = 2;
    let arrAportAdi: AportacionAdicional[] = [];
    arrAportAdi = this.agregarAportacionesAniosPlazo(aportacion, anioInicio, anioFin, 90);
    arrAportAdi = this.formulas.asignarProyeccionAportacionAdicional(this.porcentajeFondoExcedente.value, comision, arrAportAdi);
    this.arrAportAdi_BS.next(arrAportAdi);
  }

  unica(anioAportacion: number, aportacion: number, comision: number) {
    this.arrAportAdi_BS.next([]);
    this.tipoExcedente = 3;
    let arrAportAdi: AportacionAdicional[] = [];
    const plazo = 90 - this.opc.prospecto.age;
    arrAportAdi = this.agregarAportacionesAniosPlazo(0, 0, 0, plazo);
    arrAportAdi = this.agregarAportacionXanio(arrAportAdi, anioAportacion, aportacion);
    arrAportAdi = this.formulas.asignarProyeccionAportacionAdicional(this.porcentajeFondoExcedente.value, comision, arrAportAdi);
    this.arrAportAdi_BS.next(arrAportAdi);
  }

  retiro(anio: number, aportacion: number, comision: number) {
    if (this.tipoExcedente === 1) {
      this.agregarQuitarRetiro(anio, aportacion);
      this.metaBuscada(this.metaBuscadaObj.anios, this.metaBuscadaObj.numAportacion, this.metaBuscadaObj.montoDeseado, comision);
    } else {
      let arrAportAdi = this.arrAportAdi_BS.value;
      const aporAdi = arrAportAdi.filter(s => s.anio === anio.toString());
      if (aporAdi.length > 0) {
        aporAdi[0].retiro = aportacion;
        arrAportAdi = this.formulas.asignarProyeccionAportacionAdicional(this.porcentajeFondoExcedente.value, comision, arrAportAdi);
        this.arrAportAdi_BS.next(arrAportAdi);
      } else {
        Error('RETIRO: NO SE ENCONTRO EL AÑO ' + anio + ' EN EL ARREGLO ' + JSON.stringify(arrAportAdi));
      }
    }

  }

  private agregarQuitarRetiro(anio: number, aportacion: number) {
    const arrRetiro = this.retiroObj.filter(s => s.anio === anio);
    if (arrRetiro.length === 0) {
      const retObj: Retiro = {
        anio: anio,
        aportacion: aportacion
      };
      this.retiroObj.push(retObj);
    } else {
      arrRetiro[0].anio = anio;
      arrRetiro[0].aportacion = aportacion;
    }
  }

  // quitarAportacionRetiro(anio: number, comision: number) {
  //   let arrAportAdi = this.arrAportAdi_BS.value;
  //   const aporAdi = arrAportAdi.filter(s => s.anio === anio.toString());
  //   if (aporAdi.length > 0) {
  //     aporAdi[0].retiro = 0;
  //     aporAdi[0].aportacion = 0;
  //     arrAportAdi = this.formulas.asignarProyeccionAportacionAdicional(this.porcentajeFondoExcedente.value, comision, arrAportAdi);
  //     this.arrAportAdi_BS.next(arrAportAdi);
  //   } else {
  //     Error('RETIRO: NO SE ENCONTRO EL AÑO ' + anio + ' EN EL ARREGLO ' + JSON.stringify(arrAportAdi));
  //   }
  // }

  borrarAportaciones() {
    this.arrAportAdi_BS.next([]);
    this.tipoExcedente = 0;
  }

  private llenarAportacionesAnios(aportacion: number, proyeccion: number, anios: number, numAportaciones: number) {
    const arrAportAdi: AportacionAdicional[] = [];
    let aportacionesPendientes = numAportaciones;
    for (let i = 1; i <= anios; i++) {
      let anioRetiro = 0;
      let retiro = 0;
      const arrRetiro = this.retiroObj.filter(s => s.anio === i);
      if (arrRetiro.length > 0) {
        anioRetiro = arrRetiro[0].anio;
        retiro = arrRetiro[0].aportacion;
      }
      const aportAdi: AportacionAdicional = {
        anio: i.toString(),
        aportacion: (--aportacionesPendientes >= 0) ? aportacion : 0,
        proyeccion: proyeccion,
        numeroAportacion: i <= numAportaciones ? i : 0,
        retiro: (anioRetiro < anios) ? ((i) === anioRetiro) ? retiro : 0 : 0
      };
      arrAportAdi.push(aportAdi);
    }
    return arrAportAdi;
  }

  private agregarAportacionXanio(arrAportAdi: AportacionAdicional[], anioAportacion: number, aportacion: number) {
    const aportAdi = arrAportAdi.filter(s => s.anio === anioAportacion.toString());
    if (aportAdi.length > 0) {
      aportAdi[0].aportacion = aportacion;
      aportAdi[0].numeroAportacion = 1;
    } else {
      Error('NO SE ENCONTRO EL AÑO ' + anioAportacion + ' EN EL ARREGLO ' + JSON.stringify(arrAportAdi));
    }
    return arrAportAdi;
  }

  private agregarAportacionesAniosPlazo(anioInicio: number, anioFin: number, aportacion: number, plazo: number) {
    const arrAportAdi: AportacionAdicional[] = [];
    let countAnios = anioInicio;
    let aport = 0, numAportacion = 0;
    for (let i = 1; i <= plazo; i++) {
      if (countAnios === i && countAnios <= anioFin) {
        numAportacion++;
        aport = aportacion;
        countAnios++;
      } else {
        numAportacion = 0;
        aport = 0;
      }
      const aportAdi: AportacionAdicional = {
        anio: i.toString(),
        aportacion: aport,
        proyeccion: 0,
        numeroAportacion: numAportacion,
        retiro: 0
      };
      arrAportAdi.push(aportAdi);
    }
    return arrAportAdi;
  }

  public cambiarFondo() {
    this.initFondos();
    if (this.opc.moneda === 'USD') {
      this.fondoBasico.distribucion.dolares = 100;
      this.fondoBasico.tasa.dolares = 2;
      this.fondoExcedente.distribucion.dolares = 100;
      this.fondoExcedente.tasa.dolares = 2;
    } else {
      this.fondoBasico.distribucion.balanceado = 100;
      this.fondoBasico.tasa.balanceado = 5;
      this.fondoExcedente.distribucion.conservador = 100;
      this.fondoExcedente.tasa.conservador = 5;
    }

  }

  public initFondos() {
    this.fondoBasico = {
      distribucion: this.newFondo(),
      tasa: this.newFondo()
    };
    this.fondoExcedente = {
      distribucion: this.newFondo(),
      tasa: this.newFondo()
    };
  }

  private newFondo() {
    const fondo: Fondo = {
      conservador: 0,
      balanceado: 0,
      creciente: 0,
      dinamicoMX: 0,
      dolares: 0,
      dinamicoEUA: 0
    };
    return JSON.parse(JSON.stringify(fondo));
  }


}
