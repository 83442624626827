import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import * as jsPDF from 'jspdf';
import * as moment from 'moment';
import { AuthenticationService } from '../authentication/authentication.service';
import { User, CoverageStatus, PaymentType } from '@app/models';
import { RoutesService } from '../routes/routes.service';
import { ProspectoService } from '../prospecto/prospecto.service';
import { PlanType } from '@app/constants';
import { OpcionesPlanService } from '../opcionesPlan/opciones-plan.service';
import { Currency, SPLIT_PAYMENT } from '../../constants';
import { CalculosService } from '../calculos/calculos.service';
import { RecursosExternosService } from '../recursosExternos/recursos-externos.service';
import { ExcedentesService } from '../excedentes/excedentes.service';
import { PaymentFrequencyService } from '../paymentFrequency/payment-frequency.service';
import { ANUAL_INFLATIONS } from '../../constants/plan-options/anual-inflations.constant';
import { formatCurrency } from '@angular/common';
import { ConyugalPlazoPagoPipe } from '@pipes/conyugal-plazo-pago/conyugal-plazo-pago.pipe';

@Injectable({
  providedIn: 'root'
})
export class CartaPdfService {
  private pdf: jsPDF;
  positionY1: number;
  positionY2: number;
  posY: number;
  cotizador: string;
  user: User;
  prospectu = this.prospectusDataService.prospecto.value;
  plan: PlanType;
  moneda: Currency;
  mon: string;
  typeMon: string;
  segundaMoneda: Currency;
  plazoPagos: number;
  primaTotalUDI: number;
  primaAdicionalUDI: number;
  primaAnualUDI: number;
  valorUDI: number;
  primaTotalMXN: number;
  primaAdicionalMXN: number;
  primaAnualMXN: number;
  inflacionAnual: string;
  fallecimiento: number;
  invalidezBI: CoverageStatus;
  invalidezPAI: number;
  accidentes: number;
  enfermedades: number;
  cancer: number;
  conyugal: number;
  desempleo: CoverageStatus;
  funerarios: number;
  isDotal: boolean;
  temporalidad: string;
  temp: string;
  aportacion: number;
  aportacionTempUDI: number;
  aportacionTempMXN: number;

  constructor(
    private prospectusDataService: ProspectoService,
    private calculos: CalculosService,
    private opciones: OpcionesPlanService,
    private recursos: RecursosExternosService,
    private excedentes: ExcedentesService,
    private authentication: AuthenticationService,
    private paymentFrecuency: PaymentFrequencyService,
    private routes: RoutesService,
    private conyugalPipe: ConyugalPlazoPagoPipe,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.cotizador = this.routes.chosenCotizador.value;
    this.authentication.user.subscribe(user => (this.user = user));
    this.plazoPagos = Number(this.opciones.plazoPago.value);
  }

  private obtieneValores() {
    this.prospectusDataService.prospectoActual.subscribe(
      prospecto => (this.prospectu = prospecto)
    );
    /**
     * CARACTERISTICAS DEL PLAN
     */
    // Plan
    this.plan = this.opciones.tipoPlan;
    // Tipo Moneda
    this.moneda = this.opciones.moneda;

    if (this.mon === 'MXN') {
      this.mon = 'Pesos';
      this.typeMon = 'Pesos';
    } else if (this.moneda === 'USD') {
      this.mon = 'Dólares';
      this.typeMon = 'Dólares';
    } else {
      this.mon = 'UDI';
      this.typeMon = 'Pesos';
    }
    // Tipo Segunda Moneda
    this.segundaMoneda = this.opciones.segundaMoneda;
    // Plazo Pagos
    this.plazoPagos = Number(this.opciones.plazoPago.value);
    // Prima total
    this.primaTotalUDI = this.calculos.primaTotalFraccionadaDividida.value;
    // Prima adicional
    this.primaAdicionalUDI = this.calculos.G_primaAdicional_UDI.value;
    // Prima anual
    this.primaAnualUDI = this.calculos.sumaPrimas.value;
    // Valor de la UDI:
    this.valorUDI = this.recursos.valor_UDI;
    // Prima Total Moneda 2:
    this.primaTotalMXN =
      this.opciones.moneda === 'UDI'
        ? this.recursos.FirstToSecond(this.primaTotalUDI)
        : this.primaTotalUDI;
    // Prima anual moneda 2:
    this.primaAnualMXN =
      this.opciones.moneda === 'UDI'
        ? this.recursos.FirstToSecond(this.primaAnualUDI)
        : this.primaAnualUDI;
    // Inflación anual:
    this.inflacionAnual = ANUAL_INFLATIONS.filter(
      item => Number(item.value) === Number(this.excedentes.inflacion_udi.value)
    )[0].title;
    // Fallecimiento
    this.fallecimiento = this.calculos.BASICA_sumaAseguradaUDI.value;
    // Invalidez
    this.invalidezBI = this.calculos.BI.sumaAsegurada_UDI.value;
    this.invalidezPAI = this.calculos.PAI.sumaAsegurada_UDI.value;
    // Accidentes
    switch (true) {
      case this.calculos.DI.sumaAsegurada_UDI.value > 0:
        this.accidentes = this.calculos.DI.sumaAsegurada_UDI.value;
        break;
      case this.calculos.DIPO.sumaAsegurada_UDI.value > 0:
        this.accidentes = this.calculos.DIPO.sumaAsegurada_UDI.value;
        break;
      case this.calculos.DIPOC.sumaAsegurada_UDI.value > 0:
        this.accidentes = this.calculos.DIPOC.sumaAsegurada_UDI.value;
        break;
      default:
        this.accidentes = 0;
        break;
    }
    // Enfermedades
    this.enfermedades = this.calculos.PAE.sumaAsegurada_UDI.value;
    // Cáncer
    this.cancer = this.calculos.IAC.sumaAsegurada_UDI.value;
    // Conyugal
    this.conyugal = this.calculos.CC.sumaAsegurada_UDI.value;
    // Desempleo
    this.desempleo = this.calculos.PCD.sumaAsegurada_UDI.value;
    // Funerarios
    this.funerarios = this.calculos.GF.sumaAsegurada_UDI.value;
    if (this.opciones.tipoPlan === 'DOTAL') {
      this.isDotal = true;
    } else {
      this.isDotal = false;
    }
    this.temporalidad = this.paymentFrecuency.frecuenciaPago.value;
    if (this.temporalidad === 'ANUAL') {
      this.temp = 'total';
    } else {
      this.temp = this.temporalidad.toLowerCase();
    }
    this.aportacion = this.excedentes.aportacion_anual_UDI.value;
    // Prima adicional moneda 2:
    this.primaAdicionalMXN =
      this.moneda !== 'UDI'
        ? this.aportacion
        : this.recursos.FirstToSecond(this.aportacion);
    this.aportacionTempUDI = this.aportacion / this.getTemporalidadDivisor();

    this.aportacionTempMXN =
      this.moneda !== 'UDI'
        ? this.aportacionTempUDI
        : this.recursos.FirstToSecond(this.aportacionTempUDI);
  }
  getTemporalidadDivisor(): number {
    let splitPayment: PaymentType = 'yearly';
    switch (this.paymentFrecuency.frecuenciaPago.value) {
      case 'ANUAL':
        splitPayment = 'yearly';
        break;

      case 'SEMESTRAL':
        splitPayment = 'semi-annually';
        break;

      case 'TRIMESTRAL':
        splitPayment = 'quarterly';
        break;

      case 'MENSUAL':
        splitPayment = 'monthly';
        break;
    }

    return SPLIT_PAYMENT.filter(i => i.type === splitPayment)[0].divider;
  }
  generarPDF(): string {
    this.pdf = new jsPDF('p', 'mm', 'letter', 1);
    this.setHeader();
    this.setDataProspectus();
    this.setFeactures();
    this.setBody();
    this.setFooter();

    return btoa(this.pdf.output());
    // this.pdf.save('Cotizador Vida - AXA');
  }
  // muestraPDF(): void {
  //   this.pdf = new jsPDF('p', 'mm', 'letter', 1);
  //   this.setHeader();
  //   this.setDataProspectus();
  //   this.setFeactures();
  //   this.setBody();
  //   this.setFooter();
  //   this.pdf.save('Cotizador Vida - AXA');
  // }

  private setHeader(): void {
    this.addImage(15, 8, 15, 15, 'assets/icons/icon-96x96.png');
    this.addImage(90, 8, 22, 24, 'assets/images/linered.png');
    this.watchDate();
    this.watchVersion();
    this.dataAgent('ROL: ' + this.user.type, 130, 12);
    this.dataAgent('NOMBRE: ' + this.user.name, 130, 17);
    this.dataAgent('MX: ' + this.user.code, 130, 22);
    this.dataAgent('EMAIL: ' + this.user.email, 130, 27);
    this.titlePrincipal(
      this.cotizador === 'Aliados'
        ? `Aliados + ${moment().year()}`
        : `ProTGT ${moment().year()}`,
      15,
      29
    );
    this.separator(9, 32, 198, 1);
  }
  private setDataProspectus(): void {
    this.titles('Datos del Prospecto:', 15, 37);

    this.headerText('Nombre: ', 82, 38);
    this.text(this.prospectu.name, 115, 38);

    this.headerText('Edad: ', 82, 42);
    this.text(String(this.prospectu.age), 115, 42);

    this.headerText('Género: ', 82, 46);
    this.text(this.prospectu.gender === 'H' ? 'HOMBRE' : 'MUJER', 115, 46);

    this.headerText('Hábito: ', 82, 50);
    this.text(
      this.prospectu.fumador === 'SI' ? 'FUMADOR' : 'NO FUMADOR',
      115,
      50
    );    
    this.separator(9, 53, 198, 1);    
  }
  private setFeactures() {
    this.obtieneValores();
    this.titles('Características del Plan:', 15, 58);
    this.posY = 58;
    this.headerText('Plan: ', 82, (this.posY += 4));
    this.text(this.plan === 'DOTAL' ? 'Aliados +' : this.plan, 115, this.posY);

    this.headerText('Tipo Moneda: ', 82, (this.posY += 4));
    this.text(this.mon, 115, this.posY);

    this.headerText('Plazo de Pagos: ', 82, (this.posY += 4));
    this.text(
      String(this.plazoPagos) +
        String(this.plazoPagos <= 60 ? ' años' : ' edad alcanzada'),
      115,
      this.posY
    );
    this.positionY1 = this.posY;
    this.positionY2 = this.posY;
    if (this.moneda === 'UDI') {
      this.headerText('Prima Anual: ', 82, (this.positionY1 += 4));
      this.text(
        String(
          formatCurrency(this.primaAnualUDI, this.locale, ' ', 'MXN', '1.0-0')
        ) +
          ' ' +
          this.mon,
        115,
        (this.positionY2 += 4)
      );
      if (this.aportacion !== 0) {
        this.headerText('Aportación: ', 82, (this.positionY1 += 5));
        this.text(
          formatCurrency(this.aportacion, this.locale, ' ', 'MXN', '1.0-0') +
            ' ' +
            this.mon,
          115,
          (this.positionY2 += 4)
        );
      }
      this.headerText('Prima ' + this.temp + ': ', 82, (this.positionY1 += 4));
      this.text(
        formatCurrency(
          this.primaTotalUDI + this.aportacionTempUDI,
          this.locale,
          ' ',
          'MXN',
          '1.0-0'
        ),
        115,
        (this.positionY2 += 4)
      );
      // this.text(String(this.primaTotalUDI + this.aportacionTempUDI), 115, this.positionY2 += 5);
      this.headerText('Valor de la UDI: ', 82, (this.positionY1 += 4));
      this.text(' ' + String(this.valorUDI), 115, (this.positionY2 += 4));
    }
    this.headerText('Prima Anual: ', 82, (this.positionY1 += 7));
    this.text(
      formatCurrency(this.primaAnualUDI, this.locale, ' ', 'MXN', '1.0-0') +
        ' ' +
        this.typeMon,
      115,
      (this.positionY2 += 7)
    );
    if (this.aportacion !== 0) {
      this.headerText('Aportacion: ', 82, (this.positionY1 += 4));
      this.text(
        formatCurrency(
          this.primaAdicionalMXN,
          this.locale,
          ' ',
          'MXN',
          '1.0-0'
        ) +
          ' ' +
          this.typeMon,
        115,
        (this.positionY2 += 4)
      );
    }
    this.headerText('Prima ' + this.temp + ': ', 82, (this.positionY1 += 4));
    this.text(
      formatCurrency(
        this.primaTotalMXN + this.aportacionTempMXN,
        this.locale,
        ' ',
        'MXN',
        '1.0-0'
      ) +
        ' ' +
        this.typeMon,
      115,
      (this.positionY2 += 4)
    );
    this.separator(9, (this.positionY1 += 3), 198, 1);
  }
  private setBody() {
    // FALLECIMIENTO
    this.titleCoverage('Fallecimiento', 15, (this.positionY1 += 5));
    this.descriptionCoverage(
      'En caso de Fallecimiento, tus beneficiarios recibirán la cantidad de ' +
        formatCurrency(this.fallecimiento, this.locale, ' ', 'MXN', '1.0-0') +
        ' ' +
        this.mon +
        ' en \nuna sola exhibición.',
      45,
      (this.positionY2 += 13)
    );
    this.addImage(
      19,
      (this.positionY1 += 3),
      9,
      10,
      'assets/icons/icon-fall.png'
    );
    this.separator(9, (this.positionY2 += 9), 198, 1);
    // INVALIDEZ
    this.titleCoverage('Invalidez', 18, (this.positionY1 += 16));
    this.addImage(
      19,
      (this.positionY1 += 3),
      9,
      10,
      'assets/icons/icon-bi.png'
    );
    if (this.invalidezBI === 'INCLUIDO' && this.invalidezPAI <= 0) {
      this.descriptionCoverage(
        'En caso de invalidez total y permanente, tu seguro quedará excento del pago de las' +
          '\nprimas de la cobertura básica  y continuará protegido por fallecimiento.',
        45,
        (this.positionY2 += 9)
      );
      this.separator(9, (this.positionY2 += 12), 198, 1);
    }
    if (this.invalidezBI === 'INCLUIDO' && this.invalidezPAI > 0) {
      this.descriptionCoverage(
        'En caso de invalidez total y permanente, tu seguro quedará excento del pago de las' +
          '\nprimas de la cobertura básica  y continuará protegido por fallecimiento y recibirá la\ncantidad de: ' +
          formatCurrency(this.invalidezPAI, this.locale, ' ', 'MXN', '1.0-0') +
          ' ' +
          this.mon,
        45,
        (this.positionY2 += 9)
      );
      this.separator(9, (this.positionY2 += 12), 198, 1);
    }
    if (this.invalidezPAI > 0 && this.invalidezBI === 'EXCLUIDO') {
      this.descriptionCoverage(
        'En caso de invalidez total y permanente,recibirás la cantidad de: ' +
          formatCurrency(this.invalidezPAI, this.locale, ' ', 'MXN', '1.0-0') +
          ' ' +
          this.mon,
        45,
        (this.positionY2 += 9)
      );
      this.separator(9, (this.positionY2 += 11), 198, 1);
    }
    if (this.invalidezBI === 'EXCLUIDO' && this.invalidezPAI <= 0) {
      this.descriptionCoverage(
        'Sin cobertura de invalidez.',
        95,
        (this.positionY2 += 9)
      );
      this.separator(9, (this.positionY2 += 11), 198, 1);
    }
    // Accidentes
    if (!this.isDotal) {
      this.titleCoverage('Accidentes', 17, (this.positionY2 += 5));
      this.addImage(
        19,
        (this.positionY1 += 18),
        9,
        10,
        'assets/icons/icon-di.png'
      );
      if (this.accidentes > 0) {
        this.descriptionCoverage(
          'En caso de muerte accidental y/o perdida orgánica tus beneficiarios recibirán la cantidad\nde: ' +
            formatCurrency(this.accidentes, this.locale, ' ', 'MXN', '1.0-0') +
            ' ' +
            this.mon,
          45,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 9), 198, 1);
      }
      if (this.accidentes <= 0) {
        this.descriptionCoverage(
          'Sin cobertura de accidentes.',
          93,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 8), 198, 1);
      }
    }
    // Enfermedades
    if (!this.isDotal) {
      this.titleCoverage('Enfermedades', 15, (this.positionY2 += 5));
      this.addImage(
        19,
        (this.positionY1 += 18),
        9,
        10,
        'assets/icons/icon-pae.png'
      );
      if (this.enfermedades > 0) {
        // tslint:disable-next-line:max-line-length
        this.descriptionCoverage(
          'En caso de diagnosticarse una efermedad grave como infarto al miocardio,' +
            ' accidente\nvascular cerebral, cáncer o insuficiencia renal recibiras la candidad de: ' +
            formatCurrency(
              this.enfermedades,
              this.locale,
              ' ',
              'MXN',
              '1.0-0'
            ) +
            ' ' +
            this.mon,
          45,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 9), 198, 1);
      }
      if (this.enfermedades <= 0) {
        this.descriptionCoverage(
          'Sin cobertura de enfermedades graves.',
          85,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 7), 198, 1);
      }
    }
    // Cáncer
    if (!this.isDotal) {
      this.titleCoverage('Cáncer', 19, (this.positionY2 += 5));
      // this.addImage(19, this.positionY1 += 18, 9, 10, 'assets/icons/icon-iac.png');
      if (this.cancer > 0) {
        this.addImage(
          19,
          (this.positionY1 += 21),
          9,
          10,
          'assets/icons/icon-iac.png'
        );
        // tslint:disable-next-line:max-line-length
        this.descriptionCoverage(
          'En caso de pronosticarse cáncer no metastásico se pagará un anticipo del 50% de:\n' +
            formatCurrency(this.cancer, this.locale, ' ', 'MXN', '1.0-0') +
            ' ' +
            this.mon +
            '. En caso de diagnosticarse cáncer' +
            ' recibirás, el remanente en caso de\nhaber solicitado el anticipo.',
          45,
          (this.positionY2 += 4)
        );
        this.separator(9, (this.positionY2 += 12), 198, 1);
      }
      if (this.cancer <= 0) {
        this.addImage(
          19,
          (this.positionY1 += 18),
          9,
          10,
          'assets/icons/icon-iac.png'
        );
        this.descriptionCoverage(
          'Sin cobertura de cáncer.',
          97,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 10), 198, 1);
      }
    }
    // Conyugal
    if (!this.isDotal) {
      this.titleCoverage('Conyugal', 18, (this.positionY2 += 5));
      if (this.conyugal > 0) {
        this.addImage(
          19,
          (this.positionY1 += 21),
          9,
          10,
          'assets/icons/icon-cc.png'
        );
        // tslint:disable-next-line:max-line-length
        this.descriptionCoverage(
          'En caso del fallecimiento del cónyugue, dentro de los primeros ' +
            this.conyugalPipe.transform(this.plazoPagos, this.prospectu.age) +
            ' años de la póliza,\n' +
            'se entregara a los beneficiarios designados la cantidad de: ' +
            formatCurrency(this.conyugal, this.locale, ' ', 'MXN', '1.0-0') +
            ' ' +
            this.mon,
          45,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 9), 198, 1);
      }
      if (this.conyugal <= 0) {
        this.addImage(
          19,
          (this.positionY1 += 20),
          9,
          10,
          'assets/icons/icon-cc.png'
        );
        this.descriptionCoverage(
          'Sin cobertura de conyugal.',
          96,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 9), 198, 1);
      }
    }
    // Desempleo
    this.titleCoverage('Desempleo', 18, (this.positionY2 += 5));
    if (this.desempleo === 'INCLUIDO') {
      this.addImage(
        19,
        (this.positionY1 += 18),
        9,
        10,
        'assets/icons/icon-pcd.png'
      );
      this.descriptionCoverage(
        // tslint:disable-next-line:max-line-length
        'En caso de desempleo involuntario, quedará excento de la prima del seguro por un año\nprotegido por fallecimiento y por las coberturas adicionales que haya contratado.',
        45,
        (this.positionY2 += 5)
      );
      this.separator(9, (this.positionY2 += 8), 198, 1);
    }
    if (this.desempleo === 'EXCLUIDO') {
      this.addImage(
        19,
        (this.positionY1 += 18),
        9,
        10,
        'assets/icons/icon-pcd.png'
      );
      this.descriptionCoverage(
        'Sin cobertura de desempleo.',
        95,
        (this.positionY2 += 5)
      );
      this.separator(9, (this.positionY2 += 7), 198, 1);
    }
    if (!this.isDotal) {
      // Funerarios
      this.titleCoverage('Funerarios', 18, (this.positionY2 += 5));
      if (this.funerarios > 0) {
        this.addImage(
          19,
          (this.positionY1 += 20),
          9,
          10,
          'assets/icons/icon-gf.png'
        );
        // tslint:disable-next-line:max-line-length
        this.descriptionCoverage(
          'En caso de fallecimiento, tus beneficiarios recibirán por úlimos gastos la cantidad\nde: ' +
            formatCurrency(this.funerarios, this.locale, ' ', 'MXN', '1.0-0') +
            ' ' +
            this.mon,
          45,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 10), 198, 1);
      }
      if (this.funerarios <= 0) {
        this.addImage(
          19,
          (this.positionY1 += 18),
          9,
          10,
          'assets/icons/icon-gf.png'
        );
        this.descriptionCoverage(
          'Sin cobertura de gastos funerarios.',
          90,
          (this.positionY2 += 5)
        );
        this.separator(9, (this.positionY2 += 8), 198, 1);
      }
    }
  }
  private setFooter() {
    this.footer(
      'Esta cotización tiene una validez de 15 días naturales a partir de la fecha de su elaboración, por lo que no se garantiza' +
        ' la emisión del seguro. La misma es ilustrativa y de apoyo a la fuerza',
      12,
      (this.positionY2 += 5)
    );
    this.footer(
      'de ventas. No forma parte del contrato del seguro.',
      85,
      (this.positionY2 += 3)
    );
    this.footer(
      'AXA Seguros se reserva el derecho de solicitar la respuesta a un cuestionario médico y de ocupación, así como estudios de laboratorio.',
      40,
      (this.positionY2 += 4.3)
    );
  }
  private footer(text: string, x: number, y: number): void {
    this.pdf.setFontStyle('normal');
    this.pdf.setFontSize(6.5);
    this.pdf.text(text, x, y);
  }
  private watchDate(): void {
    this.pdf.setFontType('bold');
    this.pdf.setFontSize(8.5);
    this.pdf.text(
      `${moment().format('DD')} de ${moment().format(
        'MMMM'
      )} del ${moment().format('YYYY')}`,
      154,
      7
    );
  }
  private watchVersion(): void {
    this.pdf.setTextColor(255, 0, 0);
    this.pdf.setFontSize(7);
    this.pdf.text(`v${moment().year()}.${moment().month()}`, 189, 7);
  }
  private dataAgent(text: string, x: number, y: number) {
    this.pdf.setFontType('bold');
    this.pdf.setFontSize(8.8);
    this.pdf.setTextColor(73, 90, 135);
    this.pdf.text(text, x, y);
  }
  private titlePrincipal(text: string, x: number, y: number) {
    this.pdf.setFontType('bold');
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(14);
    this.pdf.text(text, x, y);
  }
  private separator(x: number, y: number, w: number, h: number) {
    this.pdf.setDrawColor(8, 42, 136);
    this.pdf.setLineWidth(1);
    this.pdf.rect(x, y, w, h);
  }
  private titles(text: string, x: number, y: number) {
    this.pdf.setFontType('bold');
    this.pdf.setFontSize(8);
    this.pdf.text(text, x, y);
  }
  private headerText(text: string, x: number, y: number) {
    this.pdf.setFontType('bold');
    this.pdf.setFontSize(8.5);
    this.pdf.text(text, x, y);
  }
  private text(text: string, x: number, y: number) {
    this.pdf.setFontType('normal');
    this.pdf.setFontSize(9);
    this.pdf.text(text, x, y);
  }
  private titleCoverage(text: string, x: number, y: number) {
    this.pdf.setFontType('normal');
    this.pdf.setFontSize(8.5);
    this.pdf.text(text, x, y);
  }
  private descriptionCoverage(text: string, x: number, y: number) {
    this.pdf.setFontType('normal');
    this.pdf.setFontSize(11);
    this.pdf.text(text, x, y);
  }
  private addImage(x: number, y: number, w: number, h: number, file: string) {
    try {
      const image = new Image();
      image.src = file;
      this.pdf.addImage(
        image,
        file.split('.')[1].toUpperCase(),
        x,
        y,
        w,
        h,
        undefined,
        'FAST'
      );
    } catch (error) {}
  }
}
