/**
 *
 * CONSTANTES DE MONEDAS PARA AMBIENTE DE DESARROLLO
 *
*/

import { PlanType } from '@constants/plan-options';
/**
 * Moneda
 * @description Moneda soportada para las cotizaciones.
 */
export type Currency = 'MXN' | 'USD' | 'UDI';
/**
 * Constante que almacena las distintas monedas soportadas para las cotizaciones.
 * @const
 * @param title -type {string}
 * @param value -type {Currency}
 */
export const CURRENCIES: {title: string, value: Currency}[] = [
   { title: 'PESOS', value: 'MXN'},
   { title: 'DÓLARES', value: 'USD'},
   { title: 'UDI', value: 'UDI'},
];

/**
 * Monedas soportadas por cada uno de los planes.
 * @const
 */
export const CURRENCIES_SUPPORTED: {plan: PlanType, currencies: Currency[]}[] = [
  { plan: 'VPL', currencies: ['MXN', 'UDI', 'USD'] },
  { plan: 'TEMPORAL', currencies: ['UDI', 'USD', 'MXN'] },
  // { plan: 'OV', currencies: ['UDI', 'USD', 'MXN'] },
  { plan: 'OV', currencies: ['UDI', 'MXN'] },
  { plan: 'DOTAL', currencies: ['UDI'] },
  { plan: 'ALIADOSKIDS', currencies: ['UDI'] },
  { plan: 'PROTECANCER', currencies: ['MXN'] },
  { plan: 'PROTECCION EFECTIVA', currencies: ['MXN'] },
 // { plan: 'MUJER UNICA', currencies: ['UDI', 'USD', 'MXN'] },
  { plan: 'VIDA INTELIGENTE', currencies: ['MXN', 'USD'] },
  { plan: 'MI PROYECTO R', currencies: ['MXN'] }
];

/**
 * Moneda por defecto.
 * @const
 */
export const DEFAULT_CURRENCY: Currency = 'UDI';
/**
 * Moneda secundaria por defecto.
 * @const
 * @type {Currency}
 * @default "MXN"
 */
export const DEFAULT_SECONDCURRENCY: Currency = 'MXN';
