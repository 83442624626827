import { Component, OnInit } from '@angular/core';
import { FormGroup} from '@angular/forms';
// MODELS
import { Need } from '@models/necesity/needs-options.model';
// CONSTANT
import { PROSPECTUS_NECESITY_CONSTANTS } from '@constants/prospectus-needs/prospectus-needs.constants';
// SERVICE
import { NecessityService} from '@services/necessity/necessity.service';

/**
 * Componente encargado de manerjar la necesidad de meta
 */
@Component({
  selector: 'cvida-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.sass']
})
export class GoalComponent implements OnInit {

  /**
   * Opcion activa actual
   * @property
   * @type [Need]
   */
   currentNeedOption: Need;

  /**
   *  Contenido de la opción activa actual
   *
   * @type {FormGroup}
   * @memberof GoalComponent
   */
  necesidadOpcion: FormGroup;

  /**
   ** Pregunta uno activa
   * @property
   * @type [boolean]
   * @type {boolean}
   * @memberof GoalComponent
   */
  isAskOneActive: boolean;

  /**
   * Máscara de edad
   * @property
   * @type [Array<RegExp>]
   * @memberof GoalComponent
   */
  ageMask = [/[1-9]/, /[1-9]/];

  /**
   * Constructor
   * @method
   * @param necessity
   */
  constructor(
    private necessity: NecessityService
  ) { }

  /**
   * Lifecycle hook de OnInit
   * @method
   */
  ngOnInit() {
    this.currentNeedOption = <Need>PROSPECTUS_NECESITY_CONSTANTS.DEFAULT_NEED_OPTION;
    this.isAskOneActive = true;
  }

  /**
   * Cambia la pregunta actual
   * @method
   * @param isQuestion
   */
  changeQuestion(isQuestion: boolean) {
     if (isQuestion !== this.isAskOneActive) {
      this.isAskOneActive = isQuestion;
    }
  }

  /**
   * Establece la opción de necesidad
   * @method
   * @param needOption
   */
  setActiveNeedOption(needOption: Need): void {
    if (needOption !== this.currentNeedOption) {
      this.currentNeedOption = needOption;
      this.necessity.updateGoals(this.currentNeedOption.toString());
    } else {
      return;
    }
  }

  /**
   * Cambia el estado de la necesidad seleccionada
   * @method
   * @param needOption
   */
  isActiveNeedOption(needOption: Need): boolean {
    return this.currentNeedOption === needOption;
  }
}
