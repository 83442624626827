import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe name
 * @export
 * @class CurrencyToNumberPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'currencyToNumber'
})
export class CurrencyToNumberPipe implements PipeTransform {

  /**
	 * Convierte una cantidad separada por comas a numero.
	 * @param {string} currency '9,999.99'
	 * @returns {number} 9999.99
	 * @memberof CurrencyToNumberPipe
	 */
  transform(currency: string | number): number {
    if (typeof currency === 'string') {
      if (currency === null || currency === '' || currency === undefined) {
        return 0;
      }
      return Number(parseFloat(currency.replace(/,/g, '')));
    } else {
      if (currency === null || currency === undefined) {
        return 0;
      }
      return currency;
    }
  }
}
