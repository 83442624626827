import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { BnNgIdleService } from 'bn-ng-idle';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { AlertModalComponent } from './shared/modal/alert-modal/alert-modal.component';
import { ModalService } from './services/utils/modal.service';

/**
 * El componente principal
 */
@Component({
  selector: 'cvida-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  online$ = null;
  modalRef: BsModalRef;
  flagStartToTcheck = false;

  /**ñf
   * Referencia el template del modal
   * @property
   * @type {TemplateRef<any>}
   * @memberof AppComponent
   */
  @ViewChild('modalSession') modalSession: TemplateRef<any>;

  /**
   * Creates an instance of AppComponent.
   * @param {BsModalService} modalService
   * @param {Router} router
   * @param {BnNgIdleService} idle
   * @memberof AppComponent
   */
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private idle: BnNgIdleService,
    private modal: ModalService
  ) {}

  /**
   * Inicializa la condicional que reemplaza el protocolo en la URL.
   */
  ngOnInit() {

    this.idle.startWatching(600 /* 10 minutos */).take(1)
      .subscribe(inactivityTime => {
        this.router.navigate(['/cotizador', 'landing']);
        this.openModal(this.modalSession);
      });

    /**
     * Reemplaza el protocolo http por https en la barra de direcciones.
     */
    if (environment.production) {
      if (location.protocol === 'http:' && location.hostname !== 'localhost') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    setTimeout(() => {
      this.router.navigate(['/cotizador', 'landing']);
      this.openModal(this.modalSession);
    }, 3600000 /* 1 hora */);


    //Detectar conexión para mostrar mensaje de error
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(true)),
    );

    this.online$.subscribe(result => { 
        if(!result)
        {
          this.mostrarModal('Error de conexion, intenta de nuevo.');
        }
      } 
    )


    this.router.events.pairwise().subscribe((event: any) => {
        if(this.flagStartToTcheck && event[0].navigationTrigger && event[1].urlAfterRedirects && event[0].navigationTrigger !== event[1].urlAfterRedirects)
        {
          if(!navigator.onLine)
          {
            this.mostrarModal('Error de conexion, intenta de nuevo.');
          }
        }
        else if(!this.flagStartToTcheck  && event[0].navigationTrigger && event[1].urlAfterRedirects && event[0].navigationTrigger !== event[1].urlAfterRedirects)
        {
          this.flagStartToTcheck = true;
        }
    });

  }

  

  private mostrarModal(descripcion: string) {
    this.modal.bolBodalServ = true;
    this.modal.descripcion = descripcion;
    this.modalRef = this.modalService.show(AlertModalComponent, {
      backdrop: false,
      class: 'alert-modal'
    });
    this.modal.bolBodalServ = false;
  }

  /**
   * Abre modal especificado
   * @method
   * @private
   * @param {TemplateRef<any>} template
   * @memberof AppComponent
   */
  private openModal(template: TemplateRef<any>): void {
    this.modalService.show(template, { backdrop: 'static', animated: false, ignoreBackdropClick: true });
  }

  /**
   * Recarga la página
   * @method
   * @memberof AppComponent
   */
  reload(): void {
    location.reload();
  }

}
