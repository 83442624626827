import { Component, OnInit } from '@angular/core';

/**
 * Componente de requerimientos
 */
@Component({
  selector: 'cvida-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.sass']
})
export class RequirementsComponent implements OnInit {

  /**
   * Lifecycle Hook de OnInit
   * @method
   */
  ngOnInit() {
  }

}
