/** Angular Core */
import { Injectable } from '@angular/core';
/** Rxjs */
import { Observable, BehaviorSubject, fromEvent } from 'rxjs';
import 'rxjs/add/operator/pluck';
import 'rxjs/add/operator/map';

/**
 *
 * Servicio dedicado a la detección del porcentaje concurrente del scroll.
 * El porcentaje es determinado con base en la posición concurrente del height.
 * @export
 * @class ScrollPercentageDetectionService
 */
@Injectable({
  providedIn: 'root'
})
export class ScrollPercentageDetectionService {
  /**
   * Almacena el valor del porcentaje.
   * @property
   * @type {Observable<number>}
    */
  scrollY$: Observable<number>;

  /**
   * Inicialización de variables y métodos.
   */
  constructor() {
    const percentScrollY$ = new BehaviorSubject(this.getPercentScrollY());
    this.scrollY$ = (percentScrollY$.pluck('percentage') as Observable<number>).distinctUntilChanged();

    fromEvent(window, 'scroll')
      .map(this.getPercentScrollY)
      .subscribe(percentScrollY$);
  }
  /**
   * Obtiene el porcejaje del scroll.
   */
  private getPercentScrollY(): Object {
    const h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';
    return {
      percentage: isNaN((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100) ?
        100 : ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100)
    };
  }

}
