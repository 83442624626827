import { Injectable } from '@angular/core';
import { ECMIUniversal, PAIUniversal, DIPOCUniversal, GFIUniversal } from '@constants/coverages';
import { DIUniversal, GFHUniversal, CDC } from '@constants/coverages/coverages.constants';
import { GFCUniversal } from '../../constants/coverages/coverages.constants';
import { ProyeccionVidaInteligente } from '../../models/universales/proyeccionVidaInteligente';
import { CalculosUniversalesService } from './calculos-universales.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { CalculosProyeccionUniversalesComunService } from './calculos-proyeccion-universales-comun.service';

@Injectable({
  providedIn: 'root'
})
export class CalculosProyeccionUniversalesVipprService {

  arrCancelacion: number[] = [(ECMIUniversal.CANCELLATION_DATE + 1), (PAIUniversal.CANCELLATION_DATE + 1), (DIUniversal.CANCELLATION_DATE + 1),
  (DIPOCUniversal.CANCELLATION_DATE + 1), (GFIUniversal.CANCELLATION_DATE + 1),
  (GFHUniversal.CANCELLATION_DATE + 1), (GFCUniversal.CANCELLATION_DATE + 1), (CDC.CANCELLATION_DATE + 1)];

  constructor(
    private primas: CalculosUniversalesService,
    private opc: OpcionesPlanService,
    private comun: CalculosProyeccionUniversalesComunService
  ) {
    this.arrCancelacion = this.arrCancelacion.filter((v, i, a) => a.indexOf(v) === i);
  }

  async proyeccion() {
    this.comun.arrCargoAdq = [];
    this.comun.fx = -1;
    this.comun.arrQx = [];
    const arrProyec: ProyeccionVidaInteligente[] = [];
    try {
      let edadCalculada = this.opc.prospecto.mancomunado.mancomunado ? this.primas.edadEquivalenteCalculada :
      this.primas.edadCalculada.value;
      let edadReal = this.opc.prospecto.mancomunado.mancomunado ? this.primas.edadEquivalenteCalculada :
      this.opc.prospecto.age;
      // let edadReal = this.opc.prospecto.age;
      const inicio = edadReal;
      const fin = this.opc.plazoPago.value;
      let anio = 1;
      let primaBasica = this.primas.primaTotal.value;
      for (let i = inicio; i < fin; i++) {
        primaBasica = this.primaBasica(primaBasica, edadReal);
        const primaExcedente = this.comun.primaExcedente(anio);
        const primaTotal = this.comun.primaTotal(primaBasica, primaExcedente);
        const primaAcumulada = this.comun.primaAcumulada(primaTotal, anio === 1 ? 0 : arrProyec[anio - 2].primaAcumulada);
        const sumaAsegurada = this.sumaAsegurada();

        let fondoExcedente = anio === 1 ? 0 : arrProyec[anio - 2].fondoExcedente;
        fondoExcedente = this.comun.fondoExcedente(fondoExcedente, anio);
        let fondoBasico = anio === 1 ? 0 : arrProyec[anio - 2].fondoBasicoDec;
        let primasFondos = await this.comun.fondoBasico(primaBasica, sumaAsegurada, fondoBasico, anio, edadCalculada,fondoExcedente, edadReal,);
        const fondoBasicoDec = primasFondos.fondoBasico;
        fondoBasico = Math.max(primasFondos.fondoBasico, 0);                
        let fondoE = Math.max(primasFondos.fondoExcedente,0);       
        if (fondoBasico === 0 && anio > 3 && fondoE === 0) {
          break;
        }
        const valorEfectivo = this.comun.valorEfectivo(fondoBasico, fondoExcedente);
        const valorRescate = this.comun.valorRescate(primaBasica, fondoBasico, fondoExcedente, anio);
        const porcRec = this.comun.porcentajeRecuperacion(valorRescate, primaAcumulada);
        const benfFalle = this.comun.beneficioFallecimiento(sumaAsegurada, valorEfectivo, fondoExcedente);
        const proyec: ProyeccionVidaInteligente = {
          anioPoliza: anio,
          edad: edadReal,
          edadCalculo: edadCalculada,
          primaBasica: primaBasica,
          primaExcedente: primaExcedente,
          primaTotal: primaTotal,
          primaAcumulada: primaAcumulada,
          sumaAsegurada: sumaAsegurada,
          fondoBasico: primasFondos.fondoBasico,
          fondoExcedente: primasFondos.fondoExcedente,
          valorEfectivo: valorEfectivo,
          valorRescate: valorRescate,
          porcentajeRecuperacion: porcRec,
          beneficioFallecimiento: benfFalle,
          fondoBasicoDec: fondoBasicoDec
        };
        arrProyec.push(proyec);
        anio++;
        edadCalculada++;
        edadReal++;
      }
    } catch (error) {
      console.log(error);
    }

    return arrProyec;
  }

  private sumaAsegurada() {
    return this.primas.basica.value.sumaAsegurada;
  }

  private primaBasica(primaTotal: number, edadReal: number) {
    if (this.arrCancelacion.includes(edadReal)) {
      primaTotal = primaTotal - (edadReal === ECMIUniversal.CANCELLATION_DATE + 1 ? this.primas.ecmi.value.prima : 0);
      primaTotal = primaTotal - (edadReal === PAIUniversal.CANCELLATION_DATE + 1 ? this.primas.pai.value.prima : 0);
      primaTotal = primaTotal - (edadReal === DIUniversal.CANCELLATION_DATE + 1 ? this.primas.di.value.prima : 0);
      primaTotal = primaTotal - (edadReal === DIPOCUniversal.CANCELLATION_DATE + 1 ? this.primas.dipoc.value.prima : 0);
      primaTotal = primaTotal - (edadReal === GFIUniversal.CANCELLATION_DATE + 1 ? this.primas.gfi.value.prima : 0);
      primaTotal = primaTotal - (edadReal === GFHUniversal.CANCELLATION_DATE + 1 ? this.primas.gfh.value.prima : 0);
      primaTotal = primaTotal - (edadReal === GFCUniversal.CANCELLATION_DATE + 1 ? this.primas.gfc.value.prima : 0);
      primaTotal = primaTotal - (edadReal === CDC.CANCELLATION_DATE + 1 ? this.primas.cdc.value.prima : 0);
    }
    return primaTotal;
  }
}
