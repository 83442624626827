
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ShellComponent } from '@shell/shell.component';
import { FooterComponent } from '@shell/footer/footer.component';
import { HeaderComponent } from '@shell/header/header.component';
import { ProgressMenuComponent } from '@shell/progress-menu/progress-menu.component';
import { CotizadoresComponent } from '@shell/cotizadores/cotizadores.component';
import { ShellRoutingModule } from '@shell/shell.routing';
import { NotFoundComponent } from '@shell/not-found/not-found.component';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


import { CollapseModule } from 'ngx-bootstrap/collapse';

/**
* Shell Module
* @description Modulo principal de la cotización
*/
@NgModule({
  imports: [
    CommonModule,
    ShellRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    CollapseModule,
    TooltipModule.forRoot()
  ],
  declarations: [
    ShellComponent,
    FooterComponent,
    HeaderComponent,
    ProgressMenuComponent,
    NotFoundComponent,
    CotizadoresComponent
  ],
  exports: [
  ],
  providers: [
    AuthenticationService,
  ]
})
export class ShellModule { }
