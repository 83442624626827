import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { Router } from '@angular/router';
import { RoutesService } from '@app/services/routes/routes.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { environment } from '@env/environment';
import { ProtectionPlansGeneratorService } from '@app/services/protectionPlansGenerator/protection-plans-generator.service';
import { BaseDatosService } from '@app/services/baseDatos/base-datos.service';
import { NetworkStateService } from '@app/services/networkState/network-state.service';
import { BehaviorSubject } from 'rxjs';


/**
 * Clase del componente del landing
 * @export
 * @class LandingComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.sass'],
  providers: [
    {
      provide: CarouselConfig,
      useValue: {
        interval: 100000000000,
        showIndicators: false,
        noPause: true,
        noWrap: true
      }
    }
  ]
})
export class LandingComponent implements OnInit {
  /**
   * Referencia al modal
   * @property
   * @type {BsModalRef}
   */
  modalRef: BsModalRef;
  property: string;

  /**
   * Variable reactiva del estado de la red
   * @property
   * @type {BehaviorSubject<boolean>}
   * @memberof LandingComponent
   */
  online: BehaviorSubject<boolean> = this.network._state;

  imageLanding: String = '';

  /**
   * Creates an instance of LandingComponent.
   * @param {Router} router
   * @param {RoutesService} routes
   * @param {BsModalService} modalService
   * @param {AuthenticationService} auth
   * @param {ProtectionPlansGeneratorService} protectionPlans
   * @param {BaseDatosService} baseDatos
   * @param {NetworkStateService} network
   * @memberof LandingComponent
   */
  constructor(
    private router: Router,
    private routes: RoutesService,
    private modalService: BsModalService,
    private auth: AuthenticationService,
    private protectionPlans: ProtectionPlansGeneratorService,
    public baseDatos: BaseDatosService,
    public network: NetworkStateService
  ) {

    // Observable.fromEvent(navigator, '').subscribe(console.log);
  }

  ngOnInit() {
    // this.auth.token.subscribe(console.log);
    this.validaRuta();
    // this.imageLanding = '../../../assets/images/carousel/banner_vida_2.jpg';
    // this.getLandingImage();
  }
  /**
   * Abre modal
   * @method
   * @param template
   */
  openModal(template) {
    this.modalRef = this.modalService.show(template);
  }
  validaRuta() {
    // if ( this.router.navigate(['cotizador', 'landing'])) {
    //   console.log('entro y podria servir');
    //  } else {
    //    console.log('rayos');
    //  }
    // this.routes.chosenCotizador.subscribe(console.log);
  }

  continueFlow(flow: string, modal: BsModalRef, offline: BsModalRef): void {
     localStorage.setItem('planElegido', flow);
    if (flow === 'Aliados' && environment.activeRoles) {
      if (
        this.auth.user.value.roles.filter(
          rol => rol === 'Aliados+' || rol === 'Funcionario'
        ).length > 0
      ) {
        this.routes.chosenCotizador.next(flow);
        this.routes.firstTimeChoose.next(true);

        this.validOffline('DOTAL')
          ? this.router.navigate(['cotizador', flow, 'datos-prospecto'])
          : this.openModal(offline);
      } else {
        this.openModal(modal);
      }
    } else {
      this.routes.chosenCotizador.next(flow);
      this.routes.firstTimeChoose.next(true);

      this.validOffline(this.routes.offlinePlan(flow))
        ? this.router.navigate(['cotizador', flow, 'datos-prospecto'])
        : this.openModal(offline);
    }
  }

  validOffline(planABuscar: string): boolean {
    this.protectionPlans;
    return true;
  }

  changeProperty(): void {
      this.property = 'notChange';
  }

  async getLandingImage() {
    let resourceLanding = '';
    
    await this.baseDatos.getResourceLanding().then(function(doc) {
      resourceLanding = 'data:image/jpg;base64,' + doc;
    });
    this.imageLanding = resourceLanding;
  }
}
