// Injectable de Angular
import { Injectable } from '@angular/core';

// Módelos
import { CoverageFactors } from '@models/coverage-factors/coverage-factors';
import { FactorsSearch } from '@models/factors-search/factors-search.model';
import { ValueFactors } from '@models/value-factors/value-factors';
import { ReplicationModel, ReplicationMobile } from '@models/.';
import { ReplicationMobileExtend, MobileDatabases } from '../../models/replication/replication.model';
import { ReplicationMobileType } from '@models/replication/replication.model';
import { ValorGarantizado } from '@models/valor-garantizado/ValorGarantizado';




// Rxjs
import { BehaviorSubject, Observable, merge, of, fromEvent } from 'rxjs';
import {  mapTo } from 'rxjs/operators';

// Shared
import { AlertModalComponent } from '../../shared/modal/alert-modal/alert-modal.component';

// Ngx-bootstrap
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

// Constants
import { REPLICATION } from '../../constants/cloud/replication.constant';
import { PlanType } from '@constants/plan-options';

import * as moment from 'moment';

// Services
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';

// Environment
import { environment } from '../../../environments/environment';
import { PdfValuesService } from '../pdf-values/pdf-values.service';
import { PdfTarifasService } from '../pdf-tarifas/pdf-tarifas.service';
import { Plan } from '@constants/plan-options/plan.constant';
import { HttpClient } from '@angular/common/http';
import { ServicesApi } from '../services-api/services-api';

/**
 * Servicio de bases de datos
 *
 * @description Servicio encargado del manejo de peticiones con las base de datos
 * locales y remotas.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseDatosService {
  /**
   * Mensaje
   * @property
   * @type {string}
   */
  message: string;
  /**
   * Nueva cotización
   * @property
   * @type {number}
   */
  cotizacioNueva: number;
  /**
   * Folio
   * @property
   * @type {string}
   */
  folio: string;
  /**
   * Texto del Folio
   * @property
   * @type {string}
   */
  textFolio: string;
  /**
   * Contador
   * @property
   * @type {number}
   * @default
   */
  contador = 1;
  /**
   * Cotización guardada. Se crea una una instancia reactiva
   * @property
   * @type {BehaviorSubject<boolean>}
   * @default "false"
   */
  cotizacionGuardada = new BehaviorSubject<boolean>(false);
  /**
   * Bases de datos permitidas.
   * @property
   */
  databasesAllowed: { plan: PlanType; terms: number[] }[];

  // *Bases de datos para desktop
  /**
   *Bases de datos por replicar
   *@property
   *@type {BehaviorSubject<ReplicationMobileExtend[]>}
   */
  databasesToReplicate = new BehaviorSubject<ReplicationMobileExtend[]>([]);

  // * Bases de datos para mobile (android/ios)
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 1 año.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_1 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 5 años.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_5 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 10 años.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_10 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 15 años.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_15 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 20 años.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_20 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 25 años.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_25 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 60 años alcanzados.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_60 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 65 años alcanzados.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_65 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Vida Pagos Limitados, plazo: 70 años alcanzados.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_VPL_70 = new BehaviorSubject<number>(0);
  percentaje_TEMP_1 = new BehaviorSubject<number>(0);
  percentaje_TEMP_5 = new BehaviorSubject<number>(0);
  percentaje_TEMP_10 = new BehaviorSubject<number>(0);
  percentaje_TEMP_15 = new BehaviorSubject<number>(0);
  percentaje_TEMP_20 = new BehaviorSubject<number>(0);
  percentaje_TEMP_25 = new BehaviorSubject<number>(0);
  percentaje_TEMP_60 = new BehaviorSubject<number>(0);
  percentaje_TEMP_65 = new BehaviorSubject<number>(0);
  percentaje_TEMP_70 = new BehaviorSubject<number>(0);
  percentaje_OV = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Aliados+, plazo: 15 años.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_DOTAL_15 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Aliados+, plazo: 20 años.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_DOTAL_20 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Aliados+, plazo: 65 años alcanzados.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  percentaje_DOTAL_65 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Protección Efectiva
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   * @memberof BaseDatosService
   */
  percentaje_PR_EF = new BehaviorSubject<number>(0);
  /**
   * Porcentaje Protecáncer
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   * @memberof BaseDatosService
   */
  percentaje_PR_CA = new BehaviorSubject<number>(0);

  /**
   * Aliados+ Kids/ plazo: 18 y 22 años/ Básica y Valores Garantizados --  DB  REMOTA
   * @property
   * @private
   */

  /** Modal de información */
  modalRef: BsModalRef;
  /**
   * Prueba del folio.
   * @property
   * @type {BehaviorSubject<string>}
   * @default "' '"
   */
  folioTest = new BehaviorSubject<string>('');
  /**
   * Valor anterior.
   * @property
   * @type {BehaviorSubject<ValorGarantizado[]>}
   */
  beforeValue = new BehaviorSubject<ValorGarantizado[]>([]);
  /**
   * Datos totales.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  totalData = new BehaviorSubject<number>(0);
  /**
   * Contador de datos.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  countData = new BehaviorSubject<number>(0);
  /**
   * Total de datos locales
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  totalLocalData = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de datos totales.
   * @property
   * @type {BehaviorSubject<number>}
   * @default "0"
   */
  totalDataPercentage = new BehaviorSubject<number>(0);
  /**
   * DB Remota -- Mujer No Fumadora.
   * @property
   * @type {number}
   */
  db_MNF_remote_total: number;
  /**
   * Variable de estado.
   * --> True si no hay ninguna base de datos replicada, false si hay alguna.
   * @property
   * @type {boolean}
   */
  noData: boolean;
  /**
   * Tamaño del lote
   * @property
   */
  batchSize = REPLICATION.X3;
  /**
   * Tipo de conexión.
   * @property
   */
  connection_Type: string;
  /**
   * Mostrar antes - Variable de estado (observable).
   * @property
   * @type {BehaviorSubject<boolean>}
   * @default "false"
   */
  showBefore = new BehaviorSubject<boolean>(false);
  /**
   * Historial de replicado.
   * @property
   * @type {ReplicationMobile[]}
   */
  replicatedHistory: ReplicationMobile[];

  // Plazos de pago
  /**
   * Plazos de pago
   * @property
   * @type {number[]}
   */
  plazosPago: number[] = [];
  /**
   * Bases de datos replicadas en mobile.
   * @property
   * @type {MobileDatabases}
   */
  mobileDatabasesReplicated: MobileDatabases;
  /**
   * Cola de Descargas para Mobile
   *
   * @memberof BaseDatosService
   */
  downloadQueue = new BehaviorSubject<ReplicationModel[]>([]);

    /**
   * Índice de coberturas.
   * @property
   * @private
   
     private coberturasIndex = {
      index: {
        fields: ['Edad', 'Moneda', 'Plan', 'PlazoSeguro', 'PlazoPago'],
        ddoc: 'coberturas-index',
        type: 'json'
      }
    };*/
  /**
   * Índice de coberturas en mobile.
   * @property
   */
  coberturasIndexMobile = {
    index: {
      fields: [
        'Edad',
        'Moneda',
        'Plan',
        'PlazoPago',
        'PlazoSeguro',
        'Sexo',
        'Fumador'
      ]
    }
  };

  /**
   * Índice de valores en mobile.
   * @property
   */
  valoresIndexMobile = {
    index: {
      fields: [
        'Edad',
        'Moneda',
        'Esquema',
        'Plan',
        'PlazoSeguro',
        'PlazoPago',
        'Sexo',
        'Fumador'
      ]
    }
  }
  
  /**
   * Estado de la primera replicación.
   * @property
   * @type {boolean}
   * @default "true"
   */
  firstReplication = true;
  /**
   *Observable que detecta el estado de conexión.
   * @type {Observable<boolean>}
   */
  online$: Observable<boolean>;
  /**
   * Online - Variable de estado.
   * @type {boolean}
   */
  online: boolean;

  private pdfS: PdfValuesService | PdfTarifasService;
  private forceDownloadOnce = new BehaviorSubject<boolean>(true);


  /**
   * Crea una instancia de BaseDatosService.
   * @param {ProtectionPlansGeneratorService} ProtectionPlansGenerator
   * @param {BsModalService} modalService
   * @param {DeviceTypeService} device
   */
  constructor(
    private ProtectionPlansGenerator: ProtectionPlansGeneratorService,
    private modalService: BsModalService,
    private device: DeviceTypeService,
    private http: HttpClient,
    private api:ServicesApi
  ) {
    /** Se inicializa el estado a internet*/
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(true))
    );

    this.online$.subscribe(result => {
      this.online = true;
    });

    /**
     * Se establece la localización horaria
     * @property
     * @default "es"
     */
    moment.locale('es');

    this.modalService.onHide
      .takeWhile(v => this.forceDownloadOnce.value)
      .subscribe(() => {
        if (
          this.modalService.config.class === 'alert-modal-pdfvg' &&
          (this.device.getDeviceType() === 'iPad' ||
            this.device.getDeviceType() === 'iPhone')
        ) {
          this.pdfS.generatePDF(this.folioTest.value);
          this.forceDownloadOnce.next(false);
        }
      });
  }

  /**
   * Obtiene los factores de coberturas desde la base de datos remota
   * @async
   * @returns Promise<CoverageFactors[]>
   * @memberof BaseDatosService
   */
  async generarFactoresCoberturas(
    params: FactorsSearch
  ): Promise<CoverageFactors[][]> {
    return await new Promise<CoverageFactors[][]>(async (resolve, reject) => {
      const isFlat = params.tipoPlan === 'OV' || params.plan === 'AxaParaTodos';
      this.ProtectionPlansGenerator.sugerenciaPlazos(params.plazoPago, isFlat);
      this.plazosPago = this.ProtectionPlansGenerator.plazosSugeridos.value;
      let moneda = 'UDI';

      switch (params.moneda) {
        case 'MXN':
          moneda = 'PESOS';
          break;
        case 'UDI':
          moneda = 'UDI';
          break;
        case 'USD':
          moneda = 'DOLARES';
          break;
      }

      let plazoSeguro: string | Object = String(params.plazoSeguro);
      let plazoPago: string | Object = {
        $in: [
          String(this.plazosPago[0]),
          String(this.plazosPago[1]),
          String(this.plazosPago[2])
        ]
      };

      switch (params.tipoPlan) {
        case 'TEMPORAL':
          plazoSeguro = {
            $in: [
              String(this.plazosPago[0]),
              String(this.plazosPago[1]),
              String(this.plazosPago[2])
            ]
          };
          break;
        case 'VPL':
          plazoSeguro = String(params.plazoSeguro);
          break;
        case 'DOTAL':
          plazoSeguro = {
            $in: [
              String(this.plazosPago[0]),
              String(this.plazosPago[1]),
              String(this.plazosPago[2])
            ]
          };
          plazoPago = {
            $in: [
              String(this.plazosPago[0]),
              String(this.plazosPago[1]),
              String(this.plazosPago[2])
            ]
          };
          break;
        case 'OV':
          plazoPago = '99';
          plazoSeguro = '99';
          break;
      }
      let query = {};
      let result: any;
      switch (params.tipoPlan) {
        case 'PROTECANCER':
          query = {
            selector: {
              Sexo: params.sexo,
              Fumador: params.fumador
            },
            fields: ['Plan', 'Edad', 'Tarifa', 'Cobertura', 'PlazoPago'],
            limit: 100
          };
          /**Se suscribe al servicio */
          this.api.getServiceCancer(query).subscribe(res => {
            result = this.resultAsig(res);
            resolve(result);
          })
          break;
        case 'PROTECCION EFECTIVA':
          query = {
            selector: {
              Sexo: params.sexo
            },
            limit: 100
          };
          this.api.getServiceEfect(query).subscribe(res => {
            result = this.resultAsig(res);
            resolve(result);
          })
          break;
        case 'ALIADOSKIDS':
          query = {
            selector: {
              PlazoSeguro: String(params.plazoSeguro),
              Sexo: { $in: ['A', params.sexo] },
              Fumador: { $in: ['A', params.fumador] },
              EdadMenor: String(params.edadMenor),
              Edad: { $in: ['0', String(params.edad)] }
            },
            fields: ['Plan', 'Cobertura', 'PlazoPago', 'EdadMenor', 'Edad', 'Tarifa'],
            limit: 100
          };
          this.api.getServiceKidsBa(query).subscribe(res => {
            result = this.resultAsig(res);
            resolve(result);
          });
          break;
        default:
          query = {
            selector: {
              Edad: params.mancomunado.mancomunado
                ? {
                  $in: [
                    String(params.edad),
                    String(params.mancomunado.edadEquivalente)
                  ]
                }
                : String(params.edad),
              Moneda: moneda,
              Plan: params.tipoPlan, 
              PlazoSeguro: plazoSeguro,
              PlazoPago: plazoPago,
              Fumador:params.fumador,
              Sexo:params.sexo
            },
            fields: ['Edad', 'PlazoPago', 'Cobertura', 'Tarifa'],
            limit: 100
          };
          this.api.getServiceBA(query).subscribe(res => {
            result = this.resultAsig(res);
            resolve(result);
          });
          break;
      }
    
      /** const baseDeDatos: = this.getRemoteDB(params.sexo, params.fumador,(params.tipoPlan === 'PROTECCION EFECTIVA' || params.tipoPlan === 'PROTECANCER'), 'ba', params.tipoPlan as PlanType)*/

    });
  }


  async generarFactoresMujerUnica(params: FactorsSearch) {
    return await new Promise(async (resolve, reject) => {
      let moneda = 'UDI';
      switch (params.moneda) {
        case 'MXN':
          moneda = 'PESOS';
          break;
        case 'UDI':
          moneda = 'UDI';
          break;
        case 'USD':
          moneda = 'DOLARES';
          break;
      }     

      const query = {
        selector: {
          $and: [
            { Edad: params.edad },
            { Sexo: params.sexo },
            { Fumador: params.fumador },
            { PlazoPago: params.plazoPago.toString() },
            { Moneda: moneda },
            {
              Plan: {
                '$in': ['TEMPORALMU', 'VPL']
              }
            }
          ]
        },
        fields: ['Edad', 'Cobertura', 'Tarifa', 'Plan'],
        limit: 100,
        execution_stats: true
      };

      try {
        let result: any;
       await this.api.getServiceBA(query).subscribe(res =>{
           result = res;
        });

          resolve([result.docs.filter(v => v['Plan'] === 'VPL' && v['Cobertura'] === 'BASICA'),
          result.docs.filter(v => v['Plan'] === 'TEMPORALMU')/* cdcRes.docs, cdcDiezMas.docs*/]);
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Obtiene el factor de la cobertura desde la base de datos remota para propuestas
   * @async
   * @returns Promise<CoverageFactors[]>
   * @memberof BaseDatosService
   */
  async obtenerFactoresCoberturas(
    params: FactorsSearch
  ): Promise<CoverageFactors[]> {
    return await new Promise<CoverageFactors[]>((resolve, reject) => {
      let moneda = 'UDI';
      switch (params.moneda) {
        case 'MXN':
          moneda = 'PESOS';
          break;
        case 'UDI':
          moneda = 'UDI';
          break;
        case 'USD':
          moneda = 'DOLARES';
          break;
      }
      let query = {};
      let result: any;
      switch (params.tipoPlan) {
        case 'PROTECANCER':
          query = {
            selector: {
              Sexo: params.sexo,
              Fumador: params.fumador
            },
            fields: ['Plan', 'Edad', 'Tarifa', 'Cobertura', 'PlazoPago'],
            limit: 100
          };
          this.api.getServiceCancer(query).subscribe(res => {
            result = res;
            resolve(result.docs);
          })
          break;
        case 'PROTECCION EFECTIVA':
          query = {
            selector: {
              Sexo: params.sexo
            },
            limit: 100
          };
          this.api.getServiceEfect(query).subscribe(res => {
            result = res;
            resolve(result.docs);
          })
          break;
        case 'ALIADOSKIDS':
          query = {
            selector: {
              Edad: params.mancomunado.mancomunado
                ? {
                  $in: [
                    String(params.edad),
                    String(params.mancomunado.edadEquivalente)
                  ]
                }
                : String(params.edad),
              Moneda: moneda,
              Plan: params.tipoPlan, 
              PlazoPago: String(params.plazoPago)
            },
            fields: ['Edad', 'PlazoPago', 'Cobertura', 'Tarifa'],
            limit: 100
          };
          this.api.getServiceKidsBa(query).subscribe(res => {
            result = res;
            resolve(result.docs);
          });
          break;
        default:
          query = {
            selector: {
              Edad: params.mancomunado.mancomunado
                ? {
                  $in: [
                    String(params.edad),
                    String(params.mancomunado.edadEquivalente)
                  ]
                }
                : String(params.edad),
              Moneda: moneda,
              Plan: params.tipoPlan, 
              PlazoPago: String(params.plazoPago),
              Fumador:params.fumador,
              Sexo:params.sexo
            },
            fields: ['Edad', 'PlazoPago', 'Cobertura', 'Tarifa'],
            limit: 100
          };
          this.api.getServiceBA(query).subscribe(res => {
            result = res;
            resolve(result.docs);
          });
          break;
      }
     
    });
  }

  async generarFactoresCoberturasINPC(params: FactorsSearch) {
    return await new Promise(async (resolve, reject) => {
      let moneda = 'UDI';
      switch (params.moneda) {
        case 'MXN':
          moneda = 'PESOS';
          break;
        case 'UDI':
          moneda = 'UDI';
          break;
        case 'USD':
          moneda = 'DOLARES';
          break;
      }


      const edades = Array(
        params.tipoPlan !== 'TEMPORAL' ?
          Number(params.plazoSeguro) - Number(params.edad) : Number(params.plazoSeguro)
      ).fill(Number(params.edad))
        .map((a, b) => String(a + b));

      if (params.mancomunado.mancomunado) {
        const edadM = params.mancomunado.edadEquivalente;
        for (let i = edadM; i <= 70; i++) {
          edades.push(String(i));
        }
      }
      let result:any;
      try {
        if(params.tipoPlan === 'ALIADOSKIDS') {
          const query = {
            selector: {
              $and: [
                { Edad: { $in: edades } },
                { Fumador: params.fumador },
                { PlazoPago: params.plazoPago.toString() },
                { Moneda: moneda },
                { Plan: params.tipoPlan },
              ]
            },
            fields: ['Edad', 'PlazoPago', 'Cobertura', 'Tarifa'],
            execution_stats: true
          };
          await this.api.getServiceKidsBa(query).subscribe(res =>{
            result = res;
          });

        } else {
          const query = {
            selector: {
              $and: [
                { Edad: { $in: edades } },
                { Fumador: params.fumador },
                { PlazoPago: params.plazoPago.toString() },
                { Moneda: moneda },
                { Plan: params.tipoPlan },
                { Sexo:params.sexo }
              ]
            },
            fields: ['Edad', 'PlazoPago', 'Cobertura', 'Tarifa'],
            execution_stats: true
          };
          await this.api.getServiceBA(query).toPromise().then(res=> {
             result = res;
          });
        }
        resolve(result.docs);
      } catch (err) {
        reject(err);
      }
    });
  }

  async getEquivalentAge(age1: string, age2: string, plan: Plan): Promise<any> {
    const query = {
      selector: {
        EdadHorizontal: age1,
        EdadVertical: age2
      },
      fields: ['EdadEquivalente']
    };
      if (plan === 'Universales') {
        return await this.api.getServiceAgesUniversal(query).toPromise().then(res => {
          return res;
        })
      } else {
        return await this.api.getServiceAges(query).toPromise().then(res => {
          return res;
        })
      }
  }

  async getAliadosMas65Deducible(
    gender: string,
    habit: string,
    age: string
  ): Promise<any> {
    const query = {
      selector: {
        Plan: 'Aliados+',
        Moneda: 'UDI',
        PlazoPago: '65',
        Sexo: gender,
        Fumador: habit,
        Edad: age
      },
      fields: ['Factor']
    };
    /*return this.api.getDeductible(query).subscribe((res:any) => {
      return res;

    })/** */
    return new Promise(resolve=>{
      this.api.getDeductible(query).subscribe((res:any) => {
        resolve( res);
  
      })
    })
  }

  /**
   * Método que genera los factores de Valores Garantizados.
   * @async
   * @method
   * @param {FactorsSearch} paryams
   * @returns {Promise<ValueFactors[][]>}
   * @memberof BaseDatosService
   */
  async generarFactoresValoresG(
    params: FactorsSearch
  ): Promise<ValueFactors[][]> {
    return await new Promise<ValueFactors[][]>((resolve, reject) => {
      const edad = params.mancomunado.mancomunado
        ? params.mancomunado.edadEquivalente
        : params.edad;
      const isFlat = params.tipoPlan === 'OV';

      this.ProtectionPlansGenerator.sugerenciaPlazos(params.plazoPago, isFlat);
      this.plazosPago = this.ProtectionPlansGenerator.plazosSugeridos.value;

      let moneda = 'UDI';
      let plazoSeguro: object | string;
      let plazoPago: object | string;
      switch (params.moneda) {
        case 'MXN':
          moneda = 'PESOS';
          break;
        case 'UDI':
          moneda = 'UDI';
          break;
        case 'USD':
          moneda = 'DOLARES';
          break;
      }

      switch (
      params.tipoPlan // 'TEMPORAL' | 'OV' | 'VPL' | 'DOTAL';
      ) {
        case 'TEMPORAL':
          plazoSeguro = {
            $in: [
              String(this.plazosPago[0]),
              String(this.plazosPago[1]),
              String(this.plazosPago[2])
            ]
          };
          plazoPago = {
            $in: [
              String(this.plazosPago[0]),
              String(this.plazosPago[1]),
              String(this.plazosPago[2])
            ]
          };
          break;
        case 'OV':
          plazoSeguro = '99';
          plazoPago = '99';
          break;
        case 'DOTAL':
          plazoSeguro = {
            $in: [
              String(this.plazosPago[0]),
              String(this.plazosPago[1]),
              String(this.plazosPago[2])
            ]
          };
          plazoPago = {
            $in: [
              String(this.plazosPago[0]),
              String(this.plazosPago[1]),
              String(this.plazosPago[2])
            ]
          };
          break;
        default:
          plazoSeguro = String(params.plazoSeguro);
          plazoPago = {
            $in: [
              String(this.plazosPago[0]),
              String(this.plazosPago[1]),
              String(this.plazosPago[2])
            ]
          };
      }

      const esquema = String(params.esquema).toUpperCase();
      let query = {};
      let queryVG = {};
        if( params.tipoPlan !== 'ALIADOSKIDS'){
          query = {
            selector: {
              Edad: String(edad),
              Moneda: moneda,
              Esquema: esquema,
              Plan: params.tipoPlan === 'MUJER UNICA' ? 'TEMPORALMU' : params.tipoPlan,
              PlazoSeguro: params.tipoPlan === 'MUJER UNICA' ? '65' : plazoSeguro,
              PlazoPago: plazoPago,
              Fumador:params.fumador,
              Sexo:params.sexo
            },
            fields: ['PlazoPago', 'AnioPoliza', 'Factor', 'Plan'],
            limit: 243
          };
          queryVG = {
            selector: {
              Edad: String(edad),
              Moneda: moneda,
              Esquema: esquema,
              Plan: params.tipoPlan === 'MUJER UNICA' ? 'TEMPORALMU' : params.tipoPlan, 
              PlazoSeguro: params.tipoPlan === 'MUJER UNICA' ? '65' : plazoSeguro,
              PlazoPago: plazoPago,
              Fumador:params.fumador,
              Sexo:params.sexo
            },
            fields: ['PlazoPago', 'AnioPoliza', 'Factor', 'Plan'],
            limit: 243
          };
        }else{
          query = {
            selector: {
              EdadMenor: String(params.edadMenor),                        
              Plan: 'Dotal',
              PlazoPago: plazoPago
            },
            fields: ['PlazoPago', 'AnioPoliza', 'Factor', 'Plan'],
            limit: 243
          };

        }  
     let result :any;
      switch (params.tipoPlan) {
        case 'PROTECCION EFECTIVA':
          this.api.getServiceEfect(query).subscribe(res => {
            result = this.generarFactor(res);
            resolve(result); 
          })
          break;
        case 'PROTECANCER':
          this.api.getServiceCancer(query).subscribe(res => {
            result = this.generarFactor(res);
            resolve(result); 
          })
          break;
        case 'ALIADOSKIDS':
          this.api.getServiceKidsvg(query).subscribe(res => {
            result = this.generarFactor(res);
            resolve(result); 
          })
          break;
        default:
          this.api.getServiceVG(queryVG).subscribe(res =>{
            result = this.generarFactor(res);
            resolve(result);
          });          
          break;
      } 
          
    });
  }
/**
 * Esta funcion es dependiente de generarFactoresValoresG()
 * @param {*} docs
 * @return {*} 
 * @memberof BaseDatosService
 */
async generarFactor(response: any) {
    return await new Promise<ValueFactors[][]>((resolve, reject) => {
      const factoresAnio_Sugerido1: any[] = response.docs.filter(
        item => item['PlazoPago'] === String(this.plazosPago[1])
      ),
        factoresAnio_Elegido: any[] = response.docs.filter(
          item => item['PlazoPago'] === String(this.plazosPago[0])
        ),
        factoresAnio_Sugerido2: any[] = response.docs.filter(
          item => item['PlazoPago'] === String(this.plazosPago[2])
        );

      resolve([
        factoresAnio_Elegido,
        factoresAnio_Sugerido1,
        factoresAnio_Sugerido2
      ]);
    });
  }
  /**
   * Método que genera los factores de Valores Garantizados en las Propuestas.
   * @async
   * @method
   * @param {FactorsSearch} params
   * @returns {Promise<ValueFactors[]>}
   * @memberof BaseDatosService
   */
  async generarFactoresValoresGPropuestas(
    params: FactorsSearch
  ): Promise<ValueFactors[]> {
    return await new Promise<ValueFactors[]>((resolve, reject) => {
      const edad = params.mancomunado.mancomunado
        ? params.mancomunado.edadEquivalente
        : params.edad;
      let moneda = 'UDI';
      const plazoPago = String(params.plazoPago);

      switch (params.moneda) {
        case 'MXN':
          moneda = 'PESOS';
          break;
        case 'UDI':
          moneda = 'UDI';
          break;
        case 'USD':
          moneda = 'DOLARES';
          break;
      }
      const esquema = String(params.esquema).toUpperCase();
      let query = {};
      let queryVG = {};
        query = {
          selector: {
            Edad: String(edad),
            Moneda: moneda,
            Esquema: esquema,
            Plan: params.tipoPlan,
            PlazoPago: plazoPago
          },
          fields: ['PlazoPago', 'AnioPoliza', 'Factor'],
          limit: 243
        };
      queryVG = {
          selector: {
            Edad: String(edad),
            Moneda: moneda,
            Esquema: esquema,
            Plan: params.tipoPlan,
            // 'PlazoSeguro': plazoSeguro,
            PlazoPago: plazoPago,
            Fumador:params.fumador,
            Sexo:params.sexo
          },
          fields: ['PlazoPago', 'AnioPoliza', 'Factor'],
          limit: 243
        };

        let result:any;
        switch (params.tipoPlan) {
          case 'PROTECCION EFECTIVA':
            this.api.getServiceEfect(query).subscribe(res => {
              result = res;
              resolve(result.docs);
            })
            break;
          case 'PROTECANCER':
            this.api.getServiceCancer(query).subscribe(res => {
              result = res;
              resolve(result.docs);
            })
            break;
          case 'ALIADOSKIDS':
            this.api.getServiceKidsvg(query).subscribe(res => {
             result= res;
             resolve(result.docs);
            })
            break;
          default:
            
            this.api.getServiceVG(queryVG).subscribe(res =>{
             result = res;
             resolve(result.docs);
            });
            break;
        } 
        
    });
  }

  async generarFactoresValoresGMujerUnica(params: FactorsSearch) {
    return await new Promise(async (resolve, reject) => {
      let moneda = 'UDI';
      switch (params.moneda) {
        case 'MXN':
          moneda = 'PESOS';
          break;
        case 'UDI':
          moneda = 'UDI';
          break;
        case 'USD':
          moneda = 'DOLARES';
          break;
      }

      const query = {
        selector: {
          $and: [
            { Edad: params.edad },
            { Sexo: params.sexo },
            { Fumador: params.fumador },
            { PlazoPago: params.plazoPago.toString() },
            { Moneda: moneda },
            {
              Plan: {
                '$in': ['TEMPORALMU', 'VPL']
              }
            },
            { Esquema: params.esquema.toUpperCase() }
          ]
        },
        fields: ['Edad', 'Cobertura', 'Factor', 'Plan', 'PlazoPago', 'AnioPoliza'],
        execution_stats: true
      };
      try {
        this.api.getServiceVG(query).subscribe(res => {
          const result: any = res;
          resolve([result.docs.filter(v => v['Plan'] === 'VPL'),
          result.docs.filter(v => v['Plan'] === 'TEMPORALMU')]);
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  }

  /**
   * Método que se encarga de generar el folio de la cotización en base de las cotizaciones existentes.
   * @async
   * @method
   * @param doc
   * @param pdfView
   */
  async guardarCotizacion(
    doc: any,
    offlineSaved?: boolean,
    pdfS?: PdfValuesService | PdfTarifasService
  ) {
    this.pdfS = pdfS;


      try {
        let urlU = `${environment.services}/save/quote`;

        this.http.post(urlU, doc).subscribe((response : any) => {
          if(!response.status)
          {
            this.message = ' Cotización guardada con éxito';
            this.textFolio = 'Folio: ' + this.folio;
            this.closeModal(false);
          }
          else
          {
            this.message = 'Error al guardar la cotización';
            this.closeModal(false);
            this.cotizacionGuardada.next(false);
          }

        });

      } catch (error) {
        this.message = 'Error al guardar la cotización';
        this.closeModal(false);
        this.cotizacionGuardada.next(false);
      }

  }

  async generateFolio(tipoPlan?: string) {
    let folioBase = 'ProTGT000000';
    if (tipoPlan !== 'ProTGT') {
      folioBase = tipoPlan + '000000';
    }
      try {
        let urlFolio = `${environment.services}/info/folio-quote`;

        await this.http.post(urlFolio,null).toPromise().then((response: any) => {
          let folio = response.response.lastFolio.toString();
          if( String(folio).length <= 6 ){
            this.folio = `${folioBase.substring(0, 6)}${folioBase.substring(
              6, 6 + (6 - String(folio).length))}${Number(folio) + 1}`;
          }else{
            this.folio = `${folioBase.substring(0, 6)}${Number(folio) + 1}`;
          }

          return this.folio;
        });/** */
        

        this.folioTest.next(this.folio);
        return this.folio;
      } catch (error) {
        throw new Error(error);
      }
  }

  /**
   * Método que se encarga de mostrar el modal al iniciar la replicación de las bases de datos en local.
   * @method
   * @param replica
   */
  openModal(replica: boolean) {
    if (replica) {
      this.ProtectionPlansGenerator.title =
        'COMENZAREMOS CON LA DESCARGA DE TARIFA EN TU NAVEGADOR, TE AVISAREMOS EN CUANTO HAYA TERMINADO.';
    } else {
      this.ProtectionPlansGenerator.title = this.message;
    }
    this.modalRef = this.modalService.show(AlertModalComponent, {
      backdrop: false,
      class: 'alert-modal'
    });
  }

  /**
   * Método que se encarga de cerrar el modal al finalizar la replicación de las bases de datos en local.
   */
  closeModal(replica: boolean) {
    if (replica) {
      this.ProtectionPlansGenerator.title = `HEMOS FINALIZADO LA DESCARGA DE TARIFA EN TU NAVEGADOR.
        AHORA PUEDES USAR EL COTIZADOR SIN CONEXIÓN A INTERNET. FECHA DE ACTUALIZACIÓN : ${moment().format(
        'DD/MM/YYYY'
      )}`;

      localStorage.setItem(
        'expiration',
        String(`${moment().format('YYYYMM')}${moment().daysInMonth()}`)
      );
    } else {
      this.ProtectionPlansGenerator.strFolio = this.textFolio;
      this.ProtectionPlansGenerator.title = this.message;
    }
    this.modalRef = this.modalService.show(AlertModalComponent, {
      backdrop: false,
      class: 'alert-modal-pdfvg'
    });
  }


  /**
   * Elimina de local storage el estado de la base de datos cuando ya no se requiere.
   * @method
   * @param {ReplicationMobileType} plan
   * @memberof BaseDatosService
   */
  eraseCompleteDatabase(plan: ReplicationMobileType) {
    localStorage.removeItem(String(plan));
  }

  /**
   * Obtiene los datos completos de la BD guardada en local storage.
   * @method
   * @param {ReplicationMobileType} plan
   * @returns {boolean}
   * @memberof BaseDatosService
   */
  getCompleteDatabase(plan: ReplicationMobileType): boolean {
    const DBstate = localStorage.getItem(String(plan));
    return DBstate !== null;
  }
  
  private resultAsig(response:any){
    return new Promise<CoverageFactors[][]>(async (resolve, reject) => {
    let factoresAnio_Sugerido1: any[] = response.docs.filter(
      item => item['PlazoPago'] === String(this.plazosPago[1])
    ),
      factoresAnio_Elegido: any[] = response.docs.filter(
        item => item['PlazoPago'] === String(this.plazosPago[0])
      ),
      factoresAnio_Sugerido2: any[] = response.docs.filter(
        item => item['PlazoPago'] === String(this.plazosPago[2])
      );
    switch (true) {
      // Si es penultimo plazo
      case this.ProtectionPlansGenerator.esPenultimoPlazo.value:
        (factoresAnio_Sugerido1 = response.docs.filter(
          item => item['PlazoPago'] === String(this.plazosPago[0])
        )),
          (factoresAnio_Elegido = response.docs.filter(
            item => item['PlazoPago'] === String(this.plazosPago[1])
          ));
        break;
      // Si es ultimo plazo
      case this.ProtectionPlansGenerator.esUltimoPlazo.value:
        (factoresAnio_Sugerido1 = response.docs.filter(
          item => item['PlazoPago'] === String(this.plazosPago[0])
        )),
          (factoresAnio_Elegido = response.docs.filter(
            item => item['PlazoPago'] === String(this.plazosPago[2])
          )),
          (factoresAnio_Sugerido2 = response.docs.filter(
            item => item['PlazoPago'] === String(this.plazosPago[1])
          ));
        break;
    }
    resolve([factoresAnio_Elegido,
      factoresAnio_Sugerido1,
      factoresAnio_Sugerido2])

  })
}


  /**
* MÉTODO QUE OBTIENE DE LAS BASE DE DATOS LOS FACTORES PARA LAS COBERTURAS
* QUE REQUIEREN UNA NUEVA PRIMA CADA 10 ANIOS
* @param {FactorsSearch} params
* @returns {[]}
* @memberof BaseDatosService
*/
  async generarFactoresDiezMas(params: FactorsSearch) {
    return await new Promise(async (resolve, reject) => {
      const moneda = params.moneda === 'MXN' ? 'PESOS' : params.moneda === 'USD' ? 'DOLARES' : params.moneda;
      const query = {
        selector: {
          $and: [
            {
              Edad: {
                '$in': params.arrDiezMas
              }
            },
            { Sexo: params.sexo },
            { Fumador: params.fumador },
            { PlazoPago: params.plazoPago.toString() },
            { Moneda: moneda },
            { Plan: params.tipoPlan }
          ]
        },
        fields: ['Plan', 'Edad', 'Tarifa', 'Cobertura', 'PlazoPago'],
        limit: 100,
        execution_stats: true
      };
      try {
        switch (params.tipoPlan) {
          case 'PROTECCION EFECTIVA':
            this.api.getServiceEfect(query).subscribe(res=>{
              const result: any = res;
              resolve([result.docs])
            });
            break;
            case 'PROTECANCER':
            this.api.getServiceCancer(query).subscribe(res => {
              const result: any = res;
              resolve([result.docs])
            });
              break;
              case 'ALIADOSKIDS':
                this.api.getServiceKidsBa(query).subscribe(res => {
                  const result: any = res;
                  resolve([result.docs])
                })
                break;
        
          default:
            this.api.getServiceBA(query).subscribe(res=> {
              const result: any = res;
              resolve([result.docs])
            })
            break;
        }
        } catch (err) {
        reject(err);
      }
    });
  }

  async getResourceLanding(): Promise<string> {
    return await new Promise<any>(resolve => {
      let response = '';
      resolve(String(response));
    });
  }
}
