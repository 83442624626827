/** Angular Core */
import { Injectable } from '@angular/core';

/**
 * Servicio que provee utilidades relacionadas con manejo de fechas
 * Utilidades de fecha.
 * @description Servicio que provee utilidades relacionadas con manejo de fechas
 */
@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  /**
   * Días en el mes
   * Retorna arreglo númerico, en donde opcionalmente cada posición puede tener un
   * numero representativo del día de la semana. 0 - Lunes, 6 - Domingo.
   *
   * @param number year - Año a evaluar
   * @param number month - Mes a evaluar
   * @param boolean weekly - Introducir el número representativo de la semana
   * @returns number[]
   * @memberof DateUtilsService
   */
  getDaysInMonth(year: number, month: number, weekly?: boolean): number[] {
    month = month + 1;
    const numOfDays = new Date(year, month, 0).getDate();
    let days: number[] = [];
    if (weekly) {
      for (let i = 0; i < numOfDays; i++) {
        days = [...days, new Date(year, month - 1, i + 1).getDay()];
      }
    } else {
      for (let i = 0; i < numOfDays; i++) {
        days = [...days, i + 1];
      }
    }
    return days;
  }

  /**
   * Día actual
   * Obtiene el día actual empezando desde 1
   *
   * @returns {number}
   * @memberof DateUtilsService
   */
  getActualDay(): number {
    return new Date(Date.now()).getDate();
  }

  /**
   * Mes Actual
   * Retorna el número de mes actual empezando desde 0
   * 0 -> Enero, 11 -> Diciembre
   *
   * @returns {number}
   * @memberof DateUtilsService
   */
  getActualMonth(): number {
    return new Date(Date.now()).getMonth();
  }

  /**
   * Año actual
   * Retorna el año actual
   *
   * @returns {number}
   * @memberof DateUtilsService
   */
  getActualYear(): number {
    return new Date(Date.now()).getFullYear();
  }

}
