import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Componente
import { TemplateComponent } from '@components/template/template.component';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule
  ],
  declarations: [
    TemplateComponent
  ]
})
export class TemplateModule { }
