/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { Plan } from '@constants/plan-options/plan.constant';
import { DEFAULT_PLANTYPE } from '@constants/plan-options/plan-types.constant';
import {
  BASIC,
  Currency,
  DEFAULT_PLAN,
  DEFAULT_CURRENCY,
  PlanType,
  DEFAULT_SECONDCURRENCY
} from '../../constants';
/** Models */
import { Coverage } from '../../models';
import { Prospectu } from '@models/prospectu/prospectu.model';
import { FrontTerms, PlanList } from '../../models/terms/custom-terms.interface';
/** Services */
import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { FactoresService } from '@services/factores/factores.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { FactorsSearch } from '@models/factors-search/factors-search.model';
import { CoverageConstants } from '@models/.';
import { CommissionScheme } from '@models/.';

/**
 * Servicio encargado de las opciones del plan
 * @export
 * @class OpcionesPlanService
 */
@Injectable({
  providedIn: 'root'
})
export class OpcionesPlanService {
  /**
   * Tipo de covertura
   * @property
   * @type [Coverage]
   */
  cobertura: Coverage;
  /**
   * Numero de plazos asegurados
   * @property
   * @type [Number]
   */
  plazosAsegurados: number;
  /**
   * Tipo de plazo de pago
   * @var
   * @type [BehavioprSubject]
   */
  plazoPago = new BehaviorSubject<number>(0);
  /**
   * Plazo del seguro
   * @var
   * @default [99]
   */
  plazoSeguro = 99;
  /**
   * Arreglo de plazos a pagar
   * @var
   */
  plazosPagos: number[] = [];
  /**
   * Prospecto
   * @var
   * @type [Prospectu]
   */
  prospecto: Prospectu;
  /**
   * Folio del documento
   * @var
   * @type [string]
   */
  folio: string;

  /**
   * Constantes de las opciones de plan
   * @var
   * @memberof OpcionesPlanService
   */
  tipodeplanes = this.protectionPlansGenerator.products.value;

  /**
   * Constantes del tipo de moneda
   * @var
   * @memberof OpcionesPlanService
   */
  tipodecambio = this.protectionPlansGenerator.currencies.value;

  /**
   * Array inmutable con los planes de protección
   * @type {FrontTerms[]}
   * @memberof OpcionesPlanService
   */
  plazos: FrontTerms[];

  /**
   * Establece las configuraciones por defecto del plan
   * @var
   */
  tipoPlan: PlanType = DEFAULT_PLANTYPE;
  tipoPlan_Ob = new BehaviorSubject<string>('VPL');

  /**
   * Nombre del plan
   * @var
   */
  plan: Plan = DEFAULT_PLAN;
  /**
   * Tipo de moneda
   * @var
   */
  moneda: Currency = DEFAULT_CURRENCY;

  /**
   * Moneda secundaria
   * @var
   */
  segundaMoneda: Currency = DEFAULT_SECONDCURRENCY;

  /**
   * Valores finales
   * @var
   */
  valoresGarantizadosListos = new BehaviorSubject<boolean>(false);

  generateProposals = new BehaviorSubject<boolean>(false);

  arrParams: FactorsSearch[] = [];
  screen = new BehaviorSubject<number>(1);
  arrayListPlan: Array<PlanList> = [];
  incrementosProgramados = new BehaviorSubject<boolean>(false);
  incrementoInpcPorc = new BehaviorSubject<number>(0);
  planOffline = new BehaviorSubject<boolean>(false);
  tipoRiesgo = new BehaviorSubject<string>('Normal');
  gfiIncluido = new BehaviorSubject<boolean>(false);
  gfcIncluido = new BehaviorSubject<boolean>(false);
  gfhIncluido = new BehaviorSubject<boolean>(false);
  inpcUniversal = new BehaviorSubject<boolean>(false);
  totalQuestion = new BehaviorSubject<number>(0);
  arrEdad = [];
  esquemaComisionUniv: CommissionScheme = 'Decrecientes';

  /**
   * Método constructor ue inicializa todas las variables
   * @param factores
   * @param prospectoService
   * @param protectionPlansGenerator
   * @param excedentes
   * @param folioService
   * @method
   */
  constructor(
    private factores: FactoresService,
    private prospectoService: ProspectoService,
    public protectionPlansGenerator: ProtectionPlansGeneratorService,
    private excedentes: ExcedentesService,
    private router: Router
  ) {
    this.tipoPlan_Ob.next(this.changeTipoPlanOb(this.router.url.split('/')[2]));

    this.router.events
      .filter(value => value instanceof NavigationStart)
      .distinctUntilChanged()
      .subscribe((value: NavigationStart) =>
        this.tipoPlan_Ob.next(this.changeTipoPlanOb(value.url.split('/')[2]))
      );

    this.prospectoService.prospectoActual.subscribe(
      prospecto => (this.prospecto = prospecto)
    );
    if (this.plazoPago.value === 0 || this.plazoPago.value === null) {
      this.plazoPago.next(
        this.protectionPlansGenerator.getDefaultTerm(DEFAULT_PLANTYPE)
      );
    }

    // Plan
    this.protectionPlansGenerator.productsGenerator(this.tipoPlan);
    this.protectionPlansGenerator.products.subscribe(products => {
      this.tipodeplanes = products;
    });
    // Moneda
    this.protectionPlansGenerator.currenciesGenerator(
      this.moneda,
      this.tipoPlan
    );
    this.protectionPlansGenerator.currencies.subscribe(currencies => {
      this.tipodecambio = currencies;
    });
    // Plazo pagos
    this.protectionPlansGenerator.generatePlansFront(
      this.tipoPlan,
      this.plazoPago.value,
      this.moneda
    );
    this.protectionPlansGenerator.NewTermsToExport.subscribe(plazo => {
      this.plazos = plazo.terms;
    });
  }

  changeTipoPlanOb(plan: string): string {
    if (plan === 'Aliados') {
      return 'DOTAL';
    } else if (plan === 'ProTGT') {
      return 'VPL';
    }

    return 'AxaParaTodos';
  }

  /**
   * Método que cambia los terminos del pago
   * @param term
   * @method
   */
  changePaymentTerm(term: number) {
    this.plazoPago.next(term);
    this.plazoSeguro = term;
    this.protectionPlansGenerator.sugerenciaPlazos(term);
  }

  /**
   * Método que genera los Factores
   * @param isFirstTime
   * @method
   */
  async generarFactores(isFirstTime: boolean = false) {
    return await new Promise<any>(async resolve => {
      
      let plazo_seg = 99;
      if( this.tipoPlan === 'DOTAL' || this.tipoPlan === 'ALIADOSKIDS'){
        plazo_seg = this.plazoPago.value;
      }      
      const plazo_seguro = plazo_seg;

      let params: FactorsSearch;
      if (this.tipoPlan !== 'ALIADOSKIDS') {
        params = {
          plan: this.plan,
          moneda: this.moneda,
          tipoPlan: this.tipoPlan,
          plazoPago: Number(this.plazoPago.value),
          plazoSeguro: plazo_seguro,
          sexo: this.prospecto.gender,
          fumador: this.prospecto.fumador,
          edad: this.prospecto.age,
          mancomunado: {
            mancomunado: this.prospecto.mancomunado.mancomunado,
            edadEquivalente: this.prospecto.mancomunado.edadEquivalente
          }
        };
      } else if(this.tipoPlan === 'ALIADOSKIDS' ){
        let planKids = (this.prospecto.kids.ageKids < 8 ? 'DOTAL' : 'TEMPORAL' );
        params = {
          plan: planKids,
          moneda: this.moneda,
          tipoPlan: this.tipoPlan,
          plazoPago: Number(this.plazoPago.value),
          plazoSeguro: plazo_seguro,
          sexo: this.prospecto.gender,
          fumador: this.prospecto.fumador,
          edad: this.prospecto.age,
          mancomunado: {
            mancomunado: this.prospecto.mancomunado.mancomunado,
            edadEquivalente: this.prospecto.mancomunado.edadEquivalente
          },
          edadMenor: this.prospecto.kids.ageKids
        }
      } 
      if (this.tipoPlan === 'DOTAL') {
        this.factores.plan = this.tipoPlan;
        this.factores.plazosPago = this.plazoPago.value;
      }
      const fact = await this.factores.generarFactoresCoberturas(params, true);
      if( this.tipoPlan !== 'ALIADOSKIDS' ) //TODO:ALIADOSKIDS verificar si no afecta que no traiga los factoresDiezMas
        this.generarFactoresDiezMas(params);
      resolve(fact);
    });
  }

  /**
   * Método que genera los Factores Propuestas
   * @param isFirstTime
   * @method
   */
  async generarFactoresPropuestas() {
    return await new Promise<any>(resolve => {
      let params: FactorsSearch;
      params = {
        plan: this.plan,
        moneda: this.moneda,
        tipoPlan: this.tipoPlan,
        plazoPago: Number(this.plazoPago.value),
        plazoSeguro: this.plazoSeguro,
        sexo: this.prospecto.gender,
        fumador: this.prospecto.fumador,
        edad: this.prospecto.age,
        mancomunado: {
          mancomunado: this.prospecto.mancomunado.mancomunado,
          edadEquivalente: this.prospecto.mancomunado.edadEquivalente
        }
      };
      this.asignarParametros(params);
      this.factores
        .generarFactoresCoberturasPropuestas(this.arrParams)
        .then(data => {
          this.valoresGarantizadosListos.next(true);
          resolve(data);
        });
    });
  }

  async generarFactoresMujerUnica() {
    return await new Promise(async (resolve, reject) => {
      let params: FactorsSearch;
      params = {
        plan: 'VPL',
        moneda: this.moneda,
        tipoPlan: 'MUJER UNICA',
        plazoPago: 65,
        plazoSeguro: 99,
        sexo: this.prospecto.gender,
        fumador: this.prospecto.fumador,
        edad: this.prospecto.age,
        mancomunado: {
          mancomunado: this.prospecto.mancomunado.mancomunado,
          edadEquivalente: this.prospecto.mancomunado.edadEquivalente
        },
        arrDiezMas: this.arrEdad
      };
      const data = await this.factores.generarFactoresMujerUnica(params);
      this.valoresGarantizadosListos.next(true);
      this.generarFactoresDiezMas(params);
      resolve(data);
    });
  }

  async generarFactoresCoberturasINPC() {
    return await new Promise<any>(resolve => {
      let params: FactorsSearch;
      params = {
        plan: this.plan,
        moneda: this.moneda,
        tipoPlan: this.tipoPlan,
        plazoPago: Number(this.plazoPago.value),
        plazoSeguro: this.plazoSeguro,
        sexo: this.prospecto.gender,
        fumador: this.prospecto.fumador,
        edad: this.prospecto.age,
        mancomunado: {
          mancomunado: this.prospecto.mancomunado.mancomunado,
          edadEquivalente: this.prospecto.mancomunado.edadEquivalente
        }
      };
      this.asignarParametros(params);
      this.factores.generarFactoresCoberturasINPC(params).then(data => {
        this.valoresGarantizadosListos.next(true);
        resolve(data);
      });
    });
  }

  /**
   * Método que genera solo los factores de los Valores
   * @param isFirstTime
   * @method
   */
  async generarFactoresValoresG(isFirstTime: boolean = false) {
    return await new Promise<any>(resolve => {
      // let thereAreFactors = false;

      // if (isFirstTime) {
      //   thereAreFactors = this.factores.factoresValoresActual.length !== 0;
      // }

      let params: FactorsSearch;

      const plazo_seguro =
        this.tipoPlan === 'DOTAL' ? this.plazoPago.value : 99;

        params = {
          plan: this.plan,
          esquema: this.excedentes.esquema_comision.value,
          tipoPlan: this.tipoPlan,
          moneda: this.moneda,
          plazoSeguro: plazo_seguro,
          plazoPago: this.plazoPago.value,
          sexo: this.prospecto.gender,
          fumador: this.prospecto.fumador,
          edad: this.prospecto.age,
          edadMenor: this.prospecto.kids.ageKids,
          mancomunado: {
            mancomunado: this.prospecto.mancomunado.mancomunado,
            edadEquivalente: this.prospecto.mancomunado.edadEquivalente
          }
        };
      
      if (this.tipoPlan === 'DOTAL') {
        this.factores.plan = this.tipoPlan;
        this.factores.plazosPago = this.plazoPago.value;
      }
      this.factores.generarFactoresValoresG(params).then(data => resolve(data));
    });
  }

  /**
   * Método que genera los Factores Valores Garantizados Propuestas
   * @param isFirstTime
   * @method
   */
  async generarFactoresValoresGPropuestas() {
    return await new Promise<any>(resolve => {
      let params: FactorsSearch;
      params = {
        plan: this.plan,
        moneda: this.moneda,
        tipoPlan: this.tipoPlan,
        plazoPago: Number(this.plazoPago.value),
        plazoSeguro: this.plazoSeguro,
        sexo: this.prospecto.gender,
        fumador: this.prospecto.fumador,
        edad: this.prospecto.age,
        mancomunado: {
          mancomunado: this.prospecto.mancomunado.mancomunado,
          edadEquivalente: this.prospecto.mancomunado.edadEquivalente
        },
        esquema: this.excedentes.esquema_comision.value
      };
      this.asignarParametros(params);
      this.factores
        .generarFactoresValoresGPropuestas(this.arrParams)
        .then(data => {
          this.valoresGarantizadosListos.next(true);
          resolve(data);
        });
    });
  }

  async generarFactoresValoresGMujerUnica() {
    return await new Promise((resolve, reject) => {
      let params: FactorsSearch;
      params = {
        plan: 'VPL',
        moneda: this.moneda,
        tipoPlan: 'MUJER UNICA',
        plazoPago: 65,
        plazoSeguro: 99,
        sexo: this.prospecto.gender,
        fumador: this.prospecto.fumador,
        edad: this.prospecto.age,
        mancomunado: {
          mancomunado: this.prospecto.mancomunado.mancomunado,
          edadEquivalente: this.prospecto.mancomunado.edadEquivalente
        },
        esquema: this.excedentes.esquema_comision.value
      };
      this.factores.generarFactoresValoresGMujerUnica(params).then(data => {
        this.valoresGarantizadosListos.next(true);
        resolve(data);
      });
    });
  }

  asignarParametros(params: FactorsSearch) {
    for (let i = 0; i < this.arrayListPlan.length; i++) {
      this.arrParams[i] = JSON.parse(JSON.stringify(params));
      this.arrParams[i].tipoPlan = this.arrayListPlan[i].producto as PlanType;
      this.arrParams[i].plazoPago = Number(this.arrayListPlan[i].plazo);
      this.arrParams[i].plazoSeguro =
        this.arrayListPlan[i].producto === 'TEMPORAL' ||
          this.arrayListPlan[i].producto === 'DOTAL'
          ? Number(this.arrayListPlan[i].plazo)
          : 99;
    }
  }

  /**
   * Método que optiene los descuentos para la suma asegurada
   * @param sumaAsegurada
   * @method
   */
  obtenerDescuento(sumaAsegurada: number): number {
    if (this.tipoPlan === 'DOTAL') {
      return 1;
    }

    if (sumaAsegurada >= 0) {
      if (this.moneda === 'UDI') {
        if(localStorage.getItem('planElegido')=='ProTGT' && (this.tipoPlan=== 'VPL' || this.tipoPlan=== 'OV')) {
          return this.getFilter(
            BASIC.DISCOUNT_PER_VOLUME_PRTG.UDI,
            Math.round(sumaAsegurada));
        } else {
        return this.getFilter(
          BASIC.DISCOUNT_PER_VOLUME.UDI,
          Math.round(sumaAsegurada)
        );
        }
      } else if (this.moneda === 'MXN') {
        if (this.tipoPlan === 'TEMPORAL') {
          return this.getFilter(
            BASIC.DISCOUNT_PER_VOLUME.MXN_TMP,
            Math.round(sumaAsegurada)
          );
        }
        return this.getFilter(
          BASIC.DISCOUNT_PER_VOLUME.MXN,
          Math.round(sumaAsegurada)
        );
      } else {
        return this.getFilter(
          BASIC.DISCOUNT_PER_VOLUME.USD,
          Math.round(sumaAsegurada)
        );
      }
    }
    return 0;
  }

  /**
   * Método que filtra la suma asegurada
   * @param range
   * @param sumaAsegurada
   */
  private getFilter(
    range: { MIN: number; MAX: number; DISCOUNT: number }[],
    sumaAsegurada: number
  ): number {
    return range.filter(
      result => sumaAsegurada >= result.MIN && sumaAsegurada < result.MAX
    )[0].DISCOUNT;
  }

  /**
   * Método que obtiene el recargo
   * @param currency
   * @method
   */
  obtenerRecargoFijo(currency: Currency): number {
    if (this.tipoPlan === 'ALIADOSKIDS')
      return 400;
    else
      return (
        BASIC.FIXED_SURCHARGE.filter(results => currency === results.CURRENCY)[0]
          .SURCHARGE || 0
      );
  }

  obtenerDescuentoProteccionEfectiva(sumaAsegurada: number): number {
    return (
      BASIC.DISCOUNT_PROTECCION_EFECTIVA.MXN.filter(
        el => sumaAsegurada >= el.MIN && sumaAsegurada < el.MAX
      )[0].DISCOUNT || 0
    );
  }

  async generarFactoresDiezMas(params: FactorsSearch) {
    // Colocar logica para usar el recalculo de diez años
    this.diezMas();
    let arrEdades = this.arrEdad;
    if (this.prospecto.mancomunado.mancomunado) {
      const plazo = this.plazoPago.value < 60 ? (Number(this.prospecto.age) + Number(this.plazoPago.value)) : 80;
      const arrEdadTitular = this.addDiezMas(plazo, this.prospecto.age);
      arrEdades = arrEdades.concat(arrEdadTitular);
    }
    params.arrDiezMas = arrEdades;
    params.tipoPlan = params.tipoPlan === 'MUJER UNICA' ? 'TEMPORALMU' : params.tipoPlan;
    await this.factores.generarFactoresDiezMas(params);
  }

  private diezMas() {
    const edadM =  this.prospecto.mancomunado.mancomunado ? Number(this.prospecto.mancomunado.edadEquivalente) :
    Number(this.prospecto.age);
    const plazo = this.plazoPago.value < 60 ? (Number(edadM) + Number(this.plazoPago.value)) : 80;
    this.arrEdad = [];
    this.arrEdad = this.addDiezMas(plazo, edadM);
  }

  private addDiezMas(plazo: number, edad: number) {
    let edadMas = Number(edad);
    const arrEdades = [];
    while (edadMas <= plazo) {
      edadMas += 10;
      if (edadMas <= plazo) {
        arrEdades.push(edadMas.toString());
      }
    }
    return arrEdades;
  }

    /**
   * MÉTODO QUE REGRESA EK VAKIR DE LAS COBERTURAS EN VIGENCIA
   * @private
   * @param {number} valor
   * @param {CoverageConstants} cobertura
   * @param {number} edad
   * @returns {number}
   * @memberof ValoresGarantizadosService
   */
  coberturaEnVigencia(valor: number, cobertura: CoverageConstants, edad: number): number {
    if (edad <= cobertura.CANCELLATION_DATE) {
      return valor;
    }
    return 0;
  }

}
