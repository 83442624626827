import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Plazo } from '@app/models';

import { FactoresService } from '@app/services/factores/factores.service';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';

/** Constants */
import { BAService } from '../BA/ba.service';
import { RecursosExternosService } from '@app/services/recursosExternos/recursos-externos.service';
import { CDC_MU } from '@constants/coverages';

@Injectable({
  providedIn: 'root'
})
export class CDCService {
  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_PrimeraMoneda = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_PrimeraMoneda = new BehaviorSubject<number>(0);


  DEFAULT = true;

  prima_DiezMasCdc = 0;

  constructor(
    private factores: FactoresService,
    private opciones: OpcionesPlanService,
    private BA: BAService,
    private recursosExternos: RecursosExternosService
  ) { }

  establecePrimaAnual(sumaAsegurada: number): void {
    if (sumaAsegurada === -1) {
      this.resetValues();
      return;
    }
    let suma;
    switch (this.opciones.moneda) {
      case 'UDI':
        suma = CDC_MU.MIN_SUM_UDI;
        break;
      case 'MXN':
        suma = CDC_MU.MIN_SUM_MXN;
        break;
      case 'USD':
        suma = CDC_MU.MIN_SUM_USD;
        break;
    }

    if (sumaAsegurada >= suma && isFinite(sumaAsegurada)) {
      suma = sumaAsegurada;
    }

    if (
      suma > this.BA.sumaAsegurada_UDI.value ||
      suma <= this.recursosExternos.GetMinMax('CDC', 'MIN')
    ) {
      suma = this.BA.sumaAsegurada_UDI.value;
    }

    if (suma <= this.recursosExternos.GetMinMax('CDC', 'MIN')) {
      suma = this.recursosExternos.GetMinMax('CDC', 'MIN');
    }

    if (suma >= this.recursosExternos.GetMinMax('CDC', 'MAX')) {
      suma = this.recursosExternos.GetMinMax('CDC', 'MAX');
    }

    this.sumaAsegurada_PrimeraMoneda.next(suma);

    const prima = this.calculoPrimaCDC(suma, 'Elegido');

    this.primaAnual_PrimeraMoneda.next(prima);
  }

  calculoPrimaCDC(sumaAsegurada: number, plazo: Plazo, edad?: number): number {
    // Variables necesarias para el calculo
    const factorCDC = this.factores.obtenerFactorCobertura('CDC', plazo, false, edad);

    // Ejecuta la formula dada
    const primaCDC = this.obtenerPrimaCDC(sumaAsegurada, factorCDC);

    // Retorna el resultado de la operación
    return primaCDC;
  }

  /**
* MÉTODO QUE CALCULA LA NUEVA PRIMA CADA 10 ANIOS
* @param {number} edad
* @memberof CDCService
*/
  calculoPrimaCDCDiezMas(edad: number) {
    let prima = 0;
    if (this.opciones.coberturaEnVigencia(this.sumaAsegurada_PrimeraMoneda.value, CDC_MU, edad) > 0) {
      const factorCDC = this.factores.obtenerFactoresDiezMas('CDC', edad);
      prima = this.obtenerPrimaCDC(this.sumaAsegurada_PrimeraMoneda.value, factorCDC);
      prima = prima - this.primaAnual_PrimeraMoneda.value;
    }
    this.prima_DiezMasCdc = prima;
  }

  obtenerPrimaCDC(sumaAsegurada: number, factorCDC: number) {
    const primaCDC = factorCDC !== 0 ? (sumaAsegurada * factorCDC) / 1000 : 0;
    return primaCDC;
  }

  resetValues(): void {
    this.primaAnual_PrimeraMoneda.next(0);
    this.sumaAsegurada_PrimeraMoneda.next(0);
    this.DEFAULT = true;
  }
}
