/** SERVICES */
import { DeviceTypeService } from './../../../services/deviceType/deviceType.service';
/** ANGULAR CORE */
import { Component, OnInit, ViewChild } from '@angular/core';
/** ROUTES */
import { Router } from '@angular/router';
/** NGX - BOOTSTRAP */
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * MODAL DE INICIO QUE REDIRIGE A DESCARGA DE PLANES
 * @export
 * @class OfflineconfigModalComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-offlineconfig-modal',
  templateUrl: './offlineconfig-modal.component.html',
  styleUrls: ['./offlineconfig-modal.component.sass']
})
export class OfflineconfigModalComponent implements OnInit {
  /** PROPIEDAD PARA COMUNICAR AL COMPONENTE PADRE CON EL COMPONENTE HIJO */
  @ViewChild('modalRef') mod: BsModalRef;

  /** VARIABLE PARA LA REFERENCIA AL MODAL */
  modalRef: BsModalRef;
  /** VARIABLE PARA DETECTAR EL DISPOSITIVO EN EL QUE SE EJECUTA */
  dev: string;
  /** VARIABLE BOOLEANA QUE DETECTA SI ESTA CONECTADO EN UN DISPOSITIVO */
  dispositivo: boolean;
  /** VARIABLE PARA ALMACENAR LA ORIENTACION */
  orient: string;

  /**
   * Creates an instance of OfflineconfigModalComponent.
   * @param {BsModalRef} modal
   * @param {Router} router
   * @param {DeviceTypeService} device
   * @memberof OfflineconfigModalComponent
   */
  constructor(
    private modal: BsModalRef,
    private router: Router,
    private device: DeviceTypeService
  ) { }

  /**
   * MÉTODO PRINCIPAL EN EL CUAL SE CARGAN LOS VALORES INICIALES
   *
   * @memberof OfflineconfigModalComponent
   */
  ngOnInit() {
  }

  /**
   * MÉTODO PARA DETECTAR EL TIPO DE DISPOSITIVO
   * EN EL QUE SE EJECUTA EL COTIZADOR
   * @returns
   * @memberof OfflineconfigModalComponent
   */
  devtype() {
    if (this.device.getDeviceType() === 'iPad') {
      this.orient = this.device.getOrientation(window.innerWidth, window.innerHeight);
      this.dev = 'iPad';
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'iPhone') {
      this.dev = 'iPhone';
      this.orient = this.device.getOrientation(window.innerWidth, window.innerHeight);
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'Android') {
      this.dev = 'Android';
      this.orient = this.device.getOrientation(window.innerWidth, window.innerHeight);
      return this.dispositivo = true;
    } else {
      this.dev = 'Desktop';
      this.orient = this.device.getOrientation(window.innerWidth, window.innerHeight);
      return this.dispositivo = false;
    }
  }

  /**
   * MÉTODO PARA CERRA EL MODAL
   * @memberof OfflineconfigModalComponent
   */
  close() {
    this.modal.hide();
  }

  /**
   * MÉTODO QUE CIERRA EL MODAL Y REDIRIGE
   * A LA PANTALLA DE DESCARGA DE PLANES
   * @memberof OfflineconfigModalComponent
   */
  configFlow() {
    this.router.navigate(['/cotizador/configurar-plazo']);
    this.modal.hide();
  }

}
