import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';
import { AlertModalComponent } from '@app/shared/modal/alert-modal/alert-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalService } from '../utils/modal.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

    modalRef: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private modal: ModalService
    ){}
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError(error => {
        let errorMessage = '';
        if (error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // backend error
          errorMessage = `Server-side error: ${error.status} ${error.message}`;
        }
        
        // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.
        this.mostrarModal('Ha ocurrido un error, intenta de nuevo.');
        return throwError(errorMessage);
      })
    );
  }

  private mostrarModal(descripcion: string) {
    this.modal.bolBodalServ = true;
    this.modal.descripcion = descripcion;
    this.modalRef = this.modalService.show(AlertModalComponent, {
        backdrop: false,
        class: 'alert-modal'
    });
    this.modal.bolBodalServ = false;
}

}