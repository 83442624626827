/** Angular Core */
import { Injectable } from '@angular/core';
/** Rxjs */
import { BehaviorSubject, /*merge, fromEvent, of,/** */ Observable } from 'rxjs';
//import { mapTo } from 'rxjs/operators';
/**
 * Injectable para el estado de conexión
 * @export
 * @class NetworkStateService
 */
@Injectable({
  providedIn: 'root'
})
export class NetworkStateService {

  /**
   * Sujeto del estado de la conexión
   *
   * (false) -> Sin internet
   * (true) -> Con internet
   *
   * @private
   * @memberof NetworkStateService
   */
  _state = new BehaviorSubject<boolean>(true);

  lastNetworkState: boolean;

  /**
   * Crea una instancia del servicio de conexión e inicializa el
   * estado de la conexión
   * @memberof NetworkStateService
   */
  constructor() {
    this._state.next(true);
  }

  /**
   * Devuelve el estado actual de la conexión como booleano
   *
   * @readonly
   * @type {boolean}
   * @memberof NetworkStateService
   */
  get state(): boolean {
    return true;
  }

  /**
   * Devuelve el estado de la conexión como observable
   *
   * @returns {Observable<boolean>}
   * @memberof NetworkStateService
   */
  stateObservable(): Observable<boolean> {
    return this._state.asObservable();
  }

}
