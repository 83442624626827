/**
 * Datos por defecto del prospecto (Género y Hábito).
 * @const
 * @default Gender: "H"
 * @default Habit: "NO"
 */
export const PROSPECTUS_DATA_CONSTANTS = {
  DEFAULT_GENDER: 'H',
  DEFAULT_HABIT: 'NO'
};
