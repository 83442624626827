import { Injectable } from '@angular/core';
import { FormulasService } from './formulas.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { CalculosUniversalesService } from './calculos-universales.service';
import { ProyeccionVidaInteligente } from './../../models/universales/proyeccionVidaInteligente';
import { Cobertura } from './../../models/universales/cobertura';
import { CoberturaTarifa } from './../../models/universales/coberturaTarifa';
import { BaseDatosUniversalesService } from './../baseDatos/base-datos-universales.service';
import { DescipherService } from './../descipher/descipher.service';
import { CalculosProyeccionUniversalesComunService } from './calculos-proyeccion-universales-comun.service';
import { ECMIUniversal, DIPOCUniversal, GFIUniversal } from '@constants/coverages';
import { PAIUniversal, DIUniversal, GFHUniversal } from '@constants/coverages/coverages.constants';
import { GFCUniversal } from './../../constants/coverages/coverages.constants';

@Injectable({
  providedIn: 'root'
})
export class CalculosProyeccionUniversalesInpcService {

  arrTarifas: CoberturaTarifa[] = [];
  arrCoberturas: Cobertura[] = [];

  constructor(
    private formulas: FormulasService,
    private opc: OpcionesPlanService,
    private primas: CalculosUniversalesService,
    private bd: BaseDatosUniversalesService,
    private descipher: DescipherService,
    private comun: CalculosProyeccionUniversalesComunService) {
    this.arrCoberturas = [];
  }

  async proyeccion() {
    this.arrCoberturas = [];
    const arrProyec: ProyeccionVidaInteligente[] = [];
    let edadCalculada = this.opc.prospecto.mancomunado.mancomunado ? this.primas.edadEquivalenteCalculada :
      this.primas.edadCalculada.value;
    let edadReal = this.opc.prospecto.mancomunado.mancomunado ? this.primas.edadEquivalenteCalculada :
    this.opc.prospecto.age;
    // let edadReal = this.opc.prospecto.age;
    const inicio = edadReal;
    const fin = this.opc.plazoPago.value;
    let anio = 1;
    await this.obtenerTarifas(edadCalculada);
    for (let i = inicio; i < fin; i++) {
      this.generarSumasAseguradas(anio, edadReal);
      const sumaAsegurada = this.sumaAsegurada('BA', anio);
      const primaBasica = this.primaBasica(anio, edadCalculada, sumaAsegurada, edadReal);
      const primaExcd = this.comun.primaExcedente(anio);
      const primaTotal = this.comun.primaTotal(primaBasica, primaExcd);
      const primaAcumulada = this.comun.primaAcumulada(primaTotal, anio === 1 ? 0 : arrProyec[anio - 2].primaAcumulada);
      
      let fondoExcedente = anio === 1 ? 0 : arrProyec[anio - 2].fondoExcedente;
      fondoExcedente = this.comun.fondoExcedente(fondoExcedente, anio);
      let fondoBasico = anio === 1 ? 0 : arrProyec[anio - 2].fondoBasicoDec;
      let primasFondo = await this.obtenerFondoBasico(primaBasica, sumaAsegurada, fondoBasico, anio, edadCalculada, edadReal, fondoExcedente);
      const fondoBasicoDec = primasFondo.fondoBasico;
      fondoBasico = Math.max(primasFondo.fondoBasico, 0);
      let fondoE =  Math.max(primasFondo.fondoExcedente, 0);
      if (fondoBasico === 0 && anio > 3 && fondoE == 0 ) {
        break;
      }      
      const valorEfectivo = this.comun.valorEfectivo(fondoBasico, fondoExcedente);
      const valorRescate = this.comun.valorRescate(primaBasica, fondoBasico, fondoExcedente, anio);
      const porcRec = this.comun.porcentajeRecuperacion(valorRescate, primaAcumulada);
      const benfFalle = this.comun.beneficioFallecimiento(sumaAsegurada, valorEfectivo, fondoExcedente);
      const proyec: ProyeccionVidaInteligente = {
        anioPoliza: anio,
        edad: edadReal,
        edadCalculo: edadCalculada,
        primaBasica: primaBasica,
        primaExcedente: primaExcd,
        primaTotal: primaTotal,
        primaAcumulada: primaAcumulada,
        sumaAsegurada: sumaAsegurada,
        fondoBasico: primasFondo.fondoBasico,
        fondoExcedente: primasFondo.fondoExcedente,
        valorEfectivo: valorEfectivo,
        valorRescate: valorRescate,
        porcentajeRecuperacion: porcRec,
        beneficioFallecimiento: benfFalle,
        fondoBasicoDec: fondoBasicoDec
      };
      arrProyec.push(proyec);
      anio++;
      edadCalculada++;
      edadReal++;
    }
    return arrProyec;
  }

  private generarSumasAseguradas(anio: number, edadReal: number) {
    this.obtenerSumasAseguradas('BA', this.primas.basica.value, anio, edadReal, 70, 89);
    this.obtenerSumasAseguradas('ECMI', this.primas.ecmi.value, anio, edadReal, ECMIUniversal.ACCEPTANCE_AGE.MAX, ECMIUniversal.CANCELLATION_DATE);
    this.obtenerSumasAseguradas('PAI', this.primas.pai.value, anio, edadReal, PAIUniversal.ACCEPTANCE_AGE.MAX, PAIUniversal.CANCELLATION_DATE);
    this.obtenerSumasAseguradas('DI', this.primas.di.value, anio, edadReal, DIUniversal.ACCEPTANCE_AGE.MAX, DIUniversal.CANCELLATION_DATE);
    this.obtenerSumasAseguradas('DIPOC', this.primas.dipoc.value, anio, edadReal, DIPOCUniversal.ACCEPTANCE_AGE.MAX, DIPOCUniversal.CANCELLATION_DATE);
    this.obtenerSumasAseguradas('GFI', this.primas.gfi.value, anio, edadReal, GFIUniversal.ACCEPTANCE_AGE.MAX, GFIUniversal.CANCELLATION_DATE);
    this.obtenerSumasAseguradas('GFH', this.primas.gfh.value, anio, edadReal, GFHUniversal.ACCEPTANCE_AGE.MAX, GFHUniversal.CANCELLATION_DATE);
    this.obtenerSumasAseguradas('GFC', this.primas.gfc.value, anio, edadReal, GFCUniversal.ACCEPTANCE_AGE.MAX, GFCUniversal.CANCELLATION_DATE);
    this.obtenerSumasAseguradas('CDC', this.primas.cdc.value, anio, edadReal, 65, 65);
  }
  private obtenerSumasAseguradas(tipoCober: string, coberObj: Cobertura, anio: number, edadReal: number, anioSiNcremento: number, anioCancelacion: number) {
    if (edadReal <= anioCancelacion) {
      let sumAseg = coberObj.sumaAsegurada;
      let prima = coberObj.prima;
      if (sumAseg > 0) {
        let incremento = 0;
        if (anio > 1) {
          sumAseg = this.arrCoberturas.filter(s => s.anio === (anio - 1) && s.cobertura === tipoCober)[0].sumaAsegurada;
          if (edadReal <= anioSiNcremento) {
            incremento = this.formulas.obtenerIncrementoSumaAseguradaInpc(sumAseg, this.opc.incrementoInpcPorc.value);
            sumAseg = sumAseg + incremento;
          }
          prima = 0;
        }
        const cober: Cobertura = {
          cobertura: tipoCober,
          sumaAsegurada: sumAseg > 0 ? sumAseg : 0,
          anio: anio,
          prima: prima,
          incrementoSumaAsegurada: incremento
        };
        this.arrCoberturas.push(cober);
      }
    }
  }

  private sumaAsegurada(cobertura: string, anio: number) {
    const sumAseg = this.arrCoberturas.filter(s => s.anio === anio && s.cobertura === cobertura)[0].sumaAsegurada;
    return sumAseg;
  }

  private primaBasica(anio: number, edadCalc: number, sumaAsegurada: number, edadReal: number) {
    let primaBasica = 0;
    if (anio === 1) {
      primaBasica = this.primas.primaTotal.value;
    } else {
      primaBasica += this.obtenerPrimaBasica('BA', anio, edadCalc, 70, 89, edadReal, sumaAsegurada);
      primaBasica += this.obtenerPrimaBasica('ECMI', anio, edadCalc, ECMIUniversal.ACCEPTANCE_AGE.MAX, ECMIUniversal.CANCELLATION_DATE, edadReal);
      primaBasica += this.obtenerPrimaBasica('PAI', anio, edadCalc, PAIUniversal.ACCEPTANCE_AGE.MAX, PAIUniversal.CANCELLATION_DATE, edadReal);
      primaBasica += this.obtenerPrimaBasica('DI', anio, edadCalc, DIUniversal.ACCEPTANCE_AGE.MAX, DIUniversal.CANCELLATION_DATE, edadReal);
      primaBasica += this.obtenerPrimaBasica('DIPOC', anio, edadCalc, DIPOCUniversal.ACCEPTANCE_AGE.MAX, DIPOCUniversal.CANCELLATION_DATE, edadReal);
      primaBasica += this.obtenerPrimaBasica('GFI', anio, edadCalc, GFIUniversal.ACCEPTANCE_AGE.MAX, GFIUniversal.CANCELLATION_DATE, edadReal);
      primaBasica += this.obtenerPrimaBasica('GFH', anio, edadCalc, GFHUniversal.ACCEPTANCE_AGE.MAX, GFHUniversal.CANCELLATION_DATE, edadReal);
      primaBasica += this.obtenerPrimaBasica('GFC', anio, edadCalc, GFCUniversal.ACCEPTANCE_AGE.MAX, GFCUniversal.CANCELLATION_DATE, edadReal);
      primaBasica += this.obtenerPrimaBasica('CDC', anio, edadCalc, 65, 65, edadReal);
    }
    return primaBasica;
  }

  private obtenerPrimaBasica(tipoCober: string, anio: number, edadCalc: number, anioSincremento: number, anioCancelacion: number,
    edadReal: number , sumaAsegurada?: number) {
    let primaIncremento = 0;
    if (edadReal <= anioCancelacion) {
      const arrCober = this.arrCoberturas.filter(s => s.cobertura === tipoCober && s.anio === anio);
      if (arrCober.length > 0) {
        let incremento = 0;
        if (edadReal <= anioSincremento) {
          const cober = tipoCober === 'BA' ? 'BASICA' : tipoCober;
          const tarifa = this.obtenerTarifa(edadCalc, cober, sumaAsegurada);
          incremento = this.formulas.obtenerPrimaCoberturaBasicaIncremento(arrCober[0].incrementoSumaAsegurada, Number(tarifa));
        }
        const primaAnt = this.arrCoberturas.filter(s => s.cobertura === tipoCober && s.anio === (anio - 1))[0].prima;
        primaIncremento = incremento + primaAnt;
        arrCober[0].prima = primaIncremento;
      }
    }
    return primaIncremento;
  }

  private obtenerTarifa(edadCalc: number, cober: string, sumaAsegurada: number) {
    // Exclusive
    // const tipoPan = this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR';
    // let tarifa;
    // if (tipoPan === 'VI') {
    //   const tarifaEnc = this.arrTarifas.filter(s => s.Edad === edadCalc.toString() && s.Cobertura === cober);
    //   tarifa = this.descipher.desiph(tarifaEnc.length > 0 ? tarifaEnc[0].Tarifa : '0');
    // } else {
    //   const edad = this.primas.edadCalculada.value.toString();
    //   const banda = cober === 'BASICA' ? this.primas.obtenerBanda(sumaAsegurada).banda : 'T';
    //   const tarifaEnc = this.arrTarifas.filter(s => s.Edad === edad && s.Cobertura === cober && s.Banda === banda);
    //   tarifa = this.descipher.desiph(tarifaEnc.length > 0 ? tarifaEnc[0].Tarifa : '0');
    // }
    let tarifa;
    const tipoPan = this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR';
    if (tipoPan === 'VI') {
      const tarifaEnc = this.arrTarifas.filter(s => s.Edad === edadCalc.toString() && s.Cobertura === cober);
      tarifa = this.descipher.desiph(tarifaEnc.length > 0 ? tarifaEnc[0].Tarifa : '0');
    } else {
      const tarifaEnc = this.arrTarifas.filter(s => s.Cobertura === cober);
      tarifa = this.descipher.desiph(tarifaEnc.length > 0 ? tarifaEnc[0].Tarifa : '0');
    }
    return tarifa;
  }

  private async obtenerTarifas(edadCalculada: number) {
    this.arrTarifas = [];
    const arrEdades = [];
    let arrCoberturas = [];
    let arrBanda = [];
    const tipoPlan = this.opc.tipoPlan === 'VIDA INTELIGENTE' ? 'VI' : 'PPR';

    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.basica.value.sumaAsegurada, 'BASICA');
    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.ecmi.value.sumaAsegurada, 'ECMI');
    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.pai.value.sumaAsegurada, 'PAI');
    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.di.value.sumaAsegurada, 'DI');
    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.dipoc.value.sumaAsegurada, 'DIPOC');
    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.gfi.value.sumaAsegurada, 'GFI');
    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.gfh.value.sumaAsegurada, 'GFH');
    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.gfc.value.sumaAsegurada, 'GFC');
    arrCoberturas = this.obtenerArrCoberturas(arrCoberturas, this.primas.cdc.value.sumaAsegurada, 'CDC');

    // Cuando sea exclusive comentar esto y descomentar donde dice exclusive
    const inicio = edadCalculada;
    const fin = this.opc.plazoPago.value;
    if (tipoPlan === 'VI') {
      for (let i = inicio; i < fin; i++) {
        arrEdades.push(i.toString());
      }
    } else {
      arrEdades.push(edadCalculada.toString());
    }
    arrBanda = arrCoberturas.length === 1 ? [this.primas.bandaBasica.value] : [this.primas.bandaBasica.value, 'T'];

    // Exclusive
    // if (tipoPlan === 'VI') {
    //   const inicio = edadCalculada;
    //   const fin = this.opc.plazoPago.value;
    //   for (let i = inicio; i < fin; i++) {
    //     arrEdades.push(i.toString());
    //   }
    //   arrBanda = arrCoberturas.length === 1 ? [this.primas.bandaBasica.value] : [this.primas.bandaBasica.value, 'T'];
    // } else {
    //   arrEdades.push(edadCalculada.toString());
    //   arrBanda = ['B1', 'B2', 'B3', 'B4', 'T'];
    // }

    const cobTar: CoberturaTarifa = {
      Producto: tipoPlan,
      ArrCobertura: arrCoberturas,
      Moneda: this.opc.moneda === 'MXN' ? '1' : '2',
      Riesgo: this.opc.tipoRiesgo.value === 'Normal' ? 'N' : 'P',
      ArrBanda: arrBanda,
      ArrEdad: arrEdades,
      Genero: this.opc.prospecto.gender,
      Fumador: this.opc.prospecto.fumador
    };
    this.arrTarifas = await this.bd.getTarifa(cobTar) as CoberturaTarifa[];
  }

  private obtenerArrCoberturas(arrCoberturas: string[], sumaAsegurada: number, cobertura: string) {
    if (sumaAsegurada > 0) {
      arrCoberturas.push(cobertura);
    }
    return arrCoberturas;
  }

  private async obtenerFondoBasico(primaBasica: number, sumaAsegurada: number, fondoBasicoAnt: number, anio: number, edadCalculada: number, edadReal: number, primaFondoExcedente:number) {
    const difPrima = this.obtenerDiferenciasPrimas(anio);
    const sumaPrimaAnt = this.sumaPrimas(anio);
    const arrCobert = this.arrCoberturas.filter(s => s.anio === anio);
    const fondoBasico = await this.comun.fondoBasico(primaBasica, sumaAsegurada, fondoBasicoAnt, anio, edadCalculada, primaFondoExcedente, edadReal,
       difPrima, sumaPrimaAnt, arrCobert);
    return fondoBasico;
  }


  private obtenerDiferenciasPrimas(anio: number) {
    let difPrima = 0;
    if (anio === 1) {
      difPrima = this.primas.primaTotal.value;
    } else {
      const arrCoberActual = this.arrCoberturas.filter(s => s.anio === anio);
      for (let i = 0; i < arrCoberActual.length; i++) {
        let primaAnt = 0;
        const arrCoberAnterior = this.arrCoberturas.filter(s => s.anio === (anio - 1) && s.cobertura === arrCoberActual[i].cobertura);
        if (arrCoberAnterior.length > 0) {
          primaAnt = arrCoberAnterior[0].prima;
        }
        const prima = arrCoberActual[i].prima - primaAnt;
        difPrima += prima < 0 ? 0 : prima;
      }
    }
    return difPrima;
  }

  private sumaPrimas(anio: number) {
    let suma = 0;
    if (anio > 1) {
      const arrCoberActual = this.arrCoberturas.filter(s => s.anio === anio);
      for (let i = 0; i < arrCoberActual.length; i++) {
        let primaAnt = 0;
        const arrCoberAnterior = this.arrCoberturas.filter(s => s.anio === (anio - 1) && s.cobertura === arrCoberActual[i].cobertura);
        if (arrCoberAnterior.length > 0) {
          primaAnt = arrCoberAnterior[0].prima;
        }
        const prima = arrCoberActual[i].prima - primaAnt;
        suma += prima < 0 ? 0 : primaAnt;
      }
    }
    return suma;
  }


}
