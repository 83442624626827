/**
 * Descuento del monto asegurado.
 * @description A mayor cantidad a pagar, mayor es el descuento aplicado.
 * @const
 * @param DISCOUNT es el total de la cantidad a pagar, 1 = 100% como .93 = 93%
 */
export const INSURED_AMOUNT_DISCOUNT = {
  UDI: [
    // 80000
    { MIN: 0, MAX: 350000, DISCOUNT: 1 },
    { MIN: 350000, MAX: 500000, DISCOUNT: .97 },
    { MIN: 500000, MAX: 1000000, DISCOUNT: .95 },
    { MIN: 1000000, MAX: Infinity, DISCOUNT: .93 }
  ],
  MXN: [
    // 300000
    { MIN: 0, MAX: 5000000, DISCOUNT: 1 },
    { MIN: 3500000, MAX: 5000000, DISCOUNT: .97 },
    { MIN: 5000000, MAX: 10000000, DISCOUNT: .95 },
    { MIN: 10000000, MAX: Infinity, DISCOUNT: .93 }
  ],
  USD: [
    // 80000
    { MIN: 0, MAX: 350000, DISCOUNT: 1 },
    { MIN: 350000, MAX: 500000, DISCOUNT: .97 },
    { MIN: 500000, MAX: 1000000, DISCOUNT: .95 },
    { MIN: 1000000, MAX: Infinity, DISCOUNT: .93 }
  ],
};
