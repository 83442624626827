import { Pipe, PipeTransform } from '@angular/core';
import { PlanType } from '@constants/plan-options';

/**
 * Pipe que transforma el tipo de plan 'Dotal' a 'Aliados+', los demás tipos de planes no se modifican.
 */
@Pipe({
  name: 'dotalToAliados'
})
export class DotalToAliadosPipe implements PipeTransform {
/** Método básico de la pipe */
  transform(prod: PlanType): any {
    switch (prod) {
      case 'DOTAL':
        return 'Aliados+';
      case  'ALIADOSKIDS':
        return 'Aliados+ Kids';
      default:
        return prod;
    }
  }
}

