import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
import { CalculosService } from '@services/calculos/calculos.service';
import { ExcedentesService } from './../excedentes/excedentes.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectionService {

  /**
   * Tipo de proyeccion
   * @var
   * @type [BehavioprSubject]
   */
  // projectionType = new BehaviorSubject<number>(0);
  // projectionTypeObs = this.projectionType.asObservable();

  constructor(public calculos: CalculosService,
    private excedentes: ExcedentesService,
    private opc: OpcionesPlanService) {
  }


  /**
 * MÉTODO QUE OBTIENE EL TIPO DE PROYECCIÓN
 * DE ACUERDO AL PLAZO ELEGIDO
 * @param {Plazo} plazo
 * @returns {number}
 * @memberof ProposalsComponent
 */
  // getProyectionType(plazo: Plazo): number {
  getProjectionType(): number {
    // switch (plazo) {
    //   case 'Elegido':
    return this.validarTipoProyeccion(
      this.calculos.BA.primaAnual_UDI.value,
      this.calculos.BI.primaAnual_UDI.value,
      this.calculos.PAI.primaAnual_UDI.value,
      this.calculos.CC.primaAnual_UDI.value,
      this.calculos.DI_primaAnualUDI.value,
      this.calculos.DIPO_primaAnualUDI.value,
      this.calculos.DIPOC_primaAnualUDI.value,
      this.calculos.IAC.primaAnual_UDI.value,
      this.calculos.PAE.primaAnual_UDI.value,
      this.calculos.PCD.primaAnual_UDI.value,
      this.calculos.GF.primaAnual_UDI.value,
      this.excedentes.aportacion_anual_UDI.value,
      this.calculos.VPL.primaAnual_PrimeraMoneda.value,
      this.calculos.CDC.primaAnual_PrimeraMoneda.value
    );
  }

  /**
   * MÉTODO PARA VALIDAR EL TIPO DE PROYECCIÓN
   * @param {number} BA
   * @param {number} BI
   * @param {number} PAI
   * @param {number} CC
   * @param {number} DI
   * @param {number} DIPO
   * @param {number} DIPOC
   * @param {number} IAC
   * @param {number} PAE
   * @param {number} PCD
   * @param {number} GF
   * @param {number} EXCEDENTE
   * @returns {number}
   * @memberof ProposalsComponent
   */
  validarTipoProyeccion(
    BA: number,
    BI: number,
    PAI: number,
    CC: number,
    DI: number,
    DIPO: number,
    DIPOC: number,
    IAC: number,
    PAE: number,
    PCD: number,
    GF: number,
    EXCEDENTE: number,
    VPL: number,
    CDC: number
  ): number {
    if (this.opc.tipoPlan === 'MUJER UNICA') {
      switch (true) {
        case (
          (BA > 0 &&
          VPL > 0 &&
          CDC > 0) &&
          (BI <= 0 &&
          PAI <= 0 &&
          CC <= 0 &&
          DI <= 0 &&
          DIPO <= 0 &&
          DIPOC <= 0 &&
          PAE <= 0 &&
          PCD <= 0 &&
          GF <= 0) &&
          EXCEDENTE <= 0):
          return 0;
        case (
          (BA > 0 &&
          VPL > 0 &&
          CDC > 0) &&
          (BI >= 0 ||
          PAI >= 0 ||
          CC >= 0 ||
          DI >= 0 ||
          DIPO >= 0 ||
          DIPOC >= 0 ||
          PAE >= 0 ||
          PCD >= 0 ||
          GF >= 0) &&
          EXCEDENTE <= 0):
          return 1;
          case (
            (BA > 0 &&
            VPL > 0 &&
            CDC > 0) &&
            (BI >= 0 ||
            PAI >= 0 ||
            CC >= 0 ||
            DI >= 0 ||
            DIPO >= 0 ||
            DIPOC >= 0 ||
            PAE >= 0 ||
            PCD >= 0 ||
            GF >= 0) &&
            EXCEDENTE >= 0):
            return 2;
          default :
            return 0;
      }
    } else {
      switch (true) {
        case(this.opc.tipoPlan === 'ALIADOSKIDS'):
          if(EXCEDENTE <= 0)
            return 1;
          else
            return 2;
        case (
          // BASICA -> SI
          BA > 0 &&
          // ADICIONALES -> NO
          (
            BI <= 0 &&
            PAI <= 0 &&
            CC <= 0 &&
            DI <= 0 &&
            DIPO <= 0 &&
            DIPOC <= 0 &&
            IAC <= 0 &&
            PAE <= 0 &&
            PCD <= 0 &&
            GF <= 0
          ) &&
          // EXCEDENTES -> NO
          EXCEDENTE <= 0
        ):
          return 0;
        case (
          // BASICA -> SI
          BA > 0 &&
          // ADICIONALES -> SI
          (
            BI >= 0 ||
            PAI >= 0 ||
            CC >= 0 ||
            DI >= 0 ||
            DIPO >= 0 ||
            DIPOC >= 0 ||
            IAC >= 0 ||
            PAE >= 0 ||
            PCD >= 0 ||
            GF >= 0 
          ) &&
          // EXCEDENTES -> NO
          EXCEDENTE <= 0
        ):
          return 1;
        case (
          // BASICA -> SI
          BA > 0 &&
          // ADICIONALES -> SI
          (
            BI >= 0 ||
            PAI >= 0 ||
            CC >= 0 ||
            DI >= 0 ||
            DIPO >= 0 ||
            DIPOC >= 0 ||
            IAC >= 0 ||
            PAE >= 0 ||
            PCD >= 0 ||
            GF >= 0
          ) &&
          // EXCEDENTES -> NO
          EXCEDENTE >= 0
        ):
          return 2;
        default:
          return 2;
      }
    }


  }
}
