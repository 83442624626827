/** ANGULAR CORE */
import { Component, OnInit, ViewChild } from '@angular/core';
/** NGX - BOOTSTRAP */
import { BsModalRef } from 'ngx-bootstrap/modal';
/**
 * ALETA PARA OFRECER UNA MEJOR EXPERIENCIA OFFLINE
 * @export
 * @class AlertsupportComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-alertsupport',
  templateUrl: './alertsupport.component.html',
  styleUrls: ['./alertsupport.component.sass']
})
export class AlertsupportComponent implements OnInit {
  /** PROPIEDAD PARA LA COMUNICACIÓN ENTRE COMPONENTE PADRE Y COMPONENTE HIJO */
  @ViewChild('modalIosSupport') mod: BsModalRef;

  /** REFERENCIA AL MODAL */
  modalRef: BsModalRef;
  /**
   * Creates an instance of AlertsupportComponent.
   * @param {BsModalRef} _bsModalRef
   * @memberof AlertsupportComponent
   */

  constructor(private _bsModalRef: BsModalRef) { }
  /**
   * MÉTODO PRINCIPAL EN EL CUAL SE CARGAN LOS VALORES INICIALES
   *
   * @memberof AlertsupportComponent
   */
  ngOnInit() {
  }
  /**
   * MÉTODO PARA CERRAR EL MODAL
   * @memberof AlertsupportComponent
   */
  close() {
    this._bsModalRef.hide();
  }

}
