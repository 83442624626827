import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@shared/shared.module';

import { ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from "@angular/material/checkbox";


// Componente
import { ProspectusDataComponent } from '@components/prospectus-data/prospectus-data.component';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    BrowserAnimationsModule,
    MatRadioModule,
    TextMaskModule,
    // Formularios Reactivos
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  declarations: [
    ProspectusDataComponent
  ],
  exports: [
    ProspectusDataComponent,
    MatRadioModule
  ]
})
export class ProspectusDataModule { }
