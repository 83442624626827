/**
 * TIPO DE CAMBIO PARA REQUISITOS DE ASEGURABILIDAD DE VIDA
 * Médicos y financieros
 * @description Se específica el tipo de cambio de dólar o UDI a moneda nacional MXN.
 * de vida.
 *
 * Vigente a partir del 1° de Noviembre de 2016
 */
export const EXCHANGE_RATE_REQUIREMENTS = {
  // Dólar
  USD: 17,
  // Unidad de inversion
  UDI: 5
};
