/** Angular core */
import { Injectable } from '@angular/core';
/** Models */
import { Plazo } from '@models/plazo/plazo.interface';
/** Services */
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { BAService } from '@services/coverages/BA/ba.service';
import { FactoresService } from '@services/factores/factores.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
/** Environments */
import { environment } from 'environments/environment';
/** Rxjs */
import { BehaviorSubject, Subscription } from 'rxjs';
/** Constants */
import { PAI, PAIUniversal } from '@constants/coverages';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';

/**
 * Servicio para cobertura PAI
 */
@Injectable({
  providedIn: 'root'
})
export class PAIService {
  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);
  // Prima anual sugerida 2
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();
  /**
   * Selección por defecto
   * @property
   * @default true
   */
  DEFAULT = true;
  /**
   * Crea una suscripción almacenando la suma asegurada de la cobertura Básica
   * @property {Subscription}
   */
  sumaAsegurada_BA: Subscription;

  sumaAseguradaMXN_Universales = new BehaviorSubject<number>(0);
  sumaAseguradaUSD_Universales = new BehaviorSubject<number>(0);
  sumaAsegurada_MXN_Universales$ = this.sumaAseguradaMXN_Universales.asObservable();
  sumaAsegurada_USD_Universales$ = this.sumaAseguradaUSD_Universales.asObservable();
  /**
   * Constructor
   * @constructor
   * @param recursosExternos Crea instancia de RecursosExternosService
   * @param factores Crea instancia de FactoresService
   * @param BaService Crea instancia de BAService
   * @param prospectoService Crea instancia de ProspectoService
   */
  constructor(
    private recursosExternos: RecursosExternosService,
    private factores: FactoresService,
    private BaService: BAService,
    private prospectoService: ProspectoService,
    private opciones: OpcionesPlanService
  ) {
    this.sumaAsegurada_BA = this.BaService.sumaAseguradaUniversal.distinctUntilChanged()
      .subscribe(sumaAsegurada => {
        if (this.sumaAseguradaMXN_Universales.value > 0 &&
          this.sumaAseguradaMXN_Universales.value > sumaAsegurada) {
          this.obtenerPrimaU(0);
        }
      });
  }
  /**
   * Establece la prima anual en la cobertura dado el valor
   * @param sumaAseguradaUDI
   */
  establecePrimaAnual(sumaAseguradaUDI) {
    this.sumaAsegurada_BA = this.BaService.sumaAsegurada_UDI.distinctUntilChanged()
      .subscribe(sumaAsegurada => {
        this.obtenerPrima(sumaAseguradaUDI);
      });
  }
  /**
   * Obtiene la prima apartir del valor asignado
   * @param {number} sumaAsegurada
   */
  obtenerPrima(sumaAsegurada: number): void {
    // Variable de suma asegurada que contendra el valor final de la suma asegurada despues de ser evaluada.
    let suma = 0;

    /**
     * Si la suma asegurada inicial es igual a 0, tomará por defecto el valor de la suma asegurada basica
     * de lo contrario continuará evaluando la suma asegurada introducida.
    */
    this.DEFAULT = sumaAsegurada === 0;

    // Se obtienen la informacion del prospecto actual
    const prospecto = this.prospectoService.prospecto.value;

    // Evalua si la edad del prospecto esta dentro del rango de aceptación de la cobertura
    if (
      prospecto.age >= PAI.ACCEPTANCE_AGE.MIN &&
      prospecto.age <= PAI.ACCEPTANCE_AGE.MAX
    ) {

      /**
       * El -1 representa la exclusion de la cobertura
      */
      if (sumaAsegurada !== -1) {
        if (this.DEFAULT) {
          suma = this.BaService.sumaAsegurada_UDI.value;
          this.DEFAULT = false;
        } else {
          suma = sumaAsegurada || 0;
        }

        if (suma > this.BaService.sumaAsegurada_UDI.value || suma < this.recursosExternos.GetMinMax('BA', 'MIN')) {
          suma = this.BaService.sumaAsegurada_UDI.value;
        }

        if (this.opciones.tipoPlan === 'TEMPORAL' && this.opciones.moneda === 'MXN') {
          suma = this.recursosExternos.obtenerSumaAsegurada('PlanTmpPAI', suma);
        } else {
          suma = this.recursosExternos.obtenerSumaAsegurada('PAI', suma);
        }

        // if (suma <= this.recursosExternos.GetMinMax('PAI', 'MIN')) {
        //   suma = this.recursosExternos.GetMinMax('PAI', 'MIN');
        // }

        // if (suma >= this.recursosExternos.GetMinMax('PAI', 'MAX')) {
        //   suma = this.recursosExternos.GetMinMax('PAI', 'MAX');
        // }

        const primaPAI_UDI = this.calculoPrimaPAI(this.recursosExternos.FirstToUDI(suma), 'Elegido');
        const primaPAI_MXN = this.recursosExternos.UDIToSecond(primaPAI_UDI);

        const primaPAI_UDI_Sugerida1 = this.calculoPrimaPAI(this.recursosExternos.FirstToUDI(suma), 'Sugerido1');
        const primaPAI_MXN_Sugerida1 = this.recursosExternos.UDIToSecond(primaPAI_UDI_Sugerida1);

        const primaPAI_UDI_Sugerida2 = this.calculoPrimaPAI(this.recursosExternos.FirstToUDI(suma), 'Sugerido2');
        const primaPAI_MXN_Sugerida2 = this.recursosExternos.UDIToSecond(primaPAI_UDI_Sugerida2);

        const sumaAsegurada_UDI = suma;
        const sumaAsegurada_MXN = this.recursosExternos.FirstToSecond(sumaAsegurada_UDI);

        this.primaAnual_UDI.next(this.recursosExternos.UDIToFirst(primaPAI_UDI));
        this.primaAnual_MXN.next(primaPAI_MXN);

        this.primaAnual_UDI_Sugerida1.next(this.recursosExternos.UDIToFirst(primaPAI_UDI_Sugerida1));
        this.primaAnual_MXN_Sugerida1.next(primaPAI_MXN_Sugerida1);

        this.primaAnual_UDI_Sugerida2.next(this.recursosExternos.UDIToFirst(primaPAI_UDI_Sugerida2));
        this.primaAnual_MXN_Sugerida2.next(primaPAI_MXN_Sugerida2);

        this.sumaAsegurada_UDI.next(sumaAsegurada_UDI);
        this.sumaAsegurada_MXN.next(sumaAsegurada_MXN);


        if (!environment.production) {
        }

        return;

      }

    }
    this.resetValues();
    return;
  }

  /**
   * Realiza el calculo de la tarifa
   *
   * @param {number} calculoPrimaPAI
   * @returns {number} Prima PAI
   * @memberof PAIService
   */
  calculoPrimaPAI(sumaAseguradaBasica: number, plazo: Plazo, isINPC: boolean = false, edadInpc?: number): number {

    // Variables necesarias para el calculo
    const factorPAI = this.factores.obtenerFactorCobertura('PAI', plazo, isINPC, edadInpc);

    // Ejecuta la formula dada
    const primaPAI = factorPAI !== 0 ? (
      sumaAseguradaBasica * factorPAI / 1000
    ) : 0;

    // Retorna el resultado de la operación
    return primaPAI;
  }

  resetValues() {
    this.primaAnual_UDI.next(0);
    this.primaAnual_MXN.next(0);
    this.primaAnual_UDI_Sugerida1.next(0);
    this.primaAnual_MXN_Sugerida1.next(0);
    this.primaAnual_UDI_Sugerida2.next(0);
    this.primaAnual_MXN_Sugerida2.next(0);
    this.sumaAsegurada_UDI.next(0);
    this.sumaAsegurada_MXN.next(0);
    this.DEFAULT = true;
    this.sumaAseguradaMXN_Universales.next(0);
    this.sumaAseguradaUSD_Universales.next(0);
  }
  // Métodos para universales
  establecePrimaAnualU(sumaAseguradaMXN) {
    this.obtenerPrimaU(sumaAseguradaMXN);
  }

  obtenerPrimaU(sumaAsegurada: number): void {
    // Variable de suma asegurada que contendra el valor final de la suma asegurada despues de ser evaluada.
    let suma = 0;

    /**
     * Si la suma asegurada inicial es igual a 0, tomará por defecto el valor de la suma asegurada basica
     * de lo contrario continuará evaluando la suma asegurada introducida.
    */
    this.DEFAULT = sumaAsegurada === 0;

    // Se obtienen la informacion del prospecto actual
    const prospecto = this.prospectoService.prospecto.value;

    // Evalua si la edad del prospecto esta dentro del rango de aceptación de la cobertura
    if (
      prospecto.age >= PAIUniversal.ACCEPTANCE_AGE.MIN &&
      prospecto.age <= PAIUniversal.ACCEPTANCE_AGE.MAX
    ) {
      /**
       * El -1 representa la exclusion de la cobertura
      */
      if (sumaAsegurada !== -1) {
        if (this.DEFAULT) {
          suma = this.BaService.sumaAseguradaUniversal.value;
          this.DEFAULT = false;
        } else {
          suma = sumaAsegurada || 0;
        }
        if (suma > this.BaService.sumaAseguradaUniversal.value) {
          suma = this.BaService.sumaAseguradaUniversal.value;
        }
        if (suma < this.recursosExternos.GetMinMaxU('PAI', 'MIN', this.opciones.moneda, this.opciones.tipoPlan)) {
          suma = this.recursosExternos.GetMinMaxU('PAI', 'MIN', this.opciones.moneda, this.opciones.tipoPlan);
        }

        if (suma > this.recursosExternos.GetMinMaxU('PAI', 'MAX', this.opciones.moneda, this.opciones.tipoPlan)) {
          suma = this.recursosExternos.GetMinMaxU('PAI', 'MAX', this.opciones.moneda, this.opciones.tipoPlan);
        }

        const sumaAsegurada_MXN = suma;
        const sumaAsegurada_USD = this.recursosExternos.MXNtoUSDU(sumaAsegurada_MXN);

        this.sumaAseguradaMXN_Universales.next(sumaAsegurada_MXN);
        this.sumaAseguradaUSD_Universales.next(sumaAsegurada_USD);


        if (!environment.production) {
        }

        return;

      }

    }
    this.resetValues();
    return;
  }
}
