import { Component } from '@angular/core';

/**
 * Componente para información de
 * acciones no permitidas dentro la aplicación.
 *
 * @export
 * @class NotPermittedComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-not-permitted',
  templateUrl: './not-permitted.component.html',
  styleUrls: ['./not-permitted.component.sass']
})
export class NotPermittedComponent {

}
