/**
 *
 * CONSTANTES DE TIPOS DE PLANES DISPONIBLES EN AMBIENTE DE QA
 *
 */

/**
 * Tipos de plan.
 * @exports
 */
export type PlanType = 'TEMPORAL' | 'OV' | 'VPL' | 'DOTAL' | 'PROTECANCER' | 'PROTECCION EFECTIVA' | 'MUJER UNICA'|
'VIDA INTELIGENTE' | 'MI PROYECTO R' | 'TEMPORALMU' | 'ALIADOSKIDS';

/**
 * Tipos de plan (Títulos para negocio).
 * @const
 * @type {Array}
 * @param title
 * @param value
 */
export const PLANTYPES: {title: string, value: PlanType}[] = [
  { title: 'Temporal', value: 'TEMPORAL'},
  { title: 'Ordinario de vida', value:  'OV'},
  { title: 'Vida pagos límitados', value: 'VPL'},
  // Se desactiva aliados+ en version de cotizadorvida
  { title: 'Aliados+', value: 'DOTAL' },
  { title: 'Vida Inteligente', value: 'VIDA INTELIGENTE'},
  { title: 'Mi Proyecto R', value: 'MI PROYECTO R'},
  { title: 'Aliados+ Kids ', value: 'ALIADOSKIDS'}
];

/**
 * Tipo de plan por defecto.
 * @const
 * @type {PlanType}
 * @default "VPL"
 */
export const DEFAULT_PLANTYPE: PlanType = 'VPL';
