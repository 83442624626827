/**
 * Planes (títulos).
 * @exports
 */
export type Plan = 'ProTGT' | 'Aliados+' | 'AxaParaTodos' | 'MujerUnica'| 'Universales';

/**
 * Planes (valor y estado).
 * @const
 * @type {Array}
 * @param value
 * @param selected
 */
export const PLANS: {value: Plan, selected: boolean}[] = [
  { value: 'ProTGT', selected: true},
  { value: 'Aliados+', selected: false},
  { value: 'AxaParaTodos', selected: false},
  { value: 'MujerUnica', selected: false },
  { value: 'Universales', selected: false}
];

/**
 * Plan predeterminado.
 * @const
 * @type {Plan}
 * @default "ProTGT"
 */
export const DEFAULT_PLAN: Plan = 'ProTGT';
