import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(async () => {

    window.addEventListener('beforeinstallprompt', async (ev: any) => {
      ev.preventDefault();
      try {
        await ev.prompt();
      } catch (error) {}
    });

    /*if ('serviceWorker' in navigator) {
      try {
        const sw = await navigator.serviceWorker.register('./ngsw-worker.js');

        sw.update();
      } catch (error) {
        console.log(error);
      }
    }/** */

    if ('caches' in window) {
      caches.keys()
      .then(function(keyList) {
          return Promise.all(keyList.map(function(key) {
              return caches.delete(key);
          }));
      })
    }

    if(window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
      .then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister();
        }
      });
    }/** */

  })
  .catch(err => {});
