import { PaymentFrecuencyComponent } from '@shared/payment-frecuency/payment-frecuency.component';
// Módulos de Angular
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CollapsibleModule } from 'angular2-collapsible';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
// import { MatRadioModule } from '@angular/material/radio';

// Componentes
import { GeneralsComponent } from '@shared/generals/generals.component';
// import { InsurageRequirementsComponent } from '@shared/insurage-requirements/insurage-requirements.component';
import { ProspectusProfileComponent } from '@shared/prospectus-profile/prospectus-profile.component';
import { RequirementsComponent } from '@shared/requirements/requirements.component';
import { TableSettingsComponent } from '@shared/table-settings/table-settings.component';
import { VariableSettingsComponent } from '@shared/variable-settings/variable-settings.component';
import { InsurageCoverageComponent } from '@shared/insurage-coverage/insurage-coverage.component';
import { SurplusComponent } from '@shared/surplus/surplus.component';
import { AlertModalComponent } from '@shared/modal/alert-modal/alert-modal.component';

import { ModalModule } from 'ngx-bootstrap/modal';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { TextMaskModule } from 'angular2-text-mask';

// TABS
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatCardModule, MatRadioModule, MatTabsModule} from '@angular/material';
// Pipes
import { PipesModule } from '@pipes/pipes.module';
import { AlertIosComponent } from './modal/alert-ios/alert-ios.component';
import { AlertsupportComponent } from './modal/alertsupport/alertsupport.component';
import { OfflineconfigModalComponent } from './modal/offlineconfig-modal/offlineconfig-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CollapsibleModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatRadioModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    TextMaskModule,

    CdkTableModule,
    CdkTreeModule,
    MatRadioModule,
    MatTabsModule,
    PipesModule,
    ReactiveFormsModule
  ],
  declarations: [
    GeneralsComponent,
    // InsurageRequirementsComponent,
    PaymentFrecuencyComponent,
    ProspectusProfileComponent,
    RequirementsComponent,
    TableSettingsComponent,
    VariableSettingsComponent,
    InsurageCoverageComponent,
    AlertModalComponent,
    AlertIosComponent,
    SurplusComponent,
    AlertsupportComponent,
    OfflineconfigModalComponent
  ],
  exports: [
    GeneralsComponent,
    // InsurageRequirementsComponent,
    PaymentFrecuencyComponent,
    ProspectusProfileComponent,
    RequirementsComponent,
    TableSettingsComponent,
    VariableSettingsComponent,
    InsurageCoverageComponent,
    SurplusComponent,

    CdkTableModule,
    CdkTreeModule,
    MatCardModule,
    MatRadioModule,
    MatTabsModule
  ],
  entryComponents: [
    AlertModalComponent,
    AlertIosComponent,
    OfflineconfigModalComponent ]
})
export class SharedModule { }
