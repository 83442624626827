import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ROUTE_NAMING } from '../../constants';
import { RoutesService } from '@services/routes/routes.service';

// Pipes
import { MenuPipe } from '@pipes/menu-pipe/menu.pipe';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { DeviceTypeService } from '@app/services/deviceType/deviceType.service';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';

/**
 * COMPONENTE PARA LA BARRA DE PROGRESO
 * @export
 * @class ProgressMenuComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-progress-menu',
  templateUrl: './progress-menu.component.html',
  styleUrls: ['./progress-menu.component.sass'],
  providers: [MenuPipe]
})
export class ProgressMenuComponent implements OnInit {
  /** PROPIEDAD PARA ESTILO FIXED */
  fixed: any;
  /** VARIABLE QUE ALMACENA LA DIRECCIÓN DEL PROGRESO */
  flexDirection: string;
  /** VARIABLE PARA ALMACENAR EL PROCESO QUE VALLA AVANZANDO LA BARRA */
  process: string;
  /** VARIABLE QUE ALMACENA EL NÚMERO DE LOS PASOS */
  step: number;
  /** ARRAY | ARREGLO QUE OBTIENE LAS RUTAS INVOLUCRADAS EN EL FLUJO */
  rutas: string[] = [];
  /** ARRAY | ARREGLO QUE OBTIENE LAS RUTAS QUE SE VALLAN ACTIVANDO EN EL MOMENTO */
  activeRoutes: string[] = [];
  /** VARIABLE BOOLEANA PARA IDENTIFICAR SI EL SISTEMA IOS ESA OFFLINE/ONLINE */
  stepFlow: string;
  /** Comprueba si un dispositivo iOS está sin conexión */
  isIOSofline = false;
  /** ARRAY | ARRAY QUE OBTIENE LAS RUTAS DE A CUERDO A SU RESPECTIVO PASO */

  mode$: Observable<boolean>;

  validaMode: boolean;


  urls = [
    ROUTE_NAMING.PASO1,
    ROUTE_NAMING.PASO2B,
    ROUTE_NAMING.PASO2A,
    ROUTE_NAMING.PASO3,
    ROUTE_NAMING.PASO4,
    ROUTE_NAMING.REQUISITOS,
    ROUTE_NAMING.TARIFAS,
    ROUTE_NAMING.PERFILAMIENTO,
    ROUTE_NAMING.PASO4UNI,
    ROUTE_NAMING.PASO5UNI,
  ];

  /** VARIBLE PARA IDENTIFICAR SI SE ESTA EJECUTANDO EN UN DISPOSITIBO MOBILE */
  isMobile: boolean;
  /** VARIABLE QUE HACE UNA INSTANCIA A UN VALOR BOOLEANO */
  online$: Observable<boolean>;

  amIOnLanding: boolean;

  chosenCotizador: string;

  /**
   * Creates an instance of ProgressMenuComponent.
   * @param {Router} router
   * @param {RoutesService} routesService
   * @memberof ProgressMenuComponent
   */
  constructor(
    private router: Router,
    public routesService: RoutesService,
    public device: DeviceTypeService,
    public opciones: OpcionesPlanService
  ) {
    this.createOnline$();
    this.generarRutas();
    this.process = '';
    this.step = 0;
    this.isMobile = false;
    // Actualiza el flujo flexible al cambiar de ruta
    router.events.pipe(
      filter( event => event instanceof NavigationStart)
    )
    .subscribe( (event: NavigationStart) => {
      const currentRute = event.url.split('/')[3];

      if (event.url.split('/')[2] === ROUTE_NAMING.LANDING) {
        this.amIOnLanding = true;
      } else {
        this.amIOnLanding = false;
        this.updateFlowProcess(currentRute);
      }
    });

    this.routesService.chosenCotizador.subscribe(cotizador => {
      this.chosenCotizador = cotizador;
    });

    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(true)),
    );

    this.online$.subscribe(result => {
      this.isIOSofline = true;
    });

    this.routesService.isIndividual.subscribe(isIndividual => {
      this.updateFlowProcess(this.router.url.split('/')[3]);
    });

  }

  /**
   * MÉTODO PARA GENERAR LAS RUTAS QUE CONTENGAN LA BARRA
   * @memberof ProgressMenuComponent
   */
  generarRutas() {
    this.routesService.routes$.subscribe(rutas => {
      this.rutas = rutas;
    });
  }

  /**
   * MÉTODO PRINCIPAL, EN EL CUAL ESTAN LAS VARIABLES
   * O PROPIEDADES QUE SE EJECUTARAN EN PRIMERA INSTANCIA
   * @memberof ProgressMenuComponent
   */
  ngOnInit() {
    // Default value to flex design
    this.isMobile = window.innerWidth <= 768;
    const currentRute = this.router.url.split('/')[3];
    this.updateFlowProcess(currentRute);
  }

  /**
   * MÉTODO PARA IR A ALGÚN PASO SELECCIONADO
   * @param {number} step
   * @memberof ProgressMenuComponent
   */
  goToStep(step: number): void {
    this.process = this.adjustFlexDesign(step);
  }

  // Actualiza el diseño flex de la barra laterla de progreso, dependiendo de la ruta actual.
  /**
   * MÉTODO PARA ACTUALIZAR EL FLUJO DEL PROGRESS
   * @param {string} currentRute
   * @memberof ProgressMenuComponent
   */
  updateFlowProcess(currentRute: string) {
    let indexOfActiveRuteUrls = this.urls.indexOf(currentRute);

    if (indexOfActiveRuteUrls === 0) { indexOfActiveRuteUrls++; }
    if (currentRute === 'identificar-objetivo') { indexOfActiveRuteUrls = 2; }

    this.process = this.adjustFlexDesign(
      indexOfActiveRuteUrls !== 4 ?
      indexOfActiveRuteUrls : 4
    );

    switch (currentRute) {
      case 'datos-prospecto':
        this.activeRoutes = [];
        this.stepFlow = '1';
      break;
      case 'arma-tu-plan':
        this.activeRoutes = ['datos-prospecto'];
        this.stepFlow = '2';
      break;
      case 'identificar-objetivo':
        this.activeRoutes = ['datos-prospecto'];
        this.stepFlow = '2';
      break;
        case 'propuestas':
        this.activeRoutes = ['datos-prospecto', 'arma-tu-plan', 'identificar-objetivo'];
        this.stepFlow = '3';
      break;
      case 'tarifas':
        this.activeRoutes = ['datos-prospecto', 'arma-tu-plan'];
        this.stepFlow = '3';
      break;
      case 'valores-garantizados':
        this.activeRoutes = ['datos-prospecto', 'arma-tu-plan', 'identificar-objetivo', 'propuestas', 'requisitos-asegurabilidad'];
        this.stepFlow = '4';
      break;
      case 'requisitos-asegurabilidad':
        this.activeRoutes = ['datos-prospecto', 'arma-tu-plan', 'identificar-objetivo', 'tarifas', 'propuestas', 'valores-garantizados',
        'estrategia-inversion', 'primas-exceentes', 'valores-proyectados'];
        // this.chosenCotizador === 'AxaParaTodos' ? this.stepFlow = '4' : this.stepFlow = '5';
        if (this.chosenCotizador === 'AxaParaTodos') {
          this.stepFlow = '4';
        }
        if (this.chosenCotizador === 'Universales') {
          this.stepFlow = '6';
        }
        if (this.chosenCotizador !== 'AxParaTodos' && this.chosenCotizador !== 'Universales') {
          this.stepFlow = '5';
        }
        // this.chosenCotizador === 'AxaParaTodos' ? this.stepFlow = '4' : this.chosenCotizador === 'Universales' ? this.stepFlow = '6' : this.stepFlow = '5';
      break;
      case 'estrategia-inversion':
        this.activeRoutes = ['datos-prospecto', 'arma-tu-plan'];
        this.stepFlow = '3';
      break;
      case 'primas-excedentes':
          this.activeRoutes = ['datos-prospecto', 'arma-tu-plan', 'estrategia-inversion'];
          this.stepFlow = '4';
      break;
      case 'valores-proyectados':
          this.activeRoutes = ['datos-prospecto', 'arma-tu-plan', 'estrategia-inversion', 'primas-excedentes', 'requisitos-asegurabilidad'];
          this.stepFlow = '5';
      break;
    }
  }

  /**
   * MÉTODO PARA ENCONTRAR LA RUTA ACTIVA
   * @param {string} givRoute
   * @returns {boolean}
   * @memberof ProgressMenuComponent
   */
  findActiveRoute(givRoute: string): boolean {
    return this.activeRoutes.find(route => route === givRoute) === givRoute;
  }

  /**
   * Adjust flex design to corresponding step
   *
   * @param {number} step
   * @memberof ProgressMenuComponent
   */
  adjustFlexDesign(step: number): string {
    let percentage: string;

    if (this.routesService.chosenCotizador.getValue() !== 'AxaParaTodos' && this.routesService.chosenCotizador.getValue() !== 'Universales') {
      if (!this.isMobile && step === 4) {
        step = 4.3;
      } else if (!this.isMobile && step === 5) {
        step = 5.6;
      }

      this.isMobile ?
        percentage = (step * 20).toString()
        : percentage = (step * 18).toString();
    }
    if (this.routesService.chosenCotizador.getValue() === 'AxaParaTodos') {
      if (!this.isMobile && (step === 4 || step === 5)) {
        step = 3.33;
      }

      if (step === 2 && !this.routesService.isIndividual.getValue()) {
        step = 2.3;
      }

      if (step === 6) {
        step = 3.3;
      }

      this.isMobile  ?
        percentage = (step * 33).toString()
        : percentage = (step * 30).toString();
    }

    if (this.routesService.chosenCotizador.getValue() === 'Universales') {
      if (step === 1) {
        // console.log('entro');
        step = 0.5;
      }
      if (!this.isMobile && (step === 4 || step === 5)) {
        step = 3.33;
      }
      // console.log(step, this.isMobile);
      if (step === 2) {
       step = 1;
      }

     if (step === 7 || step === 3) {
      step = 1.65;
     }

     if (step === 6) {
       step = 3.33;
     }
     if (step === 8) {
       step = 2.15;
     }

      this.isMobile && step === 7  ?
        step = 1.50
        : percentage = (step * 30).toString();
    }

    return `0 0 ${percentage}%`;
  }

  /**
   * MÉTODO QUE DETECTA LA RESOLUCIÓN DE LA PANTALLA
   * @param {*} event
   * @memberof ProgressMenuComponent
   */
  onResize(event: any) {
    this.isMobile = (event.target.innerWidth <= 768);

    this.updateFlowProcess(this.router.url.split('/')[3]);

  }

  /**
   * MÉTODO PARA DAR EL DIRECCIONAMIENTO
   * A LA BARRA DE PROGRESO. HORIZONTAL (ROW) | VERTICAL (COLUMN)
   * @returns
   * @memberof ProgressMenuComponent
   */
  flexDirectionResize() {
    return this.isMobile ? `row` : `column`;
  }

  createOnline$() {
    return true;
  }

}
