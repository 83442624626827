import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';

// Componente
import { TextMaskModule } from 'angular2-text-mask';
import { SurplusComponent } from '@components/surplus/surplus.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MatTabsModule } from '@angular/material';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SharedModule } from '@shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
@NgModule({
  imports: [
    BrowserAnimationsModule,
    MatRadioModule,
    TextMaskModule,
    MatTabsModule,
    CollapseModule,
    ReactiveFormsModule,
    CarouselModule.forRoot(),
    SharedModule,
    MatPaginatorModule,
    FormsModule
  ],
  declarations: [
    SurplusComponent
  ],
  exports: [
    SurplusComponent,
    MatRadioModule
  ]
})
export class SurplusModule { }

