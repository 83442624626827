import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cvida-cotizadores',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./cotizadores.component.sass']
})
export class CotizadoresComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}
