import { ResizeDetectionService } from '@services/resizeDetection/resize-detection.service';
import { Router } from '@angular/router';
import { CalculosService } from '@services/calculos/calculos.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Prospectu, Gender, Fumador } from '../../models';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { PROSPECTUS_DATA_CONSTANTS } from '@constants/prospectus-data/prospectus-data.constant';
import { FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { ROUTE_NAMING, PROTECTION_TERMS } from '../../constants';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal/.';
import { FactoresService } from '@app/services/factores/factores.service';
import { RoutesService } from '@app/services/routes/routes.service';
import { ProtectionPlansGeneratorService } from '@app/services/protectionPlansGenerator/protection-plans-generator.service';

/**
 * Componente del perfil del prospecto
 */
@Component({
  selector: 'cvida-prospectus-profile',
  templateUrl: './prospectus-profile.component.html',
  styleUrls: ['./prospectus-profile.component.sass']
})
export class ProspectusProfileComponent implements OnInit {
  /**
   * Prospecto actual
   * @property
   * @type {Prospectu}
   */
  prospectu: Prospectu;
  /**
   * Formulario del prospecto
   * @property
   * @type {FormGroup}
   */
  datosProspecto: FormGroup;
  /**
   * Género seleccionado
   * @property
   * @type {Gender}
   */
  currentGender: Gender;
  /**
   * Hábito seleccionado
   * @property
   * @type {Fumador}
   */
  currentFumador: Fumador;
  /**
   * Evento de página
   * @property
   * @type {PageEvent}
   */
  pageEvent: PageEvent;
  /**
   * Género seleccionado
   * @property
   * @type {string}
   */
  genero: string;
  /**
   * Hábito seleccionado
   * @property
   * @type {string}
   */
  fumador: string;
  generoMancomunado: string;
  fumadorMancomunado: string;
  iAmOnGuarantesValues: boolean;
  /**
   * Checa si está en valores garantizados
   * @property
   * @type {boolean}
   */
  iAmOnGuaranteedValues = true;
  /**
   * Máscara de edad
   * @property
   * @type {RegExp[]}
   */
  ageMask: RegExp[] = [/[1-9]/, /[0-9]/];
  /**
   *  Edad
   * @property
   * @type {String[]}
   */
  name: string[] = [];

  /**
   * g
   * @property
   * @type {String}
   */
  g: string;

  /**
   * Referencia del modal
   * @property
   * @type {BsModalRef}
   */
  modalRef: BsModalRef;

  /**
   * Modal Loader
   * @property
   */

  producto: String;
  @ViewChild('loader')
  loader: TemplateRef<any>;

  /**
   * Constructor
   * @param {ProspectoService} prospectoService
   * @param {OpcionesPlanService} opcionesPlan
   * @param {BsModalService} modalService
   * @param {CalculosService} calculosService
   * @param {Router} router
   * @param {ResizeDetectionService} resizeDetection
   * @memberof ProspectusProfileComponent
   */
  constructor(
    private prospectoService: ProspectoService,
    private opcionesPlan: OpcionesPlanService,
    private modalService: BsModalService,
    private calculosService: CalculosService,
    private router: Router,
    public resizeDetection: ResizeDetectionService,
    private factorService: FactoresService,
    private routes: RoutesService,
    private generator: ProtectionPlansGeneratorService
  ) {
    this.prospectoService.prospectoActual.subscribe(
      data => (this.prospectu = data)
    );
    this.routes.chosenCotizador.subscribe(cotizador => this.setProducto(cotizador));  
  }

  /**
   * Lifecycle Hook de OnInit
   * @method
   */
  ngOnInit() {
    this.currentGender = <Gender>PROSPECTUS_DATA_CONSTANTS.DEFAULT_GENDER;
    this.currentFumador = PROSPECTUS_DATA_CONSTANTS.DEFAULT_HABIT as Fumador;
    this.setValueData();
    this.splitName();
  }

  isFemaleActive(mancomunado: boolean): boolean {
    if (mancomunado) {
      return this.prospectu.mancomunado.genderMancomunado === 'M';
    } else {
      return this.prospectu.gender === 'M';
    }
  }

  isKidsFemActive(kids: boolean): boolean {
    if (kids) {
      return this.prospectu.kids.genderKids === 'M';
    } else {
      return this.prospectu.gender === 'M';
    }
  }

  isColorActive(mancomunado: boolean): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    if (mancomunado) {
      return this.prospectu.mancomunado.genderMancomunado === 'M';
    } else {
      return this.prospectu.gender === 'M';
    }
  }

  /**
   * Selecciona el primer nombre del input
   * @method
   * @return {string}
   */
  splitName() {
    this.name = this.prospectu.name.split(' ');
    return this.name;
  }
  /**
   * Retorna si el prospecto es hombre
   * @method
   * @return {boolean}
   */
  isMan() {
    if (this.prospectu.gender === 'H') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Retorna si el prospecto es mujer
   * @method
   * @return {boolean}
   */
  isWoman() {
    if (this.prospectu.gender === 'M') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Retorna si el prospecto es fumador
   * @method
   * @return {boolean}
   */
  isFumador() {
    if (this.prospectu.fumador === 'SI') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Retorna si el prospecto no es fumador
   * @method
   * @return {boolean}
   */
  isNoFumador() {
    if (this.prospectu.fumador === 'NO') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Deshabilita la edición del prospecto en paso 4
   * @method
   * @return {boolean}
   */
  itsDisabled(): boolean {
    return this.router.url.split('/')[3] === ROUTE_NAMING.PASO4 || this.router.url.split('/')[3] === ROUTE_NAMING.TARIFAS;
  }

  /**
   * Retorna si el prospecto es mujer
   * @method
   * @return {boolean}
   */
  /*isFemaleActive(): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    return this.prospectu.gender === 'M';
  }*/
  /**
   * Retorna si el color del prospecto es correspondiente a mujer
   * @method
   * @return {boolean}
   */
  /* isColorActive(): boolean {
    // BUG - Se repite varias veces la compobación - arreglar
    return this.prospectu.gender === 'M';
  }
  */
  /**
   * Limpia el input
   * @param input
   */
  cleanInput(input: HTMLInputElement) {
    if (
      input.value === '0' &&
      this.router.url.split('/')[2] !== ROUTE_NAMING.PASO4
    ) {
      input.value = '';
    }
  }

  /**
   * Cambia el nombre del prospecto
   * @method
   * @param data
   */
  changeName(data) {
    this.prospectu.name = data;
  }

  /**
   * Cambia datos del prospecto
   * @method
   */
  setValueData() {
    this.fumador = this.prospectu.fumador === 'NO' ? 'No Fumador' : 'Fumador';
    this.genero = this.prospectu.gender === 'H' ? 'Hombre' : 'Mujer';
    this.fumadorMancomunado =
      this.prospectu.mancomunado.fumadorMancomunado === 'NO'
        ? 'No Fumador'
        : 'Fumador';
    this.generoMancomunado =
      this.prospectu.mancomunado.genderMancomunado === 'H' ? 'Hombre' : 'Mujer';
    this.prospectu.mancomunado.nameMancomunado =
      this.prospectu.mancomunado.nameMancomunado === ''
        ? 'Mancomunado'
        : this.prospectu.mancomunado.nameMancomunado;
    this.prospectoService.changeProspecto(this.prospectu);
  }

  setProducto(producto: string): void {
    switch (true) {
      case producto === 'Aliados':
        if(this.prospectu.kids.kids)
          this.producto = 'Aliados +Kids';
        else
          this.producto = 'Aliados +';
      break;
      case producto === 'ProTGT':
        this.producto = 'Vida ProTGT';
      break;
      case producto === 'AxaParaTodos':
        this.opcionesPlan.tipoPlan === 'PROTECCION EFECTIVA' ?
          this.producto = 'Protección Efectiva' : this.producto = 'Protecáncer';
      break;
    }
  }

  /**
   * Actualiza el prospecto
   * @method
   * @param data
   */
  updateProspectus(data) {
    this.modalRef = this.modalService.show(this.loader, {
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'loader-modal'
    });

    if (this.router.url.split('/')[2] !== ROUTE_NAMING.PASO4) {
      if (
        data === 'h' ||
        data === 'H' ||
        data === 'hombre' ||
        data === 'HOMBRE' ||
        data === 'Hombre'
      ) {
        this.prospectu.gender = 'H';
        this.genero = 'Hombre';
      } else if (
        data === 'm' ||
        data === 'M' ||
        data === 'mujer' ||
        data === 'MUJER' ||
        data === 'Mujer'
      ) {
        this.prospectu.gender = 'M';
        this.genero = 'Mujer';
      }

      if (data === 's' || data === 'S' || data === 'si' || data === 'SI') {
        this.prospectu.fumador = 'SI';
        this.fumador = 'Fumador';
      } else if (
        data === 'n' ||
        data === 'N' ||
        data === 'no' ||
        data === 'NO'
      ) {
        this.prospectu.fumador = 'NO';
        this.fumador = 'No Fumador';
      }

      this.handleAge(data);

      this.prospectoService.changeProspecto(this.prospectu);

      const promises =
        this.routes.chosenCotizador.value === 'AxaParaTodos'
          ? [this.opcionesPlan.generarFactores()]
          : [
              this.opcionesPlan.generarFactores(),
              this.opcionesPlan.generarFactoresValoresG()
            ];

      Promise.all(promises).then(() => {
        this.calculosService.RECALCULAR();
        if (this.prospectu.age <= 60 && this.opcionesPlan.tipoPlan === 'DOTAL' ) {
          this.factorService.obtenerFactorDedicibleAliasdosMas65(this.prospectu);
        }
        this.modalRef.hide();
      });
    }
  }

  /**
   * Maneja los casos de la edad cuando sea editada
   * @method
   * @private
   * @param {number} edad
   * @memberof ProspectusProfileComponent
   */
  private handleAge(edad: number): void {
    let planInfo: any = PROTECTION_TERMS.filter(
      plan => plan.plan === this.opcionesPlan.tipoPlan
    );

    if (this.routes.chosenCotizador.value !== 'AxaParaTodos') {
      this.opcionesPlan.plazoPago.value >= 60 || this.opcionesPlan.tipoPlan === 'OV'
        ? (planInfo = planInfo.map(
            plan =>
              plan.reachedAge.filter(
                p => p.term === Number(this.opcionesPlan.plazoPago.value)
              )[0]
          )[0])
        : (planInfo = planInfo.map(
            plan =>
              plan.paymentTerms.filter(
                p => p.term === Number(this.opcionesPlan.plazoPago.value)
              )[0]
          )[0]);
      const minAge: number = planInfo.minAge;
      const maxAge: number = planInfo.maxAge;

      Number(edad) >= minAge && Number(edad) <= maxAge ?
        this.prospectu.age = Number(edad) :
        this.prospectu.age = 18;
    } else {
      /* Al ser el único plazo de pago no hay necesidad de iterarlos */
      const minAge: number = planInfo[0].paymentTerms[0].minAge;
      const maxAge: number = planInfo[0].paymentTerms[0].maxAge;

      if (this.opcionesPlan.tipoPlan === 'PROTECANCER') {
        const maxAgePE = PROTECTION_TERMS.filter(plan => plan.plan === 'PROTECCION EFECTIVA')[0].paymentTerms[0].maxAge;

        if (Number(edad) >= minAge && Number(edad) <= maxAge) {
          this.prospectu.age = Number(edad);
        } else if (Number(edad) >= minAge && Number(edad) <= maxAgePE) {
          const productos = this.generator.products.value.map(producto => {
            producto.selected = false;

            if (producto.value === 'PROTECCION EFECTIVA') {
              producto.selected = true;
            }

            return producto;

          });

          this.prospectu.age = Number(edad);
          this.opcionesPlan.tipoPlan = 'PROTECCION EFECTIVA';

          this.generator.products.next(productos);
        } else {
          const productos = this.generator.products.value.map(producto => {
            producto.selected = false;

            if (producto.value === 'PROTECCION EFECTIVA') {
              producto.selected = true;
            }

            return producto;
          });

          this.prospectu.age = 18;
          this.opcionesPlan.tipoPlan = 'PROTECCION EFECTIVA';
          this.generator.products.next(productos);
        }
      } else {
        Number(edad) >= minAge && Number(edad) <= maxAge ?
        this.prospectu.age = Number(edad) :
        this.prospectu.age = 18;
      }

    }
  }

  /**
   * Máscara de nombre
   * @method
   * @param rawValue
   * @type {string}
   * @return {regExp[]}
   */
  nameMask(rawValue: string): RegExp[] {
    const mask = /[A-Za-zÁÉÍÓÚáéíóú ]/;
    const strLength = String(rawValue).length;
    const nameMask: RegExp[] = [];

    for (let i = 0; i <= strLength; i++) {
      nameMask.push(mask);
    }

    return nameMask;
  }
}
