/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { BA, BAUniversal } from '@constants/coverages';
/** Services */
import { FactoresService } from '@services/factores/factores.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';
/** Models */
import { Plazo } from '@models/plazo/plazo.interface';

/**
 * Abastrae los cálculos de la cobertura Básica
 * @class
 * @export
 */
@Injectable({
  providedIn: 'root'
})
export class BAService {
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_MXN = new BehaviorSubject<number>(0);

  sumaAseguradaMasiva_1 = new BehaviorSubject<number>(0);
  sumaAseguradaMasiva_2 = new BehaviorSubject<number>(0);
  sumaAseguradaMasiva_3 = new BehaviorSubject<number>(0);

  primaAnualMasiva_1 = new BehaviorSubject<number>(0);
  primaAnualMasiva_2 = new BehaviorSubject<number>(0);
  primaAnualMasiva_3 = new BehaviorSubject<number>(0);

  sumaAseguradaUniversal = new BehaviorSubject<number>(0);
  sumaAseguradaConvertUniversal = new BehaviorSubject<number>(0);


  /**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);

  // Prima anual Sugerida 1
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);

  // Prima anual sugerida 2
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();
 /**
   * Variable con el tipoPlan ALIADOSKIDS
   * @property
   * @type string
   * @default 0
   */
  kidsPlan: boolean;

  /**
   * Constructor
   * @constructor
   * @param prospectuService Crea instancia de ProspectusService
   * @param recursosExternos Crea instancia de RecursosExternosService
   * @param factores Crea instancia de FactoresService
   * @param opcionesPlan crea instancia de OpcionesService
   */
  constructor(
    private prospectuService: ProspectoService,
    private recursosExternos: RecursosExternosService,
    private factores: FactoresService,
    private opcionesPlan: OpcionesPlanService
  ) {
    this.prospectuService.prospectoActual
      .distinctUntilChanged()
      .subscribe(prospecto => {
        this.kidsPlan = prospecto.kids.kids;   
        this.establecePrimaAnual(this.sumaAsegurada_UDI.value);
      });
    }

  /**
   * Establece el valor de la prima anual en la moneda principal
   *
   * @param {number} valorNuevo
   * @memberof BAService
   */
  establecePrimaAnual(valorNuevo: number): void {
    // Establece un valor predeterminado de la suma asegurada de BA.
    let sumaAsegurada;
    // let sumaAseguradaUni;
    switch (this.opcionesPlan.moneda) {
      case 'UDI':
        sumaAsegurada = ( this.kidsPlan ? BA.MIN_SUM_KIDS : BA.MIN_SUM_UDI );
        break;
      case 'MXN':
          this.opcionesPlan.tipoPlan === 'PROTECCION EFECTIVA'
          ? (sumaAsegurada = BA.MIN_SUM_PRO_EF)
          : (sumaAsegurada = BA.MIN_SUM_MXN);
      break;
      case 'USD':
        sumaAsegurada = BA.MIN_SUM_USD;
        break;
    }
    if (this.opcionesPlan.tipoPlan === 'MUJER UNICA') {
      sumaAsegurada = this.recursosExternos.GetMinMax(this.opcionesPlan.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN');
    } else if (this.opcionesPlan.tipoPlan === 'TEMPORAL' && this.opcionesPlan.moneda === 'MXN') {
      sumaAsegurada = this.recursosExternos.GetMinMax('PlanTmp', 'MIN');
    }
    /** Si el nuevo valor es mayor al predeterminado,
     * Lo establece, ya que no hay límite de la cobertura
     * Básica.
     */
    /** Excepto en PRotección Efectiva */
    if (valorNuevo >= sumaAsegurada && isFinite(valorNuevo)) {
      if (this.opcionesPlan.tipoPlan === 'PROTECCION EFECTIVA') {
        valorNuevo > BA.MAX_SUM_PRO_EF
          ? (sumaAsegurada = BA.MAX_SUM_PRO_EF)
          : (sumaAsegurada = valorNuevo);

        const multiple = 10e4;

        if (sumaAsegurada % multiple !== 0) {
          const nearestThousand =
            Math.round(sumaAsegurada / multiple) * multiple;
          sumaAsegurada = nearestThousand;
        }
      }       
      else if(this.opcionesPlan.tipoPlan !== 'ALIADOSKIDS') {
        if (
          valorNuevo <= this.recursosExternos.GetMinMax(this.opcionesPlan.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN')
        ) {
          sumaAsegurada = this.recursosExternos.GetMinMax(this.opcionesPlan.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN');
        } else if (valorNuevo <= this.recursosExternos.GetMinMax(this.opcionesPlan.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN')) {
          sumaAsegurada = this.recursosExternos.GetMinMax(this.opcionesPlan.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN');
        } else if (valorNuevo >= this.recursosExternos.GetMinMax(this.opcionesPlan.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MAX')) {
          sumaAsegurada = this.recursosExternos.GetMinMax(this.opcionesPlan.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MAX');
        } else {
          sumaAsegurada = valorNuevo;
        }
      }
      else if(this.opcionesPlan.tipoPlan === 'ALIADOSKIDS'){
        if(valorNuevo < BA.MIN_SUM_KIDS ){
          sumaAsegurada = BA.MIN_SUM_KIDS;
        }else if( valorNuevo > BA.MAX_SUM_KIDS ){
          sumaAsegurada = BA.MAX_SUM_KIDS;
        }else{
          sumaAsegurada = valorNuevo;
        }
      }
    }
    // Realiza el calculo de las primas
    const resultadoPrimaBasica = this.calculoPrimaBasica(
      sumaAsegurada,
      'Elegido'
    );
    const resultadoPrimaBasica_Sugerida1 = this.calculoPrimaBasica(
      sumaAsegurada,
      'Sugerido1'
    );
    const resultadoPrimaBasica_Sugerida2 = this.calculoPrimaBasica(
      sumaAsegurada,
      'Sugerido2'
    );

    // Convierte la suma asegurada de UDI a MXN
    const sumaAsegurada_MXN = this.recursosExternos.FirstToSecond(
      sumaAsegurada
    );
    // Guarda la suma asegurada en la moneda principal
    this.sumaAsegurada_UDI.next(sumaAsegurada);

    // Guarda el valor de la sumaAsegurada MXN
    this.sumaAsegurada_MXN.next(sumaAsegurada_MXN);

    // Guarda el resultado del calc +o de la prima básica
    this.primaAnual_UDI.next(resultadoPrimaBasica);
    this.primaAnual_UDI_Sugerida1.next(resultadoPrimaBasica_Sugerida1);
    this.primaAnual_UDI_Sugerida2.next(resultadoPrimaBasica_Sugerida2);

    // Convierte la prima básica de UDI a MXN
    const primaAnual_MXN = this.recursosExternos.FirstToSecond(
      resultadoPrimaBasica
    );
    const primaAnual_MXN_Sugerida1 = this.recursosExternos.FirstToSecond(
      resultadoPrimaBasica_Sugerida1
    );
    const primaAnual_MXN_Sugerida2 = this.recursosExternos.FirstToSecond(
      resultadoPrimaBasica_Sugerida2
    );

    // Guarda el valor de la prima básica en MXN
    this.primaAnual_MXN.next(primaAnual_MXN);
    this.primaAnual_MXN_Sugerida1.next(primaAnual_MXN_Sugerida1);
    this.primaAnual_MXN_Sugerida2.next(primaAnual_MXN_Sugerida2);
  }

  establecePrimaAnualMasivo(valorNuevo: number, cantidadPrimas?: number): void {
    let sumaAsegurada = BA.MIN_SUM_PRO_EF;

    if (valorNuevo >= sumaAsegurada && isFinite(valorNuevo)) {
      valorNuevo > BA.MAX_SUM_PRO_EF
        ? (sumaAsegurada = BA.MAX_SUM_PRO_EF)
        : (sumaAsegurada = valorNuevo);

      const multiple = 10e4;

      if (sumaAsegurada % multiple !== 0) {
        const nearestThousand = Math.round(sumaAsegurada / multiple) * multiple;
        sumaAsegurada = nearestThousand;
      }
    }

    const resultadoPrimaBasica = this.calculoPrimaBasica(
      sumaAsegurada,
      'Elegido'
    );

    switch (cantidadPrimas) {
      case 1:
        this.sumaAseguradaMasiva_1.next(sumaAsegurada);
        this.primaAnualMasiva_1.next(resultadoPrimaBasica);
        break;
      case 2:
        this.sumaAseguradaMasiva_2.next(sumaAsegurada);
        this.primaAnualMasiva_2.next(resultadoPrimaBasica);
        break;
      case 3:
        this.sumaAseguradaMasiva_3.next(sumaAsegurada);
        this.primaAnualMasiva_3.next(resultadoPrimaBasica);
        break;
    }
  }

  /**
   * Realiza el calculo de la prima básica en base a la suma asegurada proporcionada y el plazo
   *
   * @param {number} sumaAsegurada
   * @param {Plazo} plazo
   * @returns {number} Prima Básica
   * @memberof BAService
   */
  calculoPrimaBasica(sumaAsegurada: number, plazo: Plazo, factor?: number): number {
    // Variables necesarias para el calculo
    let recargoFijo = this.opcionesPlan.obtenerRecargoFijo(
      this.opcionesPlan.moneda || 'UDI'
    );
    let factorTarifa;
    factor ? factorTarifa = factor : factorTarifa = this.factores.obtenerFactorCobertura('BASICA', plazo);
    let descuentoPorVolumen = this.opcionesPlan.obtenerDescuento(sumaAsegurada);

    if (this.opcionesPlan.tipoPlan === 'PROTECANCER') {
      recargoFijo = 200;
      descuentoPorVolumen = 1;
    } else if (this.opcionesPlan.tipoPlan === 'PROTECCION EFECTIVA') {
      recargoFijo = 0;
      descuentoPorVolumen = this.opcionesPlan.obtenerDescuentoProteccionEfectiva(
        sumaAsegurada
      );
    } else if(this.opcionesPlan.tipoPlan === 'ALIADOSKIDS'){
      recargoFijo = 400;
      descuentoPorVolumen = 1;
    }

    let primaBasica = 0;

    if (factorTarifa !== 0) {
      switch (this.opcionesPlan.moneda) {
        case 'UDI':
          // prima udi
          primaBasica =
            recargoFijo +
            (sumaAsegurada * factorTarifa * descuentoPorVolumen) / 1000;
          break;
        default:
          // prima pesos / dolares
          primaBasica =
            (sumaAsegurada * factorTarifa * descuentoPorVolumen) / 1000;
          primaBasica += recargoFijo;
          break;
      }
      // Retorna el resultado de la operación
      return primaBasica;
    } else {
      return 0;
    }
  }

  /**
   * Realiza el calculo de la suma asegurada básica en base a la prima proporcionada
   *
   * @param {number} valorNuevo
   * @memberof BAService
   */
  estableceSumaAsegurada(valorNuevo: number): void {
    let primaBasica;
    let sumMin;
    let primaMax;
    let resultadoSumaAsegurada;
    // DEBE QUEDAR EN SEGUNDA MONEDA
    switch (this.opcionesPlan.moneda) {
      case 'UDI':
        sumMin = (this.opcionesPlan.tipoPlan === 'ALIADOSKIDS' ? BA.MIN_SUM_KIDS : BA.MIN_SUM_UDI);
        primaBasica = this.recursosExternos.FirstToSecond(
          this.calculoPrimaBasica(sumMin, 'Elegido'));
          if(this.opcionesPlan.tipoPlan === 'ALIADOSKIDS'){
            primaMax =this.recursosExternos.FirstToSecond(
              this.calculoPrimaBasica(BA.MAX_SUM_KIDS, 'Elegido'));     
          }    
        break;
      case 'MXN':
        primaBasica = this.recursosExternos.FirstToSecond(
          this.calculoPrimaBasica(BA.MIN_SUM_MXN, 'Elegido')
        );
        break;
      case 'USD':
        primaBasica = this.recursosExternos.FirstToSecond(
          this.calculoPrimaBasica(BA.MIN_SUM_USD, 'Elegido')
        );
        break;
    }
    /** Si el nuevo valor es mayor al predeterminado,
     * Lo establece, ya que no hay límite de la cobertura
     * Básica.
     */
    if(this.opcionesPlan.tipoPlan === 'ALIADOSKIDS' && valorNuevo > primaMax){
      primaBasica = primaMax;
    } else if (valorNuevo >= primaBasica && isFinite(valorNuevo)) {
      primaBasica = valorNuevo;
    }
    // Realiza el calculo de las sumas aseguradas
    if(this.opcionesPlan.tipoPlan === 'ALIADOSKIDS'){
      resultadoSumaAsegurada = this.calculoSumaAsegurada(
        this.recursosExternos.SecondToFirst(primaBasica),
        'Elegido', 1
      );
    }else{
      resultadoSumaAsegurada = this.calculoSumaAsegurada(
        this.recursosExternos.SecondToFirst(primaBasica),
        'Elegido'
      );
    }
    

    const primaAsegurada_UDI = this.recursosExternos.SecondToFirst(primaBasica);

    if (this.primaAnual_MXN.value === valorNuevo && this.opcionesPlan.tipoPlan !== 'ALIADOSKIDS') {
      switch (Math.round(this.sumaAsegurada_UDI.value)) {
        case 350000:
          resultadoSumaAsegurada = this.calculoSumaAsegurada(
            this.recursosExternos.SecondToFirst(primaBasica),
            'Elegido',
            this.opcionesPlan.tipoPlan === 'DOTAL' ? 1 : 0.97
          );
          break;
        case 500000:
          resultadoSumaAsegurada = this.calculoSumaAsegurada(
            this.recursosExternos.SecondToFirst(primaBasica),
            'Elegido',
            this.opcionesPlan.tipoPlan === 'DOTAL' ? 1 : 0.95
          );
          break;
        case 1000000:
          resultadoSumaAsegurada = this.calculoSumaAsegurada(
            this.recursosExternos.SecondToFirst(primaBasica),
            'Elegido',
            this.opcionesPlan.tipoPlan === 'DOTAL' ? 1 : 0.93
          );
          break;
      }
    }

    this.primaAnual_MXN.next(primaBasica);

    this.primaAnual_UDI.next(primaAsegurada_UDI);

    this.sumaAsegurada_MXN.next(
      this.recursosExternos.FirstToSecond(resultadoSumaAsegurada)
    );

    this.sumaAsegurada_UDI.next(resultadoSumaAsegurada);
  }

  /**
   * Realiza el calculo de la prima anual básica de la segunda moneda de acuerdo a la prima proporcionada
   *
   * @param {number} sumaAseguradaSegMon
   * @memberof BAService
   */
  establecePrimaAnualSegMon(sumaAseguradaSegMon: number): void {
    let sumaAsegurada;
    let sumaAseguradaMax;
    switch (this.opcionesPlan.moneda) {
      case 'UDI':
        if( this.opcionesPlan.tipoPlan === 'ALIADOSKIDS' ){
          sumaAsegurada = this.recursosExternos.FirstToSecond(BA.MIN_SUM_KIDS);
          sumaAseguradaMax = this.recursosExternos.FirstToSecond(BA.MAX_SUM_KIDS);
        }else{
          sumaAsegurada = this.recursosExternos.FirstToSecond(BA.MIN_SUM_UDI);
        }        
        break;
      case 'MXN':
        sumaAsegurada = this.recursosExternos.FirstToSecond(BA.MIN_SUM_MXN);
        break;
      case 'USD':
        sumaAsegurada = this.recursosExternos.FirstToSecond(BA.MIN_SUM_USD);
        break;
    }

    if (sumaAseguradaSegMon >= sumaAsegurada && isFinite(sumaAseguradaSegMon)) {
      sumaAsegurada = sumaAseguradaSegMon;
    }

    if(sumaAseguradaSegMon > sumaAseguradaMax && isFinite(sumaAseguradaSegMon)){
      sumaAsegurada = sumaAseguradaMax;
    }
    const resultadoPrimaBasica = this.calculoPrimaBasica(
      this.recursosExternos.SecondToFirst(sumaAsegurada),
      'Elegido'
    );

    const sumaAsegurada_UDI = this.recursosExternos.SecondToFirst(
      sumaAsegurada
    );

    this.sumaAsegurada_MXN.next(sumaAsegurada);

    this.sumaAsegurada_UDI.next(sumaAsegurada_UDI);

    // HERE
    this.primaAnual_MXN.next(
      this.recursosExternos.FirstToSecond(resultadoPrimaBasica)
    );

    this.primaAnual_UDI.next(resultadoPrimaBasica);
  }

  /**
   * Realiza el calculo de la suma asegurada básica en base a la prima proporcionada
   *
   * @param {number} primaBasica
   * @param {Plazo} plazo
   * @param {moneda} string
   * @returns {number} Suma asegurada Básica
   * @memberof BAService
   */
  calculoSumaAsegurada(
    primaBasica: number,
    plazo: Plazo,
    descuentoDefault?: number
  ): number {
    // Variables necesarias para el calculo
    let sumaAseguradaFinal;
    const recargoFijo = this.opcionesPlan.obtenerRecargoFijo(
      this.opcionesPlan.moneda || 'UDI'
    );
    const factorTarifa = this.factores.obtenerFactorCobertura('BASICA', plazo);

    if (descuentoDefault) {
      sumaAseguradaFinal =
        ((primaBasica - recargoFijo) * 1000) /
        (factorTarifa * descuentoDefault);
    } else {
      // Ejecuta la primer formula dada
      const sumaAsegurada1 =
        ((primaBasica - recargoFijo) * 1000) / factorTarifa;
      const descuentoPorVolumen1 = this.opcionesPlan.obtenerDescuento(
        sumaAsegurada1
      );

      const sumaAsegurada2 =
        ((primaBasica - recargoFijo) * 1000) /
        (factorTarifa * descuentoPorVolumen1);
      const descuentoPorVolumen2 = this.opcionesPlan.obtenerDescuento(
        sumaAsegurada2
      );

      let descuentoFinal;

      if (descuentoPorVolumen1 !== descuentoPorVolumen2) {
        descuentoFinal = descuentoPorVolumen2;
      } else {
        descuentoFinal = descuentoPorVolumen1;
      }

      sumaAseguradaFinal =
        ((primaBasica - recargoFijo) * 1000) / (factorTarifa * descuentoFinal);
    }

    return sumaAseguradaFinal;
  }
  // métodos para universales

  establecePrimaAnualUniversal(valorNuevo: number): void {
    // Establece un valor predeterminado de la suma asegurada de BA.
    let sumaAsegurada;
    switch (this.opcionesPlan.moneda) {
      case 'MXN':
        switch (this.opcionesPlan.tipoPlan) {
          case 'VIDA INTELIGENTE':
            if (valorNuevo) {
              if (this.opcionesPlan.tipoRiesgo.value === 'Preferente') {
                sumaAsegurada = BAUniversal.MIN_SUM_RIES_PREF_MXN ;
              } else {
                sumaAsegurada = BAUniversal.MIN_SUM_MXN_VI;
              }
            } else {
              sumaAsegurada = 1000000;
            }
            break;
            case 'MI PROYECTO R':
            if (this.opcionesPlan.tipoRiesgo.value === 'Preferente') {
              sumaAsegurada = BAUniversal.MIN_SUM_RIES_PREF_MXN ;
            } else {
              sumaAsegurada = BAUniversal.MIN_SUM_MXN_MR;
            }
            break;
        }
        break;
      case 'USD':
          switch (this.opcionesPlan.tipoPlan) {
            case 'VIDA INTELIGENTE':
              if (valorNuevo) {
                if (this.opcionesPlan.tipoRiesgo.value === 'Preferente') {
                  sumaAsegurada = BAUniversal.MIN_SUM_RIES_PREF_USD ;
                } else {
                  sumaAsegurada = BAUniversal.MIN_SUM_USD_VI;
                }
              } else {
                sumaAsegurada = 100000;
              }
              break;
              case 'MI PROYECTO R':
              if (this.opcionesPlan.tipoRiesgo.value === 'Preferente') {
                sumaAsegurada = BAUniversal.MIN_SUM_RIES_PREF_USD ;
              } else {
                sumaAsegurada = BAUniversal.MIN_SUM_USD_MR;
              }
              break;
          }
          break;
    }

    if (valorNuevo >= sumaAsegurada && isFinite(valorNuevo)) {
        sumaAsegurada = valorNuevo;
    }
    const sumaAseguradaConvertUn = sumaAsegurada;
    this.sumaAseguradaUniversal.next(sumaAsegurada);
    const sumaAseguradaConvertU = this.recursosExternos.MXNtoUSDU(sumaAseguradaConvertUn);
    this.sumaAseguradaConvertUniversal.next(sumaAseguradaConvertU);

  }
  establecePrimaAnualSegMonU(sumaAseguradaSegMon: number): void {
    let sumaAsegurada;
    switch (this.opcionesPlan.moneda) {
      case 'USD':
        switch (this.opcionesPlan.tipoPlan) {
          case 'VIDA INTELIGENTE':
              if (this.opcionesPlan.tipoRiesgo.value === 'Preferente') {
                sumaAsegurada = BAUniversal.MIN_SUM_RIES_PREF_USD ;
              }
              if (this.opcionesPlan.tipoRiesgo.value === 'Normal') {
                sumaAsegurada = BAUniversal.MIN_SUM_USD_VI;
              }
            break;
            case 'MI PROYECTO R':
            if (this.opcionesPlan.tipoRiesgo.value === 'Preferente') {
              sumaAsegurada = BAUniversal.MIN_SUM_RIES_PREF_USD ;
            }
            if (this.opcionesPlan.tipoRiesgo.value === 'Normal') {
              sumaAsegurada = BAUniversal.MIN_SUM_USD_MR;
            }
            break;
        }
        break;
    }

    if (sumaAseguradaSegMon >= sumaAsegurada && isFinite(sumaAseguradaSegMon)) {
      sumaAsegurada = sumaAseguradaSegMon;
    }


    const sumaAsegurada_UDS = this.recursosExternos.SecondToFirstU(
      sumaAsegurada
    );
    this.sumaAseguradaConvertUniversal.next(sumaAsegurada);
    this.sumaAseguradaUniversal.next(sumaAsegurada_UDS);

  }
}
