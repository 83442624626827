import {
  Injectable,
  Inject, LOCALE_ID
} from '@angular/core';
import { formatCurrency, CurrencyPipe } from '@angular/common';

import { Gender, Fumador } from '@app/models';
import { PlanType } from '@app/constants/plan-options/plan-types.constant.pre';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { CalculosService } from '@services/calculos/calculos.service';
import { DeviceTypeService } from '@services/deviceType/deviceType.service';
import * as moment from 'moment';
import { GeneradorTablaValoresService } from '../generadorTablaValores/generador-tabla-valores.service';
import { FrecuenciaPagoService } from '../universales/frecuencia-pago.service';
import { CalculosUniversalesService } from './../universales/calculos-universales.service';
import { CalculosExcedentesUniversalesService } from '../universales/calculos-excedentes-universales.service';
import { Fondos } from './../../models/universales/fondos';

declare let jsPDF;

/**
 * Servicio que maneja el PDF de Tarifas (Masivo)
 * @export
 * @class PdfTarifasService
 */
@Injectable({
  providedIn: 'root'
})
export class PdfMiProyectoR {
  // Colores rosas -> https://coolors.co/f06192-f97ca7-ff9ebf-ffb7d0-ffe8ef

  /**
   * Instancia de jsPDF
   * @property
   * @private
   * @memberof PdfTarifasService
   */
  private pdf;
  x = 0;
  y = 0;
  yBody: number;
  yCobertua: number;
  yBeneficios: number;
  yPortafolio: number;
  yCoberturasAd: number;

  vPrimaBasica: string;
  vPrimaExcedente: string;
  vPrimaAnualTotal: string;

  num: number;

  private cols: { field: string, value: string }[] = [
    { field: 'anioPoliza', value: 'Año' },
    { field: 'edad', value: 'Edad' },
    { field: 'edadCalculo', value: 'Edad\nCalculo' },
    { field: 'primaBasica', value: 'Prima\nBasica' },
    { field: 'primaExcedente', value: 'Prima\nExcedente' },
    { field: 'primaTotal', value: 'Prima\nTotal' },
    { field: 'primaAcumulada', value: 'Prima\nAcumulada' },
    { field: 'sumaAsegurada', value: 'Suma\nAsegurada' },
    { field: 'fondoBasico', value: 'Fondo\nBasico' },
    { field: 'fondoExcedente', value: 'Fondo\nExcedente' },
    { field: 'valorEfectivo', value: 'Valor en\nEfectivo' },
    { field: 'valorRescate', value: 'Valor de\nRescate' },
    { field: 'porcentajeRecuperacion', value: 'Recuperación (%)' },
    { field: 'beneficioFallecimiento', value: 'Beneficio por\nFallecimiento' }
  ];

  /**
   * Creates an instance of PdfTarifasService.
   * @param {OpcionesPlanService} opciones
   * @param {AuthenticationService} auth
   * @param {ProspectoService} prospecto
   * @param {NetworkStateService} network
   * @param {BaseDatosService} db
   * @param {PaymentFrequencyService} paymentFrecuency
   * @param {CalculosService} calculos
   * @param {string} locale
   * @param {GeneradorTablaValoresService} tabla
   * @param {DeviceTypeService} device
   * @memberof PdfTarifasService
   */
  constructor(
    private opciones: OpcionesPlanService,
    private auth: AuthenticationService,
    private prospecto: ProspectoService,
    // private network: NetworkStateService,
    // private paymentFrecuency: PaymentFrequencyService,
    private calculos: CalculosService,
    @Inject(LOCALE_ID) private locale: string,
    private tabla: GeneradorTablaValoresService,
    private device: DeviceTypeService,
    private frecuenciaPago: FrecuenciaPagoService,
    private calcUniv: CalculosUniversalesService,
    private excd: CalculosExcedentesUniversalesService
  ) { }

  /**
   * Inicializa el proceso de crear un PDF
   *
   * @private
   * @returns {Promise<void>}
   * @memberof PdfTarifasService
   */
  private async initPDF(folio?: string): Promise<void> {
    this.pdf = new jsPDF('p', 'mm', 'legal', 1);
    try {
      await this.setHeader();
      this.setBody(folio);
      //   this.setTable();
    } catch (error) {
      console.log(error);
    }

  }

  /**
   * Establece header del pdf (primera hoja)
   *
   * @private
   * @returns {Promise<boolean>}
   * @memberof PdfTarifasService
   */
  private setHeader(): Promise<boolean> {
    return new Promise(async (res, rej) => {
      try {
        const img = new Image();

          img.src = 'assets/images/ico_AXA.png';

        this.pdf.addImage(img, 'PNG', 18, 0, 18, 18, undefined, 'FAST');
      } catch (error) {
        rej(false);
        console.log(error);
      }

      this.writedDate();
      this.footer();
      res(true);
    });
  }
  private writedDate(): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(12);
    this.pdf.setFontStyle('bold');
    this.pdf.text('Fecha: ', 140, 17);
    this.pdf.setFontStyle('normal');
    this.pdf.text(`${moment().format('DD')} de ${moment().format('MMMM')} del ${moment().format('YYYY')}`, 155, 17);
    this.banner();
  }
  private banner() {
    if (this.opciones.tipoPlan === 'VIDA INTELIGENTE') {
      this.addImage(0, 23, 230, 65, '/assets/images/banner_2_VI.png');
    }
    if (this.opciones.tipoPlan === 'MI PROYECTO R') {
      this.addImage(0, 23, 230, 65, '/assets/images/banner_1_MR.png');
    }
    this.writeProducto(this.opciones.tipoPlan);
  }

  private estableceEncabezado(firstPage: boolean = false) {
    this.setHeader();
  }

  /**
   * Establece el cuerpo del pdf (primera hoja)
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private setBody(folio?: string) {
    if (this.opciones.tipoPlan === 'VIDA INTELIGENTE') {
      this.num = 90;
    } else {
      this.num = 85;
    }
    const data = this.tabla.vistaVg.map(
      vista => vista.filter(row => row.visible).map(row => this.formatCurrency$(row.value, row.field))
    );

    let indexCondition = 0;
    let positionExc = 0, applyExcede = false;
    let positionBas = 0, applyBasica = false;
    let positionTot = 0, applyTotal = false;
    this.tabla.vistaCols.forEach(name => {
      if (name.value === 'Prima Excedente pesos' ||
        name.value === 'Prima Excedente dólares') {
        applyExcede = true;
        positionExc = indexCondition;
      }
      if (name.value === 'Prima Basica pesos' ||
        name.value === 'Prima Basica dólares') {
        applyBasica = true;
        positionBas = indexCondition;
      }
      if (name.value === 'Prima Total pesos' ||
        name.value === 'Prima Total dólares') {
        applyTotal = true;
        positionTot = indexCondition;
      }
      indexCondition = Number(indexCondition) + 1;
    });
    this.vPrimaExcedente = applyExcede === false ? '0' :
      String(data[0][positionExc]).substr(1, String(data[0][positionExc]).length);
    this.vPrimaBasica = applyBasica === false ? '0' :
      String(data[0][positionBas]).substr(1, String(data[0][positionBas]).length).replace(',', '');
    this.vPrimaAnualTotal = applyTotal === false ? '0' :
      String(data[0][positionTot]).substr(1, String(data[0][positionTot]).length);

    this.pdf.setFillColor(220, 220, 220);
    this.pdf.setDrawColor(180, 180, 180);
    this.pdf.rect(6, 65, 203, 23, 'FD');
    this.datosDelAgente(folio);
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, 88, 203, 260, 'FD');
    this.datosDelProspecto();
    this.pdf.line(6, 119, 209, 119);
    this.caracteristicasDelPlan();
    this.pdf.line(6, this.yBody += 6, 209, this.yBody);
    this.beneficios();
    this.detallesCoberturas();
    this.pdf.addPage();

    this.setTable(data, folio);

    this.pdf.addPage();
    this.encabezadoPagPost(folio, 30);
    this.perfilInversion();
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, 160, 203, 148, 'FD');
    this.detallePortafolio();
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, 289, 203, 31, 'FD');
    this.describeNota();
    // this.detalleDePrimas(true);

    // this.writeCaducidad();

    // this.pdf.addPage();
  }

  /**
   * Genera los datos del agente
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private datosDelAgente(folio?: string): void {
    // Primerafila
    // *Agente
    this.writeBoldedText('Agente: ', 12, 71);
    this.writeBoldedText(this.auth.user.getValue().name, 27, 71);

    // *MXN
    this.writeBoldedText('MX: ', 12, 77);
    this.writeText(this.auth.user.getValue().code, 20, 77);

    // *Correo electrónico
    this.writeBoldedText('Correo electrónico: ', 12, 83);
    // this.writeText(this.auth.user.getValue().email, 60, 94);
    this.writeText(this.auth.user.getValue().email, 49, 83);

    // // *Conexión
    // this.writeBoldedText('Conexión: ', 15, 95);
    // this.writeText(
    //   this.network._state.getValue() ? 'ONLINE' : 'OFFLINE',
    //   42,
    //   95
    // );

    // Segunda fila
    // *Fecha
    this.writeBoldedText('Fecha: ', 145, 71);
    this.writeText(
      `${moment().format('DD')} de ${moment().format(
        'MMMM'
      )} del ${moment().format('YYYY')}`,
      158,
      71
    );

    // *Folio
    this.writeBoldedText('Folio: ', 145, 77);
    this.writeText(folio, 156, 77);

    // *Rol
    this.writeBoldedText('Rol: ', 145, 83);
    this.writeText(this.auth.user.getValue().type, 154, 83);
  }

  private datosDelAgenteP(folio?: string): void {
    // Primerafila
    // *Agente
    this.writeBoldedText('Agente: ', 119, 64);
    this.writeBoldedText(this.auth.user.getValue().name, 134, 64);

    // *MXN
    this.writeBoldedText('MX: ', 119, 69);
    this.writeText(this.auth.user.getValue().code, 127, 69);

    // *Correo electrónico
    this.writeBoldedText('Correo electrónico: ', 119, 73);
    this.writeText(this.auth.user.getValue().email, 156, 73);

    // // *Conexión
    // this.writeBoldedText('Conexión: ', 15, 95);
    // this.writeText(
    //   this.network._state.getValue() ? 'ONLINE' : 'OFFLINE',
    //   42,
    //   95
    // );

    // Segunda fila
    // *Fecha
    this.writeBoldedText('Fecha: ', 119, 77);
    this.writeText(
      `${moment().format('DD')} de ${moment().format(
        'MMMM'
      )} del ${moment().format('YYYY')}`,
      132,
      77
    );

    // *Folio
    this.writeBoldedText('Folio: ', 119, 81);
    if (this.opciones.tipoPlan === 'VIDA INTELIGENTE') {
      this.writeText('VidaINT205173', 131, 81);
    } else if (this.opciones.tipoPlan === 'MI PROYECTO R') {
      this.writeText('ProyR205173', 131, 81);
    } else {
      this.writeText('Exclusive205173', 131, 81);
    }

    // *Rol
    this.writeBoldedText('Rol: ', 119, 85);
    this.writeText(this.auth.user.getValue().type, 128, 85);
  }

  /**
   * Genera los datos del prospecto
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private datosDelProspecto(): void {
    this.titlePrincipal('Datos del Cliente', 12, 96);
    // Nombre
    this.writeBoldedText('Nombre: ', 12, 102);
    this.writeText(this.prospecto.prospecto.getValue().name, 29, 102);
    this.writeBoldedText('Edad: ', 12, 108);
    this.writeText(String(this.prospecto.prospecto.getValue().age) + ' años', 23, 108);
    // *Género
    this.writeBoldedText('Género: ', 12, 114);
    this.writeText(
      this.prospecto.prospecto.getValue().gender === 'H'
        ? 'Hombre'
        : 'Mujer',
      28,
      114
    );
    if (this.opciones.prospecto.mancomunado.mancomunado) {
      // *Hábito
      this.writeBoldedText('Hábito: ', 45, 108);
      this.writeText(
        this.habitoHandler(
          this.prospecto.prospecto.getValue().fumador,
          this.prospecto.prospecto.getValue().gender
        ),
        59,
        108
      );
      this.writeBoldedText('Edad de cálculo: ', 45, 114);
      this.writeText(String(this.calcUniv.edadCalculada.value) + ' años', 76, 114);

      // Mancomunado

      this.titlePrincipal('Datos del Mancomunado', 120, 96);
      this.writeBoldedText('Nombre: ', 120, 102);
      this.writeText(this.opciones.prospecto.mancomunado.nameMancomunado, 137, 102);
      this.writeBoldedText('Edad: ', 120, 108);
      this.writeText(String(this.opciones.prospecto.mancomunado.ageMancomunado) + ' años', 131, 108);
      // *Género
      this.writeBoldedText('Género: ', 120, 114);
      this.writeText(
        this.opciones.prospecto.mancomunado.genderMancomunado === 'H'
          ? 'Hombre'
          : 'Mujer',
        136,
        114
      );
      // *Hábito
      this.writeBoldedText('Hábito: ', 153, 108);
      this.writeText(
        this.habitoHandler(
          this.opciones.prospecto.mancomunado.fumadorMancomunado,
          this.opciones.prospecto.mancomunado.genderMancomunado
        ),
        167,
        108
      );
      this.writeBoldedText('Edad de cálculo: ', 153, 114);
      this.writeText(String(this.calcUniv.edadMancomunadoCalculada) + ' años', 184, 114);
    } else {
          // *Hábito
    this.writeBoldedText('Hábito: ', 145, 102);
    this.writeText(
      this.habitoHandler(
        this.prospecto.prospecto.getValue().fumador,
        this.prospecto.prospecto.getValue().gender
      ),
      159,
      102
    );
    this.writeBoldedText('Edad de cálculo: ', 145, 108);
    this.writeText(String(this.calcUniv.edadCalculada.value) + ' años', 176, 108);
    }

  }

  private datosDelProspectoP(): void {
    this.titlePrincipal('Datos del Cliente', 12, 64);
    // Nombre
    this.writeBoldedText('Nombre: ', 12, 69);
    this.writeText(this.prospecto.prospecto.getValue().name, 29, 69);
    this.writeBoldedText('Edad: ', 12, 73);
    this.writeText(String(this.prospecto.prospecto.getValue().age) + ' años', 23, 73);
    // *Género
    this.writeBoldedText('Género: ', 12, 77);
    this.writeText(
      this.prospecto.prospecto.getValue().gender === 'H'
        ? 'Hombre'
        : 'Mujer',
      28,
      77
    );

    // *Hábito
    this.writeBoldedText('Hábito: ', 12, 81);
    this.writeText(
      this.habitoHandler(
        this.prospecto.prospecto.getValue().fumador,
        this.prospecto.prospecto.getValue().gender
      ),
      27,
      81
    );
    this.writeBoldedText('Edad de cálculo: ', 12, 85);
    this.writeText(String(this.calcUniv.edadCalculada.value) + ' años', 44, 85);
  }

  private encabezadoPagPost(folio, tamRecY?: number): void {
    tamRecY = tamRecY > 0 ? tamRecY : 0;
    this.estableceEncabezado();
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, 59, 112, 29, 'FD');
    this.datosDelProspectoP();
    this.pdf.setFillColor(220, 220, 220);
    this.pdf.setDrawColor(180, 180, 180);
    this.pdf.rect(112, 59, 97, 29, 'FD');
    this.datosDelAgenteP(folio);
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, 88, 203, 53 + tamRecY, 'FD');
    this.legacy();
  }

  /**
   * Retorna el texto según el hábito y el género
   * @method
   * @private
   * @param {Fumador} habito
   * @param {Gender} genero
   * @returns {string}
   * @memberof PdfTarifasService
   */
  private habitoHandler(habito: Fumador, genero: Gender): string {
    switch (true) {
      case habito === 'NO' && genero === 'H':
        return 'No fumador';
      case habito === 'SI' && genero === 'H':
        return 'Fumador';
      case habito === 'NO' && genero === 'M':
        return 'No fumadora';
      case habito === 'SI' && genero === 'M':
        return 'Fumadora';
      default:
        return '';
    }
  }

  /**
   * Genera las características del plan
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private caracteristicasDelPlan(): void {
    this.x = 0;
    this.y = 0;
    this.titlePrincipal('Caracteristicas del plan', 12, 127);

    // *Plan
    this.writeBoldedText('Plan: ', 12, 133);
    this.writeText(this.opciones.tipoPlan, 22, 133);

    // * Plazo de pagos
    this.writeBoldedText('Plazo de pagos: ', 12, 139);
    this.writeText('Edad Alcanzada  ' + `${this.opciones.plazoPago.getValue()}`, 42, 139);

    // *Riesgo
    this.writeBoldedText('Riesgo: ', 12, 145);
    this.writeText(this.opciones.tipoRiesgo.value, 27, 145);

    // *Moneda
    this.writeBoldedText('Moneda: ', 12, 151);
    this.writeText(this.opciones.moneda === 'MXN' ? 'Pesos' : 'Dólares', 29, 151);

    // *Incrementos Programados
    this.writeBoldedText('Incrementos de la suma asegurada: ', 12, 157);
    if (this.opciones.inpcUniversal.value === true) {
      this.writeText((this.calculos.inflacion_inpc_mxn.value * 100).toFixed(2) + ' %', 79, 157);
      // if (this.opciones.moneda === 'MXN') {
      //   this.writeText((this.calculos.inflacion_inpc_mxn.value * 100).toFixed(2) + ' %', 79, 157);
      // } else if (this.opciones.moneda === 'USD') {
      //   this.writeText((this.calculos.inflacion_inpc_usd.value * 100).toFixed(2) + ' %', 79, 157);
      // } else {
      // }
    } else {
      this.writeText('No tiene incrementos', 79, 157);
    }

    // Seunda Fila
    // const primaBasica = this.calculos.BA.primaAnual_UDI.value;
    const primaFrecuencia = this.frecuenciaPago.frecuenciaPagoUniv.value
      .filter(v => v.seleccionado)[0].primaFormaPago || 0;
    // const primaAnual = this.frecuenciaPago.frecuenciaPagoUniv.value
     // .filter(v => v.nombre === 'ANUAL')[0].primaAnual || 0;
    const frecuenciaDePago = this.calculos.paymentFrecuency;
    const moneda = this.opciones.moneda === 'MXN' ? 'pesos' : 'dólares';
    // * Prima Básica
    this.writeBoldedText('Prima Básica: ', 145, 133);
    this.writeText(CurrencyPipe.prototype.transform(this.vPrimaBasica, 'MXN', 'symbol-narrow', '1.0-0', 'en-US'), 172, 133);

    // * Prima Excedente
    this.writeBoldedText('Prima Excedente: ', 145, 139);
    this.writeText('$' + this.vPrimaExcedente, 178, 139);

    // * Prima anual total
    this.writeBoldedText('Prima anual total: ', 145, 145);
    this.writeText('$' + this.vPrimaAnualTotal, 179, 145);
    this.x = 145;
    this.y = 151;

    // * Prima en pesos
    // this.writeBoldedText('Prima anual en ' + moneda + ': ', this.x, this.y); // y = 151
    // this.writeText(CurrencyPipe.prototype.transform(primaAnual, 'MXN', 'symbol-narrow', '1.0-0', 'en-US'), 188, this.y);

    // * Prima segun frecuencia de pago
    if (frecuenciaDePago.toLowerCase() !== 'anual') {
      const monedaX = moneda === 'pesos' ? 190 : 193;
      this.writeBoldedText(`Prima ${frecuenciaDePago.toLowerCase()} ` + moneda + ':', this.x, this.y); // y = 157
      this.writeText(CurrencyPipe.prototype.transform(primaFrecuencia, 'MXN', 'symbol-narrow', '1.0-0', 'en-US'), monedaX, this.y);

      // * Frecuencia de pago
      this.writeBoldedText('Frecuencia de pago: ', 145, this.y += 6); // y = 163
      this.writeText(frecuenciaDePago[0] + frecuenciaDePago.slice(1).toLowerCase(), 184, this.y);
    }


    this.yBody = this.y + 6;

  }

  private detallesCoberturas(): void {
    const moneda = this.opciones.moneda === 'MXN' ? 'Pesos' : 'Dólares';
    let yca = this.yCoberturasAd;
    // Coberturas Adicionales
    if (
      this.calculos.ECMI_sumaAseguradaMXN.value !== 'EXCLUIDO' || this.calculos.ECMI_sumaAseguradaUSD.value !== 'EXCLUIDO' ||
      this.calculos.PAI_sumaAseguradaMXN_U.value !== 0 || this.calculos.PAI_sumaAseguradaUSD_U.value !== 0 ||
      this.calculos.DIPOCU_sumaAseguradaMXN_U.value !== 0 || this.calculos.DIPOCU_sumaAseguradaUSD_U.value !== 0 ||
      this.calculos.DIU_sumaAseguradaMXN_U.value !== 0 || this.calculos.DIU_sumaAseguradaUSD_U.value !== 0 ||
      this.calculos.CDC_sumaAseguradaMXN_U.value !== 0 || this.calculos.CDC_sumaAseguradaUSD_U.value !== 0 ||
      this.calculos.GFI_sumaAseguradaMXN.value !== 0 || this.calculos.GFI_sumaAseguradaUSD.value !== 0 ||
      this.calculos.GFC_sumaAseguradaMXN.value !== 0 || this.calculos.GFC_sumaAseguradaUSD.value !== 0 ||
      this.calculos.GFH_sumaAseguradaMXN.value !== 0 || this.calculos.GFH_sumaAseguradaUSD.value !== 0) {

      this.titlePrincipal('Cobertura Adicionales', 12, this.yCoberturasAd += 10);
      this.pdf.setDrawColor(220, 220, 220);
      this.pdf.setFillColor(15, 159, 179);
      this.pdf.rect(8, this.yCoberturasAd += 5, 72, 9, 'F');
      this.writeTextW('Cobertura', 32, yca = this.yCoberturasAd + 6);
      this.pdf.setDrawColor(220, 220, 220);
      this.pdf.setFillColor(15, 192, 217);
      this.pdf.rect(72, this.yCoberturasAd, 100, 9, 'F');
      this.writeTextW('Suma Asegurada', 90, yca);
      this.pdf.setDrawColor(220, 220, 220);
      this.pdf.setFillColor(15, 213, 240);
      this.pdf.rect(140, this.yCoberturasAd, 67, 9, 'F');
      this.writeTextW(`Prima ${moneda}`, 162, yca);
      const yc4 = yca + 3;

      this.pdf.setDrawColor(220, 220, 220);
      this.pdf.setFillColor(255, 255, 255);
      this.pdf.rect(8, yc4, 199, 69, 'FD');

      if (this.calculos.ECMI_sumaAseguradaMXN.value === 'INCLUIDO' || this.calculos.ECMI_sumaAseguradaUSD.value === 'INCLUIDO') {
        this.writeText('Exención de Costos del seguro por', 12, yca += 7);
        this.writeText('Invalidez total y Permanente.', 12, yca += 4);
        let y = yca;
        this.writeText(this.calculos.ECMI_sumaAseguradaMXN.value, 97, y -= 1.4);
        this.writeText(this.fomattCurr(this.calcUniv.ecmi.value.prima), 169, y);

      } // else {
      //   this.writeText('Exención de Costos del Seguro por', 12, yca += 7 );
      //   this.writeText('Invalidez total y Permanente.', 12, yca += 4);
      //   let y = yca;
      //   this.writeText('NO APLICA', 95, y -= 1.4);
      //   this.writeText('NO APLICA', 155, y);
      // }
      if (this.calculos.PAI_sumaAseguradaMXN_U.value !== 0 || this.calculos.PAI_sumaAseguradaUSD_U.value !== 0) {
        this.writeText('Pago adicional por invalidez total', 12, yca += 7);
        this.writeText('y Permanente.', 12, yca += 4);
        let y = yca;
        this.writeText(formatCurrency
          (this.calculos.PAI_sumaAseguradaMXN_U.value,
            this.locale,
            '$ ',
            'MXN'
          ),
          95, y -= 1.4);
        this.writeText(this.fomattCurr(this.calcUniv.pai.value.prima), 169, y);
      }
      // else {
      //   this.writeText('Pago adicional por invalidez total', 12, yca += 7 );
      //   this.writeText('y Permanente.', 12, yca += 4);
      //   let y = yca;
      //   this.writeText('NO APLICA', 95, y -= 1.4);
      //   this.writeText('NO APLICA', 155, y);
      // }
      if (this.calculos.DIU_sumaAseguradaMXN_U.value !== 0 || this.calculos.DIU_sumaAseguradaUSD_U.value !== 0) {
        this.writeText('Muerte accidental.', 12, yca += 8);
        this.writeText(formatCurrency
          (this.calculos.DIU_sumaAseguradaMXN_U.value,
            this.locale,
            '$ ',
            'MXN'
          ),
          95, yca);
        this.writeText(this.fomattCurr(this.calcUniv.di.value.prima), 169, yca);

      }
      // else {
      //   this.writeText('Muerte accidental.', 12, yca += 7 );
      //   this.writeText('NO APLICA', 95, yca);
      //   this.writeText('NO APLICA', 155, yca);
      // }
      if (this.calculos.DIPOCU_sumaAseguradaMXN_U.value || this.calculos.DIPOCU_sumaAseguradaUSD_U.value !== 0) {
        this.writeText('Muerte Accidental y Pérdidas', 12, yca += 7);
        this.writeText('Orgánicas y Muerte Colectiva.', 12, yca += 4);
        let y = yca;
        this.writeText(formatCurrency
          (this.calculos.DIPOCU_sumaAseguradaMXN_U.value,
            this.locale,
            '$ ',
            'MXN'
          ),
          95, y -= 1.4);
        this.writeText(this.fomattCurr(this.calcUniv.dipoc.value.prima), 169, y);

      }
      // else {
      //   this.writeText('Muerte Accidental y Pérdidas', 12, yca += 7 );
      //   this.writeText('Orgánicas y Muerte Colectiva.', 12, yca += 4);
      //   let y = yca;
      //   this.writeText('NO APLICA', 95, y -= 1.4);
      //   this.writeText('NO APLICA', 155, y);
      // }
      if (this.opciones.tipoPlan === 'MI PROYECTO R') {
        if (this.calculos.CDC_sumaAseguradaMXN_U.value !== 0 || this.calculos.CDC_sumaAseguradaUSD_U.value !== 0) {
          this.writeText('Cobertura de cáncer.', 12, yca += 8);
          this.writeText(formatCurrency
            (this.calculos.CDC_sumaAseguradaMXN_U.value,
              this.locale,
              '$ ',
              'MXN'
            ),
            95, yca);
          this.writeText(this.fomattCurr(this.calcUniv.cdc.value.prima), 169, yca);

        }
        // else {
        //   this.writeText('Cobertura de cáncer.', 12, yca += 7 );
        //   this.writeText('NO APLICA', 95, yca);
        //   this.writeText('NO APLICA', 155, yca);
        // }
      }
      if (this.calculos.GFI_sumaAseguradaMXN.value !== 0 || this.calculos.GFI_sumaAseguradaUSD.value !== 0) {
        this.writeText('Gastos Funerarios individual.', 12, yca += 7);
        this.writeText(formatCurrency
          (this.calculos.GFI_sumaAseguradaMXN.value,
            this.locale,
            '$ ',
            'MXN'
          ),
          95, yca);
        this.writeText(this.fomattCurr(this.calcUniv.gfi.value.prima), 169, yca);
      }
      // else {
      //   this.writeText('Gastos Funerarios Individual.', 12, yca += 7 );
      //   this.writeText('NO APLICA', 95, yca);
      //   this.writeText('NO APLICA', 155, yca);
      // }
      if (this.calculos.GFC_sumaAseguradaMXN.value !== 0 || this.calculos.GFC_sumaAseguradaUSD.value !== 0) {
        this.writeText('Gastos Funerarios Cónyuge.', 12, yca += 7);
        this.writeText(formatCurrency
          (this.calculos.GFC_sumaAseguradaMXN.value,
            this.locale,
            '$ ',
            'MXN'
          ),
          95, yca);
        this.writeText(this.fomattCurr(this.calcUniv.gfc.value.prima), 169, yca);

      }
      // else {
      //   this.writeText('Gastos Funerarios Cónyuge.', 12, yca += 7 );
      //   this.writeText('NO APLICA', 95, yca);
      //   this.writeText('NO APLICA', 155, yca);
      // }
      if (this.calculos.GFH_sumaAseguradaMXN.value !== 0 || this.calculos.GFH_sumaAseguradaUSD.value !== 0) {
        this.writeText('Gastos Funerarios Hijos.', 12, yca += 7);
        this.writeText(formatCurrency
          (this.calculos.GFH_sumaAseguradaMXN.value,
            this.locale,
            '$ ',
            'MXN'
          ),
          95, yca);
        this.writeText(this.fomattCurr(this.calcUniv.gfh.value.prima), 169, yca);
      }

      // else {
      //   this.writeText('Gastos Funerarios Hijos.', 12, yca += 7 );
      //   this.writeText('NO APLICA', 95, yca);
      //   this.writeText('NO APLICA', 155, yca);
      // }
    }
    const text1 = '* Esta cotización tiene una validez de 15 días naturales contados a partir de la fecha de su elaboración por lo que no se garantiza la';
    const text2 = '  emisión del seguro. La misma es ilustrativa y de apoyo a la fuerza de ventas.';
    this.writeTextRed(text1, 12, 341);
    this.writeTextRed(text2, 12, 344);
    this.yBeneficios = 286;
    // this.beneficios();
  }

  private beneficios(): void {
    let yc2;
    const moneda = this.opciones.moneda === 'MXN' ? 'Pesos' : 'Dólares';
    this.yCobertua = this.yBody;
    this.titlePrincipal('Cobertura', 12, this.yCobertua += 8);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 159, 179);
    this.pdf.rect(8, this.yCobertua += 5, 72, 9, 'F');
    yc2 = this.yCobertua;
    this.writeTextW('Cobertura', 32, yc2 += 5);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 192, 217);
    this.pdf.rect(72, this.yCobertua, 100, 9, 'F');
    this.writeTextW('Suma Asegurada', 90, yc2);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 213, 240);
    this.pdf.rect(140, this.yCobertua, 67, 9, 'F');
    this.writeTextW(`Prima ${moneda}`, 162, yc2);
    const yc3 = yc2 + 3;
    // Segunda Columna
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.rect(8, yc3, 199, 9, 'FD');
    this.writeText('Básica', 33, yc2 += 8);
    this.writeText(formatCurrency(this.calculos.BASICA_sumaAsegurada_Universal.value, this.locale, '$ ', 'MXN'), 92, yc2);
    this.writeText(this.fomattCurr(this.calcUniv.basica.value.prima), 169, yc2);
    this.titlePrincipal('Beneficios', 12, yc2 += 10);
    let yb = yc2;
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 192, 217);
    this.pdf.rect(8, yb += 4, 105, 9, 'F');
    let yd = yb;
    this.writeTextW('Beneficio', 49, yd += 6);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 213, 240);
    this.pdf.rect(105, yb, 102, 9, 'F');
    this.writeTextW('Suma Asegurada', 138, yd);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(255, 255, 255);
    let ytb = yd;
    this.pdf.rect(8, ytb += 2, 199, 36, 'FD');

    this.writeText('Anticipo por fallecimiento', 38, yd += 9);
    this.writeText('30 % de la suma asegurada por fallecimiento', 120, yd);
    this.writeText('Anticipo por enfermedades terminales', 28, yd += 9);
    this.writeText('30 % de la suma asegurada por fallecimiento', 120, yd);
    this.writeText('Asistencia Funeraria', 42, yd += 9);
    this.writeText('Amparado', 148, yd);
    this.writeText('Asistencia en viajes', 42, yd += 9);
    this.writeText('Amparado', 148, yd);
    this.yCoberturasAd = yd;

  }

  private descripcionValores(): void {
    let ydv;
    this.writeBoldedText('BENEFICIO POR FALLECIMIENTO:', 12, 96);
    ydv = 96;
    this.writeText('Es la cantidad que se pagará al (los) beneficiario(s) en caso de fallecimiento de asegurado.', 12, ydv += 6);
    this.writeBoldedText('VALOR EN EFECTIVO:', 12, ydv += 8);
    this.writeText('Es la suma del fondo básico y fondo excedente.', 12, ydv += 6);
    this.writeBoldedText('VALOR RESCATE:', 12, ydv += 8);
    this.writeText('Es el valor del fondo básico menos el cargo por rescate que corresponde más el fondo excedente.', 12, ydv += 6);
    this.writeText('Si deseas cancelar tu seguro, recibirás esta cantidad.', 12, ydv += 6);
    // this.writeBoldedText('BENEFICIO POR FALLECIMIENTO:', 12, ydv += 8);
    // this.writeText('Es la cantidad que pagará al (los) beneficiario(s) en caso de fallecimiento de asegurado.', 12, ydv += 6);
  }

  private perfilInversion(): void {
    let ydp;
    this.titlePrincipal('Características del perfil de inversión', 12, 96);
    ydp = 96;
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 192, 217);
    this.pdf.rect(8, ydp += 4, 95, 7, 'FD');
    let ydd1;
    ydd1 = ydp;
    this.writeTextW('Fondo Básico', 42, ydd1 += 5);
    let ydp1;
    ydp1 = ydp;
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(200, 234, 237);
    this.pdf.rect(8, ydp1 += 7, 95, 12, 'FD');
    let ydd2;
    ydd2 = ydp1;
    this.writeTextTable('Fondo', 20, ydd2 += 7);
    this.writeTextTable('Distribución (%)', 49, ydd2);
    this.writeTextTable('Tasa (%)', 83, ydd2);
    let ydd3;
    ydd3 = ydd2;
    const ydd2s = ydd2;
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(255, 255, 255);
    const arrFondBa = this.obtenerFondos(this.excd.fondoBasico, true);
    const recHeigthBa = arrFondBa.length === 1 ? 12 : (((arrFondBa.length - 1) * 6) + 12);
    const lineWidthBa = arrFondBa.length === 1 ? 131 : (((arrFondBa.length - 1) * 6) + 131);
    this.pdf.rect(8, ydd3 += 5, 95, recHeigthBa, 'FD');
    ydd2 += 12;
    let ytba = ydd2;
    for (let i = 0; i < arrFondBa.length; i++) {
      if (i > 0) {
        ytba += 6;
      }
      this.writeTextTable(arrFondBa[i].valor, 12, ytba);
      this.writeTextTable(arrFondBa[i].distribucion, 56, ytba);
      this.writeTextTable(arrFondBa[i].tasa, 86, ytba);
    }
    this.pdf.line(42, 107, 42, lineWidthBa);
    this.pdf.line(74, 107, 74, lineWidthBa);
    let yddf;
    yddf = ydd2;

    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 192, 217);

    let yddf1;
    yddf1 = yddf;
    yddf = arrFondBa.length === 1 ? 131 : (((arrFondBa.length - 1) * 6) + 131);
    yddf1 = arrFondBa.length === 1 ? 135.5 : (((arrFondBa.length - 1) * 6) + 135.5);
    this.pdf.rect(29, yddf, 45, 7, 'FD');
    this.writeTextWTable('Tasa ponderada básica', 35, yddf1);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.rect(74, yddf, 29, 7, 'FD');
    this.writeTextTable(`${this.numberToDecimal(this.excd.porcentajeFondoBasico.value)}%`, 85, yddf1);
    this.pdf.line(74, 107, 74, 138);

    // Segunda columna

    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 192, 217);
    this.pdf.rect(110, ydp, 97, 7, 'FD');

    this.writeTextW('Fondo Excedente', 143, ydd1);

    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(200, 234, 237);
    this.pdf.rect(110, ydp1, 97, 12, 'FD');

    this.writeTextTable('Fondo', 122, ydd2s);
    this.writeTextTable('Distribución (%)', 151, ydd2s);
    this.writeTextTable('Tasa (%)', 188, ydd2s);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(255, 255, 255);
    const arrFondExcd = this.obtenerFondos(this.excd.fondoExcedente, false);
    const recHeigth = arrFondExcd.length === 1 ? 12 : (((arrFondExcd.length - 1) * 6) + 12);
    const lineWidth = arrFondExcd.length === 1 ? 131 : (((arrFondExcd.length - 1) * 6) + 131);
    this.pdf.rect(110, ydd3, 97, recHeigth, 'FD');
    for (let i = 0; i < arrFondExcd.length; i++) {
      if (i > 0) {
        ydd2 += 6;
      }
      this.writeTextTable(arrFondExcd[i].valor, 114, ydd2);
      this.writeTextTable(arrFondExcd[i].distribucion, 158, ydd2);
      this.writeTextTable(arrFondExcd[i].tasa, 192, ydd2);
    }

    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 192, 217);

    this.pdf.line(143, 107, 143, lineWidth);
    this.pdf.line(178, 107, 178, lineWidth);

    yddf = arrFondExcd.length === 1 ? 131 : (((arrFondExcd.length - 1) * 6) + 131);
    yddf1 = arrFondExcd.length === 1 ? 135.5 : (((arrFondExcd.length - 1) * 6) + 135.5);
    this.pdf.rect(130, yddf, 48, 7, 'FD');
    this.writeTextWTable('Tasa ponderada excedente', 136, yddf1);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.rect(178, yddf, 29, 7, 'FD');
    this.writeTextTable(`${this.numberToDecimal(this.excd.porcentajeFondoExcedente.value)}%`, 190, yddf1);
    this.yPortafolio = 150;
  }

  private obtenerFondos(fondos: Fondos, boolBasic: boolean) {
    const fDist = fondos.distribucion;
    const fTasa = fondos.tasa;
    let arrFondoMoneda = [];
    if (boolBasic) {
      arrFondoMoneda = this.opciones.tipoPlan === 'MI PROYECTO R' ? ['conservador', 'balanceado', 'creciente', 'dinamicoMX'] : ['balanceado']
      if (this.opciones.moneda === 'USD') {
        arrFondoMoneda = this.opciones.tipoPlan === 'MI PROYECTO R' ? ['dolares', 'dinamicoEUA'] : ['dolares'];
      }
    } else {
      arrFondoMoneda = ['conservador', 'balanceado', 'creciente', 'dinamicoMX'];
      if (this.opciones.moneda === 'USD') {
        arrFondoMoneda = ['dolares', 'dinamicoEUA'];
      }
    }
    const mapFondos = Object.keys(fDist).map((key) => {
      const result = {
        tipo: key.toString(),
        valor: key.substr(0, 1).toUpperCase() + key.substr(1),
        distribucion: Number(fDist[key]).toFixed(0).toString(),
        tasa: String(fTasa[key]).replace('%', ''),
        visible: false
      };
      if (arrFondoMoneda.includes(key)) {
        result.visible = true;
        if (fDist[key] === 0) {
          result.tasa = '0';
        }
      }
      return result;
    }).filter(s => s.visible);
    return mapFondos;
  }

  private detallePortafolio(): void {

    this.yPortafolio += 20;
    this.titlePrincipal('Descripción de portafolio de inversión', 12, this.yPortafolio);
    let ydp;
    ydp = this.yPortafolio;
    if (this.opciones.moneda === 'MXN') {
      this.writeBoldedTextBlue('Fondo conservador', 12, ydp += 12);

      this.writeBoldedTextD('° Descripción:', 14, ydp += 6);
      this.writeTextD('Portafolio que invierte sus recursos en la adquisición de instrumentos de deuda gubernamental, bancaria y ', 40, ydp);
      this.writeTextD('corporativa de alta calidad crediticia que proporcionen rendimientos atractivos y que impliquen el menor riesgo ', 40, ydp += 4);
      this.writeTextD('posible.', 40, ydp += 4);

      this.writeBoldedTextD('° Perfil del cliente:', 14, ydp += 6);
      this.writeTextD('Moderado. Personas que buscan preservar y proteger su patrimonio.', 46, ydp);

      this.writeBoldedTextBlue('Fondo Balanceado', 12, ydp += 8);

      this.writeBoldedTextD('° Descripción:', 14, ydp += 6);
      this.writeTextD('Portafolio que busca preservar el capital y optimizar el rendimiento de mediano plazo en un portafolio diversificado ', 40, ydp);
      this.writeTextD('que incluye acciones e instrumentos de deuda de alta calidad, como gubernamentales, bancarios y privados.', 40, ydp += 4);

      this.writeBoldedTextD('° Perfil del cliente:', 14, ydp += 6);
      this.writeTextD('Intermedio. Personas que buscan estabilidad minimizando los riesgos de mercado.', 47, ydp);

      this.writeBoldedTextBlue('Fondo Creciente', 12, ydp += 8);

      this.writeBoldedTextD('° Descripción:', 14, ydp += 6);
      this.writeTextD('Portafolio enfocado en la apreciación del capital a través de inversiones en activos con alto rendimiento potencial. ', 40, ydp);
      this.writeTextD('Incluye deuda, instrumentos corporativos y una importante exposición en bolsa.', 40, ydp += 4);

      this.writeBoldedTextD('° Perfil del cliente:', 14, ydp += 6);
      this.writeTextD('Intermedio. Personas que buscan obtener rendimientos que aporten a su patrimonio.', 47, ydp);
      this.writeBoldedTextBlue('Fondo Dinámico MX', 12, ydp += 8);

      this.writeBoldedTextD('° Descripción:', 14, ydp += 6);
      this.writeTextD('Portafolio de renta variable que invierte en las mejores empresas que cotizan en la Bolsa Mexicana de Valores', 40, ydp);
      this.writeTextD('(BMV).', 40, ydp += 4);

      this.writeBoldedTextD('° Perfil del cliente:', 14, ydp += 6);
      this.writeTextD('Agresivo. Personas que  buscan alto rendimiento en el alrgo plazo, al asumir el mayor riesgo.', 47, ydp);
    }
    if (this.opciones.moneda === 'USD') {
      this.writeBoldedTextBlue('Fondo Dólares', 12, ydp += 8);

      this.writeBoldedTextD('° Descripción:', 14, ydp += 6);
      this.writeTextD('Portafolio que otorga una alternativa para invertir dólares americanos. Busca preservar el capital al invertir', 40, ydp);
      this.writeTextD('en valores gubernamentales nacionales denominados en dólares y mantiene liquidez en la misma moneda.', 40, ydp += 4);

      this.writeBoldedTextD('° Perfil del cliente:', 14, ydp += 6);
      this.writeTextD('Moderado. Personas que buscan consolidar y proteger su patrimonio frente a las variaciones del tipo de cambio.', 47, ydp);

      this.writeBoldedTextBlue('Fondo Dinámico EUA', 12, ydp += 8);

      this.writeBoldedTextD('° Descripción:', 14, ydp += 6);
      this.writeTextD('Portafolio de renta variable que busca optimizar los rendimientos de acuerdo al comportamiento de las mejores ', 40, ydp);
      this.writeTextD('empresas de Estados Unidos de América.', 40, ydp += 4);

      this.writeBoldedTextD('° Perfil del cliente:', 14, ydp += 6);
      this.writeTextD('Agresivo. Personas que buscan alto crecimiento en el largo plazo, al asumir el mayor riesgo.', 47, ydp);
    }

    this.writeTextD('Si necesitas más información acerca de los fondos de inversión, solicita la Guía de Fondos.', 12, ydp += 6);
  }

  private describeNota(): void {
    this.writeBoldedTextBlue('NOTA:', 20, 295);
    this.writeText('el presente documento es únicamente informativo para ayudarte a tomar una decisión en cuanto', 34, 295);
    this.writeText('a tu inversión, sin embargo no garantiza ningún rendimiento y no forma parte del contrato del seguro.', 20, 301);
    this.writeText('Los rendimientos pasados no garantizan rendimientos futuros en ninguno de los portafolios.', 20, 307);

    this.writeBoldedTextD('Fuente de información:', 20, 313);
    this.writeTextD('AXA Seguros. El producto se regirá por las disposiciones contractuales y legales aplicables.', 20, 318);
  }

  private encabezadoTabla(): void {
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.setFillColor(15, 192, 217);
    this.pdf.rect(130, 142, 79, 14, 'F');
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(8.5);
    this.pdf.setFontStyle('bold');
    this.pdf.text('Escenario de rendimiento supuesto', 144.5, 146);
    this.pdf.text(`Básico: ${this.numberToDecimal(this.excd.porcentajeFondoBasico.value)}%`, 138, 153);
    this.pdf.text(`Excedente: ${this.numberToDecimal(this.excd.porcentajeFondoExcedente.value)}%`, 178, 153);
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.text(this.opciones.esquemaComisionUniv.toUpperCase(), 6, 153);
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFillColor(255, 255, 255);
    this.pdf.setDrawColor(220, 220, 220);
    this.pdf.rect(6, 155, 203, 164.9, 'FD');
  }

  private numberToDecimal(value: number) {
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  private legacy(): void {
    this.pdf.setDrawColor(15, 192, 217);
    this.pdf.setFillColor(15, 192, 217);
    this.pdf.rect(6, 320, 203, 28, 'FD');
    // tslint:disable-next-line:max-line-length
    const txtLegacy =
`Esta cotización tiene una validez de 15 días naturales contados a partir de la fecha de su elaboración por lo que no se garantiza la emisión del seguro.
La misma es ilustrativa y de apoyo a la fuerza de ventas. No forma parte del contrato del seguro, por lo que no se garantiza el comportamiento futuro
de la tasa de rendimiento que se muestra como ejemplo en la misma.

AXA Seguros se reserva el derecho de solicitar la respuesta a un cuestionario médico y de ocupación, así como estudios de laboratorio
Los montos que aparecen en el documento se reflejan de acuerdo a la moneda o unidad de valor elegida al momento de la cotización.
Los retiros totales o parciales de la póliza estarán sujetos a la retención de los impuestos de acuerdo a las disposiciones fiscales vigentes.`;
    this.writeTextWTable(txtLegacy, 7, 324);
  }

  private footer(): void {
    this.pdf.setDrawColor(6, 0, 101);
    this.pdf.setFillColor(6, 0, 101);
    this.pdf.rect(0, 320, 220, 37, 'F');
    this.writeTextW('AXA SEGUROS, S.A DE C.V Tels. 5169 1000      .     01 800 900 1292     .     www.axa.mx', 31, 353);
  }

  /**
   * Genera tabla de tarifas
   * @method
   * @private
   * @memberof PdfTarifasService
   */
  private setTable(datos: any[], folio: string): void {
    const head = [this.tabla.vistaCols.filter(row => row.visible)
      .map(row => this.cols.filter(v => v.field === row.field)[0].value || '')];

    // const body = this.tabla.vistaVg.map(col => col.map(item => item.value));
    // let edadColumna: string;
    let page = 1;
    this.pdf.autoTable({
      margin: {
        right: 0,
        left: 6,
        bottom: 50,
        top: 155
      },
      tableLineColor: [223, 223, 223],
      tableLineWidth: 0.4,
      headStyles: { fillColor: [15, 192, 217], fontSize: 7 },
      styles: { halign: 'center', lineColor: [223, 223, 223], lineWidth: 0.2 },
      tableWidth: 203,
      bodyStyles: { fontSize: 7 },
      // columnStyles: {
      //   0: { columnWidth: 50 },
      //   1: { columnWidth: 66 },
      //   2: { columnWidth: 66 },
      //   3: { columnWidth: 66 },
      // },
      head,
      body: datos,
      // createdCell: data => {
      //   if (data.section === 'head') {
      //     if (data.cell.styles.cellPadding < 4) {
      //       data.cell.styles.cellPadding = 4;
      //     }
      //   }
      // },
      willDrawCell: data => {
        if (data.pageNumber === page) {
          this.encabezadoPagPost(folio);
          this.descripcionValores();
          this.encabezadoTabla();
          page++;
        }


        if (data.section === 'head' && data.row.index === 0) {
          this.pdf.setFillColor(15, 192, 217);
        } else {
          (data.row.index + 1) % 2 === 0
            ? this.pdf.setFillColor(216, 245, 249)
            : this.pdf.setFillColor(255, 255, 255);
        }
      },
      didDrawPage: data => {
        this.writeTextTable('Los rendimientos que se hubieran obtenido en el pasado no se garantizan en el presente o en el futuro.', 7, 315);
      }
    });
  }

  /**
   * Inicia la generación del PDF y lo guarda
   * @method
   * @memberof PdfTarifasService
   */
  async generatePDF(folio: string) {
    await this.initPDF(folio);
    if ((this.device.getDeviceType() === 'iPad' || this.device.getDeviceType() === 'iPhone') && this.device.isSafari()) {
      const blob = URL.createObjectURL(this.pdf.output('blob'));
      window.open(blob);
    } else {
      this.pdf.save('Cotizacion');
    }
    // if ((this.device.getDeviceType() === 'iPad' || this.device.getDeviceType() === 'iPhone') && this.device.isSafari()) {
    //   this.initPDF(folio).then(() => {
    //     const blob = URL.createObjectURL(this.pdf.output('blob'));
    //     window.open(blob);
    //   });
    // } else {
    //   this.initPDF(folio).then(() => this.pdf.save('Cotizacion'));
    // }
  }

  /**
   * Genera texto en negritas
   * @method
   * @private
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @memberof PdfTarifasService
   */
  private writeBoldedText(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(11);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }

  /**
   * Escribe texto normal
   * @method
   * @private
   * @param {string} text
   * @param {number} x
   * @param {number} y
   * @memberof PdfTarifasService
   */
  private writeText(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(10.5);
    this.pdf.setFontStyle('normal');
    this.pdf.text(text, x, y);
  }

  private writeTextW(text: string, x: number, y: number): void {
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(11);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }
  private writeTextWTable(text: string, x: number, y: number): void {
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(8);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }
  private writeTextTable(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(8);
    this.pdf.setFontStyle('normal');
    this.pdf.text(text, x, y);
  }
  private writeBoldedTextBlue(text: string, x: number, y: number): void {
    this.pdf.setTextColor(15, 159, 179);
    this.pdf.setFontSize(11);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }
  private writeBoldedTextD(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(10);
    this.pdf.setFontStyle('bold');
    this.pdf.text(text, x, y);
  }
  private writeTextD(text: string, x: number, y: number): void {
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(9);
    this.pdf.setFontStyle('normal');
    this.pdf.text(text, x, y);
  }
  private writeTextRed(text: string, x: number, y: number): void {
    this.pdf.setTextColor(255, 0, 0);
    this.pdf.setFontSize(7);
    this.pdf.setFontStyle('normal');
    this.pdf.text(text, x, y);
  }

  /**
   * Escribe texto de producto
   * @method
   * @private
   * @param {PlanType} plan
   * @memberof PdfTarifasService
   */
  private writeProducto(plan: PlanType): void {
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(32);
    this.pdf.setFontStyle('bold');
    if (this.opciones.tipoPlan === 'VIDA INTELIGENTE') {
      this.pdf.text(plan, 64, 55);
    }
    if (this.opciones.tipoPlan === 'MI PROYECTO R') {
      this.pdf.text(plan, 70, 55);
    }
    this.pdf.setFontSize(20);
    this.pdf.text('VIDA Y  AHORRO', 87, 40);
  }

  /**
   * Transforma y le da formato a los valores de la tabla
   *
   * @private
   * @param {*} value
   * @param {string} field
   * @returns
   * @memberof PdfMiProyectoR
   */
  private formatCurrency$(value: any, field: string) {
    if (field === 'porcentajeRecuperacion') {
      return isNaN(value) ? 0.00 + ' %' : value.toFixed(2) + ' %';
    }

    if (field === 'anioPoliza' || field === 'edad' || field === 'edadCalculo') {
      return value;
    }

    return formatCurrency(isNaN(value) ? 0 : value, this.locale, '$', 'MXN', '1.0-0');
  }

  private addImage(x: number, y: number, w: number, h: number, file: string) {
    try {
      const image = new Image();
      image.src = file;
      this.pdf.addImage(
        image,
        file.split('.')[1].toUpperCase(),
        x,
        y,
        w,
        h,
        undefined,
        'FAST'
      );
    } catch (error) { }
  }

  private titlePrincipal(text: string, x: number, y: number) {
    this.pdf.setFontType('bold');
    this.pdf.setTextColor(0, 0, 0);
    this.pdf.setFontSize(13);
    this.pdf.text(text, x, y);
  }

  private fomattCurr(amount: number) {
    return formatCurrency(amount, this.locale, '$ ', 'MXN');
  }

}
