/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { Currency } from './../../constants/currency/currency.constant';
/** Models */
import { Prospectu } from '@models/prospectu/prospectu.model';
import { Plazo } from '@models/.';
import {
  ValorGarantizado,
  ValoresGarantizadosTabla
} from '@models/valor-garantizado/ValorGarantizado';
/** Services */
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { ValoresGarantizadosService } from '@services/valoresGarantizados/valores-garantizados.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { ExcedentesService } from '@services/excedentes/excedentes.service';
import { ProtectionPlansGeneratorService } from '@services/protectionPlansGenerator/protection-plans-generator.service';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';
import { FactorsSearch } from './../../models/factors-search/factors-search.model';
import { FactorsInpc } from './../../models/inpc/factors-inpc';
import { ValorGarantizadoInpc } from '@app/models/valor-garantizado/valor-garantizado-inpc';

/**
 * Servicio dedicado a la generación de la tabla de valores y cuestiones relacionadas a ella.
 * @export
 * @class GeneradorTablaValoresService
 */
@Injectable({
  providedIn: 'root'
})
export class GeneradorTablaValoresService {
  /**
   * Tabla actual. Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<ValorGarantizado[]>}
   * @default "[ ]"
   */
  tabla_Actual = new BehaviorSubject<ValorGarantizado[]>([]);
  tabla_ActualBa = new BehaviorSubject<ValorGarantizado[]>([]);
  paramsElegido = new BehaviorSubject<FactorsSearch>(null);
  ofertaSeleccionada = new BehaviorSubject<number>(0);
  /**
   * Tabla elegida. Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<ValorGarantizado[]>}
   * @default "[ ]"
   */
  tabla_Elegida = new BehaviorSubject<ValorGarantizado[]>([]);
  /**
   * Tabla sugerida No. 1. Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<ValorGarantizado[]>}
   * @default "[ ]"
   */
  tabla_Sugerida1 = new BehaviorSubject<ValorGarantizado[]>([]);
  /**
   * Tabla sugerida No. 2. Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<ValorGarantizado[]>}
   * @default "[ ]"
   */
  tabla_Sugerida2 = new BehaviorSubject<ValorGarantizado[]>([]);
  /**
   * Tabla elegida Basica Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<ValorGarantizado[]>}
   * @default "[ ]"
   */
  tabla_ElegidaBA = new BehaviorSubject<ValorGarantizado[]>([]);
  /**
   * Tabla elegida Basica mas coberturas (Se quitan las coberturas)Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<ValorGarantizado[]>}
   * @default "[ ]"
   */
  tabla_Sugerida1BA = new BehaviorSubject<ValorGarantizado[]>([]);
  /**
  * Tabla elegida Basica mas coberturas mas excedentes (Se quitan las coberturas y excedentes)Se crea una instancia reactiva.
  * @property
  * @type {BehaviorSubject<ValorGarantizado[]>}
  * @default "[ ]"
  */
  tabla_Sugerida2BA = new BehaviorSubject<ValorGarantizado[]>([]);
  /**
   *Valor Iterativo de la currency UDI.
   *@property
   */
  valorUDI_Iterativo = this.recursosExternos.valor_UDI;
  /**
   * Vista Valores Garantizados.
   * @property
   * @type {Array<ValoresGarantizadosTabla[]>}
   * @default "[ ]"
   */
  vistaVg: Array<ValoresGarantizadosTabla[]> = [];
  /**
   * Vista Columnas
   * @property
   * @type {Array<ValoresGarantizadosTabla[]>}
   * @default "[ ]"
   */
  vistaCols: ValoresGarantizadosTabla[] = [];
  /**
   * Vista Columnas
   *
   * @type {ValoresGarantizadosTabla[]}
   * @memberof GeneradorTablaValoresService
   */
  colsTest: ValoresGarantizadosTabla[] = [];
  /**
   * Vista del Header de la Tabla de Valores
   *
   * @type {string[]}
   * @memberof GeneradorTablaValoresService
   */
  vistaHeader: string[] = [];
  /**
   * Variable que detecta si mancomunado esta activo
   *
   * @type {boolean}
   * @memberof GeneradorTablaValoresService
   */
  ismancomunado: boolean;
  /**
   * Variable que almacena los datos del prospecto
   *
   * @type {Prospectu}
   * @memberof GeneradorTablaValoresService
   */
  prospectu: Prospectu;

  /**
   * Inicialización de variables. Se crea una instancia de GeneradorTablaValoresService
   * @param excedentes
   * @param recursosExternos
   * @param valoresG
   * @param protectionG
   */
  constructor(
    private excedentes: ExcedentesService,
    private planOptions: OpcionesPlanService,
    private recursosExternos: RecursosExternosService,
    private valoresG: ValoresGarantizadosService,
    private protectionG: ProtectionPlansGeneratorService,
    private prospectoService: ProspectoService
  ) { }
  /**
   * Método que genera la tabla de valores garantizados.
   *  @method
   * @param {number} plazosTotales
   * @param {number} edadInicial
   * @param {Plazo} plazoPago
   * @param {number} plazoPagoOpciones
   * @param {Currency} moneda
   * @param {boolean} [force=false]
   * @returns {ValorGarantizado[]}
   */
  generarTablaValores(
    plazosTotales: number,
    edadInicial: number,
    plazoPago: Plazo,
    plazoPagoOpciones: number,
    moneda: Currency,
    addOne: boolean = false
  ): ValorGarantizado[] {
    let datos: ValorGarantizado[] = [];
    this.valorUDI_Iterativo = this.recursosExternos.valor_UDI;
    let primaElegidaPesos = 0;
    let valorRescatePesos = 0;
    let sumaAseguradaPesos = 0;
    let primaEleUdiEdad = 0;
    let primaElegidaPesosEdad = 0;
    let prima_AcumuladaMXN = 0;
    let aportacion_MXN = 0;
    let valorEnEfectivo_UDI = 0;
    let valorEnEfectivo_Pesos = 0;
    let primaElegidaUDIConstante = 0;
    let ultimaPrimaAcomuladaUDI = 0;
    let ultimaPrimaAcomuladaMXN = 0;
    let edadAlcanzada = false;
    let edadAlcanzadaMXN = false;
    let iterar = 1;
    let porcentajeRec = 0;
    let porcentajeRecuperacionUdiBaCoExcd = 0;
    let porcentajeRecuperacionPesosBaCoExcd = 0;
    let edadTitular = this.planOptions.prospecto.age;
    // let primaAcumuladaBA = 0;
    // let saINPC;
    // let porcentaje_sa_inpc;
    // let primaInpc;
    // let primaInpcTotal = 0;
    // let age = 0;

    // let ejemploproyeccion = 0;
    // Se limpian variables
    this.valoresG.proyeccionAnterior.next(0);
    this.valoresG.proyeccion.next(0);
    this.valorUDI_Iterativo = 0;

    this.excedentes.valor_UDI.next(this.recursosExternos.valor_UDI);
    this.excedentes.excedente.next(0);
    this.excedentes.excedente_menos_comision.next(0);
    this.excedentes.no_aporte.next(0);
    this.excedentes.total_excedente.next(0);
    this.excedentes.iteracionCalculoAcumulado.next(1);

    this.valoresG.porcentajeRecuperacion.next(0);
    this.prospectoService.prospectoActual.subscribe(pros => {
      this.ismancomunado = pros.mancomunado.mancomunado;
      this.prospectu = pros;
    });
    
    let plazoTotal = Number(plazosTotales) - 1;    
    // validacion por tipo de plazo
    switch (true) {
      case this.planOptions.tipoPlan === 'TEMPORAL' &&
        Number(plazoTotal) === 60:
        plazoTotal = plazosTotales - this.planOptions.prospecto.age;
        break;

      case this.planOptions.tipoPlan === 'TEMPORAL' &&
        Number(plazoTotal) === 65:
        plazoTotal = plazosTotales - this.planOptions.prospecto.age;
        break;

      case this.planOptions.tipoPlan === 'TEMPORAL' &&
        Number(plazoTotal) === 70:
        plazoTotal = plazosTotales - this.planOptions.prospecto.age;
        break;

      case this.planOptions.tipoPlan === 'DOTAL' &&
        Number(plazoPagoOpciones) === 65:
        plazoTotal =
          plazosTotales -
          (this.ismancomunado
            ? this.prospectu.mancomunado.edadEquivalente
            : this.planOptions.prospecto.age);
        break;
      case this.planOptions.tipoPlan === 'OV' &&
        Number(plazoPagoOpciones) === 99:
        // plazosTotales -= this.planOptions.prospecto.age;
        plazoTotal =
          Number(plazoPagoOpciones) -
          (this.ismancomunado
            ? this.prospectu.mancomunado.edadEquivalente
            : this.planOptions.prospecto.age);
        break;
      case (this.planOptions.tipoPlan === 'DOTAL' ||
        this.planOptions.tipoPlan === 'TEMPORAL') &&
        plazosTotales !== 65:
        plazoTotal = addOne ? Number(plazosTotales) : Number(plazosTotales);
        break;
      case(this.planOptions.tipoPlan === 'ALIADOSKIDS'):
        plazoTotal =  Number(plazosTotales) - 1;

        //Agregar opcion para edad 99
    }

    if (
      this.planOptions.plazoPago.value === 65 &&
      !this.protectionG.esPenultimoPlazo.value &&
      this.protectionG.esUltimoPlazo &&
      plazoPagoOpciones === 35 &&
      plazoPago === 'Sugerido2'
    ) {
      plazoPago = 'Elegido';
    }
    if(localStorage.getItem('planElegido') == 'ProTGT' && 
    (this.planOptions.tipoPlan ==='OV' || this.planOptions.tipoPlan ==='VPL') && moneda=='UDI') {
      plazoTotal= plazoTotal + 1;
    } 

    for (let plazo = 1; plazo <= plazoTotal; plazo++) {
      const valor_UDI = this.excedentes.calculosExcedentes(plazo);
      this.valorUDI_Iterativo = valor_UDI; 
      const edadIterativa = Number(edadInicial - 1)  + Number(plazo);
      let eddP;
      if(this.planOptions.tipoPlan !== 'ALIADOSKIDS'){
        eddP = this.ismancomunado ? this.prospectu.mancomunado.edadEquivalente : this.planOptions.prospecto.age;
      }else{
        eddP = this.planOptions.prospecto.kids.ageKids;
      }
      const edadP = eddP;
      let valorRescate =
        plazo > plazosTotales || (plazo >= (65 - Number(this.planOptions.prospecto.age) + 1)
          && this.planOptions.tipoPlan === 'MUJER UNICA')
          ? 0
          : this.valoresG.generarValorDeRescate(
            plazo,
            plazoPago,
            plazoPagoOpciones,
            edadP
          );

      if (this.planOptions.tipoPlan === 'MUJER UNICA') {
        const valorRescateVPL =
          plazo > plazosTotales
            ? 0
            : this.valoresG.generarValorDeRescate(
              plazo,
              plazoPago,
              plazoPagoOpciones,
              edadP,
              true
            );
        valorRescate += valorRescateVPL;
      }
      if (valorRescate === -1) {
        break;
      } else {
        let plazoTest = Number(this.protectionG.plazoPagoElegido.value);
        let force = false;
        let specificCase = 0;

        if (
          this.planOptions.tipoPlan === 'DOTAL' &&
          Number(this.protectionG.plazoPagoElegido.value) === 65
        ) {
          force = true;
          switch (plazoPago) {
            case 'Elegido':
              plazoTest = 65;
              break;
          }

          if (plazo === 15) {
            plazoTest = 15;
          }
          if (plazo === 20) {
            plazoTest = 20;
          }
        } else {
          Number(this.protectionG.plazoPagoElegido.value);
        }

        if (this.planOptions.tipoPlan === 'DOTAL' && plazo) {
          switch (true) {
            case this.planOptions.plazoPago.value === 20 &&
              this.protectionG.esPenultimoPlazo.value &&
              !this.protectionG.esUltimoPlazo.value &&
              plazoPagoOpciones === 15 &&
              plazoPago === 'Elegido':
              specificCase = 1;

              break;

            case this.planOptions.plazoPago.value === 20 &&
              this.protectionG.esPenultimoPlazo.value &&
              !this.protectionG.esUltimoPlazo.value &&
              plazoPagoOpciones === 20 &&
              plazoPago === 'Sugerido1':
              specificCase = 2;

              break;

            case this.planOptions.plazoPago.value === 65 &&
              !this.protectionG.esPenultimoPlazo.value &&
              this.protectionG.esUltimoPlazo.value &&
              plazoPagoOpciones === 20 &&
              plazoPago === 'Sugerido1':
              specificCase = 3;

              break;

            case this.planOptions.plazoPago.value === 65 &&
              !this.protectionG.esPenultimoPlazo.value &&
              this.protectionG.esUltimoPlazo.value &&
              plazoPagoOpciones === 65 &&
              plazoPago === 'Elegido':
              specificCase = 4;

              break;

            case this.planOptions.plazoPago.value === 65 &&
              !this.protectionG.esPenultimoPlazo.value &&
              this.protectionG.esUltimoPlazo.value &&
              plazoPagoOpciones === 15 &&
              plazoPago === 'Elegido':
              specificCase = 5;

              break;

            case this.planOptions.plazoPago.value === 65 &&
              !this.protectionG.esPenultimoPlazo.value &&
              this.protectionG.esUltimoPlazo.value &&
              plazoPagoOpciones === 47 &&
              plazoPago === 'Sugerido2':
              specificCase = 6;

              break;
          }
        }
        if (plazoPago === 'ElegidoBA' || plazoPago === 'Sugerido1BA' || plazoPago === 'Sugerido2BA') {
          specificCase = 7;
        }
        const aportacionUDI = this.excedentes.total_excedente.value;
        porcentajeRec = this.valoresG.generarPorcentajeRecUDI(
          valorRescate,
          plazo,
          edadIterativa,
          plazo,
          plazoPagoOpciones,
          valor_UDI,
          plazoPago,
          specificCase,
          edadTitular
        );
        const primaElegidaUDI =
          plazo <= plazoPagoOpciones
            ? this.valoresG.primasCoberturasAdicionales(
              true,
              plazoPago,
              edadIterativa,
              plazoPagoOpciones,
              plazoTest,
              force,
              specificCase,
              edadTitular
            )
            : 0;
        // primaAcumuladaBA += primaElegidaUDI;
        // porcentajeRec = this.valoresG.porcentajeRecuperacionN(valorRescate, primaAcumuladaBA, valor_UDI);
        let sumaAsegurada = this.valoresG.generarSumaAsegurada(plazo);
        let primaDeducibleUDI = this.valoresG.asignaCalculoDeducibleUDI();
        // let primaNoDeducibleUDI = this.valoresG.asignaCalculoNoDeducibleUDI();
        let primaDeduciblePesos = this.valoresG.asignaCalculoDeducibleUDI() * valor_UDI;
        // let primaNoDeduciblePesos = this.valoresG.asignaCalculoNoDeducibleUDI() * valor_UDI;
        let primAcumuladaUDI = this.valoresG.proyeccion.value;
        // const primAcumuladaMXN = this.valoresG.primaAcumulada_MXN.value;
        const aportacionMXN = this.excedentes.excedentes_pesos.value;

        if (primaDeduciblePesos > 152000) {
          primaDeduciblePesos = 152000;
          let pd;
          pd = primaDeduciblePesos / valor_UDI;
          primaDeducibleUDI = pd;
        }

        let apAdicional;
        // if (iterar <= this.excedentes.numero_aportaciones.value) {
        if (iterar >= this.excedentes.primerAnioAportacion.value && iterar <= this.excedentes.segundaAportacion.value) {
          apAdicional = this.excedentes.aportacion_anual_UDI.value;
          iterar++;
        } else {
          apAdicional = 0;
          iterar++;
        }

        const aportacionProyectada = apAdicional * valor_UDI;
        const aportacionSumaFirst = this.excedentes.suma_aportacion_first.value;
        if (plazo === 1) {
          primaElegidaUDIConstante = primaElegidaUDI;
        }

        if (plazo <= this.excedentes.segundaAportacion.value) {
          primAcumuladaUDI = primAcumuladaUDI + aportacionSumaFirst;

          if (plazo === this.excedentes.segundaAportacion.value) {
            ultimaPrimaAcomuladaUDI = primAcumuladaUDI;
          }
        } else {
          if (this.excedentes.aportacion_anual_UDI.value !== 0) {
            if (this.planOptions.tipoPlan === 'VPL') {
              if (plazo <= plazoPagoOpciones && !edadAlcanzada) {
                primAcumuladaUDI =
                  ultimaPrimaAcomuladaUDI +
                  primaElegidaUDIConstante *
                  (plazo - this.excedentes.segundaAportacion.value);

                if (
                  plazo === Number(plazoPagoOpciones) ||
                  (edadIterativa === (Number(plazoPagoOpciones) - 1) &&
                    (Number(plazoPagoOpciones) === 65 ||
                      Number(plazoPagoOpciones) === 60 ||
                      Number(plazoPagoOpciones) === 70))
                ) {
                  edadAlcanzada = true;
                  ultimaPrimaAcomuladaUDI = primAcumuladaUDI;
                }
              } else {
                primAcumuladaUDI = ultimaPrimaAcomuladaUDI;
              }
            } else {
              primAcumuladaUDI =
                primaElegidaUDIConstante *
                this.excedentes.segundaAportacion.value +
                aportacionSumaFirst +
                (this.excedentes.iteracionCalculoAcumulado.value - 1) *
                this.excedentes.aportacion_anual_UDI.value +
                primaElegidaUDIConstante *
                (plazo - this.excedentes.segundaAportacion.value);
            }
          }
        }

        sumaAsegurada += this.excedentes.total_excedente.value;

        switch (moneda) {
          case 'MXN':
            primaElegidaPesos = primaElegidaUDI;
            valorRescatePesos = valorRescate;
            sumaAseguradaPesos = sumaAsegurada;

            if (plazo >= this.excedentes.primerAportacion.value && plazo <= this.excedentes.segundaAportacion.value) {
              prima_AcumuladaMXN =
                primaElegidaPesos +
                this.excedentes.aportacion_anual_UDI.value +
                prima_AcumuladaMXN;

              if (plazo === this.excedentes.segundaAportacion.value) {
                ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
              }
            } else {
              if (plazo <= Number(plazoPagoOpciones) && !edadAlcanzadaMXN) {
                prima_AcumuladaMXN = primaElegidaPesos + prima_AcumuladaMXN;

                if (plazo === Number(plazoPagoOpciones) || (edadIterativa === (Number(plazoPagoOpciones) - 1) &&
                  (Number(plazoPagoOpciones) === 65 ||
                    Number(plazoPagoOpciones) === 60 ||
                    Number(plazoPagoOpciones) === 70))) {
                  edadAlcanzadaMXN = true;
                  ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
                }
              } else {
                prima_AcumuladaMXN = ultimaPrimaAcomuladaMXN;
              }
            }
            aportacion_MXN = aportacionUDI;
            valorEnEfectivo_UDI =
              plazo <= this.planOptions.plazoSeguro
                ? aportacionUDI + valorRescate
                : valorRescate;
            valorEnEfectivo_Pesos =
              plazo <= this.planOptions.plazoSeguro
                ? aportacionUDI + valorRescatePesos
                : valorRescatePesos;
            porcentajeRecuperacionPesosBaCoExcd =
              plazo <= this.planOptions.plazoSeguro
                ? valorEnEfectivo_Pesos / prima_AcumuladaMXN
                : 0;
            porcentajeRecuperacionUdiBaCoExcd =
              plazo <= this.planOptions.plazoSeguro
                ? valorEnEfectivo_Pesos / prima_AcumuladaMXN
                : 0;
            break;
          case 'UDI':
            primaElegidaPesos = primaElegidaUDI * valor_UDI;
            valorRescatePesos = valorRescate * valor_UDI;
            sumaAseguradaPesos = sumaAsegurada * valor_UDI;
            aportacion_MXN = aportacionUDI * valor_UDI;

            if (plazo >= this.excedentes.primerAportacion.value && plazo <= this.excedentes.segundaAportacion.value) {
              prima_AcumuladaMXN =
                primaElegidaPesos +
                this.excedentes.aportacion_anual_UDI.value * valor_UDI +
                prima_AcumuladaMXN;

              if (plazo === this.excedentes.segundaAportacion.value) {
                ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
              }
            } else {
              if (plazo <= Number(plazoPagoOpciones) && !edadAlcanzadaMXN) {
                prima_AcumuladaMXN = primaElegidaPesos + prima_AcumuladaMXN;

                if (plazo === Number(plazoPagoOpciones) || (edadIterativa === (Number(plazoPagoOpciones) - 1) &&
                  (Number(plazoPagoOpciones) === 65 ||
                    Number(plazoPagoOpciones) === 60 ||
                    Number(plazoPagoOpciones) === 70))) {
                  edadAlcanzadaMXN = true;
                  ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
                }
              } else {
                prima_AcumuladaMXN = ultimaPrimaAcomuladaMXN;
              }
            }

            valorEnEfectivo_UDI =
              plazo <= this.planOptions.plazoSeguro
                ? aportacionUDI + valorRescate
                : valorRescate;
            valorEnEfectivo_Pesos =
              plazo <= this.planOptions.plazoSeguro
                ? aportacionMXN + valorRescatePesos
                : valorRescatePesos;
            porcentajeRecuperacionUdiBaCoExcd =
              plazo <= this.planOptions.plazoSeguro
                ? valorEnEfectivo_UDI / primAcumuladaUDI : 0;
            porcentajeRecuperacionPesosBaCoExcd =
              plazo <= this.planOptions.plazoSeguro
                ? valorEnEfectivo_Pesos / prima_AcumuladaMXN : 0;
            break;
          case 'USD':
            primaElegidaPesos = primaElegidaUDI;
            valorRescatePesos = valorRescate;
            sumaAseguradaPesos = sumaAsegurada;

            if (plazo >= this.excedentes.primerAportacion.value && plazo <= this.excedentes.segundaAportacion.value) {
              prima_AcumuladaMXN =
                primaElegidaPesos +
                this.excedentes.aportacion_anual_UDI.value +
                prima_AcumuladaMXN;

              if (plazo === this.excedentes.segundaAportacion.value) {
                ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
              }
            } else {
              if (plazo <= Number(plazoPagoOpciones) && !edadAlcanzadaMXN) {
                prima_AcumuladaMXN = primaElegidaPesos + prima_AcumuladaMXN;

                if (plazo === Number(plazoPagoOpciones) || (edadIterativa === (Number(plazoPagoOpciones) - 1) &&
                  (Number(plazoPagoOpciones) === 65 ||
                    Number(plazoPagoOpciones) === 60 ||
                    Number(plazoPagoOpciones) === 70))) {
                  edadAlcanzadaMXN = true;
                  ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
                }
              } else {
                prima_AcumuladaMXN = ultimaPrimaAcomuladaMXN;
              }
            }
            // prima_AcumuladaMXN =
            //   plazo <= this.excedentes.segundaAportacion.value
            //     ? primAcumuladaMXN + aportacionSumaFirst
            //     : prima_AcumuladaMXN + primaElegidaUDI;
            aportacion_MXN = aportacionUDI;
            valorEnEfectivo_UDI =
              plazo <= this.planOptions.plazoSeguro
                ? aportacionUDI + valorRescate
                : valorRescate;
            valorEnEfectivo_Pesos =
              plazo <= this.planOptions.plazoSeguro
                ? aportacionUDI + valorRescatePesos
                : valorRescatePesos;
            porcentajeRecuperacionPesosBaCoExcd =
              plazo <= this.planOptions.plazoSeguro
                ? valorEnEfectivo_UDI / primAcumuladaUDI : 0;
            porcentajeRecuperacionUdiBaCoExcd =
              plazo <= this.planOptions.plazoSeguro
                ? valorEnEfectivo_UDI / primAcumuladaUDI : 0;
            break;
        }
        porcentajeRecuperacionUdiBaCoExcd = porcentajeRecuperacionUdiBaCoExcd * 100;
        porcentajeRecuperacionPesosBaCoExcd = porcentajeRecuperacionPesosBaCoExcd * 100;
        primaEleUdiEdad = Number(primaElegidaUDI.toFixed(0));
        let porcentajeRecuperacion_MXN = 0;
        // if (edadIterativa >= plazo) { // edad - plazo === 1
        //   porcentajeRecuperacion_MXN = ( valorRescatePesos / primaElegidaPesosEdad ) * 100;
        // } else {
        porcentajeRecuperacion_MXN =
          (valorRescatePesos / this.valoresG.primaAcumulada_MXN.value) * 100;
        // }
        primaElegidaPesosEdad = Number(primaElegidaPesos.toFixed(2));

        // TO-DO plazo pago traer el valor
        switch (Number(plazoPagoOpciones)) {
          case 60:
            primaEleUdiEdad =
              edadIterativa < 60 ? Number(primaElegidaUDI.toFixed(0)) : 0;
            primaElegidaPesosEdad =
              edadIterativa < 60 ? Number(primaElegidaPesos.toFixed(0)) : 0;
            break;

          case 65:
            primaEleUdiEdad =
              edadIterativa < 65 ? Number(primaElegidaUDI.toFixed(0)) : 0;
            primaElegidaPesosEdad =
              edadIterativa < 65 ? Number(primaElegidaPesos.toFixed(0)) : 0;
            break;

          case 70:
            primaEleUdiEdad =
              edadIterativa < 70 ? Number(primaElegidaUDI.toFixed(0)) : 0;
            primaElegidaPesosEdad =
              edadIterativa < 70 ? Number(primaElegidaPesos.toFixed(0)) : 0;
            break;
        }

        datos = [
          ...datos,
          {
            edad: edadIterativa,
            anioPoliza: plazo,
            primaElegida: primaEleUdiEdad,
            aportacionAdicional_UDI:
              plazo <= this.planOptions.plazoSeguro
                ? Number(aportacionUDI.toFixed(0))
                : 0,
            prima_AcumuladaUDI: Number(primAcumuladaUDI.toFixed(0)),
            primaDeducibleUDI: Number(primaDeducibleUDI.toFixed(2)),
            // primaNoDeducibleUDI: Number(primaNoDeducibleUDI.toFixed(2)),
            valorRescate: Number(valorRescate.toFixed(2)),
            porcentajeRecuperacionUdi: Number(porcentajeRec.toFixed(2)),
            sumaAsegurada: Number(sumaAsegurada.toFixed(0)),
            valorUDI: Number(valor_UDI),
            primaElegida_Pesos: primaElegidaPesosEdad,
            aportacionAdicional_MXN:
              plazo <= this.planOptions.plazoSeguro
                ? Number(aportacion_MXN.toFixed(0))
                : 0,
            prima_AcumuladaMXN: Number(prima_AcumuladaMXN.toFixed(0)),
            primaDeducibleMXN: Number(primaDeduciblePesos.toFixed(2)),
            // primaNoDeducibleMXN: Number(primaNoDeduciblePesos.toFixed(2)),
            valorRescate_Pesos: Number(valorRescatePesos.toFixed(2)),
            porcentajeRecuperacionPesos: Number(
              porcentajeRecuperacion_MXN.toFixed(2)
            ),
            sumaAsegurada_Pesos: Number(sumaAseguradaPesos.toFixed(2)),
            valorEnEfectivo_UDI: Number(valorEnEfectivo_UDI.toFixed(2)),
            valorEnEfectivo_Pesos: Number(valorEnEfectivo_Pesos.toFixed(2)),
            aportacionAdicional: Number(apAdicional),
            aportacionAdicionalProyectada: Number(aportacionProyectada.toFixed(2)),
            porcentajeRecuperacionUdiBaCoExcd: Number(porcentajeRecuperacionUdiBaCoExcd.toFixed(2)),
            porcentajeRecuperacionPesosBaCoExcd: Number(porcentajeRecuperacionPesosBaCoExcd.toFixed(2)),
            porcentajeRecuperacionUdiBaCo: 0,
            porcentajeRecuperacionPesosBaCo: 0
          }
        ];
      }
      edadTitular++;
    }

    let parsedDatos: ValorGarantizado[] = [];
    for (let i = 0; i < datos.length; i++) {
      if (i === 0) {
        parsedDatos = [
          ...parsedDatos,
          {
            edad: datos[i].edad,
            anioPoliza: datos[i].anioPoliza,
            primaElegida: datos[i].primaElegida,
            aportacionAdicional_UDI: datos[i].aportacionAdicional_UDI,
            prima_AcumuladaUDI: datos[i].prima_AcumuladaUDI,
            primaDeducibleUDI: datos[i].primaDeducibleUDI,
            // primaNoDeducibleUDI: datos[i].primaNoDeducibleUDI,
            valorRescate: datos[i].valorRescate,
            porcentajeRecuperacionUdi: datos[i].porcentajeRecuperacionUdi,
            sumaAsegurada: datos[i].sumaAsegurada,
            valorUDI: datos[i].valorUDI,
            primaElegida_Pesos: datos[i].primaElegida_Pesos,
            aportacionAdicional_MXN: datos[i].aportacionAdicional_MXN,
            prima_AcumuladaMXN: datos[i].prima_AcumuladaMXN,
            primaDeducibleMXN: datos[i].primaDeducibleMXN,
            // primaNoDeducibleMXN: datos[i].primaNoDeducibleMXN,
            valorRescate_Pesos: datos[i].valorRescate_Pesos,
            porcentajeRecuperacionPesos: datos[i].porcentajeRecuperacionPesos,
            sumaAsegurada_Pesos: datos[i].sumaAsegurada_Pesos,
            valorEnEfectivo_UDI: datos[i].valorEnEfectivo_UDI,
            valorEnEfectivo_Pesos: datos[i].valorEnEfectivo_Pesos,
            aportacionAdicional: datos[i].aportacionAdicional,
            aportacionAdicionalProyectada: datos[i].aportacionAdicionalProyectada,
            porcentajeRecuperacionUdiBaCoExcd: datos[i].porcentajeRecuperacionUdiBaCoExcd,
            porcentajeRecuperacionPesosBaCoExcd: datos[i].porcentajeRecuperacionPesosBaCoExcd,
            porcentajeRecuperacionUdiBaCo: 0,
            porcentajeRecuperacionPesosBaCo: 0
          }
        ];
      } else if (i === Number(plazoPago)) {
        parsedDatos = [
          ...parsedDatos,
          {
            edad: datos[i].edad,
            anioPoliza: datos[i].anioPoliza,
            primaElegida: 0,
            aportacionAdicional_UDI: datos[i].aportacionAdicional_UDI,
            prima_AcumuladaUDI: datos[i].prima_AcumuladaUDI,
            primaDeducibleUDI: datos[i].primaDeducibleUDI,
            // primaNoDeducibleUDI: datos[i].primaNoDeducibleUDI,
            valorRescate: datos[i].valorRescate,
            porcentajeRecuperacionUdi: datos[i].porcentajeRecuperacionUdi,
            sumaAsegurada: datos[i].sumaAsegurada,
            valorUDI: datos[i].valorUDI,
            primaElegida_Pesos: datos[i].primaElegida_Pesos,
            aportacionAdicional_MXN: datos[i].aportacionAdicional_MXN,
            prima_AcumuladaMXN: datos[i].prima_AcumuladaMXN,
            primaDeducibleMXN: datos[i].primaDeducibleMXN,
            // primaNoDeducibleMXN: datos[i].primaNoDeducibleMXN,
            valorRescate_Pesos: datos[i].valorRescate_Pesos,
            porcentajeRecuperacionPesos: datos[i].porcentajeRecuperacionPesos,
            sumaAsegurada_Pesos: datos[i].sumaAsegurada_Pesos,
            valorEnEfectivo_UDI: datos[i].valorEnEfectivo_UDI,
            valorEnEfectivo_Pesos: datos[i].valorEnEfectivo_Pesos,
            aportacionAdicional: datos[i].aportacionAdicional,
            aportacionAdicionalProyectada: datos[i].aportacionAdicionalProyectada,
            porcentajeRecuperacionUdiBaCoExcd: datos[i].porcentajeRecuperacionUdiBaCoExcd,
            porcentajeRecuperacionPesosBaCoExcd: datos[i].porcentajeRecuperacionPesosBaCoExcd,
            porcentajeRecuperacionUdiBaCo: 0,
            porcentajeRecuperacionPesosBaCo: 0
          }
        ];
      } else {
        parsedDatos = [
          ...parsedDatos,
          {
            edad: datos[i].edad,
            anioPoliza: datos[i].anioPoliza,
            primaElegida: datos[i - 1].primaElegida,
            aportacionAdicional_UDI: datos[i].aportacionAdicional_UDI,
            prima_AcumuladaUDI: datos[i].prima_AcumuladaUDI,
            primaDeducibleUDI: datos[i].primaDeducibleUDI,
            // primaNoDeducibleUDI: datos[i].primaNoDeducibleUDI,
            valorRescate: datos[i].valorRescate,
            porcentajeRecuperacionUdi: datos[i].porcentajeRecuperacionUdi,
            sumaAsegurada: datos[i].sumaAsegurada,
            valorUDI: datos[i].valorUDI,
            primaElegida_Pesos: datos[i].primaElegida_Pesos,
            aportacionAdicional_MXN: datos[i].aportacionAdicional_MXN,
            prima_AcumuladaMXN: datos[i].prima_AcumuladaMXN,
            primaDeducibleMXN: datos[i].primaDeducibleMXN,
            // primaNoDeducibleMXN: datos[i].primaNoDeducibleMXN,
            valorRescate_Pesos: datos[i].valorRescate_Pesos,
            porcentajeRecuperacionPesos: datos[i].porcentajeRecuperacionPesos,
            sumaAsegurada_Pesos: datos[i].sumaAsegurada_Pesos,
            valorEnEfectivo_UDI: datos[i].valorEnEfectivo_UDI,
            valorEnEfectivo_Pesos: datos[i].valorEnEfectivo_Pesos,
            aportacionAdicional: datos[i].aportacionAdicional,
            aportacionAdicionalProyectada: datos[i].aportacionAdicionalProyectada,
            porcentajeRecuperacionUdiBaCoExcd: datos[i].porcentajeRecuperacionUdiBaCoExcd,
            porcentajeRecuperacionPesosBaCoExcd: datos[i].porcentajeRecuperacionPesosBaCoExcd,
            porcentajeRecuperacionUdiBaCo: 0,
            porcentajeRecuperacionPesosBaCo: 0
          }
        ];
      }
    }
    return datos;
  }

  generarTablaInpc(factInpc: FactorsInpc[], factValorResc: any[]): ValorGarantizadoInpc[] {
    const arrVgInpc: ValorGarantizadoInpc[] = [];
    const edadInicial = this.planOptions.prospecto.mancomunado.mancomunado ? this.planOptions.prospecto.mancomunado.edadEquivalente :
    this.planOptions.prospecto.age;
    const plazoTotal = this.planOptions.tipoPlan === 'OV' ?
      this.planOptions.plazoSeguro - Number(edadInicial) + 1 : this.planOptions.plazoSeguro;
    const plazoPago = this.planOptions.plazoPago.value;
    let saINPC, porcentaje_sa_inpc, aumentoPr, primaAcumulada;
    let primaInpcTotal = 0;
    const sumaAseguradaInicial = this.valoresG.generarSumaAsegurada();
    const plazos = this.planOptions.tipoPlan === ('VPL' || 'OV') || (this.planOptions.tipoPlan === 'TEMPORAL' && this.planOptions.plazoSeguro >= 60)
      ? this.planOptions.plazoSeguro - edadInicial : this.planOptions.plazoSeguro;
    const arrValoresRescate = this.generarValoresRescateInpc(factValorResc, sumaAseguradaInicial);
    let lastAumentos = [];
    let sumasPasadas = [];
    let lastAumento;
    let lastPrimaAcumulada = 0;
    let lastPrimaElegida = 0;
    let lastSumaAsegurada = 0;
    let aumentoParaPCD = 0;
    let edadManconunado = this.planOptions.prospecto.mancomunado.mancomunado ? this.planOptions.prospecto.mancomunado.edadEquivalente : 0;
    let edad = this.planOptions.prospecto.age;
    let bolExcedentes = false;
    for (let plazo = 1; plazo <= plazos; plazo++) {
      let vgInpc: ValorGarantizadoInpc;
      vgInpc = {
        edad: 0, anioPoliza: 0, sumaAsegurada_Pesos: 0,
        primaElegida_Pesos: 0, prima_AcumuladaMXN: 0, valorRescate_Pesos: 0, porcentajeRecuperacionPesos: 0,
        aportacionAdicionalProyectada: 0, aportacionAdicional_MXN: 0, valorEnEfectivo_Pesos: 0
      };
      const edadIterativa = Number(edadInicial - 1) + Number(plazo);
      const primaElegidaUDI =
        plazo <= plazoPago
          ? this.valoresG.primaBA : 0;
      vgInpc.edad = edadIterativa;
      vgInpc.anioPoliza = plazo;

      if (plazo < (this.planOptions.tipoPlan !== 'TEMPORAL' ? plazoTotal : plazoTotal + 1)) {
        const factValue = factInpc;
        if (plazo === 1) {
          saINPC = sumaAseguradaInicial;
          aumentoPr = 0;
          porcentaje_sa_inpc = 0;
          primaAcumulada = primaElegidaUDI;
          primaInpcTotal = primaElegidaUDI;
        }

        const porcentaje_inpc = this.valoresG.porcentajeINPC();
        if (plazo > 1) {
          if (plazo <= factInpc.length) {

            if (plazo === 2) {
              // incremento
              porcentaje_sa_inpc = this.valoresG.porcentajeSAinpc();
              saINPC = this.valoresG.saINPC();
              aumentoPr = (porcentaje_sa_inpc * Number(factValue[plazo - 1].tarifa) / 1000);
              primaInpcTotal = primaElegidaUDI + aumentoPr;
              primaAcumulada = arrVgInpc[plazo - 2].prima_AcumuladaMXN + primaInpcTotal;
            } else {
              // incremento
              porcentaje_sa_inpc = (saINPC * porcentaje_inpc);
              saINPC = saINPC + porcentaje_sa_inpc;
              aumentoPr = (porcentaje_sa_inpc * Number(factValue[plazo - 1].tarifa)) / 1000;
              primaInpcTotal = primaInpcTotal + aumentoPr;
              primaAcumulada = arrVgInpc[plazo - 2].prima_AcumuladaMXN + primaInpcTotal;

              if (this.planOptions.tipoPlan === 'VPL') {
                saINPC = saINPC;
                // aumentoPr = 0;
                if (plazo <= factInpc.length && plazo < 60) {
                  lastPrimaAcumulada = primaAcumulada;
                }
              }
              if (this.planOptions.tipoPlan === 'OV') {
                saINPC = saINPC;
                aumentoPr = aumentoPr;
              }
              // plazo < factInpc.length && this.planOptions.tipoPlan === 'OV' ?
              //   lastPrimaAcumulada = primaAcumulada : primaAcumulada = lastPrimaAcumulada;
              if (this.planOptions.tipoPlan === 'VPL' && plazo === factInpc.length && Number(this.planOptions.plazoPago.value) < 60) {
                lastPrimaAcumulada = primaAcumulada;
              }

              if (this.planOptions.tipoPlan === 'VPL' && plazo === factInpc.length && Number(this.planOptions.plazoPago.value) >= 60) {
                lastPrimaAcumulada = arrVgInpc[plazo - 2].prima_AcumuladaMXN;
                primaAcumulada = lastPrimaAcumulada;
              }
            }
          } else {
            // incremento
            porcentaje_sa_inpc = (saINPC * porcentaje_inpc);
            saINPC = saINPC + porcentaje_sa_inpc;
            aumentoPr = (porcentaje_sa_inpc * 0) / 1000;
            primaInpcTotal = primaInpcTotal + aumentoPr;
            lastPrimaAcumulada = arrVgInpc[plazo - 2].prima_AcumuladaMXN;
            primaAcumulada = lastPrimaAcumulada;

            if (this.planOptions.tipoPlan === 'VPL') {
              saINPC = saINPC;
              // aumentoPr = 0;
            }
            if (this.planOptions.tipoPlan === 'OV') {
              saINPC = saINPC;
              aumentoPr = aumentoPr;
             // primaAcumulada = arrVgInpc[plazo - 2].prima_AcumuladaMXN + primaInpcTotal;
            }
            if (plazo <= this.planOptions.plazoPago.value) {
              primaAcumulada = arrVgInpc[plazo - 2].prima_AcumuladaMXN;
              primaAcumulada = (this.planOptions.tipoPlan === 'VPL') ? primaAcumulada : (primaAcumulada + primaInpcTotal);
            }
          }
        }


        if (this.valoresG.activeCoberturas) {
          if (edadIterativa === 60) {
            aumentoParaPCD = aumentoPr;
          }

          if (plazo <= factInpc.length) {
            const a = this.valoresG.calculoINPCCoberturas(
              plazo > 2 ? primaInpcTotal - lastAumento : primaInpcTotal,
              // plazo === 1 ? Number(this.planOptions.prospecto.age) : Number(this.planOptions.prospecto.age) + plazo - 1,
              edad,
              sumasPasadas,
              lastAumentos,
              aumentoPr,
              aumentoParaPCD,
              plazo,
              edadManconunado
            );

            primaInpcTotal =
              plazo < 3
                ? primaInpcTotal + a.suma
                : primaInpcTotal + a.suma - lastAumento;

            plazo < 3 ? primaAcumulada += a.suma : primaAcumulada += a.suma - lastAumento;

            sumasPasadas = a.sumasPasadas;
            lastAumentos = a.last;
            lastAumento = a.suma;
            lastPrimaAcumulada = primaAcumulada;
          } else {
            // incremento
            // primaInpcTotal = 0;
            porcentaje_sa_inpc = saINPC * porcentaje_inpc;
            saINPC = saINPC + porcentaje_sa_inpc;
            aumentoPr =
              (porcentaje_sa_inpc * 0) / 1000;
            primaInpcTotal = primaInpcTotal + aumentoPr;

            if (this.planOptions.tipoPlan === 'VPL') {
              saINPC = saINPC;
              // aumentoPr = 0;
            }
            if (this.planOptions.tipoPlan === 'OV') {
              saINPC = saINPC;
              aumentoPr = aumentoPr;
            }
          }
        }

        vgInpc.sumaAsegurada_Pesos = saINPC;
        if (
          this.planOptions.plazoPago.value >= 60 && this.planOptions.tipoPlan !== 'OV' ?
            plazo <= factInpc.length : plazo <= factInpc.length) {
          vgInpc.primaElegida_Pesos = primaInpcTotal;
          if (primaInpcTotal > 0) {
            lastPrimaElegida = primaInpcTotal;
          }
          lastSumaAsegurada = saINPC;
        } else {
          vgInpc.primaElegida_Pesos = (this.planOptions.tipoPlan === 'OV') ? lastPrimaElegida : 0;
          vgInpc.sumaAsegurada_Pesos = lastSumaAsegurada;
        }
        if (this.planOptions.tipoPlan === 'VPL' && this.planOptions.plazoPago.value >= 60) {
          if ((edadManconunado === 0 ? edad : edadManconunado) > (this.planOptions.plazoPago.value - 1)) {
            primaAcumulada = arrVgInpc[plazo - 2].prima_AcumuladaMXN;
            primaAcumulada = vgInpc.primaElegida_Pesos > 0 ? (primaAcumulada + vgInpc.primaElegida_Pesos) : primaAcumulada;
          }
        }
        if ((edadManconunado === 0 ? edad : edadManconunado) > 70) {
          let primaMxn = arrVgInpc[plazo - 2].primaElegida_Pesos;
          primaMxn = (this.planOptions.tipoPlan === 'VPL' && plazo > this.planOptions.plazoPago.value) ? 0 : primaMxn;
          vgInpc.primaElegida_Pesos = primaMxn;
          primaAcumulada = arrVgInpc[plazo - 2].prima_AcumuladaMXN;
          primaAcumulada = vgInpc.primaElegida_Pesos > 0 ? (primaAcumulada + vgInpc.primaElegida_Pesos) : primaAcumulada;
        }
        if (vgInpc.primaElegida_Pesos > 0) {

          vgInpc.primaElegida_Pesos -=
            this.valoresG.cancelarCoberturas(edadManconunado === 0 ? edad : edadManconunado, lastAumentos);
          // this.valoresG.cancelarCoberturas(Number(this.planOptions.prospecto.age) + plazo - 1, lastAumentos);
          primaAcumulada = primaAcumulada
            - this.valoresG.cancelarCoberturas(edadManconunado === 0 ? edad : edadManconunado, lastAumentos);
          // - this.valoresG.cancelarCoberturas(Number(this.planOptions.prospecto.age) + plazo - 1, lastAumentos);
        }
        vgInpc.prima_AcumuladaMXN = primaAcumulada;

        // vgInpc.primaElegida_Pesos = ageResult <= 70 &&
        //   (this.planOptions.tipoPlan !== 'VPL' && this.planOptions.plazoPago.value >= 60 ?
        //     plazo <= factInpc.length : plazo < factInpc.length) ? primaInpcTotal : 0;


        // Number(aumentoPr.toFixed(0));
        this.excedentes.calculosExcedentes(plazo);
        if (plazo >= this.excedentes.primerAportacion.value && plazo <= this.excedentes.segundaAportacion.value) {
          vgInpc.aportacionAdicionalProyectada = this.excedentes.aportacion_anual_UDI.value;
          vgInpc.prima_AcumuladaMXN += vgInpc.aportacionAdicionalProyectada;
          bolExcedentes = true;
        } else {
          vgInpc.aportacionAdicionalProyectada = 0;
        }
        if (bolExcedentes) {
          vgInpc.aportacionAdicional_MXN = this.excedentes.total_excedente.value;
        } else {
          vgInpc.aportacionAdicional_MXN = 0;
        }
        vgInpc.valorRescate_Pesos = arrValoresRescate[plazo - 1];
        vgInpc.valorEnEfectivo_Pesos = vgInpc.valorRescate_Pesos + vgInpc.aportacionAdicional_MXN;
        vgInpc.porcentajeRecuperacionPesos = Number(
          ((vgInpc.valorRescate_Pesos / vgInpc.prima_AcumuladaMXN) * 100).toFixed(2)
        );
        arrVgInpc.push(vgInpc);
      }
      if (this.planOptions.prospecto.mancomunado.mancomunado) {
        edadManconunado++;
      }
      edad++;
    }
    return arrVgInpc;
  }


  private generarValoresRescateInpc(factValorResc: any[], sumaAseguradaInicial: number) {
    let incremento = sumaAseguradaInicial;
    let vr = 0;
    const edadM = Number(this.planOptions.prospecto.mancomunado.mancomunado ? this.planOptions.prospecto.mancomunado.edadEquivalente :
      this.planOptions.prospecto.age);
    const plazos = this.planOptions.tipoPlan === 'VPL' || (this.planOptions.tipoPlan === 'TEMPORAL' && this.planOptions.plazoSeguro >= 60)
      ? this.planOptions.plazoSeguro - edadM : this.planOptions.plazoSeguro;
    const valorRescate: number[][] = [];
    if (this.planOptions.tipoPlan === 'OV' || Number(this.planOptions.plazoPago.value) >= 60) {
      for (let i = edadM;
        i <= (this.planOptions.tipoPlan === 'OV' ? 70 : Number(this.planOptions.plazoPago.value) - 1); i++) {
        if (vr === 1) {
          incremento = (incremento * this.planOptions.incrementoInpcPorc.value);
        } else if (vr > 1) {
          incremento = incremento + (incremento * this.planOptions.incrementoInpcPorc.value);
        }

        valorRescate[vr] = [];
        const factores = factValorResc.filter(s => Number(s.vpl) === Number(i))[0].factores;

        valorRescate[vr].push(...Array(i - edadM).fill(0));

        for (let j = 1; j <= plazos; j++) {
          const factor = Number(factores[j - 1]);
          const vaResc = (incremento * factor) / 1000;

          valorRescate[vr][valorRescate[vr].length] = vaResc;
        }
        vr++;
      }
    } else {
      const plazoPago = this.planOptions.plazoPago.value;
      // plazoPago = this.planOptions.tipoPlan === 'TEMPORAL' ? plazoPago - 1 : plazoPago;
      for (let i = Number(plazoPago); i > 0; i--) {
        if (vr === 1) {
          incremento = (incremento * this.planOptions.incrementoInpcPorc.value);
        } else if (vr > 1) {
          incremento = incremento + (incremento * this.planOptions.incrementoInpcPorc.value);
        }

        valorRescate[vr] = [];
        const factores = plazoPago - i === 0 ? [] :
          factValorResc.filter(s => Number(s.vpl) === Number(i))[0].factores;

        valorRescate[vr].push(...Array(Number(plazoPago) - i).fill(0));

        for (let j = 1; j <= plazos; j++) {
          let factor = (plazoPago - i) > 0 ?
            Number(factores[j - 1]) : this.valoresG.obtenerFactorValorGarantizado(undefined, j - 1);
          factor = isNaN(factor) ? 0 : factor;
          let vaResc = (incremento * factor) / 1000;
          vaResc = isNaN(vaResc) ? 0 : vaResc;
          valorRescate[vr][valorRescate[vr].length] = vaResc;
        }
        vr++;
      }
    }

    const valorResc: number[] = [];
    for (let i = 0; i < valorRescate[0].length; i++) {
      let suma = 0;
      for (let j = 0; j < valorRescate.length; j++) {
        suma = suma + valorRescate[j][i];
      }
      valorResc.push(suma);
    }

    return valorResc;
  }

  generarTablaValoresPropuestas(
    plazosTotales: number,
    edadInicial: number,
    plazoPago: Plazo,
    plazoPagoOpciones: number,
    moneda: Currency,
    addOne: boolean = false,
    params: FactorsSearch
  ): ValorGarantizado[] {
    let datos: ValorGarantizado[] = [];
    this.valorUDI_Iterativo = this.recursosExternos.valor_UDI;
    let primaElegidaPesos = 0;
    let valorRescatePesos = 0;
    let sumaAseguradaPesos = 0;
    let primaEleUdiEdad = 0;
    let primaElegidaPesosEdad = 0;
    let prima_AcumuladaMXN = 0;
    let aportacion_MXN = 0;
    let valorEnEfectivo_UDI = 0;
    let valorEnEfectivo_Pesos = 0;
    let primaElegidaUDIConstante = 0;
    let ultimaPrimaAcomuladaUDI = 0;
    let ultimaPrimaAcomuladaMXN = 0;
    let edadAlcanzada = false;
    let edadAlcanzadaMXN = false;
    let iterar = 1;
    let porcentajeRec = 0;
    let porcentajeRecuperacionUdiBaCoExcd = 0;
    let porcentajeRecuperacionPesosBaCoExcd = 0;

    this.valoresG.proyeccionAnterior.next(0);
    this.valoresG.proyeccion.next(0);
    this.valorUDI_Iterativo = 0;

    this.excedentes.valor_UDI.next(this.recursosExternos.valor_UDI);
    this.excedentes.excedente.next(0);
    this.excedentes.excedente_menos_comision.next(0);
    this.excedentes.no_aporte.next(0);
    this.excedentes.total_excedente.next(0);
    this.excedentes.iteracionCalculoAcumulado.next(1);

    this.valoresG.porcentajeRecuperacion.next(0);
    this.prospectoService.prospectoActual.subscribe(pros => {
      this.ismancomunado = pros.mancomunado.mancomunado;
      this.prospectu = pros;
    });
    let plazoTotal = Number(plazosTotales) - 1;
    // validacion por tipo de plazo
    switch (true) {
      case params.tipoPlan === 'TEMPORAL' &&
        Number(plazoTotal) === 60:
        plazoTotal = plazosTotales - this.planOptions.prospecto.age;
        break;

      case params.tipoPlan === 'TEMPORAL' &&
        Number(plazoTotal) === 65:
        plazoTotal = plazosTotales - this.planOptions.prospecto.age;
        break;

      case params.tipoPlan === 'TEMPORAL' &&
        Number(plazoTotal) === 70:
        plazoTotal = plazosTotales - this.planOptions.prospecto.age;
        break;

      case params.tipoPlan === 'DOTAL' &&
        Number(plazoPagoOpciones) === 65:
        plazoTotal =
          plazosTotales -
          (this.ismancomunado
            ? this.prospectu.mancomunado.edadEquivalente
            : this.planOptions.prospecto.age);
        break;
      case params.tipoPlan === 'OV' &&
        Number(plazoPagoOpciones) === 99:
        plazoTotal =
          Number(plazoPagoOpciones) -
          (this.ismancomunado
            ? this.prospectu.mancomunado.edadEquivalente
            : this.planOptions.prospecto.age);
        break;
      case (params.tipoPlan === 'DOTAL' ||
        params.tipoPlan === 'TEMPORAL') &&
        plazosTotales !== 65:
        plazoTotal = addOne ? Number(plazosTotales) : Number(plazosTotales);
        break;
    }
    for (let plazo = 1; plazo <= plazoTotal; plazo++) {
      const bolPlazoSeguro = plazo <= params.plazoSeguro ? true : false;
      const valor_UDI = this.excedentes.calculosExcedentes(plazo);
      this.valorUDI_Iterativo = valor_UDI;
      const edadIterativa = Number(edadInicial - 1) + Number(plazo);
      const valorRescate =
        plazo > plazosTotales
          ? 0
          : this.valoresG.generarValorDeRescatePropuestas(plazo, plazoPago);
      if (valorRescate === -1) {
        break;
      } else {
        const aportacionUDI = this.excedentes.total_excedente.value;
        porcentajeRec = this.valoresG.generarPorcentajeRecUDIPropuestas(
          valorRescate,
          plazo,
          edadIterativa,
          plazo,
          plazoPagoOpciones,
          valor_UDI,
          plazoPago
        );
        const primaElegidaUDI =
          plazo <= plazoPagoOpciones
            ? this.valoresG.primasCoberturasAdicionalesPropuestas(
              true,
              plazoPago,
              edadIterativa
            )
            : 0;
        let sumaAsegurada = this.valoresG.generarSumaAsegurada();
        let primaDeducibleUDI = this.valoresG.asignaCalculoDeducibleUDI();
        let primaDeduciblePesos = this.valoresG.asignaCalculoDeducibleUDI() * valor_UDI;
        let primAcumuladaUDI = this.valoresG.proyeccion.value;
        const aportacionMXN = this.excedentes.excedentes_pesos.value;
        if (primaDeduciblePesos > 152000) {
          primaDeduciblePesos = 152000;
          let pd;
          pd = primaDeduciblePesos / valor_UDI;
          primaDeducibleUDI = pd;
        }
        let apAdicional;
        if (iterar >= this.excedentes.primerAnioAportacion.value && iterar <= this.excedentes.segundaAportacion.value) {
          apAdicional = this.excedentes.aportacion_anual_UDI.value;
          iterar++;
        } else {
          apAdicional = 0;
          iterar++;
        }

        const aportacionProyectada = apAdicional * valor_UDI;
        const aportacionSumaFirst = this.excedentes.suma_aportacion_first.value;
        if (plazo === 1) {
          primaElegidaUDIConstante = primaElegidaUDI;
        }

        if (plazo <= this.excedentes.segundaAportacion.value) {
          primAcumuladaUDI = primAcumuladaUDI + aportacionSumaFirst;

          if (plazo === this.excedentes.segundaAportacion.value) {
            ultimaPrimaAcomuladaUDI = primAcumuladaUDI;
          }
        } else {
          if (this.excedentes.aportacion_anual_UDI.value !== 0) {
            if (params.tipoPlan === 'VPL') {
              if (plazo <= plazoPagoOpciones && !edadAlcanzada) {
                primAcumuladaUDI =
                  ultimaPrimaAcomuladaUDI +
                  primaElegidaUDIConstante *
                  (plazo - this.excedentes.segundaAportacion.value);

                if (
                  plazo === Number(plazoPagoOpciones) ||
                  (edadIterativa === (Number(plazoPagoOpciones) - 1) &&
                    (Number(plazoPagoOpciones) === 65 ||
                      Number(plazoPagoOpciones) === 60 ||
                      Number(plazoPagoOpciones) === 70))
                ) {
                  edadAlcanzada = true;
                  ultimaPrimaAcomuladaUDI = primAcumuladaUDI;
                }
              } else {
                primAcumuladaUDI = ultimaPrimaAcomuladaUDI;
              }
            } else {
              primAcumuladaUDI =
                primaElegidaUDIConstante *
                this.excedentes.segundaAportacion.value +
                aportacionSumaFirst +
                (this.excedentes.iteracionCalculoAcumulado.value - 1) *
                this.excedentes.aportacion_anual_UDI.value +
                primaElegidaUDIConstante *
                (plazo - this.excedentes.segundaAportacion.value);
            }
          }
        }

        sumaAsegurada += this.excedentes.total_excedente.value;

        switch (moneda) {
          case 'MXN':
            primaElegidaPesos = primaElegidaUDI;
            valorRescatePesos = valorRescate;
            sumaAseguradaPesos = sumaAsegurada;

            if (plazo >= this.excedentes.primerAportacion.value && plazo <= this.excedentes.segundaAportacion.value) {
              prima_AcumuladaMXN =
                primaElegidaPesos +
                this.excedentes.aportacion_anual_UDI.value +
                prima_AcumuladaMXN;

              if (plazo === this.excedentes.segundaAportacion.value) {
                ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
              }
            } else {
              if (plazo <= Number(plazoPagoOpciones) && !edadAlcanzadaMXN) {
                prima_AcumuladaMXN = primaElegidaPesos + prima_AcumuladaMXN;

                if (plazo === Number(plazoPagoOpciones) || (edadIterativa === (Number(plazoPagoOpciones) - 1) &&
                  (Number(plazoPagoOpciones) === 65 ||
                    Number(plazoPagoOpciones) === 60 ||
                    Number(plazoPagoOpciones) === 70))) {
                  edadAlcanzadaMXN = true;
                  ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
                }
              } else {
                prima_AcumuladaMXN = ultimaPrimaAcomuladaMXN;
              }
            }
            aportacion_MXN = aportacionUDI;
            valorEnEfectivo_UDI =
              bolPlazoSeguro
                ? aportacionUDI + valorRescate
                : valorRescate;
            valorEnEfectivo_Pesos =
              bolPlazoSeguro
                ? aportacionUDI + valorRescatePesos
                : valorRescatePesos;
            porcentajeRecuperacionPesosBaCoExcd =
              bolPlazoSeguro
                ? valorEnEfectivo_Pesos / prima_AcumuladaMXN
                : 0;
            porcentajeRecuperacionUdiBaCoExcd =
              bolPlazoSeguro
                ? valorEnEfectivo_Pesos / prima_AcumuladaMXN
                : 0;
            break;
          case 'UDI':
            primaElegidaPesos = primaElegidaUDI * valor_UDI;
            valorRescatePesos = valorRescate * valor_UDI;
            sumaAseguradaPesos = sumaAsegurada * valor_UDI;
            aportacion_MXN = aportacionUDI * valor_UDI;

            if (plazo >= this.excedentes.primerAportacion.value && plazo <= this.excedentes.segundaAportacion.value) {
              prima_AcumuladaMXN =
                primaElegidaPesos +
                this.excedentes.aportacion_anual_UDI.value * valor_UDI +
                prima_AcumuladaMXN;

              if (plazo === this.excedentes.segundaAportacion.value) {
                ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
              }
            } else {
              if (plazo <= Number(plazoPagoOpciones) && !edadAlcanzadaMXN) {
                prima_AcumuladaMXN = primaElegidaPesos + prima_AcumuladaMXN;

                if (plazo === Number(plazoPagoOpciones) || (edadIterativa === (Number(plazoPagoOpciones) - 1) &&
                  (Number(plazoPagoOpciones) === 65 ||
                    Number(plazoPagoOpciones) === 60 ||
                    Number(plazoPagoOpciones) === 70))) {
                  edadAlcanzadaMXN = true;
                  ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
                }
              } else {
                prima_AcumuladaMXN = ultimaPrimaAcomuladaMXN;
              }
            }

            valorEnEfectivo_UDI =
              bolPlazoSeguro
                ? aportacionUDI + valorRescate
                : valorRescate;
            valorEnEfectivo_Pesos =
              bolPlazoSeguro
                ? aportacionMXN + valorRescatePesos
                : valorRescatePesos;
            porcentajeRecuperacionUdiBaCoExcd =
              bolPlazoSeguro
                ? valorEnEfectivo_UDI / primAcumuladaUDI : 0;
            porcentajeRecuperacionPesosBaCoExcd =
              bolPlazoSeguro
                ? valorEnEfectivo_Pesos / prima_AcumuladaMXN : 0;
            break;
          case 'USD':
            primaElegidaPesos = primaElegidaUDI;
            valorRescatePesos = valorRescate;
            sumaAseguradaPesos = sumaAsegurada;

            if (plazo >= this.excedentes.primerAportacion.value && plazo <= this.excedentes.segundaAportacion.value) {
              prima_AcumuladaMXN =
                primaElegidaPesos +
                this.excedentes.aportacion_anual_UDI.value +
                prima_AcumuladaMXN;

              if (plazo === this.excedentes.segundaAportacion.value) {
                ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
              }
            } else {
              if (plazo <= Number(plazoPagoOpciones) && !edadAlcanzadaMXN) {
                prima_AcumuladaMXN = primaElegidaPesos + prima_AcumuladaMXN;

                if (plazo === Number(plazoPagoOpciones) || (edadIterativa === (Number(plazoPagoOpciones) - 1) &&
                  (Number(plazoPagoOpciones) === 65 ||
                    Number(plazoPagoOpciones) === 60 ||
                    Number(plazoPagoOpciones) === 70))) {
                  edadAlcanzadaMXN = true;
                  ultimaPrimaAcomuladaMXN = prima_AcumuladaMXN;
                }
              } else {
                prima_AcumuladaMXN = ultimaPrimaAcomuladaMXN;
              }
            }
            aportacion_MXN = aportacionUDI;
            valorEnEfectivo_UDI =
              bolPlazoSeguro
                ? aportacionUDI + valorRescate
                : valorRescate;
            valorEnEfectivo_Pesos =
              bolPlazoSeguro
                ? aportacionUDI + valorRescatePesos
                : valorRescatePesos;
            porcentajeRecuperacionPesosBaCoExcd =
              bolPlazoSeguro
                ? valorEnEfectivo_UDI / primAcumuladaUDI : 0;
            porcentajeRecuperacionUdiBaCoExcd =
              bolPlazoSeguro
                ? valorEnEfectivo_UDI / primAcumuladaUDI : 0;
            break;
        }
        porcentajeRecuperacionUdiBaCoExcd = porcentajeRecuperacionUdiBaCoExcd * 100;
        porcentajeRecuperacionPesosBaCoExcd = porcentajeRecuperacionPesosBaCoExcd * 100;
        primaEleUdiEdad = Number(primaElegidaUDI.toFixed(0));
        let porcentajeRecuperacion_MXN = 0;
        porcentajeRecuperacion_MXN =
          (valorRescatePesos / this.valoresG.primaAcumulada_MXN.value) * 100;
        primaElegidaPesosEdad = Number(primaElegidaPesos.toFixed(2));
        switch (Number(plazoPagoOpciones)) {
          case 60:
            primaEleUdiEdad =
              edadIterativa < 60 ? Number(primaElegidaUDI.toFixed(0)) : 0;
            primaElegidaPesosEdad =
              edadIterativa < 60 ? Number(primaElegidaPesos.toFixed(0)) : 0;
            break;

          case 65:
            primaEleUdiEdad =
              edadIterativa < 65 ? Number(primaElegidaUDI.toFixed(0)) : 0;
            primaElegidaPesosEdad =
              edadIterativa < 65 ? Number(primaElegidaPesos.toFixed(0)) : 0;
            break;

          case 70:
            primaEleUdiEdad =
              edadIterativa < 70 ? Number(primaElegidaUDI.toFixed(0)) : 0;
            primaElegidaPesosEdad =
              edadIterativa < 70 ? Number(primaElegidaPesos.toFixed(0)) : 0;
            break;
        }

        datos = [
          ...datos,
          {
            edad: edadIterativa,
            anioPoliza: plazo,
            primaElegida: primaEleUdiEdad,
            aportacionAdicional_UDI:
              bolPlazoSeguro
                ? Number(aportacionUDI.toFixed(0))
                : 0,
            prima_AcumuladaUDI: Number(primAcumuladaUDI.toFixed(0)),
            primaDeducibleUDI: Number(primaDeducibleUDI.toFixed(2)),
            valorRescate: Number(valorRescate.toFixed(2)),
            porcentajeRecuperacionUdi: Number(porcentajeRec.toFixed(2)),
            sumaAsegurada: Number(sumaAsegurada.toFixed(0)),
            valorUDI: Number(valor_UDI),
            primaElegida_Pesos: primaElegidaPesosEdad,
            aportacionAdicional_MXN:
              bolPlazoSeguro
                ? Number(aportacion_MXN.toFixed(0))
                : 0,
            prima_AcumuladaMXN: Number(prima_AcumuladaMXN.toFixed(0)),
            primaDeducibleMXN: Number(primaDeduciblePesos.toFixed(2)),
            valorRescate_Pesos: Number(valorRescatePesos.toFixed(2)),
            porcentajeRecuperacionPesos: Number(
              porcentajeRecuperacion_MXN.toFixed(2)
            ),
            sumaAsegurada_Pesos: Number(sumaAseguradaPesos.toFixed(2)),
            valorEnEfectivo_UDI: Number(valorEnEfectivo_UDI.toFixed(2)),
            valorEnEfectivo_Pesos: Number(valorEnEfectivo_Pesos.toFixed(2)),
            aportacionAdicional: Number(apAdicional),
            aportacionAdicionalProyectada: Number(aportacionProyectada.toFixed(2)),
            porcentajeRecuperacionUdiBaCoExcd: Number(porcentajeRecuperacionUdiBaCoExcd.toFixed(2)),
            porcentajeRecuperacionPesosBaCoExcd: Number(porcentajeRecuperacionPesosBaCoExcd.toFixed(2)),
            porcentajeRecuperacionUdiBaCo: 0,
            porcentajeRecuperacionPesosBaCo: 0
          }
        ];
      }
    }

    let parsedDatos: ValorGarantizado[] = [];
    for (let i = 0; i < datos.length; i++) {
      if (i === 0) {
        parsedDatos = [
          ...parsedDatos,
          {
            edad: datos[i].edad,
            anioPoliza: datos[i].anioPoliza,
            primaElegida: datos[i].primaElegida,
            aportacionAdicional_UDI: datos[i].aportacionAdicional_UDI,
            prima_AcumuladaUDI: datos[i].prima_AcumuladaUDI,
            primaDeducibleUDI: datos[i].primaDeducibleUDI,
            valorRescate: datos[i].valorRescate,
            porcentajeRecuperacionUdi: datos[i].porcentajeRecuperacionUdi,
            sumaAsegurada: datos[i].sumaAsegurada,
            valorUDI: datos[i].valorUDI,
            primaElegida_Pesos: datos[i].primaElegida_Pesos,
            aportacionAdicional_MXN: datos[i].aportacionAdicional_MXN,
            prima_AcumuladaMXN: datos[i].prima_AcumuladaMXN,
            primaDeducibleMXN: datos[i].primaDeducibleMXN,
            valorRescate_Pesos: datos[i].valorRescate_Pesos,
            porcentajeRecuperacionPesos: datos[i].porcentajeRecuperacionPesos,
            sumaAsegurada_Pesos: datos[i].sumaAsegurada_Pesos,
            valorEnEfectivo_UDI: datos[i].valorEnEfectivo_UDI,
            valorEnEfectivo_Pesos: datos[i].valorEnEfectivo_Pesos,
            aportacionAdicional: datos[i].aportacionAdicional,
            aportacionAdicionalProyectada: datos[i].aportacionAdicionalProyectada,
            porcentajeRecuperacionUdiBaCoExcd: datos[i].porcentajeRecuperacionUdiBaCoExcd,
            porcentajeRecuperacionPesosBaCoExcd: datos[i].porcentajeRecuperacionPesosBaCoExcd,
            porcentajeRecuperacionUdiBaCo: 0,
            porcentajeRecuperacionPesosBaCo: 0
          }
        ];
      } else if (i === Number(plazoPago)) {
        parsedDatos = [
          ...parsedDatos,
          {
            edad: datos[i].edad,
            anioPoliza: datos[i].anioPoliza,
            primaElegida: 0,
            aportacionAdicional_UDI: datos[i].aportacionAdicional_UDI,
            prima_AcumuladaUDI: datos[i].prima_AcumuladaUDI,
            primaDeducibleUDI: datos[i].primaDeducibleUDI,
            valorRescate: datos[i].valorRescate,
            porcentajeRecuperacionUdi: datos[i].porcentajeRecuperacionUdi,
            sumaAsegurada: datos[i].sumaAsegurada,
            valorUDI: datos[i].valorUDI,
            primaElegida_Pesos: datos[i].primaElegida_Pesos,
            aportacionAdicional_MXN: datos[i].aportacionAdicional_MXN,
            prima_AcumuladaMXN: datos[i].prima_AcumuladaMXN,
            primaDeducibleMXN: datos[i].primaDeducibleMXN,
            valorRescate_Pesos: datos[i].valorRescate_Pesos,
            porcentajeRecuperacionPesos: datos[i].porcentajeRecuperacionPesos,
            sumaAsegurada_Pesos: datos[i].sumaAsegurada_Pesos,
            valorEnEfectivo_UDI: datos[i].valorEnEfectivo_UDI,
            valorEnEfectivo_Pesos: datos[i].valorEnEfectivo_Pesos,
            aportacionAdicional: datos[i].aportacionAdicional,
            aportacionAdicionalProyectada: datos[i].aportacionAdicionalProyectada,
            porcentajeRecuperacionUdiBaCoExcd: datos[i].porcentajeRecuperacionUdiBaCoExcd,
            porcentajeRecuperacionPesosBaCoExcd: datos[i].porcentajeRecuperacionPesosBaCoExcd,
            porcentajeRecuperacionUdiBaCo: 0,
            porcentajeRecuperacionPesosBaCo: 0
          }
        ];
      } else {
        parsedDatos = [
          ...parsedDatos,
          {
            edad: datos[i].edad,
            anioPoliza: datos[i].anioPoliza,
            primaElegida: datos[i - 1].primaElegida,
            aportacionAdicional_UDI: datos[i].aportacionAdicional_UDI,
            prima_AcumuladaUDI: datos[i].prima_AcumuladaUDI,
            primaDeducibleUDI: datos[i].primaDeducibleUDI,
            valorRescate: datos[i].valorRescate,
            porcentajeRecuperacionUdi: datos[i].porcentajeRecuperacionUdi,
            sumaAsegurada: datos[i].sumaAsegurada,
            valorUDI: datos[i].valorUDI,
            primaElegida_Pesos: datos[i].primaElegida_Pesos,
            aportacionAdicional_MXN: datos[i].aportacionAdicional_MXN,
            prima_AcumuladaMXN: datos[i].prima_AcumuladaMXN,
            primaDeducibleMXN: datos[i].primaDeducibleMXN,
            valorRescate_Pesos: datos[i].valorRescate_Pesos,
            porcentajeRecuperacionPesos: datos[i].porcentajeRecuperacionPesos,
            sumaAsegurada_Pesos: datos[i].sumaAsegurada_Pesos,
            valorEnEfectivo_UDI: datos[i].valorEnEfectivo_UDI,
            valorEnEfectivo_Pesos: datos[i].valorEnEfectivo_Pesos,
            aportacionAdicional: datos[i].aportacionAdicional,
            aportacionAdicionalProyectada: datos[i].aportacionAdicionalProyectada,
            porcentajeRecuperacionUdiBaCoExcd: datos[i].porcentajeRecuperacionUdiBaCoExcd,
            porcentajeRecuperacionPesosBaCoExcd: datos[i].porcentajeRecuperacionPesosBaCoExcd,
            porcentajeRecuperacionUdiBaCo: 0,
            porcentajeRecuperacionPesosBaCo: 0
          }
        ];
      }
    }
    return datos;
  }

}
