import { UserType } from '../../constants';
/**
 * USUARIO
 * @description Interfaz de Usuario que hace uso de la aplicación
 * @param { UserType } type Tipo de usuario
 * @param { string } name Nombre del usuario
 * @param { string } code Código del usuario
 */

export class User {
  /**Nombre de Usuario*/
  name: string;
  /**Código de Usuario*/
  code: string;
  /**Tipo de Usuario*/
  type: UserType;
  /**Correo del usuario */
  email: string;

  roles: string[];

  rawRoles: string;

  /**
   * Se crea una instancia de User. Inicialización de variables.
   * @param name
   * @param code
   * @param type
   */
  constructor(
    name: string = '',
    code: string = '',
    email: string = '',
    roles: string[] = [],
    rawRoles: string = '',
    type: UserType
  ) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.email = email;
    this.roles = roles;
    this.rawRoles = rawRoles;
  }

}
