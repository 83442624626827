import { Injectable } from '@angular/core';
import { FRECUENCIA_PAGO } from './../../constants/universales/universales.constant';
import { BehaviorSubject } from 'rxjs';
import { FrecuenciaPago } from '@app/models/universales/frecuencia-pago';

@Injectable({
  providedIn: 'root'
})
export class FrecuenciaPagoService {

frecuenciaPagoUniv = new BehaviorSubject<FrecuenciaPago[]>([]);

constructor() { }

obtenerFrecuencias(primaTotal: number, excedente: number) {
  const primaAnual = excedente + primaTotal;
  const frecPago = FRECUENCIA_PAGO;
  for (let i = 0; i < frecPago.length; i++) {
    frecPago[i].primaAnual = primaAnual;
    frecPago[i].primaFormaPago = primaAnual / FRECUENCIA_PAGO[i].frecuencia;
  }
  this.frecuenciaPagoUniv.next(frecPago);
}

}
