import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FactoresService } from '@app/services/factores/factores.service';
import { Coverage } from '@app/models';
import { OpcionesPlanService } from '@app/services/opcionesPlan/opciones-plan.service';

/** Constants */
import { BA } from '@constants/coverages';
import { RecursosExternosService } from '@app/services/recursosExternos/recursos-externos.service';

@Injectable({
  providedIn: 'root'
})
export class VPLService {
  sumaAsegurada_PrimeraMoneda = new BehaviorSubject<number>(0);
  primaAnual_PrimeraMoneda = new BehaviorSubject<number>(0);

  constructor(
    private factores: FactoresService,
    private opciones: OpcionesPlanService,
    private recursos: RecursosExternosService
  ) {}

  establecePrimaAnual(sumaAsegurada: number): void {
    if (sumaAsegurada === -1) {
      this.resetValues();
      return;
    }

    let suma;
    switch (this.opciones.moneda) {
      case 'UDI':
        suma = BA.MIN_SUM_UDI;
        break;
      case 'MXN':
        suma = BA.MIN_SUM_MXN;
        break;
      case 'USD':
        suma = BA.MIN_SUM_USD;
        break;
    }

    if (sumaAsegurada >= suma && isFinite(sumaAsegurada)) {
      suma = sumaAsegurada;
    }

    if (
          sumaAsegurada <= this.recursos.GetMinMax(this.opciones.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN')
        ) {
          suma = this.recursos.GetMinMax(this.opciones.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN');
        } else if (sumaAsegurada <= this.recursos.GetMinMax(this.opciones.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN')) {
          suma = this.recursos.GetMinMax(this.opciones.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MIN');
        } else if (sumaAsegurada >= this.recursos.GetMinMax(this.opciones.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MAX')) {
          suma = this.recursos.GetMinMax(this.opciones.tipoPlan === 'MUJER UNICA' ? 'MU' : 'Ba', 'MAX');
        }

    this.sumaAsegurada_PrimeraMoneda.next(suma);

    const prima = this.calculoPrimaVPL(suma, 'Elegido');

    this.primaAnual_PrimeraMoneda.next(prima);
  }

  calculoPrimaVPL(sumaAsegurada, plazo) {
    // Variables necesarias para el calculo
    const factorCDC = this.factores.obtenerFactorCobertura('VPL' as Coverage, plazo);
    const desc = this.opciones.obtenerDescuento(sumaAsegurada);
    // Ejecuta la formula dada
    const primaCDC = factorCDC !== 0 ? (sumaAsegurada * factorCDC * desc) / 1000 : 0;

    // Retorna el resultado de la operación
    return primaCDC;
  }

  resetValues(): void {
    this.primaAnual_PrimeraMoneda.next(0);
    this.sumaAsegurada_PrimeraMoneda.next(0);
  }

}
