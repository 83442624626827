  /** CORE */
import { Component, OnInit } from '@angular/core';
/** ROUTE */
import { Router } from '@angular/router';
/** SEVICES */
import { BaseDatosService } from '@services/baseDatos/base-datos.service';
import { DeviceOSService } from '@services/device-os/device-os.service';
import { DeviceTypeService } from './../../services/deviceType/deviceType.service';
import { ResizeDetectionService } from '@services/resizeDetection/resize-detection.service';
import { ScrollPercentageDetectionService } from './../../services/scrollPercentageDetection/scroll-percentage-detection.service';
/** RXJS */
import { BehaviorSubject, Observable, merge, of, fromEvent } from 'rxjs';
/** MODELS */
import { ReplicaionPlansModel, ReplicationMobileType, MobileDatabases } from '@models/replication/replication.model';
import { ReplicationMobile } from '../../models/replication/replication.model';
import { mapTo } from 'rxjs/operators';

// import { ProtectionPlansGeneratorService } from '@app/services/protectionPlansGenerator/protection-plans-generator.service';
// import { MatSlideToggle } from '@angular/material';
import { MatSlideToggle } from '@angular/material';

/**
 * COMPONENTE PARA DESCARGAR PLANES
 * @export
 * @class DownloadPlansComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'cvida-download-plans',
  templateUrl: './download-plans.component.html',
  styleUrls: ['./download-plans.component.sass']
})
export class DownloadPlansComponent implements OnInit {
  // Variables para obtener la accion del collapse.
  /**
   * VPL Collapse - Estado.
   * @property
   * @type {boolean}
   */
  isCollapsedVPL = true;
  isCollapsedTEMP = true;
  isCollapsedOV = true;
  /**
   *  Aliados+ Collapse - Estado.
   * @property
   * @type {boolean}
   */
  isCollapsedAliPLus = true;
  /**
   * Protección Efectiva Collapse - Estado.
   * @property
   * @type {boolean}
   * @memberof DownloadPlansComponent
   */
  isCollapsedPR_EF = true;
  /**
   * Protecáncer Collapse - Estado.
   * @property
   * @type {boolean}
   * @memberof DownloadPlansComponent
   */
  isCollapsedPR_CA = true;
  /**
   * Variable de estado.
   * @property
   */
  isActive: boolean;
  /**
   * Variable de item removido
   * @property
   * @type {boolean}
   */
  isRemoveItem: boolean;
  /**
   * Item que contiene cada una de las bases de datos
   * @property
   * @type {MobileDatabases}
   */
  mobileDatabasesStorage: MobileDatabases;

  // Variables para mandar el arreglo de objetos al servicio de base de datos.
  /**
   * Arreglo de objetos
   * @property
   * @type {ReplicaionPlansModel[]}
   * @default "[ ]"
   */
  replicationPlanArray: ReplicaionPlansModel[] = [];
  /**
   * Plan por replicar.
   * @property
   * @type {ReplicaionPlansModel}
   */
  replicationPlan: ReplicaionPlansModel;
  // Variables para detectar la conexión online/offline.
  /**
   * Título
   * @property
   * @default "test-ios"
   */
  title = 'test-ios';
  /**
   * Online - Estado.
   * @property
   * @type {Observable<boolean>}
   */
  online$: Observable<boolean>;

  // Variables para detectar el OS que utiliza.
  /**
  * iOS - Variable de estado.
  * @property
  * @type {boolean}
  */
  iOS: boolean;
  /**
  * Sistema (Título).
  * @property
  * @type {string}
  */
  sistema: string;
  // Variables para detectar si se esta utilizando en un dispositivo.
  /**
   *  Nombre del dispositivo.
   * @property
   * @type {string}
   */
  dev: string;
  /**
   *  Dispositivo:
   * True --> Se accedió desde un dispositivo (móvil).
   * False --> No se accedió desde un dispositivo.
   * @property
   * @type {boolean}
   */
  dispositivo: boolean;
  /**
   * Orientación de la pantalla
   * @property
   * @type {string}
   */
  orient: string;

  // Variables para detectar el comportamiento del slide-toggle.
  /**
   * Variable de estado.
   * @property
   * @type {boolean}
   */
  disabled: boolean;

  // Variable que cambia el contenido del badge de selección.
  /**
   * Contenido.
   * @property
   * @type {number}
   */
  content: number;
  /**
   * Ítems Restantes.
   * @property
   */
  itemsRemain: number;
  /**
   * Total de items VPL descargados
   * @property
   * @default 0
   */
  totalItems_VPL = 0;
  totalItems_TEMP = 0;
  totalItems_OV = 0;
  /**
   * Total de items DOTAL descargados
   * @property
   * @default 0
   */
  totalItems_DOTAL = 0;
  totalItems_PR_EF = 0;
  totalItems_PR_CA = 0;

  // Plazos de pago a descargar.
  /**
   * Cola de Descarga. Se crea una instancia reactiva.
   * @property
   * @type {BehaviorSubject<ReplicationMobile[]>}
   * @default "[ ]"
   */
  downloadQueue = new BehaviorSubject<ReplicationMobile[]>([]);

  /**
   *Porcentaje Total.
   *@property
   *@type {BehaviorSubject<number>}
   *@default "0"
   */
  totalPercentaje = new BehaviorSubject<number>(0);

  /**
   * Vida Pagos Limitados, plazo: 1 año
   * @property
   * @type {boolean}
   */
  VPL_1: boolean;
  /**
   * Vida Pagos Limitados, plazo: 5 años
   * @property
   * @type {boolean}
   */
  VPL_5: boolean;
  /**
   * Vida Pagos Limitados, plazo: 10 años
   * @property
   * @type {boolean}
   */
  VPL_10: boolean;
  /**
   * Vida Pagos Limitados, plazo: 15 años
   * @property
   * @type {boolean}
   */
  VPL_15: boolean;
  /**
   * Vida Pagos Limitados, plazo: 20 años
   * @property
   * @type {boolean}
   */
  VPL_20: boolean;
  /**
   * Vida Pagos Limitados, plazo: 25 años
   * @property
   * @type {boolean}
   */
  VPL_25: boolean;
  /**
   * Vida Pagos Limitados, plazo: 60 años alcanzados
   * @property
   * @type {boolean}
   */
  VPL_60: boolean;
  /**
   * Vida Pagos Limitados, plazo: 65 años alcanzados
   * @property
   * @type {boolean}
   */
  VPL_65: boolean;
  /**
   * Vida Pagos Limitados, plazo: 70 años alcanzados
   * @property
   * @type {boolean}
   */
  VPL_70: boolean;

  // Temporales
  TEMP_1: boolean;
  TEMP_5: boolean;
  TEMP_10: boolean;
  TEMP_15: boolean;
  TEMP_20: boolean;
  TEMP_25: boolean;
  TEMP_60: boolean;
  TEMP_65: boolean;
  TEMP_70: boolean;

  // OV
  OV: boolean;

  // Aliados+
  /**
   * Aliados+, plazo: 15 años.
   * @property
   * @type {boolean}
   */
  DOTAL_15: boolean;
  /**
   * Aliados+, plazo: 20 años
   * @property
   * @type {boolean}
   */
  DOTAL_20: boolean;
  /**
   * Aliados+, plazo: 65 años alcanzados.
   * @property
   * @type {boolean}
   */
  DOTAL_65: boolean;
  /**
   * Edades equivalentes
   * @property
   * @type {boolean}
   */
  EE: boolean;
  /**
   * Protección Efectiva
   * @property
   * @type {boolean}
   * @memberof DownloadPlansComponent
   */
  PR_EF: boolean;
  /**
   * Protecáncer
   * @property
   * @type {boolean}
   * @memberof DownloadPlansComponent
   */
  PR_CA: boolean;
  /**
   * Referencia a base datos
   * @property
   * @type {MobileDatabases}
   */
  mdb: MobileDatabases;
  /**
   * Porcentaje de descarga de base EE
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_EE = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_1
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_1 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_5
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_5 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_10
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_10 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_15
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_15 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_20
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_20 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_25
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_25 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_60
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_60 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_65
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_65 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base VPL_70
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_VPL_70 = new BehaviorSubject<number>(0);

  // Temporal
  percentaje_TEMP_1 = new BehaviorSubject<number>(0);
  percentaje_TEMP_5 = new BehaviorSubject<number>(0);
  percentaje_TEMP_10 = new BehaviorSubject<number>(0);
  percentaje_TEMP_15 = new BehaviorSubject<number>(0);
  percentaje_TEMP_20 = new BehaviorSubject<number>(0);
  percentaje_TEMP_25 = new BehaviorSubject<number>(0);
  percentaje_TEMP_60 = new BehaviorSubject<number>(0);
  percentaje_TEMP_65 = new BehaviorSubject<number>(0);
  percentaje_TEMP_70 = new BehaviorSubject<number>(0);

  // OV
  percentaje_OV = new BehaviorSubject<number>(0);

  // Aliados
  /**
   * Porcentaje de descarga de base DOTAL_15
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_DOTAL_15 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base DOTAL_20
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_DOTAL_20 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga de base DOTAL_65
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  percentaje_DOTAL_65 = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga en base PR_EF
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   * @memberof DownloadPlansComponent
   */
  percentaje_PR_EF = new BehaviorSubject<number>(0);
  /**
   * Porcentaje de descarga en base PR_CA
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   * @memberof DownloadPlansComponent
   */
  percentaje_PR_CA = new BehaviorSubject<number>(0);
  /**
   * Contadores de replicacion
   * @property
   * @type {BehaviourSubject<number>}
   * @default 0
   */
  lengthToReplicate = 0;
  /**
   * Longitud de la cola de descarga
   * @property
   */
  lengthThis = 0;
  /**
   * Lengitud de las bases a replicar
   * @property
   */
  lenght2 = 0;

  /**
   * Variable para oculatar los plazos de VPL 1,25,70
  */
  notShow = false;

  /**
   * Constructor
   * @constructor
   * @param device
   * @param system
   * @param router
   * @param resizeDetection Importa el servicio de detección de Redimensionamiento.
   * @param scrollPercent Crea una instancia de ScrollPercentageDetectionService
   * @param db
   */
  constructor(
    private device: DeviceTypeService,
    private system: DeviceOSService,
    private router: Router,
    public resizeDetection: ResizeDetectionService,
    public scrollPercent: ScrollPercentageDetectionService,
    private db: BaseDatosService,
    ) {

      this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(true)),
      );

      this.online$.subscribe(result => {

          this.percentaje_VPL_5 = this.db.percentaje_VPL_5;
          this.percentaje_VPL_10 = this.db.percentaje_VPL_10;
          this.percentaje_VPL_15 = this.db.percentaje_VPL_15;
          this.percentaje_VPL_20 = this.db.percentaje_VPL_20;
          // this.percentaje_VPL_25 = this.db.percentaje_VPL_25;
          this.percentaje_VPL_60 = this.db.percentaje_VPL_60;
          this.percentaje_VPL_65 = this.db.percentaje_VPL_65;
          // this.percentaje_VPL_70 = this.db.percentaje_VPL_70;
          this.percentaje_TEMP_1 = this.db.percentaje_TEMP_1;
          this.percentaje_TEMP_5 = this.db.percentaje_TEMP_5;
          this.percentaje_TEMP_10 = this.db.percentaje_TEMP_10;
          this.percentaje_TEMP_15 = this.db.percentaje_TEMP_15;
          this.percentaje_TEMP_20 = this.db.percentaje_TEMP_20;
          this.percentaje_TEMP_25 = this.db.percentaje_TEMP_25;
          this.percentaje_TEMP_60 = this.db.percentaje_TEMP_60;
          this.percentaje_TEMP_65 = this.db.percentaje_TEMP_65;
          this.percentaje_TEMP_70 = this.db.percentaje_TEMP_70;
          this.percentaje_OV = this.db.percentaje_OV;
          this.percentaje_DOTAL_15 = this.db.percentaje_DOTAL_15;
          this.percentaje_DOTAL_20 = this.db.percentaje_DOTAL_20;
          this.percentaje_DOTAL_65 = this.db.percentaje_DOTAL_65;
          this.percentaje_PR_EF = this.db.percentaje_PR_EF;
          this.percentaje_PR_CA = this.db.percentaje_PR_CA;
          
          this.isActive = result;
      });

      this.totalPercentaje = this.db.totalDataPercentage;
      this.downloadQueue.next(this.db.replicatedHistory);
    }

  /**
   * MÉTODO PRINCIPAL DEL COMPONENTE
   * @memberof DownloadPlansComponent
   */
  ngOnInit() {
    this.content = 0;
    this.disabled = false;
    this.system.getSystemType();
    this.OS();
    this.devtype();

    if (localStorage.getItem('deleteItem') === 'false') {
      this.isRemoveItem = localStorage.getItem('deleteItem') === 'true' ? false : true;
    }
    this.lengthToReplicate = this.db.databasesAllowed !== undefined ? this.db.databasesAllowed.length : -1;

    this.lengthThis = this.downloadQueue.value !== undefined
    ? this.downloadQueue.value.filter(item => item.status === 'on' && ( (/DOTAL/).test(item.plan) || (/VPL/).test(item.plan)) ).length : 0;

    this.lenght2 = this.db.databasesToReplicate.value.filter(item => ( (/DOTAL/).test(item.plan) || (/VPL/).test(item.plan))).length / 2;

    this.totalItems_VPL = this.db.databasesToReplicate.value.filter(item => ( (/VPL/).test(item.plan))).length / 2;
    this.totalItems_TEMP = this.db.databasesToReplicate.value.filter(item => ((/TEMP/).test(item.plan))).length / 2;
    this.totalItems_OV = this.db.databasesToReplicate.value.filter(item => ((/OV/).test(item.plan))).length / 2;
    this.totalItems_DOTAL = this.db.databasesToReplicate.value.filter(item => ( (/DOTAL/).test(item.plan))).length / 2;
    this.totalItems_PR_EF = this.db.databasesToReplicate.value.filter(item => ((/PR_EF/).test(item.plan))).length;
    this.totalItems_PR_CA = this.db.databasesToReplicate.value.filter(item => ((/PR_CA/).test(item.plan))).length;

    this.isRemoveItem = localStorage.getItem('isActive') === 'false' ? false : true;

  }

  /**
   * MÉTODO PARA DETECTAR SI SE ESTA EJECUTANDO EN UN DISPOSITIVO
   * @returns
   * @memberof DownloadPlansComponent
   */
  devtype() {
    if (this.device.getDeviceType() === 'iPad') {
      this.dev = 'iPad';
      this.orient = this.device.getOrientation(window.innerHeight, window.innerWidth);
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'iPhone') {
      this.dev = 'iPhone';
      this.orient = this.device.getOrientation(window.innerHeight, window.innerWidth);
      return this.dispositivo = true;
    } else if (this.device.getDeviceType() === 'Android') {
      this.dev = 'Android';
      this.orient = this.device.getOrientation(window.innerHeight, window.innerWidth);
      return this.dispositivo = true;
    } else {
      this.dev = 'Desktop';
      this.orient = this.device.getOrientation(window.innerHeight, window.innerWidth);
      return this.dispositivo = false;
    }
  }

  /**
   * MÉTODO PARA DETECTAR EL OS EN EL QUE SE EJECUTA
   * @returns
   * @memberof DownloadPlansComponent
   */
  OS() {
    if (this.system.getSysType() === 'android') {
      return this.sistema = 'android';
    } else if (this.system.getSysType() === 'ios') {
      return this.sistema = 'ios';
    } else if (this.system.getSysType() === 'linux') {
      return this.sistema = 'linux';
    } else {
      return this.sistema = 'desktop';
    }
  }

  /**
   * Devuelve si todos los planes estan activos para descargar
   *
   * @returns {boolean}
   * @memberof DownloadPlansComponent
   */
  areAllActivated(): boolean {
    return (
      // this.EE &&
      // this.VPL_1 &&
      this.VPL_5 &&
      this.VPL_10 &&
      this.VPL_15 &&
      this.VPL_20 &&
      // this.VPL_25 &&
      this.VPL_60 &&
      this.VPL_65 &&
      // this.VPL_70 &&
      this.OV &&
      this.DOTAL_15 &&
      this.DOTAL_20 &&
      this.DOTAL_65 &&
      this.PR_EF &&
      this.PR_CA
    );
  }

  /**
   * Guarda la configuración e inicia la replicación.
   * Siguiente a esto, redirige a la pantalla principal de
   * cotizador.
   *
   * @memberof DownloadPlansComponent
   */
  Acept() {
      if (/(iPod|iPhone|iPad)/.test(navigator.userAgent)) {
        localStorage.setItem('deleteItem', 'true');
        localStorage.setItem('isFirst', 'no');
        // KL('----------------', this.downloadQueue.value);
        this.isRemoveItem = false;
      }
    // Redirige a la pantalla principal de cotizador.
    this.router.navigate(['/cotizador', 'landing']);
  }

  /**
   * Método usado al seleccionar un plazo a descargar,
   * modifica el estado del arreglo de cola de descargas, activando
   * el plazo seleccionado
   *
   * @param {boolean} state - Estado del plazo
   * @param {(PlanType | 'EE_0' | 'ALL')} plan - Tipo de plan
   * @param {number} term - Plazo de pago
   * @memberof DownloadPlansComponent
   */
  selectToDownload(state: boolean, plan: ReplicationMobileType | 'ALL', ...toggles: MatSlideToggle[]) {

    // -1 si no existe el plazo en el arreglo ó si existe retorna el indice posicional.
    const indexToSearch =
      this.downloadQueue.value !== undefined
      ? this.downloadQueue.value.findIndex(result => result.plan === plan)
      : -1;

    // Si es un plazo de descarga o edades equivalentes
    if (plan !== 'ALL') {
      // Si ya existe el plazo en la cola de descargas
      if (indexToSearch !== -1) {
        // Estado del plan
        const planStatus = state ? <'on'>'on' : <'off'>'off';
        // Elimina el plazo con el estado anterior
        const arrayModified = this.downloadQueue.value.map((item) => {
          // Retorna el plan con el estado modificado
          return item.plan === plan
            ? { status: item.status = planStatus, plan: item.plan}
            : item;
        });
        // Guarda el arreglo resultante
        this.downloadQueue.next(arrayModified);

        // --- yo
        let saveObj: ReplicationMobile;

        const arrayWithoutItem = this.downloadQueue.value.filter((item, index, array) => {
          if (/(iPod|iPhone|iPad)/.test(navigator.userAgent)) {
            if (array[index].plan === plan) {
              saveObj = array[index];
            }
          }
          return (array[index].plan !== plan);
        });
        const newItem: ReplicationMobile = {status: state ? 'on' : 'off', plan};
        this.downloadQueue.next([...arrayWithoutItem, newItem]);

        if (/(iPod|iPhone|iPad)/.test(navigator.userAgent)) {

            if (saveObj.plan && newItem.plan && saveObj.status === 'on' && newItem.status === 'off') {
              // KL(saveObj.plan, '  es eliminado');
              // localStorage.setItem('isActive', 'false');
              const noReplicado = this.getPlaStorage(newItem);
              localStorage.setItem('isActive', 'false');

              // this.isRemoveItem = noReplicado === true ? true : false;

              this.isRemoveItem =
                this.totalItems_DOTAL +
                  this.totalItems_OV +
                  this.totalItems_VPL +
                  this.totalItems_TEMP +
                  this.totalItems_PR_CA +
                  this.totalItems_PR_EF >=
                3
                  ? false
                  : true;

              //  this.isRemoveItem = this.totalItems_DOTAL + this.totalItems_VPL < 3 ? true : false;

              if (localStorage.getItem('isFirst') === 'no') {
                if (
                  this.totalItems_DOTAL +
                    this.totalItems_OV +
                    this.totalItems_VPL +
                    this.totalItems_TEMP +
                    this.totalItems_PR_CA +
                    this.totalItems_PR_EF <
                    3 &&
                  noReplicado
                ) {
                  this.isRemoveItem = true;
                } else {
                  this.isRemoveItem = false;
                }
              }

            }

        }
        // // KL(newItem);
        // // KL(this.downloadQueue.value);

      // Si no existe en la cola de descargas, se agrega.
      } else {
        // Si la cola de descargas no esta definida, se establece a vacio.
        if (this.downloadQueue.value === undefined) {
          this.downloadQueue.next([]);
        }
        let newArray: any[] = [];
        newArray = [...this.downloadQueue.value, {status: 'on', plan }];
        this.downloadQueue.next(newArray);
        // // KL(newArray);
      }

    // Si son todos los planes, se establecen para su descarga.
    } else if (plan === 'ALL') {
      // Realiza el toggle para todos los parametros que reciba
      for (const toggle of toggles) {
        toggle.checked = state;
      }
      // Estado del plan
      const planStatus = state ? <'on'>'on' : <'off'>'off';
      // Establece a todos los planes el estado
      const newArray = this.downloadQueue.value.map((item) => {
        // Retorna el plan con el estado modificado
        return { status: item.status = planStatus, plan: item.plan };
      });
      // Guarda el arreglo resultante
      this.downloadQueue.next(newArray);
    }

    // KL('COLA DE DESCARGA EN DOWNLOAD PLANS');
    // KL(this.downloadQueue.value);

    this.totalItems_DOTAL = this.downloadQueue.value.filter(item => item.status === 'on' && (/DOTAL/).test(item.plan)).length;
    this.totalItems_VPL = this.downloadQueue.value.filter(item => item.status === 'on' && (/VPL/).test(item.plan)).length;
  }

  /**
   *  Método que activa y desactiva la descarga de todos los planes.
   * @param {(ReplicationMobileType | 'ALL')} plan
   * @returns {boolean}
   * @memberof DownloadPlansComponent
   */
  imDisabled(plan: ReplicationMobileType): boolean {
    if (this.isActive === false || this.isRemoveItem === false) {
      return true;
    } else {
      if (this.downloadQueue.value !== undefined) {
        const vpl = this.downloadQueue.value.filter(item => (/VPL/).test(item.plan) && item.status === 'on').length;
        const temp = this.downloadQueue.value.filter(item => (/TEMP/).test(item.plan) && item.status === 'on').length;
        const ov = this.downloadQueue.value.filter(item => (/OV/).test(item.plan) && item.status === 'on').length;
        const dotal = this.downloadQueue.value.filter(item => (/DOTAL/).test(item.plan) && item.status === 'on').length;
        const pr_ef = this.downloadQueue.value.filter(item => (/PR_EF/).test(item.plan) && item.status === 'on').length;
        const pr_ca = this.downloadQueue.value.filter(item => (/PR_CA/).test(item.plan) && item.status === 'on').length;
        const isSelected = this.downloadQueue.value.findIndex(item => item.plan === plan && item.status === 'on');

        return isSelected === -1 &&
          (vpl + temp + ov + dotal + pr_ef + pr_ca) >= 3 && (this.device.getDeviceType() !== 'Android' && this.device.getDeviceType() !== 'Desktop');
      } else {
        this.itemsRemain = 3 - (this.totalItems_DOTAL +
          this.totalItems_OV +
          this.totalItems_VPL +
          this.totalItems_TEMP +
          this.totalItems_PR_CA +
          this.totalItems_PR_EF );
        const indexToSearch = this.db.replicatedHistory !== undefined
          ? this.db.replicatedHistory.findIndex(result => result.plan === plan && result.status === 'on')
          : 0;
        return (
          indexToSearch === -1 &&
          this.totalItems_DOTAL +
                    this.totalItems_OV +
                    this.totalItems_VPL +
                    this.totalItems_TEMP +
                    this.totalItems_PR_CA +
                    this.totalItems_PR_EF  >= 3 &&
          (this.device.getDeviceType() !== 'Android' &&
            this.device.getDeviceType() !== 'Desktop')
        );
      }
    }
  }

  /**
   * Método que retorna si un plan de base de datos está descargado o no
   * @method
   * @param newItem
   */
  getPlaStorage(newItem: ReplicationMobile): Boolean {
    const replicationHistory = localStorage.getItem('replicationHistory');
    this.mobileDatabasesStorage = JSON.parse(replicationHistory) as MobileDatabases;

    // if (this.mobileDatabasesStorage.EE_0 === false && newItem.plan === 'EE_0' && (newItem.status === 'on' || newItem.status === 'off')) {
    //   return true;
    // } else if (this.mobileDatabasesStorage.VPL_1 === false && newItem.plan === 'VPL_1' && (newItem.status === 'on' || newItem.status === 'off')) {
    //   // KL('no esta replicado');
    //   return true;
    // } else
    if (this.mobileDatabasesStorage.VPL_5 === false && newItem.plan === 'VPL_5' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.VPL_10 === false && newItem.plan === 'VPL_10' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.VPL_15 === false && newItem.plan === 'VPL_15' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.VPL_20 === false && newItem.plan === 'VPL_20' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    // } else if (this.mobileDatabasesStorage.VPL_25 === false && newItem.plan === 'VPL_25' && (newItem.status === 'on' || newItem.status === 'off')) {
    //   return true;
    } else if (this.mobileDatabasesStorage.VPL_60 === false && newItem.plan === 'VPL_60' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.VPL_65 === false && newItem.plan === 'VPL_65' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    // } else if (this.mobileDatabasesStorage.VPL_70 === false && newItem.plan === 'VPL_70' && (newItem.status === 'on' || newItem.status === 'off')) {
    //   return true;
    } else if (this.mobileDatabasesStorage.TEMP_1 === false && newItem.plan === 'TEMP_1' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.TEMP_5  === false && newItem.plan === 'TEMP_5' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.TEMP_10  === false && newItem.plan === 'TEMP_10' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.TEMP_15  === false && newItem.plan === 'TEMP_15' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.TEMP_20  === false && newItem.plan === 'TEMP_20' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.TEMP_25  === false && newItem.plan === 'TEMP_25' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.TEMP_60  === false && newItem.plan === 'TEMP_60' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.TEMP_65  === false && newItem.plan === 'TEMP_65' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.TEMP_70  === false && newItem.plan === 'TEMP_70' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.OV  === false && newItem.plan === 'OV' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.DOTAL_15 === false && newItem.plan === 'DOTAL_15' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.DOTAL_20 === false && newItem.plan === 'DOTAL_20' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.DOTAL_65  === false && newItem.plan === 'DOTAL_65' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.PR_EF  === false && newItem.plan === 'PR_EF' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else if (this.mobileDatabasesStorage.PR_CA  === false && newItem.plan === 'PR_CA' && (newItem.status === 'on' || newItem.status === 'off')) {
      return true;
    } else { return false; }
  }

}
