import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { OpcionesPlanService } from '../opcionesPlan/opciones-plan.service';
import { ValoresGarantizadosTabla } from '@app/models/valor-garantizado/ValorGarantizado';
import { CalculosService } from '@services/calculos/calculos.service';
import { formatCurrency } from '@angular/common';
import { ProyeccionVidaInteligente } from './../../models/universales/proyeccionVidaInteligente';
import { ValorGarantizadoInpc } from '@app/models/valor-garantizado/valor-garantizado-inpc';

@Injectable({
  providedIn: 'root'
})
export class ValoresGarantizadosTablasService {

  constructor(public opciones: OpcionesPlanService,
    private calculos: CalculosService,
    @Inject(LOCALE_ID) private locale: string) { }

  getTableVg(): ValoresGarantizadosTabla[] {
    const valCoin = this.opciones.moneda === 'USD' ? 'dolares' : 'pesos';
    const columns = [
      {
        field: 'edad',
        value: this.opciones.prospecto.mancomunado.mancomunado
          ? 'Edad Equivalente'
          : 'Edad',
        order: '1',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'anioPoliza',
        value: 'Año Póliza',
        order: '2',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'primaElegida',
        value: 'Prima Anual UDI',
        order: '3',
        visible: true,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'aportacionAdicional',
        value: `Aportación Adicional ${
          (() => {
            switch (this.opciones.moneda) {
              case 'MXN':
                return 'pesos';
              case 'USD':
                return 'dólares';
              default:
                return 'UDI';
            }
          })()
          }`,
        order: '4',
        visible: false,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'aportacionAdicional_UDI',
        value: 'Aportación + Rendimiento UDI',
        order: '5',
        visible: false,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'prima_AcumuladaUDI',
        value: 'Prima Acumulada UDI',
        order: '6',
        visible: false,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'primaDeducibleUDI',
        value: 'Prima Deducible UDI',
        order: '7',
        visible: false,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      // {
      //   field: 'primaNoDeducibleUDI',
      //   value: 'Prima No Deducible UDI',
      //   order: '7',
      //   visible: false,
      //   coin: 'udi'
      // },
      {
        field: 'valorRescate',
        value: 'Valor Rescate UDI',
        order: '8',
        visible: true,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'porcentajeRecuperacionUdi',
        value: '% Recuperación P.B. UDI',
        order: '9',
        visible: true,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'porcentajeRecuperacionUdiBaCo',
        value: '% Recuperacion P.B. + C.A. UDI',
        order: '10',
        visible: false,
        coin: 'udi',
        projection: [1, 2]
      },
      {
        field: 'porcentajeRecuperacionUdiBaCoExcd',
        value: '% Recuperacion P.B. + C.A. + A.A. UDI',
        order: '11',
        visible: false,
        coin: 'udi',
        projection: [2]
      },
      {
        field: 'sumaAsegurada',
        value: 'Suma Asegurada UDI',
        order: '12',
        visible: true,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'valorEnEfectivo_UDI',
        value: `Valor En Efectivo UDI`,
        order: '13',
        visible: false,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'valorUDI',
        value: 'Valor UDI',
        order: '14',
        visible: true,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'primaElegida_Pesos',
        value: `Prima Anual ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '15',
        visible: true,
        coin: valCoin,
        projection: [0, 1, 2]
      },
      {
        field: 'aportacionAdicionalProyectada',
        value: 'Aportación Adicional Pesos',
        order: '16',
        visible: false,
        coin: 'udi',
        projection: [0, 1, 2]
      },
      {
        field: 'aportacionAdicional_MXN',
        value: `Aportación + Rendimiento ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '17',
        visible: false,
        coin: valCoin,
        projection: [0, 1, 2]
      },
      {
        field: 'prima_AcumuladaMXN',
        value: `Prima Acumulada ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '18',
        visible: false,
        coin: valCoin,
        projection: [0, 1, 2]
      },
      {
        field: 'primaDeducibleMXN',
        value: `Prima Deducible ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '19',
        visible: false,
        coin: valCoin,
        projection: [0, 1, 2]
      },
      // {
      //   field: 'primaNoDeducibleMXN',
      //   value: `Prima No Deducible ${
      //     valCoin === 'dolares' ? 'dólares' : valCoin
      //   }`,
      //   order: '16',
      //   visible: false,
      //   coin: valCoin
      // },
      {
        field: 'valorRescate_Pesos',
        value: `Valor Rescate ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '20',
        visible: true,
        coin: valCoin,
        projection: [0, 1, 2]
      },
      {
        field: 'porcentajeRecuperacionPesos',
        value: `% Recuperación P.B. ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '21',
        visible: true,
        coin: valCoin,
        projection: [0, 1, 2]
      },
      {
        field: 'porcentajeRecuperacionPesosBaCo',
        value: `% Recuperacion P.B. + C.A. ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '22',
        visible: false,
        coin: valCoin,
        projection: [1, 2]
      },
      {
        field: 'porcentajeRecuperacionPesosBaCoExcd',
        value: `% Recuperacion P.B. + C.A. + A.A. ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '23',
        visible: false,
        coin: valCoin,
        projection: [2]
      },
      {
        field: 'sumaAsegurada_Pesos',
        value: `Suma Asegurada ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '24',
        visible: true,
        coin: valCoin,
        projection: [0, 1, 2]
      },
      {
        field: 'valorEnEfectivo_Pesos',
        value: `Valor En Efectivo ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '25',
        visible: false,
        coin: valCoin,
        projection: [0, 1, 2]
      }
    ];
    return columns;
  }

  getTableAxaParaTodos(): ValoresGarantizadosTabla[] {
    const columns = [
      {
        field: 'edad',
        value: 'Edad',
        order: '1',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'prima1',
        value: `Suma Asegurada ${formatCurrency(this.calculos.BASICA_sumaAseguradaMasiva_1.value, this.locale, '$ ', 'MXN')}`,
        order: '2',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'prima2',
        value: `Suma Asegurada ${formatCurrency(this.calculos.BASICA_sumaAseguradaMasiva_2.value, this.locale, '$ ', 'MXN')}`,
        order: '3',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'prima3',
        value: `Suma Asegurada ${formatCurrency(this.calculos.BASICA_sumaAseguradaMasiva_3.value, this.locale, '$ ', 'MXN')}`,
        order: '4',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      }
    ];
    return columns;
  }

  getTableINPC(proyeccion: ValorGarantizadoInpc[]): ValoresGarantizadosTabla[] {
    const bolExcedente = proyeccion.reduce((a, b) => a + b.aportacionAdicionalProyectada, 0) > 0 ? true : false;
    const columns = [
      {
        field: 'edad',
        value: 'Edad',
        order: '1',
        visible: true,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      {
        field: 'anioPoliza',
        value: 'Año Póliza',
        order: '2',
        visible: true,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      // {
      //   field: 'aumentoPr',
      //   value: 'Aumento Pr',
      //   order: '3',
      //   visible: true,
      //   coin: 'pesos',
      //   projection: [0, 1, 2]
      // },
      {
        field: 'primaElegida_Pesos',
        value: 'Prima Anual pesos',
        order: '3',
        visible: true,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      {
        field: 'sumaAsegurada_Pesos',
        value: `Suma Asegurada pesos`,
        order: '4',
        visible: true,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      {
        field: 'valorRescate_Pesos',
        value: 'Valor de Rescate pesos',
        order: '5',
        visible: true,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      {
        field: 'porcentajeRecuperacionPesos',
        value: 'Porcentaje de Recuperación pesos',
        order: '6',
        visible: true,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      {
        field: 'prima_AcumuladaMXN',
        value: 'Prima Acumulada pesos',
        order: '7',
        visible: true,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      {
        field: 'aportacionAdicionalProyectada',
        value: 'Aportacion Adicional pesos',
        order: '8',
        visible: bolExcedente,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      {
        field: 'aportacionAdicional_MXN',
        value: 'Aportacion + Rendimientos pesos',
        order: '9',
        visible: bolExcedente,
        coin: 'pesos',
        projection: [0, 1, 2]
      },
      {
        field: 'valorEnEfectivo_Pesos',
        value: 'Valor En Efectivo Pesos',
        order: '10',
        visible: bolExcedente,
        coin: 'pesos',
        projection: [0, 1, 2]
      }
    ];
    return columns;
  }

  getTableUnivVI(proyeccion: ProyeccionVidaInteligente[]): ValoresGarantizadosTabla[] {
    const bolExcedente = proyeccion.reduce((a, b) => a + b.primaExcedente, 0) > 0 ? true : false;
    const valCoin = this.opciones.moneda === 'USD' ? 'dolares' : 'pesos';
    const columns = [
      {
        field: 'anioPoliza',
        value: 'Año',
        order: '1',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'edad',
        value: 'Edad Real',
        order: '2',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'edadCalculo',
        value: 'Edad Calculo',
        order: '3',
        visible: false,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'primaBasica',
        value: `Prima Basica ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '4',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'primaExcedente',
        value: `Prima Excedente ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '5',
        visible: bolExcedente,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'primaTotal',
        value: `Prima Total ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '6',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'primaAcumulada',
        value: `Prima Acumulada ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '7',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'sumaAsegurada',
        value: `Suma Asegurada ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '8',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'fondoBasico',
        value: `Fondo Basico ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '9',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'fondoExcedente',
        value: `Fondo Excedente ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '10',
        visible: bolExcedente,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'valorEfectivo',
        value: `Valor en Efectivo ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '11',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'valorRescate',
        value: `Valor de Rescate ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '12',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'porcentajeRecuperacion',
        value: `Recuperación (%) ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '13',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
      {
        field: 'beneficioFallecimiento',
        value: `Beneficio por Fallecimiento ${
          valCoin === 'dolares' ? 'dólares' : valCoin
          }`,
        order: '14',
        visible: true,
        coin: 'all',
        projection: [0, 1, 2]
      },
    ];
    return columns;
  }
}
