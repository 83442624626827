import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { CurrencyToNumberPipe } from '@pipes/currency-to-number/currency-to-number.pipe';
import { CurrencyToTextPipe } from '@pipes/currency-to-text/currency-to-text.pipe';
import { MenuPipe } from '@pipes/menu-pipe/menu.pipe';
import { DotalToAliadosPipe } from '@pipes/dotal-to-aliados/dotal-to-aliados.pipe';
import { ConyugalPlazoPagoPipe } from '@pipes/conyugal-plazo-pago/conyugal-plazo-pago.pipe';
import { GenderToTextPipe } from '@pipes/gender/gender-to-text';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CurrencyToNumberPipe,
    CurrencyToTextPipe,
    MenuPipe,
    DotalToAliadosPipe,
    ConyugalPlazoPagoPipe,
    GenderToTextPipe
  ],
  exports: [
    CurrencyToNumberPipe,
    CurrencyToTextPipe,
    MenuPipe,
    DotalToAliadosPipe,
    ConyugalPlazoPagoPipe,
    GenderToTextPipe
  ]
})
export class PipesModule { }
