/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
/** Models */
import { Plazo } from '@models/plazo/plazo.interface';
/** Services */
// import { ProspectoService } from '@services/prospecto/prospecto.service';
import { BAService } from '@services/coverages/BA/ba.service';
import { TUTORService } from '@services/coverages/TUTOR/tutor.service';
import { FactoresService } from '@services/factores/factores.service';
import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { CoverageStatus } from '@models/coverage-status/coverage-status';
/** Rxjs */
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProspectoService } from '@services/prospecto/prospecto.service';

/**
 * Servicio para la cobertura TUTOR KIDS
 */
@Injectable({
    providedIn: 'root'
})
export class EFIService {
/**
   * Crea una instancia reactiva de la prima anual en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI = new BehaviorSubject<number>(0);
/**
   * Crea una instancia reactiva de la prima anual en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_UDI = new BehaviorSubject<CoverageStatus>('EXCLUIDO');
  sumaAsegurada_MXN_U =new BehaviorSubject<number>(0);
  sumaAsegurada_USD_U = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de suma asegurada en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  sumaAsegurada_MXN = new BehaviorSubject<CoverageStatus>('EXCLUIDO');
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida1 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 1 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida1 = new BehaviorSubject<number>(0);
  // Prima anual sugerida 2
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la primera moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_UDI_Sugerida2 = new BehaviorSubject<number>(0);
  /**
   * Crea una instancia reactiva de la prima anual sugerida 2 en la segunda moneda
   * @property
   * @type {BehaviorSubject<number>}
   * @default 0
   */
  primaAnual_MXN_Sugerida2 = new BehaviorSubject<number>(0);

  sumaAseguradaMasiva_1 = new BehaviorSubject<number>(0);
  sumaAseguradaMasiva_2 = new BehaviorSubject<number>(0);
  sumaAseguradaMasiva_3 = new BehaviorSubject<number>(0);

  primaAnualMasiva_1 = new BehaviorSubject<number>(0);
  primaAnualMasiva_2 = new BehaviorSubject<number>(0);
  primaAnualMasiva_3 = new BehaviorSubject<number>(0);

  // Observables
  /**
   * Crea una instancia reactiva como observable de la prima anual en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_UDI$ = this.primaAnual_UDI.asObservable();
  /**
   * Crea una instancia reactiva como observable de la prima anual en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  primaAnual_MXN$ = this.primaAnual_MXN.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la primera moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_UDI$ = this.sumaAsegurada_UDI.asObservable();
  sumaAsegurada_MXN_U$ = this.sumaAsegurada_MXN_U.asObservable();
  sumaAsegurada_USD_U$ = this.sumaAsegurada_USD_U.asObservable();
  /**
   * Crea una instancia reactiva como observable de la suma asegurada en la segunda moneda
   * @property
   * @type {Observable<number>}
   * @default 0
   */
  sumaAsegurada_MXN$ = this.sumaAsegurada_MXN.asObservable();
  /**
   * Crea una suscripción almacenando la suma asegurada de la cobertura Básica
   * @property {Subscription}
   */
  sumaAsegurada_BA: Subscription;

  prima_DiezMasPae = 0;

  edadTutor: number;

  /**
   * Constructor
   * @constructor
   * @param recursosExternos Crea instancia de RecursosExternosService
   * @param factores Crea instancia de FactoresService
   * @param BaService Crea instancia de BAService
   * @param prospectoService Crea instancia de ProspectoService
   */
  constructor(
    private recursosExternos: RecursosExternosService,
    private factores: FactoresService,
    private BaService: BAService,
    private TutorService: TUTORService,
    private prospectuService: ProspectoService
  ) {
    this.prospectuService.prospectoActual
      .distinctUntilChanged()
      .subscribe(prospecto => {
        this.edadTutor = prospecto.age;  
      });
   }
  /**
   * Establece la prima anual en la cobertura dado el valor
   * @param sumaAseguradaUDI
   */
  establecePrimaAnual(sumaAsegurada) {
    this.sumaAsegurada_BA = this.BaService.sumaAsegurada_UDI.distinctUntilChanged()
      .subscribe(() => {
        this.obtenerPrima(sumaAsegurada);
      });
  }

  obtenerPrima(sumaAsegurada: number): void {

    let suma = 0;
    if(sumaAsegurada !== 0 && this.edadTutor <= 55 ){
      suma = this.TutorService.primaAnual_UDI.value + this.BaService.primaAnual_UDI.value;

      const primaEFI_UDI = this.calculoPrimaEFI( suma, 'Elegido' );
      const primaEFI_MXN = this.recursosExternos.UDIToSecond(primaEFI_UDI);

      const primaEFI_UDI_Sugerida1 = this.calculoPrimaEFI( suma, 'Sugerido1' );
      const primaEFI_MXN_Sugerida1 = this.recursosExternos.UDIToSecond(primaEFI_UDI_Sugerida1);

      const primaEFI_UDI_Sugerida2 = this.calculoPrimaEFI( suma, 'Sugerido2');
      const primaEFI_MXN_Sugerida2 = this.recursosExternos.UDIToSecond(primaEFI_UDI_Sugerida2);

      // const sumaAsegurada_UDI = suma;
      // const sumaAsegurada_MXN = this.recursosExternos.FirstToSecond(sumaAsegurada_UDI);

      this.primaAnual_UDI_Sugerida1.next( primaEFI_UDI_Sugerida1 );
      this.primaAnual_MXN_Sugerida1.next( primaEFI_MXN_Sugerida1 );

      this.primaAnual_UDI_Sugerida2.next( primaEFI_UDI_Sugerida2 );
      this.primaAnual_MXN_Sugerida2.next( primaEFI_MXN_Sugerida2 );

      this.primaAnual_UDI.next( primaEFI_UDI );
      this.primaAnual_MXN.next( primaEFI_MXN );

      this.sumaAsegurada_UDI.next( 'INCLUIDO' );
      this.sumaAsegurada_MXN.next( 'INCLUIDO' );

      return;
    } else{
      this.sumaAsegurada_UDI.next( 'EXCLUIDO' );
      this.sumaAsegurada_MXN.next( 'EXCLUIDO' );
      this.primaAnual_UDI.next(0);
      this.primaAnual_MXN.next(0);

      this.primaAnual_UDI_Sugerida1.next(0);
      this.primaAnual_MXN_Sugerida1.next(0);
      this.primaAnual_UDI_Sugerida2.next(0);
      this.primaAnual_MXN_Sugerida2.next(0);
    }

  }

  /**
   * Realiza el calculo de la tarifa
   *
   * @param {number} calculoPrimaTUTOR
   * @returns {number} Prima 
   * @memberof PAIService
   */
  calculoPrimaEFI(sumaAsegurada: number, plazo: Plazo, factor?: number, isINPC: boolean = false, edadInpc?: number): number {
    let factorEfi = this.factores.obtenerFactorCobertura('EFI', plazo, isINPC, edadInpc,'TEMPORAL');
    const primaEFI = this.obtenerPrimaEFI(sumaAsegurada, factorEfi);
    return primaEFI;
  }

  calcularPrimaDiezMas(edad: number) {
    // let primaIAC = 0;
    // if (this.opciones.coberturaEnVigencia(this.primaAnual_UDI.value, IAC, edad) > 0) {
    //   const factorIac = this.factores.obtenerFactoresDiezMas('IAC', edad);
    //   primaIAC = this.obtenerPrimaTUTOR(this.sumaAsegurada_UDI.value, factorIac);
    //   primaIAC = primaIAC - this.primaAnual_UDI.value;
    // }
    // this.prima_DiezMasPae = primaIAC;
  }

  obtenerPrimaEFI(sumaAsegurada: number, factorEfi: number){
    const primaEfi = factorEfi !== 0 ? (
      sumaAsegurada * factorEfi / 1000
    ) : 0;
    return primaEfi;
  }

  resetValues() {
    this.primaAnual_UDI.next(0);
    this.primaAnual_MXN.next(0);
    this.primaAnual_UDI_Sugerida1.next(0);
    this.primaAnual_MXN_Sugerida1.next(0);
    this.primaAnual_UDI_Sugerida2.next(0);
    this.primaAnual_MXN_Sugerida2.next(0);
    this.sumaAsegurada_UDI.next('EXCLUIDO');
    this.sumaAsegurada_MXN.next('EXCLUIDO');
    this.sumaAseguradaMasiva_1.next(0);
    this.sumaAseguradaMasiva_2.next(0);
    this.sumaAseguradaMasiva_3.next(0);
    this.primaAnualMasiva_3.next(0);
    this.primaAnualMasiva_1.next(0);
    this.primaAnualMasiva_2.next(0);
    this.sumaAsegurada_MXN_U.next(0);
    this.sumaAsegurada_USD_U.next(0);
  }
}
