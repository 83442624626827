import { Component, OnInit } from '@angular/core';
/**
 * Componente encargado de la configuración de las tablas
 */
@Component({
  selector: 'cvida-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.sass']
})
export class TableSettingsComponent implements OnInit {


  /**
   * Lifecycle Hook de OnInit
   * @method
   */
  ngOnInit() {
  }

}
