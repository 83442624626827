import { RecursosExternosService } from '@services/recursosExternos/recursos-externos.service';
import { OpcionesPlanService } from '@services/opcionesPlan/opciones-plan.service';
import { CalculosService } from '@services/calculos/calculos.service';
import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CoverageStatus, PaymentType } from '../../models';
import { ProspectoService } from '@services/prospecto/prospecto.service';
import { Currency, SPLIT_PAYMENT } from '../../constants';
import { ExcedentesService } from '@app/services/excedentes/excedentes.service';
// import html2canvas from 'html2canvas';
import { ANUAL_INFLATIONS } from '../../constants/plan-options/anual-inflations.constant';
import { PlanType } from '../../constants/plan-options/plan-types.constant';
import { User } from '../../models';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { PaymentFrequencyService } from '@app/services/paymentFrequency/payment-frequency.service';
import { RoutesService } from '@app/services/routes/routes.service';

/**
 * Componente del template
 */
@Component({
  selector: 'cvida-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.sass']
})
export class TemplateComponent implements OnInit {

  /**
   * Selecciona y checa item de html pdfQuotation
   * @property
   * @type {ElementRef}
   */
  @ViewChild('pdfQuotation') pdfTranslate: ElementRef;
  /**
   * Fecha actual formateado
   * @property
   */
  date;
  /**
   * String para fecha
   * @property
   */
  de = ' de ';
  /**
   * Meses actuales
   * @property
   * @type {number}
   */
  meses;
  /**
   * Fecha actual
   * @property
   * @type {number}
   */
  f;

  /**
   * Versión del pdf
   * @property
   * @type {string}
   */
  version: string;

  /**
   * Ancho de la pantalla
   * @property
   * @type {number}
   */
  screenWidth: number;
  /**
   * Alto de la pantalla
   * @property
   * @type {number}
   */
  screenHeight: number;

  /**
   * DATOS DEL PROSPECTO
  */
  prospectu = this.prospectusDataService.prospecto.value;
  /**
   * CARACTERISTICAS DEL PLAN
  */

  // Plan
  /**
   * Tipo de plan seleccionado
   * @property
   */
   plan: PlanType;
  // Tipo Moneda
  /**
   * Tipo de moneda seleccionado
   * @property
   * @type {Currency}
   */
  moneda: Currency;
  /**
   * Tipo de Segunda Moneda
   * @property
   * @type {Currency}
   */
  segundaMoneda: Currency;
  /**
   * Tipo de plzo de pagos seleccionado
   * @property
   * @type {number}
   */
  plazoPagos: number;

  /**
   * Primas totales de todas las coberturas en primera moneda
   * @property
   * @type {number}
   */
  primaTotalUDI: number;
  /**
   * Primas adicional en udi
   * @property
   * @type {number}
   */
  primaAdicionalUDI: number;
  /**
   * Primas anual en udi
   * @property
   * @type {number}
   */
  primaAnualUDI: number;
  /**
   * valor del udi
   * @property
   * @type {number}
   */
  valorUDI: number;
  /**
   * Prima adicional en pesos
   * @property
   * @type {number}
   */
  primaTotalMXN: number;
  /**
   * Prima adicional en pesos
   * @property
   * @type {number}
   */
  primaAdicionalMXN: number;
  /**
   * Prima anual en pesos
   * @property
   * @type {number}
   */
  primaAnualMXN: number;
  /**
   * Inflacion anual
   * @property
   * @type {number}
   */
  inflacionAnual: string;

  /**
   * covertura de fallecimiento anual
   * @property
   * @type {number}
   */
  fallecimiento: number;
  /**
   * Covertura fallecimiento titular AliadosKids
   * @property
   * @type {number}
   */
  fallecimientoTutor: number;
  coberEfi: CoverageStatus;
    /**
   * Covertura de invalidez-bi
   * @property
   * @type {number}
   */
  invalidezBI: CoverageStatus;
  /**
   * Covertura de validez-pai
   * @property
   * @type {number}
   */
  invalidezPAI: number;
  /**
   * covertura de accidentes
   * @property
   * @type {number}
   */
  accidentes: number;
  /**
   * covertura por enfermedades
   * @property
   * @type {number}
   */
  enfermedades: number;
  /**
   * covertura por cancer
   * @property
   * @type {number}
   */
  cancer: number;
  /**
   * covertura conyugsl
   * @property
   * @type {number}
   */
  conyugal: number;
  /**
   * covertura por desempleo
   * @property
   * @type {number}
   */
  desempleo: CoverageStatus;
  /**
   * covertura de gastos funerrios
   * @property
   * @type {number}
   */
  funerarios: number;

  /**
   * Variable para identificar que el tipo de plan
   * actual es Aliados+
   */
  isDotal: boolean;

  /**
   * Datos del usuario
   * @property
   * @type {User}
   */
  user: User;

  temporalidad: string;

  aportacion: number;
  aportacionTempUDI: number;
  aportacionTempMXN: number;

  cotizador: string;

  /**
   * Constructor
   * @constructor
   * @param prospectusDataService Crea instancia de ProspectoService
   * @param calculos Crea instancia de CalculosService
   * @param opciones Crea instancia de OpcionesPlanService
   * @param recursos Crea instancia de RecursosExternosService
   * @param authentication Crea instancia de AuthenticationService
   */
  constructor(
    private prospectusDataService: ProspectoService,
    private calculos: CalculosService,
    private opciones: OpcionesPlanService,
    private recursos: RecursosExternosService,
    private excedentes: ExcedentesService,
    private authentication: AuthenticationService,
    private paymentFrecuency: PaymentFrequencyService,
    private routes: RoutesService
  ) {
    this.authentication.user.subscribe(user => this.user = user);

    this.screenWidth = window.innerWidth;

    this.meses = new Array(
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    );
    this.f = new Date();

    this.cotizador = this.routes.chosenCotizador.value;
  }

  /**
   * Lifecyrcle Hook de OnInit
   * @method
   */
  ngOnInit() {
    window.scroll(0, 0);
    this.screenHeight = window.innerHeight;
    this.date = this.f.getDate() + this.de + this.meses[this.f.getMonth()] + this.de + this.f.getFullYear();
    this.prospectusDataService.prospectoActual.subscribe(prospecto => this.prospectu = prospecto);
    this.version = this.getVersion();
    /**
     * CARACTERISTICAS DEL PLAN
    */
    // Plan
    this.plan = this.opciones.tipoPlan;
    // Tipo Moneda
    this.moneda = this.opciones.moneda;
    // Tipo Segunda Moneda
    this.segundaMoneda = this.opciones.segundaMoneda;
    // Plazo Pagos
    this.plazoPagos = Number(this.opciones.plazoPago.value);
    // Prima total
    this.primaTotalUDI = this.calculos.primaTotalFraccionadaDividida.value;
    // Prima adicional
    this.primaAdicionalUDI = this.calculos.G_primaAdicional_UDI.value;
    // Prima anual
    this.primaAnualUDI = this.calculos.sumaPrimas.value;
    // Valor de la UDI:
    this.valorUDI = this.recursos.valor_UDI;
    // Prima Total Moneda 2:
    this.primaTotalMXN = this.opciones.moneda === 'UDI' ? this.recursos.FirstToSecond(this.primaTotalUDI) : this.primaTotalUDI;

    // Prima anual moneda 2:
    this.primaAnualMXN = this.opciones.moneda === 'UDI' ? this.recursos.FirstToSecond(this.primaAnualUDI) : this.primaAnualUDI;
    // Inflación anual:
    this.inflacionAnual = ANUAL_INFLATIONS.filter(item => Number(item.value) === Number(this.excedentes.inflacion_udi.value))[0].title;
    // Fallecimiento
    this.fallecimiento = this.calculos.BASICA_sumaAseguradaUDI.value;
    // Invalidez
    this.invalidezBI = this.calculos.BI.sumaAsegurada_UDI.value;
    this.invalidezPAI = this.calculos.PAI.sumaAsegurada_UDI.value;
    // Accidentes
    switch (true) {
      case this.calculos.DI.sumaAsegurada_UDI.value > 0:
        this.accidentes = this.calculos.DI.sumaAsegurada_UDI.value;
        break;
      case this.calculos.DIPO.sumaAsegurada_UDI.value > 0:
        this.accidentes = this.calculos.DIPO.sumaAsegurada_UDI.value;
        break;
      case this.calculos.DIPOC.sumaAsegurada_UDI.value > 0:
        this.accidentes = this.calculos.DIPOC.sumaAsegurada_UDI.value;
        break;
      default:
        this.accidentes = 0;
        break;
    }
    // Enfermedades
    this.enfermedades = this.calculos.PAE.sumaAsegurada_UDI.value;
    // Cáncer
    this.cancer = this.calculos.IAC.sumaAsegurada_UDI.value;
    // Conyugal
    this.conyugal = this.calculos.CC.sumaAsegurada_UDI.value;
    // Desempleo
    this.desempleo = this.calculos.PCD.sumaAsegurada_UDI.value;
    // Funerarios
    this.funerarios = this.calculos.GF.sumaAsegurada_UDI.value;

    if( this.plan === 'ALIADOSKIDS'){
      this.fallecimientoTutor = this.calculos.TUTOR_sumaAseguradaUDI.value;
      this.coberEfi = this.calculos.EFI_sumaAseguradaUDI.value;
    }

    if (this.opciones.tipoPlan === 'DOTAL') {
      this.isDotal = true;
    } else {
      this.isDotal = false;
    }

    this.temporalidad = this.paymentFrecuency.frecuenciaPago.value;

    this.aportacion = this.excedentes.aportacion_anual_UDI.value;
        // Prima adicional moneda 2:
    this.primaAdicionalMXN = this.moneda !== 'UDI' ? this.aportacion : this.recursos.FirstToSecond(this.aportacion);

    this.aportacionTempUDI = this.aportacion / this.getTemporalidadDivisor();

    this.aportacionTempMXN = this.moneda !== 'UDI' ? this.aportacionTempUDI : this.recursos.FirstToSecond(this.aportacionTempUDI);
  }

  getTemporalidadDivisor(): number {
    let splitPayment: PaymentType = 'yearly';
    switch (this.paymentFrecuency.frecuenciaPago.value) {
      case 'ANUAL':
        splitPayment = 'yearly';
        break;

      case 'SEMESTRAL':
        splitPayment = 'semi-annually';
        break;

      case 'TRIMESTRAL':
        splitPayment = 'quarterly';
        break;

      case 'MENSUAL':
        splitPayment = 'monthly';
        break;
    }

    return SPLIT_PAYMENT.filter(i => i.type === splitPayment)[0]
      .divider;
  }

  /**
   * Detecta el evento de redimensión y asigna ancho y alto
   * @method
   */
  @HostListener('window:resize', ['event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }



  /**
   * Regresa a la última ruta
   * @method
   */
  volver() {
    window.history.back();
  }

  /**
   * Ejecuta evento en navegador para imprimir
   * @method
   */
  imprimirPDF() {
    window.print();

    // html2canvas(this.pdfTranslate.nativeElement).then(canvas => {
    //   const imgWidth = 208;
    //   const imgHeight = canvas.height * imgWidth / canvas.width;

    //   const contentDataURL = canvas.toDataURL('image/png');

    //   const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    //   const position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //   pdf.save('MYPdf.pdf'); // Generated PDF
    // });
  }

  /**
   * Retorna versión del pdf
   * @method
   * @return {string}
   */
  getVersion(): string {
    let v = '';
    const vanio = this.f.getFullYear();
    const vmes = this.f.getMonth() + 1;

    v = vanio + '.' + vmes;

    return v;
  }
}
