import { Pipe, PipeTransform } from '@angular/core';

/**
 *Pipe encargada de transformar divisas a texto.
 * @export
 * @class CurrencyToTextPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'currencyToText'
})
export class CurrencyToTextPipe implements PipeTransform {

  /**
 *Método que transforma las divisas a texto.
 * @param {string} currency
 * @returns {*}
 * @memberof CurrencyToTextPipe
 */
  transform(currency: string): any {
    switch (currency) {
      case 'MXN':
        return 'Pesos';
      case 'USD':
        return 'Dólares';
      case 'UDI':
        return 'UDI';
      default:
        return '';
    }
  }

}
