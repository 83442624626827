/**
 * Constantes y datos obtenidos de:
 * - Mensajes de Error.xlsx
 * - HistoriasUsuario_VidaIndividualProTGT-20180622.xlsx
 * proporcionado por Diana Hernández Camargo
 */
import { CoverageConstants } from '@models/coverage-constants/coverage-constants.interface';

/**
 * Cobertura: Básica (Fallecimiento)
 *@const
 */

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const BA: {
  MIN_SUM_UDI     : number;
  MIN_SUM_MXN     : number;
  MIN_SUM_USD     : number;
  MIN_SUM_PRO_EF  : number;
  MAX_SUM_PRO_EF  : number;
  MIN_SUM_KIDS: number;
  MAX_SUM_KIDS: number;
} = {
  MIN_SUM_UDI: 60000,
  MIN_SUM_MXN: 300000,
  MIN_SUM_USD: 30000,
  MIN_SUM_PRO_EF: 200000,
  MAX_SUM_PRO_EF: 1200000,
  MIN_SUM_KIDS: 25000,
  MAX_SUM_KIDS: 500000
};

/**
 * Cobertura: Básica y adicionales
 * @const
 */
export const BAS: CoverageConstants = {
  MAX_SUM_MXN: 999999999,
  MAX_SUM_UDI: 999999999,
  MAX_SUM_USD: 999999999,
  MIN_SUM_UDI: 60000,
  MIN_SUM_MXN: 300000,
  MIN_SUM_USD: 30000,
  MIN_SUM_PRO_EF: 200000,
  MAX_SUM_PRO_EF: 1200000,
  MIN_SUM_KIDS: 25000,
  MAX_SUM_KIDS: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

export const PLAN_TEMP: CoverageConstants = {
  MAX_SUM_MXN: 999999999,
  MIN_SUM_MXN: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

export const PLAN_TEMP_PAI: CoverageConstants = {
  MAX_SUM_MXN: 40000000,
  MIN_SUM_MXN: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

export const PLAN_TEMP_CC: CoverageConstants = {
  MAX_SUM_MXN: 4000000,
  MIN_SUM_MXN: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

export const PLAN_TEMP_DI: CoverageConstants = {
  MAX_SUM_MXN: 20000000,
  MIN_SUM_MXN: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

export const PLAN_TEMP_DIPO: CoverageConstants = {
  MAX_SUM_MXN: 20000000,
  MIN_SUM_MXN: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

export const PLAN_TEMP_DIPOC: CoverageConstants = {
  MAX_SUM_MXN: 20000000,
  MIN_SUM_MXN: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

export const PLAN_TEMP_IAC: CoverageConstants = {
  MAX_SUM_MXN: 2000000,
  MIN_SUM_MXN: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

export const PLAN_TEMP_PAE: CoverageConstants = {
  MAX_SUM_MXN: 1000000,
  MIN_SUM_MXN: 500000,
  ACCEPTANCE_AGE: {MIN: 0, MAX: 0},
  CANCELLATION_DATE: 0
};

/**
 * Cobertura: Exención de pago de primas por invalidez total y permanente.
 * @const
 */
export const BI: CoverageConstants = {
  MAX_SUM_MXN: 20000000,
  ACCEPTANCE_AGE: {MIN: 18, MAX: 55},
  CANCELLATION_DATE: 60,
  MIN_SUM_UDI: 60000,
  MIN_SUM_MXN: 300000,
  CANCELLATION_DATE_INPC: 60
};

/**
 * Cobertura: Pago adicional por invalidez total y permanente.
 * @const
 */
export const PAI: CoverageConstants = {
  MIN_SUM_MXN: 300000,
  MAX_SUM_MXN: 40000000,

  MIN_SUM_UDI: 60000,
  MAX_SUM_UDI: 5457100,

  MIN_SUM_USD: 30000,
  MAX_SUM_USD: 2033000,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 55},
  CANCELLATION_DATE: 60,
  CANCELLATION_DATE_INPC: 55
};

/**
 * Cobertura: Cobertura Conyugal
 * @const
 */
export const CC: CoverageConstants = {
  MIN_SUM_MXN: 300000,
  MAX_SUM_MXN: 4000000,

  MIN_SUM_UDI: 60000,
  MAX_SUM_UDI: 545800,

  MIN_SUM_USD: 15000,
  MAX_SUM_USD: 203300,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 70},
  CANCELLATION_DATE: 90
};

/**
 * Cobertura: Muerte Accidental
 * @const
 */
export const DI: CoverageConstants = {
  MIN_SUM_MXN: 300000,
  MAX_SUM_MXN: 20000000,

  MAX_SUM_UDI: 2728600,
  MIN_SUM_UDI: 60000,

  MIN_SUM_USD: 30000,
  MAX_SUM_USD: 1016500,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 65},
  CANCELLATION_DATE: 70,
  CANCELLATION_DATE_INPC: 65,
  CANCELLATION_DATE_INPC_END: 70
};

/**
 * Cobertura: Muerte Accidental y Pérdidas Orgánicas
 * @const
 */
export const DIPO: CoverageConstants = {
  MIN_SUM_MXN: 300000,
  MAX_SUM_MXN: 20000000,

  MAX_SUM_UDI: 2728600,
  MIN_SUM_UDI: 60000,

  MIN_SUM_USD: 30000,
  MAX_SUM_USD: 1016500,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 65},
  CANCELLATION_DATE: 70,
  CANCELLATION_DATE_INPC: 65,
  CANCELLATION_DATE_INPC_END: 70
};

/**
 * Cobertura: Muerte Accidental y Pérdidas Orgánicas y Muerte Colectiva
 * @const
 */
export const DIPOC: CoverageConstants = {
  MIN_SUM_MXN: 300000,
  MAX_SUM_MXN: 20000000,

  MAX_SUM_UDI: 2728600,
  MIN_SUM_UDI: 60000,

  MIN_SUM_USD: 30000,
  MAX_SUM_USD: 1016500,


  ACCEPTANCE_AGE: {MIN: 18, MAX: 65},
  CANCELLATION_DATE: 70,
  CANCELLATION_DATE_INPC: 65,
  CANCELLATION_DATE_INPC_END: 70
};

/**
 * Cobertura: Indemnización adicional por cáncer
 * @const
 */
export const IAC: CoverageConstants = {
  MIN_SUM_MXN: 300000,
  MAX_SUM_MXN: 2000000,

  MIN_SUM_UDI: 60000,
  MAX_SUM_UDI: 272900,

  MIN_SUM_USD: 30000,
  MAX_SUM_USD: 101700,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 60},
  CANCELLATION_DATE: 65,
  CANCELLATION_DATE_INPC: 60
};

/**
 * Cobertura: Pago adicional por enfermedades graves
 * @const
 */
export const PAE: CoverageConstants = {
  MIN_SUM_MXN: 300000,
  MAX_SUM_MXN: 1000000,

  MIN_SUM_UDI: 60000,
  MAX_SUM_UDI: 136500,

  MIN_SUM_USD: 30000,
  MAX_SUM_USD: 50900,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 55},
  CANCELLATION_DATE: 60,
  CANCELLATION_DATE_INPC: 55
};

/**
 * Cobertura: Protección continua por desempleo
 * @const
 */
export const PCD: CoverageConstants = {
  MAX_SUM_MXN: Infinity,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 60},
  CANCELLATION_DATE: 65
};

/**
 * Cobertura: Gastos Funerarios
 * @const
 */
export const GF: CoverageConstants = {
  MAX_SUM_MXN: 100000,
  MAX_SUM_UDI: 13700,

  //MIN_SUM_USD: 30000,
  MAX_SUM_USD: 5100,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 70},
  CANCELLATION_DATE: 80,
  MAX_SUM_PERCENT: 0.1
};

export const MU: Omit<CoverageConstants, 'ACCEPTANCE_AGE' | 'CANCELLATION_DATE'> = {
  MAX_SUM_MXN: 999999999,
  MIN_SUM_MXN: 350000,

  MAX_SUM_UDI: 200000000,
  MIN_SUM_UDI: 80000,

  MAX_SUM_USD: 58823530,
  MIN_SUM_USD: 35000,
};

export const CDC_MU: CoverageConstants = {
  MAX_SUM_MXN: 2000000,
  MIN_SUM_MXN: 0,

  MAX_SUM_UDI: 400000,
  MIN_SUM_UDI: 0,

  MAX_SUM_USD: 117647,
  MIN_SUM_USD: 0,
  ACCEPTANCE_AGE: {MIN: 18, MAX: 65},
  CANCELLATION_DATE: 65,
};

export const BAUniversal: {
  MIN_SUM_MXN_VI: number;
  MIN_SUM_MXN_MR: number;
  MIN_SUM_USD_VI: number;
  MIN_SUM_USD_MR: number;
  MAX_SUM_MXN: number;
  MAX_SUM_USD: number;
  MIN_SUM_RIES_PREF_MXN: number;
  MIN_SUM_RIES_PREF_USD: number;
} = {
  MIN_SUM_MXN_VI: 350000,
  MIN_SUM_MXN_MR: 300000,
  MIN_SUM_USD_VI: 35000,
  MIN_SUM_USD_MR: 30000,
  MAX_SUM_MXN: 999999999,
  MAX_SUM_USD: 999999999,
  MIN_SUM_RIES_PREF_MXN: 2000000,
  MIN_SUM_RIES_PREF_USD: 200000
};

export const ECMIUniversal: CoverageConstants = {
    MAX_SUM_MXN: 20000000,
    ACCEPTANCE_AGE: {MIN: 18, MAX: 55},
    CANCELLATION_DATE: 60,
    MIN_SUM_VI_MXN: 350000,
    MIN_SUM_VI_USD: 35000,
    MIN_SUM_MR_MXN: 300000,
    MIN_SUM_MR_USD: 30000,
    MIN_SUM_PREF_MXN: 2000000,
    MIN_SUM_PREF_USD: 200000
};

export const PAIUniversal: CoverageConstants = {
  MIN_SUM_VI_MXN: 350000,
  MIN_SUM_MR_MXN: 300000,
  MAX_SUM_MXN: 40000000,

  MIN_SUM_VI_USD: 35000,
  MIN_SUM_MR_USD: 30000,
  MAX_SUM_USD: 2033000,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 55},
  CANCELLATION_DATE: 60,
};

export const DIUniversal: CoverageConstants = {
  MIN_SUM_VI_MXN: 350000,
  MIN_SUM_MR_MXN: 300000,
  MAX_SUM_MXN: 20000000,

  MIN_SUM_VI_USD: 35000,
  MIN_SUM_MR_USD: 30000,
  MAX_SUM_USD: 1016500,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 65},
  CANCELLATION_DATE: 70
};

export const DIPOCUniversal: CoverageConstants = {
  MIN_SUM_VI_MXN: 350000,
  MIN_SUM_MR_MXN: 300000,
  MAX_SUM_MXN: 20000000,

  MIN_SUM_VI_USD: 35000,
  MIN_SUM_MR_USD: 30000,
  MAX_SUM_USD: 1016500,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 65},
  CANCELLATION_DATE: 70
};

export const GFIUniversal: CoverageConstants = {
  MAX_SUM_MXN: 100000,
  // MIN_SUM_USD: 30000,
  MAX_SUM_USD: 5100,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 70},
  CANCELLATION_DATE: 80,
  MAX_SUM_PERCENT: 0.1
};

export const GFCUniversal: CoverageConstants = {
  MAX_SUM_MXN: 100000,
  //MIN_SUM_USD: 30000,
  MAX_SUM_USD: 5100,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 70},
  CANCELLATION_DATE: 80,
  MAX_SUM_PERCENT: 0.1
};

export const GFHUniversal: CoverageConstants = {
  MAX_SUM_MXN: 100000,
  //MIN_SUM_USD: 30000,
  MAX_SUM_USD: 5100,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 70},
  CANCELLATION_DATE: 80,
  MAX_SUM_PERCENT: 0.1
};

export const CDC: CoverageConstants = {

  MIN_SUM_VI_MXN: 350000,
  MIN_SUM_MR_MXN: 300000,
  MAX_SUM_MXN: 2000000,

  MIN_SUM_VI_USD: 35000,
  MIN_SUM_MR_USD: 30000,
  MAX_SUM_USD: 117647,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 60},
  CANCELLATION_DATE: 65
};

/**
 * Cobertura: Excención pago de primas por fallecimiento e invalidez AliadosKids
 * @const
 */
 export const EFI: CoverageConstants = {
  MAX_SUM_MXN: Infinity,

  ACCEPTANCE_AGE: {MIN: 18, MAX: 55},
  CANCELLATION_DATE: 60
};
