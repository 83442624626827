/** Angular Core */
import { Injectable } from '@angular/core';
/** Constants */
import { ROUTE_NAMING } from '@constants/routes/route-naming.constant';
/** Rxjs */
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

/**
 * Servicio que provee las rutas de los componentes principales.
 */
@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  /**
   * Rutas. Se crea una instancia reactiva.
   * @property
   * @default "[ ]"
   */
  routes = new BehaviorSubject<any[]>([]);
  /**
   * Rutas (Observable).
   * @property
   */
  routes$ = this.routes.asObservable();

  chosenCotizador = new BehaviorSubject<'AxaParaTodos' | 'ProTGT' | 'Aliados' | 'Universales' | string>(null);

  firstTimeChoose = new BehaviorSubject<boolean>(true);

  isIndividual = new BehaviorSubject<boolean>(true);

  /**
   * Declaración de variables (rutas).
   */
  constructor(
    private router: Router,
  ) {
    this.initChosenCotizador();
  }

  initChosenCotizador(): void {
    this.router.events.subscribe(route => {
      if (route['url']) {
        const cotizadorRoute = route['url'].split('/')[2];
        if (cotizadorRoute !== '' || cotizadorRoute !== undefined) {
          this.chosenCotizador.next(cotizadorRoute);
          this.initRoutes(cotizadorRoute);
        }
      }
    });
  }

  initRoutes(cotizador: string): void {
    if (cotizador !== 'AxaParaTodos') {
      this.routes.next([
        ROUTE_NAMING.PRINCIPAL,
        ROUTE_NAMING.PASO1,
        ROUTE_NAMING.PASO2A,
        ROUTE_NAMING.PASO3,
        ROUTE_NAMING.PASO4,
        ROUTE_NAMING.REQUISITOS,
      ]);
    } else {
      this.routes.next([
        ROUTE_NAMING.PRINCIPAL,
        ROUTE_NAMING.PASO1,
        ROUTE_NAMING.PASO2A,
        ROUTE_NAMING.TARIFAS,
        ROUTE_NAMING.REQUISITOS
      ]);
    }
    if (cotizador === 'Universales') {
      this.routes.next([
        ROUTE_NAMING.PRINCIPAL,
        ROUTE_NAMING.PASO1,
        ROUTE_NAMING.PASO2A,
        ROUTE_NAMING.PERFILAMIENTO,
        ROUTE_NAMING.PASO4UNI,
        ROUTE_NAMING.PASO5UNI,
        ROUTE_NAMING.REQUISITOS]);
    }
  }

  /**
   *
   *
   * @param {string} planABuscar
   * @returns {('VPL' | 'DOTAL' | 'AxaParaTodos' | '')}
   * @memberof RoutesService
   */
  offlinePlan(planABuscar: string): 'ProTGT' | 'DOTAL' | 'AxaParaTodos' | 'Universales' | '' {
    switch (planABuscar) {
      case 'Aliados':
        return 'DOTAL';
      case 'ProTGT':
        return 'ProTGT';
      case 'AxaParaTodos':
        return 'AxaParaTodos';
      case 'Universales':
        return 'Universales';
      default:
        return '';
    }
  }

}
