import { Component, OnInit, HostListener } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { ROUTE_NAMING } from '@constants/index';
import { DeviceTypeService } from '@app/services/deviceType/deviceType.service';

/**
 * Componente encargado del proveer los componentes primarios
 *
 * @export
 * @class ShellComponent
 * @implements {OnInit}
 */
@Component({
  template: `
  <div *ngIf="!explorer">
    <cvida-header ></cvida-header>
    <div class="main" [ngClass]="offlineConfig === true || amIOnLanding === true ? ' ' : 'd-flex' ">
    <cvida-progress-menu *ngIf="!offlineConfig" [ngClass]="{'fixed': isScrolled && !amIOnLanding, 'no-display': amIOnLanding}"
      [ngStyle]="{ 'padding-top': device.getDeviceType() === 'iPhone' ? '1.3rem' : '0' }"></cvida-progress-menu>
    <router-outlet></router-outlet>
    </div>
    <cvida-footer [ngClass]="{'footer-fix': iAmOnProspectusData && !amIOnLanding}"></cvida-footer>
  </div>
  <div *ngIf="explorer">
    <cvida-not-found></cvida-not-found>
  </div>
  `,
  styleUrls: ['./shell.component.sass']
})
export class ShellComponent implements OnInit {
  /**
   * Variable donde se almacenará el valor del evento scroll
   *@type {boolean}
   *@default false
   */
  isScrolled = false;
  /**
   * Variable donde se almacena el valor que se obtiene al detectar si se ha ingresado mediante internet explorer o no.
   * @type {boolean}
   */
  explorer: boolean;
  /**
   * Variable donde se almacena el valor que se obtiene al detectar si se está actualmente en ProspectusData o no.
   * @type {boolean}
   */
  iAmOnProspectusData: boolean;
  /**
  * Configuración offline (estado).
  * @property
  * @type {boolean}
  */
  offlineConfig: boolean;
  /**
   * Verifica si la ruta actual es la respectiva del landing
   * @property
   * @type {boolean}
   * @memberof ShellComponent
   */
  amIOnLanding: boolean;

  /**
   * Creates an instance of ShellComponent.
   * @param {Router} router
   * @param {AuthenticationService} auth
   * @memberof ShellComponent
   */
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    public device: DeviceTypeService
  ) {
    this.auth.preventFailReload.subscribe(value => {
      if (value) {
        this.amIOnLanding = true;
        this.auth.preventFailReload.next(false);
      }
    });
  }

  /**
   * Método que detecta cuando se lleva a cabo el evento scroll y retorna un valor dependiendo de si el evento se ha llevado a cabo o no.
   * @method
   * @param {*} $event
   * @memberof ShellComponent
   */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
      const scrollTop = $event.target.scrollingElement.scrollTop;
      const BREAKPOINT = 56;

      if (scrollTop > BREAKPOINT) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
  }

  /**
   * LifeCycle Hook de OnInit
   * @method
   * @memberof ShellComponent
   */
  ngOnInit() {
    if (/Trident/.test(navigator.userAgent)) {
      this.explorer = true;
    }

    this.checkRoutes(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.checkRoutes(event);
      }

    });
  }

  /**
   * Verifica que la ruta sea la indicada
   * @method
   * @private
   * @param {(RoutesRecognized | string)} event
   * @memberof ShellComponent
   */
  private checkRoutes(event: RoutesRecognized | string): void {
    if (typeof event === 'object') {
      this.amIOnLanding = event.urlAfterRedirects.split('/')[2] === ROUTE_NAMING.LANDING;
      this.iAmOnProspectusData = event.url.split('/')[3] === ROUTE_NAMING.PASO1;
      this.offlineConfig = event.url.split('/')[2] === ROUTE_NAMING.PLAZOPAGO_CONFIG;
      this.amIOnLanding = event.url.split('/')[2] === ROUTE_NAMING.LANDING;
    }

    if (typeof event === 'string') {
      this.offlineConfig = event.split('/')[2] === ROUTE_NAMING.PLAZOPAGO_CONFIG;
      this.amIOnLanding = event.split('/')[2] === ROUTE_NAMING.LANDING;
      this.iAmOnProspectusData = event.split('/')[3] === ROUTE_NAMING.PASO1;
      this.offlineConfig = event.split('/')[2] === ROUTE_NAMING.PLAZOPAGO_CONFIG;
      this.amIOnLanding = event.split('/')[2] === ROUTE_NAMING.LANDING;
    }

  }

}
