/**
 * Factor por defecto de cobertura Muerte Accidental.
 * @const
 * @default "1.3"
 */

export const DI_FACTOR = 1.3;
/**
 * Factor por defecto de cobertura Muerte Accidental y Pérdidas Orgánicas.
 * @const
 * @default "2.04"
 */

export const DIPO_FACTOR = 2.04;
/**
 * Factor por defecto de cobertura Muerte Accidenta y Pérdidas Orgánicas y Muerte Colectiva.
 * @const
 * @default "2.5"
 */

export const DIPOC_FACTOR = 2.5;
