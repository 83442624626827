/**
 * Necesidades de prospecto por defecto.
 * @const
 * @default Need: "Family"
 * @default Need_Option: "Car"
 */
export const PROSPECTUS_NECESITY_CONSTANTS = {
    DEFAULT_NEED: 'Family',
    DEFAULT_NEED_OPTION: 'Car'
  };
