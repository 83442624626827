import { Injectable } from '@angular/core';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { OpcionesPlanService } from '../opcionesPlan/opciones-plan.service';
import { ExcedentesService } from '../excedentes/excedentes.service';
import {ServicesApi} from '../services-api/services-api';


@Injectable({
  providedIn: 'root'
})
export class BaseDatosInpcService {

  /**
  * Observable que detecta el estado de conexión.
  * @type {Observable<boolean>}
  */
  online$: Observable<boolean>;
  /**
   * Online - Variable de estado.
   * @type {boolean}
   */
  online: boolean;

  arrParamsBd : any = [];

  arrParamsVgBd : any = [];

  constructor(
    public opciones: OpcionesPlanService,
    private excedentes: ExcedentesService,
    private api: ServicesApi,
  ) {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(true)),
    );
  }


  async getDocs(): Promise<any> {
    let query : any = {
      selector: {
      },
      dbSelector: {

      },
      fields: [
        'Edad',
        'PlazoPago',
        'Tarifa'
      ]
    };
    const tPlan = this.opciones.tipoPlan;
    if(this.opciones.prospecto.gender)
    {
      query.dbSelector.genero = this.opciones.prospecto.gender;
    }
    if(this.opciones.prospecto.fumador)
    {
      query.dbSelector.fumador = this.opciones.prospecto.fumador;
    }
    query.dbSelector.producto = tPlan;
    return await this.api.getInpcDocs(query).toPromise()
  }

  async getDocsVg(): Promise<any> {
      const plazosPagos = Array(Number(this.opciones.plazoPago.value))
        .fill(1).map((v, i) => (v + i).toString());
      const edadM = this.opciones.prospecto.mancomunado.mancomunado ? this.opciones.prospecto.mancomunado.edadEquivalente :
        this.opciones.prospecto.age;
      const edades = Array(Number(this.opciones.plazoSeguro))
        .fill(Number(edadM))
        .map((v, i) => (v + i).toString());

      const query : any = {
          selector: {
            // mayor que 1
            // menor que el plazo de pago
            PlazoPago: { $in: this.opciones.tipoPlan === 'OV' || Number(this.opciones.plazoPago.value) >= 60 ?
             [this.opciones.plazoPago.value.toString()] : plazosPagos },
            Edad: { $in: edades },
          },
          dbSelector: {

          },
          fields: ['Edad', 'PlazoPago', 'Factor', 'AnoPoliza']
        };
        const tPlan =  this.opciones.tipoPlan;

        query.dbSelector.genero = this.opciones.prospecto.gender;
        query.dbSelector.fumador = this.opciones.prospecto.fumador;
        query.dbSelector.producto = tPlan;
        query.dbSelector.comision = this.excedentes.esquema_comision.value.toUpperCase().replace('S', '');

        return await this.api.getInpcDocsVg(query).toPromise();
  }

}
