import { ProtectionPlan } from '@models/protection-plan/protection-plan';
import { PlanType } from '@constants/plan-options';

/**
 * Plazos de pago (por plan).
 * @const
 * @type {ProtectionPlan[ ]}
 */
export const PROTECTION_TERMS: ProtectionPlan[] = [

  { plan: 'TEMPORAL', paymentTerms: [
    {term: 1, minAge: 18, maxAge: 70, currency: 'All'},
    {term: 5, minAge: 18, maxAge: 70, currency: 'MXN'},
    {term: 10, minAge: 18, maxAge: 70, currency: 'MXN'},
    {term: 15, minAge: 18, maxAge: 70, currency: 'MXN'},
    {term: 20, minAge: 18, maxAge: 70, currency: 'MXN'},
    {term: 25, minAge: 18, maxAge: 70, currency: 'ALL'},
  ], reachedAge: [
    {term: 60, minAge: 18, maxAge: 59, currency: 'ALL'},
    {term: 65, minAge: 18, maxAge: 64, currency: 'MXN'},
    {term: 70, minAge: 18, maxAge: 69, currency: 'ALL'},
  ], safeTerm: 15 },

  { plan: 'VPL', paymentTerms: [
    // {term: 1, minAge: 18, maxAge: 70},
    {term: 5, minAge: 18, maxAge: 70},
    {term: 10, minAge: 18, maxAge: 70},
    {term: 15, minAge: 18, maxAge: 70},
    {term: 20, minAge: 18, maxAge: 70},
    // {term: 25, minAge: 18, maxAge: 70}, #Actualización tarifa 2020
  ], reachedAge: [
   {term: 60, minAge: 18, maxAge: 59},
    {term: 65, minAge: 18, maxAge: 64},
    // {term: 70, minAge: 18, maxAge: 69},
  ], safeTerm: 99 },

  { plan: 'OV', paymentTerms: [], reachedAge: [
    {term: 99, minAge: 18, maxAge: 70 }
  ], safeTerm: 99 },

  { plan: 'DOTAL', paymentTerms: [
    {term: 15, minAge: 18, maxAge: 70},
    {term: 20, minAge: 18, maxAge: 70},
  ], reachedAge: [
    {term: 65, minAge: 18, maxAge: 60},
  ], safeTerm: 15 },

  { plan: 'ALIADOSKIDS', paymentTerms: [
    {term: 18, minAge: 18, maxAge: 70},
    {term: 22, minAge: 18, maxAge: 70},
  ], reachedAge: [], 
    safeTerm: 18 },
  {
    plan: 'PROTECANCER', paymentTerms: [
      { term: 1, minAge: 18, maxAge: 60 }
    ], safeTerm: 1, reachedAge: []
  },
  {
    plan: 'PROTECCION EFECTIVA', paymentTerms: [
      { term: 1, minAge: 18, maxAge: 65 }
    ], safeTerm: 1, reachedAge: []
  },
  {
    plan: 'MUJER UNICA', paymentTerms: [],
    safeTerm: 99, reachedAge: [{ term: 65, minAge: 18, maxAge: 70 }]
  },
  { plan: 'VIDA INTELIGENTE', paymentTerms: [], reachedAge: [
    {term: 90, minAge: 18, maxAge: 70 }
    ], safeTerm: 90
  },
  { plan: 'MI PROYECTO R', paymentTerms: [], reachedAge: [
    {term: 85, minAge: 18, maxAge: 70 }
    ], safeTerm: 85
  },
];

/**
 * Plazos de pago por defecto.
 * @const
 * @type {Array}
 * @param plan
 * @param default
 */
export const DEFAULT_TERMS: {plan: PlanType, default: number}[] = [
  { plan: 'TEMPORAL', default: 15 },
  { plan: 'VPL', default: 10 },
  { plan: 'OV', default: 99 },
  { plan: 'DOTAL', default: 15 },
  { plan: 'ALIADOSKIDS', default: 18 },
  { plan: 'PROTECANCER', default: 1 },
  { plan: 'PROTECCION EFECTIVA', default: 1 },
  { plan: 'MUJER UNICA', default: 65 },
  { plan: 'VIDA INTELIGENTE', default: 90 },
  { plan: 'MI PROYECTO R', default: 85 }
];


export const REACHED_AGES: [60, 65, 70, 99] = [
  60, 65, 70, 99
];
