/**
 * REQUISITOS DE ASEGURABILIDAD DE VIDA
 * Finacieros
 * @description Se describen las constantes de requistos financieros de asegurabiidad
 * de vida.
 *
 * Vigente a partir del 1° de Noviembre de 2016
 */

export const FINANCIAL_REQUIREMENTS = {
  /**
   * INTERVALOS POR SUMA ASEGURADA
   * @description Arreglo que define los intervalos para petición de documentos
   * en base a la suma asegurada.
  */
  SUM_ASSURED: [ 6000000  , 20000000, 25000000, 25000001],
  /**
   * DOCUMENTOS
   * @description Documentos que son requisitados.
   * @property {string} name - Nombre del documento
   * @property {string} source - URL del documento.
  */
  DOCUMENTS: [
    {
      name: 'NO APLICA',
      source: 'https://axa.mx/web/servicios-axa/condiciones-generales'
    },
    {
      name: 'CUESTIONARIO FINANCIERO',
      source: 'https://axa.mx/web/servicios-axa/condiciones-generales'
    },
    {
      name: 'CUESTIONARIO FINANCIERO Y ENTREVISTA VÍA CAMPO',
      source: 'https://axa.mx/web/servicios-axa/condiciones-generales'
    },
    {
      name: 'CUESTIONARIO FINANCIERO, ENTREVISTA VÍA CAMPO, ESTADOS FINANCIEROS Y ACTA CONSTITUTIVA DE LA EMPRESA',
      source: 'https://axa.mx/web/servicios-axa/condiciones-generales'
    }
  ],
  /**
   * NOTAS
   * @description Notas de puntos a considerar.
  */
  NOTES: [
    {
      A: 'El Cuestionario Financiero podrá sustituirse y/o complementarse con ' +
      'una declaración fiscal anual o recibo de nómina, ambos del último periodo ' +
      'a la fecha de la solicitud del seguro.'
    },
    {
      B: 'Estados Finacieros auditados de los últimos 2 años, deben inlcuir: ' +
      'Balance General, Estado de Resultados (pérdidas y ganancias), anexos y dictamen.'
    },
    {
      C: 'En Seguro Patrimonial los Estados Financieros y el Acta Constitutiva de ' +
      'la empresa podrá sustituirse por una Relación Patrimonial completa.'
    },
    {
      D: 'Todos estos documentos deberán estar firmados por un Contador Público ' +
      'Titulado y anexar copia de su cédula profesional.'
    }
  ]
};
