/**
* NOTAS DE REQUISITOS DE ASEGURABILIDAD DE VIDA
* Médicos y financieros
* @description Notas de puntos a considerar.
* @author Jesus Torres
*/
export const NOTES = {
   A: 'Los requisitos médicos y financieros aplican para negocios nuevos y ' +
   'movimientos a pólizas emitidas de Vida Individual, tomando en cuenta el cúmulo' +
   ' de suma asegurada en vigor con AXA. En caso de Vida Grupo para excedentes a la' +
   ' Suma Asegurada Máxima Individual sin examen médico (SAMI).',

   B: 'Las solicitudes que se ingresen deben ser las institucionales en vigor, ' +
   'requisitadas en su totalidad y adjuntar una identificación oficial vigente.',

   C: ' La vigencia de las solicitudes para negocios iniciales o movimientos a ' +
   'pólizas emitidas es de 2 meses (60 días).',

   D: `La vigencia de exámenes médicos es de:
     - Riesgo normal: 1 año.
     - Riesgo preferente: 6 meses.
     - Riesgo subnormal: 6 meses.`,

   E: 'En solicitudes para seguro mancomunado, cobertura conyugal y dependientes ' +
   'económicos mayores a 18 años, es indispensable la solicitud con firma autógrafa ' +
   'e identificación oficial vigente de los Solicitantes al ingreso del trámite.',

   G: 'Para la entrevista Vía Campo concertar cita a la Ext. 4967.',

   H: 'AXA se reserva el derecho de solicitar las pruebas y/o requisitos adicionales ' +
   'que considere necesarios para la valoración del riesgo.'
};
